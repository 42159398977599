var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-content" }, [
    _vm._m(0),
    _c("div", { staticClass: "nav-content" }, [
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("router-link", { attrs: { to: "/company" } }, [
            _vm._v("企業情報"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item current" },
        [
          _c("router-link", { attrs: { to: "/tokutei" } }, [
            _vm._v("特定商取引法に基づく表示"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("router-link", { attrs: { to: "/privacy" } }, [
            _vm._v("プライバシーポリシー"),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "table" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/table_right_top.png") },
      }),
      _c("div", { staticClass: "list-content" }, [
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _vm._m(5),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "name" }, [_vm._v("お問い合わせ")]),
          _c(
            "div",
            { staticClass: "value" },
            [
              _c("router-link", { attrs: { to: "/question" } }, [
                _vm._v("お問い合わせはこちら ＞＞"),
              ]),
            ],
            1
          ),
        ]),
        _vm._m(6),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "name" }, [_vm._v("公式通販サイト")]),
          _c(
            "div",
            { staticClass: "value" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v("https://www.woodlife.jp/"),
              ]),
            ],
            1
          ),
        ]),
        _vm._m(7),
        _vm._m(8),
        _vm._m(9),
        _vm._m(10),
        _c("div", { staticClass: "list" }, [
          _vm._m(11),
          _c(
            "div",
            { staticClass: "value" },
            [
              _vm._v(
                "お客様のご都合による返品・返金および交換はお受けできません。"
              ),
              _c("br"),
              _c("router-link", { attrs: { to: "/guides/return" } }, [
                _vm._v("詳しくはこちら >>"),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg" }, [
      _c("div", { staticClass: "title" }, [_vm._v("WOODLIFEについて")]),
      _c("div", { staticClass: "text" }, [
        _vm._v("家具通販・おしゃれなインテリア専門店（ウッドライフ）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("販売業者")]),
      _c("div", { staticClass: "value" }, [
        _vm._v("はんこプレミアム株式会社 （Hanko Premium Co.,Ltd.）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("代表者")]),
      _c("div", { staticClass: "value" }, [_vm._v("晴海 旭")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("住所")]),
      _c("div", { staticClass: "value" }, [
        _vm._v(" 〒274-0065 千葉県船橋市高根台7丁目26-12 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("電話番号")]),
      _c("div", { staticClass: "value" }, [
        _vm._v("047-489-5595 (営業電話などはご遠慮ください。)"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("電話受付時間")]),
      _c("div", { staticClass: "value" }, [_vm._v("平日 9:30〜18:30")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("販売価格")]),
      _c("div", { staticClass: "value" }, [
        _vm._v("商品紹介ページをご参照ください。"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [
        _vm._v("画面表示と実際の"),
        _c("br"),
        _vm._v("商品"),
      ]),
      _c("div", { staticClass: "value" }, [
        _vm._v(
          "商品の色、素材感をできるだけ忠実に再現しようと努力してはいますが、大きな商品をモニターの中で小さな写真によって表現していますので、画面上ご覧になっている色と、実際の商品の色が多少異なる場合がございます。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("商品のお届け")]),
      _c("div", { staticClass: "value" }, [
        _vm._v(
          "弊社指定のヤマト運輸会社および、株式会社ハルミからお届けいたします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("引き渡し時期")]),
      _c("div", { staticClass: "value" }, [
        _vm._v(
          "商品は充分用意しておりますが、同一商品にご注文が殺到した際には品切れになることもあります。ご容赦ください。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("送料について")]),
      _c("div", { staticClass: "value" }, [
        _vm._v("54,000円以上のお買い上げは送料無料です。"),
        _c("br"),
        _vm._v("54,000円以下のお買い上げは1個につき3,000円（税込）です。"),
        _c("br"),
        _vm._v(
          "※北海道・沖縄・鹿児島県・離島へのお届けは、別途追加送料が必要です。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "name" }, [
      _vm._v("返品・"),
      _c("br"),
      _vm._v("交換について"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }