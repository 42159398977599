var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "popularity-title" }, [_vm._v("おすすめの商品")]),
    _c("div", { staticClass: "swiper popularity-swiper" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.initData, function (item) {
          return _c(
            "router-link",
            {
              key: item.id,
              staticClass: "swiper-slide",
              attrs: { to: "/product/" + item.product_sn },
            },
            [
              _c("img", { attrs: { src: item.image, alt: item.image_alt } }),
              _c("div", { staticClass: "discount" }, [
                item.promotion.length > 0 && item.promotion[0].is_open
                  ? _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.promotion[0].discount) + "%OFF"),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
              _c("div", { staticClass: "desc" }, [
                _vm._v(_vm._s(item.long_title)),
              ]),
              _c(
                "div",
                { staticClass: "price" },
                [
                  item.promotion.length > 0 && item.promotion[0].is_open
                    ? [
                        _c("span", { staticClass: "now" }, [
                          _vm._v(
                            "¥" + _vm._s(_vm.calcThousands(item.discount_price))
                          ),
                        ]),
                        _c("span", { staticClass: "original" }, [
                          _vm._v("¥" + _vm._s(_vm.calcThousands(item.price))),
                        ]),
                      ]
                    : [
                        _vm._v(
                          " ¥" + _vm._s(_vm.calcThousands(item.price)) + " "
                        ),
                      ],
                ],
                2
              ),
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "swiper-pagination" }),
      _c("div", { staticClass: "swiper-button-prev" }),
      _c("div", { staticClass: "swiper-button-next" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }