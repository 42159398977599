// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/pc/side_arrow.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/pc/side_arrow_hover.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".app-wrapper[data-v-400e4c9f] {\n  min-width: 1440px;\n  min-height: 100vh;\n}\n.side-content[data-v-400e4c9f] {\n  position: fixed;\n  right: 0;\n  top: 50%;\n  width: 100px;\n  color: #fff;\n  font-size: 12px;\n  text-align: center;\n  padding: 0 12px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  background: #000;\n  z-index: 10;\n  -webkit-transition: 0.1s;\n  transition: 0.1s;\n}\n.side-content:hover .btn[data-v-400e4c9f] {\n  display: block !important;\n}\n.side-content .content[data-v-400e4c9f] {\n  position: relative;\n}\n.side-content .content .btn[data-v-400e4c9f] {\n  display: none;\n  position: absolute;\n  width: 24px;\n  height: 24px;\n  left: -24px;\n  top: 14px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  cursor: pointer;\n}\n.side-content .content .btn[data-v-400e4c9f]:hover {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.side-content .content .open[data-v-400e4c9f] {\n  position: absolute;\n  width: 24px;\n  height: 24px;\n  left: -48px;\n  top: 14px;\n  cursor: pointer;\n}\n.close[data-v-400e4c9f] {\n  right: -112px;\n}\n.side-item[data-v-400e4c9f] {\n  display: block;\n  padding: 16px 0 6px;\n  border-top: 1px solid #fff;\n  cursor: pointer;\n}\n.side-item[data-v-400e4c9f]:nth-child(1) {\n  border-top: 0;\n}\n.side-item img[data-v-400e4c9f] {\n  margin-bottom: 6px;\n}", ""]);
// Exports
module.exports = exports;
