import request from '@/utils/request'

// 获取颜色标签列表
export function getColorTagList(id, params) {
  return request({
    url: '/getColorTags/' + id,
    method: 'get',
    params
  })
}
