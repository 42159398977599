<template>
  <div class="footer">
    <div v-show="!paymentFooter" class="footer-box">
      <div class="main">
        <div>
          <router-link class="question" to="/question">
            <span>お問合せ</span>
            <img src="@/assets/images/pc/rightArrow.png">
          </router-link>
          <div class="article-box">
            <div class="article-item">
              <div class="title">
                <div>ITEM</div>
              </div>
              <router-link to="/category/sofa">ソファ</router-link>
              <router-link to="/category/bed">ベッド</router-link>
              <router-link to="/category/rack">収納</router-link>
              <router-link to="/category/table">テーブル</router-link>
              <router-link to="/category/chair">チェア</router-link>
            </div>
            <div class="article-item">
              <div class="title">
                <div>ROOM</div>
              </div>
              <router-link to="">寝室</router-link>
              <router-link to="">リビング</router-link>
              <router-link to="">キッチン</router-link>
              <router-link to="">ホームオフィス</router-link>
              <router-link to="">子供部屋</router-link>
            </div>
            <div class="article-item">
              <div class="title">
                <div>GUIDE</div>
              </div>
              <router-link to="/guides/category">ご利用ガイド</router-link>
              <router-link to="/question">お問い合わせ</router-link>
              <router-link to="/guides/question">よくあるご質問</router-link>
              <router-link to="/guides/delivery">配送について</router-link>
              <router-link to="/guides/return">返品・交換</router-link>
            </div>
          </div>
        </div>
        <div class="share">
          <img class="footer-logo" src="@/assets/images/pc/footerlogo.png">
          <div class="desc">Official APP</div>
          <div class="app">
            <img src="@/assets/images/pc/appStore.png">
            <img src="@/assets/images/pc/googlePlay.png">
          </div>
          <div class="desc">Official SNS</div>
          <div class="footer-share">
            <a href="https://www.facebook.com/woodlife.jp" target="_black" @mouseenter="show = 1" @mouseleave="show = false">
              <img v-show="show !== 1" src="@/assets/images/pc/footerShare_1.png">
              <img v-show="show === 1" src="@/assets/images/pc/footerShare_1_hover.png">
            </a>
            <router-link to="" @mouseenter.native="show = 2" @mouseleave.native="show = false">
              <img v-show="show !== 2" src="@/assets/images/pc/footerShare_2.png">
              <img v-show="show === 2" src="@/assets/images/pc/footerShare_2_hover.png">
            </router-link>
            <a href="https://www.instagram.com/woodlife.jp" target="_black" @mouseenter="show = 3" @mouseleave="show = false">
              <img v-show="show !== 3" src="@/assets/images/pc/footerShare_3.png">
              <img v-show="show === 3" src="@/assets/images/pc/footerShare_3_hover.png">
            </a>
            <router-link to="" @mouseenter.native="show = 4" @mouseleave.native="show = false">
              <img v-show="show !== 4" src="@/assets/images/pc/footerShare_4.png">
              <img v-show="show === 4" src="@/assets/images/pc/footerShare_4_hover.png">
            </router-link>
            <router-link to="" @mouseenter.native="show = 5" @mouseleave.native="show = false">
              <img v-show="show !== 5" src="@/assets/images/pc/footerShare_5.png">
              <img v-show="show === 5" src="@/assets/images/pc/footerShare_5_hover.png">
            </router-link>
          </div>
        </div>
      </div>
      <div class="introduce">
        <span class="introduce-desc">COPYRIGHT© {{ year }} HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED</span>
        <router-link to="/company">企業情報</router-link>
        <router-link to="/tokutei">特定商取引法に基づく表示</router-link>
        <router-link to="/privacy">プライバシーポリシー</router-link>
        <router-link to="/guides/about">About US</router-link>
      </div>
    </div>
    <div v-show="paymentFooter" class="footer-payment-box">COPYRIGHT© {{ year }} HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      paymentFooter: false,
      year: new Date().getFullYear()
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.paymentFooter = this.$route.meta.paymentFooter
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.footer{
  position: sticky;
  top: 100%;
  color: #fff;
  font-size: 14px;
  background: #333;
}
.footer-box{
  width: 1440px;
  padding: 40px 0 0;
  margin: 0 auto;
}
.footer-payment-box{
  width: 1440px;
  margin: 0 auto;
  height: 39px;
  line-height: 39px;
  color: rgba($color: #fff, $alpha: 0.3);
  border-top: 1px solid rgba($color: #fff, $alpha: 0.06);
}
.main{
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.question{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  height: 52px;
  padding: 0 32px;
  box-sizing: border-box;
  border: 2px solid #fff;
}
.article-box{
  display: flex;
  justify-content: space-between;
  width: 700px;
  margin: 36px 0 0 0;
}
.article-item{
  display: flex;
  flex-direction: column;
  width: 192px;
  a{
    font-size: 14px;
    margin: 20px 0 0 0;
  }
  a:hover{
    text-decoration: underline;
  }
}
.title{
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  &::after{
    content: '';
    display: block;
    width: 32px;
    height: 2px;
    background: #fff;
  }
}
.share{
  padding: 0 0 0 68px;
  border-left: 1px solid rgba($color: #fff, $alpha: 0.06);
}
.footer-logo{
  margin: 0 0 77px 0;
}
.desc{
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 12px;
}
.app img{
  margin: 0 0 32px 12px;
  cursor: pointer;
}
.footer-share{
  margin: 0 0 0 12px;
  img{
    margin: 0 8px 0 0;
  }
}
.introduce{
  display: flex;
  align-items: center;
  height: 39px;
  border-top: 1px solid rgba($color: #fff, $alpha: 0.06);
  a{
    padding-right: 12px;
    border-right: 1px solid #fff;
    margin-right: 12px;
  }
  a:hover{
    text-decoration: underline;
  }
}
.introduce-desc{
  color: rgba($color: #fff, $alpha: 0.3);
  margin: 0 44px 0 0;
}
</style>
