var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "bread-crumbs" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / "),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _vm._v(" / アフターサービス "),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
      _c("router-link", { attrs: { to: "/guides/question" } }, [
        _c("div", { staticClass: "button" }, [_vm._v("他のご質問はこちら")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-content" }, [
      _vm._v(" 配送·送料について "),
      _c("img", {
        attrs: { src: require("@/assets/images/pc/guides_after_service.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lists-content" }, [
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("アフターサービス「安心の1年保証」"),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 大型家具については商品のお届け日より1年内に製造上の瑕疵による故障や不具合が生じた場合は、交換または返金させていただきます。ただし、運送費などをご負担いただく場合があります。商品の状態によっては、部材・部品をご送付してお客様でご対応いただく場合がありますので、予めご了承ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ※初期不良の交換は、商品到着後7日以内とさせていただきます。"),
          _c("br"),
          _vm._v(
            " ※こちらは商品不具合による使用上で生じた事故・損害に対して、当店はその責任（金銭的補償等）を負うことはできません。 "
          ),
        ]),
        _c("div", { staticClass: "red-box" }, [
          _vm._v(" ※保証期間は商品により異なります。"),
          _c("br"),
          _c("span", [_vm._v("商品ページ内の記載をご確認ください。")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("保証書について")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(" 保証書を同封いたしておりません。"),
          _c("br"),
          _vm._v(
            "ご連絡の際はご注文後にお送りしたメールに記載されたご注文情報が必要となりますので、保証書代わりとして必ず保管をお願いいたします。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" 保証内容について"),
          _c("br"),
          _c("span", [_vm._v("※保証は日本国内においてのみ有効です。")]),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " ご家庭で使用された家具に限り、本体貼り付けラベル等の注意書きに従った正常な使用状態で故障した場合は、修理または部品交換をいたします。"
          ),
          _c("br"),
          _vm._v(
            " 但し、以下の場合は保証期間内であっても有償での修理・交換となりますので予めご了承ください。 "
          ),
        ]),
        _c("div", { staticClass: "text-content" }, [
          _c("div", { staticClass: "head" }, [_vm._v("保証対象外")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _vm._v("取扱説明書や注意表示での注意事項を守られなかった場合。"),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v("不当な修理や改造による故障や損傷。"),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v("納品後の移動、輸送により生じた破損・故障。"),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "一般家庭用以外（業務用）に使用した場合の故障または損傷。"
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "外観のキズ、張地、塗装部分などの外観劣化、木の変質によるもの・自然な変色などの場合。"
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "天然木の無垢材による一般的な経年変化や反りや割れなどの場合。"
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "直射日光、冷暖房機器ほか電気製品の熱などによる変形・変色。"
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v("機能や性能にかかわらない場合。"),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v(
                "摩耗、傷、汚れ、色落ち、ソファのクッション部分のヘタレなどの使用による経年劣化。"
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._v("当店以外から商品を購入された場合。"),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("免責・注意事項")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 商品に瑕疵がなく、通常のご使用中に発生した床・壁等周辺物の直接又は間接の傷及び汚損等につきましては、一切の責任を負うことはできません。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 商品組立業者等をお客様自身で手配される場合において、商品不良等の理由で作業ができなかった場合や再組み立て・再設置等が必要になる場合であっても、その費用を当社にて負担することはできません。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ご購入品以外の商品への交換はできません。交換品・部品のお届けまでに初回配送時と同様にお届けまでにお時間を頂戴する場合がございます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 対象商品の在庫が無い場合は入荷後の交換、もしくはご返品（返金）を承ります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 修理については部品などの都合で、外観が変わる場合があります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 保証期間を過ぎた商品については、有償で修理いたしますが、メーカー都合(生産終了)によっては対応できない場合があります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 消耗部分の交換や自然な損耗、経年変化に関するメンテナンス・修理は有償となります。お見積りはお問い合わせください。 "
          ),
        ]),
        _c("div", { staticClass: "red-box" }, [
          _vm._v(
            "※こちらは商品不具合による使用上で生じた事故・損害に対して、当店はその責任（金銭的補償等）を負うことはできません。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("無垢材家具の割れを予防するコツ"),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 冬の乾燥する時期に、木材は外気に水分を失った木材はどんどんと収縮してしまい、割れが発生してしまいます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " エアコンの風向きを調整し、家具に風が直接当たらないようにしましょう。家具の近くで、加湿器をつけると良いでしょう。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 特に冬場は、空気が乾燥するので、暖房器具から離れて使用してください。 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }