var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-content" },
    [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/images/sp/reset_pwd_success_icon.png"),
        },
      }),
      _c("div", { staticClass: "title" }, [
        _vm._v("パスワードを再設定しました"),
      ]),
      _c("router-link", { attrs: { to: "/login" } }, [
        _c("div", { staticClass: "button" }, [_vm._v("ログイン")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }