var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
        }),
        _c("router-link", { attrs: { to: "/question" } }, [
          _vm._v("お問い合わせ"),
        ]),
        _c("img", {
          attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
        }),
        _c("span", [_vm._v("送信完了")]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "question-from" },
      [
        _vm._m(0),
        _c("div", { staticClass: "dc" }, [_vm._v("お問い合わせ送信完了")]),
        _vm._m(1),
        _vm._m(2),
        _c("router-link", { staticClass: "deliver", attrs: { to: "/" } }, [
          _vm._v("トップページ"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ttl" }, [
      _c("span", [_vm._v("Contact us by email")]),
      _c("span", { staticClass: "num" }, [_vm._v("03")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v("この度お問い合わせ頂き、ありがとうございました。"),
      _c("br"),
      _vm._v("下記お問い合わせ内容をご確認の上、送信してください。"),
      _c("br"),
      _vm._v(
        "24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。"
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v(
        "※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。"
      ),
      _c("br"),
      _vm._v(
        "※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }