var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "simple-footer" },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/sp/logo_footer.png") },
        }),
      ]),
      _c("div", { staticClass: "footer-box" }, [
        _vm._v("© HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED."),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }