<template>
  <div class="personal-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/personal/mine">マイページ</router-link> / アカウント情報・変更
    </div>
    <div class="b-title">アカウント情報・変更</div>
    <div class="desc">
      情報変更を行う場合、<span>※</span> の箇所は必ず入力してください。
    </div>
    <div class="user-content">
      <div class="list">
        <div class="name">メールアドレス</div>
        <div class="value">{{ ruleForm.email }}</div>
      </div>
      <div class="list">
        <div class="name">パスワード</div>
        <div class="value">******</div>
      </div>
    </div>
    <div class="change-pwd" @click="handleChangePassword">パスワード変更の場合、こちら</div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
      <div class="inline-item">
        <el-form-item prop="first_name" label="姓">
          <el-input v-model.trim="ruleForm.first_name" placeholder="姓" />
        </el-form-item>
        <el-form-item prop="last_name" label="名">
          <el-input v-model.trim="ruleForm.last_name" placeholder="名" />
        </el-form-item>
      </div>
      <div class="inline-item">
        <el-form-item prop="receive_phone" label="電話番号">
          <el-input v-model.trim="ruleForm.receive_phone" type="tel" maxlength="11" placeholder="電話番号" />
        </el-form-item>
        <el-form-item prop="zip_code" label="郵便番号">
          <el-input v-model.trim="ruleForm.zip_code" type="tel" maxlength="7" placeholder="郵便番号" @change="changeZipCode" />
        </el-form-item>
      </div>
      <div class="inline-item">
        <el-form-item prop="stats" label="都道府県">
          <el-select v-model="ruleForm.stats" placeholder="都道府県">
            <el-option v-for="item in areaData" :key="item.id" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item prop="city" label="市区町村">
          <el-input v-model.trim="ruleForm.city" placeholder="市区町村" />
        </el-form-item>
      </div>
      <el-form-item prop="address" label="番地住所">
        <el-input v-model.trim="ruleForm.address" placeholder="番地住所" />
      </el-form-item>
      <el-form-item prop="detail_address" label="建物名・部屋番号（任意）">
        <el-input v-model.trim="ruleForm.detail_address" placeholder="建物名・部屋番号（任意）" />
      </el-form-item>
      <el-button type="primary" class="save-btn" :loading="btnLoading" @click="onSubmit">変更を保存</el-button>
      <router-link to="/personal/mine">
        <div class="prev-btn">マイページへ戻る</div>
      </router-link>
    </el-form>
    <van-overlay :show="dialogVisible">
      <div class="wrapper" @click.stop>
        <div class="modal-content">
          <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="dialogVisible = false">
          <div class="title">パスワードの変更</div>
          <el-form ref="passwordForm" :model="passwordForm" :rules="rules" label-position="top">
            <el-form-item prop="original_password" label="現在のパスワード">
              <el-input v-model.trim="passwordForm.original_password" placeholder="現在のパスワード" />
            </el-form-item>
            <el-form-item prop="password" label="新しいパスワード">
              <el-input v-model.trim="passwordForm.password" placeholder="新しいパスワード" />
            </el-form-item>
            <el-form-item prop="password_confirmation" label="新しい会パスワードを再入力">
              <el-input v-model.trim="passwordForm.password_confirmation" placeholder="新しい会パスワードを再入力" />
            </el-form-item>
          </el-form>
          <el-button type="primary" class="confirm-btn" :loading="pwdLoading" @click="handleUpdatePassword">変更する</el-button>
          <div class="cancel-btn" @click="dialogVisible = false">キャンセル</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getUserDetail, updateUser, updatePassword } from '@/api/user'
import { getRegionList, getZipCodeList } from '@/api/personal'
export default {
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('電話番号をご入力ください'))
      } else {
        if (isNaN(value)) {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else if (value.length < 9 || value.length > 12 || value.substr(0, 1) !== '0') {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else {
          callback()
        }
      }
    }
    var checkZipCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('郵便番号をご入力ください'))
      } else {
        if (isNaN(value) || value.length !== 7) {
          callback(new Error('郵便番号は半角数字7桁で入力してください'))
        } else {
          callback()
        }
      }
    }
    return {
      dialogVisible: false,
      btnLoading: false,
      pwdLoading: false,
      ruleForm: {
        first_name: '',
        first_name_kana: '',
        last_name: '',
        last_name_kana: '',
        receive_phone: '',
        zip_code: '',
        stats: '',
        city: '',
        address: '',
        detail_address: '',
        receiver_email: ''
      },
      passwordForm: {

      },
      areaData: [],
      rules: {
        first_name: { required: true, message: '姓をご入力ください', trigger: ['blur'] },
        last_name: { required: true, message: '名をご入力ください', trigger: ['blur'] },
        receive_phone: [{ required: true, validator: checkPhone, trigger: ['blur'] }],
        zip_code: [{ required: true, validator: checkZipCode, trigger: ['blur'] }],
        stats: { required: true, message: '届け先住所を選択', trigger: ['blur'] },
        city: { required: true, message: '市区町村をご入力ください', trigger: ['blur'] },
        address: { required: true, message: '番地をご入力ください', trigger: ['blur'] },
        receiver_email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスをご入力ください', trigger: ['blur'] }
        ],
        original_password: { required: true, message: '現在のパスワードをご入力ください', trigger: ['blur'] },
        password: { required: true, message: '新しいパスワードをご入力ください', trigger: ['blur'] },
        password_confirmation: { required: true, message: '新しい会パスワードを再入力"', trigger: ['blur'] }
      }
    }
  },
  computed: {

  },
  created() {
    this.getUserDetail()
    this.getRegionList()
  },
  methods: {
    getUserDetail() {
      getUserDetail().then(result => {
        this.ruleForm = result
      })
    },
    getRegionList() {
      getRegionList().then(result => {
        this.areaData = result
      })
    },
    handleChangePassword() {
      this.dialogVisible = true
      this.passwordForm = {}
    },
    changeZipCode(value) {
      getZipCodeList({ zip_code: value }).then(result => {
        this.ruleForm.stats = result.state
        this.ruleForm.city = result.city
        this.ruleForm.address = result.address
      })
    },
    handleUpdatePassword() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.pwdLoading = true
          updatePassword(this.passwordForm).then(() => {
            this.$store.dispatch('user/logout').then(() => {
              this.dialogVisible = false
              this.pwdLoading = false
              this.$router.push('/login')
            })
          }).catch(() => {
            this.pwdLoading = false
          })
        }
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          const data = { ...this.ruleForm }
          updateUser(data).then(() => {
            this.btnLoading = false
            this.$toast('アカウント情報変更完了')
            this.$store.dispatch('user/getUserDetail')
            this.$router.push('/personal/mine')
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content{
    padding: 0 .24rem;
    .bread-crumbs{
      margin-top: .16rem;
      text-align: center;
      font-size: .24rem;
    }
    .b-title{
        margin-top: .56rem;
        font-size: .36rem;
        font-weight: 700;
    }
    .desc{
        margin-top: .24rem;
        padding: .24rem;
        background: #f4f5fc;
        border-radius: .08rem;
        span{
            color: #c40a0a;
            font-size: .32rem;
        }
    }
    .user-content{
        margin-top: .4rem;
        .list{
            display: flex;
            justify-content: space-between;
            padding: .28rem .24rem;
            border: .02rem solid #e8ebed;
            box-sizing: border-box;
            &:last-child{
                margin-top: .08rem;
            }
            .value{
                font-weight: 700;
            }
        }
    }
    .change-pwd{
        margin-top: .24rem;
        color: #999999;
        text-align: right;
        text-decoration: underline;
    }
    .el-form{
        margin-top: .64rem;
        .inline-item{
            display: flex;
            justify-content: space-between;
            .el-input,.el-select{
                width: 3.4rem;
            }
            ::v-deep .el-form-item__content{
                width: 3.4rem;
            }
        }
        .save-btn{
            width: 100%;
            height: 1.08rem;
            margin-top: .8rem;
        }
        .prev-btn{
            width: 100%;
            height: .88rem;
            margin-top: .16rem;
            line-height: .88rem;
            text-align: center;
            border: .02rem solid #333333;
            box-sizing: border-box;
        }
    }
    .el-form-item{
            margin-bottom: .32rem;
    }
    .modal-content{
        text-align: left;
        .title{
            font-size: .36rem;
            font-weight: 700;
        }
        .el-form{
            margin-top: .38rem;
        }
        .confirm-btn{
            width: 100%;
            height: .88rem;
            margin-top: .24rem;
        }
        .cancel-btn{
            width: 100%;
            height: .88rem;
            margin-top: .16rem;
            line-height: .88rem;
            text-align: center;
            border: .02rem solid #333333;
            box-sizing: border-box;
        }
    }
}
</style>
