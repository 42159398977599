<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <router-link to="/article">ニュース</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <span>{{ initData.title }}</span>
    </div>
    <div class="article-title">
      <div>NEW</div>
      <div>ニュース</div>
    </div>
    <div class="article-box">
      <common />
      <div class="article-detail">
        <div class="title">{{ initData.title }}</div>
        <div class="date">{{ initData.created_at.substring(0,10) }}</div>
        <div class="custom" v-html="initData.pc_desc" />
        <div class="operation">
          <div class="return" @click="$router.go(-1)">
            <img src="@/assets/images/pc/leftArrow.png">
            <span>戻る</span>
          </div>
          <div class="share">
            <span>SHARE ON SNS :</span>
            <router-link to="" @mouseenter.native="show = 1" @mouseleave.native="show = false">
              <img v-show="show !== 1" src="@/assets/images/pc/facebook.png">
              <img v-show="show === 1" src="@/assets/images/pc/facebook_current.png">
            </router-link>
            <router-link to="" @mouseenter.native="show = 2" @mouseleave.native="show = false">
              <img v-show="show !== 2" src="@/assets/images/pc/twitter.png">
              <img v-show="show === 2" src="@/assets/images/pc/twitter_current.png">
            </router-link>
            <router-link to="" @mouseenter.native="show = 3" @mouseleave.native="show = false">
              <img v-show="show !== 3" src="@/assets/images/pc/ins.png">
              <img v-show="show === 3" src="@/assets/images/pc/ins_current.png">
            </router-link>
          </div>
        </div>
        <div v-if="initData.products.length>0" class="ttl">関連商品</div>
        <div class="goods">
          <router-link v-for="item in initData.products" :key="item.id" class="goods-item" :to="'/product/'+ item.product_sn">
            <img :src="item.image" :alt="item.image_alt">
            <div class="goods-title">{{ item.title }}</div>
            <div class="desc">{{ item.long_title }}</div>
            <div class="price">¥{{ calcThousands(item.price) }}</div>
          </router-link>
        </div>
        <div v-if="initData.link_article.length>0" class="ttl">関連記事</div>
        <div class="article">
          <router-link v-for="item in initData.link_article" :key="item.id" class="article-item" :to="'/article/'+ item.id">
            <img :src="item.image">
            <div class="article-item-box">
              <div class="article-box-title">{{ item.title }}</div>
              <div class="article-box-desc">{{ item.description }}</div>
              <div class="article-box-date">{{ item.created_at.substring(0,10) }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getArticlesDetail } from '@/api/article'
import common from '@/views/pc/article/common'
import { calcThousands } from '@/utils/validate'
export default {
  components: { common },
  data() {
    return {
      show: false,
      initData: {
        created_at: '',
        products: [],
        link_article: [],
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        pc_desc: null,
        pc_desc_css_path: '',
        pc_desc_js_path: ''
      }
    }
  },
  metaInfo() {
    return {
      title: this.initData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.initData.seo_keywords },
        { vmid: 'description', name: 'description', content: this.initData.seo_description }
      ],
      link: [
        { vmid: this.initData.pc_desc_css_path, rel: 'stylesheet', href: this.initData.pc_desc_css_path }
      ],
      script: [
        { vmid: this.initData.pc_desc_js_path, async: 'async', src: this.initData.pc_desc_js_path }
      ]
    }
  },
  computed: {
    id: function() { return this.$route.params.id }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.fetchData()
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    calcThousands,
    fetchData() {
      getArticlesDetail(this.id, { include: 'linkArticle,products' }).then(result => {
        result.pc_desc_css_path = result.pc_desc_css_path || ''
        result.pc_desc_js_path = result.pc_desc_js_path || ''
        this.initData = result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.breadcrumb{
  width: 1440px;
  line-height: 17px;
  margin: 28px auto 0;
}
.article-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 192px;
  color: #333;
  background: url('~@/assets/images/pc/article_bg.png') center center / auto 100%;
  margin-top: -26px;
  :nth-child(1){
    font-size: 54px;
    font-weight: bold;
  }
  :nth-child(2){
    font-size: 14px;
  }
}
.article-box{
  display: flex;
  justify-content: space-between;
  width: 1440px;
  font-size: 16px;
  color: #333;
  margin: 48px auto;
  .title{
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
  .date{
    font-size: 14px;
    color: #999;
    text-align: right;
    margin: 24px 0 40px;
  }
  .article-detail{
    width: 1098px;
  }
}
.operation{
  display: flex;
  justify-content: space-between;
  margin: 44px 0;
  .return{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 204px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #292e32;
    cursor: pointer;
    img{
      margin-right: 4px;
    }
  }
  .share{
    display: flex;
    align-items: center;
    color: #999;
    a{
      margin-left: 12px;
    }
  }
}
.ttl{
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid #333;
  padding-bottom: 8px;
  margin: 80px 0 32px;
}
.goods{
  display: flex;
  flex-wrap: wrap;
  .goods-item{
    width: 350px;
    margin: 24px 24px 0 0;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3){
      margin-top: 0;
    }
    &:nth-child(3n){
      margin-right: 0;
    }
    img{
      width: 100%;
      height: 350px;
    }
    .goods-title{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 8px 0 0;
    }
    .desc{
      font-size: 12px;
      color: #999;
      margin: 12px 0 0;
    }
    .price{
      font-weight: bold;
      margin: 8px 0 0;
    }
  }
}
.article{
  .article-item{
    display: flex;
    justify-content: space-between;
    margin: 24px 0 0 0;
    img{
      width: 250px;
      height: 166px;
    }
  }
  .article-item-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 832px;
  }
  .article-box-title{
    flex-shrink: 0;
    font-size: 20px;
    font-weight: bold;
  }
  .article-box-desc{
    height: 100%;
    margin: 12px 0;
  }
  .article-box-date{
    flex-shrink: 0;
    font-size: 14px;
    color: #999;
  }
}
.custom{
  width: 700px;
  margin: 0 auto;
 :target {
    scroll-margin-top: 70px;
}
 p{
      line-height: 1.6;
      margin: 40px 0;
  }
.share-content{
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin: 0 auto;
  a{
    width: 48%;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    color: #ffffff;
    text-align: center;
  }
  .facebook{
    background: #3b5998;
    box-shadow: 0 2px #2d4373;
  }
  .line{
    background: #00c300;
    box-shadow: 0 2px #009d00;
  }
}
.top-img{
  display: block;
  width: 700px;
  margin: 40px auto;
}
  .title-content{
    width: 500px;
    padding: 10px;
    margin: 0 auto;
    background: #f9f9f9 none repeat scroll 0 0;
    border: 1px solid #aaa;
    .top-title{
      margin: 30px auto;
      font-size: 18px;
      text-align: center;
      font-weight: 700;
    }
    .list-content{
      color: #2581c4;
      li{
        margin: 6px 0;
        font-size: 14px;
      }
      .little{
        margin-left: 5px;
      }
      a{
        display: block;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .detail-content{
    width: 700px;
    margin: 0 auto;
    h2{
      display: block;
      font-size: 26px;
      margin: 60px 0 50px 0;
      padding: 16px 10px 16px 20px;
      color: #383939;
      background-color: #f8f8f8;
      border-bottom: 3px solid #0063dc;
    }
    h3{
      margin: 40px 0 20px;
      font-size: 24px;
      border-bottom: 2px solid #0063dc;
      padding-bottom: 13px;
    }
  }
  .swiper-list-content{
    .list-content{
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
    }
    .swiper{
      width: 260px;
    }
    .swiper-wrapper{
      width: 260px;
    }
    .swiper-slide{
      width: 260px !important;
      img{
        width: 260px;
        height: 260px;
      }
    }
    .swiper-pagination-bullet-active{
      background: #333333;
    }
    .swiper-button-prev{
      width: 30px;
      height: 60px;
      background: url('https://www.woodlife.jp/storage/custom/articles/ceramic/swiper_prev.png') no-repeat center center;
      left: 25px;
      &::after{
        content:''
      }
    }
    .swiper-button-next{
      width: 30px;
      height: 60px;
      background: url('https://www.woodlife.jp/storage/custom/articles/ceramic/swiper_next.png') no-repeat center center;
      left: 285px;
      &::after{
        content:''
      }
    }
    .right-content{
      width: 360px;
      .product-title{
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 700;
      }
      .product-detail{
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border: 1px solid #cccccc;
        font-size: 12px;
        img{
          width: 80px;
          height: 80px;
        }
        .content{
          width: 230px;
          .desc{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            margin-top: 4px;
            color: #666666;
          }
          .price{
            margin-top: 10px;
            font-size: 20px;
            font-weight: 700;
          }
          .detail-btn{
            display: block;
            margin-top: 20px;
            text-decoration: underline;
            color: #666666;
          }
          .tag{
            position: absolute;
            right: 16px;
            top: 58px;
            padding: 4px 8px;
            background-color: #333333;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
