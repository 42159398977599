var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-content" }, [
    _c("div", { staticClass: "content" }, [
      _c("img", {
        staticClass: "vip",
        attrs: { src: require("@/assets/images/sp/vip.png") },
      }),
      _c("div", { staticClass: "title" }, [_vm._v("会員登録完了。")]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "button-content" },
        [
          _c("router-link", { attrs: { to: "/personal/mine" } }, [
            _c("div", { staticClass: "mine-button" }, [_vm._v("マイページ")]),
          ]),
          _c("router-link", { attrs: { to: "/" } }, [
            _c("div", { staticClass: "home-button" }, [_vm._v("トップページ")]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" この度、ご登録ありがとうございます。"),
      _c("br"),
      _vm._v(" それではショッピングをお楽しみください！ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }