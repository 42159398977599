import request from '@/utils/request'

// 获取价格明细
export function getPriceShow(params) {
  return request({
    url: '/priceShow',
    method: 'get',
    params
  })
}
// 去支付页
export function addressSave(data) {
  return request({
    url: '/addressSave',
    method: 'post',
    data
  })
}
// 获取下单信息
export function getShoppingDetail(params) {
  return request({
    url: '/shoppingShow',
    method: 'get',
    params
  })
}
// 支付
export function orderPayment(data) {
  return request({
    url: '/checkoutSave',
    method: 'post',
    data
  })
}
// paypay支付
export function sendPaypay(data) {
  return request({
    url: '/shopping/send/paypay',
    method: 'post',
    data
  })
}
// paypay支付
export function sendRakutenPay(data) {
  return request({
    url: '/shopping/send/rakutenpay',
    method: 'post',
    data
  })
}
// 支付成功获取订单详情
export function getOrderDetail() {
  return request({
    url: '/complete',
    method: 'get'
  })
}
// 根据订单号获取订单详情
export function getOrderSnDetail(orderSn, params) {
  return request({
    url: '/orders/showByOrderSn/' + orderSn,
    method: 'get',
    params
  })
}
// 使用优惠券、 积分
export function useDiscount(data) {
  return request({
    url: '/paymentSave',
    method: 'post',
    data
  })
}
