<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/personal/order">注文履歴</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>レビューを書く</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/order" />
      </div>
      <div class="right-content">
        <div class="product-content">
          <div v-for="item in initData.order_products" :key="item.id" class="item">
            <div class="product">
              <div class="img">
                <img :src="item.product_img">
              </div>
              <div class="desc">
                <div class="name">{{ item.product_title }}</div>
                <div class="sku">
                  {{ item.product_sku_display.join(';') }} {{ item.product_spu_display.join(';') }}
                </div>
                <div class="star">
                  <van-rate v-model="item.stars" :size="28" color="#fccf52" void-icon="star" void-color="#e3e4eb" />
                  <span class="value">{{ item.stars }}</span>
                </div>
              </div>
            </div>
            <div class="image-content">
              <van-uploader
                v-model="item.fileList"
                multiple
                :after-read="afterRead"
                :max-count="4"
                :before-delete="beforeDelete"
                :max-size="1024 * 1024"
                @oversize="onOversize"
              >
                <div class="upload-btn">
                  <div class="notice">
                    <img src="@/assets/images/pc/upload_icon.png" alt="">
                    <p>写真を追加する({{ item.fileList.length }}/4)</p>
                  </div>
                </div>
              </van-uploader>
            </div>
            <el-input v-model="item.content" type="textarea" maxlength="500" placeholder="商品のレビューを書く（最大500文字）" />
          </div>
        </div>
        <el-button type="primary" class="button" :loading="btnLoading" @click="onSubmit">送信する</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { getMyOrderDetail, addComments } from '@/api/personal'
import { uploadImage } from '@/api/upload'
import SideBar from './component/sideBar'
export default {
  components: { SideBar },
  data() {
    return {
      btnLoading: false,
      id: this.$route.params.id,
      index: 0,
      initData: {}
    }
  },
  computed: {

  },
  created() {
    this.getMyOrderDetail()
  },
  methods: {
    getMyOrderDetail() {
      getMyOrderDetail(this.id, { include: 'orderProducts' }).then(result => {
        this.initData = result
        this.initData.order_products.forEach(item => {
          this.$set(item, 'stars', 5)
          this.$set(item, 'fileList', [])
          this.$set(item, 'imageList', [])
        })
      })
    },
    afterRead(content) {
      if (_.isArray(content)) { // 同时传多个
        content.forEach(item => {
          this.upload(item)
        })
      } else {
        this.upload(content)
      }
    },
    upload(content) {
      content.index = this.index
      this.index++
      const formData = new FormData()
      formData.append('image', content.file)
      formData.append('type', 'comment')
      uploadImage(formData).then(result => {
        this.initData.order_products.forEach(item => {
          item.fileList.forEach(item2 => {
            if (item2.index === content.index) {
              item.imageList.push({
                url: result.path_url,
                index: content.index
              })
            }
          })
        })
      }).catch(() => {
        this.initData.order_products.forEach(item => {
          item.fileList.forEach(item2 => {
            if (item2.index === content.index) {
              item.fileList = [...item.imageList]
            }
          })
        })
      })
    },
    beforeDelete(content, detail) {
      this.initData.order_products.forEach(item => {
        item.fileList.forEach(item2 => {
          if (item2.index === content.index) {
            item.imageList.splice(detail.index, 1)
          }
        })
      })
      return true
    },
    onOversize() {
      this.$toast('ファイルサイズは1mbまで')
    },
    onSubmit() {
      const data = {
        order_id: this.id,
        comments: []
      }
      this.initData.order_products.forEach(item => {
        data.comments.push({
          product_id: item.product_id,
          product_attribute: item.product_sku_display.toString(),
          product_name: item.product_title,
          product_spu_display: item.product_spu_display.join(';'),
          stars: item.stars,
          images: item.imageList,
          content: item.content
        })
      })
      data.comments.forEach(item => {
        const arr = []
        item.images.forEach(item2 => {
          arr.push(item2.url)
        })
        item.images = [...arr]
      })
      this.btnLoading = true
      addComments(data).then(() => {
        this.btnLoading = false
        this.$router.go(-1)
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    font-size: 16px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        .product-content{
            .item{
                padding: 24px 40px;
                border: 1px solid #e8ebed;
                &:not(:last-child){
                    margin-bottom: 22px;
                }
                .product{
                    display: flex;
                    .img{
                        width: 104px;
                        height: 104px;
                        box-sizing: border-box;
                        border: 1px solid #e8ebed;
                        img{
                          width: 102px;
                          height: 102px;
                          object-fit: cover;
                        }
                    }
                    .desc{
                        margin-left: 16px;
                        .name{
                            margin-top: 4px;
                            font-weight: 700;
                        }
                        .sku{
                            margin-top: 12px;
                            font-size: 14px;
                        }
                        .star{
                            margin-top: 16px;
                            .value{
                                margin-left: 8px;
                                font-size: 20px;
                                font-weight: 700;
                                color: #FCCF52;
                            }
                        }
                    }
                }
                .image-content{
                  margin-top: 24px;
                  .upload-btn{
                    display: flex;
                    align-items: center;
                    width: 160px;
                    height: 160px;
                    text-align: center;
                    font-size: 14px;
                    background: url('~@/assets/images/pc/upload_bg.png') no-repeat center center;
                    .notice{
                      width: 100%;
                    }
                    img{
                      width: 24px;
                      height: 24px;
                    }
                  }
                  ::v-deep .van-uploader{
                    .van-uploader__preview{
                      margin: 4px 12px 0 0;
                      .van-uploader__preview-image{
                        width: 156px;
                        height: 156px;
                      }
                      .van-uploader__preview-delete{
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        top: -4px;
                        right: -4px;
                      }
                      .van-uploader__preview-delete-icon{
                        top: 5px;
                        right: 5px;
                        font-size: 14px;
                        font-weight: 700;
                        transform: scale(1);
                      }
                    }
                  }
                }
                ::v-deep .el-textarea__inner{
                    width: 100%;
                    height: 120px;
                    margin-top: 16px;
                    border-color: #e8ebed;
                }
            }
        }
        .button{
            display: block;
            width: 204px;
            height: 48px;
            margin: 52px auto 0;
            cursor: pointer;
        }
    }
}
</style>
