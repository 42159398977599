var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/pc/breadcrumb_arrow.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("マイページ")]),
      ],
      1
    ),
    _c("div", { staticClass: "personal-content" }, [
      _c(
        "div",
        { staticClass: "left-content" },
        [_c("SideBar", { attrs: { path: "/personal/mine" } })],
        1
      ),
      _c("div", { staticClass: "right-content" }, [
        _c("div", { staticClass: "swiper personal-swiper" }, [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.swiperData, function (item) {
              return _c("div", { key: item.id, staticClass: "swiper-slide" }, [
                item.href
                  ? _c("a", { attrs: { href: item.href } }, [
                      _c("img", { attrs: { src: item.image, alt: item.alt } }),
                    ])
                  : _c("img", { attrs: { src: item.image, alt: item.alt } }),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "swiper-pagination" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }