var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c("div", { staticClass: "username" }, [_vm._v(_vm._s(_vm.username))]),
    _c(
      "div",
      { staticClass: "list-content" },
      [
        _vm._l(_vm.sidebarOptions, function (item, index) {
          return _c("router-link", { key: index, attrs: { to: item.path } }, [
            _c("div", { staticClass: "list" }, [
              _c("div", { class: { current: _vm.path === item.path } }, [
                _c("img", { attrs: { src: item.img } }),
                _c("span", [_vm._v(_vm._s(item.name))]),
              ]),
            ]),
          ])
        }),
        _c("div", { staticClass: "list", on: { click: _vm.handleLogout } }, [
          _vm._m(0),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/personal_logout.png") },
      }),
      _c("span", [_vm._v("ログアウト")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }