<template>
  <div v-if="initData.length" class="record-content">
    <div class="title">閲覧履歴</div>
    <div class="swiper popularity-swiper">
      <div class="swiper-wrapper">
        <router-link v-for="item in initData" :key="item.id" :to="`/product/${item.product_sn}`" class="swiper-slide">
          <img :src="item.image" :alt="item.image_alt">
          <div class="product-title">{{ item.title }}</div>
          <div class="product-price">¥{{ calcThousands(item.price) }}円</div>
        </router-link>
      </div>
      <div class="swiper-pagination" />
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" />
    </div>
  </div>
</template>
<script>
import { getHistoryProductList } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
export default {
  components: { },
  data() {
    return {
      initData: [],
      calcThousands
    }
  },
  computed: {

  },
  mounted() {
    this.getHistoryProductList()
    // this.swiper()
  },
  created() {

  },
  methods: {
    getHistoryProductList() {
      getHistoryProductList({ page: 1, limit: 20 }).then(result => {
        this.initData = result.data
        if (this.initData.length) {
          this.$nextTick(() => {
            this.swiper()
          })
        }
      })
    },
    // 轮播
    swiper() {
      new window.Swiper('.popularity-swiper', {
        slidesPerView: 5,
        spaceBetween: 16,
        pagination: {
          type: 'progressbar',
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.record-content{
    margin-top: 40px;
    .title{
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
        margin-bottom: 16px;
        font-size: 20px;
        border-bottom: 2px solid #333;
    }
    .popularity-swiper ::v-deep{
        width: 100%;
        padding: 0 0 80px;
        .swiper-slide{
        width: 207px;
        }
        img{
        width: 100%;
        height: 207px;
        }
        .product-title{
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 8px;
        }
        .product-price{
        margin-top: 8px;
        font-weight: bold;
        }
        .swiper-pagination{
        top: auto;
        bottom: 23px;
        width: 946px;
        background: #E8EBED;
        }
        .swiper-pagination-progressbar-fill{
        background: #333;
        }
        .swiper-button-prev{
        top: auto;
        left: auto;
        right: 72px;
        bottom: 0;
        margin: 0;
        }
        .swiper-button-prev{
        top: auto;
        left: auto;
        right: 72px;
        bottom: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid #E8EBED;
        background: url('~@/assets/images/pc/arrow-left.png') center center;
        margin: 0;
        &::after{
            content: '';
        }
        }
        .swiper-button-next{
        top: auto;
        right: 0;
        bottom: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid #E8EBED;
        background: url('~@/assets/images/pc/arrow-right.png') center center;
        margin: 0;
        &::after{
            content: '';
        }
        }
        .swiper-pagination-lock{
        display: block;
        }
        .swiper-button-lock{
        display: flex;
        }
    }
}
</style>
