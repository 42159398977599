var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "password-content" }, [
    _c("div", { staticClass: "bg" }, [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v("家具通販専門店TOP"),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/breadcrumb_arrow.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("パスワードをお忘れの方")]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "right-form" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("パスワードをお忘れの方")]),
        _vm._m(0),
        _c(
          "el-form",
          { ref: "ruleForm", attrs: { model: _vm.ruleForm, rules: _vm.rules } },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "email", label: "" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "メールアドレス（会員ID）※" },
                  model: {
                    value: _vm.ruleForm.email,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "email",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.email",
                  },
                }),
              ],
              1
            ),
            _c("el-form-item", { attrs: { prop: "", label: "" } }, [
              _c(
                "div",
                { staticClass: "button", on: { click: _vm.handleRegister } },
                [
                  _c("span", [_vm._v("送信する")]),
                  _c("div", { staticClass: "arrow" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/pc/button_arrow.png"),
                        alt: "",
                      },
                    }),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v("パスワード再設定用URLをお送りいたします。"),
      _c("br"),
      _vm._v("ご登録のメールアドレスをご入力ください。"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }