import request from '@/utils/request'
/**
 * 商品api
 */

// 获取商品列表
export function productList(params) {
  return request({
    url: '/products',
    method: 'get',
    params
  })
}

// product_sn 获取商品详情
export function productDetail(product_sn, params) {
  return request({
    url: '/products/' + product_sn,
    method: 'get',
    params
  })
}
// 获取商品分类层级
export function getProductCrumb(product_sn, params) {
  return request({
    url: '/products/' + product_sn + '/crumbs',
    method: 'get',
    params
  })
}
// 获取商品评论
export function producComments(product_id, params) {
  return request({
    url: '/products/' + product_id + '/comments',
    method: 'get',
    params
  })
}

// 获取推荐商品
export function recommendProduct(params) {
  return request({
    url: '/recommendProducts',
    method: 'get',
    params
  })
}

// 加入购物车
export function addCart(data) {
  return request({
    url: '/carts',
    method: 'post',
    data
  })
}

// 商品收藏
export function productCollect(id, data) {
  return request({
    url: '/userCollects/' + id,
    method: 'patch',
    data
  })
}
