<template>
  <div class="password-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>パスワードをお忘れの方</span>
    </div>
    <div class="content">
      <div class="title">パスワードをお忘れの方</div>
      <div class="text">
        再設定メールを{{ email }}まで送信済みです、<br>
        1時間以内にパスワードの再設定を行ってください。
      </div>
      <router-link to="/">
        <div class="button">トップページ</div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: this.$route.query.email
    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.password-content{
    width: 1440px;
    margin: 0 auto 80px;
    .breadcrumb{
        margin-top: 28px;
    }
    .content{
        margin-top: 46px;
        text-align: center;
        .title{
            font-size: 28px;
            font-weight: 700;
            color: #4bc4b0;
        }
        .text{
            margin-top: 12px;
            line-height: 24px;
            font-size: 16px;
        }
        .button{
            width: 204px;
            height: 48px;
            margin: 32px auto 0;
            line-height: 48px;
            text-align: center;
            background-color: #333;
            color: #fff;
            font-size: 16px;
        }
    }
}
</style>
