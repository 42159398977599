<template>
  <div id="order-content" class="order-content">
    <router-link to="/">
      <img src="@/assets/images/sp/logo_sp.png" class="logo">
    </router-link>
    <img src="@/assets/images/sp/step2.png" class="step">
    <div class="price-detail">
      <div class="flex" @click="detailShow = !detailShow">
        <div class="name">金額明細</div>
        <img src="@/assets/images/sp/right_arrow1.png" class="icon" :class="{'flip':detailShow}">
      </div>
      <div v-show="detailShow" class="content">
        <div class="item">
          <div class="name">小計</div>
          <div class="value">￥{{ calcThousands(priceParams.total_amount || 0) }}</div>
        </div>
        <div class="item">
          <div class="name">送料</div>
          <div class="value">+￥{{ calcThousands(priceParams.freight_amount || 0) }}</div>
        </div>
        <div class="item">
          <div class="name">クーポン</div>
          <div class="value">-￥{{ calcThousands(priceParams.use_point) }}</div>
        </div>
        <div class="item">
          <div class="name">利用ポイント</div>
          <div class="value">-￥{{ calcThousands(priceParams.coupon_amount || 0) }}</div>
        </div>
        <div class="item">
          <div class="name">合計金額</div>
          <div class="value">￥{{ calcThousands(priceParams.pay_amount || 0) }}</div>
        </div>
      </div>
    </div>
    <div class="message-content">
      <div class="list">
        <div class="box">
          <div class="name">連絡先</div>
          <div class="value">{{ initData.receiver_email }}</div>
        </div>
        <router-link to="/order/address">
          <div class="change-btn">変更</div>
        </router-link>
      </div>
      <div class="list">
        <div class="box">
          <div class="name">配送先</div>
          <div class="value">
            {{ initData.first_name+initData.last_name }} 様<br>
            〒{{ initData.zip_code }}<br>
            {{ initData.stats }}{{ initData.city }}{{ initData.address }}{{ initData.detail_address ? initData.detail_address : '' }}<br>
            {{ initData.phone }}
          </div>
        </div>
        <router-link to="/order/address?href=address-content">
          <div class="change-btn">変更</div>
        </router-link>
      </div>
      <div class="list">
        <div class="box">
          <div class="name">配送方法</div>
          <div class="value">通常出荷</div>
        </div>
        <div class="price">¥{{ calcThousands(priceParams.freight_amount || 0) }}</div>
      </div>
      <div class="list">
        <div class="box">
          <div class="name">備考欄</div>
          <div v-if="initData.note" class="value">{{ initData.note }}</div>
        </div>
        <router-link to="/order/address?href=note-content">
          <div class="change-btn">変更</div>
        </router-link>
      </div>
    </div>
    <div v-if="token" class="discount-content">
      <div class="title">ご利用可能なクーポン</div>
      <div class="desc">ご利用可能なクーポン：<span>{{ couponData.length }}枚</span></div>
      <div class="radio-content">
        <div class="item">
          <el-radio v-model="useCoupon" :label="false" class="radio" @change="handleCoupon">
            <span>クーポンを利用しない</span>
          </el-radio>
        </div>
        <div class="item">
          <el-radio v-model="useCoupon" :label="true" class="radio" @change="handleCoupon">
            <span>クーポンを選択する</span>
          </el-radio>
          <div v-show="useCoupon" class="discount-detail">
            <div class="input-content">
              <div class="name">クーポンを登録する</div>
              <div class="box">
                <el-input v-model.trim="code" placeholder="クーポンコードを入力する" />
                <el-button type="primary" :loading="codeLoading" @click="handleGetCoupon">登録する</el-button>
              </div>
            </div>
            <div v-if="couponData.length" class="coupon-content">
              <el-radio-group v-model="couponValue" @change="getPriceShow">
                <div v-for="(item,index) in couponData" :key="index" class="list">
                  <el-radio :label="item.id">
                    <div>{{ item.coupon.name }}</div>
                    <div class="time">【有効期限】{{ item.coupon.end_time }}</div>
                  </el-radio>
                </div>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="token" class="discount-content">
      <div class="title">ポイントのご利用</div>
      <div class="desc">現在ポイント残高：<span>{{ totalPoint }} pt</span></div>
      <div class="radio-content">
        <div class="item">
          <el-radio v-model="usePoint" :label="0" class="radio" @change="handleNoPoint">
            <span>ポイントを利用しない</span>
          </el-radio>
        </div>
        <div class="item">
          <el-radio v-model="usePoint" :label="1" class="radio" @change="getPriceShow">
            <span>利用可能なポイントをすべて利用する：<b>{{ totalPoint }}</b>（￥{{ calcThousands(totalPointFee) }}相当）</span>
          </el-radio>
        </div>
        <div class="item">
          <el-radio v-model="usePoint" :label="2" class="radio" @change="getPriceShow">
            <span>一部のポイントを利用する</span>
          </el-radio>
          <div class="discount-detail">
            <div class="point-input">
              <el-input v-model.number="pointValue" placeholder="ポイントを" @focus="handleFocus" @input="changePoint" />
              <span>pt を利用する</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-content">
      <div class="title">お支払い方法</div>
      <div class="desc">入力された情報はすべて暗号化され、安全に転送されます。</div>
      <div class="radio-content">
        <div class="item">
          <el-radio v-model="ruleForm.pay_type" :label="3">
            <span>クレジットカード決済</span>
          </el-radio>
          <div v-show="ruleForm.pay_type === 3" class="payment-desc">
            <img src="@/assets/images/sp/payment_card.png" class="payment-card">
            <el-form ref="blackcatForm" :model="blackcatForm" label-position="top" :rules="rules">
              <el-form-item prop="credit_email" label="メールアドレス">
                <el-input v-model="blackcatForm.credit_email" placeholder="メールアドレス" />
              </el-form-item>
              <el-form-item prop="credit_number" label="カード番号">
                <el-input v-model="blackcatForm.credit_number" placeholder="カード番号" />
                <span class="input-icon">
                  <svg-icon icon-class="lock" />
                </span>
              </el-form-item>
              <el-form-item prop="credit_username" label="カード名義人">
                <el-input v-model.trim="blackcatForm.credit_username" placeholder="カード名義人" />
              </el-form-item>
              <div class="flex">
                <el-form-item prop="date" label="MM/YY" style="width:2.56rem">
                  <el-input v-model="blackcatForm.date" placeholder="MM/YY" />
                </el-form-item>
                <el-form-item prop="credit_security_code" label="セキュリティコード" style="width: 3.76rem">
                  <el-input v-model="blackcatForm.credit_security_code" placeholder="セキュリティコード" />
                  <span class="input-icon">
                    <svg-icon icon-class="question" />
                  </span>
                </el-form-item>
              </div>
              <el-form-item prop="credit_count" label="お支払い回数">
                <el-select v-model="blackcatForm.credit_count" placeholder="お支払い回数">
                  <el-option v-for="item in payCount" :key="item" :label="item" :value="item" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-if="payBank" class="item bank">
          <el-radio v-model="ruleForm.pay_type" :label="9">
            <img src="@/assets/images/pc/rakuten.png" alt="" class="rakuten-icon">
            <span class="pay-name">楽天ペイ</span>
          </el-radio>
          <div v-show="ruleForm.pay_type === 9" class="payment-desc">
            <div class="rakuten-content">
              <img src="@/assets/images/pc/rakuten_pay_icon.png" alt="" class="rakuten-icon">
              <div class="text">
                いつもの楽天会員IDとパスワードを使ってスムーズなお支払いが可能です。<br>
                楽天ポイントが貯まる・使える！「簡単」「あんしん」「お得」な楽天ペイをご利用ください。<br>
                ※楽天ポイントが貯まるのは楽天カード・楽天ポイント・楽天キャッシュでのお支払いに限ります。
              </div>
            </div>
          </div>
        </div>
        <div class="item amazon">
          <el-radio v-model="ruleForm.pay_type" :label="4">
            <img src="@/assets/images/sp/amazon.png" class="amazon-icon">
          </el-radio>
          <div v-show="ruleForm.pay_type === 4" class="payment-desc">
            <div v-if="amazonToken">
              <!-- {显示亚马逊地址簿-----开始 -->
              <div id="addressBookWidgetDiv" style="width: 100%;height: 6rem;" />
              <!-- --显示亚马逊地址簿-----结束-- -->
              <!-- 显示亚马逊支付方式-----开始 -->
              <div id="walletWidgetDiv" style="width: 100%;height: 6rem;margin-top:.2rem;" />
              <!-- 显示亚马逊支付方式-----结束 -->
            </div>
            <!-- 亚马逊按钮 -->
            <div id="AmazonPayButton" />
            <div class="text">
              Amazonに登録された配送先情報やクレジットカード情報を利用してお買い物できます。<br><br>
              ボタンをクリックして、Amazonのユーザー名とパスワードでログインする必要がございます。<br><br>
              登録ボダンが表示されない場合ば、ページを再読み込みしてください。
            </div>
          </div>
        </div>
        <div class="item paypay">
          <el-radio v-model="ruleForm.pay_type" :label="8">
            <img src="@/assets/images/sp/paypay.png" class="paypay-icon">
          </el-radio>
          <div v-show="ruleForm.pay_type === 8" class="payment-desc">
            <el-form ref="paypayForm" :model="paypayForm" label-position="top" :rules="rules">
              <el-form-item prop="buyer_name_kanji" label="お名前">
                <el-input v-model="paypayForm.buyer_name_kanji" placeholder="お名前" />
              </el-form-item>
              <el-form-item prop="buyer_tel" label="電話番号">
                <el-input v-model.trim="paypayForm.buyer_tel" placeholder="電話番号" />
              </el-form-item>
              <el-form-item label="メールアドレス" prop="buyer_email">
                <el-input v-model.trim="paypayForm.buyer_email" placeholder="メールアドレスをご入力ください" />
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-if="payBank" class="item bank">
          <el-radio v-model="ruleForm.pay_type" :label="2">
            <span>銀行振込・前払い</span>
          </el-radio>
          <div v-show="ruleForm.pay_type === 2" class="payment-desc">
            <div class="text">
              ご注文後<span style="color"> 7営業日以内 </span>お振込みくださいませ。<br>
              ※振込み手数料はお客様ご負担となります。<br><br>
              ご入金が確認でき次第商品手配とさせていただきます。
            </div>
            <div class="table-content">
              <table class="table">
                <tr>
                  <td>銀行名</td>
                  <td>三井住友銀行</td>
                </tr>
                <tr>
                  <td>支店名</td>
                  <td>船橋支店</td>
                </tr>
                <tr>
                  <td>口座番号</td>
                  <td>7154834（普）</td>
                </tr>
                <tr>
                  <td>口座名義</td>
                  <td>ハンコプレミアム（カ</td>
                </tr>
                <tr>
                  <td>振込み手数料</td>
                  <td>お客様ご負担</td>
                </tr>
                <tr>
                  <td>お支払期限</td>
                  <td>ご注文から7営業日まで</td>
                </tr>
              </table>
              <table class="table">
                <tr>
                  <td>銀行名</td>
                  <td>ゆうちょ銀行</td>
                </tr>
                <tr>
                  <td>記号</td>
                  <td>10580</td>
                </tr>
                <tr>
                  <td>番号</td>
                  <td>7154834（普）</td>
                </tr>
                <tr>
                  <td>口座名義</td>
                  <td>ハンコプレミアム（カ</td>
                </tr>
                <tr>
                  <td>振込み手数料</td>
                  <td>お客様ご負担</td>
                </tr>
                <tr>
                  <td>お支払期限</td>
                  <td>ご注文から7営業日まで</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-content">
      <el-button type="primary" class="next-btn" @click="handleConfirmShow">ご注文を確定する</el-button>
      <router-link to="/order/address">
        <div class="prev-btn">配送に戻る</div>
      </router-link>
    </div>
    <van-overlay :show="confirmVisible">
      <div class="wrapper" @click.stop>
        <div class="modal-content">
          <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="confirmVisible = false">
          <div class="title">ご注文確定前のお願い</div>
          <div class="text1">▼搬入経路は問題ございませんか？▼</div>
          <div class="text2">※搬入か可能か事前にご確認をお願い致します。</div>
          <div class="text3">ご注文確定後の変更・キャンセルはできませんので、よくご確認の上ご注文をお願いいたします。</div>
          <div class="text4">
            同意する(Yes)をクリックすることで<br>
            <router-link to="/privacy">プライバシーポリシー</router-link>に同意したことになります。
          </div>
          <el-button type="primary" class="confirm" :loading="btnLoading" @click="handleConfirmPay()">同意する（Yes）</el-button>
          <div class="cancel" @click="confirmVisible = false">カートに戻る</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getShoppingDetail, getPriceShow, orderPayment, sendPaypay, sendRakutenPay, useDiscount } from '@/api/order'
import { getMyPoint } from '@/api/personal'
import { getOrderCouponList, getCodeCoupon } from '@/api/coupon'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('電話番号をご入力ください'))
      } else {
        if (isNaN(value)) {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else if (value.length < 9 || value.length > 12 || value.substr(0, 1) !== '0') {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else {
          callback()
        }
      }
    }
    return {
      detailShow: false,
      btnLoading: false,
      codeLoading: false,
      confirmVisible: false,
      payBank: true,
      initData: {},
      priceParams: {
        freight_amount: 0,
        total_amount: 0,
        pay_amount: 0
      },
      ruleForm: {
        pay_type: ''
      },
      code: '',
      pointValue: 0,
      couponValue: '',
      discountForm: {
        coupon_for_user_id: '',
        use_point: 0
      },
      totalPoint: 0,
      totalPointFee: 0, // 积分最高可抵用金额
      useCoupon: false,
      usePoint: 0,
      couponData: [],
      payCount: [1, 2, 3, 4, 5, 6],
      amazonToken: this.$route.query && this.$route.query.access_token,
      blackcatForm: {
        credit_email: '',
        credit_count: 1,
        credit_username: '',
        credit_security_code: ''
      },
      paypayForm: {
        buyer_name_kanji: '',
        buyer_tel: '',
        buyer_email: ''
      },
      rules: {
        credit_email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ],
        credit_number: { required: true, message: 'カード番号をご入力ください', trigger: ['blur'] },
        credit_username: { required: true, message: 'カード名義人をご入力ください', trigger: ['blur'] },
        date: { required: true, message: 'MM/YYをご入力ください', trigger: ['blur'] },
        credit_security_code: { required: true, message: 'セキュリティコードをご入力ください', trigger: ['blur'] },
        credit_count: { required: true, message: 'お支払い回数をご選択ください', trigger: ['change'] },
        buyer_name_kanji: { required: true, message: 'お名前をご入力ください', trigger: ['blur'] },
        buyer_tel: { required: true, validator: checkPhone, trigger: ['blur'] },
        buyer_email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ]
      },
      calcThousands
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  created() {
    this.getShoppingDetail()
    this.getPriceShow()
    if (this.token) {
      this.getOrderCouponList()
      this.getMyPoint()
    }
  },
  mounted() {
    this.$nextTick(_ => {
      const head = document.head || document.getElementsByTagName('head')[0]
      const blackcatScript = document.createElement('script')
      const amazonScript = document.createElement('script')
      blackcatScript.src = process.env.VUE_APP_BLACKCAT
      amazonScript.src = process.env.VUE_APP_AMAZON
      head.appendChild(blackcatScript)
      head.appendChild(amazonScript)
    })
    window.onAmazonLoginReady = () => {
      /* eslint-disable */
      amazon.Login.setClientId(process.env.VUE_APP_AMAZON_CLIENT_ID)
      /* eslint-enable */
    }
    window.onAmazonPaymentsReady = () => {
      this.showButton()
    }
    if (this.amazonToken) {
      this.ruleForm.pay_type = 4
      this.ruleForm.amazon_access_token = this.amazonToken
      window.onAmazonPaymentsReady = () => {
      /* eslint-disable */
        new OffAmazonPayments.Widgets.AddressBook({
          /* eslint-enable */
          sellerId: process.env.VUE_APP_AMAZON_MERCHANT_ID,
          onOrderReferenceCreate: (orderReference) => {
            this.ruleForm.reference_id = orderReference.getAmazonOrderReferenceId()
          },
          onAddressSelect: (orderReference) => {
            this.showWallet()
          },
          design: {
            designMode: 'responsive'
          },
          onReady: (orderReference) => {},
          onError: () => {

          }
        }).bind('addressBookWidgetDiv')
      }
    }
  },
  methods: {
    showWallet() {
      /* eslint-disable */
      new OffAmazonPayments.Widgets.Wallet({
        /* eslint-enable */
        sellerId: process.env.VUE_APP_AMAZON_MERCHANT_ID,
        onPaymentSelect: (orderReference) => {},
        design: {
          designMode: 'responsive'
        },

        onError: () => {

        }
      }).bind('walletWidgetDiv')
    },
    getShoppingDetail() {
      getShoppingDetail().then(result => {
        this.initData = result
        this.blackcatForm.credit_email = result.receiver_email
      })
    },
    getPriceShow() {
      if (this.usePoint === 1) {
        this.discountForm.use_point = this.totalPoint
        getPriceShow(this.discountForm).then(result => {
          this.priceParams = result
        })
      } else if (this.usePoint === 2) {
        this.discountForm.use_point = this.pointValue
      }
      if (this.useCoupon) {
        this.discountForm.coupon_for_user_id = this.couponValue
      }
      getPriceShow(this.discountForm).then(result => {
        this.priceParams = result
      })
    },
    getMyPoint() {
      getMyPoint().then(result => {
        this.totalPoint = result.totalPoint
        this.totalPointFee = result.totalPointFee
      })
    },
    getOrderCouponList() {
      getOrderCouponList().then(result => {
        this.couponData = result.data
      })
    },
    handleNoPoint() {
      this.discountForm.use_point = 0
      this.getPriceShow()
    },
    handleCoupon(value) {
      if (value) {
        this.discountForm.coupon_for_user_id = this.couponValue
      } else {
        this.discountForm.coupon_for_user_id = ''
      }
      this.getPriceShow()
    },
    handleGetCoupon() {
      if (this.code) {
        this.codeLoading = true
        getCodeCoupon({ code: this.code, is_order: true }).then(result => {
          this.codeLoading = false
          this.$toast('クーポンをGETしました！')
          if (result.is_useful) {
            this.discountForm.coupon_for_user_id = result.id
            this.couponValue = result.id
            this.getOrderCouponList()
            this.getPriceShow()
          }
        }).catch(() => {
          this.codeLoading = false
        })
      } else {
        this.$toast('クーポンコードを入力ください')
      }
    },
    handleFocus() {
      this.usePoint = 2
    },
    changePoint() {
      if (this.pointValue > this.totalPoint) {
        this.pointValue = this.totalPoint
      }
      if (this.pointValue === '' || this.pointValue < 0 || isNaN(this.pointValue)) {
        this.pointValue = 0
      }
      this.discountForm.use_point = this.pointValue
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.getPriceShow()
      }, 500)
    },
    handleConfirmShow() {
      if (this.useCoupon && !this.discountForm.coupon_for_user_id) {
        this.$toast('クーポンを選択ください')
        return false
      }
      if (this.ruleForm.pay_type === '') {
        this.$toast('お支払い方法')
      } else if (this.ruleForm.pay_type === 3) {
        this.$refs.blackcatForm.validate((valid) => {
          if (valid) {
            this.confirmVisible = true
          }
        })
      } else if (this.ruleForm.pay_type === 8) {
        this.$refs.paypayForm.validate((valid) => {
          if (valid) {
            this.confirmVisible = true
          }
        })
      } else {
        this.confirmVisible = true
      }
    },
    showButton() {
      /* eslint-disable */
      var authRequest
      OffAmazonPayments.Button('AmazonPayButton', process.env.VUE_APP_AMAZON_MERCHANT_ID, {
        type: 'PwA',
        color: 'Dark gray',
        size: 'large',
        authorization() {
          const loginOptions = {
            scope: 'profile profile:user_id postal_code payments:widget payments:shipping_address',
            popup: true
          }
          authRequest = amazon.Login.authorize(loginOptions, window.location.href)
        },
        onError: () =>{
          // 登录失败的处理
          // console.log(error)
        }
      })
          /* eslint-enable */
    },
    callbackSuccess(result) { // コールバック関数（「正常」の場合）
      this.ruleForm.credit_token = result.token
      this.ruleForm.credit_count = this.blackcatForm.credit_count
      this.orderPayment()
    },
    callbackFailure(result) { // コールバック関数（「異常」の場合）
      this.btnLoading = false
      // エラー情報を取得
      var errorInfo = result.errorInfo
      // エラーの数だけ処理を繰り返す
      for (var i = 0; i < errorInfo.length; i++) {
        // メッセージを alert で出力
        this.$toast(errorInfo[i].errorCode + ' : ' + errorInfo[i].errorMsg)
      }
    },
    orderPayment() {
      const data = { ...this.ruleForm }
      if (this.priceParams.pay_amount === 0) {
        data.pay_type = 99
      }
      orderPayment(data).then(result => {
        this.btnLoading = false
        this.confirmVisible = false
        if (this.ruleForm.pay_type === 3) {
          const body = document.body || document.getElementsByTagName('body')[0]
          const div = document.createElement('div')
          div.innerHTML = result.html
          body.appendChild(div)
          document.forms['submitForm'].submit()
        } else {
          this.$router.push('/order/complete')
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handlePaypay() {
      sendPaypay(this.paypayForm).then(result => {
        this.btnLoading = false
        this.confirmVisible = false
        const body = document.body || document.getElementsByTagName('body')[0]
        const div = document.createElement('div')
        div.innerHTML = result
        body.appendChild(div)
        document.forms['paypaySettleForm'].submit()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handleRakutenPay() {
      sendRakutenPay().then(result => {
        this.btnLoading = false
        const chargeId = result.charge_id
        if (chargeId) {
          this.confirmVisible = false
          window.location.href = `${process.env.VUE_APP_RAKUTEN_PAY}?charge_id=${chargeId}`
        } else {
          this.$toast('支払いに失敗しました。後ほど、もう一度お試しください。')
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handleConfirmPay() {
      this.btnLoading = true
      useDiscount(this.discountForm).then(() => {
        switch (this.ruleForm.pay_type) {
          case 2: // 银行
            this.orderPayment()
            break
          case 3: // 黑猫
            var createTokenInfo = {
              traderCode: process.env.VUE_APP_BLACKCAT_CREDIT_CODE,
              authDiv: '3',
              optServDiv: '00',
              memberId: '',
              authKey: '',
              checkSum: process.env.VUE_APP_BLACKCAT_CHECK_SUM,
              cardNo: this.blackcatForm.credit_number,
              cardOwner: this.blackcatForm.credit_username,
              cardExp: this.blackcatForm.date.replace('/', ''),
              securityCode: this.blackcatForm.credit_security_code,
              cardholderEmail: this.blackcatForm.credit_email
            }
            // ｗｅｂコレクトが提供する JavaScript 関数を実行し、トークンを発行する。
            /* eslint-disable */
            WebcollectTokenLib.createToken(createTokenInfo, this.callbackSuccess, this.callbackFailure)
            /* eslint-enable */
            break
          case 4: // amazon
            this.orderPayment()
            break
          case 8: // paypay
            this.handlePaypay()
            break
          case 9: // rakuten pay
            this.handleRakutenPay()
            break
        }
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.order-content{
  ::v-deep .el-radio__label{
    font-weight: 700;
    white-space: normal;
    b{
      color: #C40A0A;
    }
  }
    .logo{
        display: block;
        width: 3.74rem;
        margin: .34rem auto;
    }
     .flex{
        display: flex;
        justify-content: space-between;
    }
    .price-detail{
        padding: .28rem .24rem;
        margin: .4rem .24rem 0;
        border: .02rem solid #e8ebed;
        .icon{
            width: .24rem;
            height: .24rem;
        }
        .flip{
             transform: rotate(90deg);
        }
        .content{
            padding-top: .32rem;
            margin-top: .28rem;
            border-top: .02rem dashed #e8ebed;
            .item{
                display: flex;
                justify-content: space-between;
                &:last-child{
                    font-weight: 700;
                    .value{
                        color: #c40a0a;
                    }
                }
                &:not(:last-child){
                    margin-bottom: .32rem;
                }
            }
        }
    }
    .message-content{
        margin: .56rem .24rem 0;
        .list{
            display: flex;
            justify-content: space-between;
            padding: .24rem;
            border: .02rem solid #e8ebed;
            &:not(:last-child){
                margin-bottom: .24rem;
            }
            .box{
                width: 5.16rem;
                padding: .12rem 0;
                .name{
                    color: #999999;
                }
                .value{
                    margin-top: .1rem;
                    font-weight: 700;
                    line-height: .42rem;
                }
            }
            a{
                display: flex;
                align-items: center;
                padding-left: .36rem;
                border-left: .02rem solid #e8ebed;
            }
            .change-btn{
                color: #666666;
            }
            .price{
                display: flex;
                align-items: center;
                font-weight: 700;
                color: #c40a0a;
            }
        }
    }
    .discount-content{
      margin-top: .56rem;
      .title{
          width: 100%;
          height: .64rem;
          padding-left: .24rem;
          line-height: .64rem;
          font-size: .32rem;
          background: #f4f5fc;
          box-sizing: border-box;
      }
      .desc{
          margin: .28rem .24rem 0;
          line-height: .42rem;
          font-weight: 500;
          span{
            color: #C40A0A;
          }
      }
      .radio-content{
            margin: .4rem .24rem 0;
            ::v-deep .el-radio__input{
              display: flex;
              align-items: center;
            }
            .item{
                padding: .3rem 0;
                border: .02rem solid #e8ebed;
                &:not(:last-child){
                    margin-bottom: .24rem;
                }
                .radio{
                  margin: 0 .24rem;
                }
                // &:first-child{
                //   .discount-detail{
                //     margin-top: .28rem;
                //   }
                // }
                .discount-detail{
                    margin-top: .28rem;
                    padding-top: .3rem;
                    border-top: .02rem dashed #e8ebed;
                    .input-content{
                      padding: .24rem;
                      background-color: #f4f5fc;
                      .name{
                        font-weight: 700;
                      }
                      .box{
                        display: flex;
                        margin-top: .14rem;
                      }
                      .el-input{
                        width: 4.74rem;
                        height: .88rem;
                        border: .02rem solid #333333;
                      }
                      .el-button{
                        height: .92rem;
                      }
                    }
                    .point-input{
                      padding: 0 .24rem;
                      .el-input{
                        width: 2.4rem;
                        height: .88rem;
                        margin-right: .16rem;
                        border: .02rem solid #333333;
                      }
                    }
                    .coupon-content{
                      margin: .3rem .24rem 0;
                      .el-radio-group{
                        width: 100%;
                      }
                        .list{
                          &:not(:first-child){
                            margin-top: .2rem;
                          }
                          .time{
                            font-weight: normal;
                          }
                        }
                        .el-radio{
                          display: flex;
                          padding: .3rem .24rem;
                          box-sizing: border-box;
                          background-color: #f4f5fc;
                          color: #333333;
                        }
                        .is-checked{
                          border: .02rem solid #333333;
                        }
                      }
                }
                .el-radio{
                    display: flex;
                    width: 100%;
                    padding:0 .24rem;
                    box-sizing: border-box;
                    line-height: .42rem;
                    color: #333333;
                }
            }
        }
    }
    .payment-content{
        margin-top: .56rem;
        #AmazonPayButton{
            width: 4.2rem;
        }
        .title{
            width: 100%;
            height: .64rem;
            padding-left: .24rem;
            line-height: .64rem;
            font-size: .32rem;
            background: #f4f5fc;
            box-sizing: border-box;
        }
        .desc{
            margin: .28rem .24rem 0;
            line-height: .42rem;
            font-weight: 500;
        }
        .radio-content{
            margin: .4rem .24rem 0;
            .item{
                padding: .3rem .24rem;
                border: .02rem solid #e8ebed;
                &:not(:last-child){
                    margin-bottom: .24rem;
                }
                &:first-child{
                  .payment-desc{
                    margin-top: .28rem;
                  }
                }
                .amazon-icon{
                    width: 2.52rem;
                }
                .paypay-icon{
                  width: 2.09rem;
                }
                .rakuten-icon{
                  width: 1.2rem;
                  margin-right: .12rem;
                  vertical-align: middle;
                }
                .pay-name{
                  vertical-align: middle;
                }
                .payment-desc{
                    padding-top: .3rem;
                    border-top: .02rem dashed #e8ebed;
                }
                .el-radio{
                    width: 100%;
                    margin:0;
                }
                .el-form-item{
                    margin-bottom: .32rem;
                }
                 .input-icon{
                    position: absolute;
                    right: .2rem;
                    top: 0;
                    font-size: .48rem;
                    user-select: none;
                }
                .el-select{
                  width: 100%;
                }
                .rakuten-content{
                  img{
                    display: block;
                    width: 4rem;
                    margin: .2rem auto;
                  }
                }
                .table{
                    width: 100%;
                    &:nth-of-type(1){
                        margin-top: .3rem;
                        margin-bottom: .24rem;
                    }
                    td{
                        padding: .24rem;
                        border: .02rem solid #333333;
                        &:first-of-type{
                            width: 2.2rem;
                        }
                    }
                }
            }
            .payment-card{
                width: 5.36rem;
            }
            .amazon{
                padding: 0 .24rem;
                ::v-deep .el-radio__input{
                    margin-top: -.72rem;
                }
                .text{
                    margin-top: .3rem;
                    line-height: .42rem;
                }
            }
            .paypay{
             padding: 0 .24rem;
             ::v-deep .el-radio__input{
                  margin-top: -.72rem;
              }
            }
            .bank{
                .text{
                    line-height: .42rem;
                    span{
                        color: #c40a0a;
                    }
                }
            }
        }
    }
    .button-content{
        margin: .8rem .24rem 0;
        .next-btn{
            width: 100%;
            height: 1.08rem;
            font-weight: 700;
        }
        .prev-btn{
            width: 100%;
            height: .88rem;
            margin-top: .16rem;
            line-height: .88rem;
            text-align: center;
            border: .02rem solid #333333;
            box-sizing: border-box;
        }
    }
}
.van-overlay{
  .modal-content{
    line-height: .42rem;
    .title{
      font-size: .36rem;
      font-weight: 700;
      color: #c40a0a;
    }
    .text1{
      margin-top: .42rem;
    }
    .text2{
        font-size: .26rem;
    }
    .text3{
        margin-top: .36rem;
    }
    .text4{
      margin-top: .36rem;
      a{
        color: #055FE6;
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .confirm{
      width: 100%;
      height: .88rem;
      margin-top: .5rem;
      background: #333333;
      color: #ffffff;
    }
    .cancel{
      width: 100%;
      height: .88rem;
      margin-top: .16rem;
      line-height: .88rem;
      border: .02rem solid #333333;
      box-sizing: border-box;
    }
  }
}
</style>
