import { login, logout, refreshToken, getUserDetail } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getCartNumber } from '@/api/cart'
import { resetRouter } from '@/router'

const state = {
  token: getToken(),
  username: '',
  cartNumber: 0,
  question: {},
  position: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_CART_NUM: (state, cartNumber) => {
    state.cartNumber = cartNumber
  },
  SET_POSITION: (state, position) => {
    state.position = position
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        // 将后端返回的token存入 cookie 和 vuex
        const token = response.token_type + ' ' + response.access_token
        commit('SET_TOKEN', token)
        setToken(token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUserDetail({ commit }) {
    return new Promise((resolve, reject) => {
      getUserDetail().then(response => {
        commit('SET_USERNAME', `${response.first_name} ${response.last_name}`)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // refresh token
  refreshToken({ commit }) {
    return new Promise((resolve, reject) => {
    // 清除过期时间
      refreshToken().then(response => {
      // 将后端返回的token存入 cookie 和 vuex
        const token = response.token_type + ' ' + response.access_token
        setToken(token)
        commit('SET_TOKEN', token)
        resolve(token)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_TOKEN', '')
        removeToken()
        resetRouter()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  updateToken({ commit }, token) {
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  },
  // get cartNumber
  getCartNumber({ commit }) {
    return new Promise((resolve, reject) => {
      getCartNumber().then(response => {
        commit('SET_CART_NUM', response.number)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  updatePosition({ commit }, position) {
    commit('SET_POSITION', position)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

