var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-content" },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/sp/logo_sp.png") },
        }),
      ]),
      _c("img", {
        staticClass: "step",
        attrs: { src: require("@/assets/images/sp/step3.png") },
      }),
      _c(
        "div",
        { staticClass: "margin-box" },
        [
          _c("div", { staticClass: "text-content" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("ご注文ありがとうございます！"),
            ]),
            _c("div", { staticClass: "order-sn" }, [
              _vm._v(" ご注文番号："),
              _c("span", [
                _vm._v(_vm._s(_vm.initData.order_sn)),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/sp/order_sn_bg.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
            _vm._m(0),
          ]),
          _c("table", { staticClass: "table" }, [
            _c("tr", [
              _c("td", [_vm._v("連絡先")]),
              _c("td", [_vm._v(_vm._s(_vm.initData.receiver_email))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("配送先")]),
              _c("td", [
                _c("p", [_vm._v(_vm._s(_vm.initData.receiver_name) + " 様")]),
                _c("p", [
                  _vm._v(
                    "〒" +
                      _vm._s(_vm.initData.zip_code) +
                      " " +
                      _vm._s(_vm.initData.stats) +
                      _vm._s(_vm.initData.city) +
                      _vm._s(_vm.initData.address)
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.initData.detail_address
                        ? _vm.initData.detail_address
                        : ""
                    )
                  ),
                ]),
                _c("p", [_vm._v(_vm._s(_vm.initData.receiver_phone))]),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("配送方法")]),
              _c("td", [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("通常出荷")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "¥" +
                        _vm._s(_vm.calcThousands(_vm.initData.freight_amount))
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("備考欄")]),
              _c("td", [_vm._v(_vm._s(_vm.initData.note))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("支払方法")]),
              _c("td", [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      _vm._s(
                        _vm.initData.pay_type === 2
                          ? "銀行振込・前払い"
                          : _vm.initData.pay_type === 3
                          ? "クレジットカード決済"
                          : _vm.initData.pay_type === 4
                          ? "amazon pay"
                          : _vm.initData.pay_type === 8
                          ? "paypay"
                          : ""
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      " ¥" +
                        _vm._s(
                          _vm.calcThousands(Number(_vm.initData.pay_amount))
                        )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm.initData.pay_type === 2
            ? _c("div", { staticClass: "table-content" }, [
                _vm._m(1),
                _vm._m(2),
                _vm._m(3),
                _vm._m(4),
              ])
            : _vm._e(),
          _c("router-link", { attrs: { to: "/" } }, [
            _c("div", { staticClass: "button" }, [
              _vm._v("ホームページに戻る"),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" 発送準備が完了しましたら順番に出荷となります。"),
      _c("br"),
      _vm._v("オーダー家具は、お届けまでにお時間を頂戴します。"),
      _c("br"),
      _vm._v("商品の到着までしばらくお待ちください。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" ご注文後7営業日以内お振込みくださいませ。"),
      _c("br"),
      _vm._v("ご入金が確認でき次第商品手配とさせていただきます。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "table" }, [
      _c("tr", [
        _c("td", [_vm._v("銀行名")]),
        _c("td", [_vm._v("三井住友銀行")]),
      ]),
      _c("tr", [_c("td", [_vm._v("支店名")]), _c("td", [_vm._v("船橋支店")])]),
      _c("tr", [
        _c("td", [_vm._v("口座番号")]),
        _c("td", [_vm._v("7154834（普）")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v("口座名義")]),
        _c("td", [_vm._v("ハンコプレミアム（カ")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v("振込み手数料")]),
        _c("td", [_vm._v("お客様ご負担")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v("お支払期限")]),
        _c("td", [_vm._v("ご注文から7営業日まで")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "table" }, [
      _c("tr", [
        _c("td", [_vm._v("銀行名")]),
        _c("td", [_vm._v("ゆうちょ銀行")]),
      ]),
      _c("tr", [_c("td", [_vm._v("記号")]), _c("td", [_vm._v("10580")])]),
      _c("tr", [
        _c("td", [_vm._v("番号")]),
        _c("td", [_vm._v("7154834（普）")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v("口座名義")]),
        _c("td", [_vm._v("ハンコプレミアム（カ")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v("振込み手数料")]),
        _c("td", [_vm._v("お客様ご負担")]),
      ]),
      _c("tr", [
        _c("td", [_vm._v("お支払期限")]),
        _c("td", [_vm._v("ご注文から7営業日まで")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text2" }, [
      _vm._v(" <他行から振り込みの場合>"),
      _c("br"),
      _vm._v(" 【店 名】058 ゼロゴハチ"),
      _c("br"),
      _vm._v(" 【口座番号】3702567"),
      _c("br"),
      _vm._v(" ******************************************** "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }