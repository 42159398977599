<template>
  <div class="order-content">
    <PaymentHeader />
    <div class="step-content">
      <div class="main">
        <div class="item">
          <img src="@/assets/images/pc/step1_border.png" class="icon">
          <div>カート</div>
        </div>
        <img src="@/assets/images/pc/step_line1_in.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step2.png" class="icon">
          <div>配送</div>
        </div>
        <img src="@/assets/images/pc/step_line2_in.png" class="step-line2">
        <div class="item">
          <img src="@/assets/images/pc/step3_border.png" class="icon">
          <div>支払い</div>
        </div>
        <img src="@/assets/images/pc/step_line1_in.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step4_in.png" class="icon">
          <div>注文完了</div>
        </div>
      </div>
    </div>
    <div class="shop-content">
      <div class="left-content">
        <div class="title">ご注文ありがとうございます！</div>
        <div class="order-sn">
          ご注文番号：<span>{{ initData.order_sn }}<img src="@/assets/images/pc/order_sn_bg.png" alt=""></span>

        </div>
        <div class="message-content">
          <div class="list">
            <div class="text">
              <div class="name">連絡先</div>
              <div class="value">{{ initData.receiver_email }}</div>
            </div>
          </div>
          <div class="list">
            <div class="text">
              <div class="name">配送先</div>
              <div class="value">
                {{ initData.receiver_name.replace(' ','') }} 様<br>
                〒{{ initData.zip_code }} {{ initData.stats }}{{ initData.city }}{{ initData.address }}{{ initData.detail_address ? initData.detail_address : '' }}<br>
                {{ initData.receiver_phone }}
              </div>
            </div>
          </div>
          <div class="list">
            <div class="text">
              <div class="name">配送方法</div>
              <div class="value">通常出荷</div>
            </div>
            <div class="price">¥{{ calcThousands(initData.freight_amount) }}</div>
          </div>
          <div class="list">
            <div class="text">
              <div class="name">備考欄</div>
              <div class="value">{{ initData.note }}</div>
            </div>
          </div>
          <div class="list" :class="initData.pay_type === 2 ? 'block' : ''">
            <div class="flex">
              <div class="text">
                <div class="name">支払方法</div>
                <div class="value">
                  <span v-if="initData.pay_type === 2">銀行振込・前払い</span>
                  <span v-else-if="initData.pay_type === 3">クレジットカード決済</span>
                  <img v-else-if="initData.pay_type === 4" src="@/assets/images/pc/amazon.png">
                  <img v-else-if="initData.pay_type === 8" src="@/assets/images/pc/paypay.png">
                </div>
              </div>
              <div class="price">
                ¥{{ calcThousands(Number(initData.pay_amount)) }}
              </div>
            </div>
            <div v-if="initData.pay_type === 2" class="payment-desc">
              <div class="text">
                ご注文後7営業日以内お振込みくださいませ。<br>
                ご入金が確認でき次第商品手配とさせていただきます。
              </div>
              <div class="table-content">
                <table class="table">
                  <tr>
                    <td>銀行名</td>
                    <td>三井住友銀行</td>
                  </tr>
                  <tr>
                    <td>支店名</td>
                    <td>船橋支店</td>
                  </tr>
                  <tr>
                    <td>口座番号</td>
                    <td>7154834（普）</td>
                  </tr>
                  <tr>
                    <td>口座名義</td>
                    <td>ハンコプレミアム（カ</td>
                  </tr>
                  <tr>
                    <td>振込み手数料</td>
                    <td>お客様ご負担</td>
                  </tr>
                  <tr>
                    <td>お支払期限</td>
                    <td>ご注文から7営業日まで</td>
                  </tr>
                </table>
                <table class="table">
                  <tr>
                    <td>銀行名</td>
                    <td>ゆうちょ銀行</td>
                  </tr>
                  <tr>
                    <td>記号</td>
                    <td>10580</td>
                  </tr>
                  <tr>
                    <td>番号</td>
                    <td>7154834（普）</td>
                  </tr>
                  <tr>
                    <td>口座名義</td>
                    <td>ハンコプレミアム（カ</td>
                  </tr>
                  <tr>
                    <td>振込み手数料</td>
                    <td>お客様ご負担</td>
                  </tr>
                  <tr>
                    <td>お支払期限</td>
                    <td>ご注文から7営業日まで</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="text1">発送準備が完了した商品から順番に出荷となります。</div>
        <div class="text2">
          オーダー家具は、お届けまでにお時間を頂戴します。<br>
          お届けの目安は商品ページに記載しておりますが、前後する場合がございますので、予めご確認お願い致します。
        </div>
        <router-link to="/" class="button">トップページ</router-link>
      </div>
      <div class="right-content">
        <img src="@/assets/images/pc/order_success.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { getOrderDetail, getOrderSnDetail } from '@/api/order'
import PaymentHeader from '@/components/PaymentHeader'
import { calcThousands } from '@/utils/validate'
import { getMaf } from '@/utils/auth'
import { getQueryString } from '@/utils/validate'
export default {
  components: { PaymentHeader },
  data() {
    return {
      initData: {
        pay_amount: 0,
        freight_amount: 0,
        receiver_name: ''
      },
      orderSn: getQueryString('order_sn'),
      calcThousands
    }
  },
  created() {
    if (this.orderSn) {
      this.getOrderSnDetail()
    } else {
      this.getOrderDetail()
    }
    this.$store.dispatch('user/getCartNumber')
  },
  methods: {
    getOrderDetail() {
      getOrderDetail().then(result => {
        this.initData = result
        const body = document.body || document.getElementsByTagName('body')[0]
        const script = document.createElement('script')
        const script2 = document.createElement('script') // もしもアフィリエイト
        const script3 = document.createElement('script') // yahoo
        const noscript = document.createElement('noscript')
        const script4 = document.createElement('script') // ga4
        const script5 = document.createElement('script') // BannerBridge
        const script6 = document.createElement('script') // yahoo
        const maf = getMaf() || ''
        script2.src = `https://r.moshimo.com/af/r/result?p_id=4540&pc_id=11849&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}`
        noscript.innerHTML = `<img src="https://r.moshimo.com/af/r/result?p_id=1578&pc_id=2875&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}" width="1" height="1" alt="">`
        let arr = []
        let ga4Arr = []
        script2.setAttribute('id', 'msmaf')
        this.initData.order_products.forEach(item => {
          arr.push({
            'code': item.product_sn,
            'price': Number(item.product_price),
            'quantity': item.product_quantity
          })
          ga4Arr.push({
            item_id: `${item.product_sn}`,
            item_name: `${item.product_title}`,
            price: Number(item.product_sku_price),
            quantity: item.product_quantity
          })
        })
        arr = (JSON.stringify(arr)).replace('}]', '},]')
        ga4Arr = (JSON.stringify(ga4Arr))
        let value = `
          a8sales({
          "pid": "s00000013030006",
          "order_number": "${this.initData.order_sn}",
          "currency": "JPY",
          "items":${arr},
          "total_price": ${this.initData.pay_amount}
          });
        `
        const payAmount = (Number(this.initData.total_amount) / 1.1).toFixed()
        value += `
        (function(){var aid="${this.initData.order_sn}",buy="${payAmount}";var pid="13671",mode="dck";var s=document.cookie.split(";");var l=pid.split("_");var t=0,p,c;for(var i=0;i<s.length;i++){var x=s[i].split("=",2);var k=x[0].trim();var v=x[1].trim().split("T",2);for(var j=0;j<l.length;j++){if(k=="NQINPX_"+l[j]&&v[0]&&+v[1]>t){p=l[j];c=v[0];t=+v[1];}}}var u=t?"https://advack.net/m2/img.php?pcode="+p+"&cid="+encodeURIComponent(c):"https://advack.net/m/img.php?pcode="+pid;var g=document.createElement("img");g.width=1;g.height=1;g.src=u+"&md="+mode+"&aid="+encodeURIComponent(aid)+"&buy="+encodeURIComponent(buy);document.body.appendChild(g);})();
        `
        script.innerHTML = value
        script3.innerHTML = `
        ytag({
        "type": "yss_conversion",
        "config": {
          "yahoo_conversion_id": "1001262176",
          "yahoo_conversion_label": "dx5GCJPB4N0DEIjJvfYo",
          "yahoo_conversion_value": "${this.initData.pay_amount}"
        }
        });
        `
        script3.async = 'async'
        script4.innerHTML = `
         dataLayer.push({ ecommerce:null });
                dataLayer.push({
                    event: 'purchase',
                    ecommerce: {
                      transaction_id: "${this.initData.order_sn}",
                      value: ${this.initData.pay_amount},
                      currency: "JPY",
                      items: ${ga4Arr}
                    }
                });
        `
        script5.src = `https://track.bannerbridge.net/sale_js.php?proID=19443&trans_amount=${this.initData.pay_amount}&orderid=${this.initData.order_sn}`
        script6.innerHTML = `
        ytag({
          "type":"yjad_conversion",
          "config":{
            "yahoo_ydn_conv_io": "JBw00kzVYy7Yg99wJVv7NQ..",
            "yahoo_ydn_conv_label": "CEAKP2CFIRI351LJ8T1065092",
            "yahoo_ydn_conv_transaction_id": "${this.initData.order_sn}",
            "yahoo_ydn_conv_value": "${this.initData.pay_amount}",
          }
        });
        `
        script6.async = 'async'
        body.appendChild(script)
        body.appendChild(script2)
        body.appendChild(noscript)
        body.appendChild(script3)
        body.appendChild(script4)
        body.appendChild(script5)
        body.appendChild(script6)
        const codeJs = document.querySelector('#codejs')
        codeJs.innerHTML += `fbq('track', 'Purchase', {value: ${this.initData.pay_amount}, currency: 'JPY'});`
      })
    },
    getOrderSnDetail() {
      getOrderSnDetail(this.orderSn, { include: 'orderProducts' }).then(result => {
        this.initData = result
        const body = document.body || document.getElementsByTagName('body')[0]
        const script = document.createElement('script')
        const script2 = document.createElement('script') // もしもアフィリエイト
        const script3 = document.createElement('script') // yahoo
        const noscript = document.createElement('noscript')
        const script4 = document.createElement('script') // ga4
        const script5 = document.createElement('script') // BannerBridge
        const script6 = document.createElement('script') // yahoo
        const maf = getMaf() || ''
        script2.src = `https://r.moshimo.com/af/r/result?p_id=4540&pc_id=11849&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}`
        noscript.innerHTML = `<img src="https://r.moshimo.com/af/r/result?p_id=1578&pc_id=2875&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}" width="1" height="1" alt="">`
        let arr = []
        let ga4Arr = []
        script2.setAttribute('id', 'msmaf')
        this.initData.order_products.forEach(item => {
          arr.push({
            'code': item.product_sn,
            'price': Number(item.product_price),
            'quantity': item.product_quantity
          })
          ga4Arr.push({
            item_id: `${item.product_sn}`,
            item_name: `${item.product_title}`,
            price: Number(item.product_sku_price),
            quantity: item.product_quantity
          })
        })
        arr = (JSON.stringify(arr)).replace('}]', '},]')
        ga4Arr = (JSON.stringify(ga4Arr))
        let value = `
          a8sales({
          "pid": "s00000013030006",
          "order_number": "${this.initData.order_sn}",
          "currency": "JPY",
          "items":${arr},
          "total_price": ${this.initData.pay_amount}
          });
        `
        const payAmount = (Number(this.initData.total_amount) / 1.1).toFixed()
        value += `
        (function(){var aid="${this.initData.order_sn}",buy="${payAmount}";var pid="13671",mode="dck";var s=document.cookie.split(";");var l=pid.split("_");var t=0,p,c;for(var i=0;i<s.length;i++){var x=s[i].split("=",2);var k=x[0].trim();var v=x[1].trim().split("T",2);for(var j=0;j<l.length;j++){if(k=="NQINPX_"+l[j]&&v[0]&&+v[1]>t){p=l[j];c=v[0];t=+v[1];}}}var u=t?"https://advack.net/m2/img.php?pcode="+p+"&cid="+encodeURIComponent(c):"https://advack.net/m/img.php?pcode="+pid;var g=document.createElement("img");g.width=1;g.height=1;g.src=u+"&md="+mode+"&aid="+encodeURIComponent(aid)+"&buy="+encodeURIComponent(buy);document.body.appendChild(g);})();
        `
        script.innerHTML = value
        script3.innerHTML = `
        ytag({
        "type": "yss_conversion",
        "config": {
          "yahoo_conversion_id": "1001262176",
          "yahoo_conversion_label": "dx5GCJPB4N0DEIjJvfYo",
          "yahoo_conversion_value": "${this.initData.pay_amount}"
        }
        });
        `
        script3.async = 'async'
        script4.innerHTML = `
         dataLayer.push({ ecommerce:null });
                dataLayer.push({
                    event: 'purchase',
                    ecommerce: {
                      transaction_id: "${this.initData.order_sn}",
                      value: ${this.initData.pay_amount},
                      currency: "JPY",
                      items: ${ga4Arr}
                    }
                });
        `
        script5.src = `https://track.bannerbridge.net/sale_js.php?proID=19443&trans_amount=${this.initData.pay_amount}&orderid=${this.initData.order_sn}`
        script6.innerHTML = `
        ytag({
          "type":"yjad_conversion",
          "config":{
            "yahoo_ydn_conv_io": "JBw00kzVYy7Yg99wJVv7NQ..",
            "yahoo_ydn_conv_label": "CEAKP2CFIRI351LJ8T1065092",
            "yahoo_ydn_conv_transaction_id": "${this.initData.order_sn}",
            "yahoo_ydn_conv_value": "${this.initData.pay_amount}",
          }
        });
        `
        script6.async = 'async'
        body.appendChild(script)
        body.appendChild(script2)
        body.appendChild(noscript)
        body.appendChild(script3)
        body.appendChild(script4)
        body.appendChild(script5)
        body.appendChild(script6)
        const codeJs = document.querySelector('#codejs')
        codeJs.innerHTML += `fbq('track', 'Purchase', {value: ${this.initData.pay_amount}, currency: 'JPY'});`
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.order-content{
  .shop-content{
    display: flex;
    justify-content: space-between;
    width: 1440px;
    margin: 88px auto;
    .left-content{
      width: 952px;
      font-size: 16px;
      .title{
        font-size: 40px;
        font-weight: 700;
        color: #4bc4b0;
        text-align: center;
      }
      .order-sn{
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
        span{
          position: relative;
          display: inline-block;
        }
        img{
          position: absolute;
          left: -3px;
          bottom: -6px;
        }
      }
      .message-content{
          margin-top: 44px;
          font-size: 16px;
          .list{
              display: flex;
              justify-content: space-between;
              padding: 24px 0;
              border: 1px solid #e8ebed;
              border-radius: 4px;
              &:not(:last-child){
                  margin-bottom: 8px;
              }
              .text{
                  display: flex;
                  padding-left: 24px;
                  .name{
                      display: flex;
                      align-items: center;
                      width: 144px;
                  }
                  .value{
                      display: flex;
                      align-items: center;
                      width: 700px;
                      line-height: 24px;
                      font-weight: 700;
                  }
              }
              .price{
                  display: flex;
                  align-items: center;
                  padding-right: 24px;
                  color: #c40a0a;
                  img{
                    margin-left: 4px;
                    transition: .3s;
                  }
                  .flip{
                      transform: rotate(90deg);
                  }
              }
              .flex{
                  display: flex;
                  justify-content: space-between;
              }
              .payment-desc{
                  padding: 38px 24px;
                  margin-top: 24px;
                  margin-bottom: -24px;
                  font-size: 16px;
                  background: #f4f5fc;
                  .text{
                      line-height: 24px;
                  }
                  .table-content{
                      display: flex;
                      justify-content: space-between;
                      width: 794px;
                      margin: 24px 0;
                  }
                  .table{
                        width: 385px;
                        text-align: center;
                        td{
                        height: 48px;
                        border: 1px solid #333333;
                            &:first-of-type {
                                width: 144px;
                            }
                        }
                    }
              }
          }
          .block{
              display: block;
          }
      }
      .text1{
          margin-top: 24px;
      }
      .text2{
          margin-top: 20px;
          line-height: 24px;
      }
      .button{
          display: block;
          margin: 32px auto;
          width: 204px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          background-color: #333333;
          color: #ffffff;
      }
    }
    .right-content{
      width: 416px;
      .next-btn{
        width: 368px;
        height: 60px;
        margin-bottom: 16px;
        line-height: 60px;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        background-color: #333333;
        cursor: pointer;
      }
    }
  }
}
</style>
