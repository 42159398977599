var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-content" },
    [
      _vm._m(0),
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { model: _vm.ruleForm, rules: _vm.rules } },
        [
          _c(
            "div",
            { staticClass: "inline-item" },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "first_name", label: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "姓※" },
                    model: {
                      value: _vm.ruleForm.first_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "first_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.first_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "last_name", label: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "名※" },
                    model: {
                      value: _vm.ruleForm.last_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "last_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.last_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "email", label: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "メールアドレス（会員ID）※" },
                model: {
                  value: _vm.ruleForm.email,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "email",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ruleForm.email,
                  expression: "ruleForm.email",
                },
              ],
              attrs: { prop: "", label: "" },
            },
            [
              _c("div", { staticClass: "name" }, [
                _vm._v("メールアドレス確認"),
              ]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.ruleForm.email)),
              ]),
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  "※メールアドレスにお間違いがないか、今一度ご確認ください。"
                ),
              ]),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password", label: "" } },
            [
              _c("el-input", {
                attrs: { type: "password", placeholder: "パスワード※" },
                model: {
                  value: _vm.ruleForm.password,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password_confirmation", label: "" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "パスワード（確認用）※",
                },
                model: {
                  value: _vm.ruleForm.password_confirmation,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "password_confirmation",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.password_confirmation",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "0" },
              attrs: { prop: "", label: "" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { loading: _vm.btnLoading },
                  on: { click: _vm.handleRegister },
                },
                [_vm._v("会員登録する")]
              ),
              _c(
                "div",
                { staticClass: "text" },
                [
                  _vm._v(" すでにアカウントがあって、"),
                  _c("router-link", { attrs: { to: "/login" } }, [
                    _vm._v("ログイン"),
                  ]),
                  _vm._v("します "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-content" }, [
      _c("div", { staticClass: "en" }, [_vm._v("JOIN TO WOODLIFE")]),
      _c("div", { staticClass: "ja" }, [_vm._v("WOODLIFE会員に登録する")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }