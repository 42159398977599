<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>お気に入り商品</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/point" />
      </div>
      <div class="right-content">
        <div class="title">ポイント残高</div>
        <div class="point-content">
          <div class="head"><span>ご利用可能ポイント：</span> <span class="value">{{ calcThousands(initData.totalPoint) }}</span></div>
          <div class="content">
            <div class="item">
              <div class="value">{{ calcThousands(initData.normalPoint) }}</div>
              <div class="text">通常ポイント</div>
              <div class="desc">
                ■ 有効期限： {{ initData.normalPointExpiredAt }}<br><br>
                ■ 通常ポイントの有効期限は最後のご購入またはポイント獲得から1年間です。woodlife.jpでお買い物をするたびに最後のお買い物の1年後に延長されます。
              </div>
            </div>
            <div class="item">
              <div class="value">{{ calcThousands(initData.activityPoint) }}</div>
              <div class="text">期間限定ポイント</div>
              <div class="desc">
                ■ 通常ポイントのうち、特定の有効期限までに限って利用できるポイントです。<br><br>
                ■ お買い物をすることで有効期限が延長されません。<br><br>
                ■ 通常のポイントと同様、{{ initData.per }}ポイント＝{{ initData.deuction }}円分として使えるポイントです。<br><br>
                ■ 期間終了後、期間限定ポイントは無効となります。
              </div>
            </div>
            <div class="item">
              <div class="value">{{ calcThousands(willPoint) }}</div>
              <div class="text">獲得予定ポイント</div>
              <div class="desc">
                ■ 獲得予定ポイントはお支払いにはご利用いただけません。原則として、獲得予定ポイントは、商品の発送日を目安にご利用可能ポイントに変わります。
              </div>
            </div>
          </div>
        </div>
        <div v-if="allPointRecordData.length" class="detail-content">
          <div class="title">ポイントご利用明細</div>
          <div class="content-box">
            <div class="item-content">
              <div v-for="(item,index) in pointRecordData" :key="index" class="item">
                <div class="date">{{ item.created_at }}</div>
                <div class="desc">{{ item.name }} {{ item.order_sn || '' }}</div>
                <div class="value" :class="{'red':!item.is_point_obtain}">{{ item.is_point_obtain ? '+' : '-' }}{{ item.point }}</div>
                <div class="detail">
                  <router-link v-if="item.order" :to="`order/detail/${item.order.id}`">
                    詳細を見る
                  </router-link>
                </div>
              </div>
            </div>
            <div v-if="allPointRecordData.length > 3 && !isOpen" class="more-btn">
              <div class="btn" @click="handleMore">もっと見る</div>
            </div>
          </div>
        </div>
        <Record />
      </div>
    </div>
  </div>
</template>
<script>

import { getMyPoint, getPointRecordList, getWillPoint } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
import SideBar from './component/sideBar'
import Record from './component/Record'
export default {
  components: { SideBar, Record },
  data() {
    return {
      initData: {
        totalPoint: 0,
        activityPoint: 0,
        normalPoint: 0
      },
      willPoint: 0,
      isOpen: false,
      pointRecordData: [],
      allPointRecordData: [],
      calcThousands
    }
  },
  computed: {

  },
  mounted() {

  },
  created() {
    this.getMyPoint()
    this.getWillPoint()
    this.getPointRecordList()
  },
  methods: {
    getMyPoint() {
      getMyPoint().then(result => {
        this.initData = result
      })
    },
    getWillPoint() {
      getWillPoint().then(result => {
        this.willPoint = result.point
      })
    },
    getPointRecordList() {
      getPointRecordList({ type: 'all' }).then(result => {
        this.allPointRecordData = result.data
        this.pointRecordData = []
        if (this.allPointRecordData.length > 3) {
          for (let i = 0; i < 3; i++) {
            this.pointRecordData.push(this.allPointRecordData[i])
          }
        } else {
          this.pointRecordData = this.allPointRecordData
        }
      })
    },
    handleMore() {
      this.pointRecordData = this.allPointRecordData
      this.isOpen = true
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        .title{
            display: flex;
            justify-content: space-between;
            padding-bottom: 8px;
            margin-bottom: 16px;
            font-size: 20px;
            border-bottom: 2px solid #333;
        }
        .point-content{
          .head{
            width: 100%;
            height: 96px;
            padding: 0 30px;
            line-height: 96px;
            background: #f4f5fc;
            font-size: 20px;
            span{
              vertical-align: middle;
            }
            .value{
              font-size: 48px;
              font-weight: bold;
            }
          }
          .content{
            display: flex;
            justify-content: space-between;
            padding: 32px;
            border-bottom: 1px solid #e8ebed;
            border-left: 1px solid #e8ebed;
            border-right: 1px solid #e8ebed;
            .item{
              width: 334px;
              padding: 40px 16px 20px;
              border: 1px solid #e8ebed;
              box-sizing: border-box;
              .value{
                text-align: center;
                font-size: 32px;
                font-weight: bold;
              }
              .text{
                margin-top: 8px;
                text-align: center;
                font-size: 16px;
              }
              .desc{
                padding-top: 16px;
                margin-top: 32px;
                border-top: 1px solid #e8ebed;
                color: #999FA5;
                line-height: 20px;
              }
            }
          }
        }
        .detail-content{
          margin-top: 40px;
          font-size: 16px;
          .content-box{
            border: 1px solid #e8ebed;
            .item-content{
              .item{
                display: flex;
                padding: 20px 40px;
                &:not(:last-child){
                  border-bottom: 1px solid #e8ebed;
                }
                .date{
                  width: 220px;
                  color: #999FA5;
                }
                .desc{
                  width: 507px;
                  font-weight: bold;
                }
                .value{
                  width: 160px;
                  color: #16B127;
                  font-weight: bold;
                }
                .red{
                  color: #C40A0A;
                }
                .detail{
                  width: 112px;
                  text-align: right;
                  text-decoration: underline;
                }
              }
            }
            .more-btn{
              padding-top: 24px;
              border-top: 1px solid #e8ebed;
              .btn{
                width: 128px;
                height: 48px;
                margin: 0 auto 40px;
                text-align: center;
                line-height: 48px;
                border: 1px solid #333333;
                cursor: pointer;
              }
            }
          }
        }
    }
}
</style>
