import axios from 'axios'
import vue from '@/main'
import store from '@/store'
import { getToken } from '@/utils/auth'

let isAlreadyFetchingAccessToken = false
let subscribers = []
// token刷新后，循环队列中的请求
function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}
// 将401的请求加入队列
function addSubscriber(callback) {
  subscribers.push(callback)
}

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    // 判断token是否存在
    if (store.getters.token) {
      // 请求头中携带token
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(

  response => {
    return response.data
  },

  // 异常处理
  error => {
    const { config } = error
    const originalRequest = config
    // 开发环境下打印错误
    if (process.env.NODE_ENV === 'development') {
      console.log(error.response) // for debug
    }

    // 根据返回状态码做处理
    if (error.response) {
      let msg = ''
      switch (error.response.status) {
        case 401:
          if (error.response.data.code === 1001) { // 输入账号信息错误
            msg = error.response.data.message
            break
          } else if (error.response.data.code === 1002) { // 刷新 token 错误
            store.dispatch('user/resetToken').then(() => {
              vue.$router.push('/login')
            })
            return Promise.reject(error)
          } else {
            // 判断是否已在刷新token中
            if (!isAlreadyFetchingAccessToken) {
              isAlreadyFetchingAccessToken = true
              store.dispatch('user/refreshToken').then((access_token) => {
                isAlreadyFetchingAccessToken = false
                onAccessTokenFetched(access_token)
              })
            }
            return new Promise((resolve) => {
              addSubscriber(access_token => {
                originalRequest.headers.Authorization = access_token
                resolve(axios(originalRequest).then(response => { return response.data }))
              })
            })
          }
        case 422:
          msg = Object.values(error.response.data.errors).shift()[0] // 字段验证错误
          break
        case 404:
          vue.$router.replace('/404')
          return Promise.reject(error)
        default:
          msg = error.response.data.message // 其他错误
          break
      }
      vue.$Toast(msg)
    }
    return Promise.reject(error)
  }
)

export default service
