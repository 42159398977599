<template>
  <div class="cart-content">
    <PaymentHeader />
    <template v-if="initData.length > 0">
      <div class="step-content">
        <div class="main">
          <div class="item">
            <img src="@/assets/images/pc/step1_in.png" class="icon">
            <div>カート</div>
          </div>
          <img src="@/assets/images/pc/step_line1.png" class="step-line1">
          <div class="item">
            <img src="@/assets/images/pc/step2.png" class="icon">
            <div>配送</div>
          </div>
          <img src="@/assets/images/pc/step_line2.png" class="step-line2">
          <div class="item">
            <img src="@/assets/images/pc/step3.png" class="icon">
            <div>支払い</div>
          </div>
          <img src="@/assets/images/pc/step_line1.png" class="step-line1">
          <div class="item">
            <img src="@/assets/images/pc/step4.png" class="icon">
            <div>注文完了</div>
          </div>
        </div>
      </div>
      <div class="shop-content">
        <div class="left-content">
          <div class="title">
            <div class="name">Cart</div>
            <div class="num">{{ allNumber }} 商品</div>
          </div>
          <div class="product-detail">
            <div class="head">
              <div class="name">商品名</div>
              <div class="price">値段</div>
              <div class="num">個数</div>
              <div class="total">合計</div>
            </div>
            <div class="list-content">
              <div
                v-for="(item, key) in initData"
                :key="item.id"
                class="item"
                :class="{
                  'no-sale': !item.product.is_on_sale || (item.product_sku_stock && item.product_sku_stock.stock === 0 || item.product_sku_stock === null),
                  'no-sku-stock': !item.product_sku_stock
                }"
              >
                <div class="no-sku-stock-desc">仕様変更のため、当商品を削除し、再度カートに追加してください。</div>
                <div class="product">
                  <router-link :to="`/product/${item.product.product_sn}`">
                    <div class="img">
                      <img :src="item.product.image" :alt="item.product.image_alt">
                      <div v-if="!item.product.is_on_sale" class="toast">販売終了</div>
                      <div v-else-if="item.product_sku_stock && item.product_sku_stock.stock === 0 || item.product_sku_stock === null" class="toast">品切れ中</div>
                    </div>
                  </router-link>
                  <div class="desc">
                    <div class="name">{{ item.product.title }}</div>
                    <div class="sku-content">
                      <div v-for="(skuItem,index2) in item.sku_value" :key="index2" class="list">{{ skuItem }}</div>
                    </div>
                    <div class="norm-content">
                      <div v-for="(normItem,index3) in item.norm_values" :key="index3" class="list">{{ normItem.norm.name }}：{{ normItem.name }}</div>
                    </div>
                  </div>
                </div>
                <div class="price center">¥{{ calcThousands(item.price) }}円</div>
                <div class="num center">
                  <div class="input-box" :class="(item.product.is_on_sale && item.product_sku_stock && item.product_sku_stock.stock !== 0 && item.number > item.product_sku_stock.stock) || item.error ? 'error' : ''">
                    <div class="value">{{ item.number }}</div>
                    <div class="action">
                      <div class="colum">
                        <img src="@/assets/images/pc/add.png" @click="handleAdd(item)">
                        <img src="@/assets/images/pc/reduce.png" @click="handleReduce(item)">
                      </div>
                    </div>
                    <div v-show="(item.product.is_on_sale && item.product_sku_stock && item.product_sku_stock.stock !== 0 && item.number > item.product_sku_stock.stock) || item.error" class="error-text">在庫不足しています</div>
                  </div>
                </div>
                <div class="total center">¥{{ calcThousands(item.price * item.number) }}円</div>
                <div class="delete-icon" @click="handleDeleteShow(item.id, key)">
                  <img src="@/assets/images/pc/x.png">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-content">
          <div class="sticky">
            <div class="title">買い物カート内の商品</div>
            <div class="detail">
              <div class="item font16">
                <div>送料</div>
                <div>会計時に計算されます</div>
              </div>
              <div class="item">
                <div>合計</div>
                <div class="price">￥{{ calcThousands(totalPrice) }}</div>
              </div>
            </div>
            <div class="light-btn" @click="handleConfirmCart">カートを更新</div>
            <el-button type="primary" class="next-btn" :loading="btnLoading" @click="handleNext">ご購入手続きへ</el-button>
            <router-link to="/">
              <div class="light-btn">買い物を続ける</div>
            </router-link>
            <div class="text">
              送料は1回のご注文につき{{ calcThousands(shippingAmount) }}円（税込）です。<br>
              合計金額{{ calcThousands(freeShippingAmount) }}円（税込）以上送料無料です。
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="cart-empty">
      <img src="@/assets/images/pc/empty_cart.png">
      <div class="text">
        現在、買い物かごには商品が入っておりません。<br>
        お買い物を続けるには下の 「お買い物を続ける」 をクリックしてください。
      </div>
      <router-link to="/">
        <button class="button">お買い物を続ける</button>
      </router-link>
    </div>

    <div v-show="confirmVisible" class="overlay confirm-overlay">
      <div class="main">
        <div class="modal-content">
          <div class="title">確認</div>
          <div class="text">
            カートの商品が変更されました。<br>
            OKボタンを押して確認してください。
          </div>
          <el-button type="primary confirm-btn" :loading="confirmLoading" @click="handleConfirmCart">OK</el-button>
          <img src="@/assets/images/pc/x.png" class="close-btn" @click="confirmVisible = false">
        </div>
      </div>
    </div>
    <div v-show="deleteVisible" class="overlay delete-overlay">
      <div class="main">
        <div class="modal-content">
          <div class="title">商品の削除？</div>
          <div class="text">
            この商品を削除しますか？
          </div>
          <div class="btn-content">
            <el-button type="primary" class="confirm" :loading="confirmLoading" @click="handleConfirmDelete(index)">はい(Yes)</el-button>
            <button class="cancel" @click="deleteVisible = false">いいえ(No)</button>
          </div>
          <img src="@/assets/images/pc/x.png" class="close-btn" @click="deleteVisible = false">
        </div>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <!-- <div v-show="loginVisible" class="overlay login-overlay">
      <div class="main">
        <div class="modal-content">
          <div class="login-bg">
            <img src="@/assets/images/pc/cart_login_bg.png" alt="" class="bg">
            <div class="text-content">
              <img src="@/assets/images/pc/cart_login_name.png" alt="">
              <div class="text">
                無垢材家具の<br>通販専門店ウッドライフ
              </div>
            </div>
          </div>
          <div class="right-form">
            <div class="title">会員のお客様</div>
            <el-form ref="loginForm" :model="loginForm" :rules="rules">
              <el-form-item prop="email" label="">
                <el-input v-model.trim="loginForm.email" placeholder="メールアドレス（会員ID）" />
              </el-form-item>
              <el-form-item prop="password" label="">
                <el-input v-model.trim="loginForm.password" type="password" placeholder="パスワード" />
              </el-form-item>
              <el-form-item prop="" label="">
                <div class="button" @click="handleLogin">
                  <span>ログインする</span>
                  <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
                </div>
              </el-form-item>
            </el-form>
            <div class="cut">
              <span class="line" />
              <span class="text">または</span>
              <span class="line" />
            </div>
            <div class="register-button" @click="handleRegisterShow">新規ユーザー登録する</div>
          </div>
          <img src="@/assets/images/pc/x.png" class="close-btn" @click="handleLoginClose">
        </div>
      </div>
    </div> -->
    <!-- 注册弹窗 -->
    <!-- <div v-show="registerVisible" class="overlay login-overlay">
      <div class="main">
        <div class="modal-content">
          <div class="login-bg">
            <img src="@/assets/images/pc/cart_login_bg.png" alt="" class="bg">
            <div class="text-content">
              <img src="@/assets/images/pc/cart_login_name.png" alt="">
              <div class="text">
                無垢材家具の<br>通販専門店ウッドライフ
              </div>
            </div>
          </div>
          <div class="right-form">
            <div class="title">新規ユーザー登録</div>
            <el-form ref="registerForm" :model="registerForm" :rules="rules">
              <div class="inline-item">
                <el-form-item prop="first_name" label="">
                  <el-input v-model.trim="registerForm.first_name" placeholder="姓" />
                </el-form-item>
                <el-form-item prop="last_name" label="">
                  <el-input v-model.trim="registerForm.last_name" placeholder="名" />
                </el-form-item>
              </div>
              <el-form-item prop="email" label="">
                <el-input v-model.trim="registerForm.email" placeholder="メールアドレス（会員ID）" />
              </el-form-item>
              <el-form-item prop="password" label="">
                <el-input v-model.trim="registerForm.password" type="password" placeholder="パスワード" />
              </el-form-item>
              <el-form-item prop="password_confirmation" label="">
                <el-input v-model.trim="registerForm.password_confirmation" type="password" placeholder="パスワード（確認用）" />
              </el-form-item>
              <el-form-item prop="" label="">
                <div class="button" @click="handleRegister">
                  <span>新規ユーザー登録する</span>
                  <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
                </div>
                <div class="text">
                  すでにアカウントがあって、<a @click="handleLoginShow">ログイン</a>します
                </div>
              </el-form-item>
            </el-form>
          </div>
          <img src="@/assets/images/pc/x.png" class="close-btn" @click="handleRegisterClose">
        </div>
      </div>
    </div> -->
    <div v-show="loginFreeVisible" class="overlay login-fee-overlay">
      <div class="main">
        <div class="modal-content">
          <div class="detail-content">
            <div class="left-content">
              <div class="title">会員のお客様</div>
              <el-form ref="loginForm" :model="loginForm" :rules="rules">
                <el-form-item prop="email" label="">
                  <el-input v-model.trim="loginForm.email" placeholder="メールアドレス（会員ID）" />
                </el-form-item>
                <el-form-item prop="password" label="">
                  <el-input v-model.trim="loginForm.password" type="password" placeholder="パスワード" />
                </el-form-item>
                <el-form-item prop="" label="">
                  <div class="button" @click="handleLogin">
                    <span>ログインする</span>
                    <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
                  </div>
                  <div class="forget-password">
                    ※パスワードを忘れた方は
                    <router-link to="/password">こちら</router-link>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="right-content">
              <div class="title">まだ会員登録されていない方</div>
              <div class="text-content">
                会員登録をせずに購入手続きをされたいかたは、<br>
                下記よりお進みください。<br>
                ポイントの獲得や会員限定クーポンなどの適用対象外となりますのでご了承ください。
              </div>
              <div class="button" @click="handleOrder">
                <span>会員登録せずに購入する</span>
                <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
              </div>
            </div>
          </div>
          <div class="register-content">
            <div class="cut">
              <span class="line" />
              <span class="text">または</span>
              <span class="line" />
            </div>
            <router-link to="/register"><div class="register-button">新規会員登録する</div></router-link>
            <div class="text-content">
              <p>
                <img src="@/assets/images/pc/login_email_icon.png">
                <span>購入履歴やメール履歴も見れます。</span>
              </p>
              <p>
                <img src="@/assets/images/pc/login_keybord_icon.png">
                <span>ご注文時の入力もスムーズに。</span>
              </p>
            </div>
          </div>
          <img src="@/assets/images/pc/x.png" class="close-btn" @click="handleFreeClose">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getBasicConfig } from '@/api/config'
import { getCartList, deleteCartProduct, updateCart, toOrder } from '@/api/cart'
import { register } from '@/api/user'
import PaymentHeader from '@/components/PaymentHeader'
import { calcThousands } from '@/utils/validate'
export default {
  components: { PaymentHeader },
  data() {
    return {
      confirmLoading: false,
      btnLoading: false,
      confirmVisible: false, // 确认购物车弹窗
      deleteVisible: false, // 删除购物车商品弹窗
      loginVisible: false, // 登录弹窗
      registerVisible: false, // 注册弹窗
      loginFreeVisible: false,
      initData: [],
      loginForm: {},
      registerForm: {},
      index: 0,
      cartId: '',
      isChange: false, // 区分购物车数量是否发生改变，如改变，需确认
      rules: {
        first_name: { required: true, message: '姓をご入力ください', trigger: ['blur'] },
        last_name: { required: true, message: '名をご入力ください', trigger: ['blur'] },
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ],
        password: { required: true, message: 'パスワードをご入力ください', trigger: ['blur'] },
        password_confirmation: { required: true, message: 'パスワードを再度ご入力ください', trigger: ['blur'] }
      },
      freeShippingAmount: 0,
      shippingAmount: 0,
      calcThousands
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ]),
    allNumber() { // 购物车总数
      let number = 0
      this.initData.forEach(item => {
        number += item.number
      })
      return number
    },
    totalPrice() { // 购物车总价
      let price = 0
      this.initData.forEach(item => {
        price += item.price * item.number
      })
      return price
    }
  },
  created() {
    this.fetchData().then(() => {
      this.getBasicConfig()
    })
  },
  methods: {
    fetchData() {
      return getCartList().then(result => {
        this.initData = result
      })
    },
    getBasicConfig() {
      getBasicConfig().then(result => {
        this.freeShippingAmount = result.free_shipping_amount
        this.shippingAmount = result.shipping_amount
      })
    },
    handleAdd(item) {
      if (item.number < 99) {
        item.number++
      }
      this.isChange = true
    },
    handleReduce(item) {
      if (item.number !== 1) {
        item.number--
      }
      if (item.number <= item.product_sku_stock.stock) {
        item.error = false
      }
      this.isChange = true
    },
    handleDeleteShow(cartId, index) { // 显示删除确认弹窗
      this.deleteVisible = true
      this.index = index
      this.cartId = cartId
    },
    handleConfirmDelete() { // 确认删除
      this.confirmLoading = true
      deleteCartProduct({ cart_ids: [this.cartId] }).then(() => {
        this.confirmLoading = false
        this.fetchData()
        this.$store.dispatch('user/getCartNumber')
        this.deleteVisible = false
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    handleLoginShow() { // 显示登录弹窗
      this.loginVisible = true
      this.registerVisible = false
    },
    handleRegisterShow() { // 显示注册弹窗
      this.loginVisible = false
      this.registerVisible = true
    },
    handleLoginClose() {
      this.loginVisible = false
      this.registerForm = {}
      this.loginForm = {}
    },
    handleRegisterClose() {
      this.registerVisible = false
      this.registerForm = {}
      this.loginForm = {}
    },
    handleFreeClose() {
      this.loginFreeVisible = false
      this.loginForm = {}
    },
    handleConfirmCart() {
      const data = {
        carts: []
      }
      this.initData.forEach(item => {
        data.carts.push({
          id: item.id,
          number: item.number
        })
      })
      this.confirmLoading = true
      updateCart(data).then(result => {
        this.confirmLoading = false
        this.confirmVisible = false
        this.isChange = false
        const data = result
        if (data.length === 0) {
          this.$toast('カートの更新を行いました')
          this.fetchData()
        } else {
          this.initData.forEach(item => {
            data.forEach(item2 => {
              if (item.id === item2) {
                this.$set(item, 'error', true)
              }
            })
          })
        }
        this.$store.dispatch('user/getCartNumber')
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$store.dispatch('user/login', this.loginForm).then(() => {
            this.handleFreeClose()
            this.fetchData()
            this.$store.dispatch('user/getCartNumber')
          })
        }
      })
    },
    handleRegister() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          register(this.registerForm).then(() => {
            this.$router.push({ path: '/register/auth', query: { first_name: this.registerForm.first_name, last_name: this.registerForm.last_name }})
          })
        }
      })
    },
    handleNext() {
      if (this.isChange) { // 改变购物车数量，提示确认
        this.confirmVisible = true
      } else if (!this.token) { // 未登录，提示登录
        this.loginFreeVisible = true
      } else { // 直接进入下单流程
        const data = {
          cart_list_ids: []
        }
        let flag = true
        this.initData.forEach(item => {
          if (!item.product.is_on_sale) {
            flag = false
            this.$toast('カートに廃盤商品が残っています。その商品を削除してください。')
          } else if (item.product_sku_stock && item.number > item.product_sku_stock.stock) {
            flag = false
          } else {
            data.cart_list_ids.push(item.id)
          }
        })
        if (flag) {
          this.btnLoading = true
          toOrder(data).then(result => {
            const data = result
            if (data.length === 0) {
              this.btnLoading = false
              this.$router.push('/order/address')
            } else {
              this.btnLoading = false
              this.initData.forEach(item => {
                data.forEach(item2 => {
                  if (item.id === item2) {
                    this.$set(item, 'error', true)
                  }
                })
              })
            }
          }).catch(() => {
            this.btnLoading = false
          })
        }
      }
    },
    handleOrder() {
      const cartIds = this.initData.map(item => item.id)
      toOrder({ cart_list_ids: cartIds }).then(result => {
        const data = result
        if (data.length === 0) {
          this.btnLoading = false
          this.$router.push('/order/address')
        } else {
          this.btnLoading = false
          this.initData.forEach(item => {
            data.forEach(item2 => {
              if (item.id === item2) {
                this.$set(item, 'error', true)
              }
            })
          })
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.cart-content{
  .shop-content{
    display: flex;
    justify-content: space-between;
    width: 1440px;
    margin: 48px auto;
    .left-content{
      width: 952px;
      .title{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid #333333;
        .name{
          font-size: 32px;
          font-weight: bold;
        }
        .num{
          font-size: 20px;
          line-height: 36px;
        }
      }
      .product-detail{
        .head{
          display: flex;
          height: 56px;
          font-size: 20px;
          line-height: 56px;
          text-align: center;
          color: #999999;
          .name{
            width: 460px;;
          }
          .price{
            width: 138px;
          }
          .num{
            width: 144px;
          }
          .total{
            width: 178px;
          }
        }
        .list-content{
          .item{
            position: relative;
            display: flex;
            padding: 16px;
            margin-bottom: 8px;
            border: 1px solid #e8ebed;
            box-sizing: border-box;
            font-size: 16px;
            .product{
              width: 440px;
              display: flex;
              .img{
                position: relative;
                width: 136px;
                height: 136px;
                .toast{
                  position: absolute;
                  left: 50%;
                  bottom: 0;
                  width: 64px;
                  height: 18px;
                  line-height: 18px;
                  background-color: #333;
                  color: #fff;
                  border-radius: 9px;
                  font-size: 12px;
                  text-align: center;
                  transform: translate(-50%,0);
                }
              }
              img{
                width: 136px;
                height: 136px;
                object-fit: cover;
              }
              .desc{
                margin-left: 12px;
                .name{
                  margin-top: 12px;
                  font-size: 16px;
                  font-weight: 700;
                }
                .sku-content{
                  margin-top: 16px;
                  line-height: 20px;
                  font-size: 14px;
                }
                .norm-content{
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
            .center{
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
            }
            .price{
              width: 138px;
              font-size: 16px;
            }
            .num{
              width: 144px;
              .input-box{
                position: relative;
                display: flex;
                width: 80px;
                height: 48px;
                margin: 0 auto;
                border: 1px solid #e8ebed;
                line-height: 48px;
                box-sizing: border-box;
                .value{
                  width: 100%;
                  text-align: center;
                  color: #333;
                }
                .action{
                  display: none;
                  width: 30px;
                  .colum{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    img{
                      width: 12px;
                      height: 12px;
                      cursor: pointer;
                      &:hover{
                        opacity: .7;
                      }
                    }
                  }
                }
                .error-text{
                  width: 144px;
                  position: absolute;
                  bottom: -44px;
                  left: 0;
                  color: red;
                }
                &:hover{
                  .value{
                    width: 50px;
                  }
                  .action{
                    display: block;
                    border-left: 1px solid #e8ebed;
                  }
                }
              }
              .error{
                  border-color: red;
              }
            }
            .total{
              width: 178px;
            }
            .delete-icon{
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              font-size: 16px;
              font-weight: 700;
              border: 1px solid #e8ebed;
              box-sizing: border-box;
              cursor: pointer;
            }
          }
          .no-sale{
            color: #999;
            .input-box:hover{
              .value{
                  width: 100% !important;
              }
              .action{
                display: none !important;
              }
            }
          }
          .no-sku-stock{
            border-color: red;
            .no-sku-stock-desc{
              display: block;
            }
          }
          .no-sku-stock-desc{
            display: none;
            position: absolute;
            right: 5px;
            bottom: 5px;
            color: red;
          }
        }
      }
    }
    .right-content{
      width: 416px;
      margin-top: 42px;
      .sticky{
        position: sticky;
        top: 0;
        padding: 32px 24px;
        background-color: #f4f5fc;
        box-sizing: border-box;
      }
      .title{
        font-size: 20px;
        font-weight: 700;
      }
      .detail{
        padding: 24px 0;
        margin-top: 16px;
        margin-bottom: 44px;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #333333;
        .item{
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          &:not(:last-child){
            margin-bottom: 24px;
          }
          .price{
            font-weight: 700;
          }
        }
      }
      .font16{
        font-size: 16px !important;
      }
      .light-btn{
        width: 368px;
        height: 60px;
        margin-bottom: 16px;
        line-height: 60px;
        border: 1px solid #333333;
        font-size: 20px;
        text-align: center;
        color: #333333;
        box-sizing: border-box;
        cursor: pointer;
      }
      .next-btn{
        width: 368px;
        height: 60px;
        margin-bottom: 16px;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        background-color: #333333;
        cursor: pointer;
      }
      .text{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .cart-empty{
    margin: 64px 0 92px;
    text-align: center;
    font-size: 16px;
    .text{
      margin-top: 12px;
    }
    .button{
      width: 204px;
      height: 48px;
      margin: 32px 0 auto;
      line-height: 48px;
      text-align: center;
      background-color: #333;
      color: #fff;
      cursor: pointer;
    }
  }
  .confirm-overlay{
    .modal-content{
        width: 452px;
        height: 300px;
        text-align: center;
        .title{
            margin-top: 56px;
            font-size: 28px;
            font-weight: 700;
        }
        .text{
            margin-top: 24px;
            font-size: 16px;
            line-height: 28px;
        }
        .confirm-btn{
            width: 142px;
            height: 48px;
            margin: 52px 0 auto;
            text-align: center;
            border: 1px solid #333333;
            font-size: 14px;
            cursor: pointer;
        }

    }
  }
  .delete-overlay{
    .modal-content{
      width: 452px;
      height: 300px;
      text-align: center;
      .title{
        margin-top: 56px;
        font-size: 28px;
        font-weight: 700;
      }
      .text{
        margin-top: 24px;
        font-size: 16px;
        line-height: 28px;
      }
      .btn-content{
        display: flex;
        justify-content: space-between;
        width: 304px;
        margin: 52px auto 0;
        button{
          width: 142px;
          height: 48px;
          text-align: center;
          border: 1px solid #333333;
          font-size: 14px;
          cursor: pointer;
        }
        // .confirm{
        //   background-color: #333;
        //   color: #fff;
        // }
        .cancel{
          background-color: #fff;
          &:hover{
            opacity: .7;
          }
        }
      }
    }
  }
  .login-overlay{
    .modal-content{
      display: flex;
      width: 920px;
      height: 614px;
      .login-bg{
        position: relative;
        width: 386px;
        height: 614px;
        text-align: center;
        .bg{
          position: absolute;
          left: 0;
          top: 0;
        }
        .text-content{
          width: 360px;
          height: 100%;
          background: #f9f5f2;
          img{
            margin-top: 232px;
          }
          .text{
            margin-top: 6px;
            line-height: 20px;
          }
        }
      }
      .right-form{
        width: 534px;
        padding-left: 54px;
        box-sizing: border-box;
        .title{
          margin-top: 56px;
          font-size: 28px;
          font-weight: 700;
        }
        .el-form{
          margin-top: 38px;
        }
        .el-input{
          width: 400px;
        }
        .inline-item{
            width: 400px;
            display: flex;
            justify-content: space-between;
            .el-input{
                width: 188px;
            }
        }
        .button{
          display: flex;
          justify-content: space-between;
          width: 400px;
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          background: #333;
          color: #fff;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
          img{
            width: 32px;
            height: 9px;
          }
          .arrow{
            display: flex;
            align-items: center;
          }
        }
        .cut{
          margin-top: 100px;
          color: #999999;
            .text{
              margin: 0 4px;
            }
            .line{
              display: inline-block;
              width: 176px;
              border-top: 1px solid #e8ebed;
              vertical-align: middle;
            }
        }
        .register-button{
          width: 400px;
          height: 60px;
          margin-top: 38px;
          line-height: 60px;
          text-align: center;
          border: 1px solid #333;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
        }
        .text{
          margin-top: 10px;
          font-size: 14px;
          color: #999;
          a{
            color: #333;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .login-fee-overlay{
    .modal-content{
      width: 1040px;
      height: 690px;
      padding: 80px;
      background-color: #fff;
      box-sizing: border-box;
      .detail-content{
        display: flex;
        justify-content: space-between;
      }
      .title{
        margin-bottom: 38px;
        font-size: 28px;
        font-weight: 700;
        color: #000000;
      }
      .button{
          display: flex;
          justify-content: space-between;
          width: 400px;
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          background: #333;
          color: #fff;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
          img{
            width: 32px;
            height: 9px;
          }
          .arrow{
            display: flex;
            align-items: center;
          }
        }
      .left-content{
        width: 400px;
        .forget-password{
          color: #999999;
          a{
            text-decoration: underline;
          }
        }
      }
      .right-content{
        width: 400px;
        .text-content{
          font-size: 16px;
          line-height: 24px;
        }
        .button{
          margin-top: 48px;
        }
      }
    }
    .register-content{
      margin-top: 40px;
      .cut{
        color: #999999;
          .text{
            margin: 0 4px;
          }
          .line{
            display: inline-block;
            width: 415px;
            border-top: 1px solid #e8ebed;
            vertical-align: middle;
          }
      }
      .register-button{
        width: 400px;
        height: 60px;
        margin: 24px auto 0;
        line-height: 60px;
        text-align: center;
        border: 1px solid #333333;
        font-size: 20px;
      }
      .text-content{
        width: 400px;
        margin: 28px auto 0;
        padding-left: 56px;
        span{
          margin-left: 8px;
          vertical-align: middle;
        }
        img{
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
