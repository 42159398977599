// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/pc/login_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".password-content[data-v-2ef5524d] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.password-content .bg[data-v-2ef5524d] {\n  width: 1076px;\n  height: 760px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100%;\n}\n.password-content .bg .breadcrumb[data-v-2ef5524d] {\n  margin-top: 28px;\n  margin-left: 240px;\n}\n.password-content .right-form[data-v-2ef5524d] {\n  margin-left: 18px;\n}\n.password-content .right-form .title[data-v-2ef5524d] {\n  margin-top: 80px;\n  font-size: 28px;\n  font-weight: 700;\n}\n.password-content .right-form .text[data-v-2ef5524d] {\n  margin-top: 64px;\n  font-size: 16px;\n  line-height: 24px;\n}\n.password-content .right-form .el-form[data-v-2ef5524d] {\n  margin-top: 16px;\n}\n.password-content .right-form .el-input[data-v-2ef5524d] {\n  width: 400px;\n}\n.password-content .right-form .button[data-v-2ef5524d] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  width: 400px;\n  height: 60px;\n  line-height: 60px;\n  padding: 0 24px;\n  background: #333;\n  color: #fff;\n  font-size: 20px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  cursor: pointer;\n}\n.password-content .right-form .button img[data-v-2ef5524d] {\n  width: 32px;\n  height: 9px;\n}\n.password-content .right-form .button .arrow[data-v-2ef5524d] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}", ""]);
// Exports
module.exports = exports;
