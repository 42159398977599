var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticClass: "custom",
        domProps: { innerHTML: _vm._s(_vm.categoryData.pc_desc) },
      }),
      _c("div", { staticClass: "product-box" }, [
        _c(
          "div",
          { staticClass: "category-box" },
          [
            _c("div", { staticClass: "category-title" }, [
              _vm._v("こだわり条件"),
            ]),
            _c(
              "el-menu",
              _vm._l(_vm.tagsData, function (item, index) {
                return _c(
                  "el-submenu",
                  { key: index, attrs: { index: String(index) } },
                  [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    item.id !== "カラー"
                      ? _vm._l(item.tags, function (it, ind) {
                          return _c(
                            "el-menu-item",
                            { key: ind, attrs: { index: it.name } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  class: {
                                    active: _vm.searchData.tag_ids === it.id,
                                  },
                                  attrs: { slot: "title" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTags(it.id)
                                    },
                                  },
                                  slot: "title",
                                },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(it.name)),
                                  ]),
                                  _c("span", { staticClass: "count" }, [
                                    _vm._v(_vm._s(it.count)),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        })
                      : [
                          _c(
                            "div",
                            { staticClass: "label" },
                            _vm._l(item.tags, function (its, key) {
                              return _c(
                                "div",
                                {
                                  key: key,
                                  staticClass: "color-box",
                                  class: {
                                    current:
                                      _vm.searchData.color_tag_ids === its.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeColorTags(its.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "round",
                                      style: { background: its.color_value },
                                    },
                                    [_c("span")]
                                  ),
                                  _c("div", { staticClass: "count" }, [
                                    _vm._v(_vm._s(its.count)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                  ],
                  2
                )
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "goods-box" },
          [
            _c(
              "div",
              { staticClass: "screen" },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "01～ 20 件目を表示しています。（全" +
                      _vm._s(_vm.total) +
                      "件）"
                  ),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: { "popper-append-to-body": false },
                    on: {
                      change: function ($event) {
                        ;(_vm.parameterInfo.page = 1), _vm.productList()
                      },
                    },
                    model: {
                      value: _vm.parameterInfo.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.parameterInfo, "sort", $$v)
                      },
                      expression: "parameterInfo.sort",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "表示順", value: "sort" },
                    }),
                    _c("el-option", {
                      attrs: { label: "人気順", value: "is_hot" },
                    }),
                    _c("el-option", {
                      attrs: { label: "新着順", value: "-created_at" },
                    }),
                    _c("el-option", {
                      attrs: { label: "価格安い順", value: "price" },
                    }),
                    _c("el-option", {
                      attrs: { label: "価格高い順", value: "-price" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "goods-list" },
              [
                _vm._l(_vm.initData, function (item) {
                  return _c(
                    "router-link",
                    {
                      key: item.id,
                      staticClass: "goods-item",
                      attrs: { to: "/product/" + item.product_sn },
                    },
                    [
                      _c("div", { staticClass: "image" }, [
                        _c("img", {
                          attrs: {
                            src:
                              item.colorTagIndex === ""
                                ? item.image
                                : item.color_tags[item.colorTagIndex].pivot
                                    .image,
                          },
                        }),
                        item.promotion.length > 0 && item.promotion[0].is_open
                          ? _c("div", { staticClass: "discount" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/pc/off_icon.png"),
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.promotion[0].discount) + "%OFF"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        !item.is_on_sale
                          ? _c("div", { staticClass: "no-inventory" }, [
                              _vm._v("在庫なし"),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "label",
                          on: {
                            mouseleave: function ($event) {
                              item.colorTagIndex = ""
                            },
                          },
                        },
                        _vm._l(item.color_tags, function (it, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: { active: item.colorTagIndex === index },
                              style: { background: it.color_value },
                              on: {
                                mouseenter: function ($event) {
                                  item.colorTagIndex = index
                                },
                              },
                            },
                            [_c("span")]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "price" },
                        [
                          item.promotion.length > 0 && item.promotion[0].is_open
                            ? [
                                _c("span", { staticClass: "now" }, [
                                  _vm._v(
                                    "¥" +
                                      _vm._s(
                                        _vm.calcThousands(item.discount_price)
                                      )
                                  ),
                                ]),
                                _c("span", { staticClass: "original" }, [
                                  _vm._v(
                                    "¥" + _vm._s(_vm.calcThousands(item.price))
                                  ),
                                ]),
                              ]
                            : [
                                _vm._v(
                                  " ¥" +
                                    _vm._s(_vm.calcThousands(item.price)) +
                                    " "
                                ),
                              ],
                          item.is_hot
                            ? _c("span", { staticClass: "sale" }, [
                                _vm._v("SALE"),
                              ])
                            : _vm._e(),
                          item.is_new
                            ? _c("span", { staticClass: "new" }, [
                                _vm._v("NEW"),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "title" }, [
                        _c("span", [_vm._v(_vm._s(item.title))]),
                      ]),
                    ]
                  )
                }),
                _c("div", { staticClass: "goods-item hidden" }),
              ],
              2
            ),
            _c("pagination-pc", {
              attrs: {
                hidden: _vm.total <= _vm.parameterInfo.limit,
                total: _vm.total,
                page: _vm.parameterInfo.page,
                limit: _vm.parameterInfo.limit,
                link: _vm.$route.fullPath,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.parameterInfo, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.parameterInfo, "limit", $event)
                },
                pagination: _vm.productList,
              },
            }),
          ],
          1
        ),
      ]),
      _c("popularity"),
      _c("special"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }