<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / ご利用ガイド
    </div>
    <div class="title-content">
      <div class="en">Shopping guide</div>
      <div class="ja">ご利用ガイド</div>
    </div>
    <div class="category-content">
      <router-link to="/guides/order" class="item">
        <img src="@/assets/images/sp/guides_order.png">
        <div>ご注文について</div>
      </router-link>
      <router-link to="/guides/payment" class="item">
        <img src="@/assets/images/sp/guides_payment.png">
        <div>お支払い方法について</div>
      </router-link>
      <router-link to="/guides/delivery" class="item">
        <img src="@/assets/images/sp/guides_delivery.png">
        <div>配送·送料について</div>
      </router-link>
      <router-link to="/guides/install" class="item">
        <img src="@/assets/images/sp/guides_install.png">
        <div>搬入・組立て・設置</div>
      </router-link>
      <router-link to="/guides/return" class="item">
        <img src="@/assets/images/sp/guides_return.png">
        <div>返品·交換について</div>
      </router-link>
      <router-link to="/guides/question" class="item">
        <img src="@/assets/images/sp/guides_question.png">
        <div>よくあるご質問</div>
      </router-link>
      <router-link to="/guides/afterService" class="item">
        <img src="@/assets/images/pc/guides_after_service.png">
        <div>アフターサービスにつ<br>いて</div>
      </router-link>
      <router-link to="/guides/about" class="item">
        <img src="@/assets/images/sp/guides_about.png">
        <div>WOODLIFEについて</div>
      </router-link>
    </div>
    <div class="guides-bg">
      <div class="text">ご質問に該当がなかった場合は、下記の窓口へお問い合わせください。</div>
      <router-link to="/question">
        <div class="button">お問い合わせフォーム <span>>></span></div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .title-content{
        position: relative;
        height: .68rem;
        margin: .54rem .24rem 0;
        background-color: #f4f5fc;
        .en{
            position: absolute;
            left: .24rem;
            top: -.2rem;
            font-size: .36rem;
            font-weight: 700;
        }
        .ja{
            position: absolute;
            left: .24rem;
            bottom: .1rem;
            font-size: .24rem;
        }
    }
    .category-content{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 .24rem;
        margin-top: .44rem;
        .item{
            width: 3.44rem;
            height: 2.42rem;
            margin-bottom: .14rem;
            text-align: center;
            border: .04rem solid #333333;
            box-sizing: border-box;
            img{
                width: 1.3rem;
                height: 1.3rem;
                margin: .16rem auto .08rem;
            }

        }
    }
    .guides-bg{
        width: 100%;
        height: 2.74rem;
        padding: .4rem .24rem;
        margin-top: .42rem;
        background: url('~@/assets/images/sp/guides_bg.png') no-repeat center center / 100%;
        box-sizing: border-box;
        .text{
            font-size: .32rem;
            line-height: .42rem;
        }
        .button{
            width: 100%;
            height: .8rem;
            margin-top: .34rem;
            line-height: .8rem;
            text-align: center;
            border: .02rem solid #333333;
            background: #ffffff;
            span{
                margin-left: .32rem;
            }
        }
    }
}
</style>
