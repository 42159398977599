var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tokutei-content" }, [
    _c(
      "div",
      { staticClass: "bread-crumbs" },
      [
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
        _vm._v(" / プライバシーポリシー "),
      ],
      1
    ),
    _vm._m(0),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _vm._v(
        " はんこプレミアム株式会社および関連通販サイトのうち、本プライバシーポリシーを採用するサイト（以下「弊社グループ」といいます）では、下記の「プライバシーポリシー」に基づき、お客様及びお取引先の皆様（以下、「お客様」といいます）の個人情報を取り扱います。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " お客様におかれましては、弊社グループが運営する各種サービス（以下あわせて「本サービス」といいます）のご利用のため、下記の「プライバシーポリシー」をご熟読ください。 "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-content" }, [
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("1. 弊社サイトで収集されるお客様の個人情報ついて"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "弊社グループが「4.取得情報の利用目的」に定める目的を達成するために取得する情報には、次のものが含まれます（以下(1)、(2)および(3)をあわせて「取得情報」とします）。"
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(" ① 弊社グループがお客様から取得する情報"),
          _c("br"),
          _vm._v(
            " 個人情報とは、個人情報の保護に関する法律（平成 15 年法律第 57 号、以下「個人情報保護法」といいます）2 条 1 項に定める個人情報をいいますが、特に弊社グループにおいて下記は個人情報として扱います。 "
          ),
          _c("p", [
            _vm._v(
              " A. 氏名、生年月日、性別、ご職業、その他の特定の個 人を識別することができる情報"
            ),
            _c("br"),
            _vm._v(
              " B. 特定の個人情報に結びついて使用されるご住所、電 話番号、アカウント情報（メールアドレス）等の情報"
            ),
            _c("br"),
            _vm._v(
              " C. お客様が本サービスに申込みの際および利用の際 に弊社グループに届け出た情報 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(" ② 弊社グループが本サービスの利用に関連して取得する情報"),
          _c("br"),
          _vm._v(
            " 個人情報に該当するか否かにかかわらず、本サービスの利用に関する以下の情報を取得します。 "
          ),
          _c("p", [
            _vm._v(
              " A. お客様がご利用になった本サービスの内容、ご利用 日時および回数、本サービス利用時のお客様のオンラ イン行動等、お客様による本サービスの利用・閲覧に 関連する情報（これには、Cookie 情報、アクセスロ グ等の利用状況に関する情報、ご利用の端末情報、 OS情報、位置情報、そして IP アドレス、ブラウザ情 報、ブラウザ言語等を含むお客様の通信に関する情報 を含みます）"
            ),
            _c("br"),
            _vm._v(
              " B. お客様が本サービスに掲載・発信した投稿、写真、 評価その他の情報"
            ),
            _c("br"),
            _vm._v(
              " C. 弊社グループが業務提携先および第三者から間接的 に収集する情報 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("2. 取得情報の収集方法")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 弊社グループは、本サービスをご利用になるお客様の情報を、以下の方法等により収集します。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ① 本サービス上でお客様自身に直接ご入力いただく方法"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ② お客様から弊社グループに対し、電子メール、郵便、書面、電話等でご提供いただく方法"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ③ お客様による本サービスの利用・閲覧の際に収集する方法 "),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " ④ 業務提携先および第三者から間接的に収集する方法 これには、以下の方法によるものを含みますがこれらに限られるものではありません。 "
          ),
          _c("p", [
            _vm._v(
              " A. お客様の同意に基づいてアフィリエイト・サービ ス・プロバイダ等の第三者からお客様の閲覧履歴・趣 味嗜好等の情報を収集する方法"
            ),
            _c("br"),
            _vm._v(
              " B.第三者のサービスで表示されるコンテンツの内容 を、お客様のご同意のもと、機械的に収集する方法 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("3. 取得情報の管理")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 弊社グループにて取得情報を管理・利用する業務に従事する者は、お客様の取得情報について厳重に管理を行い、個人情報への不当なアクセス、紛失、漏洩、改ざん等が起きないよう、取得情報の取扱に十分な注意を払い、その業務に努めます。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("4. 取得情報の利用目的")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 弊社グループは、取得情報を取得・収集する際にお客様にお知らせした利用目的、利用規約に定める利用目的または以下の目的のために、取得情報を利用します。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ① 本サービスに関する各種事項の連絡や情報提供を行うため"),
          _c("br"),
          _c("br"),
          _vm._v(" ② 本サービスを提供するため"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ③ 本サービスにユーザー登録する際に必要な情報入力を簡略化するため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ④ お客様のお申込みにかかる本サービス間の情報連携のため"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑤ 電子メール配信サービスのお申し込みの確認やメールを配信するため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ⑥ 本サービスに関するご購入の確認やお届けをするため"),
          _c("br"),
          _c("br"),
          _vm._v(" ⑦ 本サービスに関するご請求、お支払いとその確認をするため"),
          _c("br"),
          _c("br"),
          _vm._v(" ⑧ ご協力いただいた調査等に対する謝礼等をお送りするため"),
          _c("br"),
          _c("br"),
          _vm._v(" ⑨ ご応募いただいた懸賞等に対する景品等をお送りするため"),
          _c("br"),
          _c("br"),
          _vm._v(" ⑩ 本サービスの利用状況等を調査・分析するため"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑪ 本サービスの内容をよりご満足いただけるよう改良・改善し、または新サービスの開発のため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ⑫ 本サービスの内容をお客様にあわせてカスタマイズするため"),
          _c("br"),
          _c("br"),
          _vm._v(" ⑬ 本サービスに関する満足度を調査するため"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑭ 取得情報を統計的に処理した情報を、集約し調査結果として公表するため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ⑮ 本サービス、新サービスに関する研究開発をするため"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑯ 本サービス、新サービスその他の各種サービスのご案内やお知らせをお届けする等、マーケティングで利用するため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑰ 弊社グループや第三者の広告の、お客様にあわせた配信または表示のため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑱ 弊社グループや第三者の提供するサービス内に商品の情報を配信または表示するため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ⑲ 本サービス運営上のトラブルの解決のため"),
          _c("br"),
          _c("br"),
          _vm._v(" ⑳ 本サービスに関する不正利用防止や安全性の確保のため "),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("5. 取得情報の第三者への提供"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "弊社グループは、以下に定める場合、取得情報を第三者に提供します。"
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " ① 本サービスを提供するために弊社グループが必要と判断した、本サービス上での情報の提供の場合"
          ),
          _c("br"),
          _vm._v(
            " お客様は、提供された他のお客様の情報を、本サービスの利用規約に従った本サービスの利用に必要な範囲でのみ利用するものとし、それぞれのお客様の事前の同意なく、他のお客様の情報を第三者に提供してはなりません。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ② 弊社グループがサービスの運営および提供において必要と判断した場合"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ③ 商品の配送、代金決済、お客様からのお問い合わせへの対応、弊社グループからお客様へのお問い合わせ、関連するアフターサービスの提供等のために、配送業者、決済代行業者、業務委託先その他の第三者に提供する場合"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ④ 法令等に基づき、裁判所、行政機関、監督官庁その他公的機関から取得情報を提供するよう求められた場合"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑤ 第三者との紛争解決のため、または本サービスのユーザーもしくは第三者の権利や利益等を守るために情報提供が必要と弊社グループが判断した場合"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ⑥ クレジットカード発行会社が行う不正利用検知および不正利用防止のため、お客さまの出品や購買の履歴、本サービスをご利用するにあたってご登録いただいた情報、本サービスのご利用の状況等を、お客さまがご利用されているカード発行会社に提供する場合その他、弊社グループが取得情報の提供が必要と判断した場合に、お客様の同意を得た上で、取得情報を提供することがあります。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("6. 取得情報の取扱いに関する第三者への委託"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 弊社グループは、「4. 取得情報の利用目的」の達成に必要な範囲内において、取得情報の取扱いの一部を業務委託先（配送業者）に委託することがあります。その際、弊社グループが定める委託先選定基準を満たす者であるかを十分審査し、契約にあたっては取得情報が適正に管理されるよう確保します。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("7. 取得情報の共同利用")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 弊社グループは、「4．取得情報の利用目的」の達成に必要な範囲で、取得情報を、弊社グループ各社間で共同利用します。 "
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " ① 共同して利用される情報 「1. 取得する情報」に規定されている取得情報"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ② 共同して利用する者の範囲 「プライバシーポリシー」の前文に規定されている弊社グループ"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ③ 共同利用する者の利用目的 「4. 取得情報の利用目的」の達成のため"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ④ 共同利用における管理責任者 はんこプレミアム株式会社 はんこプレミアム株式会社の住所・代表者等の情報については、以下をご参照ください。 https://www.woodlife.jp/company "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("8. 「Cookie」等の利用")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " Cookie の使用を許可するかにつきましては、お客様が設定できます。 多くの Web ブラウザでは、自動的に Cookie の使用が許可されますが、Cookie の使用を禁止するようにブラウザの設定を変更することも可能です。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ① Cookie の使用を禁止した場合、本サービスを正常に利用できない、あるいは Cookie を必要とする広告設定を反映できなくなる可能性があります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ② 弊社グループは、お客様の Cookie 情報や、閲覧された広告・ページ、お客様のご利用環境等の情報を自動的にお客様のブラウザから受け取り、サーバに記録し、本サービスログイン時の電子メールアドレス自動入力等の利便性向上のため、セッションの維持および保護等セキュリティの為、また、新しいサービスを検討するため、サービスや広告の内容をよりお客様に適したものとするために利用します。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ③ Cookie等の利用に関する詳細については各ブラウザのクッキーポリシーをご覧ください。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("9. Google アナリティクスについて"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 弊社グループは本サービスの利用状況等を調査・分析するため、本サービス上にGoogle社が提供するGoogle アナリティクスを利用しています。Googleアナリティクスの利用規約に関する説明についてはGoogle アナリティクスのサイトを、Google社のプライバシーポリシーに関する説明については同社のサイトをご覧ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Google アナリティクス 利用規約："),
          _c("br"),
          _c("br"),
          _vm._v(" https://www.google.com/analytics/terms/jp.html"),
          _c("br"),
          _c("br"),
          _vm._v(" Google プライバシーポリシー："),
          _c("br"),
          _c("br"),
          _vm._v(" http://www.google.com/intl/ja/policies/privacy/"),
          _c("br"),
          _c("br"),
          _vm._v(
            " Google アナリティクスはCookie等の仕組みでお客様の利用状況等を把握しています。お客様は、ご自身のデータが Google アナリティクスで使用されることを望まない場合は、Google社の提供するGoogle アナリティクス オプトアウト アドオンをご利用ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Google アナリティクス オプトアウト アドオン："),
          _c("br"),
          _c("br"),
          _vm._v(" https://tools.google.com/dlpage/gaoptout?hl=ja "),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("10. 弊社グループによる個人情報の訂正"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "個人情報のうち、市町村等の名称および郵便番号等、商品のお届けやご請求を行う上で支障がある情報に変更があった場合には、弊社グループが登録されている個人情報を変更する場合があります。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("11. 個人情報の管理およびセキュリティ"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "弊社グループでは、収集した取得情報を、一般のユーザーがアクセスできない環境下にあるサーバにて安全に保管し、不正アクセス・紛失・破壊・改ざんまたは漏洩が生じないよう努めます。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("12. 未成年のお客様の情報"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "お客様が未成年者の場合は、ご両親、保護者または後見人の方が「プライバシーポリシー」および利用規約等の内容に同意され、お客様が「プライバシーポリシー」および利用規約等に基づき本サービスをご利用いただくことについて包括的に同意されることが必要となります。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("13. 「プライバシーポリシー」の改善および更新"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 弊社グループは「プライバシーポリシー」に関し、適宜改善に取り組みます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ① 法令変更への対応や事業上の必要性等に応じて、「プライバシーポリシー」が改定される場合があります。弊社グループは、「プライバシーポリシー」を変更した場合には、お客様に通知するものとします。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ② お客様におかれましては、本サービスにアクセスするか弊社グループのサービスをご利用になる前に、必ず最新の「プライバシーポリシー」をご確認ください。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("14. 個人情報の開示・訂正・利用停止等の手続"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " お客様はいつでもご登録されているお客様の情報を、本サービス上で確認、訂正することができます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " お客様の個人情報が正確に維持されるように注意を払っています。お客様は、弊社所定の方法により、自らの個人情報を容易に確認することができ、お客様において変更または削除することができます。もし、不正確または古い情報が残っている場合には、適切に更新してください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 私たちが保有するお客様の個人情報の内容は、会員情報管理にて確認、変更、または削除していただくことができます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 弊社会員情報管理上での確認によらずにお客様が書面により確認等したい場合には、その手続きについてご案内いたしますので、「15．お問い合わせ」に従って私たちにご連絡ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " この場合、弊社所定の手数料を申し受ける場合があります。なお、請求方法に不足がある場合や請求内容に合理的な理由がない場合その他法令上対応が難しい場合はお客様の求めに対応できないことがあります。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("15. お問い合わせ")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " 「プライバシーポリシー」に関するお問い合わせは、弊社グループの下記担当までお願いします。"
          ),
          _c("br"),
          _vm._v(
            " 〒274-0065 千葉県船橋市高根台7丁目26-12 はんこプレミアム株式会社 個人情報担当代表者 晴海 旭 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }