<template>
  <div class="order-content">
    <router-link to="/">
      <img src="@/assets/images/sp/logo_sp.png" class="logo">
    </router-link>
    <img src="@/assets/images/sp/step1.png" class="step">
    <div class="price-detail">
      <div class="flex" @click="detailShow = !detailShow">
        <div class="name">金額明細</div>
        <img src="@/assets/images/sp/right_arrow1.png" class="icon" :class="{'flip':detailShow}">
      </div>
      <div v-show="detailShow" class="content">
        <div class="item">
          <div class="name">小計</div>
          <div class="value">￥{{ calcThousands(priceParams.total_amount) }}</div>
        </div>
        <div class="item">
          <div class="name">送料</div>
          <div class="value">￥{{ calcThousands(priceParams.freight_amount) }}</div>
        </div>
        <div class="item">
          <div class="name">合計金額</div>
          <div class="value">￥{{ calcThousands(priceParams.pay_amount) }}</div>
        </div>
      </div>
    </div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
      <div class="margin-box">
        <el-form-item prop="address.receiver_email" label="連絡先">
          <el-input v-model.trim="ruleForm.address.receiver_email" placeholder="連絡先" />
        </el-form-item>
      </div>
      <div class="delivery-content">
        <div class="title">配送方法</div>
        <div class="content">
          <div class="flex">
            <el-radio v-model="delivery" :label="0">
              <span>通常出荷(玄関先まで)</span>
            </el-radio>
            <div class="price">¥{{ calcThousands(priceParams.freight_amount) }}</div>
          </div>
          <div class="detail">
            配送は玄関先までとなります。開梱や組立、梱包材の回収はございません。<br><br>発送準備が完了した商品から順番に出荷となります。オーダー家具は、お届けまでにお時間を頂戴します。出荷目安は各商品ページにご確認ください。
          </div>
        </div>
      </div>
      <div ref="addressContent" class="address-content">
        <div class="title">お届け先の住所</div>
        <div class="margin-box">
          <div class="inline-item">
            <el-form-item prop="address.first_name" label="姓">
              <el-input v-model.trim="ruleForm.address.first_name" placeholder="姓" />
            </el-form-item>
            <el-form-item prop="address.last_name" label="名">
              <el-input v-model.trim="ruleForm.address.last_name" placeholder="名" />
            </el-form-item>
          </div>
          <div class="inline-item">
            <el-form-item prop="address.phone" label="電話番号">
              <el-input v-model.trim="ruleForm.address.phone" type="tel" maxlength="11" placeholder="電話番号" />
            </el-form-item>
            <el-form-item prop="address.zip_code" label="郵便番号">
              <el-input v-model.trim="ruleForm.address.zip_code" type="tel" maxlength="7" placeholder="郵便番号" @change="changeZipCode" />
            </el-form-item>
          </div>
          <div class="inline-item">
            <el-form-item prop="address.stats" label="都道府県">
              <el-select v-model="ruleForm.address.stats" placeholder="都道府県">
                <el-option v-for="item in areaData" :key="item.id" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item prop="address.city" label="市区町村">
              <el-input v-model.trim="ruleForm.address.city" placeholder="市区町村" />
            </el-form-item>
          </div>
          <el-form-item prop="address.address" label="番地住所">
            <el-input v-model.trim="ruleForm.address.address" placeholder="番地住所" />
          </el-form-item>
          <el-form-item prop="address.detail_address" label="建物名・部屋番号（任意）">
            <el-input v-model.trim="ruleForm.address.detail_address" placeholder="建物名・部屋番号（任意）" />
          </el-form-item>
          <el-form-item v-if="token">
            <el-checkbox v-model="checked">よく使う住所として登録</el-checkbox>
          </el-form-item>
        </div>
      </div>
      <div ref="noteContent" class="note-content">
        <div class="title">備考欄</div>
        <div class="margin-box">
          <el-form-item label="備考欄（任意）">
            <el-input v-model="ruleForm.note" type="textarea" placeholder="備考欄（任意）" />
          </el-form-item>
        </div>
      </div>
      <div class="button-content">
        <el-button type="primary" class="next-btn" :loading="btnLoading" @click="toPayment">支払い方法に進む</el-button>
        <router-link to="/cart">
          <div class="prev-btn">カートに戻る</div>
        </router-link>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getShoppingDetail, getPriceShow, addressSave } from '@/api/order'
import { getUserDetail, updateUser } from '@/api/user'
import { getRegionList, getZipCodeList } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
import { scrollTo } from '@/utils/scrollTo'
export default {
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('電話番号をご入力ください'))
      } else {
        if (isNaN(value)) {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else if (value.length < 9 || value.length > 12 || value.substr(0, 1) !== '0') {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else {
          callback()
        }
      }
    }
    var checkZipCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('郵便番号をご入力ください'))
      } else {
        if (isNaN(value) || value.length !== 7) {
          callback(new Error('郵便番号は半角数字7桁で入力してください'))
        } else {
          callback()
        }
      }
    }
    return {
      detailShow: false,
      btnLoading: false,
      ruleForm: {
        address: {
          first_name: '',
          last_name: '',
          phone: '',
          zip_code: '',
          stats: '',
          city: '',
          address: '',
          detail_address: '',
          receiver_email: ''
        },
        note: ''
      },
      shippingAmount: 0,
      priceParams: {
        freight_amount: 0,
        total_amount: 0,
        pay_amount: 0
      },
      areaData: [],
      delivery: 0,
      checked: false,
      rules: {
        'address.first_name': { required: true, message: '姓をご入力ください', trigger: ['blur'] },
        'address.last_name': { required: true, message: '名をご入力ください', trigger: ['blur'] },
        'address.phone': [{ required: true, validator: checkPhone, trigger: ['blur'] }],
        'address.zip_code': [{ required: true, validator: checkZipCode, trigger: ['blur'] }],
        'address.stats': { required: true, message: '届け先住所を選択', trigger: ['blur'] },
        'address.city': { required: true, message: '市区町村をご入力ください', trigger: ['blur'] },
        'address.address': { required: true, message: '番地をご入力ください', trigger: ['blur'] },
        'address.receiver_email': [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスをご入力ください', trigger: ['blur'] }
        ]
      },
      calcThousands
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  created() {
    this.getShoppingDetail()
    this.getPriceShow()
    this.getRegionList()
    const href = this.$route.query.href
    this.$nextTick(() => {
      if (href === 'note-content') {
        scrollTo(this.$refs.noteContent.offsetTop, 50)
      } else if (href === 'address-content') {
        scrollTo(this.$refs.addressContent.offsetTop, 50)
      }
      this.$router.push('/order/address')
    })
  },

  methods: {
    getShoppingDetail() {
      getShoppingDetail().then(result => {
        if (result.receiver_email) {
          this.ruleForm.address = {
            first_name: result.first_name,
            first_name_kana: result.first_name_kana,
            last_name: result.last_name,
            last_name_kana: result.last_name_kana,
            stats: result.stats,
            city: result.city,
            address: result.address,
            detail_address: result.detail_address,
            receiver_email: result.receiver_email,
            phone: result.phone,
            zip_code: result.zip_code
          }
          this.ruleForm.note = result.note
        } else {
          if (this.token) {
            this.getUserDetail()
          }
        }
      })
    },
    getUserDetail() {
      getUserDetail().then(result => {
        this.ruleForm.address = {
          first_name: result.first_name,
          last_name: result.last_name,
          phone: result.receive_phone,
          zip_code: result.zip_code,
          stats: result.stats,
          city: result.city,
          address: result.address,
          detail_address: result.detail_address,
          receiver_email: result.email
        }
      })
    },
    getPriceShow() {
      getPriceShow({ use_point: 0 }).then(result => {
        this.priceParams = result
      })
    },
    getRegionList() {
      getRegionList().then(result => {
        this.areaData = result
      })
    },
    changeZipCode(value) {
      getZipCodeList({ zip_code: value }).then(result => {
        this.ruleForm.address.stats = result.state
        this.ruleForm.address.city = result.city
        this.ruleForm.address.address = result.address
      })
    },
    toPayment() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          addressSave(this.ruleForm).then(() => {
            this.$router.push('/order/payment')
            if (this.checked) {
              const data = {
                first_name: this.ruleForm.address.first_name,
                last_name: this.ruleForm.address.last_name,
                receive_phone: this.ruleForm.address.phone,
                zip_code: this.ruleForm.address.zip_code,
                stats: this.ruleForm.address.stats,
                city: this.ruleForm.address.city,
                address: this.ruleForm.address.address,
                detail_address: this.ruleForm.address.detail_address
              }
              updateUser(data).then(() => {
                this.btnLoading = false
              }).catch(() => {
                this.btnLoading = false
              })
            }
          })
        } else {
          if (this.ruleForm.address.receiver_email === '') {
            scrollTo(0, 800)
          } else {
            scrollTo(this.$refs.addressContent.offsetTop, 800)
          }
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.order-content{
    .logo{
        display: block;
        width: 3.74rem;
        margin: .34rem auto;
    }
    .flex{
        display: flex;
        justify-content: space-between;
    }
    .price-detail{
        padding: .28rem .24rem;
        margin: .4rem .24rem 0;
        border: .02rem solid #e8ebed;
        .icon{
            width: .24rem;
            height: .24rem;
        }
        .flip{
             transform: rotate(90deg);
        }
        .content{
            padding-top: .32rem;
            margin-top: .28rem;
            border-top: .02rem dashed #e8ebed;
            .item{
                display: flex;
                justify-content: space-between;
                &:last-child{
                    font-weight: 700;
                    .value{
                        color: #c40a0a;
                    }
                }
                &:not(:last-child){
                    margin-bottom: .32rem;
                }
            }
        }
    }
    .el-form{
        margin-top: .4rem;
        .margin-box{
            margin: 0 .24rem;
        }
        .el-form-item{
            margin-bottom: .32rem;
        }
        .delivery-content{
            margin-top: .56rem;
            .title{
                width: 100%;
                height: .64rem;
                padding-left: .24rem;
                line-height: .64rem;
                font-size: .32rem;
                background: #f4f5fc;
                box-sizing: border-box;
            }
            .content{
                padding: .28rem .24rem;
                margin: .4rem .24rem 0;
                border: .02rem solid #e8ebed;
                .flex{
                    .price{
                        font-weight: 700;
                        color: #c40a0a;
                    }
                }
                ::v-deep .el-radio__inner{
                    width: .36rem;
                    height: .36rem;
                }
                ::v-deep .el-radio__label{
                    padding-left: .12rem;
                    font-weight: 700;
                }
                .detail{
                    padding-top: .3rem;
                    margin-top: .28rem;
                    border-top: .02rem dashed #e8ebed;
                }
            }
        }
        .address-content{
            margin-top: .56rem;
           .title{
                width: 100%;
                height: .64rem;
                padding-left: .24rem;
                margin-bottom: .32rem;
                line-height: .64rem;
                font-size: .32rem;
                background: #f4f5fc;
                box-sizing: border-box;
            }
            .inline-item{
                display: flex;
                justify-content: space-between;
                .el-input,.el-select{
                    width: 3.4rem;
                }
                ::v-deep .el-form-item__content{
                    width: 3.4rem;
                }
            }
        }
        .note-content{
            margin-top: .62rem;
             .title{
                width: 100%;
                height: .64rem;
                padding-left: .24rem;
                margin-bottom: .32rem;
                line-height: .64rem;
                font-size: .32rem;
                background: #f4f5fc;
                box-sizing: border-box;
            }
            ::v-deep .el-textarea__inner{
                width: 100%;
                height: 2.4rem;
            }
        }
        .button-content{
            margin: .8rem .24rem 0;
            .next-btn{
                width: 100%;
                height: 1.08rem;
                font-weight: 700;
            }
            .prev-btn{
                width: 100%;
                height: .88rem;
                margin-top: .16rem;
                line-height: .88rem;
                text-align: center;
                border: .02rem solid #333333;
                box-sizing: border-box;
            }
        }
    }
}
</style>
