<template>
  <div class="order-content">
    <router-link to="/">
      <img src="@/assets/images/sp/logo_sp.png" class="logo">
    </router-link>
    <img src="@/assets/images/sp/step_pay_error.png" class="step">
    <div class="error-content">
      <div class="warn">
        クレジットカードの本人認証<br>
        （3Dセキュア）に失敗しました！<br>
        一度購入画面に戻り、<br>
        再度決済を実施してください。
      </div>
      <div class="desc">ご入力頂いたパスワードが間違っているか、クレジットカードに本人認証サービス（3Dセキュア）の設定をされていない可能性が考えられるため、ご利用のカード発行会社にご確認ください。</div>
      <div class="desc">安全にご利用いただくため、本人認証サービスのパスワードが設定されたクレジットカードが必要です。</div>
      <div class="desc">※3Dセキュア非対応のクレジットカードもございます。詳細はご利用のカード発行会社へお問い合わせください。</div>
      <img src="@/assets/images/sp/stripe_pay_error.png" class="error-img">
      <router-link to="/cart">
        <div class="button">もう一度やり直す</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang='scss' scoped>
.order-content{
    .logo{
        display: block;
        width: 3.74rem;
        margin: .34rem auto;
    }
    .error-content{
        margin: 0.24rem;
        .warn{
            margin: .44rem 0 .34rem;
            line-height: .54rem;
            text-align: center;
            color: #C40A0A;
            font-size: .36rem;
            font-weight: 700;
        }
        .desc{
            margin-top: .24rem;
            line-height: .42rem;
        }
        .error-img{
            margin-top: .5rem;
        }
        .button{
            width: 100%;
            height: 1.08rem;
            margin-top: .8rem;
            line-height: 1.08rem;
            text-align: center;
            background: #333333;
            color: #ffffff;
            font-weight: 700;
        }
    }
}
</style>
