var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c(
        "div",
        { staticClass: "article-list" },
        [
          _c("router-link", { attrs: { to: "/new/product" } }, [
            _c("span", [_vm._v("新着商品 ／ 特集")]),
            _c("img", {
              attrs: { src: require("@/assets/images/sp/article_arrow.png") },
            }),
          ]),
          _c("router-link", { attrs: { to: "/article" } }, [
            _c("span", [_vm._v("NEWS ／ お知らせ")]),
            _c("img", {
              attrs: { src: require("@/assets/images/sp/article_arrow.png") },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "guide-box",
              on: {
                click: function ($event) {
                  _vm.guide = !_vm.guide
                },
              },
            },
            [
              _c("span", [_vm._v("ご利用ガイド")]),
              _vm.guide
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_hide.png"),
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_open.png"),
                    },
                  }),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.guide,
                    expression: "guide",
                  },
                ],
                staticClass: "guide",
              },
              [
                _c("router-link", { attrs: { to: "/guides/category" } }, [
                  _c("span", [_vm._v("ご利用ガイド")]),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_arrow.png"),
                    },
                  }),
                ]),
                _c("router-link", { attrs: { to: "/question" } }, [
                  _c("span", [_vm._v("よくあるご質問")]),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_arrow.png"),
                    },
                  }),
                ]),
                _c("router-link", { attrs: { to: "/guides/delivery" } }, [
                  _c("span", [_vm._v("配送・送料")]),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_arrow.png"),
                    },
                  }),
                ]),
                _c("router-link", { attrs: { to: "/company" } }, [
                  _c("span", [_vm._v("企業情報")]),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_arrow.png"),
                    },
                  }),
                ]),
              ],
              1
            ),
          ]),
          _c("router-link", { attrs: { to: "/tokutei" } }, [
            _c("span", [_vm._v("特定商取引法に基づく表示")]),
            _c("img", {
              attrs: { src: require("@/assets/images/sp/article_arrow.png") },
            }),
          ]),
          _c("router-link", { attrs: { to: "/privacy" } }, [
            _c("span", [_vm._v("プライバシーポリシー")]),
            _c("img", {
              attrs: { src: require("@/assets/images/sp/article_arrow.png") },
            }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "question" },
        [
          _c("router-link", { attrs: { to: "/question" } }, [
            _vm._v("お問い合わせ"),
          ]),
          _c("div", [_vm._v("平時 9:30~18:30 (土・日を除く)")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "share" },
        [
          _vm._m(0),
          _c("router-link", { attrs: { to: "" } }, [
            _c("img", {
              attrs: { src: require("@/assets/images/sp/footerShare_2.png") },
            }),
          ]),
          _vm._m(1),
          _c("router-link", { attrs: { to: "" } }, [
            _c("img", {
              attrs: { src: require("@/assets/images/sp/footerShare_4.png") },
            }),
          ]),
          _c("router-link", { attrs: { to: "" } }, [
            _c("img", {
              attrs: { src: require("@/assets/images/sp/footerShare_5.png") },
            }),
          ]),
        ],
        1
      ),
      _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
        _c("img", {
          attrs: { src: require("@/assets/images/sp/logo_footer.png") },
        }),
      ]),
      _c("div", { staticClass: "footer-box" }, [
        _vm._v("© HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED."),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.facebook.com/woodlife.jp" } },
      [
        _c("img", {
          attrs: { src: require("@/assets/images/sp/footerShare_1.png") },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "https://www.instagram.com/woodlife.jp" } },
      [
        _c("img", {
          attrs: { src: require("@/assets/images/sp/footerShare_3.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }