var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v("家具通販専門店TOP"),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/breadcrumb_arrow.png"),
              alt: "",
            },
          }),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/breadcrumb_arrow.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("返品・交換について")]),
        ],
        1
      ),
      _vm._m(0),
      _c("router-link", { attrs: { to: "/guides/question" } }, [
        _c("div", { staticClass: "button" }, [
          _c("span", [_vm._v("他のご質問はこちら")]),
          _c("img", {
            attrs: { src: require("@/assets/images/pc/button_arrow.png") },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-content" }, [
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("01 返品ついて")]),
        _c("div", { staticClass: "red-bg" }, [
          _vm._v(
            "お客様ご都合による返品・交換は基本的にお受けすることができません。"
          ),
        ]),
        _c("div", { staticClass: "text-content" }, [
          _vm._v(
            " 返品・交換は商品到着日より7日以内に弊社へご連絡ください。期限を過ぎた場合、返品・交換のご要望はお受けできません。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " お客様の都合（イメージ違い、サイズ違い、商品の搬入が出来ない等）による返品はお受け出来ませんので予めご了承下さい。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ご注文の際は、商品について十分にご確認の上、お申し込み頂けます様お願い致します。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " モニター上では色目を完全に再現することができません。特に天然素材は色調や模様にバラツキがあります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ご連絡がいただけない場合は、ご返品をお受けいたしません。"),
          _c("br"),
          _c("br"),
          _vm._v(
            " イメージ違い、カラー違い、木目の好み、サイズが部屋に合わない、ニオイ、肌触り等の理由でご返品は、対応いたしませんので予めご了承下さいませ。"
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("b", [
            _vm._v("下記は商品の不良や掲載情報の不備には該当しません。"),
          ]),
          _c("br"),
          _c("br"),
          _c("ul", [
            _c("li", [
              _vm._v(
                "商品のお色に関してはブラウザの閲覧環境によって若干異なって見える場合がございます。"
              ),
            ]),
            _c("br"),
            _c("li", [
              _vm._v(
                "表記サイズ・重量の計測方法や計測位置によって多少の誤差がございます。"
              ),
              _c("br"),
              _vm._v(
                "※ 表記寸法において±3cm 程の誤差が生じる場合でも良品とさせて頂いております。"
              ),
            ]),
            _c("br"),
            _c("li", [
              _vm._v(
                "天然木を材料（突板を含む）として使用した商品の木目、節、木肌の色味、色合い、商品ページと多少の色味の違いが発生する場合がございます。"
              ),
              _c("br"),
              _vm._v("その場合は商品不良に該当しません。"),
            ]),
            _c("br"),
            _c("li", [
              _vm._v(
                "商品の取り扱いには細心の注意を払っておりますが、組立て・梱包時や、運送時に不可抗力で小傷（擦り傷）などが付く場合がございます。"
              ),
              _c("br"),
              _vm._v(
                "その場合は不良扱いの範疇にはなりませんのであらかじめご了承くださいますようお願い致します。"
              ),
            ]),
            _c("br"),
            _c("li", [
              _vm._v(
                "組立てが必要な商品の場合、商品を組立てた後に傷や汚れに関してご連絡を頂きましてもご対応致しかねます。ご注意ください。"
              ),
            ]),
            _c("br"),
            _c("img", {
              attrs: { src: require("@/assets/images/pc/wood.png") },
            }),
            _c("br"),
            _c("br"),
            _vm._v(
              " 上記以外の交換・返品をご希望の方は、事前に必ずメールまたはお電話で当店までご連絡ください。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " その時お客様ご自身で対応箇所をデジカメ・スマートフォンで撮影して頂き、メールに画像添付して送信してください。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " お手続き・ご連絡がないまま当店センターに直接ご返品頂いても、ご返金できない場合がございますので、予めご了承くださいませ。 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "about" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("About returns")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("02 返金について")]),
        _c("div", { staticClass: "text-content" }, [
          _c("b", [_vm._v("【クレジットカード決済】")]),
          _c("br"),
          _c("br"),
          _vm._v(" カード会社を通じて返金処理を行います。"),
          _c("br"),
          _c("br"),
          _vm._v(
            " クレジットカード会社の締日のタイミングにより、同月内で相殺される場合や、一度請求が発生し、その後マイナス請求の形でご返金となる場合がございますので、ご了承下さい。"
          ),
          _c("br"),
          _vm._v(
            " 実際にクレジットカード会社から返金される時期や、クレジットカードご利用明細書に記載された金額についてご不明な点がある場合は、ご利用のクレジットカード会社に直接お問い合わせください。"
          ),
          _c("br"),
          _c("br"),
          _c("b", [_vm._v("【銀行振込のご利用】")]),
          _c("br"),
          _c("br"),
          _vm._v(
            " 銀行振り込みをご利用された方につきましてはご返金先口座情報をメールでお知らせ頂きますようお願い申し上げます。一週間程度でご指定の口座に送金致します。"
          ),
          _c("br"),
          _c("br"),
          _c("b", [_vm._v("【キャッシュレス決済】")]),
          _c("br"),
          _c("br"),
          _vm._v(
            " クレジットカードと同じように、決済の事業者に対して返金処理を行います。原則として現金での返金処理は行いません。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " どの方法で支払ったかによって、お客様への返金処理は多少異なります。"
          ),
          _c("br"),
          _vm._v(
            " チャージした残高から支払った場合、残高として返金が行われ、貯まったポイントで支払った場合は、ポイントで変換されます。"
          ),
          _c("br"),
          _vm._v(
            " また、コード決済アプリに登録したクレジットカードから支払われる場合もあり、このケースではクレジットカードの請求額から相殺されます。"
          ),
          _c("br"),
        ]),
        _c("div", { staticClass: "about" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("About refund")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("03 交換について")]),
        _c(
          "div",
          {
            staticClass: "text-content",
            staticStyle: { "line-height": "32px" },
          },
          [
            _vm._v(" 商品不良の場合を除き、商品を交換することはできません。"),
            _c("br"),
            _vm._v(
              " 別のカラー・サイズへの交換をご希望の場合は、お届けした商品を返品の上、改めてご注文ください。"
            ),
            _c("br"),
            _vm._v(
              " 商品不良で交換をご希望の場合は、オンラインショップ カスタマーサービスへお手続き前にご連絡ください。"
            ),
            _c("br"),
            _vm._v(
              " 在庫がある場合は同一商品と交換させていただきます。在庫がない場合は、ご返金させていただきます。 "
            ),
          ]
        ),
        _c("div", { staticClass: "about" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("About exchange")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("04 クーリングオフについて"),
        ]),
        _c(
          "div",
          {
            staticClass: "text-content",
            staticStyle: { "line-height": "32px" },
          },
          [
            _vm._v(" 当店サービスはクーリングオフの適用対象外です。"),
            _c("br"),
            _vm._v(
              " 当店の運営している通信販売事業は、クーリングオフの対象にはなりません。"
            ),
            _c("br"),
            _vm._v(
              " これは、勧誘や押し売り等、半強制的または圧力的なセールスを行っておらず、受動的なサービスであることに起因します。"
            ),
            _c("br"),
            _vm._v(
              " お客様の意思でご注文いただくサービスのため、クーリングオフは適用外となります。"
            ),
            _c("br"),
            _vm._v(
              " 誠に恐れ入りますが、ご注文の際には充分にご検討いただきますよう宜しくお願いいたします。"
            ),
            _c("br"),
            _vm._v(" ※特定商取引に関する法律 第十一条より "),
          ]
        ),
        _c("div", { staticClass: "about" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("About cooling off")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("05 長期不在・受取拒否")]),
        _c(
          "div",
          {
            staticClass: "text-content",
            staticStyle: { "line-height": "32px" },
          },
          [
            _vm._v(
              " 商品出荷後、理由なく長期不在・受取拒否などにより商品が返却された場合、往復の送料と手数料をご請求させて頂きます。"
            ),
            _c("br"),
            _vm._v(
              " 長期不在により商品が返却され、その後もご連絡を頂けない場合、キャンセル料として商品代金の50％～80％をご請求致します。"
            ),
            _c("br"),
            _vm._v(
              " また、返却後に弊社から再度商品を発送することはできませんので、改めてご注文頂けますようお願い申し上げます。"
            ),
            _c("br"),
            _vm._v(
              " 場合によって、利用者からの注文を承諾せず、又は売買契約を解除することができるものとします。 "
            ),
          ]
        ),
        _c("div", { staticClass: "about" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("About absence・rejection")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }