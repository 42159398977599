<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>マイページ</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/mine" />
      </div>
      <div class="right-content">
        <div class="swiper personal-swiper">
          <div class="swiper-wrapper">
            <div v-for="item in swiperData" :key="item.id" class="swiper-slide">
              <a v-if="item.href" :href="item.href">
                <img :src="item.image" :alt="item.alt">
              </a>
              <img v-else :src="item.image" :alt="item.alt">
            </div>
          </div>
          <div class="swiper-pagination" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPersonalSwiperList } from '@/api/personal'
import SideBar from './component/sideBar'
export default {
  components: { SideBar },
  data() {
    return {
      swiperData: []
    }
  },
  computed: {

  },
  created() {
    this.getPersonalSwiperList()
  },
  mounted() {

  },
  methods: {
    getPersonalSwiperList() {
      getPersonalSwiperList({ 'filter[type]': 3, type: 'all' }).then(result => {
        this.swiperData = result
        if (this.swiperData.length > 0) {
          this.$nextTick(() => {
            this.swiper()
          })
        }
      })
    },
    swiper() {
      new window.Swiper('.personal-swiper', {
        autoplay: false,
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 24,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        img{
            width: 100%;
        }
        .swiper-pagination ::v-deep{
            left: auto;
            bottom: 16px;
            right: 24px;
            display: inline-block;
            width: auto;
            .swiper-pagination-bullet{
            width: 48px;
            height: 6px;
            border: 1px solid #666666;
            background: transparent;
            margin: 0 8px;
            border-radius: 0;
            opacity: 1;
            }
            .swiper-pagination-bullet-active{
            background: #333333;
            }
        }
    }
}
</style>
