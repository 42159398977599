var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "tips" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / お問い合わせ"),
        ],
        1
      ),
      _c("div", { staticClass: "question-from" }, [
        _c("div", { staticClass: "question-table" }, [
          _c("div", { staticClass: "question-table-item" }, [
            _c("div", { staticClass: "desc" }, [_vm._v("お問い合わせ項目")]),
            _c("div", { staticClass: "ask" }, [
              _vm.ruleForm.type === 1
                ? _c("span", [_vm._v("商品について")])
                : _vm.ruleForm.type === 2
                ? _c("span", [_vm._v("納期について")])
                : _vm.ruleForm.type === 3
                ? _c("span", [_vm._v("不良交換について")])
                : _vm.ruleForm.type === 4
                ? _c("span", [_vm._v("サイトについて")])
                : _vm.ruleForm.type === 5
                ? _c("span", [_vm._v("その他")])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "question-table-item" }, [
            _c("div", { staticClass: "desc" }, [_vm._v("お名前")]),
            _c("div", { staticClass: "ask" }, [
              _vm._v(_vm._s(_vm.ruleForm.username)),
            ]),
          ]),
          _c("div", { staticClass: "question-table-item" }, [
            _c("div", { staticClass: "desc" }, [_vm._v("メールアドレス")]),
            _c("div", { staticClass: "ask" }, [
              _vm._v(_vm._s(_vm.ruleForm.email)),
            ]),
          ]),
          _c("div", { staticClass: "question-table-item" }, [
            _c("div", { staticClass: "desc" }, [_vm._v("お問い合わせ内容")]),
            _c("div", { staticClass: "ask" }, [
              _vm._v(_vm._s(_vm.ruleForm.content)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "button" },
          [
            _c(
              "el-button",
              {
                staticClass: "deliver",
                attrs: { loading: _vm.btnLoading, type: "primary" },
                on: { click: _vm.onSubmit },
              },
              [_vm._v("送信する")]
            ),
            _c(
              "el-button",
              {
                staticClass: "return",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v("修正する")]
            ),
          ],
          1
        ),
      ]),
      _c("van-overlay", { attrs: { show: _vm.confirmVisible } }, [
        _c(
          "div",
          {
            staticClass: "wrapper",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("img", {
                staticClass: "close-btn",
                attrs: { src: require("@/assets/images/sp/close_btn.png") },
                on: { click: _vm.handleClose },
              }),
              _c("div", { staticClass: "title" }, [
                _vm._v("お問い合わせ受付完了"),
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v("お問い合わせありがとうございます。"),
                _c("br"),
                _vm._v("順次ご対応いたします、しばらくお待ちください。"),
              ]),
              _c(
                "div",
                { staticClass: "close", on: { click: _vm.handleClose } },
                [_vm._v("閉じる")]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }