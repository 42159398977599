<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / 無垢材について
    </div>
    <div class="about-content">
      <img src="@/assets/images/sp/about_banner.png" alt="">
      <div class="desc-text">
        <div class="title">無垢材100％</div>
        <div class="text">激安家具量販店と違い、ウッドライフ(woodlife)の家具は、化粧合板やパーチクルボードなどの集成材は一切使用しません。</div>
        <div class="text">材料は主として北アメリカのアパラチア山脈で合法的に伐採された一本の原木から直接必要な寸法に切り出したものを使って製作しています。</div>
        <div class="text">厳しい自然環境の中であ外年月をかけて成長した木材を、更に時間をかけて乾燥させ、製材し、多くの人の手を掛けながら、ようやく家具作りに使えるようになりました。</div>
      </div>
      <div class="desc-text">
        <div class="title">家族が安心して使える家具<br>づくりに専念</div>
        <div class="text">当社の製品はすべて天然の植物油を主成分とし、木材の表面に浸透して保護する健康塗料です。</div>
        <div class="text">更に、熱や水に強く、傷や汚れが付きにくくため、 非常に薄い樹脂の塗膜仕上げです。 (EU環境保護基準無臭塗料)</div>
        <div class="text">家具はすべて取り寄せを行い、加工や仕上げ、 梱包までの工程を一貫して自社で行っています。</div>
        <div class="text">お客様一人ひとりの好みに応じた製品を、<br>一つひとつ手間を惜しまず、丁寧に作り上げています。</div>
      </div>
      <img src="@/assets/images/sp/about_img2.png" alt="" class="img2">
      <div class="wood-material">
        <div class="title">Wood Material</div>
        <div class="desc">※北欧原産FASグレード木材を多用、<br>節の少ない広く長いクリアー材が取れることから高級家具、<br>造作などに適しています。<br>全米広葉樹製材協会（NHLA） が定める「FAS規格（First And Seconds）」認証済材。</div>
        <div class="wood-lists">
          <div class="item">
            <div class="icon">OAK</div>
            <div class="right-desc">
              <div class="name">オーク</div>
              <div class="text">くっきりとした美しい木目を持ちながら、ナチュラルな風合いを感じさせる木材。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">WALNUT</div>
            <div class="right-desc">
              <div class="name">ウォールナット</div>
              <div class="text">落ち着いた色調と滑らかな質感を持ち、すぐれた性質を兼ね備えている高級材。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">CHERRY</div>
            <div class="right-desc">
              <div class="name">チェリー</div>
              <div class="text">赤みの強い色が特徴で、美しい経年変化が楽しめる木材。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">BEECH</div>
            <div class="right-desc">
              <div class="name">ブナ</div>
              <div class="text">通直な木理と密な肌目を持ち、頑丈で変形しにくい。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">ASH</div>
            <div class="right-desc">
              <div class="name">アッシュ</div>
              <div class="text">適度に堅く、耐久力に富む。経年変化が少なく使い続けても雰囲気が変わりにくい。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="production-process">
        <div class="title">Production Process</div>
        <div class="desc">天然無垢材という自然素材を製品にするために、<br>木の表情を読み解き、木の状態を知り、<br>木を切り、削り、磨きながら1つの製品を作り上げます。</div>
        <img src="@/assets/images/sp/about_production_process.png" alt="">
      </div>
      <div class="about-product">
        <div class="title">Products</div>
        <img src="@/assets/images/sp/about_product.png" alt="">
      </div>
    </div>
    <a href="https://www.instagram.com/woodlife.jp/" target="_blank">
      <div class="button">公式Instagram投稿をもっと見る</div>
    </a>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .about-content{
      margin-top: .16rem;
      .desc-text{
        padding: 0 .24rem;
        margin-top: .94rem;
        text-align: center;
        .title{
          margin-bottom: .4rem;
          font-size: .48rem;
          font-weight: 700;
        }
        .text{
          margin-bottom: .32rem;
          line-height: .36rem;
        }
      }
      .img2{
        margin-top: .58rem;
      }
      .wood-material{
        width: 100%;
        height: 16.82rem;
        padding: .9rem .24rem 0;
        background: url('~@/assets/images/sp/about_wood_material.png') no-repeat center center / 100%;
        box-sizing: border-box;
        .title{
          text-align: center;
          font-size: .48rem;
          font-weight: 700;
        }
        .desc{
          margin-top: .1rem;
          line-height: .36rem;
          text-align: center;
          font-size: .24rem;
          color: #999999;
        }
        .wood-lists{
          margin-top: .6rem;
          .item{
            display: flex;
            justify-content: space-between;
            margin-bottom: .4rem;
            &:nth-child(1){
              .icon{
                background: url('~@/assets/images/sp/about_wood1.png') no-repeat center center / 100%;
              }
            }
            &:nth-child(2){
              .icon{
                background: url('~@/assets/images/sp/about_wood2.png') no-repeat center center / 100%;
              }
            }
            &:nth-child(3){
              .icon{
                background: url('~@/assets/images/sp/about_wood3.png') no-repeat center center / 100%;
              }
            }
            &:nth-child(4){
              .icon{
                background: url('~@/assets/images/sp/about_wood4.png') no-repeat center center / 100%;
              }
            }
            &:nth-child(5){
              .icon{
                background: url('~@/assets/images/sp/about_wood5.png') no-repeat center center / 100%;
              }
            }
            .icon{
              width: 2rem;
              height: 2rem;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              font-weight: 700;
            }
            .right-desc{
              width: 4.78rem;
              .name{
                font-size: .4rem;
                font-weight: 700;
                color: 000000;
              }
              .text{
                margin-top: .1rem;
                line-height: .42rem;
                color: #999999;
              }
            }
          }
        }
      }
      .production-process{
        margin-top: .8rem;
        text-align: center;
        .title{
          font-size: .48rem;
          font-weight: 700;
        }
        .desc{
          margin-top: .1rem;
          margin-bottom: .12rem;
          line-height: .36rem;
          font-size: .24rem;
          color: #999999;
        }
      }
    }
    .about-product{
      margin-top: .3rem;
      .title{
        margin-bottom: .34rem;
        text-align: center;
        font-size: .48rem;
        font-weight: 700;
      }
    }
    .button{
        height: 1.08rem;
        margin: .8rem .24rem 0;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
