<template>
  <div class="simple-footer">
    <router-link to="/">
      <img src="@/assets/images/sp/logo_footer.png" class="logo">
    </router-link>
    <div class="footer-box">© HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED.</div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  watch: {

  }
}
</script>
<style lang="scss" scoped>
.simple-footer{
    width: 100%;
    height: 1.58rem;
    margin-top: 1.12rem;
    text-align: center;
    background: #292e32;
    .logo{
        width: 4rem;
        margin-top: .24rem;
    }
    .footer-box{
        margin-top: .24rem;
        padding-top: .16rem;
        border-top: .02rem solid #363b3f;
        color: #6a6d70;
        font-size: .24rem;
    }
}
</style>
