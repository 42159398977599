<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / 配送・送料について
    </div>
    <div class="title-content">
      配送·送料について
      <img src="@/assets/images/sp/guides_delivery.png">
    </div>
    <div class="lists-content">
      <div class="list">
        <div class="title">01 配送について</div>
        <div class="bg-box">
          配送は玄関先までとなります。<br>開梱や組立、梱包材の回収はございません。
        </div>
        <div class="desc">
          配送会社（ヤマト運輸・佐川急便・メーカーから直送）にて発送しております。配送会社ご指定ありの場合は差額分お客様ご負担となります。<br><br>
          <span class="red">配送希望日にお応えできない場合、搬入出来なかった場合も、ご注文キャンセル・返品などはお受けできませんので、予めご了承くださいませ。</span><br><br>
          ※＜開梱設置＞無料・有料ご選択された場合、開梱・組み立て・梱包材の引き上げまで行います。<br><br>
          <router-link to="/guides/install">＞＞搬入・組立てについてこちらのページにてご確認ください。</router-link>
        </div>
        <div class="red-box">
          北海道・沖縄県・その他の離島につきましてはご利用・お届けができないことを予めご了承ください。
        </div>
        <img src="@/assets/images/sp/delivery.png">
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About delivery</span>
        </div>
      </div>
      <div class="list">
        <div class="title">02 配送日時について</div>
        <div class="desc">
          大型家具商品(受注生産)のお届けまでは時間がかかるため、ご注文の際に配送希望日時の指定が不可となっております。<br><br>
          通常在庫品は、ご入金確認後から1週間程度で順次発送をさせていただきます。<br><br>
          受注生産品は各商品ページでご確認ください。最大約60日前後発送いたします。<br><br>
          発送準備が完了した商品から「ご注文商品出荷のお知らせ」メールにてご連絡し、順番に出荷となります。<br><br>
          工場生産状況や港の混雑に伴いにより、お届けが遅れる場合がございますので、予めご了承くださいませ。<br><br>
          ★★複数商品をご注文の場合のお届け★★<br><br>
          すべての商品のお届けができる最短のお日にちにて、まとめて発送致します。<br><br>
          ただし、欠品等で納期が大幅に遅れてしまう場合は、複数に分けてのお届けとなる場合がございます。<br><br>
          その際は、メール、またはお電話にて手配方法についてご相談させていただきます。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About delivery date and time</span>
        </div>
      </div>
      <div class="list">
        <div class="title">03 送料について</div>
        <div class="desc">
          送料は、1回のご注文につき3,000円（税込）となります。<br><br>
          <span class="red">※北海道・沖縄・鹿児島・離島へのお届けは、別途追加送料が必要です。</span><br><br>
        </div>
        <table class="table">
          <tr>
            <td>合計が54,000円(税込)未満</td>
            <td>合計が54,000円(税込)以上</td>
          </tr>
          <tr>
            <td>送料3,000円</td>
            <td>送料無料</td>
          </tr>
        </table>
        <div class="desc">
          対象外 ※配送業者による組立作業などの付帯料金・手数料<br><br>
          お客様都合による受取辞退、長期不在、再発送をされますと送料は別途かかります。（再送・転送料金込み）
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About shipping cost</span>
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">他のご質問はこちら</div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .red{
      color: #c40a0a;
      font-weight: bold;
    }
    a{
      text-decoration: underline;
    }
    .title-content{
        position: relative;
        width: 100%;
        height: .88rem;
        padding: 0 .24rem;
        margin-top: .36rem;
        line-height: .88rem;
        font-size: .32rem;
        font-weight: 700;
        background: linear-gradient(90deg,#f4f5fc 1%, #ffffff 100%);
        box-sizing: border-box;
        img{
            position: absolute;
            right: 0;
            top: -.3rem;
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    .lists-content{
        margin-top: .56rem;
        .list{
            padding-bottom: .4rem;
            border-bottom: .02rem solid #e8ebed;
            &:not(:last-child){
                margin-bottom: .4rem;
            }
            .title{
                margin-bottom: .32rem;
                font-size: .32rem;
                font-weight: 700;
            }
            .bg-box{
                width: 100%;
                padding: .28rem .24rem;
                margin-top: .4rem;
                margin-bottom: .24rem;
                background: #f4f5fc;
                box-sizing: border-box;
                font-weight: 500;
                line-height: .4rem;
            }
            .red-box{
                width: 100%;
                padding: .28rem .24rem;
                margin: .32rem 0;
                background: #fcf0f0;
                color: #c40a0c;
                box-sizing: border-box;
                font-weight: 500;
                line-height: .4rem;
            }
            .desc{
                line-height: .42rem;
            }
            .s-title{
                margin-top: .34rem;
                text-align: right;
                font-size: .24rem;
                color: #999999;
                img{
                    width: .16rem;
                    height: .2rem;
                    vertical-align: middle;
                }
                span{
                    margin-left: .08rem;
                    vertical-align: middle;
                }
            }
            .table{
                width: 100%;
                margin: .24rem 0;
                font-size: .24rem;
                td{
                    padding: .3rem 0;
                    text-align: center;
                    border: .02rem solid #333333;
                }
            }
        }
    }
    .button{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
