// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/pc/personal_side_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sidebar[data-v-1df6388d] {\n  width: 280px;\n  border: 1px solid #e8ebed;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  font-size: 16px;\n}\n.sidebar .username[data-v-1df6388d] {\n  width: 100%;\n  height: 91px;\n  line-height: 91px;\n  font-size: 28px;\n  font-weight: 700;\n  text-align: center;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.sidebar .list-content .list[data-v-1df6388d] {\n  padding: 8px 0;\n  border-top: 1px solid #e8ebed;\n  cursor: pointer;\n}\n.sidebar .list-content .list div[data-v-1df6388d] {\n  height: 40px;\n  line-height: 40px;\n}\n.sidebar .list-content .list div img[data-v-1df6388d] {\n  margin-left: 16px;\n}\n.sidebar .list-content .list div span[data-v-1df6388d] {\n  margin-left: 8px;\n}\n.sidebar .list-content .list div.current[data-v-1df6388d] {\n  border-left: 4px solid #333333;\n}\n.sidebar .list-content .list div.current img[data-v-1df6388d] {\n  margin-left: 24px;\n}\n.sidebar .list-content .list img[data-v-1df6388d], .sidebar .list-content .list span[data-v-1df6388d] {\n  vertical-align: middle;\n}", ""]);
// Exports
module.exports = exports;
