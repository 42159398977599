// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/pc/question_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".question-title[data-v-8182f84a] {\n  height: 140px;\n  line-height: 140px;\n  font-size: 28px;\n  color: #333;\n  text-align: center;\n  font-weight: bold;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center/auto 100%;\n  margin-top: -26px;\n}\n.breadcrumb[data-v-8182f84a] {\n  width: 1440px;\n  line-height: 17px;\n  margin: 28px auto 0;\n}", ""]);
// Exports
module.exports = exports;
