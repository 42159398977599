var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen" }, [
    _c("div", { staticClass: "screen-title" }, [_vm._v("検索")]),
    _c("div", { staticClass: "input" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.title,
            expression: "title",
          },
        ],
        attrs: { type: "text", placeholder: "search here…" },
        domProps: { value: _vm.title },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.screen()
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.title = $event.target.value
          },
        },
      }),
      _c("img", {
        attrs: { src: require("@/assets/images/pc/article_screen.png") },
        on: {
          click: function ($event) {
            return _vm.screen()
          },
        },
      }),
    ]),
    _vm.keywordsData.length > 0
      ? _c(
          "div",
          { staticClass: "keywords" },
          _vm._l(_vm.keywordsData, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                on: {
                  click: function ($event) {
                    return _vm.keywordsChange(item.name)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "category" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("コンテンツ")]),
        _vm._l(_vm.categoriesData, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.changeCategory(item.id)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }