<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/guides/category">ご利用ガイド</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>お支払い方法について</span>
    </div>
    <div class="list-content">
      <div class="list">
        <div class="title">01 クレジット決済</div>
        <div class="text-content">
          ご利用可能なクレジットカード<br>
          <img src="@/assets/images/pc/payment_img.png" class="pay">
          ※海外発行のクレジットカードはご利用いただけませんので、あらかじめご了承ください。<br><br>
          また、決済処理はご注文確定の際におこなっております。<br><br>
          引き落とし名義は「はんこプレミ<br><br>
          アム」と記載されます。 引き落とし日時につきましては、ご利用のクレジットカードの締め日や契約内容により異なりますので、ご利用のクレジットカード会社に直接お問合せをお願いいたします。
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>Credit payment</span>
        </div>
      </div>
      <div class="list">
        <div class="title">02 銀行振込について</div>
        <div class="text-content">
          銀行振込は前払いですので、お支払いを確認させていただいてからの生産及び出荷手配となります。<br><br>
          お振込は下記の口座にお願いいたします。
        </div>
        <table>
          <tr>
            <td>三井住友銀行</td>
            <td>ゆうちょ銀行</td>
          </tr>
          <tr>
            <td>船橋支店 普通 7154834</td>
            <td>記号10580 番号37025671</td>
          </tr>
          <tr>
            <td colspan="2">口座名義&nbsp;&nbsp;ハンコプレミアム( カ</td>
          </tr>
        </table>
        <p class="line24">
          ※振込み手数料はお客様のご負担となりますのでご了承ください。<br>※ご注文後の7日以内にお振込み下さい。当店よりお振込用紙は送付いたしておりません。
        </p>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>Bank transfer</span>
        </div>
      </div>
      <div class="list">
        <div class="title">03 Amazon pay</div>
        <div class="text-content line24">
          Amazonに登録されている住所・クレジットカード情報を使用してお支払いができるサービスです。<br>
          会員登録をせずにご購入の際はログイン画面よりAmazon Payを選択の上、決済手続きにお進みください。
          <img src="@/assets/images/pc/guides_amazon.png" class="amazon">
        </div>
        <div class="warn">
          <p><b>⚠️ 【注意事項】</b></p>
          <p class="line24">
            会員サービス（ポイント・クーポン等）をご利用いただくには、「」会員情報でのログインが必要です。<br>
            ※Amazonアカウントでログインいただいた場合、AmazonPay以外のお支払い方法は選択できません。<br>
            ※Amazonポイントはつきません。※予約商品のご注文の際、当支払方法はご利用できません。<br>
            ※Amazonアカウントでご注文後は配送先・お支払い方法の変更はできません。
          </p>
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>Amazonpay</span>
        </div>
      </div>
      <div class="list">
        <div class="title">04 PayPay</div>
        <div class="text-content line24">
          PayPayは事前にチャージした電子マネー(PayPay残高)を使ってお支払いができるサービスです。<br>
          詳しいお支払手順はこちらからご確認ください。
          <img src="@/assets/images/pc/guides_paypay.png" class="paypay">
        </div>
        <div class="warn">
          <p><b>⚠️ 【注意事項】</b></p>
          <p class="line24">
            ネットサービスの支払いで使用できるのはPayPay残高のみとなります。<br>
            ※残高による一括払いのみとなり残高不足分の場合はご利用いただけません。<br>
            ※PayPayから他のお支払い方法への変更はできません。<br>
            ※注文後、お客様ご自身でのキャンセルはできません。当店までお問い合わせください。<br>
            また、購入商品の一部キャンセルは承ることはできませんので、予めご了承ください。
          </p>
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>Paypay</span>
        </div>
      </div>
      <div class="list">
        <div class="title">05 楽天ペイ</div>
        <div class="text-content line24">
          いつもの楽天IDとパスワードを使ってスムーズなお支払いが可能です。<br>
          楽天ポイントが貯まる・使える！「簡単」「あんしん」「お得」な楽天ペイをご利用ください。<br>
          楽天ペイの詳細については <a href="https://checkout.rakuten.co.jp/" target="_blank">こちら</a> よりお願いします。
          <img src="@/assets/images/pc/guides_rakuten.png" class="rakuten-pay">
        </div>
        <div class="warn">
          <p><b>⚠️ 【注意事項】</b></p>
          <p class="line24">
            ※ご利用には楽天IDが必要です。<br>
            ※ご利用の際、楽天ペイのお支払い画面に移動してご購入手続きを行ってください。<br>
            ※楽天ポイントのご利用履歴は「<a href="https://my.checkout.rakuten.co.jp/mych/" target="_blank">楽天ペイ利用履歴</a>」にてご確認ください。当サイトのマイページには表示されません。<br>
            ※クレジットカードの有効期限により、お支払い方法を変更していただく場合があります。あらかじめご了承ください。<br>
            ※楽天ポイントが貯まるのは楽天カード・楽天ポイント・楽天キャッシュでのお支払いに限ります。<br>
            ※取り寄せ品・入荷予定商品につきましては、お支払方法としてご利用いただけません。予めご了承をお願いいたします。
          </p>
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>楽天ペイ</span>
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">
        <span>他のご質問はこちら</span>
        <img src="@/assets/images/pc/button_arrow.png">
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.guides-content{
    width: 1440px;
    margin: 0 auto 80px;
    font-size: 16px;
    .line24{
        line-height: 24px;
        a{
          color: blue;
        }
    }
    .breadcrumb{
        margin-top: 28px;
    }
    .list-content{
        .list{
            padding: 38px 32px 0;
            margin-top: 45px;
            border-top: 1px solid #e8ebed;
            &:last-child{
                padding-bottom: 45px;
                border-bottom: 1px solid #e8ebed;
            }
            .title{
                font-size: 20px;
                font-weight: 700;
            }
            .text-content{
                position: relative;
                margin-top: 24px;
                .pay{
                    display: block;
                    margin: 20px 0;
                }
                 .amazon{
                    position: absolute;
                    top: 0;
                    left: 895px;
                }
                .paypay{
                    position: absolute;
                    top: -10px;
                    left: 895px;
                }
                .rakuten-pay{
                  position: absolute;
                  top: -20px;
                  left: 900px;
                  width: 108px;
                }
            }
            .warn{
                margin-top: 16px;
                p:nth-child(1){
                    margin-bottom: 6px;
                }
            }
            table {
                width: 482px;
                margin: 20px 0;
                td{
                    width: 240px;
                    height: 48px;
                    border: 1px solid #333333;
                    text-align: center;
                }
            }
            .about{
                margin-top: 28px;
                font-size: 12px;
                color: #999;
                span{
                    margin-left: 8px;
                }
            }
        }
    }
    .button{
      width: 248px;
      height: 48px;
      margin: 52px auto 0;
      line-height: 48px;
      text-align: center;
      background-color: #333;
      color: #fff;
      cursor: pointer;
      img{
          margin-left: 4px;
      }
    }
}
</style>
3
