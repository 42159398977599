import request from '@/utils/request'

// 获取优惠券列表
export function getMyCouponList(params) {
  return request({
    url: '/coupons/current',
    method: 'get',
    params
  })
}
// 获取当前可用优惠券
export function getOrderCouponList(params) {
  return request({
    url: '/coupons/getOrderCoupons',
    method: 'get',
    params
  })
}
// 领取优惠券
export function getCodeCoupon(data) {
  return request({
    url: '/coupons/draw',
    method: 'post',
    data
  })
}
