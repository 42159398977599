import request from '@/utils/request'
/**
 * 商品分类api
 */

// 获取分类树
export function getCategoryTree(params) {
  return request({
    url: '/categories/tree',
    method: 'get',
    params
  })
}
// url 获取分类详情
export function getCategoryDetail(url, params) {
  return request({
    url: '/categories/' + url,
    method: 'get',
    params
  })
}
// 获取分类关联标签
export function getTagCategories(id, params) {
  return request({
    url: '/getTagCategories/' + id,
    method: 'get',
    params
  })
}
// 获取推荐分类
export function getCategoryList(params) {
  return request({
    url: '/categories',
    method: 'get',
    params
  })
}
