<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/guides/category">ご利用ガイド</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>無垢材について</span>
    </div>
    <div class="about-content">
      <div class="wood-material">
        <div class="desc-text">
          <div class="title">Wood Material</div>
          <div class="text">※北欧原産FASグレード木材を多用、節の少ない広く長いクリアー材が取れることから高級家具、造作などに適しています。<br>全米広葉樹製材協会（NHLA）が定める「FAS規格（First And Seconds）」認証済材。</div>
        </div>
        <div class="wood-lists">
          <div class="item">
            <div class="icon">OAK</div>
            <div class="desc">
              <div class="name">オーク</div>
              <div class="text">くっきりとした美しい木目を持ちながら、<br>ナチュラルな風合いを感じさせる木材。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">WALNUT</div>
            <div class="desc">
              <div class="name">ウォールナット</div>
              <div class="text">落ち着いた色調と滑らかな質感を持ち、<br>すぐれた性質を兼ね備えている高級材。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">CHERRY</div>
            <div class="desc">
              <div class="name">チェリー</div>
              <div class="text">赤みの強い色が特徴で、<br>美しい経年変化が楽しめる木材。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">BEECH</div>
            <div class="desc">
              <div class="name">ブナ</div>
              <div class="text">通直な木理と密な肌目を持ち、<br>頑丈で変形しにくい。</div>
            </div>
          </div>
          <div class="item">
            <div class="icon">ASH</div>
            <div class="desc">
              <div class="name">アッシュ</div>
              <div class="text">適度に堅く、耐久力に富む。<br>経年変化が少なく使い続けても雰囲気が変わりにくい。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <img src="@/assets/images/pc/about_banner.png" alt="" class="banner">
        <div class="desc-text">
          <div class="title">無垢材100％</div>
          <div class="text">
            激安家具量販店と違い、ウッドライフ(woodlife)の家具は、化粧合板やパーチクルボードなどの集成材は一切使用しません。<br>
            材料は主として北アメリカのアパラチア山脈で合法的に伐採された一本の原木から直接必要な寸法に切り出したものを使って製作しています。<br>
            厳しい自然環境の中であ外年月をかけて成長した木材を、更に時間をかけて乾燥させ、製材し、多くの人の手を掛けながら、<br>
            ようやく家具作りに使えるようになりました。
          </div>
        </div>
        <div class="bg-content">
          <div class="desc-text">
            <div class="title">家族が安心して使える家具づくりに専念</div>
            <div class="text">
              当社の製品はすべて天然の植物油を主成分とし、木材の表面に浸透して保護する健康塗料です。<br>
              更に、熱や水に強く、傷や汚れが付きにくくため、非常に薄い樹脂の塗膜仕上げです。(EU環境保護基準無臭塗料)<br>
              家具はすべて受注生産を行い、加工や仕上げ、梱包までの工程を一貫して自社で行っています。<br>
              お客様一人ひとりの好みに応じた製品を、一つひとつ手間を惜しまず、丁寧に作り上げています。
            </div>
          </div>
        </div>
        <div class="production-process">
          <div class="desc-text">
            <div class="title">Production Process</div>
            <div class="text">天然無垢材という自然素材を製品にするために、木の表情を読み解き、木の状態を知り、木を切り、削り、磨きながら1つの製品を作り上げます。</div>
          </div>
          <img src="@/assets/images/pc/about_production_process.png" alt="">
        </div>
        <div class="about-product">
          <div class="title">Products</div>
          <img src="@/assets/images/pc/about_product.png" alt="">
        </div>
      </div>
    </div>
    <div class="button">
      <a href="https://www.instagram.com/woodlife.jp/" target="_blank">
        <span>公式Instagram投稿をもっと見る</span>
        <img src="@/assets/images/pc/button_arrow.png">
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.guides-content{
    margin-bottom: 80px;
    font-size: 16px;
    .breadcrumb{
        width: 1440px;
        margin: 28px auto 0;
    }
    .about-content{
      position: relative;
      .main{
        width: 1440px;
        margin: 46px auto 0;
      }
      .banner{
        margin-bottom: 70px;
      }
      .desc-text{
        text-align: center;
        .title{
          font-weight: 700;
          font-size: 32px;
        }
        .text{
          margin-top: 20px;
          font-size: 16px;
          line-height: 36px;
        }
      }
      .bg-content{
        width: 100%;
        height: 1095px;
        padding-top: 40px;
        margin-top: 52px;
        box-sizing: border-box;
        background: url('~@/assets/images/pc/about_bg.png');
      }
      .wood-material{
        position: absolute;
        top: 1686px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 1018px;
        padding-top: 360px;
        background: url('~@/assets/images/pc/about_wood_material.png');
        box-sizing: border-box;
        .desc-text{
          .text{
            color: #999999;
          }
        }
        .wood-lists{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 1440px;
          margin: 70px auto 0;
          .item{
            display: flex;
            .icon{
              width: 120px;
              height: 120px;
              margin-right: 16px;
              text-align: center;
              line-height: 120px;
              color: #ffffff;
              font-weight: 700;
            }
            .desc{
              padding-top: 22px;
              .name{
                font-weight: 700;
              }
              .text{
                margin-top: 6px;
                line-height: 24px;
              }
            }
            &:nth-child(1){
              .icon{
                background: url('~@/assets/images/pc/about_wood1.png');
              }
            }
            &:nth-child(2){
              .icon{
                background: url('~@/assets/images/pc/about_wood2.png');
              }
            }
            &:nth-child(3){
              .icon{
                background: url('~@/assets/images/pc/about_wood3.png');
              }
            }
            &:nth-child(4){
              margin-top: 70px;
              margin-left: 180px;
              .icon{
                background: url('~@/assets/images/pc/about_wood4.png');
              }
            }
            &:nth-child(5){
              margin-top: 70px;
              margin-right: 180px;
              .icon{
                background: url('~@/assets/images/pc/about_wood5.png');
              }
            }
          }
        }
      }
      .production-process{
        margin-top: 846px;
        .desc-text{
          margin-bottom: 48px;
        }
      }
      .about-product{
        margin-top: 50px;
        .title{
          margin-bottom: 26px;
          font-size: 32px;
          font-weight: 700;
          text-align: center;
        }
      }
    }
    .button{
      width: 340px;
      height: 48px;
      margin: 52px auto 0;
      line-height: 48px;
      text-align: center;
      background-color: #333;
      color: #fff;
      cursor: pointer;
      img{
          margin-left: 4px;
      }
    }
}
</style>
