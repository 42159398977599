import Vue from 'vue'
import { Locale } from 'vant'
import jaJP from 'vant/es/locale/lang/ja-JP'
Locale.use('ja-JP', jaJP)
import {
  Toast,
  Rate,
  Overlay,
  Uploader,
  ImagePreview,
  Popup
} from 'vant'
Vue.component(Rate.name, Rate)
Vue.component(Overlay.name, Overlay)
Vue.component(Uploader.name, Uploader)
Vue.component(Popup.name, Popup)

Vue.prototype.$Toast = Toast
Vue.prototype.$ImagePreview = ImagePreview
