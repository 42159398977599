<template>
  <div>
    <div class="swiper home-page-swiper">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in swiperData" :key="index" class="swiper-slide">
          <a v-if="item.href" :href="item.href">
            <img :src="item.image" :alt="item.alt">
          </a>
          <img v-else :src="item.image" :alt="item.alt">
        </div>
      </div>
      <!-- <div class="swiper-pagination" />
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" /> -->
    </div>
    <div class="custom" v-html="customData.pc_content" />
    <div class="article-box">
      <div class="article-item">
        <div class="title">
          <span>NEWS</span>
          <router-link to="/article">view more</router-link>
        </div>
        <router-link v-for="item in newsArticleData" :key="item.id" :to="`/article/${item.id}`" class="article-item-box">
          <img :src="item.image">
          <div class="article-desc">
            <div>{{ item.title }}</div>
            <div>{{ item.description }}</div>
            <div>{{ item.created_at.substring(0,10) }}</div>
          </div>
        </router-link>
      </div>
      <div class="article-item">
        <div class="title">
          <span>INFORMATION</span>
          <router-link to="/article">view more</router-link>
        </div>
        <router-link v-for="item in informationArticleData" :key="item.id" :to="`/article/${item.id}`" class="article-item-box">
          <img :src="item.image">
          <div class="article-desc">
            <div>{{ item.title }}</div>
            <div>{{ item.description }}</div>
            <div>{{ item.created_at.substring(0,10) }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="our-service-content">
      <div class="title">OUR SERVICES</div>
      <div class="item-content">
        <div class="item">
          <img src="@/assets/images/pc/our_services_1.png" alt="組立設置サービス">
          <div class="name">組立設置サービス</div>
          <div class="desc">配送員が開梱・組立・設置・梱包資材の回収までを行います。</div>
        </div>
        <div class="item">
          <img src="@/assets/images/pc/our_services_2.png" alt="送料無料">
          <div class="name">送料無料</div>
          <div class="desc">北海道 · 沖縄県 · 離島、山間部の配送は別途お見積もりとなります。</div>
        </div>
        <div class="item">
          <img src="@/assets/images/pc/our_services_3.png" alt="安心の保証">
          <div class="name">安心の保証</div>
          <div class="desc">商品のお届け日から１年間を保証期間として、無償修理致します。</div>
        </div>
        <div class="item">
          <img src="@/assets/images/pc/our_services_4.png" alt="AmazonPay対応">
          <div class="name">AmazonPay対応</div>
          <div class="desc">Amazonアカウント情報を使って簡単にログインし、安心、安全に商品を購入することが可能です。</div>
        </div>
      </div>
    </div>
    <div class="ins-content">
      <div class="title">INSTAGRAM</div>
      <div class="desc">@woodlife.jp</div>
      <div id="c7c2cf80_1678778462" class="powr-instagram-feed" />
    </div>
    <div v-if="productData.length" class="product-content">
      <div class="title">PRODUCTS</div>
      <div class="desc">商品情報</div>
      <div class="waterfall-container">
        <div v-for="item in productData" :key="item.id" class="waterfall-item">
          <router-link :to="`/product/${item.product_sn}`">
            <div class="image" :class="{active:item.active}">
              <img :src="item.image" :alt="item.image_alt">
              <div class="collect" @click="event=>handleCollection(event,item)">
                <img v-if="item.isCollect" src="@/assets/images/sp/home_collect_in.png">
                <img v-else src="@/assets/images/sp/home_collect.png">
              </div>
            </div>
          </router-link>
        </div>

      </div>
      <div v-show="currentPage !== allPage" class="view-more" @click="handleMoreProduct">他の商品を表示する</div>
    </div>
    <!-- <div class="commitment">
      <div class="title">COMMITMENT</div>
      <div class="desc">こだわり</div>
      <div class="commitment-title">
        <div :class="{active:commitment === 1}" @click="commitment = 1">
          <img v-show="commitment !== 1" src="@/assets/images/pc/commitment_1.png">
          <img v-show="commitment === 1" src="@/assets/images/pc/commitment_active_1.png">
        </div>
        <div :class="{active:commitment === 2}" @click="commitment = 2">
          <img v-show="commitment !== 2" src="@/assets/images/pc/commitment_2.png">
          <img v-show="commitment === 2" src="@/assets/images/pc/commitment_active_2.png">
        </div>
        <div :class="{active:commitment === 3}" @click="commitment = 3">
          <img v-show="commitment !== 3" src="@/assets/images/pc/commitment_3.png">
          <img v-show="commitment === 3" src="@/assets/images/pc/commitment_active_3.png">
        </div>
      </div>
      <div v-show="commitment === 1" class="commitment-desc">
        <img src="@/assets/images/pc/commitment_desc_1.png">
        <div class="commitment-copywriting">
          <div>無垢材使用</div>
          <div>
            当店の木製品に使用される木は、北アメリカとヨーロッパの広葉樹林で育つ優れた木であり、一本の原木から角材や板を直接必要な寸法に切り出したものを使用しています。 <br><br>
            無垢材の利点は、質感が良く、接着剤の量が非常に少なく、より安定していて強いことです。
          </div>
        </div>
      </div>
      <div v-show="commitment === 2" class="commitment-desc">
        <img src="@/assets/images/pc/commitment_desc_2.png">
        <div class="commitment-copywriting">
          <div>RoHS指令対応塗料</div>
          <div>ウッドワックスオイル仕上げ。EU（欧州連合）RoHS指令に該当する有害物質(鉛、クロム、トルエン、キシレン)を含まず環境に配慮したアクリルラッカー塗料を使用しています。</div>
        </div>
      </div>
      <div v-show="commitment === 3" class="commitment-desc">
        <img src="@/assets/images/pc/commitment_desc_3.png">
        <div class="commitment-copywriting">
          <div>安全基準ワックスオイル塗装</div>
          <div>有機ホホバ油で作られ、植物油の自然な香りがあり、無毒で、環境保護は子供に影響を与えません。</div>
        </div>
      </div>
    </div>
    <div class="foollow">
      <div class="title">FOLLOW US</div>
      <div class="email-box">
        <div>イベントのご招待やアウトレットショップ開催のお知らせをいち早くお届けします。</div>
        <div class="email-input">
          <input v-model="email" type="text" placeholder="E-mail:">
          <div class="email-button" @click="emailSubmit">
            <img src="@/assets/images/pc/email.png">
            <span>Eメール登録</span>
          </div>
        </div>
      </div>
      <div class="share">
        <div>OFFICIAL SNS</div>
        <a href="https://www.facebook.com/woodlife.jp" target="_black">
          <img src="@/assets/images/pc/facebook_share.png">
          <span>Facebook 公式アカウント</span>
        </a>
        <a href="https://www.instagram.com/woodlife.jp" target="_black">
          <img src="@/assets/images/pc/ins_share.png">
          <span>Instagram 公式アカウント</span>
        </a>
      </div>
    </div> -->
    <div class="category-search-content">
      <div class="title">CATEGORY SEARCH</div>
      <div class="desc">カテゴリー検索</div>
      <div class="item-content">
        <router-link to="/category/sofa" class="item">ソファ</router-link>
        <router-link to="/category/table" class="item">テーブル</router-link>
        <router-link to="/category/bed" class="item">ベッド</router-link>
        <router-link to="/category/chair" class="item">チェア</router-link>
        <router-link to="/category/rack" class="item">収納・本棚</router-link>
        <router-link to="/category/TV_board" class="item">TVボード</router-link>
        <router-link to="/category/interior" class="item">インテリア雑貨</router-link>
        <router-link to="/category/outdoor" class="item">アウトドア</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { getSwipers, emailSubscriptions, getCustomPage } from '@/api/config'
import { getHomeModelDetail } from '@/api/homeModel'
import { getArticles } from '@/api/article'
import { productCollect } from '@/api/product'
import { parameterCorrection } from '@/utils/validate'
export default {
  data() {
    return {
      email: '',
      swiperData: [],
      newsArticleData: [],
      informationArticleData: [],
      commitment: 1,
      customData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        pc_content: null,
        pc_css_path: '',
        pc_js_path: ''
      },
      productData: [],
      productAllData: [],
      currentPage: 1,
      allPage: 0
    }
  },
  metaInfo() {
    return {
      title: this.customData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.customData.seo_keyword },
        { vmid: 'description', name: 'description', content: this.customData.seo_description }
      ],
      link: [
        { vmid: this.customData.pc_css_path, rel: 'stylesheet', href: this.customData.pc_css_path }
      ],
      script: [
        { vmid: this.customData.pc_js_path, async: 'async', src: this.customData.pc_js_path }
      ]
    }
  },
  mounted() {
    this.getSwipers()
    this.getArticles()
    this.getCustomPage()
    this.getHomeModelDetail()
  },
  methods: {
    // 轮播
    getSwipers() {
      const data = parameterCorrection({ type: 1 })
      getSwipers({ ...data, type: 'all' }).then(result => {
        this.swiperData = result
        this.$nextTick(() => {
          this.swiper()
        })
      })
    },
    getArticles() {
      getArticles({ type: 'all' }).then(result => {
        this.newsArticleData = (_.filter(result, { article_category_id: 2 })).slice(0, 3)
        this.informationArticleData = (_.filter(result, { article_category_id: 3 })).slice(0, 3)
      })
    },
    // 获取自定义内容
    getCustomPage() {
      getCustomPage('home').then(result => {
        result.pc_css_path = result.pc_css_path || ''
        result.pc_js_path = result.pc_js_path || ''
        this.customData = result
      })
    },
    getHomeModelDetail() {
      getHomeModelDetail({ include: 'products', type: 'all' }).then(result => {
        const arr = result.data[0].products
        if (arr.length) {
          this.productAllData = _.chunk(arr, 12)
          this.allPage = this.productAllData.length
          this.productData = this.productAllData[0]
          const num = Math.ceil(this.productData.length / 3)
          this.productData.forEach((item, index) => {
            if (index === num) {
              this.$set(item, 'active', true)
            } else {
              this.$set(item, 'active', false)
            }
          })
        }
      })
    },
    handleMoreProduct() {
      this.currentPage++
      this.productData = _.concat(this.productData, this.productAllData[this.currentPage - 1])
      const num = Math.ceil(this.productData.length / 3)
      this.productData.forEach((item, index) => {
        if (index === num) {
          this.$set(item, 'active', true)
        } else {
          this.$set(item, 'active', false)
        }
      })
    },
    handleCollection(event, item) {
      event.stopPropagation()
      event.preventDefault()
      productCollect(item.product_sn).then(result => {
        if (result.code === 2001) {
          item.isCollect = true
        } else {
          item.isCollect = false
        }
      })
    },
    swiper() {
      new window.Swiper('.home-page-swiper', {
        speed: 30000,
        autoplay: {
          delay: 0
        },
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 24,
        allowTouchMove: false,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    },
    // 邮件订阅
    emailSubmit() {
      emailSubscriptions({
        email: this.email,
        type: 1
      }).then(() => {
        this.email = ''
        this.$Toast('送信しました')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.home-page-swiper{
  margin: 0 auto;
  .swiper-wrapper{
    transition-timing-function: linear;
  }
  .swiper-slide{
    width: 1200px;
    img{
      width: 100%;
      vertical-align: middle;
    }
  }
  .swiper-pagination ::v-deep{
    left: auto;
    bottom: 16px;
    right: calc(50% - 690px);
    display: inline-block;
    width: auto;
      .swiper-pagination-bullet{
      width: 48px;
      height: 6px;
      border: 1px solid #666666;
      background: transparent;
      margin: 0 8px;
      border-radius: 0;
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #333333;
    }
  }
  .swiper-button-prev{
    left: calc(50% - 876px);
    width: 48px;
    height: 48px;
    background: url('~@/assets/images/pc/arrow-left.png');
    margin-top: -24px;
    &::after{
      content: '';
    }
  }
  .swiper-button-next{
    right: calc(50% - 876px);
    width: 48px;
    height: 48px;
    background: url('~@/assets/images/pc/arrow-right.png');
    margin-top: -24px;
    &::after{
      content: '';
    }
  }
}
.article-box{
  display: flex;
  justify-content: space-between;
  width: 1440px;
  margin: 80px auto 0;
}
.article-item{
  width: 680px;
  .title{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #333333;
    span{
      font-size: 32px;
      font-weight: bold;
    }
    a{
      font-size: 20px;
      color: #999;
    }
    a:hover{
      color: #666;
      text-decoration: underline;
    }
  }
}
.article-item-box{
  display: flex;
  margin-top: 24px;
  img{
    flex-shrink: 0;
    width: 250px;
    height: 166px;
    margin-right: 16px;
  }
  .article-desc{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    :nth-child(1){
      flex-shrink: 0;
      font-size: 20px;
      font-weight: bold;
    }
    :nth-child(2){
      height: 100%;
      font-size: 16px;
      color: #666;
      text-align: justify;
      margin: 10px 0;
    }
    :nth-child(3){
      flex-shrink: 0;
      font-size: 14px;
      color: #999;
    }
  }
  &:hover .article-desc div:nth-child(1){
    text-decoration: underline;
  }
}
.our-service-content{
  width: 1440px;
  margin: 80px auto 0;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    &::before,
    &::after{
      content: '';
      display: block;
      width: 120px;
      height: 1px;
      background: #333;
      margin: 0 24px;
    }
  }
  .item-content{
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    .item{
      width: 300px;
      text-align: center;
      img{
        width: 112px;
        height: 112px;
      }
      .name{
        margin-top: 6px;
        font-size: 20px;
        font-weight: 700;
      }
      .desc{
        margin-top: 10px;
        font-size: 16px;
        text-align: left;
        color: #666666;
      }
    }
  }
}
.ins-content{
  margin: 80px auto 0;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    &::before,
    &::after{
      content: '';
      display: block;
      width: 120px;
      height: 1px;
      background: #333;
      margin: 0 24px;
    }
  }
  .desc{
    font-size: 14px;
    text-align: center;
    margin: 16px 0 32px;
  }
}
.product-content{
  width: 1440px;
  margin: 80px auto 0;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    &::before,
    &::after{
      content: '';
      display: block;
      width: 120px;
      height: 1px;
      background: #333;
      margin: 0 24px;
    }
  }
  .desc{
    font-size: 14px;
    text-align: center;
    margin: 16px 0 32px;
  }
  .waterfall-container {
    column-count: 3;
  }

  .waterfall-item {
    margin-bottom: 24px;
    break-inside: avoid;
    .image{
      position: relative;
      width: 464px;
      height: 464px;
      .collect{
        img{
          position: absolute;
          right: 8px;
          bottom: 8px;
          width: 32px;
          height: 32px;
        }
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .active{
      width: 464px;
      height: 618px;
    }
  }
  .view-more{
    width: 248px;
    height: 48px;
    margin: 32px auto 0;
    line-height: 48px;
    text-align: center;
    background: #333333;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
  }
}
.category-search-content{
  width: 1440px;
  margin: 80px auto;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    &::before,
    &::after{
      content: '';
      display: block;
      width: 120px;
      height: 1px;
      background: #333;
      margin: 0 24px;
    }
  }
  .desc{
    font-size: 14px;
    text-align: center;
    margin: 16px 0 32px;
  }
  .item-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item{
      width: 160px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border: 1px solid #333333;
      box-sizing: border-box;
    }
  }
}
#c7c2cf80_1678778462{
  overflow: hidden;
  height: 450px;
}
.commitment{
  width: 1440px;
  margin: 110px auto 80px;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    &::before,
    &::after{
      content: '';
      display: block;
      width: 120px;
      height: 1px;
      background: #333;
      margin: 0 24px;
    }
  }
  .desc{
    font-size: 14px;
    text-align: center;
    margin: 16px 0 32px;
  }
  .commitment-title{
    display: flex;
    justify-content: center;
    div{
      width: 232px;
      text-align: center;
      padding-bottom: 24px;
      margin: 0 58px;
      cursor: pointer;
    }
    .active{
      border-bottom: 2px solid #333;
    }
  }
  .commitment-desc{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 98px 0 122px;
    margin: 72px 0 80px;
    &::after{
      content: '';
      position: absolute;
      left: 0;
      top: 42px;
      width: 960px;
      height: 240px;
      background: #F4F5FC;
      z-index: -1;
    }
  }
  .commitment-copywriting{
    width: 634px;
    text-align: justify;
    :nth-child(1){
      font-size: 32px;
      font-weight: bold;
      margin: 25px 0 0;
    }
    :nth-child(2){
      font-size: 20px;
      margin: 40px 0 0 0;
    }
  }
}
.foollow{
  width: 1440px;
  margin: 80px auto;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    &::before,
    &::after{
      content: '';
      display: block;
      width: 120px;
      height: 1px;
      background: #333;
      margin: 0 24px;
    }
  }
  .email-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 104px;
    font-size: 16px;
    padding: 0 122px 0 40px;
    background: #F4F5FC;
    margin: 34px 0 32px;
    .email-input{
      display: flex;
      justify-content: center;
    }
    input{
      width: 440px;
      height: 48px;
      box-sizing: border-box;
      padding: 0 20px;
      border: 1px solid #E8EBED;
    }
    .email-button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 164px;
      height: 48px;
      color: #fff;
      line-height: 48px;
      background: #292E32;
      cursor: pointer;
      img{
        margin-right: 8px;
      }
    }
  }
  .share{
    display: flex;
    align-items: center;
    justify-content: center;
    div,a{
      display: flex;
      align-items: center;
      height: 48px;
      font-style: 16px;
      padding: 0 106px;
      border-right: 1px solid #E8EBED;
      img{
        margin-right: 12px;
      }
    }
    :nth-child(1){
      font-size: 32px;
      font-weight: bold;
    }
  }
}
</style>
