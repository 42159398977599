import request from '@/utils/request'

// 获取地区列表
export function getRegionList(params) {
  return request({
    url: '/users/regions',
    method: 'get',
    params
  })
}
export function getZipCodeList(params) {
  return request({
    url: '/users/zipCode',
    method: 'get',
    params
  })
}
// 获取个人中心轮播
export function getPersonalSwiperList(params) {
  return request({
    url: '/swipers',
    method: 'get',
    params
  })
}
// 我的订单列表
export function getMyOrderList(params) {
  return request({
    url: '/orders',
    method: 'get',
    params
  })
}

// 我的订单详情
export function getMyOrderDetail(id, params) {
  return request({
    url: '/orders/' + id,
    method: 'get',
    params
  })
}
// 我的收藏
export function getMyCollectProduct(params) {
  return request({
    url: '/userCollects',
    method: 'get',
    params
  })
}
// 取消收藏
export function cancelCollectProduct(id) {
  return request({
    url: '/userCollects/' + id,
    method: 'patch'
  })
}

// 添加评价
export function addComments(data) {
  return request({
    url: '/comments',
    method: 'post',
    data
  })
}
// 查看订单评价
export function getMyOrderComments(params) {
  return request({
    url: '/comments',
    method: 'get',
    params
  })
}
// 可使用积分
export function getMyPoint() {
  return request({
    url: '/shopping/hasPoint',
    method: 'get'
  })
}
// 可使用积分
export function getPointRecordList(params) {
  return request({
    url: '/points',
    method: 'get',
    params
  })
}

// 即将计入的积分
export function getWillPoint(params) {
  return request({
    url: '/points/will',
    method: 'get',
    params
  })
}
// 浏览记录
export function getHistoryProductList(params) {
  return request({
    url: '/historyProducts',
    method: 'get',
    params
  })
}
