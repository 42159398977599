/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 数字，字符串 转换千分位
export function calcThousands(data) {
  const string = ('' + data).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
  return string
}

/**
 * 参数修正
 * 筛选 filter
 */
export function parameterCorrection(data) {
  const dt = {}
  for (const key in data) {
    if (data[key] === '' || data[key] === null) {
      continue
    }
    dt[`filter[${key}]`] = data[key]
  }
  return dt
}

export function getQueryString(name) {
  var href = decodeURIComponent(window.location.href)
  if (href.indexOf('?') !== href.lastIndexOf('?')) { var urls = href.replace(/\?/g, '&').replace(/^.*?&/, '') } else { urls = href.replace(/^.*\?/, '') }
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = ('?' + urls).substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

