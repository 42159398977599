var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("heads", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.headerShow,
            expression: "headerShow",
          },
        ],
      }),
      _c("router-view", { attrs: { name: "default" } }),
      _c("footers"),
      _c(
        "div",
        { staticClass: "side-content", class: { close: !_vm.sideShow } },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "router-link",
                { staticClass: "side-item", attrs: { to: "/question" } },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/pc/customer.png") },
                  }),
                  _c("div", [
                    _vm._v("カスタマーサ"),
                    _c("br"),
                    _vm._v("ポート"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "side-item",
                  on: {
                    click: function ($event) {
                      _vm.confirmVisible = true
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/pc/mail.png") },
                  }),
                  _vm._m(0),
                ]
              ),
              _c("transition", { attrs: { name: "el-fade-in" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.toTopShow,
                        expression: "toTopShow",
                      },
                    ],
                    staticClass: "side-item",
                    on: {
                      click: function ($event) {
                        return _vm.toTop()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/images/pc/to_top.png") },
                    }),
                  ]
                ),
              ]),
              _c("div", {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.sideShow = false
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.sideShow,
                    expression: "!sideShow",
                  },
                ],
                staticClass: "open",
                attrs: { src: require("@/assets/images/pc/side_open.png") },
                on: {
                  click: function ($event) {
                    _vm.sideShow = true
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("mail", {
        attrs: { "confirm-visible": _vm.confirmVisible },
        on: {
          "update:confirmVisible": function ($event) {
            _vm.confirmVisible = $event
          },
          "update:confirm-visible": function ($event) {
            _vm.confirmVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_vm._v("ニュースレ"), _c("br"), _vm._v("ター登録")])
  },
]
render._withStripped = true

export { render, staticRenderFns }