<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>アカウント情報・変更</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/address" />
      </div>
      <div class="right-content">
        <div class="warn">⚠️ アカウント情報登録・修正を行います。 必須の箇所は、必ず入力してください。</div>
        <div class="user-message">
          <div class="item">
            <div class="name">メールアドレス:</div>
            <div class="value">{{ ruleForm.email }}</div>
          </div>
          <div class="item">
            <div class="name">パスワード:</div>
            <div class="value">******</div>
            <div class="btn" @click="handleChangePassword">パスワード変更の場合、こちら</div>
          </div>
        </div>
        <div class="address-content">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
            <div class="inline-item">
              <el-form-item prop="first_name" label="姓">
                <el-input v-model.trim="ruleForm.first_name" placeholder="姓" />
              </el-form-item>
              <el-form-item prop="last_name" label="名">
                <el-input v-model.trim="ruleForm.last_name" placeholder="名" />
              </el-form-item>
            </div>
            <div class="inline-item">
              <el-form-item prop="receive_phone" label="電話番号">
                <el-input v-model.trim="ruleForm.receive_phone" maxlength="11" placeholder="電話番号" />
              </el-form-item>
              <el-form-item prop="zip_code" label="郵便番号">
                <el-input v-model.trim="ruleForm.zip_code" maxlength="7" placeholder="郵便番号" @change="changeZipCode" />
              </el-form-item>
            </div>
            <div class="inline-item">
              <el-form-item prop="stats" label="都道府県">
                <el-select v-model="ruleForm.stats" placeholder="都道府県">
                  <el-option v-for="item in areaData" :key="item.id" :label="item.name" :value="item.name" />
                </el-select>
              </el-form-item>
              <el-form-item prop="city" label="市区町村">
                <el-input v-model.trim="ruleForm.city" placeholder="市区町村" />
              </el-form-item>
            </div>
            <div class="inline-item">
              <el-form-item prop="address" label="番地住所">
                <el-input v-model.trim="ruleForm.address" placeholder="番地住所" />
              </el-form-item>
              <el-form-item prop="detail_address" label="建物名・部屋番号（任意）">
                <el-input v-model.trim="ruleForm.detail_address" placeholder="建物名・部屋番号（任意）" />
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="action-content">
          <div class="button dark" @click="onSubmit">変更する</div>
          <div class="button" @click="handleReset">キャンセル</div>
        </div>
      </div>
      <div v-show="dialogVisible" class="overlay">
        <div class="main">
          <div class="modal-content">
            <div class="title">パスワードの変更</div>
            <el-form ref="passwordForm" :model="passwordForm" :rules="rules" label-position="top">
              <el-form-item prop="original_password" label="現在のパスワード">
                <el-input v-model.trim="passwordForm.original_password" placeholder="現在のパスワード" />
              </el-form-item>
              <el-form-item prop="password" label="新しいパスワード">
                <el-input v-model.trim="passwordForm.password" placeholder="新しいパスワード" />
              </el-form-item>
              <el-form-item prop="password_confirmation" label="新しい会パスワードを再入力">
                <el-input v-model.trim="passwordForm.password_confirmation" placeholder="新しい会パスワードを再入力" />
              </el-form-item>
            </el-form>
            <div class="action-content">
              <div class="button dark" @click="handleUpdatePassword">変更する</div>
              <div class="button" @click="dialogVisible = false">キャンセル</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isToast" class="toast-message">
        <img src="@/assets/images/pc/toast_icon.png" alt="">
        <span>アカウント情報変更完了</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserDetail, updateUser, updatePassword } from '@/api/user'
import { getRegionList, getZipCodeList } from '@/api/personal'
import SideBar from './component/sideBar'
export default {
  components: { SideBar },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('電話番号をご入力ください'))
      } else {
        if (isNaN(value)) {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else if (value.length < 9 || value.length > 12 || value.substr(0, 1) !== '0') {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else {
          callback()
        }
      }
    }
    var checkZipCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('郵便番号をご入力ください'))
      } else {
        if (isNaN(value) || value.length !== 7) {
          callback(new Error('郵便番号は半角数字7桁で入力してください'))
        } else {
          callback()
        }
      }
    }
    return {
      dialogVisible: false,
      isToast: false,
      ruleForm: {
        first_name: '',
        first_name_kana: '',
        last_name: '',
        last_name_kana: '',
        receive_phone: '',
        zip_code: '',
        stats: '',
        city: '',
        address: '',
        detail_address: '',
        receiver_email: ''
      },
      defaultData: {},
      passwordForm: {

      },
      areaData: [],
      rules: {
        first_name: { required: true, message: '姓をご入力ください', trigger: ['blur'] },
        last_name: { required: true, message: '名をご入力ください', trigger: ['blur'] },
        receive_phone: [{ required: true, validator: checkPhone, trigger: ['blur'] }],
        zip_code: [{ required: true, validator: checkZipCode, trigger: ['blur'] }],
        stats: { required: true, message: '届け先住所を選択', trigger: ['blur'] },
        city: { required: true, message: '市区町村をご入力ください', trigger: ['blur'] },
        address: { required: true, message: '番地をご入力ください', trigger: ['blur'] },
        receiver_email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスをご入力ください', trigger: ['blur'] }
        ],
        original_password: { required: true, message: '現在のパスワードをご入力ください', trigger: ['blur'] },
        password: { required: true, message: '新しいパスワードをご入力ください', trigger: ['blur'] },
        password_confirmation: { required: true, message: '新しい会パスワードを再入力"', trigger: ['blur'] }
      }
    }
  },
  computed: {

  },
  created() {
    this.getUserDetail()
    this.getRegionList()
  },
  methods: {
    getUserDetail() {
      getUserDetail().then(result => {
        this.ruleForm = result
        this.defaultData = { ...this.ruleForm }
      })
    },
    getRegionList() {
      getRegionList().then(result => {
        this.areaData = result
      })
    },
    handleChangePassword() {
      this.dialogVisible = true
      this.passwordForm = {}
    },
    changeZipCode(value) {
      getZipCodeList({ zip_code: value }).then(result => {
        this.ruleForm.stats = result.state
        this.ruleForm.city = result.city
        this.ruleForm.address = result.address
      })
    },
    handleUpdatePassword() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          updatePassword(this.passwordForm).then(() => {
            this.$store.dispatch('user/logout').then(() => {
              this.$router.push('/login')
            })
          })
        }
      })
    },
    handleReset() {
      this.ruleForm = { ...this.defaultData }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = { ...this.ruleForm }
          updateUser(data).then(() => {
            this.isToast = true
            this.defaultData = { ...this.ruleForm }
            this.$store.dispatch('user/getUserDetail')
            setTimeout(() => {
              this.isToast = false
            }, 2000)
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
     ::v-deep .el-form-item__label{
          padding-bottom: 8px;
          line-height: 16px;
      }
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        font-size: 16px;
        .warn{
            padding: 12px 16px;
            background-color: #f4f5fc;
            border-radius: 4px;
            font-size: 14px;
        }
        .user-message{
            margin-top: 32px;
            .item{
                display: flex;
                &:not(:last-child){
                    margin-bottom: 24px;
                }
                .name{
                    width: 120px;
                    font-weight: 700;
                }
                .value{
                    width: 330px;
                    margin-left: 40px;
                }
                .btn{
                    color: #999999;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .address-content{
            margin-top: 40px;
            .el-form-item{
                margin-bottom: 24px;
            }
            .inline-item{
                display: flex;
                justify-content: space-between;
            }
            ::v-deep .el-input__inner{
                width: 533px;
                border-color: #e8ebed;
            }
            ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
                content:''
            }
            .el-select .el-input.is-focus .el-input__inner {
                border-color: #e8ebed ;
            }
        }
        .action-content{
            display: flex;
            justify-content: space-between;
            width: 304px;
            margin: 56px auto 0;
            font-size: 14px;
            .button{
                width: 142px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                box-sizing: border-box;
                border: 1px solid #333;
                cursor: pointer;
            }
            .dark{
                margin-right: 20px;
                background-color: #333;
                color: #f4f5fc;
            }
        }
    }
    .modal-content{
      width: 480px;
      height: 516px;
      padding: 0 36px;
      box-sizing: border-box;
      .title{
          margin-top: 56px;
          font-size: 28px;
          font-weight: 700;
      }
      .el-form{
        margin-top: 20px;
      }
      .action-content{
            display: flex;
            justify-content: space-between;
            width: 304px;
            margin: 32px auto 0;
            font-size: 14px;
            .button{
                width: 142px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                box-sizing: border-box;
                border: 1px solid #333;
                cursor: pointer;
            }
            .dark{
                margin-right: 20px;
                background-color: #333;
                color: #f4f5fc;
            }
        }

    }
}
</style>
