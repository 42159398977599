<template>
  <div class="order-content">
    <PaymentHeader />
    <div class="step-content">
      <div class="main">
        <div class="item">
          <img src="@/assets/images/pc/step1_border.png" class="icon">
          <div>カート</div>
        </div>
        <img src="@/assets/images/pc/step_line1_in.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step2_in.png" class="icon">
          <div>配送</div>
        </div>
        <img src="@/assets/images/pc/step_line2.png" class="step-line2">
        <div class="item">
          <img src="@/assets/images/pc/step3.png" class="icon">
          <div>支払い</div>
        </div>
        <img src="@/assets/images/pc/step_line1.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step4.png" class="icon">
          <div>注文完了</div>
        </div>
      </div>
    </div>
    <div class="shop-content">
      <div class="left-content">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
          <el-form-item prop="address.receiver_email" label="" style="width: 100%">
            <el-input v-model.trim="ruleForm.address.receiver_email" placeholder="連絡先" />
          </el-form-item>
          <div class="delivery-content">
            <div class="title">配送方法</div>
            <div class="content">
              <div class="flex">
                <el-radio v-model="delivery" :label="0">
                  <span>通常出荷</span>
                </el-radio>
                <div class="price">¥{{ calcThousands(priceParams.freight_amount) }}</div>
              </div>
              <div class="detail">
                配送は玄関先までとなります。開梱や組立、梱包材の回収はございません。<br>
                発送準備が完了した商品から順番に出荷となります。<br>
                オーダー家具は、お届けまでにお時間を頂戴します。出荷目安は各商品ページにご確認ください。
              </div>
            </div>
          </div>
          <div class="address-content">
            <div class="title">お届け先の住所</div>
            <div class="inline-item">
              <el-form-item prop="address.first_name" label="姓">
                <el-input v-model.trim="ruleForm.address.first_name" placeholder="姓" />
              </el-form-item>
              <el-form-item prop="address.last_name" label="名">
                <el-input v-model.trim="ruleForm.address.last_name" placeholder="名" />
              </el-form-item>
            </div>
            <div class="inline-item">
              <el-form-item prop="address.phone" label="電話番号">
                <el-input v-model.trim="ruleForm.address.phone" maxlength="11" placeholder="電話番号" />
              </el-form-item>
              <el-form-item prop="address.zip_code" label="郵便番号">
                <el-input v-model.trim="ruleForm.address.zip_code" maxlength="7" placeholder="郵便番号" @change="changeZipCode" />
              </el-form-item>
            </div>
            <div class="inline-item">
              <el-form-item prop="address.stats" label="都道府県">
                <el-select v-model="ruleForm.address.stats" placeholder="都道府県">
                  <el-option v-for="item in areaData" :key="item.id" :label="item.name" :value="item.name" />
                </el-select>
              </el-form-item>
              <el-form-item prop="address.city" label="市区町村">
                <el-input v-model.trim="ruleForm.address.city" placeholder="市区町村" />
              </el-form-item>
            </div>
            <el-form-item prop="address.address" style="width: 100%;" label="番地住所">
              <el-input v-model.trim="ruleForm.address.address" placeholder="番地住所" />
            </el-form-item>
            <el-form-item prop="address.detail_address" style="width: 100%;" label="建物名・部屋番号（任意）">
              <el-input v-model.trim="ruleForm.address.detail_address" placeholder="建物名・部屋番号（任意）" />
            </el-form-item>
            <el-form-item v-if="token" style="width: 100%;">
              <el-checkbox v-model="checked">よく使う住所として登録</el-checkbox>
            </el-form-item>
          </div>
          <div class="note-content">
            <div class="title">備考欄</div>
            <el-input v-model="ruleForm.note" type="textarea" placeholder="備考欄（任意）" />
          </div>
        </el-form>
      </div>
      <div class="right-content">
        <div class="sticky">
          <div class="title">買い物カート内の商品</div>
          <div class="detail">
            <div class="item font16">
              <div>送料</div>
              <div>￥{{ calcThousands(priceParams.freight_amount) }}</div>
            </div>
            <div class="item">
              <div>合計</div>
              <div class="price">￥{{ calcThousands(priceParams.pay_amount) }}</div>
            </div>
          </div>
          <el-button type="primary" class="next-btn" :loading="btnLoading" @click="toPayment">支払い方法に進む</el-button>
          <router-link to="/cart">
            <div class="light-btn">カートに戻る</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getShoppingDetail, getPriceShow, addressSave } from '@/api/order'
import { getUserDetail, updateUser } from '@/api/user'
import { getRegionList, getZipCodeList } from '@/api/personal'
import PaymentHeader from '@/components/PaymentHeader'
import { calcThousands } from '@/utils/validate'
export default {
  components: { PaymentHeader },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('電話番号をご入力ください'))
      } else {
        if (isNaN(value)) {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else if (value.length < 9 || value.length > 12 || value.substr(0, 1) !== '0') {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else {
          callback()
        }
      }
    }
    var checkZipCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('郵便番号をご入力ください'))
      } else {
        if (isNaN(value) || value.length !== 7) {
          callback(new Error('郵便番号は半角数字7桁で入力してください'))
        } else {
          callback()
        }
      }
    }
    return {
      btnLoading: false,
      ruleForm: {
        address: {
          first_name: '',
          last_name: '',
          phone: '',
          zip_code: '',
          stats: '',
          city: '',
          address: '',
          detail_address: '',
          receiver_email: ''
        },
        note: ''
      },
      shippingAmount: 0,
      priceParams: {
        freight_amount: 0,
        pay_amount: 0
      },
      areaData: [],
      delivery: 0,
      checked: false,
      rules: {
        'address.first_name': { required: true, message: '姓をご入力ください', trigger: ['blur'] },
        'address.last_name': { required: true, message: '名をご入力ください', trigger: ['blur'] },
        'address.phone': [{ required: true, validator: checkPhone, trigger: ['blur'] }],
        'address.zip_code': [{ required: true, validator: checkZipCode, trigger: ['blur'] }],
        'address.stats': { required: true, message: '届け先住所を選択', trigger: ['blur'] },
        'address.city': { required: true, message: '市区町村をご入力ください', trigger: ['blur'] },
        'address.address': { required: true, message: '番地をご入力ください', trigger: ['blur'] },
        'address.receiver_email': [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスをご入力ください', trigger: ['blur'] }
        ]
      },
      calcThousands
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  created() {
    this.getShoppingDetail()
    this.getPriceShow()
    this.getRegionList()
  },
  methods: {
    getShoppingDetail() {
      getShoppingDetail().then(result => {
        if (result.receiver_email) {
          this.ruleForm.address = {
            first_name: result.first_name,
            first_name_kana: result.first_name_kana,
            last_name: result.last_name,
            last_name_kana: result.last_name_kana,
            stats: result.stats,
            city: result.city,
            address: result.address,
            detail_address: result.detail_address,
            receiver_email: result.receiver_email,
            phone: result.phone,
            zip_code: result.zip_code
          }
          this.ruleForm.note = result.note
        } else {
          if (this.token) {
            this.getUserDetail()
          }
        }
      })
    },
    getUserDetail() {
      getUserDetail().then(result => {
        this.ruleForm.address = {
          first_name: result.first_name,
          last_name: result.last_name,
          phone: result.receive_phone,
          zip_code: result.zip_code,
          stats: result.stats,
          city: result.city,
          address: result.address,
          detail_address: result.detail_address,
          receiver_email: result.email
        }
      })
    },
    getPriceShow() {
      getPriceShow({ use_point: 0 }).then(result => {
        this.priceParams = result
      })
    },
    getRegionList() {
      getRegionList().then(result => {
        this.areaData = result
      })
    },
    changeZipCode(value) {
      getZipCodeList({ zip_code: value }).then(result => {
        this.ruleForm.address.stats = result.state
        this.ruleForm.address.city = result.city
        this.ruleForm.address.address = result.address
      })
    },
    toPayment() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          addressSave(this.ruleForm).then(() => {
            this.$router.push('/order/payment')
            if (this.checked) {
              const data = {
                first_name: this.ruleForm.address.first_name,
                last_name: this.ruleForm.address.last_name,
                receive_phone: this.ruleForm.address.phone,
                zip_code: this.ruleForm.address.zip_code,
                stats: this.ruleForm.address.stats,
                city: this.ruleForm.address.city,
                address: this.ruleForm.address.address,
                detail_address: this.ruleForm.address.detail_address
              }
              updateUser(data).then(() => {
                this.btnLoading = false
              }).catch(() => {
                this.btnLoading = false
              })
            }
          })
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.order-content{
  .el-select{
      width: 460px;
  }
  ::v-deep .el-radio__inner{
      width: 18px;
      height: 18px;
  }
  .shop-content{
    display: flex;
    justify-content: space-between;
    width: 1440px;
    margin: 88px auto;
    .left-content{
      width: 952px;
      .delivery-content{
          .title{
              margin-bottom: 12px;
              font-size: 20px;
          }
          .content{
              padding: 24px;
              border-radius: 4px;
              border: 1px solid #e8ebed;
              .flex{
                  display:flex;
                  justify-content: space-between;
                  .price{
                      font-size: 16px;
                      color: #c40a0a;
                      font-weight: 700
                  };
              }
              .detail{
                  padding-left: 36px;
                  margin-top: 16px;
                  line-height: 24px;
                  font-size: 16px;
              }
          }
      }
      .address-content{
          margin-top: 32px;
          .title{
              margin-bottom: 12px;
              font-size: 20px;
          }
          .inline-item{
              display: flex;
              justify-content: space-between;
              .el-input{
                  width: 460px;
              }
              ::v-deep .el-form-item__label{
                padding-bottom: 8px;
                line-height: 16px;
            }
          }
      }
      .note-content{
          .title{
              margin-bottom: 12px;
              font-size: 20px;
          }
          ::v-deep .el-textarea__inner{
              width: 952px;
              height: 120px;
          }
      }
    }
    .right-content{
      width: 416px;
      .sticky{
        position: sticky;
        top: 0;
        padding: 32px 24px;
        background-color: #f4f5fc;
        box-sizing: border-box;
      }
      .title{
        font-size: 20px;
        font-weight: 700;
      }
      .detail{
        padding: 24px 0;
        margin-top: 16px;
        margin-bottom: 44px;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #333333;
        .item{
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          &:not(:last-child){
            margin-bottom: 24px;
          }
          .price{
            font-weight: 700;
          }
        }
      }
      .font16{
        font-size: 16px !important;
      }
      .light-btn{
        width: 368px;
        height: 60px;
        margin-bottom: 16px;
        line-height: 60px;
        border: 1px solid #333333;
        font-size: 20px;
        text-align: center;
        color: #333333;
        background-color: #fff;
        box-sizing: border-box;
        cursor: pointer;
      }
      .next-btn{
        width: 368px;
        height: 60px;
        margin-bottom: 16px;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        background-color: #333333;
        cursor: pointer;
      }
    }
  }
}
</style>
