import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [

  {
    path: '/',
    components: {
      default: () => import('@/views/pc/layout/index'),
      mobile: () => import('@/views/sp/layout/index')
    },
    children: [{
      name: 'home',
      path: '',
      components: {
        default: () => import('@/views/pc/index'),
        mobile: () => import('@/views/sp/index')
      }
    },
    {
      name: 'category',
      path: '/category/:url',
      components: {
        default: () => import('@/views/pc/category/index'),
        mobile: () => import('@/views/sp/category/index')
      }
    },
    {
      name: 'product',
      path: '/product/:productSn',
      components: {
        default: () => import('@/views/pc/product/index'),
        mobile: () => import('@/views/sp/product/index')
      },
      meta: { footerSpShow: false }
    },
    {
      path: '/new/product',
      components: {
        default: () => import('@/views/pc/product/new'),
        mobile: () => import('@/views/sp/product/new')
      }
    },
    {
      name: 'search',
      path: '/search',
      components: {
        default: () => import('@/views/pc/search/index'),
        mobile: () => import('@/views/sp/search/index')
      }
    },
    {
      path: '/question',
      components: {
        default: () => import('@/views/pc/question/index'),
        mobile: () => import('@/views/sp/question/index')
      }
    },
    {
      path: '/question/confirm',
      components: {
        default: () => import('@/views/pc/question/confirm'),
        mobile: () => import('@/views/sp/question/confirm')
      }
    },
    {
      path: '/question/success',
      components: {
        default: () => import('@/views/pc/question/success')
      }
    },
    {
      path: '/article',
      components: {
        default: () => import('@/views/pc/article/index'),
        mobile: () => import('@/views/sp/article/index')
      }
    },
    {
      path: '/article/:id',
      components: {
        default: () => import('@/views/pc/article/detail'),
        mobile: () => import('@/views/sp/article/detail')
      }
    },
    {
      path: '/cart',
      components: {
        default: () => import('@/views/pc/cart/index'),
        mobile: () => import('@/views/sp/cart/index')
      },
      meta: { headerShow: false, paymentFooter: true, footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/order/address', // 下单填写信息
      components: {
        default: () => import('@/views/pc/order/address'),
        mobile: () => import('@/views/sp/order/address')
      },
      meta: { headerShow: false, paymentFooter: true, headerSpShow: false, footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/order/payment', // 下单选择支付方式
      components: {
        default: () => import('@/views/pc/order/payment'),
        mobile: () => import('@/views/sp/order/payment')
      },
      meta: { headerShow: false, paymentFooter: true, headerSpShow: false, footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/order/complete', // 下单成功
      components: {
        default: () => import('@/views/pc/order/complete'),
        mobile: () => import('@/views/sp/order/complete')
      },
      meta: { headerShow: false, paymentFooter: true, headerSpShow: false, footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/order/error', // stripe下单失败
      components: {
        default: () => import('@/views/pc/order/error'),
        mobile: () => import('@/views/sp/order/error')
      },
      meta: { headerShow: false, paymentFooter: true, headerSpShow: false, footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/personal/mine', // 个人中心
      components: {
        default: () => import('@/views/pc/personal/mine'),
        mobile: () => import('@/views/sp/personal/mine')
      }
    },
    {
      path: '/personal/order', // 个人中心订单列表
      components: {
        default: () => import('@/views/pc/personal/order'),
        mobile: () => import('@/views/sp/personal/order')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/personal/order/detail/:id', // 个人中心订单详情
      components: {
        default: () => import('@/views/pc/personal/orderDetail'),
        mobile: () => import('@/views/sp/personal/orderDetail')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/personal/order/add_comments/:id', // 个人中心填写评价
      components: {
        default: () => import('@/views/pc/personal/addComments'),
        mobile: () => import('@/views/sp/personal/addComments')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/personal/order/my_comments/:id', // 个人中心我的评价
      components: {
        default: () => import('@/views/pc/personal/myComments')
      }
    },
    {
      path: '/personal/my_collection', // 个人中心我的收藏
      components: {
        default: () => import('@/views/pc/personal/myCollection'),
        mobile: () => import('@/views/sp/personal/myCollection')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/personal/address', // 个人中心地址
      components: {
        default: () => import('@/views/pc/personal/address'),
        mobile: () => import('@/views/sp/personal/address')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/personal/coupon', // 个人中心优惠券
      components: {
        default: () => import('@/views/pc/personal/coupon'),
        mobile: () => import('@/views/sp/personal/coupon')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/personal/point', // 个人中心积分
      components: {
        default: () => import('@/views/pc/personal/point'),
        mobile: () => import('@/views/sp/personal/point')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/login',
      components: {
        default: () => import('@/views/pc/login/index'),
        mobile: () => import('@/views/sp/login/index')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/register',
      components: {
        default: () => import('@/views/pc/register/index'),
        mobile: () => import('@/views/sp/register/index')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/register/auth',
      components: {
        default: () => import('@/views/pc/register/auth'),
        mobile: () => import('@/views/sp/register/auth')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/register/success',
      components: {
        default: () => import('@/views/pc/register/success'),
        mobile: () => import('@/views/sp/register/success')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/password',
      components: {
        default: () => import('@/views/pc/password/index'),
        mobile: () => import('@/views/sp/password/index')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/password/auth',
      components: {
        default: () => import('@/views/pc/password/auth'),
        mobile: () => import('@/views/sp/password/auth')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/password/reset',
      components: {
        default: () => import('@/views/pc/password/reset'),
        mobile: () => import('@/views/sp/password/reset')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/password/success',
      components: {
        default: () => import('@/views/pc/password/success'),
        mobile: () => import('@/views/sp/password/success')
      },
      meta: { footerSpShow: false, simpleFooterSpShow: true }
    },
    {
      path: '/company',
      components: {
        default: () => import('@/views/pc/company/index'),
        mobile: () => import('@/views/sp/company/index')
      }
    },
    {
      path: '/tokutei',
      components: {
        default: () => import('@/views/pc/tokutei/index'),
        mobile: () => import('@/views/sp/tokutei/index')
      }
    },
    {
      path: '/privacy',
      components: {
        default: () => import('@/views/pc/privacy/index'),
        mobile: () => import('@/views/sp/privacy/index')
      }
    },
    {
      path: '/guides/category', // 使用指南
      components: {
        default: () => import('@/views/pc/guides/category'),
        mobile: () => import('@/views/sp/guides/category')
      }
    },
    { path: '/guides/question',
      components: {
        default: () => import('@/views/pc/guides/question'),
        mobile: () => import('@/views/sp/guides/question')
      }
    },
    { path: '/guides/question_confirm',
      components: {
        mobile: () => import('@/views/sp/guides/questionConfirm')
      }
    },
    { path: '/guides/install',
      components: {
        default: () => import('@/views/pc/guides/install'),
        mobile: () => import('@/views/sp/guides/install')
      }
    },
    {
      path: '/guides/delivery',
      components: {
        default: () => import('@/views/pc/guides/delivery'),
        mobile: () => import('@/views/sp/guides/delivery')
      }
    },
    {
      path: '/guides/return',
      components: {
        default: () => import('@/views/pc/guides/return'),
        mobile: () => import('@/views/sp/guides/return')
      }
    },
    {
      path: '/guides/payment',
      components: {
        default: () => import('@/views/pc/guides/payment'),
        mobile: () => import('@/views/sp/guides/payment')
      }
    },
    {
      path: '/guides/afterService',
      components: {
        default: () => import('@/views/pc/guides/afterService'),
        mobile: () => import('@/views/sp/guides/afterService')
      }
    },
    {
      path: '/guides/about',
      components: {
        default: () => import('@/views/pc/guides/about'),
        mobile: () => import('@/views/sp/guides/about')
      }
    },
    {
      path: '/guides/order',
      components: {
        default: () => import('@/views/pc/guides/order'),
        mobile: () => import('@/views/sp/guides/order')
      }
    },
    {
      path: '/activity/:url', // 自定义活动页
      components: {
        default: () => import('@/views/pc/activity/index'),
        mobile: () => import('@/views/sp/activity/index')
      }
    }
    ]
  },
  {
    path: '/404',
    components: {
      default: () => import('@/views/pc/404'),
      mobile: () => import('@/views/pc/404')
    }
  },
  // 图标示例
  {
    path: '/icon',
    components: {
      default: () => import('@/views/svgIcons/index'),
      mobile: () => import('@/views/svgIcons/index')
    }
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404' }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
