var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v("家具通販専門店TOP"),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
          }),
          _vm._l(_vm.parentsData, function (item, index) {
            return _c(
              "span",
              { key: index },
              [
                index !== _vm.parentsData.length - 1
                  ? _c("router-link", { attrs: { to: item.url } }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ])
                  : _vm._e(),
                index !== _vm.parentsData.length - 1
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/images/pc/breadcrumb_arrow.png"),
                      },
                    })
                  : _vm._e(),
                index === _vm.parentsData.length - 1
                  ? _c("span", [_vm._v(_vm._s(item.name))])
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "product-detail" }, [
        _c("div", { staticClass: "product-left" }, [
          _c(
            "div",
            {
              staticClass: "product-swiper-box",
              class: { "active-not": _vm.imgUrl },
            },
            [
              _c("div", { staticClass: "swiper product-swiper" }, [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.productData.galleries, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "swiper-slide" },
                      [
                        _c("img", { attrs: { src: item } }),
                        _c("img", {
                          staticClass: "enlarge",
                          attrs: {
                            src: require("@/assets/images/pc/enlarge.png"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.imgVisibleOpen(index)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imgUrl,
                      expression: "imgUrl",
                    },
                  ],
                  staticClass: "swiper-img-box",
                  attrs: { src: _vm.imgUrl },
                }),
                _c("div", { staticClass: "swiper-pagination" }),
              ]),
              _c("div", { staticClass: "swiper product-swiper-thumbs" }, [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.productData.galleries, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "swiper-slide",
                        on: {
                          mouseover: function ($event) {
                            return _vm.swiperHover(index)
                          },
                        },
                      },
                      [_c("img", { attrs: { src: item } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "swiper-button-prev" }),
              _c("div", { staticClass: "swiper-button-next" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "product-mid" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.productData.title)),
          ]),
          _c("div", { staticClass: "sn" }, [
            _vm._v("商品コード：" + _vm._s(_vm.productData.product_sn)),
          ]),
          _c("div", { staticClass: "collection" }, [
            _c("span", [
              _vm._v(
                "お気に入りアイテム登録者数：" +
                  _vm._s(
                    _vm.productData.collects_count +
                      _vm.productData.collect_count
                  )
              ),
            ]),
          ]),
          _c("div", { staticClass: "delivery" }, [
            _vm._v("受注生産品 / 納期2ヶ月程度～"),
          ]),
          _c(
            "div",
            { staticClass: "label" },
            _vm._l(_vm.productData.tag, function (item, index) {
              return _c("div", { key: index }, [_vm._v(_vm._s(item.name))])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "attribute" },
            [
              _vm._l(_vm.productData.attribute, function (item, index) {
                return _c("div", { key: index, staticClass: "attribute-box" }, [
                  _c("div", { staticClass: "attribute-title" }, [
                    _vm._v(_vm._s(item.name)),
                    _c("span", [_vm._v("必須")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "attribute-item" },
                    _vm._l(item.attribute_value, function (it, ind) {
                      return _c(
                        "div",
                        {
                          key: ind,
                          staticClass: "attribute-item-box",
                          class: { active: it.id === _vm.skuChecked[index].id },
                          attrs: { title: it.value },
                          on: {
                            click: function ($event) {
                              return _vm.changeSku(index, it)
                            },
                          },
                        },
                        [
                          it.icon
                            ? _c("div", { staticClass: "img-box" }, [
                                _c("img", {
                                  attrs: { src: it.icon, alt: it.value },
                                }),
                              ])
                            : _c("div", { staticClass: "text-box" }, [
                                _vm._v(_vm._s(it.value)),
                              ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              }),
              _vm._l(_vm.productData.norm, function (item, index) {
                return _c(
                  "div",
                  { key: "n" + index, staticClass: "attribute-box" },
                  [
                    _c("div", { staticClass: "attribute-title" }, [
                      _vm._v(_vm._s(item.name)),
                      item.is_required
                        ? _c("span", [_vm._v("必須")])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "attribute-item" },
                      _vm._l(item.norm_values, function (it, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            staticClass: "attribute-item-box",
                            class: { active: _vm.norm_values.includes(it.id) },
                            attrs: { title: it.name },
                            on: {
                              click: function ($event) {
                                return _vm.changeNorm(item, it)
                              },
                            },
                          },
                          [
                            it.image
                              ? _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    attrs: { src: it.image, alt: it.name },
                                  }),
                                ])
                              : _c("div", { staticClass: "text-box" }, [
                                  _vm._v(
                                    _vm._s(
                                      it.name +
                                        "＋￥" +
                                        _vm.calcThousands(it.price) +
                                        "円"
                                    )
                                  ),
                                ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _c("div", { staticClass: "total-content" }, [
            _vm.productData.promotion.length > 0 &&
            _vm.productData.promotion[0].is_open
              ? _c("div", { staticClass: "discount-detail" }, [
                  _c("div", { staticClass: "off" }, [
                    _vm._v(
                      _vm._s(_vm.productData.promotion[0].discount) + "%OFF"
                    ),
                  ]),
                  _c("div", { staticClass: "price" }, [
                    _vm._v(
                      "￥" + _vm._s(_vm.calcThousands(_vm.productData.price))
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("router-link", { attrs: { to: "/guides/delivery" } }, [
                  _vm._v("配送料金について"),
                ]),
                _c("div", { staticClass: "price" }, [
                  _vm._v(
                    " ￥" +
                      _vm._s(
                        _vm.productData.promotion.length > 0 &&
                          _vm.productData.promotion[0].is_open
                          ? _vm.calcThousands(_vm.productData.discount_price)
                          : _vm.calcThousands(_vm.productData.price)
                      )
                  ),
                  _c("span", [_vm._v("(税込)")]),
                ]),
              ],
              1
            ),
          ]),
          _vm.productData.is_on_sale && _vm.sku_id
            ? _c(
                "div",
                { staticClass: "add-cart", on: { click: _vm.toCart } },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/pc/add-cart.png") },
                  }),
                  _c("span", [_vm._v("カートに入れる")]),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "add-cart",
                  on: {
                    click: function ($event) {
                      _vm.confirmVisible = true
                    },
                  },
                },
                [_vm._v("メールで入荷通知を受取る")]
              ),
          _c(
            "div",
            { staticClass: "add-collection", on: { click: _vm.collect } },
            [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.productData.is_collected,
                    expression: "!productData.is_collected",
                  },
                ],
                attrs: { src: require("@/assets/images/pc/collection.png") },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.productData.is_collected,
                    expression: "productData.is_collected",
                  },
                ],
                attrs: {
                  src: require("@/assets/images/pc/collection_choice.png"),
                },
              }),
              _c("span", [_vm._v("お気に入りに追加する")]),
            ]
          ),
        ]),
        _c("div", { staticClass: "product-right" }, [
          _vm.productData.keywordTag && _vm.productData.keywordTag.length
            ? _c(
                "div",
                { staticClass: "label-keywords-content" },
                _vm._l(_vm.productData.keywordTag, function (item, index) {
                  return _c(
                    "router-link",
                    {
                      key: index,
                      staticClass: "item",
                      attrs: { to: "/search?input=" + item.name + "&page=1" },
                    },
                    [_vm._v("# " + _vm._s(item.name))]
                  )
                }),
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "detail-content" }, [
        _c("div", { staticClass: "left-content" }, [
          _c("div", { staticClass: "product-reveal" }, [
            _c(
              "div",
              {
                class: { active: _vm.reveal === 1 },
                on: {
                  click: function ($event) {
                    _vm.reveal = 1
                  },
                },
              },
              [_vm._v("製品詳細 "), _c("span", [_vm._v("Item Detail")])]
            ),
            _vm.producCommentsData.length
              ? _c(
                  "div",
                  {
                    class: { active: _vm.reveal === 2 },
                    on: {
                      click: function ($event) {
                        _vm.reveal = 2
                      },
                    },
                  },
                  [
                    _vm._v("レビュー "),
                    _c("span", [
                      _vm._v(
                        "Review（" + _vm._s(_vm.productData.average_star) + "）"
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.reveal === 1
            ? _c("div", { staticClass: "product-custom" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.productData.pc_desc) },
                }),
              ])
            : _vm.reveal === 2
            ? _c("div", { staticClass: "evaluate" }, [
                _c("div", { staticClass: "total" }, [
                  _c("div", { staticClass: "total-rate" }, [
                    _c("div", [_vm._v(_vm._s(_vm.productData.average_star))]),
                    _c(
                      "div",
                      [
                        _c("el-rate", {
                          attrs: {
                            value: _vm.productData.average_star || 5,
                            "allow-half": "",
                            disabled: "",
                          },
                        }),
                        _c("div", { staticClass: "num" }, [
                          _vm._v("(" + _vm._s(_vm.total) + "件の商品レビュー)"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "evaluate-box" },
                  [
                    _vm._l(_vm.producCommentsData, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "evaluate-item" },
                        [
                          _c("div", [_vm._v(_vm._s(item.user_stats) + "user")]),
                          _c(
                            "div",
                            { staticClass: "date" },
                            [
                              _c("el-rate", {
                                attrs: {
                                  value: item.stars,
                                  "allow-half": "",
                                  disabled: "",
                                  "show-score": "",
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(item.created_at))]),
                            ],
                            1
                          ),
                          item.images.length > 0
                            ? _c(
                                "div",
                                { staticClass: "image-content" },
                                _vm._l(item.images, function (value, key) {
                                  return _c(
                                    "div",
                                    {
                                      key: key,
                                      staticClass: "item",
                                      class: {
                                        current:
                                          _vm.imageIndex === key &&
                                          _vm.currentIndex === index,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleImagePreview(
                                            value,
                                            index,
                                            key
                                          )
                                        },
                                      },
                                    },
                                    [_c("img", { attrs: { src: value } })]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentIndex === index,
                                  expression: "currentIndex === index",
                                },
                              ],
                              staticClass: "preview-image",
                            },
                            [
                              _c("img", {
                                class:
                                  _vm.imageIndex === 0
                                    ? "one"
                                    : _vm.imageIndex === 1
                                    ? "two"
                                    : _vm.imageIndex === 2
                                    ? "three"
                                    : _vm.imageIndex === 3
                                    ? "four"
                                    : "",
                                attrs: { src: _vm.previewImageUrl },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v(_vm._s(item.content)),
                          ]),
                        ]
                      )
                    }),
                    _c("pagination", {
                      attrs: {
                        hidden: _vm.total <= _vm.parameterInfo.limit,
                        total: _vm.total,
                        page: _vm.parameterInfo.page,
                        limit: _vm.parameterInfo.limit,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.parameterInfo, "page", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.parameterInfo, "limit", $event)
                        },
                        pagination: _vm.producComments,
                      },
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "right-content" }, [
          _vm.productData.product.length > 0
            ? _c(
                "div",
                { staticClass: "product-list" },
                [
                  _c("div", { staticClass: "product-list-title" }, [
                    _vm._v("こちらもおすすめ"),
                  ]),
                  _vm._l(_vm.productData.product, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "product-item" },
                      [
                        _c("img", { attrs: { src: item.image } }),
                        _c(
                          "div",
                          { staticClass: "product-desc" },
                          [
                            _c("div", { staticClass: "product-name" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                            _c("div", { staticClass: "price" }, [
                              _vm._v(
                                "￥" + _vm._s(_vm.calcThousands(item.price))
                              ),
                              _c("span", [_vm._v("(税込)")]),
                            ]),
                            _c(
                              "router-link",
                              { attrs: { to: "/product/" + item.product_sn } },
                              [_vm._v("一緒に購入する")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _c("mail", {
        attrs: { "confirm-visible": _vm.confirmVisible },
        on: {
          "update:confirmVisible": function ($event) {
            _vm.confirmVisible = $event
          },
          "update:confirm-visible": function ($event) {
            _vm.confirmVisible = $event
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imgVisible,
              expression: "imgVisible",
            },
          ],
          staticClass: "overlay img-overlay",
        },
        [
          _c("img", {
            staticClass: "close-btn",
            attrs: { src: require("@/assets/images/pc/close-btn-white.png") },
            on: {
              click: function ($event) {
                _vm.imgVisible = false
              },
            },
          }),
          _c("img", {
            staticClass: "arrow-left",
            attrs: { src: require("@/assets/images/pc/arrow-left.png") },
            on: { click: _vm.imgVisibleLeft },
          }),
          _c("img", { staticClass: "img-box", attrs: { src: _vm.imgData } }),
          _c("img", {
            staticClass: "arrow-right",
            attrs: { src: require("@/assets/images/pc/arrow-right.png") },
            on: { click: _vm.imgVisibleRight },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }