<template>
  <div class="register-content">
    <div class="bg">
      <div class="breadcrumb">
        <router-link to="/">家具通販専門店TOP</router-link>
        <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
        <span>新規ユーザー登録</span>
      </div>
    </div>
    <div class="right-form">
      <div class="title">新規ユーザー登録</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <div class="inline-item">
          <el-form-item prop="first_name" label="">
            <el-input v-model.trim="ruleForm.first_name" placeholder="姓※" />
          </el-form-item>
          <el-form-item prop="last_name" label="">
            <el-input v-model.trim="ruleForm.last_name" placeholder="名※" />
          </el-form-item>
        </div>
        <el-form-item prop="email" label="">
          <el-input v-model.trim="ruleForm.email" placeholder="メールアドレス（会員ID）※" />
        </el-form-item>
        <el-form-item v-show="ruleForm.email" prop="" label="">
          <div class="name">メールアドレス確認</div>
          <div class="value">{{ ruleForm.email }}</div>
          <div class="email-text">※メールアドレスにお間違いがないか、今一度ご確認ください。</div>
        </el-form-item>
        <el-form-item prop="password" label="">
          <el-input v-model.trim="ruleForm.password" type="password" placeholder="パスワード※" />
        </el-form-item>
        <el-form-item prop="password_confirmation" label="">
          <el-input v-model.trim="ruleForm.password_confirmation" type="password" placeholder="パスワード（確認用）※" />
        </el-form-item>
        <el-form-item prop="" label="">
          <el-button type="primary" class="button" :loading="btnLoading" @click="handleRegister">
            <div class="box">
              <span>新規ユーザー登録する</span>
              <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
            </div>
          </el-button>
          <div class="text">
            すでにアカウントがあって、<router-link to="/login">ログイン</router-link>します
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { register } from '@/api/user'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        first_name: { required: true, message: '姓をご入力ください', trigger: ['blur'] },
        last_name: { required: true, message: '名をご入力ください', trigger: ['blur'] },
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ],
        password: { required: true, message: 'パスワードごをご入力ください', trigger: ['blur'] },
        password_confirmation: { required: true, message: 'パスワードを再度ご入力ください', trigger: ['blur'] }
      },
      btnLoading: false
    }
  },
  methods: {
    handleRegister() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.btnLoading = true
          register(this.ruleForm).then(() => {
            this.btnLoading = false
            this.$router.push({ path: '/register/auth', query: { first_name: this.ruleForm.first_name, last_name: this.ruleForm.last_name }})
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.register-content{
    display: flex;
    .bg{
        width: 1076px;
        height: 760px;
        background: url('../../../assets/images/pc/login_bg.png') 100%;
        .breadcrumb{
            margin-top: 28px;
            margin-left: 240px;
        }
    }
    .right-form{
        margin-left: 18px;
        .title{
          margin-top: 80px;
          font-size: 28px;
          font-weight: 700;
        }
        .el-form{
          margin-top: 38px;
        }
        .inline-item{
            width: 400px;
            display: flex;
            justify-content: space-between;
            .el-input{
                width: 188px;
            }
        }
        .el-input{
          width: 400px;
        }
        .name{
          color: #999999;
          font-size: 16px;
        }
        .value{
          font-size: 36px;
          font-weight: bold;
        }
        .email-text{
          color: #999999;
        }
        .button{
          width: 400px;
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          background: #333;
          color: #fff;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
          .box{
            display: flex;
            justify-content: space-between;
          }
          img{
            width: 32px;
            height: 9px;
          }
          .arrow{
            display: flex;
            align-items: center;
          }
        }
        .is-loading .box{
          display: none;
        }
        .text{
          margin-top: 10px;
          font-size: 14px;
          color: #999;
          a{
            color: #333;
            text-decoration: underline;
          }
        }
    }
}
</style>
