<template>
  <div class="password-content">
    <img src="@/assets/images/sp/email_icon.png" class="email-icon">
    <div class="title">パスワードをお忘れの方</div>
    <div class="text">
      再設定メールを{{ email }}まで送信済みです、1時間以内にパスワードの再設定を行ってください。
    </div>
    <router-link to="/">
      <div class="button">トップページ</div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: this.$route.query.email
    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.password-content{
    margin-top: 1.28rem;
    padding: 0 .24rem;
    text-align: center;
    flex: 1;
    .email-icon{
        width: 1.32rem;
        height: .84rem;
    }
    .title{
        margin-top: .48rem;
        font-size: .46rem;
        font-weight: 700;
        color: #4bc4b0;
    }
    .text{
        margin-top: .28rem;
        line-height: .42rem;
    }
    .button{
      width: 4.08rem;
      height: .8rem;
      margin: .8rem auto 0;
      line-height: .8rem;
      text-align: center;
      color: #ffffff;
      background: #333333;
    }
}
</style>
