import request from '@/utils/request'
/**
 * 上传相关api
 */

// 图片上传
export function uploadImage(data, baseURL) {
  const config = {
    url: '/comments/images',
    method: 'post',
    data
  }
  if (baseURL) {
    config.baseURL = baseURL
  }
  return request(config)
}
