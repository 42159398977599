var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.swiperData.length > 0,
            expression: "swiperData.length>0",
          },
        ],
        staticClass: "swiper home-page-swiper",
      },
      [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.swiperData, function (item) {
            return _c("div", { key: item.id, staticClass: "swiper-slide" }, [
              item.href
                ? _c("a", { attrs: { href: item.href } }, [
                    _c("img", { attrs: { src: item.image, alt: item.alt } }),
                  ])
                : _c("img", { attrs: { src: item.image, alt: item.alt } }),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "swiper-pagination" }),
      ]
    ),
    _c(
      "div",
      { staticClass: "new-product-content" },
      [
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/new/product" } },
          [
            _c("div", { staticClass: "en" }, [_vm._v("NEW ITEM")]),
            _c("div", { staticClass: "ja" }, [_vm._v("新商品一覧")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/search?input=3-5営業日出荷" } },
          [
            _c("div", { staticClass: "en" }, [_vm._v("SHORT PERIOD")]),
            _c("div", { staticClass: "ja" }, [_vm._v("短納期商品一覧")]),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "category-content" }, [
      _c("div", { staticClass: "view-title" }, [_vm._v("CATEGORY SEARCH")]),
      _c(
        "div",
        { staticClass: "item-content" },
        [
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/sofa" } },
            [_vm._v("ソファ")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/table" } },
            [_vm._v("テーブル")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/bed" } },
            [_vm._v("ベッド")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/chair" } },
            [_vm._v("チェア")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/rack" } },
            [_vm._v("収納・本棚")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/TV_board" } },
            [_vm._v("TVボード")]
          ),
          _vm.categoryShow
            ? [
                _c(
                  "router-link",
                  { staticClass: "item", attrs: { to: "/category/interior" } },
                  [_vm._v("インテリア雑貨")]
                ),
                _c(
                  "router-link",
                  { staticClass: "item", attrs: { to: "/category/outdoor" } },
                  [_vm._v("アウトドア")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      !_vm.categoryShow
        ? _c(
            "div",
            {
              staticClass: "view-more",
              on: {
                click: function ($event) {
                  _vm.categoryShow = true
                },
              },
            },
            [_vm._v("view more")]
          )
        : _vm._e(),
    ]),
    _c("div", {
      staticClass: "custom",
      domProps: { innerHTML: _vm._s(_vm.customData.sp_content) },
    }),
    _vm.productData.length
      ? _c("div", { staticClass: "product-content" }, [
          _c("div", { staticClass: "view-title" }, [_vm._v("PRODUCTS")]),
          _c(
            "div",
            { staticClass: "waterfall-container" },
            _vm._l(_vm.productData, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "waterfall-item" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/product/" + item.product_sn } },
                    [
                      _c("img", {
                        attrs: { src: item.image, alt: item.image_alt },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "collect",
                          on: {
                            click: function (event) {
                              return _vm.handleCollection(event, item)
                            },
                          },
                        },
                        [
                          item.isCollect
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/images/sp/home_collect_in.png"),
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/images/sp/home_collect.png"),
                                },
                              }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPage !== _vm.allPage,
                  expression: "currentPage !== allPage",
                },
              ],
              staticClass: "view-more",
              on: { click: _vm.handleMoreProduct },
            },
            [_vm._v("view more")]
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "category-content" }, [
      _c("div", { staticClass: "view-title" }, [_vm._v("CATEGORY SEARCH")]),
      _c(
        "div",
        { staticClass: "item-content" },
        [
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/sofa" } },
            [_vm._v("ソファ")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/table" } },
            [_vm._v("テーブル")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/bed" } },
            [_vm._v("ベッド")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/chair" } },
            [_vm._v("チェア")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/rack" } },
            [_vm._v("収納・本棚")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/TV_board" } },
            [_vm._v("TVボード")]
          ),
          _vm.categoryShow
            ? [
                _c(
                  "router-link",
                  { staticClass: "item", attrs: { to: "/category/interior" } },
                  [_vm._v("インテリア雑貨")]
                ),
                _c(
                  "router-link",
                  { staticClass: "item", attrs: { to: "/category/outdoor" } },
                  [_vm._v("アウトドア")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      !_vm.categoryShow
        ? _c(
            "div",
            {
              staticClass: "view-more",
              on: {
                click: function ($event) {
                  _vm.categoryShow = true
                },
              },
            },
            [_vm._v("view more")]
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.articleData.length > 0,
            expression: "articleData.length>0",
          },
        ],
      },
      [
        _c("div", { staticClass: "view-title" }, [_vm._v("INFORMATION")]),
        _c(
          "div",
          { staticClass: "article-box" },
          _vm._l(_vm.articleData, function (item) {
            return _c(
              "router-link",
              {
                key: item.id,
                staticClass: "article-item",
                attrs: { to: "/article/" + item.id },
              },
              [
                _c("div", { staticClass: "date" }, [
                  _vm._v(_vm._s(item.created_at.substring(0, 10))),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ]
            )
          }),
          1
        ),
        _c(
          "router-link",
          { staticClass: "view-more", attrs: { to: "/article" } },
          [_vm._v("view more")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }