<template>
  <div>
    <div class="custom" v-html="categoryData.pc_desc" />
    <div class="product-box">
      <div class="category-box">
        <div class="category-title">こだわり条件</div>
        <el-menu>
          <el-submenu v-for="(item,index) in tagsData" :key="index" :index="String(index)">
            <span slot="title">{{ item.name }}</span>
            <template v-if="item.id !== 'カラー'">
              <el-menu-item v-for="(it,ind) in item.tags" :key="ind" :index="it.name">
                <div slot="title" class="flex" :class="{active:searchData.tag_ids === it.id}" @click="changeTags(it.id)">
                  <span class="name">{{ it.name }}</span>
                  <span class="count">{{ it.count }}</span>
                </div>
              </el-menu-item>
            </template>
            <template v-else>
              <div class="label">
                <div
                  v-for="(its,key) in item.tags"
                  :key="key"
                  class="color-box"
                  :class="{current:searchData.color_tag_ids === its.id}"
                  @click="changeColorTags(its.id)"
                >
                  <div class="round" :style="{background:its.color_value}">
                    <span />
                  </div>
                  <div class="count">{{ its.count }}</div>
                </div>
              </div>
            </template>
          </el-submenu>
        </el-menu>
      </div>
      <div class="goods-box">
        <div class="screen">
          <span class="text">01～ 20 件目を表示しています。（全{{ total }}件）</span>
          <el-select v-model="parameterInfo.sort" :popper-append-to-body="false" @change="parameterInfo.page = 1,productList()">
            <el-option label="表示順" value="sort" />
            <el-option label="人気順" value="is_hot" />
            <el-option label="新着順" value="-created_at" />
            <el-option label="価格安い順" value="price" />
            <el-option label="価格高い順" value="-price" />
          </el-select>
        </div>
        <div class="goods-list">
          <router-link v-for="item in initData" :key="item.id" :to="'/product/'+ item.product_sn" class="goods-item">
            <div class="image">
              <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
              <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="discount">
                <img src="@/assets/images/pc/off_icon.png">
                <span>{{ item.promotion[0].discount }}%OFF</span>
              </div>
              <div v-if="!item.is_on_sale" class="no-inventory">在庫なし</div>
            </div>
            <div class="label" @mouseleave="item.colorTagIndex = ''">
              <div
                v-for="(it,index) in item.color_tags"
                :key="index"
                :style="{background:it.color_value}"
                :class="{active:item.colorTagIndex === index}"
                @mouseenter="item.colorTagIndex = index"
              >
                <span />
              </div>
            </div>
            <div class="price">
              <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
                <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
                <span class="original">¥{{ calcThousands(item.price) }}</span>
              </template>
              <template v-else>
                ¥{{ calcThousands(item.price) }}
              </template>
              <span v-if="item.is_hot" class="sale">SALE</span>
              <span v-if="item.is_new" class="new">NEW</span>
            </div>
            <div class="title">
              <span>{{ item.title }}</span>
            </div>
          </router-link>
          <div class="goods-item hidden" />
        </div>
        <pagination-pc
          :hidden="total <= parameterInfo.limit"
          :total="total"
          :page.sync="parameterInfo.page"
          :limit.sync="parameterInfo.limit"
          :link="$route.fullPath"
          @pagination="productList"
        />
      </div>
    </div>
    <popularity />
    <special />
  </div>
</template>
<script>
import _ from 'lodash'
import { productList } from '@/api/product'
import { getCategoryDetail, getTagCategories } from '@/api/category'
import { getColorTagList } from '@/api/tag'
import popularity from '@/views/pc/product/popularity'
import special from '@/views/pc/product/special'
import { calcThousands, parameterCorrection } from '@/utils/validate'
import PaginationPc from '@/components/PaginationPc'
export default {
  components: { popularity, special, PaginationPc },
  data() {
    return {
      categoryData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        pc_desc: null,
        pc_desc_css_path: '',
        pc_desc_js_path: ''
      },
      initData: [],
      tag: [],
      colorTag: [],
      tagsData: [],
      total: 0,
      parameterInfo: {
        page: parseInt(this.$route.query.page) || 1,
        limit: 20,
        sort: 'sort',
        include: 'tag,colorTags,promotion'
      },
      searchData: {
        // is_on_sale: true,
        color_tag_ids: '',
        tag_ids: ''
      }
    }
  },
  metaInfo() {
    return {
      title: this.categoryData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.categoryData.seo_keywords },
        { vmid: 'description', name: 'description', content: this.categoryData.seo_description }
      ],
      link: [
        { vmid: this.categoryData.pc_desc_css_path, rel: 'stylesheet', href: this.categoryData.pc_desc_css_path }
      ],
      script: [
        { vmid: this.categoryData.pc_desc_js_path, async: 'async', src: this.categoryData.pc_desc_js_path }
      ]
    }
  },
  computed: {
    url: function() {
      return this.$route.params.url
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.searchData.color_tag_ids = ''
        this.searchData.tag_ids = ''
        this.parameterInfo.page = parseInt(this.$route.query.page) || 1
        this.parameterInfo.sort = this.$route.query.sort || 'sort'
        this.fetchData().then(() => {
          this.productList()
          this.getColorTagList()
        })
      }
    }
  },
  mounted() {
    this.fetchData().then(() => {
      this.productList()
      this.getColorTagList()
    })
  },
  created() {
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log(to, from)
  //   let position = 0
  //   if (to.name === 'product') {
  //     position = window.scrollY
  //   }
  //   this.$store.dispatch('user/updatePosition', position)
  //   next()
  // },
  methods: {
    calcThousands,
    fetchData() {
      return getCategoryDetail(this.url).then(result => {
        result.pc_desc_css_path = result.pc_desc_css_path || ''
        result.pc_desc_js_path = result.pc_desc_js_path || ''
        this.categoryData = result
        // const position = this.$store.state.user.position
        // setTimeout(() => {
        //   window.scroll(0, position)
        // }, 100)
      })
    },
    productList() {
      let data = parameterCorrection({ ...this.searchData, category_with_children: this.categoryData.id })
      data = { ...data, ...this.parameterInfo }
      productList(data).then((result) => {
        result.data.forEach(item => {
          item.colorTagIndex = ''
        })
        this.initData = result.data
        this.total = result.total
        this.initData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
      })
    },
    getColorTagList() {
      getColorTagList(this.categoryData.id).then(result => {
        this.colorTag = result
        getTagCategories(this.categoryData.id, { type: 'all', include: 'toShowTags' }).then(result => {
          let tagCategoriesOrders = []
          this.categoryData.tag_categories.forEach(item => {
            result.forEach(item2 => {
              if (item.id === 'カラー') {
                tagCategoriesOrders.push('カラー')
              }
              if (item2.id === item.id) {
                tagCategoriesOrders.push(item2.id)
              }
            })
          })
          tagCategoriesOrders = _.uniq(tagCategoriesOrders)
          const index = tagCategoriesOrders.findIndex((item) => item === 'カラー')
          const arr = result
          const colorData = {
            id: 'カラー',
            name: 'カラー',
            tags: this.colorTag
          }
          arr.splice(index, 0, colorData)
          this.tagsData = arr
        })
      })
    },
    changeColorTags(id) {
      if (this.searchData.color_tag_ids === id) {
        this.searchData.color_tag_ids = ''
      } else {
        this.searchData.color_tag_ids = id
      }
      this.searchData.tag_ids = ''
      this.parameterInfo.page = 1
      this.productList()
    },
    changeTags(id) {
      if (this.searchData.tag_ids === id) {
        this.searchData.tag_ids = ''
      } else {
        this.searchData.tag_ids = id
      }
      this.searchData.color_tag_ids = ''
      this.parameterInfo.page = 1
      this.productList()
    }
  }
}
</script>
<style lang="scss" scoped>
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.product-box{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 1440px;
  margin: 100px auto;
}
.category-box {
  width: 280px;
  border-bottom: 1px solid #E8EBED;
  margin-top: 25px;
  .label{
    padding-bottom: 16px;
    white-space: initial;
    span{
      background: transparent;
    }
    .color-box{
      display: inline-block;
      margin: 0 8px 14px 0;
      text-align: center;
      .round{
        width: 34px;
        height: 34px;
        box-sizing: border-box;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid #e8ebed;
      }
      .count{
        margin-top: 6px;
        font-size: 16px;
        color: #b2b2b2;
      }
    }
    .current{
      .round{
        border: 1px solid #333;
      }
      span{
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid #fff;
      }
      .count{
        color: #333333;
        font-weight: bold;
      }
    }
  }
}
.category-title{
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 2px solid #333;
}
.el-menu ::v-deep{
  border-right: 0;
  .el-submenu__title{
    font-size: 16px;
    padding: 0 12px !important;
    border-top: 1px solid #E8EBED;
    &:hover{
      background: #fff;
    }
  }
  .is-opened{
    .el-submenu__title{
      color: #333333;
      font-weight: bold;
    }
  }
  .el-menu-item{
    font-size: 16px;
    height: auto;
    line-height: 1.15;
    color: #666;
    padding: 6px 24px 6px !important;
    &:last-child{
      padding-bottom: 20px !important;
    }
    cursor: inherit;
    .flex{
      display: flex;
      justify-content: space-between;
      &:hover{
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .count{
      color: #b2b2b2;
    }
    &:hover{
      background: #fff;
    }
    &:active,&:focus{
      color: #333;
      background: #fff;
    }
  }
  .color{
    padding: 0 !important;
  }
  .active{
    color: #333333;
    font-weight: bold;
    .count{
      color: #333333;
    }
  }
}
.goods-box{
  width: 1098px;
  .screen ::v-deep{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding-bottom: 8px;
    border-bottom: 2px solid #333;
    .text{
      margin-top: 16px;
    }
    .el-input{
      width: 138px;
      height: 48px;
    }
    input{
      height: 100%;
      font-size: 16px;
    }
    .el-select-dropdown{
      margin: 0;
    }
    .el-select-dropdown__item{
      font-size: 16px;
    }
    .popper__arrow{
      display: none;
    }
  }
}
.goods-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.goods-item{
  width: 350px;
  margin: 32px 0 0 0;
  .image{
    position: relative;
    height: 350px;
    img{
      width: 350px;
      height: 350px;
    }
  }
  .title{
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    margin: 12px 0 0 0;
    font-size: 16px;
  }
  .sale{
    font-weight: bold;
    color: #FECB35;
    margin-left: 8px;
  }
  .new{
    font-weight: bold;
    color: #F90412;
    margin-left: 8px;
  }
  .discount{
    position: absolute;
    left: 8px;
    bottom: 8px;
    width: 80px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #c40a0a;
    img{
      width: 12px;
      height: 12px;
      margin-right: 4px;
      vertical-align: middle;
    }
    span{
      vertical-align: middle;
    }
  }
  .no-inventory{
    position: absolute;
    right: 8px;
    top: 8px;
    width: 72px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: #504E4A;
    color: #ffffff;
  }
  .label{
    margin-top: 8px;
    span{
      background: transparent;
    }
    div{
      display: inline-block;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 50%;
      margin-right: 4px;
      border: 1px solid #e8ebed;
    }
    .active{
      border: 1px solid #333;
      span{
        display: block;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid #fff;
      }
    }
  }
  // .desc{
  //   display: -webkit-box;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  //   -webkit-box-orient:vertical;
  //   -webkit-line-clamp:2;
  //   margin: 16px 0 0 0;
  // }
  .price{
    margin-top: 12px;
    .now{
      font-size: 16px;
      font-weight: bold;
      color: #c40a0a;
    }
    .original{
      margin-left: 8px;
      color: #999999;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
}
.hidden{
  visibility: hidden;
  margin: 0;
}
</style>
