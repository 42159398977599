var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "tips" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _c("span", [_vm._v(" / ")]),
          _vm._l(_vm.parentsData, function (item, index) {
            return _c(
              "span",
              { key: index },
              [
                index !== _vm.parentsData.length - 1
                  ? _c("router-link", { attrs: { to: item.url } }, [
                      _c("span", { staticClass: "crumb-a" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ])
                  : _vm._e(),
                index !== _vm.parentsData.length - 1
                  ? _c("span", [_vm._v(" / ")])
                  : _vm._e(),
                index === _vm.parentsData.length - 1
                  ? _c("span", { staticClass: "crumb-span" }, [
                      _vm._v(_vm._s(item.name)),
                    ])
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { ref: "productSwiper", staticClass: "swiper product-swiper" },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.productData.galleries, function (item, index) {
              return _c("div", { key: index, staticClass: "swiper-slide" }, [
                _c("img", { attrs: { width: "100%", src: item } }),
              ])
            }),
            0
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.floatBtnShow,
              expression: "floatBtnShow",
            },
          ],
          staticClass: "suspension-button-content",
        },
        [
          _c(
            "div",
            {
              staticClass: "item",
              class: { active: _vm.current === 1 },
              on: {
                click: function ($event) {
                  return _vm.handleScroll("productDesc", 1)
                },
              },
            },
            [_vm._v("製品詳細")]
          ),
          _vm.producCommentsData.length
            ? _c(
                "div",
                {
                  staticClass: "item",
                  class: { active: _vm.current === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.handleScroll("comment", 2)
                    },
                  },
                },
                [_vm._v("レビュー")]
              )
            : _vm._e(),
          _vm.productData.product.length
            ? _c(
                "div",
                {
                  staticClass: "item",
                  class: { active: _vm.current === 3 },
                  on: {
                    click: function ($event) {
                      return _vm.handleScroll("relation", 3)
                    },
                  },
                },
                [_vm._v("関連商品")]
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "swiper product-swiper-thumbs" }, [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.productData.galleries, function (item, index) {
            return _c("div", { key: index, staticClass: "swiper-slide" }, [
              _c("img", { attrs: { src: item } }),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "product-introduce" }, [
        _c("div", { staticClass: "price-content" }, [
          _c("div", { staticClass: "now" }, [
            _c("div", { staticClass: "price" }, [
              _vm._v("￥"),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm.productData.promotion.length > 0 &&
                      _vm.productData.promotion[0].is_open
                      ? _vm.calcThousands(_vm.productData.discount_price)
                      : _vm.calcThousands(_vm.productData.price)
                  )
                ),
              ]),
              _vm._v("(税込)"),
            ]),
            _vm.productData.promotion.length > 0 &&
            _vm.productData.promotion[0].is_open
              ? _c("div", { staticClass: "off" }, [
                  _vm._v(
                    _vm._s(_vm.productData.promotion[0].discount) + "%OFF"
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.productData.promotion.length > 0 &&
          _vm.productData.promotion[0].is_open
            ? _c("div", { staticClass: "original" }, [
                _vm._v(
                  " ￥" +
                    _vm._s(_vm.calcThousands(_vm.productData.price)) +
                    "円 "
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "label" },
          _vm._l(_vm.productData.tag, function (item, index) {
            return _c("div", { key: index }, [_vm._v(_vm._s(item.name))])
          }),
          0
        ),
        _c("div", { staticClass: "sn" }, [
          _vm._v("商品コード：" + _vm._s(_vm.productData.product_sn)),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.productData.title)),
        ]),
        _vm.producCommentsData.length
          ? _c(
              "div",
              {
                staticClass: "rate",
                on: {
                  click: function ($event) {
                    return _vm.commentScroll()
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.productData.average_star || 5))]),
                _c("el-rate", {
                  attrs: {
                    value: _vm.productData.average_star || 5,
                    "allow-half": "",
                    disabled: "",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "checked-attribute-content" }, [
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                _vm.attributeShow = true
              },
            },
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "checked-value" }, [
              _vm._v(_vm._s(_vm.checkedAttribute)),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "image-content" },
          _vm._l(_vm.productData.attribute, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "item" },
              _vm._l(item.attribute_value, function (value, index2) {
                return _c(
                  "div",
                  {
                    key: index2,
                    staticClass: "value",
                    class: { active: value.id === _vm.skuChecked[index].id },
                    on: {
                      click: function ($event) {
                        return _vm.changeSku(index, value)
                      },
                    },
                  },
                  [
                    value.icon
                      ? _c("div", { staticClass: "img-box" }, [
                          _c("img", {
                            attrs: { src: value.icon, alt: value.value },
                          }),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "desc-content" }, [
        _vm._m(1),
        _c("div", { staticClass: "text2" }, [
          _vm._v(
            "購入金額 " +
              _vm._s(_vm.calcThousands(_vm.config.free_shipping_amount)) +
              "円以上 送料無料"
          ),
        ]),
        _c("div", { staticClass: "text3" }, [
          _vm._v(
            "※北海道・沖縄・鹿児島県・離島へのお届けは、別途追加送料が必要です。"
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "product-desc" },
        [
          _c(
            "div",
            {
              staticClass: "product-desc-title",
              on: {
                click: function ($event) {
                  _vm.descShow = !_vm.descShow
                },
              },
            },
            [
              _c("span", { ref: "productDesc" }, [_vm._v("製品詳細")]),
              _vm.descShow
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_hide.png"),
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_open.png"),
                    },
                  }),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.descShow,
                    expression: "descShow",
                  },
                ],
              },
              [
                _c("div", {
                  staticClass: "product-desc-content",
                  domProps: { innerHTML: _vm._s(_vm.productData.mobile_desc) },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.producCommentsData.length,
              expression: "producCommentsData.length",
            },
          ],
          ref: "comment",
          staticClass: "product-desc product-comment",
        },
        [
          _c("div", { staticClass: "product-anchor" }),
          _c(
            "div",
            {
              staticClass: "product-desc-title",
              on: {
                click: function ($event) {
                  _vm.commentShow = !_vm.commentShow
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  "レビュー（" + _vm._s(_vm.productData.average_star) + "）"
                ),
              ]),
              _vm.commentShow
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_hide.png"),
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/sp/article_open.png"),
                    },
                  }),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.commentShow,
                    expression: "commentShow",
                  },
                ],
                staticClass: "evaluate-box",
              },
              [
                _vm._l(_vm.producCommentsData, function (item, index) {
                  return [
                    index < 3
                      ? _c(
                          "div",
                          { key: index, staticClass: "evaluate-item" },
                          [
                            _c("div", { staticClass: "evaluate-box" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.user_stats) + "user"),
                              ]),
                              _c("div", { staticClass: "stars" }, [
                                _vm._v(_vm._s(item.stars)),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "evaluate-box" },
                              [
                                _c("div", { staticClass: "date" }, [
                                  _vm._v(_vm._s(item.created_at)),
                                ]),
                                _c("el-rate", {
                                  attrs: {
                                    value: item.stars,
                                    "allow-half": "",
                                    disabled: "",
                                  },
                                }),
                              ],
                              1
                            ),
                            item.images.length > 0
                              ? _c("div", { staticClass: "image-content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "box",
                                      class:
                                        item.images.length === 1
                                          ? "one"
                                          : item.images.length === 2
                                          ? "two"
                                          : item.images.length === 3
                                          ? "three"
                                          : item.images.length === 4
                                          ? "four"
                                          : "",
                                    },
                                    _vm._l(item.images, function (value, key) {
                                      return _c("img", {
                                        key: key,
                                        attrs: { src: value },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleImagePreview(
                                              item.images,
                                              key
                                            )
                                          },
                                        },
                                      })
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "desc" }, [
                              _vm._v(_vm._s(item.content)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
                _c("el-collapse-transition", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.commentTotalShow,
                          expression: "commentTotalShow",
                        },
                      ],
                    },
                    [
                      _vm._l(_vm.producCommentsData, function (item, index) {
                        return [
                          index > 2
                            ? _c(
                                "div",
                                { key: index, staticClass: "evaluate-item" },
                                [
                                  _c("div", { staticClass: "evaluate-box" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.user_stats) + "user"),
                                    ]),
                                    _c("div", { staticClass: "stars" }, [
                                      _vm._v(_vm._s(item.stars)),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "evaluate-box" },
                                    [
                                      _c("div", { staticClass: "date" }, [
                                        _vm._v(_vm._s(item.created_at)),
                                      ]),
                                      _c("el-rate", {
                                        attrs: {
                                          value: item.stars,
                                          "allow-half": "",
                                          disabled: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  item.images.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "image-content" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "box",
                                              class:
                                                item.images.length === 1
                                                  ? "one"
                                                  : item.images.length === 2
                                                  ? "two"
                                                  : item.images.length === 3
                                                  ? "three"
                                                  : item.images.length === 4
                                                  ? "four"
                                                  : "",
                                            },
                                            _vm._l(
                                              item.images,
                                              function (value, key) {
                                                return _c("img", {
                                                  key: key,
                                                  attrs: { src: value },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleImagePreview(
                                                        item.images,
                                                        key
                                                      )
                                                    },
                                                  },
                                                })
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "desc" }, [
                                    _vm._v(_vm._s(item.content)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.producCommentsData.length > 3,
                        expression: "producCommentsData.length > 3 ",
                      },
                    ],
                    staticClass: "view-more",
                    on: {
                      click: function ($event) {
                        _vm.commentTotalShow = !_vm.commentTotalShow
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.commentTotalShow ? "閉じる" : "view more")
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "label-keywords-content" },
        _vm._l(_vm.productData.keywordTag, function (item, index) {
          return _c(
            "router-link",
            {
              key: index,
              staticClass: "item",
              attrs: { to: "/search?input=" + item.name + "&page=1" },
            },
            [_vm._v("# " + _vm._s(item.name))]
          )
        }),
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.productData.product.length,
              expression: "productData.product.length",
            },
          ],
          ref: "relation",
        },
        [
          _c("div", { staticClass: "view-title" }, [_vm._v("関連商品")]),
          _c("div", { staticClass: "swiper relation-swiper" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.productData.product, function (item, index) {
                return _c(
                  "router-link",
                  {
                    key: index,
                    staticClass: "swiper-slide",
                    attrs: { to: "/product/" + item.product_sn },
                  },
                  [
                    _c("img", { attrs: { width: "100%", src: item.image } }),
                    _c("div", { staticClass: "product-desc" }, [
                      _c("div", { staticClass: "product-name" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v("￥" + _vm._s(_vm.calcThousands(item.price))),
                        _c("span", [_vm._v("(税込)")]),
                      ]),
                    ]),
                  ]
                )
              }),
              1
            ),
            _c("div", { staticClass: "swiper-pagination" }),
            _c("div", { staticClass: "swiper-button-prev" }),
            _c("div", { staticClass: "swiper-button-next" }),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "link-box" },
        [
          _c("router-link", { attrs: { to: "/new/product" } }, [
            _c("div", [_vm._v("NEW ITEM")]),
            _vm._v(" 新商品一覧 "),
          ]),
          _c("router-link", { attrs: { to: "" } }, [
            _c("div", [_vm._v("SHORT PERIOD")]),
            _vm._v(" 短納期商品一覧 "),
          ]),
        ],
        1
      ),
      _c(
        "form",
        {
          attrs: { action: "javascript:return false" },
          on: { submit: _vm.search },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input",
              },
            ],
            ref: "search",
            staticClass: "search",
            attrs: { type: "search", placeholder: "キーワードで検索" },
            domProps: { value: _vm.input },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.input = $event.target.value
              },
            },
          }),
        ]
      ),
      _c("mail", {
        attrs: { "confirm-visible": _vm.confirmVisible },
        on: {
          "update:confirmVisible": function ($event) {
            _vm.confirmVisible = $event
          },
          "update:confirm-visible": function ($event) {
            _vm.confirmVisible = $event
          },
        },
      }),
      _c("div", { staticClass: "product-cart" }, [
        _c(
          "div",
          {
            staticClass: "collection",
            on: {
              click: function ($event) {
                return _vm.collect()
              },
            },
          },
          [
            _vm.productData.is_collected
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/sp/collection_active.png"),
                  },
                })
              : _c("img", {
                  attrs: { src: require("@/assets/images/sp/collection.png") },
                }),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.productData.collects_count + _vm.productData.collect_count
                )
              ),
            ]),
          ]
        ),
        _vm.productData.is_on_sale && _vm.sku_id
          ? _c(
              "div",
              {
                staticClass: "add-cart",
                on: {
                  click: function ($event) {
                    _vm.attributeShow = true
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/images/sp/add-cart.png") },
                }),
                _c("span", [_vm._v("カートに入れる")]),
              ]
            )
          : _c(
              "div",
              {
                staticClass: "add-cart",
                on: {
                  click: function ($event) {
                    _vm.confirmVisible = true
                  },
                },
              },
              [_vm._v("メールで入荷通知を受取る")]
            ),
      ]),
      _c(
        "van-popup",
        {
          staticClass: "fixed-attribute-content",
          attrs: { position: "bottom" },
          model: {
            value: _vm.attributeShow,
            callback: function ($$v) {
              _vm.attributeShow = $$v
            },
            expression: "attributeShow",
          },
        },
        [
          _c("div", { staticClass: "top-content" }, [
            _c("img", {
              staticClass: "image",
              attrs: { src: _vm.imageUrl, alt: _vm.productData.image_alt },
            }),
            _c("div", { staticClass: "detail" }, [
              _c("div", { staticClass: "price" }, [
                _vm._v("￥"),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.productData.promotion.length > 0 &&
                        _vm.productData.promotion[0].is_open
                        ? _vm.calcThousands(_vm.productData.discount_price)
                        : _vm.calcThousands(_vm.productData.price)
                    )
                  ),
                ]),
                _vm._v("(税込)"),
              ]),
              _c("div", { staticClass: "original" }, [
                _vm.productData.promotion.length > 0 &&
                _vm.productData.promotion[0].is_open
                  ? _c("span", [
                      _vm._v(
                        " ￥" +
                          _vm._s(_vm.calcThousands(_vm.productData.price)) +
                          "円 "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "checked-attribute" }, [
                _vm._v(_vm._s(_vm.checkedAttribute)),
              ]),
            ]),
            _c("img", {
              staticClass: "close-btn",
              attrs: { src: require("@/assets/images/sp/close_btn.png") },
              on: {
                click: function ($event) {
                  _vm.attributeShow = false
                },
              },
            }),
          ]),
          _c("div", { staticClass: "mid-content" }, [
            _c(
              "div",
              { staticClass: "product-attribute" },
              [
                _vm._l(_vm.productData.attribute, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "attribute-box" },
                    [
                      _c("div", { staticClass: "attribute-title" }, [
                        _vm._v(_vm._s(item.name)),
                        _c("span", [_vm._v("必須")]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "attribute-item" },
                        _vm._l(item.attribute_value, function (it, ind) {
                          return _c(
                            "div",
                            {
                              key: ind,
                              staticClass: "attribute-item-box",
                              class: {
                                active: it.id === _vm.skuChecked[index].id,
                              },
                              attrs: { title: it.value },
                              on: {
                                click: function ($event) {
                                  return _vm.changeSku(index, it)
                                },
                              },
                            },
                            [
                              it.icon
                                ? _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: { src: it.icon, alt: it.value },
                                    }),
                                  ])
                                : _c("div", { staticClass: "text-box" }, [
                                    _vm._v(_vm._s(it.value)),
                                  ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                _vm._l(_vm.productData.norm, function (item, index) {
                  return _c(
                    "div",
                    { key: "n" + index, staticClass: "attribute-box" },
                    [
                      _c("div", { staticClass: "attribute-title" }, [
                        _vm._v(_vm._s(item.name)),
                        item.is_required
                          ? _c("span", [_vm._v("必須")])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "attribute-item" },
                        _vm._l(item.norm_values, function (it, ind) {
                          return _c(
                            "div",
                            {
                              key: ind,
                              staticClass: "attribute-item-box",
                              class: {
                                active: _vm.norm_values.includes(it.id),
                              },
                              attrs: { title: it.name },
                              on: {
                                click: function ($event) {
                                  return _vm.changeNorm(item, it)
                                },
                              },
                            },
                            [
                              it.image
                                ? _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: { src: it.image, alt: it.name },
                                    }),
                                  ])
                                : _c("div", { staticClass: "text-box" }, [
                                    _vm._v(
                                      _vm._s(
                                        it.name +
                                          "＋￥" +
                                          _vm.calcThousands(it.price) +
                                          "円"
                                      )
                                    ),
                                  ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _c(
            "el-button",
            {
              staticClass: "bottom-content",
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.toCart },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/sp/add-cart.png") },
              }),
              _c("span", [
                _vm._v(
                  "カートに入れる￥" +
                    _vm._s(
                      _vm.productData.promotion.length > 0 &&
                        _vm.productData.promotion[0].is_open
                        ? _vm.calcThousands(_vm.productData.discount_price)
                        : _vm.calcThousands(_vm.productData.price)
                    ) +
                    "(税込)"
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "name" }, [
      _c("span", [_vm._v("選択された仕様")]),
      _c("img", {
        attrs: { src: require("@/assets/images/sp/right_arrow1.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text1" }, [
      _c("span", [_vm._v("受注品 / 納期60日後")]),
      _c("span", [_vm._v("在庫品 / 納期10日後")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }