<template>
  <div class="tokutei-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / 特定商取引法に基づく表示
    </div>
    <div class="table">
      <div class="list-content">
        <div class="list">
          <div class="name">販売業者</div>
          <div class="value">はんこプレミアム株式会社 （Hanko Premium Co.,Ltd.）</div>
        </div>
        <div class="list">
          <div class="name">代表者</div>
          <div class="value">晴海 旭</div>
        </div>
        <div class="list">
          <div class="name">住所</div>
          <div class="value">
            〒274-0065 千葉県船橋市高根台7丁目26-12
          </div>
        </div>
        <div class="list">
          <div class="name">電話番号</div>
          <div class="value">047-489-5595 (営業電話などはご遠慮ください。)</div>
        </div>
        <div class="list">
          <div class="name">電話受付時間</div>
          <div class="value">平日 9:30〜18:30</div>
        </div>
        <div class="list">
          <div class="name">お問い合わせ</div>
          <div class="value">
            <router-link to="/question">お問い合わせはこちら ＞＞</router-link>
          </div>
        </div>
        <div class="list">
          <div class="name">販売価格</div>
          <div class="value">商品紹介ページをご参照ください。</div>
        </div>
        <div class="list">
          <div class="name">公式通販サイト</div>
          <div class="value">
            <router-link to="/">https://www.woodlife.jp/</router-link>
          </div>
        </div>
        <div class="list">
          <div class="name">画面表示と実際の商品</div>
          <div class="value">商品の色、素材感をできるだけ忠実に再現しようと努力してはいますが、大きな商品をモニターの中で小さな写真によって表現していますので、画面上ご覧になっている色と、実際の商品の色が多少異なる場合がございます。</div>
        </div>
        <div class="list">
          <div class="name">商品のお届け</div>
          <div class="value">弊社指定のヤマト運輸会社および、株式会社ハルミからお届けいたします。</div>
        </div>
        <div class="list">
          <div class="name">引き渡し時期</div>
          <div class="value">商品は充分用意しておりますが、同一商品にご注文が殺到した際には品切れになることもあります。ご容赦ください。</div>
        </div>
        <div class="list">
          <div class="name">送料について</div>
          <div class="value">54,000円以上のお買い上げは送料無料です。<br>54,000円以下のお買い上げは1個につき3,000円（税込）です。<br>※北海道・沖縄・鹿児島県・離島へのお届けは、別途追加送料が必要です。</div>
        </div>
        <div class="list">
          <div class="name">返品・<br>交換について</div>
          <div class="value">お客様のご都合による返品・返金および交換はお受けできません。<br>
            <router-link to="/guides/return">詳しくはこちら >></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.tokutei-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .table{
      padding: 0 .24rem;
      margin-top: .34rem;
      border-top: .02rem dashed #e8ebed;
      line-height: .42rem;
      .list{
        display: flex;
        padding: .28rem 0;
        border-bottom: .02rem dashed #e8ebed;
        .name{
          display: flex;
          align-items: center;
          width: 2.24rem;
          font-weight: 700;
        }
        .value{
          width: 4.34rem;
          margin-left: .44rem;
          a{
            text-decoration: underline;
          }
        }
      }
    }
}
</style>
