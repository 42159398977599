import request from '@/utils/request'
/**
 * 用户相关api
 */

// 登录
export function login(data) {
  return request({
    url: '/authorizations',
    method: 'post',
    data
  })
}
// 刷新 token
export function refreshToken() {
  return request({
    url: '/authorizations/current',
    method: 'put'
  })
}
// 注册
export function register(data) {
  return request({
    url: '/users/register',
    method: 'post',
    data
  })
}
// 退出登录
export function logout() {
  return request({
    url: '/authorizations/current',
    method: 'delete'
  })
}
// 获取用户信息
export function getUserDetail() {
  return request({
    url: '/users/current',
    method: 'get'
  })
}
// 修改
export function updateUser(data) {
  return request({
    url: '/users/current',
    method: 'patch',
    data
  })
}
// 修改密码
export function updatePassword(data) {
  return request({
    url: '/users/updatePassword',
    method: 'patch',
    data
  })
}
// 发送修改密码邮件
export function sendForgetMail(data) {
  return request({
    url: '/users/sendForgetMail',
    method: 'POST',
    data
  })
}
// 注册验证
export function registerAuth(data) {
  return request({
    url: '/users/activity',
    method: 'POST',
    data
  })
}
// 重置密码（非登录）
export function resetPassword(data) {
  return request({
    url: '/users/resetPassword',
    method: 'POST',
    data
  })
}
