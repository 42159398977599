<template>
  <div>
    <div class="tips"><router-link to="/">家具TOP</router-link> / ニュース</div>
    <div class="article-title">
      <div @click="commentScroll('info')">
        <img src="@/assets/images/sp/article_arrow_black.png">
        <span>INFORMATION</span>
      </div>
      <div @click="commentScroll('new')">
        <img src="@/assets/images/sp/article_arrow_black.png">
        <span>NEWS</span>
      </div>
    </div>
    <div class="view-title">
      <div>INFORMATION</div>
      <div ref="info" class="seat" />
    </div>
    <div class="info-box">
      <router-link v-for="item in infoData" :key="item.id" class="info-item" :to="'/article/'+ item.id">
        <div class="date">{{ item.created_at.substring(0,10) }}</div>
        <div class="title">{{ item.title }}</div>
      </router-link>
    </div>
    <div class="view-title">
      <div>NEWS</div>
      <div ref="new" class="seat" />
    </div>
    <div class="article-box">
      <router-link v-for="item in newData" :key="item.id" class="article-item" :to="'/article/'+ item.id">
        <img width="100%" :src="item.image">
        <div class="title">{{ item.title }}</div>
        <div class="desc">{{ item.description }}</div>
        <div class="date">{{ item.created_at.substring(0,10) }}</div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { scrollTo } from '@/utils/scrollTo'
import { getArticles } from '@/api/article'
export default {
  data() {
    return {
      newData: [],
      infoData: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    commentScroll(ref) {
      const top = this.$refs[ref].getBoundingClientRect().top + document.documentElement.scrollTop
      scrollTo(top, 800)
    },
    // 文章列表
    getList() {
      getArticles({ type: 'all' }).then((result) => {
        const newData = []
        const infoData = []
        result.forEach(item => {
          if (item.article_category_id === 3) {
            infoData.push(item)
          } else {
            newData.push(item)
          }
        })
        this.newData = newData
        this.infoData = infoData
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tips{
  text-align: center;
  margin: 0.16rem 0 0.16rem;
}
.article-title{
  margin: 0.36rem 0.24rem 0.9rem;
  div{
    font-size: 0.32rem;
    font-weight: bold;
    padding: 0.35rem 0.24rem;
    background: #F4F5FC;
    margin-top: 0.08rem;
  }
  img{
    width: 0.24rem;
    margin-right: 0.24rem;
  }
}
.view-title{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.9rem 0 0.28rem;
  &::before,
  &::after{
    content: '';
    width: 0.8rem;
    height: 0.02rem;
    background: #333333;
    margin: 0 0.4rem;
  }
  .seat{
    position: absolute;
    top: -1.4rem;
  }
}
.info-box{
  margin: 0.28rem 0.24rem 0.9rem;
  .info-item{
    display: flex;
    align-items: center;
    line-height: 0.74rem;
    border-bottom: 0.02rem solid #E8EBED;
    &:nth-child(1){
      border-top: 0.02rem solid #E8EBED;
    }
  }
  .date{
    flex-shrink: 0;
    font-size: 0.024rem;
    color: #999999;
    margin-right: 0.32rem;
  }
  .title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.article-box{
  margin: 0 0.24rem 1.1rem;
  .article-item{
    display: block;
    border: 0.02rem solid #E8EBED;
    margin-bottom: 0.4rem;
  }
  .title{
    font-size: 0.36rem;
    font-weight: bold;
    margin: 0.2rem 0.24rem 0.14rem;
  }
  .desc{
    text-align: justify;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:3;
    margin: 0 0.24rem;
  }
  .date{
    font-size: 0.24rem;
    color: #999999;
    text-align: right;
    margin: 0.24rem 0.24rem 0.3rem;
  }
}
</style>
