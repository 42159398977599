var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.paymentFooter,
            expression: "!paymentFooter",
          },
        ],
        staticClass: "footer-box",
      },
      [
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            [
              _c(
                "router-link",
                { staticClass: "question", attrs: { to: "/question" } },
                [
                  _c("span", [_vm._v("お問合せ")]),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/rightArrow.png"),
                    },
                  }),
                ]
              ),
              _c("div", { staticClass: "article-box" }, [
                _c(
                  "div",
                  { staticClass: "article-item" },
                  [
                    _vm._m(0),
                    _c("router-link", { attrs: { to: "/category/sofa" } }, [
                      _vm._v("ソファ"),
                    ]),
                    _c("router-link", { attrs: { to: "/category/bed" } }, [
                      _vm._v("ベッド"),
                    ]),
                    _c("router-link", { attrs: { to: "/category/rack" } }, [
                      _vm._v("収納"),
                    ]),
                    _c("router-link", { attrs: { to: "/category/table" } }, [
                      _vm._v("テーブル"),
                    ]),
                    _c("router-link", { attrs: { to: "/category/chair" } }, [
                      _vm._v("チェア"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "article-item" },
                  [
                    _vm._m(1),
                    _c("router-link", { attrs: { to: "" } }, [_vm._v("寝室")]),
                    _c("router-link", { attrs: { to: "" } }, [
                      _vm._v("リビング"),
                    ]),
                    _c("router-link", { attrs: { to: "" } }, [
                      _vm._v("キッチン"),
                    ]),
                    _c("router-link", { attrs: { to: "" } }, [
                      _vm._v("ホームオフィス"),
                    ]),
                    _c("router-link", { attrs: { to: "" } }, [
                      _vm._v("子供部屋"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "article-item" },
                  [
                    _vm._m(2),
                    _c("router-link", { attrs: { to: "/guides/category" } }, [
                      _vm._v("ご利用ガイド"),
                    ]),
                    _c("router-link", { attrs: { to: "/question" } }, [
                      _vm._v("お問い合わせ"),
                    ]),
                    _c("router-link", { attrs: { to: "/guides/question" } }, [
                      _vm._v("よくあるご質問"),
                    ]),
                    _c("router-link", { attrs: { to: "/guides/delivery" } }, [
                      _vm._v("配送について"),
                    ]),
                    _c("router-link", { attrs: { to: "/guides/return" } }, [
                      _vm._v("返品・交換"),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "share" }, [
            _c("img", {
              staticClass: "footer-logo",
              attrs: { src: require("@/assets/images/pc/footerlogo.png") },
            }),
            _c("div", { staticClass: "desc" }, [_vm._v("Official APP")]),
            _vm._m(3),
            _c("div", { staticClass: "desc" }, [_vm._v("Official SNS")]),
            _c(
              "div",
              { staticClass: "footer-share" },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.facebook.com/woodlife.jp",
                      target: "_black",
                    },
                    on: {
                      mouseenter: function ($event) {
                        _vm.show = 1
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 1,
                          expression: "show !== 1",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_1.png"),
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 1,
                          expression: "show === 1",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_1_hover.png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "" },
                    nativeOn: {
                      mouseenter: function ($event) {
                        _vm.show = 2
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 2,
                          expression: "show !== 2",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_2.png"),
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 2,
                          expression: "show === 2",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_2_hover.png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.instagram.com/woodlife.jp",
                      target: "_black",
                    },
                    on: {
                      mouseenter: function ($event) {
                        _vm.show = 3
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 3,
                          expression: "show !== 3",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_3.png"),
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 3,
                          expression: "show === 3",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_3_hover.png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "" },
                    nativeOn: {
                      mouseenter: function ($event) {
                        _vm.show = 4
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 4,
                          expression: "show !== 4",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_4.png"),
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 4,
                          expression: "show === 4",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_4_hover.png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "" },
                    nativeOn: {
                      mouseenter: function ($event) {
                        _vm.show = 5
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 5,
                          expression: "show !== 5",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_5.png"),
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 5,
                          expression: "show === 5",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/footerShare_5_hover.png"),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "introduce" },
          [
            _c("span", { staticClass: "introduce-desc" }, [
              _vm._v(
                "COPYRIGHT© " +
                  _vm._s(_vm.year) +
                  " HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED"
              ),
            ]),
            _c("router-link", { attrs: { to: "/company" } }, [
              _vm._v("企業情報"),
            ]),
            _c("router-link", { attrs: { to: "/tokutei" } }, [
              _vm._v("特定商取引法に基づく表示"),
            ]),
            _c("router-link", { attrs: { to: "/privacy" } }, [
              _vm._v("プライバシーポリシー"),
            ]),
            _c("router-link", { attrs: { to: "/guides/about" } }, [
              _vm._v("About US"),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.paymentFooter,
            expression: "paymentFooter",
          },
        ],
        staticClass: "footer-payment-box",
      },
      [
        _vm._v(
          "COPYRIGHT© " +
            _vm._s(_vm.year) +
            " HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED"
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("div", [_vm._v("ITEM")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("div", [_vm._v("ROOM")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("div", [_vm._v("GUIDE")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app" }, [
      _c("img", { attrs: { src: require("@/assets/images/pc/appStore.png") } }),
      _c("img", {
        attrs: { src: require("@/assets/images/pc/googlePlay.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }