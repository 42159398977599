var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-wrapper" },
    [
      _vm.headerSpShow ? _c("heads") : _vm._e(),
      _c("router-view", { attrs: { name: "mobile" } }),
      _vm.footerSpShow ? _c("footers") : _vm._e(),
      _vm.simpleFooterSpShow ? _c("SimpleFooter") : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.suspension,
              expression: "suspension",
            },
          ],
          staticClass: "suspension",
          class: { close: !_vm.sideShow },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("img", {
                staticClass: "close-btn",
                attrs: {
                  src: require("@/assets/images/sp/close_side_btn.png"),
                },
                on: {
                  click: function ($event) {
                    _vm.sideShow = false
                  },
                },
              }),
              _c("router-link", { attrs: { to: "/question" } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/sp/question.png") },
                }),
              ]),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.toTopShow,
                    expression: "toTopShow",
                  },
                ],
                attrs: { src: require("@/assets/images/sp/to_top.png") },
                on: {
                  click: function ($event) {
                    return _vm.toTop()
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.sideShow,
                    expression: "!sideShow",
                  },
                ],
                staticClass: "open-btn",
                attrs: { src: require("@/assets/images/sp/open_side_btn.png") },
                on: {
                  click: function ($event) {
                    _vm.sideShow = true
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }