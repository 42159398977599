<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <span v-for="(item,index) in parentsData" :key="index">
        <router-link v-if="index!==parentsData.length-1" :to="item.url">
          <span>{{ item.name }}</span>
        </router-link>
        <img v-if="index!==parentsData.length-1" src="@/assets/images/pc/breadcrumb_arrow.png">
        <span v-if="index===parentsData.length-1">{{ item.name }}</span>
      </span>
    </div>
    <div class="product-detail">
      <div class="product-left">
        <div class="product-swiper-box" :class="{'active-not':imgUrl}">
          <div class="swiper product-swiper">
            <div class="swiper-wrapper">
              <div v-for="(item,index) in productData.galleries" :key="index" class="swiper-slide">
                <img :src="item">
                <img class="enlarge" src="@/assets/images/pc/enlarge.png" @click="imgVisibleOpen(index)">
              </div>
            </div>
            <img v-show="imgUrl" class="swiper-img-box" :src="imgUrl">
            <div class="swiper-pagination" />
          </div>
          <div class="swiper product-swiper-thumbs">
            <div class="swiper-wrapper">
              <div v-for="(item,index) in productData.galleries" :key="index" class="swiper-slide" @mouseover="swiperHover(index)">
                <img :src="item">
              </div>
            </div>
          </div>
          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
      </div>
      <div class="product-mid">
        <div class="title">{{ productData.title }}</div>
        <div class="sn">商品コード：{{ productData.product_sn }}</div>
        <div class="collection">
          <span>お気に入りアイテム登録者数：{{ productData.collects_count + productData.collect_count }}</span>
        </div>
        <div class="delivery">受注生産品 / 納期2ヶ月程度～</div>
        <div class="label">
          <div v-for="(item,index) in productData.tag" :key="index">{{ item.name }}</div>
        </div>
        <div class="attribute">
          <div v-for="(item,index) in productData.attribute" :key="index" class="attribute-box">
            <div class="attribute-title">{{ item.name }}<span>必須</span></div>
            <div class="attribute-item">
              <div
                v-for="(it,ind) in item.attribute_value"
                :key="ind"
                class="attribute-item-box"
                :class="{active:it.id === skuChecked[index].id}"
                :title="it.value"
                @click="changeSku(index,it)"
              >
                <div v-if="it.icon" class="img-box">
                  <img :src="it.icon" :alt="it.value">
                </div>
                <div v-else class="text-box">{{ it.value }}</div>
              </div>
            </div>
          </div>
          <div v-for="(item,index) in productData.norm" :key="'n' + index" class="attribute-box">
            <div class="attribute-title">{{ item.name }}<span v-if="item.is_required">必須</span></div>
            <div class="attribute-item">
              <div
                v-for="(it,ind) in item.norm_values"
                :key="ind"
                class="attribute-item-box"
                :class="{active:norm_values.includes(it.id)}"
                :title="it.name"
                @click="changeNorm(item,it)"
              >
                <div v-if="it.image" class="img-box">
                  <img :src="it.image" :alt="it.name">
                </div>
                <div v-else class="text-box">{{ it.name + '＋￥' + calcThousands(it.price) + '円' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="total-content">
          <div v-if="productData.promotion.length > 0 && productData.promotion[0].is_open" class="discount-detail">
            <div class="off">{{ productData.promotion[0].discount }}%OFF</div>
            <div class="price">￥{{ calcThousands(productData.price) }}</div>
          </div>
          <div class="content">
            <router-link to="/guides/delivery">配送料金について</router-link>
            <div class="price">
              ￥{{ productData.promotion.length > 0 && productData.promotion[0].is_open ? calcThousands(productData.discount_price) : calcThousands(productData.price) }}<span>(税込)</span>
            </div>
          </div>
        </div>
        <div v-if="productData.is_on_sale && sku_id" class="add-cart" @click="toCart">
          <img src="@/assets/images/pc/add-cart.png">
          <span>カートに入れる</span>
        </div>
        <div v-else class="add-cart" @click="confirmVisible = true">メールで入荷通知を受取る</div>
        <div class="add-collection" @click="collect">
          <img v-show="!productData.is_collected" src="@/assets/images/pc/collection.png">
          <img v-show="productData.is_collected" src="@/assets/images/pc/collection_choice.png">
          <span>お気に入りに追加する</span>
        </div>

      </div>
      <div class="product-right">
        <div v-if="productData.keywordTag && productData.keywordTag.length" class="label-keywords-content">
          <router-link v-for="(item,index) in productData.keywordTag" :key="index" :to="`/search?input=${item.name}&page=1`" class="item"># {{ item.name }}</router-link>
        </div>
      </div>
    </div>
    <div class="detail-content">
      <div class="left-content">
        <div class="product-reveal">
          <div :class="{active:reveal === 1}" @click="reveal = 1">製品詳細 <span>Item Detail</span></div>
          <div v-if="producCommentsData.length" :class="{active:reveal === 2}" @click="reveal = 2">レビュー <span>Review（{{ productData.average_star }}）</span></div>
        </div>
        <div v-if="reveal === 1" class="product-custom">
          <div v-html="productData.pc_desc" />
        </div>
        <div v-else-if="reveal === 2" class="evaluate">
          <div class="total">
            <div class="total-rate">
              <div>{{ productData.average_star }}</div>
              <div>
                <el-rate :value="productData.average_star || 5" allow-half disabled />
                <div class="num">({{ total }}件の商品レビュー)</div>
              </div>
            </div>
          </div>
          <div class="evaluate-box">
            <div v-for="(item,index) in producCommentsData" :key="index" class="evaluate-item">
              <div>{{ item.user_stats }}user</div>
              <div class="date">
                <el-rate :value="item.stars" allow-half disabled show-score />
                <span>{{ item.created_at }}</span>
              </div>
              <div
                v-if="item.images.length > 0"
                class="image-content"
              >
                <div
                  v-for="(value,key) in item.images"
                  :key="key"
                  class="item"
                  :class="{'current': imageIndex === key && currentIndex === index}"
                  @click="handleImagePreview(value,index,key)"
                >
                  <img :src="value">
                </div>
              </div>
              <div v-show="currentIndex === index" class="preview-image">
                <img :src="previewImageUrl" :class="imageIndex === 0 ? 'one': imageIndex === 1 ? 'two' : imageIndex === 2 ? 'three' : imageIndex === 3 ? 'four': ''">
              </div>
              <div class="desc">{{ item.content }}</div>
            </div>
            <pagination :hidden="total <= parameterInfo.limit" :total="total" :page.sync="parameterInfo.page" :limit.sync="parameterInfo.limit" @pagination="producComments" />
          </div>
        </div>
      </div>
      <div class="right-content">
        <div v-if="productData.product.length>0" class="product-list">
          <div class="product-list-title">こちらもおすすめ</div>
          <div v-for="(item,index) in productData.product" :key="index" class="product-item">
            <img :src="item.image">
            <div class="product-desc">
              <div class="product-name">{{ item.title }}</div>
              <div class="price">￥{{ calcThousands(item.price) }}<span>(税込)</span></div>
              <router-link :to="'/product/'+ item.product_sn">一緒に購入する</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mail :confirm-visible.sync="confirmVisible" />
    <div v-show="imgVisible" class="overlay img-overlay">
      <img src="@/assets/images/pc/close-btn-white.png" class="close-btn" @click="imgVisible = false">
      <img class="arrow-left" src="@/assets/images/pc/arrow-left.png" @click="imgVisibleLeft">
      <img class="img-box" :src="imgData">
      <img class="arrow-right" src="@/assets/images/pc/arrow-right.png" @click="imgVisibleRight">
    </div>
  </div>
</template>
<script>
import { productDetail, producComments, addCart, productCollect, getProductCrumb } from '@/api/product'
import pagination from '@/components/Pagination'
import mail from '@/views/pc/layout/mail'
import { calcThousands, parameterCorrection } from '@/utils/validate'
export default {
  components: { pagination, mail },
  data() {
    return {
      reveal: 1,
      product_id: null,
      confirmVisible: false,
      title: null,
      keywords: null,
      description: null,
      productData: {
        product: [],
        price: 0,
        collects_count: 0,
        collect_count: 0,
        is_collected: false,
        promotion: []
      },
      producCommentsData: [],
      parentsData: [],
      currentIndex: '',
      previewImageUrl: '',
      imageIndex: '',
      sku_id: '',
      sku_code: '',
      skuChecked: [],
      norm_values: [],
      attribute_value_ids: [],
      imgVisible: false,
      imgData: '',
      imgIndex: 0,
      total: 0, // 总数
      parameterInfo: {
        page: 1,
        limit: 10
      },
      imgUrl: '',
      swiperObj: null
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.keywords },
        { vmid: 'description', name: 'description', content: this.description }
      ]
    }
  },
  computed: {
    productSn: function() {
      return this.$route.params.productSn
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.sku_id = ''
        this.sku_code = ''
        this.skuChecked = []
        this.norm_values = []
        this.attribute_value_ids = []
        this.productDetail().then(() => {
          this.getProductCrumb()
        })
      }
    }
  },
  mounted() {
    this.productDetail().then(() => {
      this.getProductCrumb()
    })
  },
  methods: {
    swiperHover(index) {
      if (this.swiperObj) {
        this.swiperObj.slideTo(index)
        this.imgUrl = ''
      }
    },
    calcThousands,
    // 轮播
    swiper() {
      this.swiperObj = new window.Swiper('.product-swiper', {
        effect: 'fade',
        pagination: {
          type: 'fraction',
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        thumbs: {
          swiper: {
            el: '.product-swiper-thumbs', // 注意此处的设置方式
            touchRatio: 0,
            slidesPerView: 5,
            spaceBetween: 8,
            watchSlidesVisibility: true
          }
        }
      })
    },
    // 获取商品详情
    productDetail() {
      return productDetail(this.productSn, { include: 'tag,sku,attribute,attribute.attributeValue,norm,norm.normValues,product,promotion' }).then((result) => {
        this.product_id = result.id
        this.title = result.seo_title
        this.keywords = result.seo_keywords
        this.description = result.seo_description
        result.tag = result.tag.filter(item => {
          return item.is_detail
        })
        result.attribute.forEach(item => {
          this.skuChecked.push(item.attribute_value.length > 0 ? item.attribute_value[0] : '')
        })
        result.norm.forEach(item => {
          if (item.is_required && item.norm_values.length > 0) {
            this.norm_values.push(item.norm_values[0].id)
          }
        })
        this.productData = result
        const arr = []
        this.productData.tag.forEach(item => {
          if (item.is_keyword) {
            arr.push(item)
          }
        })
        this.$set(this.productData, 'keywordTag', arr)
        this.totalPrice()
        this.parameterInfo.page = 1
        this.producComments()
        this.$nextTick(() => {
          this.swiper()
        })
      })
    },
    getProductCrumb() {
      getProductCrumb(this.productSn).then(result => {
        this.parentsData = []
        result.data.forEach(item => {
          this.parentsData.push({ name: item.name, url: '/category/' + item.url })
        })
        this.parentsData.push({ name: this.productData.title, url: '' })
      })
    },
    // 获取商品评论
    producComments() {
      const data = parameterCorrection({ is_show: true })
      producComments(this.product_id, { ...this.parameterInfo, ...data }).then((result) => {
        this.producCommentsData = result.data
        this.producCommentsData.forEach(item => {
          item.images = item.images === null ? [] : item.images
        })
        this.total = result.meta.total
      })
    },
    handleImagePreview(url, index, key) {
      if (this.imageIndex === key) {
        this.currentIndex = ''
        this.imageIndex = ''
      } else {
        this.previewImageUrl = url
        this.currentIndex = index
        this.imageIndex = key
      }
    },
    // 改变 SKU
    changeSku(index, val) {
      this.skuChecked.splice(index, 1, val)
      this.totalPrice()
      this.imgShow(val.image)
    },
    // 改变 服务
    changeNorm(norm, val) {
      if (norm.is_required && norm.choose_type === 1) { // 必选单选
        norm.norm_values.forEach(item => {
          if (this.norm_values.indexOf(item.id) !== -1) this.norm_values.splice(this.norm_values.indexOf(item.id), 1)
        })
        this.norm_values.push(val.id)
      } else if (norm.is_required && norm.choose_type === 2) { // 必选多选
        if (this.norm_values.indexOf(val.id) !== -1) {
          const arr = norm.norm_values.map(item => this.norm_values.indexOf(item.id) !== -1)
          if (arr.length > 1) { this.norm_values.splice(this.norm_values.indexOf(val.id), 1) }
        } else {
          this.norm_values.push(val.id)
        }
      } else if (!norm.is_required && norm.choose_type === 1) { // 非必选单选
        if (this.norm_values.indexOf(val.id) !== -1) {
          this.norm_values.splice(this.norm_values.indexOf(val.id), 1)
        } else {
          norm.norm_values.forEach(item => {
            if (this.norm_values.indexOf(item.id) !== -1) this.norm_values.splice(this.norm_values.indexOf(item.id), 1)
          })
          this.norm_values.push(val.id)
        }
      } else if (!norm.is_required && norm.choose_type === 2) { // 非必选多选
        if (this.norm_values.indexOf(val.id) !== -1) {
          this.norm_values.splice(this.norm_values.indexOf(val.id), 1)
        } else {
          this.norm_values.push(val.id)
        }
      }
      this.totalPrice()
    },
    // 计算价格
    totalPrice() {
      let price = 0
      let discountPrice = 0
      const value = this.skuChecked.map(item => item.value).join(',')
      this.productData.sku.forEach(item => {
        if (value === item.sku_value) {
          this.sku_code = item.sku_code
          this.sku_id = ''
          this.attribute_value_ids = ''
          if (item.stock > 0) {
            this.sku_id = item.id
            this.attribute_value_ids = this.skuChecked.map(item => item.id)
          }
          price += parseInt(item.price)
          discountPrice += parseInt(item.price)
        }
      })
      if (this.productData.promotion.length > 0) {
        discountPrice = Math.ceil(price * (100 - this.productData.promotion[0].discount) / 100)
        if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
          discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
        }
      }
      this.productData.norm.forEach(norm => {
        norm.norm_values.forEach(value => {
          if (this.norm_values.indexOf(value.id) !== -1) {
            price += parseInt(value.price)
            discountPrice += parseInt(value.price)
          }
        })
      })
      this.productData.price = price
      this.$set(this.productData, 'discount_price', discountPrice)
    },
    // 商品收藏
    collect() {
      productCollect(this.productSn).then(() => {
        this.productData.is_collected = !this.productData.is_collected
        productDetail(this.productSn).then((result) => {
          this.productData.collects_count = result.collects_count
        })
      })
    },
    // 加入购物车
    toCart() {
      const data = {
        sku_code: this.sku_code,
        number: 1,
        norm_values: this.norm_values,
        attribute_value_ids: this.attribute_value_ids
      }
      addCart(data).then(() => {
        this.$Toast('カートに入りました。')
        this.$store.dispatch('user/getCartNumber')
      })
    },
    // 图片放大
    imgVisibleOpen(index) {
      this.imgData = this.productData.galleries[index]
      this.imgVisible = true
    },
    imgVisibleLeft() {
      if (this.imgIndex <= 0) {
        this.imgIndex = this.productData.galleries.length - 1
        this.imgData = this.productData.galleries[this.imgIndex]
      } else {
        this.imgIndex -= 1
        this.imgData = this.productData.galleries[this.imgIndex]
      }
    },
    imgVisibleRight() {
      if (this.imgIndex >= this.productData.galleries.length - 1) {
        this.imgIndex = 0
        this.imgData = this.productData.galleries[this.imgIndex]
      } else {
        this.imgIndex += 1
        this.imgData = this.productData.galleries[this.imgIndex]
      }
    },
    // 展示图
    imgShow(url) {
      this.imgUrl = url
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.product-detail{
  display: flex;
  justify-content: space-between;
  width: 1440px;
  font-size: 14px;
  color: #333;
  margin: 13px auto 0;
}
.product-left{
  width: 544px;
}
.product-swiper-box{
  position: relative;
}
.product-swiper-thumbs{
  width: 432px;
  height: 80px;
  margin: 8px auto 0;
  .swiper-slide{
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .swiper-slide-thumb-active{
    border: 2px solid #333;
  }
}
.active-not .swiper-slide-thumb-active{
    border: none;
}
.product-swiper{
  width: 544px;
  margin: 0;
  border: 1px solid #e8ebed;
  .swiper-slide{
    width: 544px;
    height: 544px;
    box-sizing: border-box;
    border: 1px solid #E8EBED;
    img{
      width: 544px;
      height: 544px;
    }
    .enlarge{
      position: absolute;
      bottom: 8px;
      right: 8px;
      width: 48px;
      height: 48px;
      opacity: 0.8;
      border-radius: 50%;
      z-index: 100;
      cursor: pointer;
    }
  }
  .swiper-pagination{
    left: 1px;
    bottom: 1px;
    display: inline-block;
    width: 60px;
    height: 30px;
    color: #333;
    line-height: 30px;
    text-align: center;
    background: rgba($color: #fff, $alpha: 0.8);
  }
  .swiper-img-box{
    position: absolute;
    top: 1px;
    left: 1px;
    width: 544px;
    height: 544px;
    background: #fff;
    z-index: 100;
  }
}
.swiper-button-prev{
  position: absolute;
  bottom: 12px;
  top: auto;
  left: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #E8EBED;
  background: url('~@/assets/images/pc/arrow-left.png') center center;
  margin: 0;
  &::after{
    content: '';
  }
}
.swiper-button-next{
  position: absolute;
  bottom: 12px;
  top: auto;
  right: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #E8EBED;
  background: url('~@/assets/images/pc/arrow-right.png') center center;
  margin: 0;
  &::after{
    content: '';
  }
}
.product-mid{
  width: 512px;
  margin: 25px 0;
  .title{
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 16px;
  }
  .sn{
    font-size: 14px;
  }
  .collection{
    display: flex;
    align-items: center;
    margin: 8px 0;
    img{
      margin: 0 6px 2px;
    }
  }
  .delivery{
    color: #c40a0c;
  }
  .label{
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    div{
      height: 22px;
      line-height: 24px;
      font-size: 14px;
      padding: 0 8px;
      border: 1px solid #333;
      margin: 8px 8px 0 0;
    }
  }
  .attribute{
    margin-top: 16px;
    border-top: 2px solid #e8ebed;
    .attribute-box{
      margin: 24px 0;
    }
    .attribute-title{
      font-size: 18px;
      span{
        color: #C40A0A;
        font-size: 14px;
        margin-left: 8px;
      }
    }
    .attribute-item{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .attribute-item-box{
      margin: 8px 8px 0 0;
      cursor: pointer;
    }
    .text-box{
      height: 32px;
      line-height: 30px;
      padding: 0 12px;
      box-sizing: border-box;
      border: 1px solid #E8EBED;
    }
    .img-box{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-sizing: border-box;
      img{
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .active{
      .text-box{
        line-height: 28px;
        padding: 0 11px;
        border: 2px solid #333;
      }
      .img-box{
        border: 2px solid #333;
        img{
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }
      }
    }
  }
  .total-content{
    margin: 130px 0 16px;
    .discount-detail{
      margin-bottom: 8px;
      text-align: right;
      font-size: 14px;
      div{
        display: inline-block;
      }
      .off{
        width: 72px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #c40a0a;
        color: #ffffff;
      }
      .price{
        margin-left: 12px;
        color: #999999;
        vertical-align: bottom;
        text-decoration: line-through;
      }
    }
    .content{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      font-size: 36px;
      font-weight: bold;
      color: #c40a0c;
    }
    a{
      font-size: 14px;
      color: #666;
      text-decoration: underline;
    }
    span{
      font-size: 14px;
    }
  }
  .add-cart{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background: #333;
    margin-top: 16px;
    cursor: pointer;
    img{
      vertical-align: middle;
      margin: 0 8px 5px 0;
    }
  }
  .add-collection{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 1px solid #333;
    margin-top: 16px;
    font-size: 16px;
    cursor: pointer;
    img{
      vertical-align: middle;
      margin: 0 8px 2px 0;
    }
  }
}
.product-right{
  width: 320px;
  .label-keywords-content{
    display: flex;
    flex-wrap: wrap;
    padding: 24px 24px 16px 24px;
    box-sizing: border-box;
    background: #f4f5fc;
    .item{
      padding: 6px 14px;
      height: 32px;
      margin: 0 8px 8px 0;
      border-radius: 18px;
      border: 1px solid #999fa5;
      box-sizing: border-box;
      &:hover{
        border: 2px solid #333333;
      }
    }
  }
}

.img-overlay{
  display: flex;
  align-items:center;
  justify-content: center;
  .arrow-left{
    position: absolute;
    margin-right: calc((100vh - 24px));
    cursor: pointer;
  }
  .arrow-right{
    position: absolute;
    margin-left: calc((100vh - 24px));
    cursor: pointer;
  }
  .close-btn{
    position: absolute;
    top: 64px;
    right: 64px;
    cursor: pointer;
  }
  .img-box{
    width: calc(100vh - 128px );
    height: calc(100vh - 128px );
  }
}
.detail-content{
  display: flex;
  justify-content: space-between;
  width: 1440px;
  margin: 56px auto 80px;
  .left-content{
    width: 1080px;
    .product-reveal{
    display: flex;
    border-bottom: 1px solid #333;
    font-size: 16px;
    div{
      width: 212px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: #F4F5FC;
      margin-right: 16px;
      cursor: pointer;
    }
    span{
      font-size: 14px;
    }
    .active{
      color: #fff;
      font-weight: bold;
      background: #333;
    }
  }
  .product-custom ::v-deep{
    padding: 35px 165px 0;
    border-left: 1px solid #333333;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    img{
      max-width: 100%;
    }
  }
  .evaluate{
    .total ::v-deep{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 84px;
      border-bottom: 1px solid #333;
      .total-rate{
        display: flex;
        align-items: center;
        >:nth-child(1){
          font-size: 48px;
          font-weight: bold;
          color: #FCCF52;
          margin-right: 12px;
        }
        .el-rate__icon{
          font-size: 26px;
        }
        .num{
          font-size: 14px;
          color: #999;
          margin: 5px 0 10px;
        }
      }
    }
  }
  .evaluate-box ::v-deep{
    .el-rate__text{
      color: #FCCF52 !important;
      vertical-align: bottom;
    }
    .image-content{
      margin-top: 16px;
      display: flex;
      .item{
        margin-right: 8px;
        width: 160px;
        height: 160px;
        img{
          width: 100%;
          height: 100%;
          cursor: zoom-in;
        }
      }
      .current{
        position: relative;
        box-sizing: border-box;
        border: 2px solid #333333;
        &::after{
          content: '';
          position: absolute;
          bottom: -6px;
          left: 50%;
          width: 0;
          height: 0;
          transform: translate(-50%, 0);
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #333333;
        }
        img{
          cursor: zoom-out;
        }
      }
    }
    .preview-image{
      margin-top: 4px;
      img{
        width: 312px;
        height: 312px;
      }
      .two{
        margin-left: 100px;
      }
      .three{
        margin-left: 262px;
      }
      .four{
        margin-left: 430px;
      }
    }
    .evaluate-item{
      padding: 16px 0;
      border-bottom: 1px solid #E8EBED;
    }
    .date{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #999;
    }
    .desc{
      text-align: justify;
      line-height: 1.5;
      margin-top: 16px;
    }
  }
  }
  .right-content{
    width: 320px;
      .product-list{
      padding: 24px;
      border: 2px solid #e8ebed;
      .product-list-title{
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 16px;
        border-bottom: 1px solid #333;
      }
      .product-item{
        margin-top: 24px;
        &:not(:last-child){
          padding-bottom: 24px;
          border-bottom: 1px solid #333333;
        }
        img{
          width: 272px;
          height: 272px;
        }
      }
      .product-desc{
        margin-top: 12px;
        a{
          display: block;
          width: 272px;
          height: 40px;
          margin-top: 16px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          background: #333;
        }
      }
      .product-name{
        flex-shrink: 0;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        font-size: 16px;
      }
      .price{
        margin-top: 12px;
        font-size: 20px;
        font-weight: bold;
        span{
          font-size: 12px;
        }
      }
    }
  }
}
</style>
