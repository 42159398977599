var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "guides-content" }, [
    _c(
      "div",
      { staticClass: "bread-crumbs" },
      [
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
        _vm._v(" / "),
        _c("router-link", { attrs: { to: "/guides/category" } }, [
          _vm._v("ご利用ガイド"),
        ]),
        _vm._v(" / 無垢材について "),
      ],
      1
    ),
    _vm._m(0),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-content" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/sp/about_banner.png"), alt: "" },
      }),
      _c("div", { staticClass: "desc-text" }, [
        _c("div", { staticClass: "title" }, [_vm._v("無垢材100％")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "激安家具量販店と違い、ウッドライフ(woodlife)の家具は、化粧合板やパーチクルボードなどの集成材は一切使用しません。"
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "材料は主として北アメリカのアパラチア山脈で合法的に伐採された一本の原木から直接必要な寸法に切り出したものを使って製作しています。"
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "厳しい自然環境の中であ外年月をかけて成長した木材を、更に時間をかけて乾燥させ、製材し、多くの人の手を掛けながら、ようやく家具作りに使えるようになりました。"
          ),
        ]),
      ]),
      _c("div", { staticClass: "desc-text" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("家族が安心して使える家具"),
          _c("br"),
          _vm._v("づくりに専念"),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "当社の製品はすべて天然の植物油を主成分とし、木材の表面に浸透して保護する健康塗料です。"
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "更に、熱や水に強く、傷や汚れが付きにくくため、 非常に薄い樹脂の塗膜仕上げです。 (EU環境保護基準無臭塗料)"
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "家具はすべて取り寄せを行い、加工や仕上げ、 梱包までの工程を一貫して自社で行っています。"
          ),
        ]),
        _c("div", { staticClass: "text" }, [
          _vm._v("お客様一人ひとりの好みに応じた製品を、"),
          _c("br"),
          _vm._v("一つひとつ手間を惜しまず、丁寧に作り上げています。"),
        ]),
      ]),
      _c("img", {
        staticClass: "img2",
        attrs: { src: require("@/assets/images/sp/about_img2.png"), alt: "" },
      }),
      _c("div", { staticClass: "wood-material" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Wood Material")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v("※北欧原産FASグレード木材を多用、"),
          _c("br"),
          _vm._v("節の少ない広く長いクリアー材が取れることから高級家具、"),
          _c("br"),
          _vm._v("造作などに適しています。"),
          _c("br"),
          _vm._v(
            "全米広葉樹製材協会（NHLA） が定める「FAS規格（First And Seconds）」認証済材。"
          ),
        ]),
        _c("div", { staticClass: "wood-lists" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "icon" }, [_vm._v("OAK")]),
            _c("div", { staticClass: "right-desc" }, [
              _c("div", { staticClass: "name" }, [_vm._v("オーク")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "くっきりとした美しい木目を持ちながら、ナチュラルな風合いを感じさせる木材。"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "icon" }, [_vm._v("WALNUT")]),
            _c("div", { staticClass: "right-desc" }, [
              _c("div", { staticClass: "name" }, [_vm._v("ウォールナット")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "落ち着いた色調と滑らかな質感を持ち、すぐれた性質を兼ね備えている高級材。"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "icon" }, [_vm._v("CHERRY")]),
            _c("div", { staticClass: "right-desc" }, [
              _c("div", { staticClass: "name" }, [_vm._v("チェリー")]),
              _c("div", { staticClass: "text" }, [
                _vm._v("赤みの強い色が特徴で、美しい経年変化が楽しめる木材。"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "icon" }, [_vm._v("BEECH")]),
            _c("div", { staticClass: "right-desc" }, [
              _c("div", { staticClass: "name" }, [_vm._v("ブナ")]),
              _c("div", { staticClass: "text" }, [
                _vm._v("通直な木理と密な肌目を持ち、頑丈で変形しにくい。"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "icon" }, [_vm._v("ASH")]),
            _c("div", { staticClass: "right-desc" }, [
              _c("div", { staticClass: "name" }, [_vm._v("アッシュ")]),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "適度に堅く、耐久力に富む。経年変化が少なく使い続けても雰囲気が変わりにくい。"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "production-process" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Production Process")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v("天然無垢材という自然素材を製品にするために、"),
          _c("br"),
          _vm._v("木の表情を読み解き、木の状態を知り、"),
          _c("br"),
          _vm._v("木を切り、削り、磨きながら1つの製品を作り上げます。"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/sp/about_production_process.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "about-product" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Products")]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/sp/about_product.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href: "https://www.instagram.com/woodlife.jp/",
          target: "_blank",
        },
      },
      [
        _c("div", { staticClass: "button" }, [
          _vm._v("公式Instagram投稿をもっと見る"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }