var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tips" },
      [
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
        _vm._v(" / 新着商品"),
      ],
      1
    ),
    _c("div", {
      staticClass: "custom",
      domProps: { innerHTML: _vm._s(_vm.customData.sp_content) },
    }),
    _vm.initData.length > 0
      ? _c(
          "div",
          [
            _c("div", { staticClass: "view-title" }, [_vm._v("ITEMS")]),
            _c("div", { staticClass: "view-desc" }, [
              _vm._v(
                _vm._s((_vm.parameterInfo.page - 1) * _vm.parameterInfo.limit) +
                  " ～ " +
                  _vm._s(_vm.parameterInfo.page * _vm.parameterInfo.limit) +
                  " 件目を表示しています。(全" +
                  _vm._s(_vm.total) +
                  "件)"
              ),
            ]),
            _c(
              "div",
              { staticClass: "goods-list" },
              _vm._l(_vm.initData, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "goods-item" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/product/" + item.product_sn } },
                      [
                        _c("div", { staticClass: "img" }, [
                          _c("img", {
                            attrs: {
                              src:
                                item.colorTagIndex === ""
                                  ? item.image
                                  : item.color_tags[item.colorTagIndex].pivot
                                      .image,
                            },
                          }),
                          item.promotion.length > 0 && item.promotion[0].is_open
                            ? _c("div", { staticClass: "discount" }, [
                                _vm._v(
                                  _vm._s(item.promotion[0].discount) + "%OFF"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "label" },
                      _vm._l(item.color_tags, function (it, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: { active: item.colorTagIndex === index },
                            style: { background: it.color_value },
                            on: {
                              click: function ($event) {
                                item.colorTagIndex === index
                                  ? (item.colorTagIndex = "")
                                  : (item.colorTagIndex = index)
                              },
                            },
                          },
                          [_c("span")]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "title-box" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "price" },
                        [
                          item.promotion.length > 0 && item.promotion[0].is_open
                            ? [
                                _c("span", { staticClass: "now" }, [
                                  _vm._v(
                                    "¥" +
                                      _vm._s(
                                        _vm.calcThousands(item.discount_price)
                                      )
                                  ),
                                ]),
                                _c("span", { staticClass: "original" }, [
                                  _vm._v(
                                    "¥" + _vm._s(_vm.calcThousands(item.price))
                                  ),
                                ]),
                              ]
                            : [
                                _vm._v(
                                  " ¥" +
                                    _vm._s(_vm.calcThousands(item.price)) +
                                    " "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
            _c("pagination-sp", {
              attrs: {
                hidden: _vm.total <= _vm.parameterInfo.limit,
                total: _vm.total,
                page: _vm.parameterInfo.page,
                limit: _vm.parameterInfo.limit,
                link: _vm.$route.fullPath,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.parameterInfo, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.parameterInfo, "limit", $event)
                },
                pagination: _vm.fetchData,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }