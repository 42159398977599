<template>
  <div class="register-content">
    <div class="content">
      <img src="@/assets/images/sp/vip.png" class="vip">
      <div class="title">会員登録完了。</div>
      <div class="text">
        この度、ご登録ありがとうございます。<br>
        それではショッピングをお楽しみください！
      </div>
      <div class="button-content">
        <router-link to="/personal/mine">
          <div class="mine-button">マイページ</div>
        </router-link>
        <router-link to="/">
          <div class="home-button">トップページ</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { registerAuth } from '@/api/user'
export default {
  data() {
    return {
      key: this.$route.query.activity_token
    }
  },
  created() {
    registerAuth({ activity_token: this.key }).then(result => {
      const token = result.token_type + ' ' + result.access_token
      this.$store.dispatch('user/updateToken', token)
    })
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.register-content{
    margin-top: 1.92rem;
    padding: 0 .32rem;
    flex: 1;
    text-align: center;
    .vip{
        width: .94rem;
        height: .54rem;
    }
    .title{
        margin-top: .48rem;
        font-size: .46rem;
        color: #4bc4b0;
        font-weight: 700;
    }
    .text{
        margin-top: .28rem;
        line-height: .42rem;
    }
    .button-content{
        display: flex;
        justify-content: space-between;
        margin-top: .8rem;
        div{
            width: 3.3rem;
            height: .8rem;
            line-height: .8rem;
            text-align: center;
            box-sizing: border-box;
        }
        .mine-button{
            border: .02rem solid #333333;
        }
        .home-button{
            background: #333333;
            color: #ffffff;
        }
    }
}
</style>
