<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/guides/category">ご利用ガイド</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>アフターサービス</span>
    </div>
    <div class="bg-content">
      <div class="bg-text">
        <div class="en">After Service</div>
        <div class="ja">アフターサービス</div>
      </div>
      <img src="@/assets/images/pc/365.png" class="everyday">
    </div>
    <div class="list-content">
      <div class="list">
        <div class="title">アフターサービス「安心の1年保証」</div>
        <div class="text-content">
          大型家具については商品のお届け日より1年内に製造上の瑕疵による故障や不具合が生じた場合は、交換または返金させていただきます。<br>
          ただし、運送費などをご負担いただく場合があります。<br>
          商品の状態によっては、部材・部品をご送付してお客様でご対応いただく場合がありますので、予めご了承ください。<br><br>
          <p class="underline">
            ※初期不良の交換は、商品到着後7日以内とさせていただきます。<br>
            ※こちらは商品不具合による使用上で生じた事故・損害に対して、当店はその責任（金銭的補償等）を負うことはできません。
          </p>
        </div>
        <div class="red-bg">※保証期間は商品により異なります。商品ページ内の記載をご確認ください。</div>
      </div>
      <div class="list">
        <div class="title">保証書について</div>
        <div class="text-content">
          保証書を同封いたしておりません。<br>ご連絡の際はご注文後にお送りしたメールに記載されたご注文情報が必要となりますので、保証書代わりとして必ず保管をお願いいたします。
        </div>
      </div>
      <div class="list">
        <div class="title">保証内容について<span>※保証は日本国内においてのみ有効です。</span></div>
        <div class="text-content">
          ご家庭で使用された家具に限り、本体貼り付けラベル等の注意書きに従った正常な使用状態で故障した場合は、修理または部品交換をいたします。<br>
          但し、以下の場合は保証期間内であっても有償での修理・交換となりますので予めご了承ください。
        </div>
        <table class="table">
          <tr>
            <td rowspan="10" width="144">保証対象外</td>
            <td>・取扱説明書や注意表示での注意事項を守られなかった場合。</td>
          </tr>
          <tr>
            <td>・不当な修理や改造による故障や損傷。</td>
          </tr>
          <tr>
            <td>・納品後の移動、輸送により生じた破損・故障。</td>
          </tr>
          <tr>
            <td>・一般家庭用以外（業務用）に使用した場合の故障または損傷。</td>
          </tr>
          <tr>
            <td>・外観のキズ、張地、塗装部分などの外観劣化、木の変質によるもの・自然な変色などの場合。</td>
          </tr>
          <tr>
            <td>・天然木の無垢材による一般的な経年変化や反りや割れなどの場合。</td>
          </tr>
          <tr>
            <td>・直射日光、冷暖房機器ほか電気製品の熱などによる変形・変色。</td>
          </tr>
          <tr>
            <td>・機能や性能にかかわらない場合。</td>
          </tr>
          <tr>
            <td>・摩耗、傷、汚れ、色落ち、ソファのクッション部分のヘタレなどの使用による経年劣化。</td>
          </tr>
          <tr>
            <td>・当店以外から商品を購入された場合。</td>
          </tr>
        </table>
      </div>
      <div class="list">
        <div class="title">免責・注意事項</div>
        <div class="text-content">
          商品に瑕疵がなく、通常のご使用中に発生した床・壁等周辺物の直接又は間接の傷及び汚損等につきましては、一切の責任を負うことはできません。<br><br>
          商品組立業者等をお客様自身で手配される場合において、商品不良等の理由で作業ができなかった場合や再組み立て・再設置等が必要になる場合であっても、その費用を当社にて負担することはできません。<br><br>
          ご購入品以外の商品への交換はできません。交換品・部品のお届けまでに初回配送時と同様にお届けまでにお時間を頂戴する場合がございます。<br><br>
          対象商品の在庫が無い場合は入荷後の交換、もしくはご返品（返金）を承ります。<br><br>
          修理については部品などの都合で、外観が変わる場合があります。<br><br>
          保証期間を過ぎた商品については、有償で修理いたしますが、メーカー都合(生産終了)によっては対応できない場合があります。<br><br>
          消耗部分の交換や自然な損耗、経年変化に関するメンテナンス・修理は有償となります。お見積りはお問い合わせください。
        </div>
        <div class="red-bg">※こちらは商品不具合による使用上で生じた事故・損害に対して、当店はその責任（金銭的補償等）を負うことはできません。</div>
      </div>
      <div class="list">
        <div class="title">無垢材家具の割れを予防するコツ</div>
        <div class="text-content">
          冬の乾燥する時期に、木材は外気に水分を失った木材はどんどんと収縮してしまい、割れが発生してしまいます。<br><br>
          エアコンの風向きを調整し、家具に風が直接当たらないようにしましょう。家具の近くで、加湿器をつけると良いでしょう。<br><br>
          特に冬場は、空気が乾燥するので、暖房器具から離れて使用してください。
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">
        <span>他のご質問はこちら</span>
        <img src="@/assets/images/pc/button_arrow.png">
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.guides-content{
    width: 1440px;
    margin: 0 auto 80px;
    font-size: 16px;
    .breadcrumb{
        margin-top: 28px;
    }
    .red{
      color: #c40a0a;
      font-weight: bold;
    }
    .underline{
      text-decoration: underline;
    }
  .bg-content{
    position: relative;
    width: 1050px;
    padding-bottom: 32px;
    border-bottom: 1px solid #e8ebed;
    .everyday{
        position: absolute;
        right: -380px;
        bottom: -76px;
      }
    .bg-text{
        position: relative;
        width: 266px;
        height: 44px;
        margin-top: 60px;
        background-color: #f4f5fc;
        .en{
          position: absolute;
          top: -18px;
          left: 16px;
          font-size: 32px;
          font-weight: bold;
        }
        .ja{
          position: absolute;
          left: 16px;
          bottom: 6px;
          font-size: 14px;
        }
    }
  }
    .list-content{
      position: relative;
        .list{
            padding: 48px 32px 45px;
            border-bottom: 1px solid #e8ebed;
            .title{
                font-size: 24px;
                font-weight: 700;
                span{
                  margin-left: 16px;
                  font-size: 14px;
                  font-weight: normal;
                  color: #999999;
                }
            }
            .text-content{
                margin-top: 24px;
                line-height: 24px;
            }
            .red-bg{
                display: inline-block;
                height: 48px;
                margin: 20px 0;
                padding: 0 24px;
                line-height: 48px;
                font-weight: 700;
                color: #c40a0a;
                background: #fcf0f0;
                text-align: center;
            }
            .table{
              width: 808px;
              margin-top: 20px;
              border: 1px solid #333333;
              font-size: 14px;
              tr{
                &:nth-child(1){
                  td:nth-child(1){
                    padding-left: 0;
                    text-align: center;
                    background-color: #f4f5fc;
                    font-size: 16px;
                    font-weight: bold;
                  }
                }
                td{
                  padding: 12px 0 12px 20px;
                }
              }
            }
        }
    }
    .button{
      width: 248px;
      height: 48px;
      margin: 52px auto 0;
      line-height: 48px;
      text-align: center;
      background-color: #333;
      color: #fff;
      cursor: pointer;
      img{
          margin-left: 4px;
      }
    }
}
</style>
