<template>
  <div class="personal-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/personal/mine">マイページ</router-link> / myクーポン
    </div>
    <template v-if="canUseData.length > 0">
      <div class="title">
        <span>myクーポン</span>
        <span>{{ canUseData.length }} 枚</span>
      </div>
      <div class="coupon-content">
        <div v-for="(item,index) in canUseData" :key="index" class="item">
          <img :src="item.coupon.sp_image">
          <div class="name">{{ item.coupon.name }}</div>
          <div class="limit">{{ item.coupon.remark }}</div>
          <div class="time"><span>期間中</span>～{{ item.end_time.replace(/-/g, "/") }}</div>
          <div class="day">有効期間終了まで、あと {{ item.rest_day }}日！</div>
          <div class="button-content">
            <a :href="item.coupon.link ? `${item.coupon.link}` : '/'">
              <div class="btn">クーポンを使う</div>
            </a>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="empty-content">
      <img src="@/assets/images/sp/empty_coupon.png">
      <div class="text">該当内容はございません。</div>
      <router-link to="/">
        <div class="button">トップページに戻る</div>
      </router-link>
    </div>
    <div v-if="usedData.length > 0" class="over-content">
      <div class="head" @click="handleUsedCopuponShow">
        <div>使用不可のクーポン</div>
        <div class="arrow">
          <img v-show="!usedCouponShow" src="@/assets/images/sp/down_arrow.png">
          <img v-show="usedCouponShow" src="@/assets/images/sp/up_arrow.png">
        </div>
      </div>
      <div v-show="usedCouponShow" class="coupon-content">
        <div v-for="(item,index) in usedData" :key="index" class="item">
          <div class="detail">
            <img :src="item.coupon.sp_image">
            <div class="name">{{ item.coupon.name }}</div>
            <div class="limit">{{ item.coupon.remark }}</div>
            <div class="time"><span>期間中</span>～{{ item.end_time.replace(/-/g, "/") }}</div>
          </div>
          <img v-if="item.is_used" src="@/assets/images/sp/coupon_used.png" class="icon">
          <img v-else src="@/assets/images/sp/coupon_over.png" class="icon">
        </div>
      </div>
    </div>
    <div class="get-coupon-content">
      <div class="title">クーポンコード登録</div>
      <div class="text">お手持ちの「クーポンコード」を入力してください </div>
      <div class="input-content">
        <el-input v-model.trim="couponCode" placeholder="クーポンコード" />
        <el-button type="primary" :loading="btnLoading" @click="handleGetCoupon">登録する</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyCouponList, getCodeCoupon } from '@/api/coupon'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      btnLoading: false,
      usedCouponShow: true,
      canUseData: [],
      usedData: [],
      couponCode: '',
      calcThousands
    }
  },
  computed: {
  },
  created() {
    this.getCanUseCouponList()
    this.getUsedCouponList()
  },

  methods: {
    getCanUseCouponList() {
      getMyCouponList({ 'filter[is_valid]': true, include: 'coupon' }).then(result => {
        this.canUseData = result.data
        this.canUseData.forEach(item => {
          this.$set(item, 'rest_day', this.calculateDaysUntilDate(item.end_time))
        })
      })
    },
    getUsedCouponList() {
      getMyCouponList({ 'filter[is_valid]': false, include: 'coupon' }).then(result => {
        this.usedData = result.data
      })
    },
    handleUsedCopuponShow() {
      this.usedCouponShow = !this.usedCouponShow
    },
    handleGetCoupon() {
      if (this.couponCode) {
        this.btnLoading = true
        getCodeCoupon({ code: this.couponCode }).then(() => {
          this.btnLoading = false
          this.$toast('クーポンをGETしました！')
          this.getCanUseCouponList()
          this.getUsedCouponList()
        }).catch(() => {
          this.btnLoading = false
        })
      } else {
        this.$toast('クーポンコードを入力ください')
      }
    },
    calculateDaysUntilDate(targetDateStr) {
      const today = new Date()
      const targetDate = new Date(targetDateStr)
      const timeDiff = targetDate - today
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + 1
      return daysDiff
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content{
  padding: 0 .24rem;
  flex: 1;
  .bread-crumbs{
      margin-top: .16rem;
      text-align: center;
      font-size: .24rem;
  }
  .title{
      display: flex;
      justify-content: space-between;
      margin-top: .56rem;
      padding-bottom: .24rem;
      border-bottom: .02rem solid #333333;
      font-size: .36rem;
      font-weight: 700;
  }
  .coupon-content{
    font-size: .28rem;
    .item{
      padding: .24rem;
      margin-top: .4rem;
      border: .02rem solid #e8ebed;
      .name{
        margin-top: .24rem;
        font-size: .36rem;
        font-weight: 700;
      }
      .limit{
        margin-top: .36rem;
      }
      .time{
        margin-top: .16rem;
        span{
          color: #C40A0A;
        }
      }
      .day{
        margin-top: .16rem;
      }
      .button-content{
        padding-top: .24rem;
        margin-top: .32rem;
        border-top: .02rem solid #e8ebed;
        .btn{
          width: 2.56rem;
          height: .62rem;
          margin: 0 auto;
          line-height: .62rem;
          text-align: center;
          background-color: #333333;
          color: #ffffff;
        }
      }
    }
  }
  .over-content{
    margin-top: .4rem;
    border: .02rem solid #e8ebed;
    .head{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: .84rem;
      padding: 0 .22rem;
      line-height: .84rem;
      box-sizing: border-box;
      background-color: #e8ebed;
      .arrow{
        display: flex;
        align-items: center;
      }
      img{
        width: .24rem;
        height: .24rem;
      }
    }
    .item{
      position: relative;
      margin-top: 0;
      border: 0;
      &:not(:last-child){
        border-bottom: .02rem solid #e8ebed;
      }
      .detail{
        opacity: .3;
      }
      .icon{
        position: absolute;
        right: .12rem;
        bottom: 0;
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .empty-content{
      margin-top: 1.46rem;
      text-align: center;
      img{
          width: 1.32rem;
          height: 1.32rem;
      }
      .text{
          margin-top: .32rem;
      }
      .button{
          width: 4.08rem;
          height: .8rem;
          margin: .8rem auto 0;
          line-height: .8rem;
          text-align: center;
          color: #ffffff;
          background-color: #333333;
      }
  }
  .get-coupon-content{
    margin-top: .8rem;
    .text{
      margin-top: .32rem;
      font-size: .28rem;
      font-weight: 700;
    }
    .input-content{
      display: flex;
      justify-content: space-between;
      margin-top: .32rem;
      .el-input{
        width: 5.1rem;
        height: .88rem;
      }
      .el-button{
        width: 1.76rem;
        height: .88rem;
      }
    }
  }
}
</style>
