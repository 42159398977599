<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / アフターサービス
    </div>
    <div class="title-content">
      配送·送料について
      <img src="@/assets/images/pc/guides_after_service.png">
    </div>
    <div class="lists-content">
      <div class="list">
        <div class="title">アフターサービス「安心の1年保証」</div>
        <div class="desc">
          大型家具については商品のお届け日より1年内に製造上の瑕疵による故障や不具合が生じた場合は、交換または返金させていただきます。ただし、運送費などをご負担いただく場合があります。商品の状態によっては、部材・部品をご送付してお客様でご対応いただく場合がありますので、予めご了承ください。<br><br>
          ※初期不良の交換は、商品到着後7日以内とさせていただきます。<br>
          ※こちらは商品不具合による使用上で生じた事故・損害に対して、当店はその責任（金銭的補償等）を負うことはできません。
        </div>
        <div class="red-box">
          ※保証期間は商品により異なります。<br><span>商品ページ内の記載をご確認ください。</span>
        </div>
      </div>
      <div class="list">
        <div class="title">保証書について</div>
        <div class="desc">
          保証書を同封いたしておりません。<br>ご連絡の際はご注文後にお送りしたメールに記載されたご注文情報が必要となりますので、保証書代わりとして必ず保管をお願いいたします。
        </div>
      </div>
      <div class="list">
        <div class="title">
          保証内容について<br>
          <span>※保証は日本国内においてのみ有効です。</span>
        </div>
        <div class="desc">
          ご家庭で使用された家具に限り、本体貼り付けラベル等の注意書きに従った正常な使用状態で故障した場合は、修理または部品交換をいたします。<br>
          但し、以下の場合は保証期間内であっても有償での修理・交換となりますので予めご了承ください。
        </div>
        <div class="text-content">
          <div class="head">保証対象外</div>
          <div class="content">
            <div class="item">取扱説明書や注意表示での注意事項を守られなかった場合。</div>
            <div class="item">不当な修理や改造による故障や損傷。</div>
            <div class="item">納品後の移動、輸送により生じた破損・故障。</div>
            <div class="item">一般家庭用以外（業務用）に使用した場合の故障または損傷。</div>
            <div class="item">外観のキズ、張地、塗装部分などの外観劣化、木の変質によるもの・自然な変色などの場合。</div>
            <div class="item">天然木の無垢材による一般的な経年変化や反りや割れなどの場合。</div>
            <div class="item">直射日光、冷暖房機器ほか電気製品の熱などによる変形・変色。</div>
            <div class="item">機能や性能にかかわらない場合。</div>
            <div class="item">摩耗、傷、汚れ、色落ち、ソファのクッション部分のヘタレなどの使用による経年劣化。</div>
            <div class="item">当店以外から商品を購入された場合。</div>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="title">免責・注意事項</div>
        <div class="desc">
          商品に瑕疵がなく、通常のご使用中に発生した床・壁等周辺物の直接又は間接の傷及び汚損等につきましては、一切の責任を負うことはできません。<br><br>
          商品組立業者等をお客様自身で手配される場合において、商品不良等の理由で作業ができなかった場合や再組み立て・再設置等が必要になる場合であっても、その費用を当社にて負担することはできません。<br><br>
          ご購入品以外の商品への交換はできません。交換品・部品のお届けまでに初回配送時と同様にお届けまでにお時間を頂戴する場合がございます。<br><br>
          対象商品の在庫が無い場合は入荷後の交換、もしくはご返品（返金）を承ります。<br><br>
          修理については部品などの都合で、外観が変わる場合があります。<br><br>
          保証期間を過ぎた商品については、有償で修理いたしますが、メーカー都合(生産終了)によっては対応できない場合があります。<br><br>
          消耗部分の交換や自然な損耗、経年変化に関するメンテナンス・修理は有償となります。お見積りはお問い合わせください。
        </div>
        <div class="red-box">※こちらは商品不具合による使用上で生じた事故・損害に対して、当店はその責任（金銭的補償等）を負うことはできません。</div>
      </div>
      <div class="list">
        <div class="title">無垢材家具の割れを予防するコツ</div>
        <div class="desc">
          冬の乾燥する時期に、木材は外気に水分を失った木材はどんどんと収縮してしまい、割れが発生してしまいます。<br><br>
          エアコンの風向きを調整し、家具に風が直接当たらないようにしましょう。家具の近くで、加湿器をつけると良いでしょう。<br><br>
          特に冬場は、空気が乾燥するので、暖房器具から離れて使用してください。
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">他のご質問はこちら</div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .red{
      color: #c40a0a;
      font-weight: bold;
    }
    a{
      text-decoration: underline;
    }
    .title-content{
        position: relative;
        width: 100%;
        height: .88rem;
        padding: 0 .24rem;
        margin-top: .36rem;
        line-height: .88rem;
        font-size: .32rem;
        font-weight: 700;
        background: linear-gradient(90deg,#f4f5fc 1%, #ffffff 100%);
        box-sizing: border-box;
        img{
            position: absolute;
            right: 0;
            top: -.3rem;
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    .lists-content{
        margin-top: .56rem;
        .list{
            padding-bottom: .4rem;
            border-bottom: .02rem solid #e8ebed;
            &:not(:last-child){
                margin-bottom: .4rem;
            }
            .title{
                margin-bottom: .32rem;
                font-size: .32rem;
                font-weight: 700;
                span{
                    font-size: .24rem;
                    color: #999999;
                }
            }
            .bg-box{
                width: 100%;
                padding: .28rem .24rem;
                margin-top: .4rem;
                margin-bottom: .24rem;
                background: #f4f5fc;
                box-sizing: border-box;
                font-weight: 500;
                line-height: .4rem;
            }
            .red-box{
                width: 100%;
                padding: .28rem .24rem;
                margin: .32rem 0;
                background: #fcf0f0;
                color: #c40a0c;
                box-sizing: border-box;
                font-weight: 500;
                line-height: .4rem;
                span{
                    margin-left: .28rem;
                }
            }
            .desc{
                line-height: .42rem;
            }
            .s-title{
                margin-top: .34rem;
                text-align: right;
                font-size: .24rem;
                color: #999999;
                img{
                    width: .16rem;
                    height: .2rem;
                    vertical-align: middle;
                }
                span{
                    margin-left: .08rem;
                    vertical-align: middle;
                }
            }
            .text-content{
                margin-top: .28rem;
                border: .02rem solid #333333;
                .head{
                    width: 100%;
                    height: .96rem;
                    line-height: .96rem;
                    text-align: center;
                    font-weight: bold;
                    background-color: #f4f5fc;
                }
                .content{
                    .item{
                        position: relative;
                        padding: .32rem .32rem .32rem .64rem;
                        &:not(:last-child){
                            border-bottom: .02rem solid #f4f5fc;
                        }
                        &::before{
                          content: '・';
                          position: absolute;
                          left: .32rem;
                          top: .32rem;
                        }
                    }
                }
            }
        }
    }
    .button{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
