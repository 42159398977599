<template>
  <div class="mobile-wrapper">
    <heads v-if="headerSpShow" />
    <router-view name="mobile" />
    <footers v-if="footerSpShow" />
    <SimpleFooter v-if="simpleFooterSpShow" />
    <div v-show="suspension" class="suspension" :class="{'close':!sideShow}">
      <div class="content">
        <img src="@/assets/images/sp/close_side_btn.png" class="close-btn" @click="sideShow = false">
        <router-link to="/question">
          <img src="@/assets/images/sp/question.png">
        </router-link>
        <img v-show="toTopShow" src="@/assets/images/sp/to_top.png" @click="toTop()">
        <img v-show="!sideShow" src="@/assets/images/sp/open_side_btn.png" class="open-btn" @click="sideShow = true">
      </div>
    </div>
  </div>
</template>
<script>
import heads from './header'
import footers from './footer'
import SimpleFooter from './simpleFooter'
import { scrollTo } from '@/utils/scrollTo'
export default {
  components: { heads, footers, SimpleFooter },
  data() {
    return {
      suspension: false,
      toTopShow: false,
      headerSpShow: true,
      footerSpShow: true,
      simpleFooterSpShow: false,
      sideShow: true
    }
  },
  watch: {
    $route: {
      handler: function(to, from) {
        this.headerSpShow = this.$route.meta.headerSpShow === undefined
        this.footerSpShow = this.$route.meta.footerSpShow === undefined
        this.simpleFooterSpShow = !!this.$route.meta.simpleFooterSpShow
        this.suspension = ['home', 'search', 'category', 'product'].includes(to.name)
      },
      immediate: true
    }
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // 获取滚动时的高度
      const top = document.documentElement.scrollTop
      if (top > 300) {
        this.toTopShow = true
      } else {
        this.toTopShow = false
      }
    },
    // 回到顶部
    toTop() {
      scrollTo(0, 800)
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile-wrapper{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.suspension{
  position: fixed;
  right: 0.32rem;
  bottom: 1rem;
  z-index: 100;
  transition: .1s;
  .content{
    position: relative;
  }
  img{
    display: block;
    width: 0.76rem;
    height: 0.76rem;
  }
  .close-btn{
    margin-bottom: .08rem;
  }
  .open-btn{
    width: .5rem;
    position: absolute;
    top: 0;
    right: .76rem;
  }
}
.close{
  right: -.76rem;
}
</style>
