<template>
  <div class="company-content">
    <div class="bg">
      <div class="title">WOODLIFEについて</div>
      <div class="text">家具通販・おしゃれなインテリア専門店（ウッドライフ）</div>
    </div>
    <div class="nav-content">
      <div class="item">
        <router-link to="/company">企業情報</router-link>
      </div>
      <div class="item current">
        <router-link to="/tokutei">特定商取引法に基づく表示</router-link>
      </div>
      <div class="item">
        <router-link to="/privacy">プライバシーポリシー</router-link>
      </div>
    </div>
    <div class="table">
      <img src="@/assets/images/pc/table_right_top.png">
      <div class="list-content">
        <div class="list">
          <div class="name">販売業者</div>
          <div class="value">はんこプレミアム株式会社 （Hanko Premium Co.,Ltd.）</div>
        </div>
        <div class="list">
          <div class="name">代表者</div>
          <div class="value">晴海 旭</div>
        </div>
        <div class="list">
          <div class="name">住所</div>
          <div class="value">
            〒274-0065 千葉県船橋市高根台7丁目26-12
          </div>
        </div>
        <div class="list">
          <div class="name">電話番号</div>
          <div class="value">047-489-5595 (営業電話などはご遠慮ください。)</div>
        </div>
        <div class="list">
          <div class="name">電話受付時間</div>
          <div class="value">平日 9:30〜18:30</div>
        </div>
        <div class="list">
          <div class="name">お問い合わせ</div>
          <div class="value">
            <router-link to="/question">お問い合わせはこちら ＞＞</router-link>
          </div>
        </div>
        <div class="list">
          <div class="name">販売価格</div>
          <div class="value">商品紹介ページをご参照ください。</div>
        </div>
        <div class="list">
          <div class="name">公式通販サイト</div>
          <div class="value">
            <router-link to="/">https://www.woodlife.jp/</router-link>
          </div>
        </div>
        <div class="list">
          <div class="name">画面表示と実際の<br>商品</div>
          <div class="value">商品の色、素材感をできるだけ忠実に再現しようと努力してはいますが、大きな商品をモニターの中で小さな写真によって表現していますので、画面上ご覧になっている色と、実際の商品の色が多少異なる場合がございます。</div>
        </div>
        <div class="list">
          <div class="name">商品のお届け</div>
          <div class="value">弊社指定のヤマト運輸会社および、株式会社ハルミからお届けいたします。</div>
        </div>
        <div class="list">
          <div class="name">引き渡し時期</div>
          <div class="value">商品は充分用意しておりますが、同一商品にご注文が殺到した際には品切れになることもあります。ご容赦ください。</div>
        </div>
        <div class="list">
          <div class="name">送料について</div>
          <div class="value">54,000円以上のお買い上げは送料無料です。<br>54,000円以下のお買い上げは1個につき3,000円（税込）です。<br>※北海道・沖縄・鹿児島県・離島へのお届けは、別途追加送料が必要です。</div>
        </div>
        <div class="list">
          <div class="name">返品・<br>交換について</div>
          <div class="value">お客様のご都合による返品・返金および交換はお受けできません。<br>
            <router-link to="/guides/return">詳しくはこちら >></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.company-content{
    margin-bottom: 80px;
    font-size: 16px;
    .bg{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 192px;
        background: url('../../../assets/images/pc/company_bg.png') 100%;
        .title{
            font-size: 54px;
            font-weight: 700;
        }
        .text{
            margin-top: 16px;
            font-size: 14px;
        }
    }
    .nav-content{
        padding-bottom: 10px;
        margin-top: 28px;
        border-bottom: 2px dashed #c6cbd9;
        text-align: center;
        .item{
            display: inline-block;
            &:nth-child(2){
                margin: 0 48px;
            }
        }
        .current{
            font-size: 20px;
            font-weight: 700;
            a:hover{
                text-decoration: inherit;
            }
        }
        a:hover{
            text-decoration: underline;
        }
    }
    .table{
        position: relative;
        width: 812px;
        padding: 56px 40px;
        margin: 56px auto 0;
        border-radius: 12px;
        box-shadow: 0px 2px 6px 0px rgba(28,48,64,0.06);
        img{
            position: absolute;
            right: -4px;
            top: -4px;
        }
        .list{
            display: flex;
            line-height: 24px;
            &:not(:last-child){
                padding-bottom: 24px;
                margin-bottom: 24px;
                border-bottom: 1px solid #e8ebed;
            }
            .name{
                display: flex;
                align-items: center;
                width: 128px;
                font-weight: 700;
            }
            .value{
                width: 484px;
                margin-left: 80px;
                a{
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
