var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v("家具通販専門店TOP"),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
          }),
          _c("span", [_vm._v("お問い合わせ")]),
        ],
        1
      ),
      _c("div", { staticClass: "question-title" }, [
        _vm._v(
          "お問い合わせ前に、よくあるご質問に該当がないかご確認ください。"
        ),
      ]),
      _c("common"),
      _c("question-from"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }