<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <span>ニュース</span>
    </div>
    <div class="article-title">
      <div>NEW</div>
      <div>ニュース</div>
    </div>
    <div class="article-box">
      <common />
      <div>
        <div class="article-list">
          <router-link v-for="item in initData" :key="item.id" class="article-item" :to="'/article/'+ item.id">
            <img :src="item.image">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.description }}</div>
            <div class="date">{{ item.created_at.substring(0,10) }}</div>
          </router-link>
          <div class="article-item-seat" />
        </div>
        <pagination-pc
          :hidden="total <= parameterInfo.limit"
          :total="total"
          auto-scroll
          :page.sync="parameterInfo.page"
          :limit.sync="parameterInfo.limit"
          :link="$route.fullPath"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getArticles } from '@/api/article'
import { parameterCorrection } from '@/utils/validate'
import PaginationPc from '@/components/PaginationPc'
import common from '@/views/pc/article/common'
export default {
  components: { PaginationPc, common },
  data() {
    return {
      initData: [],
      total: 0
    }
  },
  computed: {
    title: function() { return this.$route.query.title || '' },
    article_category_id: function() { return this.$route.query.article_category_id || '' },
    parameterInfo: function() {
      return {
        page: parseInt(this.$route.query.page) || 1,
        limit: 9
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.getList()
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 文章列表
    getList() {
      let data = parameterCorrection({ title: this.title, article_category_id: this.article_category_id })
      data = { ...data, ...this.parameterInfo }
      getArticles(data).then((result) => {
        this.initData = result.data
        this.total = result.meta.total
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  line-height: 17px;
  margin: 28px auto 0;
}
.article-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 192px;
  color: #333;
  background: url('~@/assets/images/pc/article_bg.png') center center / auto 100%;
  margin-top: -26px;
  :nth-child(1){
    font-size: 54px;
    font-weight: bold;
  }
  :nth-child(2){
    font-size: 14px;
  }
}
.article-box{
  display: flex;
  justify-content: space-between;
  width: 1440px;
  font-size: 16px;
  color: #333;
  margin: 48px auto;
  .article-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1098px;
    .article-item-seat{
      width: 350px;
    }
    .article-item{
      width: 348px;
      border: 1px solid #E8EBED;
      margin-bottom: 20px;
      img{
        width: 348px;
        height: 230px;
      }
    }
    .title{
      font-size: 20px;
      font-weight: bold;
      margin: 24px 24px 16px;
    }
    .desc{
      color: #666;
      text-align: justify;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:3;
      margin: 16px 24px 24px;
    }
    .date{
      color: #999999;
      text-align: right;
      margin: 24px;
    }
  }
}
</style>
