<template>
  <div class="password-content">
    <div class="title">パスワードをお忘れの方</div>
    <div class="text">お客様のパスワードの再設定をします。<br>新しいパスワードをご入力ください。</div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
      <el-form-item prop="password" label="">
        <el-input v-model.trim="ruleForm.password" type="password" placeholder="パスワード※" />
      </el-form-item>
      <el-form-item prop="password_confirmation" label="">
        <el-input v-model.trim="ruleForm.password_confirmation" type="password" placeholder="パスワード（確認用）※" />
      </el-form-item>
      <el-form-item prop="" label="">
        <div class="button" @click="handleResetPassword">
          <span>確定</span>
          <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { resetPassword } from '@/api/user'
export default {
  data() {
    return {
      ruleForm: {
        email: this.$route.query.email,
        reset_token: this.$route.query.reset_token
      },
      rules: {
        password: { required: true, message: 'パスワードごをご入力ください', trigger: ['blur'] },
        password_confirmation: { required: true, message: 'パスワードを再度ご入力ください', trigger: ['blur'] }
      }
    }
  },
  created() {

  },
  methods: {
    handleResetPassword() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          resetPassword(this.ruleForm).then(() => {
            this.$router.push('/password/success')
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.password-content{
    margin-top: .56rem;
    padding: 0 .24rem;
    flex: 1;
    .title{
        font-size: .36rem;
        font-weight: 700;
    }
    .text{
        margin-top: .08rem;
        line-height: .42rem;
    }
    .el-form{
        margin-top: .36rem;
    }
    .button{
      width: 100%;
      height: .88rem;
      margin-top: .24rem;
      line-height: .88rem;
      text-align: center;
      color: #ffffff;
      background: #333333;
    }
}
</style>
