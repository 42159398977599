<template>
  <div id="app">
    <router-view :name="isMobile?'mobile':'default'" />
  </div>
</template>

<script>
import { getBasicConfig } from '@/api/config'
import { scrollTo } from '@/utils/scrollTo'
import { getQueryString } from '@/utils/validate'
import { setMaf } from '@/utils/auth'
export default {
  name: 'App',
  data() {
    return {
      title: null,
      keywords: null,
      description: null,
      canonical: window.location.origin
    }
  },
  computed: {
    isMobile() {
      const flag = navigator.userAgent.match( // match方法可在字符串内检索指定的值，
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  },
  watch: {
    $route(to, from) {
      window.dataLayer = window.dataLayer || [] // dataLayer初始化
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': to.path // 新页面的页面Path
      })
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.keywords },
        { vmid: 'description', name: 'description', content: this.description }
      ],
      link: [
        { vmid: 'canonical', rel: 'canonical', href: this.canonical }
      ]
    }
  },
  created() {
    this.getBasicConfig()
    const maf = getQueryString('maf')
    if (maf) {
      setMaf(maf)
    }
  },
  mounted() {
    scrollTo(0, 800)
  },
  methods: {
    // 获取店铺配置
    getBasicConfig() {
      getBasicConfig().then(result => {
        this.title = result.seo_title
        this.keywords = result.seo_keywords
        this.description = result.seo_description
        this.$store.dispatch('config/getBasicConfig', result)
      })
    }
  }
}
</script>
