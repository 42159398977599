import Cookies from 'js-cookie'

const TokenKey = 'woodlife_token'
const MafKey = 'woodlife_maf'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getMaf() {
  return Cookies.get(MafKey)
}

export function setMaf(maf) {
  return Cookies.set(MafKey, maf)
}

export function removeMaf() {
  return Cookies.remove(MafKey)
}
