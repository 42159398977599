var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personal-content" },
    [
      _c(
        "div",
        { staticClass: "bread-crumbs" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / "),
          _c("router-link", { attrs: { to: "/personal/mine" } }, [
            _vm._v("マイページ"),
          ]),
          _vm._v(" / "),
          _c("router-link", { attrs: { to: "/personal/order" } }, [
            _vm._v("購入履歴"),
          ]),
          _vm._v(" / 注文詳細 "),
        ],
        1
      ),
      _c("div", { staticClass: "detail-content" }, [
        _c("div", { staticClass: "head" }, [
          _c("div", { staticClass: "box" }, [
            _c("div", [_vm._v("ご注文番号：" + _vm._s(_vm.initData.order_sn))]),
            _c("div", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.initData.created_at)),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "status",
              class:
                _vm.initData.status_to_user === 1
                  ? "blue"
                  : _vm.initData.status_to_user === 2
                  ? "green"
                  : _vm.initData.status_to_user === 4
                  ? "gray"
                  : "",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.initData.store_status && _vm.initData.store_status.name
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "product-lists" },
          [
            _vm._l(_vm.initData.order_products, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "list" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/product/" + item.product_sn } },
                    [_c("img", { attrs: { src: item.product_img } })]
                  ),
                  _c("div", { staticClass: "desc" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.product_title)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "sku-content" },
                      _vm._l(
                        item.product_sku_display,
                        function (skuItem, index2) {
                          return _c(
                            "div",
                            { key: index2, staticClass: "item" },
                            [_vm._v(_vm._s(skuItem))]
                          )
                        }
                      ),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "norm-content" },
                      _vm._l(
                        item.product_spu_display,
                        function (normItem, index3) {
                          return _c(
                            "div",
                            { key: index3, staticClass: "item" },
                            [_vm._v(_vm._s(normItem))]
                          )
                        }
                      ),
                      0
                    ),
                    _c("div", { staticClass: "price" }, [
                      _c("span", [
                        _vm._v(
                          "¥" + _vm._s(_vm.calcThousands(item.pay_price)) + "円"
                        ),
                      ]),
                      _c("span", { staticClass: "num" }, [
                        _vm._v("×" + _vm._s(item.product_quantity)),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            }),
            _c("div", { staticClass: "price-detail" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "name" }, [_vm._v("送料")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "+¥" +
                      _vm._s(_vm.calcThousands(_vm.initData.freight_amount))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "name" }, [_vm._v("クーポン")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "-¥" + _vm._s(_vm.calcThousands(_vm.initData.use_point_fee))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "name" }, [_vm._v("利用ポイント")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "-¥" + _vm._s(_vm.calcThousands(_vm.initData.coupon_amount))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "name" }, [_vm._v("合计金额")]),
                _c("div", { staticClass: "value price" }, [
                  _vm._v(
                    "¥" + _vm._s(_vm.calcThousands(_vm.initData.pay_amount))
                  ),
                ]),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _c("table", { staticClass: "address-content" }, [
        _c("tr", [
          _c("td", [_vm._v("配送先")]),
          _c("td", [
            _c("p", [_vm._v(_vm._s(_vm.initData.receiver_name) + " 様")]),
            _c("p", [
              _vm._v(
                "〒" +
                  _vm._s(_vm.initData.zip_code) +
                  " " +
                  _vm._s(_vm.initData.stats) +
                  _vm._s(_vm.initData.city) +
                  _vm._s(_vm.initData.address)
              ),
            ]),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.initData.detail_address ? _vm.initData.detail_address : ""
                )
              ),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.initData.receiver_phone))]),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("支払方法")]),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.initData.pay_type === 2
                  ? "銀行振込・前払い"
                  : _vm.initData.pay_type === 3
                  ? "クレジットカード決済"
                  : _vm.initData.pay_type === 4
                  ? "amazon pay"
                  : ""
              )
            ),
          ]),
        ]),
      ]),
      _vm.initData.is_comment
        ? _c("div", { staticClass: "comments-content" }, [
            _c("div", { staticClass: "head" }, [_vm._v("レビュー")]),
            _c(
              "div",
              { staticClass: "product-lists" },
              _vm._l(_vm.commentsData, function (item) {
                return _c("div", { key: item.id, staticClass: "list" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/product/" + item.product.product_sn },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: item.product.image,
                              alt: item.product.image_alt,
                            },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "desc" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.product_name)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "sku-content" },
                          _vm._l(
                            item.product_attribute,
                            function (skuItem, index2) {
                              return _c(
                                "div",
                                { key: index2, staticClass: "item" },
                                [_vm._v(_vm._s(skuItem))]
                              )
                            }
                          ),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "norm-content" },
                          _vm._l(
                            item.product_spu_display,
                            function (normItem, index3) {
                              return _c(
                                "div",
                                { key: index3, staticClass: "item" },
                                [_vm._v(_vm._s(normItem))]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "rate-content" }, [
                    _c(
                      "div",
                      [
                        _c("van-rate", {
                          attrs: {
                            color: "#fccf52",
                            "void-icon": "star",
                            "void-color": "#e3e4eb",
                            readonly: "",
                          },
                          model: {
                            value: item.stars,
                            callback: function ($$v) {
                              _vm.$set(item, "stars", $$v)
                            },
                            expression: "item.stars",
                          },
                        }),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.stars)),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(item.content)),
                    ]),
                    item.images.length > 0
                      ? _c("div", { staticClass: "image-content" }, [
                          _c(
                            "div",
                            {
                              staticClass: "box",
                              class:
                                item.images.length === 1
                                  ? "one"
                                  : item.images.length === 2
                                  ? "two"
                                  : item.images.length === 3
                                  ? "three"
                                  : item.images.length === 4
                                  ? "four"
                                  : "",
                            },
                            _vm._l(item.images, function (value, key) {
                              return _c("img", {
                                key: key,
                                attrs: { src: value },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleImagePreview(
                                      item.images,
                                      key
                                    )
                                  },
                                },
                              })
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("router-link", { attrs: { to: "/personal/order" } }, [
        _c("div", { staticClass: "button" }, [_vm._v("閉じる")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }