var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "tips" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / お問い合わせ"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "question-title" },
        [
          _c("div", [
            _vm._v(
              "お問い合わせ前に、よくあるご質問に該当がないかご確認ください。"
            ),
          ]),
          _c("router-link", { attrs: { to: "/guides/question" } }, [
            _vm._v("よくあるご質問を確認する >>"),
          ]),
        ],
        1
      ),
      _c("question-from"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }