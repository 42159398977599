var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
        }),
        _c("router-link", { attrs: { to: "/question" } }, [
          _vm._v("お問い合わせ"),
        ]),
        _c("img", {
          attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
        }),
        _c("span", [_vm._v("内容ご確認")]),
      ],
      1
    ),
    _c("div", { staticClass: "question-from" }, [
      _vm._m(0),
      _c("div", { staticClass: "dc" }, [_vm._v("お問い合わせ内容ご確認")]),
      _vm._m(1),
      _vm._m(2),
      _c("div", { staticClass: "question-table" }, [
        _vm._m(3),
        _c("div", { staticClass: "question-table-item" }, [
          _c("div", { staticClass: "question-table-left" }, [
            _c("div", [
              _c("div", { staticClass: "desc" }, [_vm._v("お問い合わせ項目")]),
              _c("div", { staticClass: "ask" }, [
                _vm.ruleForm.type === 1
                  ? _c("span", [_vm._v("商品について")])
                  : _vm.ruleForm.type === 2
                  ? _c("span", [_vm._v("納期について")])
                  : _vm.ruleForm.type === 3
                  ? _c("span", [_vm._v("不良交換について")])
                  : _vm.ruleForm.type === 4
                  ? _c("span", [_vm._v("サイトについて")])
                  : _vm.ruleForm.type === 5
                  ? _c("span", [_vm._v("その他")])
                  : _vm._e(),
              ]),
            ]),
            _c("div", [
              _c("div", { staticClass: "desc" }, [_vm._v("お名前")]),
              _c("div", { staticClass: "ask" }, [
                _vm._v(_vm._s(_vm.ruleForm.username)),
              ]),
            ]),
            _c("div", [
              _c("div", { staticClass: "desc" }, [_vm._v("メールアドレス")]),
              _c("div", { staticClass: "ask" }, [
                _vm._v(_vm._s(_vm.ruleForm.email)),
              ]),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "desc" }, [_vm._v("お問い合わせ内容")]),
            _c("div", { staticClass: "ask" }, [
              _vm._v(_vm._s(_vm.ruleForm.content)),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "button" },
        [
          _c(
            "el-button",
            {
              staticClass: "return",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/leftArrow.png") },
              }),
              _c("span", [_vm._v("前に戻る")]),
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "deliver",
              attrs: { loading: _vm.btnLoading, type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [
              _c("span", [_vm._v("送信する")]),
              _c("img", {
                attrs: { src: require("@/assets/images/pc/rightArrow.png") },
              }),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ttl" }, [
      _c("span", [_vm._v("Contact us by email")]),
      _c("span", { staticClass: "num" }, [_vm._v("02")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v("下記フォームに必要事項をご記入の上、送信してください。"),
      _c("br"),
      _vm._v(
        "24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。"
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v(
        "※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。"
      ),
      _c("br"),
      _vm._v(
        "※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。"
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "ask" }, [_vm._v("内容ご確認")]),
      _c("div", { staticClass: "desc" }, [_vm._v("Contents confirmation")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }