<template>
  <div class="personal-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/personal/mine">マイページ</router-link> / myポイント
    </div>
    <div class="title">myポイント</div>
    <div class="detail-content">
      <div class="head">
        <span>ご利用可能ポイント：</span><span class="value">{{ calcThousands(initData.totalPoint) }} pt</span>
      </div>
      <div class="item-content">
        <div class="item">
          <div class="value">{{ calcThousands(initData.normalPoint) }} pt</div>
          <div class="text">通常ポイント</div>
          <div class="desc">
            ■ 有効期限： {{ initData.normalPointExpiredAt }}<br><br>
            ■ 通常ポイントの有効期限は最後のご購入またはポイント獲得から1年間です。woodlife.jpでお買い物をするたびに最後のお買い物の1年後に延長されます。
          </div>
        </div>
        <div class="item">
          <div class="value">{{ calcThousands(initData.activityPoint) }}</div>
          <div class="text">期間限定ポイント</div>
          <div class="desc">
            ■ 通常ポイントのうち、特定の有効期限までに限って利用できるポイントです。<br>
            ■ お買い物をすることで有効期限が延長されません。<br>
            ■ 通常のポイントと同様、{{ initData.per }}ポイント＝{{ initData.deuction }}円分として使えるポイントです。<br>
            ■ 期間終了後、期間限定ポイントは無効となります。
          </div>
        </div>
        <div class="item">
          <div class="value">{{ calcThousands(willPoint) }}</div>
          <div class="text">獲得予定ポイント</div>
          <div class="desc">
            ■ 獲得予定ポイントはお支払いにはご利用いただけません。原則として、獲得予定ポイントは、商品の発送日を目安にご利用可能ポイントに変わります。
          </div>
        </div>
      </div>
    </div>
    <div v-if="pointRecordData.length" class="record-content">
      <div class="title">ポイントご利用明細</div>
      <div class="item-content">
        <div v-for="(item,index) in pointRecordData" :key="index" to="" class="item" @click="handleTo(item)">
          <div class="name">
            <div class="text">{{ item.name }} {{ item.order_sn || '' }}</div>
            <div class="time">{{ item.created_at }}</div>
          </div>
          <div class="value" :class="{'red':!item.is_point_obtain}">{{ item.is_point_obtain ? '+' : '-' }}{{ item.point }}</div>
          <div class="arrow">
            <img v-if="item.order" src="@/assets/images/sp/right_arrow1.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyPoint, getPointRecordList, getWillPoint } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      initData: {
        totalPoint: 0,
        activityPoint: 0,
        normalPoint: 0
      },
      pointRecordData: [],
      willPoint: 0,
      calcThousands
    }
  },
  computed: {
  },
  created() {
    this.getMyPoint()
    this.getWillPoint()
    this.getPointRecordList()
  },

  methods: {
    getMyPoint() {
      getMyPoint().then(result => {
        this.initData = result
      })
    },
    getWillPoint() {
      getWillPoint().then(result => {
        this.willPoint = result.point
      })
    },
    getPointRecordList() {
      getPointRecordList({ type: 'all' }).then(result => {
        this.pointRecordData = result.data
      })
    },
    handleTo(item) {
      if (item.order) {
        this.$router.push(`order/detail/${item.order.id}`)
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content{
  padding: 0 .24rem;
  flex: 1;
  .bread-crumbs{
      margin-top: .16rem;
      text-align: center;
      font-size: .24rem;
  }
  .title{
      margin-top: .56rem;
      padding-bottom: .24rem;
      border-bottom: .02rem solid #333333;
      font-size: .36rem;
      font-weight: 700;
  }
  .detail-content{
    margin-top: .42rem;
    border: .02rem solid #e8ebed;
    .head{
      width: 100%;
      height: .96rem;
      padding: 0 .22rem;
      background: url(~@/assets/images/sp/point_bg.png) no-repeat center center / 100%;
      font-size: .24rem;
      line-height: .96rem;
      box-sizing: border-box;
      .value{
        font-size: .48rem;
        font-weight: 700;
        vertical-align: middle;
      }
    }
    .item-content{
      padding: .24rem;
      .item{
        padding: .4rem .24rem;
        background: #f4f5fc;
        &:not(:last-child){
          margin-bottom: .24rem;
        }
        .value{
          font-size: .36rem;
          font-weight: 700;
          text-align: center;
        }
        .text{
          margin-top: .12rem;
          font-size: .28rem;
          text-align: center;
        }
        .desc{
          padding-top: .4rem;
          margin-top: .4rem;
          line-height: .36rem;
          border-top: .02rem solid #e8ebed;
          color: #999FA5;
        }
      }
    }
  }
  .record-content{
    margin-top: .8rem;
    .item-content{
      margin-top: .4rem;
      .item{
        display: flex;
        justify-content: space-between;
        padding: .3rem .22rem;
        border: .02rem solid #e8ebed;
        &:not(:last-child){
          margin-bottom: .12rem;
        }
        .name{
          width: 4.4rem;
          .text{
            font-size: .28rem;
            font-weight: 700;
          }
          .time{
            margin-top: .12rem;
            color: #999FA5;
          }
        }
        .value{
          display: flex;
          align-items: center;
          width: 1.38rem;
          color: #16B127;
          font-size: .28rem;
          font-weight: 700;
        }
        .red{
          color: #C40A0A
        }
        .arrow{
          display: flex;
          align-items: center;
          width: .24rem;
          img{
            width: .24rem;
            height: .24rem;
          }
        }

      }
    }
  }
}
</style>
