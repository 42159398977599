<template>
  <div class="password-content">
    <div class="bg">
      <div class="breadcrumb">
        <router-link to="/">家具通販専門店TOP</router-link>
        <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
        <span>パスワードをお忘れの方</span>
      </div>
    </div>
    <div class="right-form">
      <div class="title">パスワードをお忘れの方</div>
      <div class="text">お客様のパスワードの再設定をします。<br>ご登録されたメールアドレスと、新しいパスワードをご入力ください。</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item prop="password" label="">
          <el-input v-model.trim="ruleForm.password" type="password" placeholder="パスワード※" />
        </el-form-item>
        <el-form-item prop="password_confirmation" label="">
          <el-input v-model.trim="ruleForm.password_confirmation" type="password" placeholder="パスワード（確認用）※" />
        </el-form-item>
        <el-form-item prop="" label="">
          <div class="button" @click="handleResetPassword">
            <span>確定</span>
            <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { resetPassword } from '@/api/user'
export default {
  data() {
    return {
      ruleForm: {
        email: this.$route.query.email,
        reset_token: this.$route.query.reset_token
      },
      rules: {
        password: { required: true, message: 'パスワードごをご入力ください', trigger: ['blur'] },
        password_confirmation: { required: true, message: 'パスワードを再度ご入力ください', trigger: ['blur'] }
      }
    }
  },
  created() {

  },
  methods: {
    handleResetPassword() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          resetPassword(this.ruleForm).then(() => {
            this.$router.push('/password/success')
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.password-content{
    display: flex;
    .bg{
        width: 1076px;
        height: 760px;
        background: url('../../../assets/images/pc/login_bg.png') 100%;
        .breadcrumb{
            margin-top: 28px;
            margin-left: 240px;
        }
    }
    .right-form{
        margin-left: 18px;
        .title{
          margin-top: 80px;
          font-size: 28px;
          font-weight: 700;
        }
        .text{
            margin-top: 64px;
            font-size: 16px;
            line-height: 24px;
        }
        .el-form{
          margin-top: 16px;
        }
        .el-input{
          width: 400px;
        }
        .button{
          display: flex;
          justify-content: space-between;
          width: 400px;
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          background: #333;
          color: #fff;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
          img{
            width: 32px;
            height: 9px;
          }
          .arrow{
            display: flex;
            align-items: center;
          }
        }
    }
}
</style>
