<template>
  <div class="personal-content">
    <div class="bread-crumbs"><router-link to="/">家具TOP</router-link> / マイページ</div>
    <div class="detail-content">
      <div class="bg">{{ username }} 様</div>
      <div class="detail">
        <div class="text1">{{ calcThousands(totalPoint) }} pt</div>
        <div class="text2">保有ポイント</div>
        <div class="text3">※ 有効期限は最終ご購入日から1年間です</div>
      </div>
    </div>
    <div v-show="swiperData.length>0" class="swiper">
      <div class="swiper-wrapper">
        <div v-for="item in swiperData" :key="item.id" class="swiper-slide">
          <a v-if="item.href" :href="item.href">
            <img :src="item.image" :alt="item.alt">
          </a>
          <img v-else :src="item.image" :alt="item.alt">
        </div>
      </div>
      <div class="swiper-pagination" />
    </div>
    <div class="list-content">
      <router-link to="/personal/address">
        <div class="list">
          <div>
            <img src="@/assets/images/sp/personal_address.png" class="icon">
            <span>アカウント情報・変更</span>
          </div>
          <div class="arrow">
            <img src="@/assets/images/sp/right_arrow1.png">
          </div>
        </div>
      </router-link>
      <router-link to="/personal/order">
        <div class="list">
          <div>
            <img src="@/assets/images/sp/personal_order.png" class="icon">
            <span>注文履歴</span>
          </div>
          <div class="arrow">
            <img src="@/assets/images/sp/right_arrow1.png">
          </div>
        </div>
      </router-link>
      <router-link to="/personal/my_collection">
        <div class="list">
          <div>
            <img src="@/assets/images/sp/personal_collection.png" class="icon">
            <span>お気に入り商品</span>
          </div>
          <div class="arrow">
            <img src="@/assets/images/sp/right_arrow1.png">
          </div>
        </div>
      </router-link>
      <router-link to="/personal/coupon">
        <div class="list">
          <div>
            <img src="@/assets/images/sp/personal_coupon.png" class="icon">
            <span>myクーポン</span>
          </div>
          <div class="arrow">
            <img src="@/assets/images/sp/right_arrow1.png">
          </div>
        </div>
      </router-link>
      <router-link to="/personal/point">
        <div class="list">
          <div>
            <img src="@/assets/images/sp/personal_point.png" class="icon">
            <span>myポイント</span>
          </div>
          <div class="arrow">
            <img src="@/assets/images/sp/right_arrow1.png">
          </div>
        </div>
      </router-link>
      <div class="list" @click="dialogVisible = true">
        <div>
          <img src="@/assets/images/sp/personal_logout.png" class="icon">
          <span>ログアウト</span>
        </div>
        <div class="arrow">
          <img src="@/assets/images/sp/right_arrow1.png">
        </div>
      </div>
    </div>
    <van-overlay :show="dialogVisible">
      <div class="wrapper" @click.stop>
        <div class="modal-content">
          <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="dialogVisible = false">
          <div class="title">ログアウトしますか？</div>
          <el-button type="primary" class="confirm" :loading="btnLoading" @click="handleLogout">する（Yes）</el-button>
          <div class="cancel" @click="dialogVisible = false">しない（NO）</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPersonalSwiperList } from '@/api/personal'
import { getMyPoint } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      swiperData: [],
      totalPoint: 0,
      calcThousands
    }
  },
  computed: {
    ...mapGetters([
      'username'
    ])
  },
  created() {
    this.getMyPoint()
    this.getPersonalSwiperList()
    if (!this.username) {
      this.$store.dispatch('user/getUserDetail')
    }
  },
  methods: {
    getMyPoint() {
      getMyPoint().then(result => {
        this.totalPoint = result.totalPoint
      })
    },
    getPersonalSwiperList() {
      getPersonalSwiperList({ 'filter[type]': 4, type: 'all' }).then(result => {
        this.swiperData = result
        if (this.swiperData.length > 0) {
          this.$nextTick(() => {
            this.swiper()
          })
        }
      })
    },
    swiper() {
      new window.Swiper('.swiper', {
        autoplay: true,
        loop: true,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination'
        }
      })
    },
    handleLogout() {
      this.btnLoading = true
      this.$store.dispatch('user/logout').then(() => {
        this.btnLoading = false
        this.$store.dispatch('user/getCartNumber')
        this.$router.push('/')
        // location.reload()
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content{
  padding: 0 .24rem;
  margin-bottom: 1.12rem;
  .bread-crumbs{
      margin-top: .16rem;
      text-align: center;
      font-size: .24rem;
  }
  .detail-content{
    position: relative;
    margin-top: .16rem;
    .bg{
      width: 4.41rem;
      height: 1.98rem;
      padding-top: .62rem;
      padding-left: .26rem;
      background: url('~@/assets/images/sp/username_bg.png') no-repeat center center / 100%;
      box-sizing: border-box;
      font-size: .36rem;
      font-weight: 700;
    }
    .detail{
      position: absolute;
      right: .36rem;
      top: 50%;
      transform: translate(0, -50%);
      text-align: right;
      .text1{
        font-size: .44rem;
        font-weight: 700;
        color: #f2994a;
      }
      .text2{
        margin-top: .12rem;
      }
      .text3{
        margin-top: .24rem;
        font-size: .24rem;
        color: #999999;
      }
    }
  }
  .swiper{
    margin-top: .32rem;
    padding-bottom: 0.5rem;
    .swiper-pagination ::v-deep{
      bottom: .2rem;
      .swiper-pagination-bullet{
        width: 0.56rem;
        height: 0.08rem;
        border: 0.02rem solid #666666;
        background: transparent;
        margin: 0 0.06rem;
        border-radius: 0;
        opacity: 1;
      }
      .swiper-pagination-bullet-active{
        background: #333333;
      }
  }
  }
  .list-content{
    margin-top: .8rem;
    border-top: .02rem solid #e8ebed;
    .list{
      display: flex;
      justify-content: space-between;
      padding: .24rem .24rem .24rem .02rem;
      border-bottom: .02rem solid #e8ebed;
      .icon{
        width: .48rem;
        height: .48rem;
        vertical-align: middle;
      }
      span{
        margin-left: .08rem;
        vertical-align: middle;
      }
      .arrow{
        display: flex;
        align-items: center;
        img{
          width: .24rem;
          height: .24rem;
        }
      }
    }
  }
  .van-overlay{
  .modal-content{
    .title{
      font-size: .36rem;
      font-weight: 700;
    }
    .confirm{
      width: 100%;
      height: .88rem;
      margin-top: .5rem;
      background: #333333;
      color: #ffffff;
    }
    .cancel{
      width: 100%;
      height: .88rem;
      margin-top: .16rem;
      line-height: .88rem;
      border: .02rem solid #333333;
      box-sizing: border-box;
    }
  }
}
}
</style>
