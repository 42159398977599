var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "question-from" },
      [
        _c("div", { staticClass: "ttl" }, [_vm._v("Contact us by email")]),
        _c("div", { staticClass: "dc" }, [_vm._v("メールでお問い合わせ")]),
        _vm._m(0),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "type", label: "お問い合わせ項目" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "（項目を選んでください）",
                      clearable: "",
                    },
                    model: {
                      value: _vm.ruleForm.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "type", $$v)
                      },
                      expression: "ruleForm.type",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "商品について", value: 1 },
                    }),
                    _c("el-option", {
                      attrs: { label: "納期について", value: 2 },
                    }),
                    _c("el-option", {
                      attrs: { label: "不良交換について", value: 3 },
                    }),
                    _c("el-option", {
                      attrs: { label: "サイトについて", value: 4 },
                    }),
                    _c("el-option", { attrs: { label: "その他", value: 5 } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "username", label: "お名前" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "お名前", clearable: "" },
                  model: {
                    value: _vm.ruleForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "username", $$v)
                    },
                    expression: "ruleForm.username",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "email", label: "メールアドレス" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "メールアドレス", clearable: "" },
                  model: {
                    value: _vm.ruleForm.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "email", $$v)
                    },
                    expression: "ruleForm.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "content", label: "お問い合わせ内容" } },
              [
                _c("el-input", {
                  attrs: { type: "textarea", placeholder: "お問い合わせ内容" },
                  model: {
                    value: _vm.ruleForm.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "content", $$v)
                    },
                    expression: "ruleForm.content",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "deliver",
            attrs: { type: "primary" },
            on: { click: _vm.onSubmit },
          },
          [_vm._v("送信")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v(" 下記フォームに必要事項をご記入の上、送信してください。"),
      _c("br"),
      _c("br"),
      _vm._v(
        " 24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " ※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " ※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }