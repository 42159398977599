<template>
  <div class="search-page">
    <div class="tips"><router-link to="/">家具TOP</router-link> / 検索: 「{{ inputShow }}」の検索結果{{ total }}件</div>
    <form action="javascript:return false" @submit="search">
      <input ref="search" v-model="input" class="search" type="search" placeholder="キーワードで検索">
    </form>
    <div v-if="initData.length>0" class="goods-box">
      <div class="screen">
        <div>{{ (parameterInfo.page - 1) * parameterInfo.limit }} ～ {{ parameterInfo.page * parameterInfo.limit }} 件目を表示しています。<br>(全{{ total }}件)</div>
        <div class="screen-box" :class="{show : show}" @click.stop="show = !show">
          <img v-if="show" src="@/assets/images/sp/screen_show.png">
          <img v-else src="@/assets/images/sp/screen.png">
          <span>並べ替え</span>
          <transition name="el-fade-in-linear">
            <div v-show="show" class="screen-item">
              <div :class="{current:parameterInfo.sort === 'is_hot'}" @click="parameterInfo.sort = 'is_hot',parameterInfo.page = 1,productList()">人気順</div>
              <div :class="{current:parameterInfo.sort === '-created_at'}" @click="parameterInfo.sort = '-created_at',parameterInfo.page = 1,productList()">新着順</div>
              <div :class="{current:parameterInfo.sort === 'price'}" @click="parameterInfo.sort = 'price',parameterInfo.page = 1,productList()">価格安い順</div>
              <div :class="{current:parameterInfo.sort === '-price'}" @click="parameterInfo.sort = '-price',parameterInfo.page = 1,productList()">価格高い順</div>
            </div>
          </transition>
        </div>
      </div>
      <div class="goods-list">
        <div v-for="item in initData" :key="item.id" class="goods-item">
          <router-link :to="'/product/'+ item.product_sn">
            <div class="img">
              <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
              <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="discount">{{ item.promotion[0].discount }}%OFF</div>
            </div>
          </router-link>
          <div class="label">
            <div
              v-for="(it,index) in item.color_tags"
              :key="index"
              :style="{background:it.color_value}"
              :class="{active:item.colorTagIndex === index}"
              @click="item.colorTagIndex === index ? item.colorTagIndex = '' : item.colorTagIndex = index"
            >
              <span />
            </div>
          </div>
          <div class="title-box">
            <div class="title">{{ item.title }}</div>
            <div class="price">
              <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
                <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
                <span class="original">¥{{ calcThousands(item.price) }}</span>
              </template>
              <template v-else>
                ¥{{ calcThousands(item.price) }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <pagination-sp
        :hidden="total <= parameterInfo.limit"
        :total="total"
        :page.sync="parameterInfo.page"
        :limit.sync="parameterInfo.limit"
        :link="$route.fullPath"
        @pagination="productList()"
      />
    </div>
    <div v-else class="empty">
      <img class="empty-img" src="@/assets/images/sp/search.png">
      <div class="empty-desc">“{{ inputShow }}” に該当する製品はございません。</div>
      <div v-if="categoryData.length > 0">
        <div class="view-title">カテゴリー</div>
        <div class="search-classification">
          <router-link v-for="item in categoryData" :key="item.id" :to="`/category/${item.url}`">{{ item.name }}</router-link>
        </div>
      </div>
      <div v-if="initPopularityData.length > 0">
        <div class="view-title">人気商品</div>
        <div class="goods-list">
          <div v-for="item in initPopularityData" :key="item.id" class="goods-item">
            <router-link :to="'/product/'+ item.product_sn">
              <div class="img">
                <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
                <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="discount">{{ item.promotion[0].discount }}%OFF</div>
              </div>
            </router-link>
            <div class="label">
              <div
                v-for="(it,index) in item.color_tags"
                :key="index"
                :style="{background:it.color_value}"
                :class="{active:item.colorTagIndex === index}"
                @click="item.colorTagIndex === index ? item.colorTagIndex = '' : item.colorTagIndex = index"
              >
                <span />
              </div>
            </div>
            <div class="title-box">
              <div class="title">{{ item.title }}</div>
              <div class="price">
                <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
                  <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
                  <span class="original">¥{{ calcThousands(item.price) }}</span>
                </template>
                <template v-else>
                  ¥{{ calcThousands(item.price) }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { productList } from '@/api/product'
import { getCategoryList } from '@/api/category'
import { calcThousands, parameterCorrection } from '@/utils/validate'
import PaginationSp from '@/components/PaginationSp'
export default {
  components: { PaginationSp },
  data() {
    return {
      show: false,
      input: this.$route.query.input || '',
      inputShow: this.$route.query.input || '',
      initData: [],
      initPopularityData: [],
      categoryData: [],
      total: 0,
      parameterInfo: {
        page: parseInt(this.$route.query.page) || 1,
        limit: 20,
        sort: 'sort',
        include: 'tag,colorTags,promotion'
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.input = this.$route.query.input || ''
        this.parameterInfo.page = this.$route.query.page || 1
        this.parameterInfo.sort = this.$route.query.sort || ''
        this.productList()
      }
    }
  },
  created() {
    this.productPopularityList()
    this.productList()
    this.getCategoryList()
    window.addEventListener('click', this.handleClick)
  },
  destroyed() {
    window.removeEventListener('click', this.handleClick)
  },
  methods: {
    calcThousands,
    handleClick() {
      this.show = false
    },
    search(e) {
      this.parameterInfo.page = 1
      this.productList()
      this.$refs.search.blur()
      e.preventDefault()
    },
    // 商品列表
    productList() {
      this.$router.push({ query: { input: this.input }})
      let data = parameterCorrection({ search: this.input, is_on_sale: true })
      data = { ...data, ...this.parameterInfo }
      productList(data).then((result) => {
        result.data.forEach(item => {
          item.colorTagIndex = ''
        })
        this.inputShow = this.input
        this.initData = result.data
        this.total = result.total
        this.initData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
      })
    },
    productPopularityList() {
      const data = parameterCorrection({ is_on_sale: true, is_hot: true })
      productList({ ...data, type: 'all', ...{ include: 'promotion' }}).then((result) => {
        result.forEach(item => {
          item.colorTagIndex = ''
        })
        this.initPopularityData = result
        this.initPopularityData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
      })
    },
    getCategoryList() {
      getCategoryList({ type: 'all', 'filter[is_recommend]]': true, sort: 'sort' }).then(result => {
        this.categoryData = result.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.search-page{
  .tips{
    text-align: center;
    margin: 0.09rem 0 0;
  }
  .search{
      width: 7.02rem;
      height: 0.88rem;
      padding: 0 0 0 0.6rem;
      border: 0.02rem solid #333333;
      box-sizing: border-box;
      background: url('~@/assets/images/sp/shape.png') 0.2rem center / 0.28rem 0.28rem no-repeat;
      margin: 0.14rem 0.24rem 0.4rem;
  }
  .search::-webkit-input-placeholder{
    color: #999FA5
  }
  .screen{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.24rem;
    padding: 0 0 0.16rem;
    border-bottom: 0.02rem solid #333;
    margin: 0 0.24rem 0.24rem;
  }
  .screen-box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 0.8rem;
    font-size: 0.28rem;
    background: #F4F5FC;
    img{
      width: 0.32rem;
    }
  }
  .screen-box.show{
    color: #fff;
    background: #333;
  }
  .screen-item{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    line-height: 0.88rem;
    color: #333;
    box-sizing: border-box;
    border: 0.02rem solid #333;
    background: #fff;
    z-index: 10;
    div{
      padding: 0 0.22rem;
    }
    .current{
      background: #F4F5FC;
    }
  }
  .goods-box{
    margin-bottom: 1.6rem;
  }
  .goods-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0.24rem;
    .goods-item{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 3.44rem;
      margin: 0 0 0.44rem;
      .img{
        position: relative;
        img{
          flex-shrink: 0;
          width: 100%;
          height: 3.44rem;
          vertical-align: middle;
        }
        .discount{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1.1rem;
          height: .36rem;
          line-height: .36rem;
          text-align: center;
          font-size: .24rem;
          background-color: #c40a0a;
          color: #ffffff;
        }
      }
      .label{
        height: 100%;
        span{
          background: transparent;
        }
        div{
          display: inline-block;
          width: 0.48rem;
          height: 0.48rem;
          vertical-align: middle;
          box-sizing: border-box;
          border-radius: 50%;
          margin: 0.16rem 0.1rem 0 0;
        }
        .active{
          border: 0.02rem solid #333;
          span{
            display: block;
            width: 0.44rem;
            height: 0.44rem;
            border-radius: 50%;
            box-sizing: border-box;
            border: 0.04rem solid #fff;
          }
        }
      }
      .title-box{
        flex-shrink: 0;
      }
      .title{
        display: -webkit-box;
        height: 0.84rem;
        line-height: 0.42rem;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        margin: 0.24rem 0 0 0;
      }
      .price{
        margin: 0.16rem 0 0 0;
        .now{
          font-size: .28rem;
          color: #c40a0c;
          font-weight: bold;
        }
        .original{
          margin-left: .12rem;
          font-size: .24rem;
          color: #999999;
          text-decoration: line-through;
        }
      }
    }
  }
  .empty{
    .empty-img{
      display: block;
      width: 1.32rem;
      margin: 0.4rem auto 0.24rem;
    }
    .empty-desc{
      text-align: center;
      margin: 0.24rem 0 0.9rem;
    }
  }
  .view-title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.48rem;
    font-weight: bold;
    margin: 0.9rem 0 0.28rem;
    &::before,
    &::after{
      content: '';
      width: 0.8rem;
      height: 0.02rem;
      background: #333333;
      margin: 0 0.4rem;
    }
  }
  .search-classification{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0.28rem 0.24rem 0.9rem;
    a{
      width: 3.44rem;
      line-height: 0.96rem;
      padding: 0 0.24rem;
      box-sizing: border-box;
      border: 0.02rem solid #E8EBED;
      margin: 0 0 0.08rem;
    }
  }
}
</style>
