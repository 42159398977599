<template>
  <div>
    <div class="custom" v-html="customData.pc_content" />
    <div class="goods-list">
      <router-link v-for="item in initData" :key="item.id" :to="'/product/'+ item.product_sn" class="goods-item">
        <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
        <div class="discount">
          <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="value">{{ item.promotion[0].discount }}%OFF</div>
        </div>
        <div class="label" @mouseleave="item.colorTagIndex = ''">
          <div
            v-for="(it,index) in item.color_tags"
            :key="index"
            :style="{background:it.color_value}"
            :class="{active:item.colorTagIndex === index}"
            @mouseenter="item.colorTagIndex = index"
          >
            <span />
          </div>
        </div>
        <div class="title">
          <span v-if="item.is_hot" class="sale">SALE</span>
          <span v-if="item.is_new" class="new">NEW</span>
          <span>{{ item.title }}</span>
        </div>
        <div class="desc">{{ item.long_title }}</div>
        <div class="price">
          <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
            <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
            <span class="original">¥{{ calcThousands(item.price) }}</span>
          </template>
          <template v-else>
            ¥{{ calcThousands(item.price) }}
          </template>
        </div>
      </router-link>
      <div class="goods-item-seat" />
    </div>
    <pagination-pc
      :hidden="total <= parameterInfo.limit"
      :total="total"
      :page.sync="parameterInfo.page"
      :limit.sync="parameterInfo.limit"
      :link="'/new/product'"
      @pagination="fetchData"
    />
    <popularity />
    <special />
  </div>
</template>
<script>
import popularity from '@/views/pc/product/popularity'
import special from '@/views/pc/product/special'
import { productList } from '@/api/product'
import PaginationPc from '@/components/PaginationPc'
import { calcThousands, parameterCorrection } from '@/utils/validate'
import { getCustomPage } from '@/api/config'
export default {
  components: { popularity, special, PaginationPc },
  data() {
    return {
      initData: [],
      customData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        pc_content: null,
        pc_css_path: '',
        pc_js_path: ''
      },
      total: 0,
      parameterInfo: {
        page: parseInt(this.$route.query.page) || 1,
        limit: 21
      }
    }
  },
  metaInfo() {
    return {
      title: this.customData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.customData.seo_keywords },
        { vmid: 'description', name: 'description', content: this.customData.seo_description }
      ],
      link: [
        { vmid: this.customData.pc_css_path, rel: 'stylesheet', href: this.customData.pc_css_path }
      ],
      script: [
        { vmid: this.customData.pc_js_path, async: 'async', src: this.customData.pc_js_path }
      ]
    }
  },
  created() {
    this.fetchData()
    this.getCustomPage()
  },
  methods: {
    calcThousands,
    fetchData() {
      let data = parameterCorrection({ is_new: true, is_on_sale: true })
      data = { ...data, ...this.parameterInfo, include: 'tag,colorTags,promotion' }
      productList(data).then(result => {
        result.data.forEach(item => {
          item.colorTagIndex = ''
        })
        this.initData = result.data
        this.total = result.total
        this.initData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
      })
    },
    // 获取自定义内容
    getCustomPage() {
      getCustomPage('new_product').then(result => {
        result.pc_css_path = result.pc_css_path || ''
        result.pc_js_path = result.pc_js_path || ''
        this.customData = result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.goods-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1440px;
  margin: 0 auto;
}
.goods-item-seat{
  width: 464px;
}
.goods-item{
  width: 464px;
  margin: 32px 0 0 0;
  img{
    width: 100%;
    height: 464px;
  }
  .title{
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 12px 0 0 0;
  }
  .sale{
    font-weight: bold;
    color: #FECB35;
    margin-right: 8px;
  }
  .new{
    font-weight: bold;
    color: #F90412;
    margin-right: 8px;
  }
  .discount{
      width: 72px;
      height: 20px;
      margin-top: 8px;
      line-height: 20px;
      text-align: center;
      .value{
        color: #ffffff;
        background-color: #c40a0a;
      }
    }
  .label{
    margin-top: 8px;
    span{
      background: transparent;
    }
    div{
      display: inline-block;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 50%;
      margin-right: 4px;
    }
    .active{
      border: 1px solid #333;
      span{
        display: block;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid #fff;
      }
    }
  }
  .desc{
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    margin: 16px 0 0 0;
  }
  .price{
      margin-top: 20px;
      .now{
        font-size: 16px;
        font-weight: bold;
        color: #c40a0a;
      }
      .original{
        margin-left: 8px;
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;
      }
    }
}
</style>
