var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-content" },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/sp/logo_sp.png") },
        }),
      ]),
      _c("img", {
        staticClass: "step",
        attrs: { src: require("@/assets/images/sp/step1.png") },
      }),
      _c("div", { staticClass: "price-detail" }, [
        _c(
          "div",
          {
            staticClass: "flex",
            on: {
              click: function ($event) {
                _vm.detailShow = !_vm.detailShow
              },
            },
          },
          [
            _c("div", { staticClass: "name" }, [_vm._v("金額明細")]),
            _c("img", {
              staticClass: "icon",
              class: { flip: _vm.detailShow },
              attrs: { src: require("@/assets/images/sp/right_arrow1.png") },
            }),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.detailShow,
                expression: "detailShow",
              },
            ],
            staticClass: "content",
          },
          [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "name" }, [_vm._v("小計")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "￥" + _vm._s(_vm.calcThousands(_vm.priceParams.total_amount))
                ),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "name" }, [_vm._v("送料")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "￥" +
                    _vm._s(_vm.calcThousands(_vm.priceParams.freight_amount))
                ),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "name" }, [_vm._v("合計金額")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "￥" + _vm._s(_vm.calcThousands(_vm.priceParams.pay_amount))
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "div",
            { staticClass: "margin-box" },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "address.receiver_email", label: "連絡先" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "連絡先" },
                    model: {
                      value: _vm.ruleForm.address.receiver_email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm.address,
                          "receiver_email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.address.receiver_email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "delivery-content" }, [
            _c("div", { staticClass: "title" }, [_vm._v("配送方法")]),
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.delivery,
                        callback: function ($$v) {
                          _vm.delivery = $$v
                        },
                        expression: "delivery",
                      },
                    },
                    [_c("span", [_vm._v("通常出荷(玄関先まで)")])]
                  ),
                  _c("div", { staticClass: "price" }, [
                    _vm._v(
                      "¥" +
                        _vm._s(
                          _vm.calcThousands(_vm.priceParams.freight_amount)
                        )
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "detail" }, [
                _vm._v(
                  " 配送は玄関先までとなります。開梱や組立、梱包材の回収はございません。"
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  "発送準備が完了した商品から順番に出荷となります。オーダー家具は、お届けまでにお時間を頂戴します。出荷目安は各商品ページにご確認ください。 "
                ),
              ]),
            ]),
          ]),
          _c("div", { ref: "addressContent", staticClass: "address-content" }, [
            _c("div", { staticClass: "title" }, [_vm._v("お届け先の住所")]),
            _c(
              "div",
              { staticClass: "margin-box" },
              [
                _c(
                  "div",
                  { staticClass: "inline-item" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "address.first_name", label: "姓" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "姓" },
                          model: {
                            value: _vm.ruleForm.address.first_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.address,
                                "first_name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.address.first_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "address.last_name", label: "名" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "名" },
                          model: {
                            value: _vm.ruleForm.address.last_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.address,
                                "last_name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.address.last_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline-item" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "address.phone", label: "電話番号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "tel",
                            maxlength: "11",
                            placeholder: "電話番号",
                          },
                          model: {
                            value: _vm.ruleForm.address.phone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.address,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.address.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { prop: "address.zip_code", label: "郵便番号" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "tel",
                            maxlength: "7",
                            placeholder: "郵便番号",
                          },
                          on: { change: _vm.changeZipCode },
                          model: {
                            value: _vm.ruleForm.address.zip_code,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.address,
                                "zip_code",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.address.zip_code",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline-item" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "address.stats", label: "都道府県" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "都道府県" },
                            model: {
                              value: _vm.ruleForm.address.stats,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm.address, "stats", $$v)
                              },
                              expression: "ruleForm.address.stats",
                            },
                          },
                          _vm._l(_vm.areaData, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "address.city", label: "市区町村" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "市区町村" },
                          model: {
                            value: _vm.ruleForm.address.city,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.address,
                                "city",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.address.city",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "address.address", label: "番地住所" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "番地住所" },
                      model: {
                        value: _vm.ruleForm.address.address,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm.address,
                            "address",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.address.address",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "address.detail_address",
                      label: "建物名・部屋番号（任意）",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "建物名・部屋番号（任意）" },
                      model: {
                        value: _vm.ruleForm.address.detail_address,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm.address,
                            "detail_address",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.address.detail_address",
                      },
                    }),
                  ],
                  1
                ),
                _vm.token
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          },
                          [_vm._v("よく使う住所として登録")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { ref: "noteContent", staticClass: "note-content" }, [
            _c("div", { staticClass: "title" }, [_vm._v("備考欄")]),
            _c(
              "div",
              { staticClass: "margin-box" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "備考欄（任意）" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        placeholder: "備考欄（任意）",
                      },
                      model: {
                        value: _vm.ruleForm.note,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "note", $$v)
                        },
                        expression: "ruleForm.note",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "button-content" },
            [
              _c(
                "el-button",
                {
                  staticClass: "next-btn",
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.toPayment },
                },
                [_vm._v("支払い方法に進む")]
              ),
              _c("router-link", { attrs: { to: "/cart" } }, [
                _c("div", { staticClass: "prev-btn" }, [
                  _vm._v("カートに戻る"),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }