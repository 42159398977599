var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-question-list" },
    [
      _c(
        "router-link",
        {
          staticClass: "item",
          attrs: { to: "/guides/question?from=question" },
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/pc/common_question_1.png"),
              },
            }),
            _c("span", [_vm._v("ご注文について")]),
          ]),
          _c("div", { staticClass: "desc" }, [
            _c("div", [_vm._v("注文完了メールが届きません。")]),
            _c("div", [_vm._v("注文したらどのぐらいものは届くですか？")]),
          ]),
          _c("div", { staticClass: "tips" }, [
            _c("span", [_vm._v("全てを見る")]),
            _c("img", {
              attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
            }),
          ]),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "item", attrs: { to: "/guides/payment" } },
        [
          _c("div", { staticClass: "title" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/pc/common_question_9.png"),
              },
            }),
            _c("span", [_vm._v("お支払いについて")]),
          ]),
          _c("div", { staticClass: "desc" }, [
            _c("div", [_vm._v("代引きを使えますか？")]),
            _c("div", [_vm._v("支払い方法を教えてください。")]),
          ]),
          _c("div", { staticClass: "tips" }, [
            _c("span", [_vm._v("全てを見る")]),
            _c("img", {
              attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
            }),
          ]),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "item", attrs: { to: "/guides/delivery" } },
        [
          _c("div", { staticClass: "title" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/pc/common_question_3.png"),
              },
            }),
            _c("span", [_vm._v("配送·送料について")]),
          ]),
          _c("div", { staticClass: "desc" }, [
            _c("div", [_vm._v("送料を教えてください。")]),
            _c("div", [_vm._v("沖縄県（離島）までの運賃はいくらですか？")]),
          ]),
          _c("div", { staticClass: "tips" }, [
            _c("span", [_vm._v("全てを見る")]),
            _c("img", {
              attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
            }),
          ]),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "item", attrs: { to: "/guides/install" } },
        [
          _c("div", { staticClass: "title" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/pc/common_question_8.png"),
              },
            }),
            _c("span", [_vm._v("搬入・組立について")]),
          ]),
          _c("div", { staticClass: "desc" }, [
            _c("div", [_vm._v("部屋まで搬入できますか？")]),
            _c("div", [_vm._v("組立をしてくれますか？")]),
          ]),
          _c("div", { staticClass: "tips" }, [
            _c("span", [_vm._v("全てを見る")]),
            _c("img", {
              attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
            }),
          ]),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "item", attrs: { to: "/guides/return" } },
        [
          _c("div", { staticClass: "title" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/pc/common_question_7.png"),
              },
            }),
            _c("span", [_vm._v("返品について")]),
          ]),
          _c("div", { staticClass: "desc" }, [
            _c("div", [_vm._v("イメージと違いますが、返品したい。")]),
            _c("div", [_vm._v("クーリングオフで返品したい")]),
          ]),
          _c("div", { staticClass: "tips" }, [
            _c("span", [_vm._v("全てを見る")]),
            _c("img", {
              attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
            }),
          ]),
        ]
      ),
      _c("div", { staticClass: "item-seat" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }