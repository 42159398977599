<template>
  <div class="screen">
    <div class="screen-title">検索</div>
    <div class="input">
      <input v-model="title" type="text" placeholder="search here…" @keyup.enter="screen()">
      <img src="@/assets/images/pc/article_screen.png" @click="screen()">
    </div>
    <div v-if="keywordsData.length>0" class="keywords">
      <div
        v-for="item in keywordsData"
        :key="item.id"
        @click="keywordsChange(item.name)"
      >{{ item.name }}</div>
    </div>
    <div class="category">
      <div class="title">コンテンツ</div>
      <div
        v-for="item in categoriesData"
        :key="item.id"
        class="item"
        @click="changeCategory(item.id)"
      >{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
import { keywords } from '@/api/config'
import { articleCategories } from '@/api/article'
import { parameterCorrection } from '@/utils/validate'
export default {
  data() {
    return {
      title: this.$route.query.title || '',
      keywordsData: [],
      categoriesData: []
    }
  },
  created() {
    this.articleCategories()
    this.keywords()
  },
  methods: {
    articleCategories() {
      articleCategories().then(result => {
        this.categoriesData = result.data
      })
    },
    // 关键词
    keywords() {
      const data = parameterCorrection({ type: 2 })
      keywords({ ...data, type: 'all' }).then(result => {
        this.keywordsData = result
      })
    },
    screen() {
      this.$router.push({ path: '/article', query: { page: 1, title: this.title }})
    },
    keywordsChange(title) {
      this.title = title
      this.$router.push({ path: '/article', query: { page: 1, title: this.title }})
    },
    changeCategory(id) {
      this.title = ''
      this.$router.push({ path: '/article', query: { page: 1, article_category_id: id }})
    }
  }
}
</script>
<style lang="scss" scoped>
.screen{
  width: 280px;
}
.screen-title{
  font-size: 20px;
  font-weight: bold;
  padding: 0 0 20px;
}
.input{
  position: relative;
  input{
    width: 100%;
    height: 60px;
    padding: 0 50px 0 24px;
    border: 1px solid #e8ebed;
    box-sizing: border-box;
    border-radius: 4px;
  }
  img{
    position: absolute;
    top: 18px;
    right: 14px;
    cursor: pointer;
  }
}
.keywords{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  div{
    height: 36px;
    line-height: 38px;
    padding: 0 12px;
    border: 1px solid #707070;
    margin: 12px 12px 0 0;
    cursor: pointer;
  }
}
.category{
  border: 1px solid #e8ebed;
  margin-top: 40px;
  .title{
    line-height: 60px;
    font-size: 20px;
    font-weight: bold;
    padding: 0 16px;
    border-bottom: 2px solid #333;
  }
  .item{
    margin: 15px 24px;
    cursor: pointer;
  }
}
</style>
