<template>
  <div>
    <h1 v-if="config.h1" class="notice">{{ config.h1 }}</h1>
    <div ref="header" class="nav-box">
      <div class="nav-fixed" :class="{'nav-fixed-current':fixed || show}">
        <div v-if="show" class="img-box" @click="show = false">
          <div class="image"><img src="@/assets/images/sp/header_close.png"></div>
        </div>
        <div v-else class="img-box">
          <div class="image">
            <span @click="show = true">
              <img v-show="!fixed" src="@/assets/images/sp/header_menu.png">
              <img v-show="fixed" src="@/assets/images/sp/header_menu_current.png">
            </span>
            <router-link v-show="!fixed && !show" to="/" class="logo">
              <img width="100%" height="100%" src="@/assets/images/sp/logo_sp.png">
            </router-link>
          </div>
        </div>
        <form v-show="fixed || show" action="javascript:return false" @submit="search">
          <input ref="search" v-model="input" class="search" type="search" placeholder="キーワードで検索">
        </form>

        <div class="img-box">
          <div class="image">
            <router-link to="/search">
              <img v-show="!fixed && !show" src="@/assets/images/sp/header_search.png">
            </router-link>
            <router-link to="/cart">
              <img v-show="!fixed && !show" src="@/assets/images/sp/header_cart.png">
              <img v-show="fixed || show" src="@/assets/images/sp/header_cart_fixed.png">
            </router-link>
          </div>
          <div class="num">{{ cartNumber }}</div>
        </div>
      </div>
    </div>
    <side :show.sync="show" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import side from './side'
export default {
  components: { side },
  data() {
    return {
      fixed: false,
      show: false,
      input: ''
    }
  },
  computed: {
    ...mapGetters([
      'config',
      'cartNumber'
    ])
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.show = false
      }
    },
    'config.h1': function() {
      this.$nextTick(() => {
        this.handleScroll()
      })
    }
  },
  created() {
    this.$store.dispatch('user/getCartNumber')
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // 获取滚动时的高度
      const top = this.$refs.header.getBoundingClientRect().top
      if (top <= 0) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
    search(e) {
      this.$router.push({ path: '/search', query: { input: this.input }})
      this.input = ''
      this.$refs.search.blur()
      e.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-box{
  .search{
    width: 5.26rem;
    height: .88rem;
    padding: 0 0 0 0.6rem;
    font-size: .28rem;
    color: #333333;
    background: url('~@/assets/images/sp/shape.png') 0.2rem center / 0.28rem 0.28rem no-repeat #ffffff;
    border-radius: .04rem;
    border: 0;
  }
}
.notice{
  color: #fff;
  font-size: 0.28rem;
  text-align: center;
  font-weight: lighter;
  padding: 0.2rem;
  background: #292E32;
}
.nav-box{
  height: 1.2rem;
  color: #333;
}
.nav-fixed{
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1.2rem;
  font-size: 0.46rem;
  background: #fff;
  padding: 0 0.24rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #F4F5FC;
  z-index: 100;
}
.nav-fixed-current{
  position: fixed;
  color: #fff;
  background: #292E32;
}
.logo{
  display: flex;
  align-items: center;
    img{
      width: 3.75rem !important;
      height: 0.5rem !important;
    }
}
.img-box{
  position: relative;
  .image{
    display: flex;
    align-content: center;
  }
  img{
    width: .88rem;
    height: .88rem;
  }
}
.num{
  position: absolute;
  top: 0.1rem;
  right: -0.1rem;
  color: #fff;
  font-size: 0.24rem;
  line-height: 0.32rem;
  text-align: center;
  padding: 0 0.16rem;
  border-radius: 0.32rem;
  background: #292E32;
}
.nav-fixed-current .num{
  color: #333;
  background: #fff;
}
</style>
