var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.initData.pc_content) } }),
    _vm.initData.products.length > 0
      ? _c("div", { staticClass: "product-content" }, [
          _c("div", { staticClass: "en-title" }, [_vm._v("Related Products")]),
          _c("div", { staticClass: "ja-title" }, [_vm._v("関連商品")]),
          _c(
            "div",
            { staticClass: "goods-list" },
            [
              _vm._l(_vm.initData.products, function (item) {
                return _c(
                  "router-link",
                  {
                    key: item.id,
                    staticClass: "goods-item",
                    attrs: { to: "/product/" + item.product_sn },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          item.colorTagIndex === ""
                            ? item.image
                            : item.color_tags[item.colorTagIndex].pivot.image,
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "label",
                        on: {
                          mouseleave: function ($event) {
                            item.colorTagIndex = ""
                          },
                        },
                      },
                      _vm._l(item.color_tags, function (it, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: { active: item.colorTagIndex === index },
                            style: { background: it.color_value },
                            on: {
                              mouseenter: function ($event) {
                                item.colorTagIndex = index
                              },
                            },
                          },
                          [_c("span")]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v(_vm._s(item.title))]),
                      item.is_hot
                        ? _c("span", { staticClass: "sale" }, [_vm._v("SALE")])
                        : _vm._e(),
                      item.is_new
                        ? _c("span", { staticClass: "new" }, [_vm._v("NEW")])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "desc" }, [
                      _vm._v(_vm._s(item.long_title)),
                    ]),
                    _c("div", { staticClass: "price" }, [
                      _vm._v(
                        "¥" + _vm._s(_vm.calcThousands(item.price)) + "円"
                      ),
                    ]),
                  ]
                )
              }),
              _c("div", { staticClass: "goods-item-seat" }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm.initData.articles.length > 0
      ? _c("div", { staticClass: "article-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("NEWS")]),
          _c(
            "div",
            { staticClass: "lists-content" },
            _vm._l(_vm.initData.articles, function (item) {
              return _c(
                "router-link",
                {
                  key: item.id,
                  staticClass: "item",
                  attrs: { to: "/article/" + item.id },
                },
                [
                  _c("img", { attrs: { src: item.image } }),
                  _c("div", { staticClass: "article-desc" }, [
                    _c("div", [_vm._v(_vm._s(item.title))]),
                    _c("div", [_vm._v(_vm._s(item.description))]),
                    _c("div", [
                      _vm._v(_vm._s(item.created_at.substring(0, 10))),
                    ]),
                  ]),
                ]
              )
            }),
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }