var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-header" },
    [
      _c("h1", [
        _vm._v("家具通販・おしゃれなインテリア専門店（ウッドライフ）"),
      ]),
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/pc/logo.png"), alt: "" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }