import Vue from 'vue'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import '@/styles/index.scss' // global css
import '@/utils/elenent-ui.js' // elenent-ui
import '@/utils/vant.js' // vant
import './styles/element-variables.scss'

import '@/assets/js/swiper-bundle.min.js' // swiper 插件
import '@/assets/css/swiper-bundle.min.css'

import '@/assets/js/lazysizes.min.js'

import App from './App'
import store from './store'
import router from './router'
import * as Sentry from '@sentry/vue'

import '@/icons' // icon
import '@/router/permission' // permission control

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_JS_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

Vue.config.productionTip = false
const vue = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
export default vue
