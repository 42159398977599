<template>
  <div class="side-box" :style="{right : show ? '0': '100%'}">
    <router-link v-if="username" class="login" to="/personal/mine">
      <span><b>{{ username }} 様</b>／マイページ</span>
      <img src="@/assets/images/sp/article_arrow_black.png">
    </router-link>
    <router-link v-else class="login" to="/login">
      <span>ログイン ／ 新規会員登録</span>
      <img src="@/assets/images/sp/article_arrow_black.png">
    </router-link>
    <div class="side-title">CATEGORY</div>
    <!-- 侧边分类固定 -->
    <div class="category-list">
      <div v-for="item in categoryData" :key="item.id">
        <div class="guide-box" @click="item.is_show = !item.is_show">
          <span>{{ item.name }}</span>
          <img v-if="item.is_show" src="@/assets/images/sp/article_hide_black.png">
          <img v-else src="@/assets/images/sp/article_open_black.png">
        </div>
        <el-collapse-transition>
          <div v-show="item.is_show" class="guide category-guide">
            <router-link v-for="item2 in item.children" :key="item2.id" :to="`/category/${item2.url}`">
              <span class="name">{{ item2.name }}</span>
              <img src="@/assets/images/sp/article_arrow_black.png">
            </router-link>
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <form action="javascript:return false" @submit="search">
      <input ref="search" v-model="input" class="search" type="search" placeholder="キーワードで検索">
    </form>
    <div class="side-title">CONTENT</div>
    <div class="article-list">
      <router-link to="/new/product">
        <span>新着商品 ／ 特集</span>
        <img src="@/assets/images/sp/article_arrow_black.png">
      </router-link>
      <router-link to="/article">
        <span>NEWS ／ お知らせ</span>
        <img src="@/assets/images/sp/article_arrow_black.png">
      </router-link>
      <div class="guide-box" @click="guide = !guide">
        <span>ご利用ガイド</span>
        <img v-if="guide" src="@/assets/images/sp/article_hide_black.png">
        <img v-else src="@/assets/images/sp/article_open_black.png">
      </div>
      <el-collapse-transition>
        <div v-show="guide" class="guide">
          <router-link to="/guides/category">
            <span>ご利用ガイド</span>
            <img src="@/assets/images/sp/article_arrow_black.png">
          </router-link>
          <router-link to="/question">
            <span>よくあるご質問</span>
            <img src="@/assets/images/sp/article_arrow_black.png">
          </router-link>
          <router-link to="/guides/delivery">
            <span>配送・送料</span>
            <img src="@/assets/images/sp/article_arrow_black.png">
          </router-link>
          <router-link to="/company">
            <span>企業情報</span>
            <img src="@/assets/images/sp/article_arrow_black.png">
          </router-link>
        </div>
      </el-collapse-transition>
      <router-link to="/tokutei">
        <span>特定商取引法に基づく表示</span>
        <img src="@/assets/images/sp/article_arrow_black.png">
      </router-link>
    </div>
    <div class="footer">
      <div class="footer-date">営業時間 平時 9:30~18:30 （土・日を除く）</div>
      <div class="footer-box">© HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED.</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getCategoryTree } from '@/api/category'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      input: '',
      guide: false,
      categoryData: []
    }
  },
  computed: {
    ...mapGetters([
      'username',
      'token'
    ])
  },
  watch: {
    show: function() {
      if (this.show) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },
  created() {
    // 侧边分类固定
    getCategoryTree({ platform: 2 }).then(result => {
      this.categoryData = result
      this.categoryData.forEach(item => {
        this.$set(item, 'is_show', false)
      })
    })
    if (this.token) {
      this.$store.dispatch('user/getUserDetail')
    }
  },
  methods: {
    search(e) {
      this.$router.push({ path: '/search', query: { input: this.input, page: 1 }})
      this.input = ''
      this.$emit('update:show', false)
      this.$refs.search.blur()
      e.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.side-box{
    position: fixed;
    top: 1.2rem;
    right: 0;
    width: 100%;
    height: calc(100% - 1.2rem);
    font-size: 0.28rem;
    background: #fff;
    z-index: 10000;
    overflow: auto;
    transition: right 0.3s;
}
.login{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.96rem;
    padding: 0 0.24rem;
    background: #F4F5FC;
    img{
        width: 0.24rem;
        height: 0.24rem;
    }
}
.side-title{
    font-size: 0.36rem;
    font-weight: bold;
    margin: 0.48rem 0.24rem 0.2rem;
}
.classification{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0.24rem;
    a{
        display: flex;
        align-items: center;
        width: 3.2rem;
        padding: 0.1rem;
        border: 0.02rem solid #E8EBED;
        margin-bottom: 0.14rem;
    }
    img{
        width: 1.2rem;
        margin-right: 0.24rem;
    }
    .english{
        font-size: 0.32rem;
    }
    div{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.search{
    width: 7.02rem;
    height: 0.88rem;
    padding: 0 0 0 0.6rem;
    border: 0.02rem solid #333333;
    box-sizing: border-box;
    background: url('~@/assets/images/sp/shape.png') 0.2rem center / 0.28rem 0.28rem no-repeat;
    margin: 0.34rem 0.24rem 0;
}
.search::-webkit-input-placeholder{
  color: #999FA5
}
.article-list,.category-list{
    border-top: 0.02rem solid #E8EBED;
    margin: 0 0.24rem;
    .guide-box,a{
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 0.96rem;
        padding: 0 .24rem;
        box-sizing: border-box;
        border-bottom: 0.02rem solid #E8EBED;

        .name{
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          // width: 2rem;

        }
    }
    img{
        width: 0.24rem;
        height: 0.24rem;
    }
    .guide{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: #F8F9FD;
        a{
            width: 50%;
        }
        a:nth-child(2n){
            border-left: 0.02rem solid #E8EBED;
        }
    }
    .category-guide{
      font-size: .24rem;
    }
}
.footer{
    position: sticky;
    top: 100%;
    .footer-date{
        color: #666;
        line-height: 0.84rem;
        padding: 0 0.24rem;
    }
    .footer-box{
        font-size: 0.24rem;
        text-align: center;
        color: #fff;
        line-height: 0.56rem;
        background: #292E32;
    }
}
</style>
