<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/guides/category">ご利用ガイド</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>ご注文について</span>
    </div>
    <div class="list-content">
      <div class="list">
        <div class="title">01 ご注文の流れについて</div>
        <div class="text-content">
          (1) 商品ページで、必須項目をご選択すると自動的にサイズに応じた金額が表示されます。<br><br>(2) 希望の商品を選んで、「カートに入れる」ボタンより商品をショッピングカートに入れてください。
        </div>
        <div class="red-bg">購入手続きは会員登録が必要です。会員ではない方は「新規ユーザー登録」からご登録ください。</div>
        <div class="text-content">
          (3) 会員ログインしてご購入手続きへお進みください。<br><br>
          (4) 配送方法とお届け先情報をご入力、ご選択ください。全ての項目のご入力が完了しましたら、「支払方法に進む」ボタンをクリックしてください。<br><br>
          (5) 希望の支払方法を選びください。必須項目をご入力が完了しましたら、「ご注文確定する」ボタンをクリックください。<br><br>
          (6) 「ご注文を確定前のお願い」をよくご理解の上、同意する/同意しないをご選択ください。 <br>
          <p class="p-left">※【同意しない】をご選択した場合、商品購入はできませんので、ご了承ください。<br><br></p>
          (7) 注文が正しく完了すると、画面にご注文番号が表示されます。ご入力したメールアドレスに「ご注文内容確認メール」が自動配信されます。
        </div>
        <div class="red-bg">弊社からのメールが届かない場合は早めにお問い合せください。</div>
      </div>
      <div class="list">
        <div class="title">02 お取引の流れについて</div>
        <img src="@/assets/images/pc/guides_order_img.png" alt="">
      </div>
      <div class="list">
        <div class="title">03 ご注文後のキャンセル・内容変更について</div>
        <div class="text-content">当店の無垢材家具は、お客様からご注文を頂いてから製作する取り寄せの為、商品ご注文後の返品・返金・キャンセルは原則としてお受けしておりません。</div>
        <div class="red-bg">ご注文後のキャンセル・内容変更（追加・変更）はできません。ご了承の上、ご購入をお願いいたします。</div>
      </div>
      <div class="list">
        <div class="title">04 ご注文後に「ご注文内容確認メール」が届かない場合</div>
        <div class="text-content">
          お客様ご登録のアドレスの不備・受信拒否設定・自動振分設定などが原因で、自動送信メールが届いていない場合がございます。<br>上記のご確認及びご入力いただいたメールアドレスが正しいかのご確認をお願い致します。<br><br>
          またご利用のメールサービスによっては、弊社からのメール（@woodlife.jp）が、迷惑メールへ分類されている可能性や受信拒否設定がされている可能性があります。<br>メールが届かない場合は、迷惑メールフォルダの確認およびドメイン設定をご確認ください。<br><br>
          Amazon Payをご利用いただいたお客様はAmazon様にご登録いただいているメールアドレスにメールが送信されていますので、ご確認お願いいたします。
        </div>
      </div>
      <div class="list">
        <div class="title">05 商品の在庫について</div>
        <div class="text-content">
          当サイトに掲載している大型家具商品は、複数店舗で同時に販売しております。その為、同一商品へご注文が集中した場合など、他店舗にて完売してしまい欠品してしまう場合がございます。<br><br>
          在庫管理は、できる限りリアルタイムな更新を心がけておりますが、万一欠品の際はご了承下さいませ。メーカー取り寄せになる商品につきましては、在庫の有無が判明するまでに数日かかる場合がございます。<br><br>
          欠品の場合は、その旨をメールにてご連絡させていただき、勝手ながらキャンセルさせていただきます。誠に恐れ入りますが、あらかじめご了承のほどお願い申し上げます。<br><br>
          ※お支払いが完了した後においても、商品を受注数分ご用意することが出来ず、ご注文をキャンセルさせて頂く場合がございます。その場合は、規約に従い返金いたします。<br><br>
          欠品の商品ページに表示される「メールで入荷通知を受け取る」につきまして、登録をされた方に対して入荷をお約束するものではありません。入荷すること無く、お知らせメールが送信されない場合がありますのでご承知ください。
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">
        <span>他のご質問はこちら</span>
        <img src="@/assets/images/pc/button_arrow.png">
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.guides-content{
    width: 1440px;
    margin: 0 auto 80px;
    font-size: 16px;
    .breadcrumb{
        margin-top: 28px;
    }
    .list-content{
        .list{
            padding: 38px 32px 0;
            margin-top: 45px;
            border-top: 1px solid #e8ebed;
            &:last-child{
                padding-bottom: 45px;
                border-bottom: 1px solid #e8ebed;
            }
            .title{
                font-size: 20px;
                font-weight: 700;
            }
            img{
              margin-top: 30px;
            }
            .text-content{
                margin-top: 24px;
                line-height: 24px;
                .p-left{
                  padding-left: 24px;
                }
            }
            .red-bg{
                width: 100%;
                height: 48px;
                padding-left: 24px;
                box-sizing: border-box;
                margin-top: 20px;
                line-height: 48px;
                font-weight: 700;
                color: #c40a0a;
                background: #fcf0f0;
            }
        }
    }
    .button{
      width: 248px;
      height: 48px;
      margin: 52px auto 0;
      line-height: 48px;
      text-align: center;
      background-color: #333;
      color: #fff;
      cursor: pointer;
      img{
          margin-left: 4px;
      }
    }
}
</style>
