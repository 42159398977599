<template>
  <div>
    <div v-html="initData.pc_content" />
    <div v-if="initData.products.length > 0" class="product-content">
      <div class="en-title">Related Products</div>
      <div class="ja-title">関連商品</div>
      <div class="goods-list">
        <router-link v-for="item in initData.products" :key="item.id" :to="'/product/'+ item.product_sn" class="goods-item">
          <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
          <div class="label" @mouseleave="item.colorTagIndex = ''">
            <div
              v-for="(it,index) in item.color_tags"
              :key="index"
              :style="{background:it.color_value}"
              :class="{active:item.colorTagIndex === index}"
              @mouseenter="item.colorTagIndex = index"
            >
              <span />
            </div>
          </div>
          <div class="title">
            <span>{{ item.title }}</span>
            <span v-if="item.is_hot" class="sale">SALE</span>
            <span v-if="item.is_new" class="new">NEW</span>
          </div>
          <div class="desc">{{ item.long_title }}</div>
          <div class="price">¥{{ calcThousands(item.price) }}円</div>
        </router-link>
        <div class="goods-item-seat" />
      </div>
    </div>
    <div v-if="initData.articles.length > 0" class="article-content">
      <div class="title">NEWS</div>
      <div class="lists-content">
        <router-link v-for="item in initData.articles" :key="item.id" :to="`/article/${item.id}`" class="item">
          <img :src="item.image">
          <div class="article-desc">
            <div>{{ item.title }}</div>
            <div>{{ item.description }}</div>
            <div>{{ item.created_at.substring(0,10) }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { getCustomActivityDetail } from '@/api/activity'
import { calcThousands } from '@/utils/validate'
export default {
  data() {
    return {
      url: this.$route.params.url,
      initData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        pc_content: null,
        pc_css_path: '',
        pc_js_path: '',
        products: [],
        articles: []
      },
      calcThousands
    }
  },
  metaInfo() {
    return {
      title: this.initData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.initData.seo_keyword },
        { vmid: 'description', name: 'description', content: this.initData.seo_description }
      ],
      link: [
        { vmid: this.initData.pc_css_path, rel: 'stylesheet', href: this.initData.pc_css_path }
      ],
      script: [
        { vmid: this.initData.pc_js_path, defer: 'defer', src: this.initData.pc_js_path }
      ]
    }
  },
  mounted() {
    this.getCustomActivityDetail()
  },
  methods: {
    getCustomActivityDetail() {
      getCustomActivityDetail(this.url, { include: 'products.colorTags,articles' }).then(result => {
        result.products.forEach(item => {
          item.colorTagIndex = ''
        })
        this.initData = result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.product-content {
    width: 1440px;
    margin: 0 auto 80px;

    .en-title {
        font-size: 60px;
        text-align: center;
    }

    .ja-title {
        width: 284px;
        margin: 18px auto 0;
        padding-top: 18px;
        border-top: 2px solid #b9b5b2;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .goods-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .goods-item-seat {
        width: 464px;
    }

    .goods-item {
        width: 464px;
        margin: 32px 0 0 0;

        img {
            width: 100%;
            height: 464px;
        }

        .title {
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 12px 0 0 0;
            font-weight: 700;
        }

        .sale {
            font-weight: bold;
            color: #FECB35;
            margin-left: 12px;
        }

        .new {
            font-weight: bold;
            color: #F90412;
            margin-left: 12px;
        }

        .label {
            margin-top: 8px;

            span {
                background: transparent;
            }

            div {
                display: inline-block;
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                border-radius: 50%;
                margin-right: 4px;
            }

            .active {
                border: 1px solid #333;

                span {
                    display: block;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 2px solid #fff;
                }
            }
        }

        .desc {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin: 16px 0 0 0;
            font-weight: 700;
        }

        .price {
            font-size: 16px;
            font-weight: bold;
            margin: 20px 0 0 0;
        }
    }
}

.article-content {
    width: 1440px;
    padding-top: 180px;
    margin: 180px auto 80px;
    border-top: 2px solid #B9B5B2;

    .title {
        font-size: 24px;
        font-weight: 700;
    }

    .lists-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;

        .item {
            display: flex;
            justify-content: space-between;
            width: 680px;
            margin-bottom: 28px;

            img {
                width: 250px;
                height: 166px;
            }

            .article-desc {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 414px;

                :nth-child(1) {
                    flex-shrink: 0;
                    font-size: 20px;
                    font-weight: bold;
                }

                :nth-child(2) {
                    height: 100%;
                    font-size: 16px;
                    color: #666;
                    text-align: justify;
                    margin: 10px 0;
                }

                :nth-child(3) {
                    flex-shrink: 0;
                    font-size: 14px;
                    color: #999;
                }
            }

            &:hover .article-desc div:nth-child(1) {
                text-decoration: underline;
            }
        }
    }
}
</style>
