import { render, staticRenderFns } from "./point.vue?vue&type=template&id=f6d3b26e&scoped=true"
import script from "./point.vue?vue&type=script&lang=js"
export * from "./point.vue?vue&type=script&lang=js"
import style0 from "./point.vue?vue&type=style&index=0&id=f6d3b26e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6d3b26e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/test.woodlife.jp/releases/56/vue-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f6d3b26e')) {
      api.createRecord('f6d3b26e', component.options)
    } else {
      api.reload('f6d3b26e', component.options)
    }
    module.hot.accept("./point.vue?vue&type=template&id=f6d3b26e&scoped=true", function () {
      api.rerender('f6d3b26e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sp/personal/point.vue"
export default component.exports