<template>
  <div class="pagination-container">
    <el-pagination
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :page-sizes.sync="pageSizes"
      :layout="layout"
      :total="total"
      v-bind="$attrs"
      @size-change="handleCurrentChange"
      @current-change="handleCurrentChange"
    >
      <div class="pager">
        <a v-if="pageCount > 0" :class="{'disabled':page===1}" :href="Jump(1)" @click="event=>handleSizeChange(event,1)">
          <span>1</span>
        </a>
        <span v-if="showPrevMore">···</span>
        <a v-for="item in pagers" :key="item" :class="{'disabled':page===item}" :href="Jump(item)" @click="event=>handleSizeChange(event,item)">
          <span>{{ item }}</span>
        </a>
        <span v-if="showNextMore">···</span>
        <a v-if="pageCount > 1" :class="{'disabled':page===pageCount}" :href="Jump(pageCount)" @click="event=>handleSizeChange(event,pageCount)">
          <span>{{ pageCount }}</span>
        </a>
      </div>
    </el-pagination>
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scrollTo'

export default {
  name: 'Pagination',
  props: {
    link: { // 路由链接
      required: true,
      type: String,
      default: ''
    },
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [20, 30, 40, 50]
      }
    },
    layout: {
      type: String,
      default: 'prev, slot, next'
    },
    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showPrevMore: false,
      showNextMore: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    },
    pageCount() {
      return Math.ceil(this.total / this.pageSize)
    },
    pagers() {
      const pagerCount = 5
      const halfPagerCount = (pagerCount - 1) / 2

      const currentPage = Number(this.currentPage)
      const pageCount = this.pageCount

      let showPrevMore = false
      let showNextMore = false

      if (pageCount > pagerCount) {
        if (currentPage > pagerCount - halfPagerCount) {
          showPrevMore = true
        }

        if (currentPage < pageCount - halfPagerCount) {
          showNextMore = true
        }
      }

      const array = []

      if (showPrevMore && !showNextMore) {
        const startPage = pageCount - (pagerCount - 2)
        for (let i = startPage; i < pageCount; i++) {
          array.push(i)
        }
      } else if (!showPrevMore && showNextMore) {
        for (let i = 2; i < pagerCount; i++) {
          array.push(i)
        }
      } else if (showPrevMore && showNextMore) {
        const offset = Math.floor(pagerCount / 2) - 1
        for (let i = currentPage - offset; i <= currentPage + offset; i++) {
          array.push(i)
        }
      } else {
        for (let i = 2; i < pageCount; i++) {
          array.push(i)
        }
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.showPrevMore = showPrevMore
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.showNextMore = showNextMore
      return array
    }
  },
  methods: {
    Jump(page) {
      const path = this.link + '?page=' + page
      return path
    },
    handleSizeChange(event, page) {
      event.stopPropagation()
      event.preventDefault()
      this.currentPage = page
      this.$emit('pagination')
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination')
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
    .el-pagination{
    display: flex;
    justify-content: center;
    padding: 0.4rem 0;
    background-color: #ffffff;
  }
  .btn-prev,
  .btn-next{
    width: 0.6rem;
    height: 0.6rem;
    color: #333 !important;
    line-height: 0.6rem;
    text-align: center;
    min-width: auto;
    padding: 0 !important;
    border-radius: 0.06rem;
    margin: 0 0.08rem !important;
    .el-icon{
      font-size: 0.3rem;
    }
  }
  .btn-prev:hover,
  .btn-next:hover{
    color: inherit;
  }
}
.pager{
  color: #666666;
  font-weight: 400;
  text-align: center;
}
.pager span{
  display: inline-block !important;
  width: 0.6rem !important;
  height: 0.6rem !important;
  font-size: 0.24rem !important;
  color: #666666 !important;
  line-height: 0.6rem !important;
  text-align: center;
  min-width: auto !important;
  border-radius: 0.06rem;
  margin: 0 0.08rem;
}
.disabled span{
  color: #fff !important;
  background-color: #292A2E;
}
</style>
