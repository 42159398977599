var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-content" },
    [
      _c("PaymentHeader"),
      _vm._m(0),
      _c("div", { staticClass: "shop-content" }, [
        _c(
          "div",
          { staticClass: "left-content" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("ご注文ありがとうございます！"),
            ]),
            _c("div", { staticClass: "order-sn" }, [
              _vm._v(" ご注文番号："),
              _c("span", [
                _vm._v(_vm._s(_vm.initData.order_sn)),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/order_sn_bg.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "message-content" }, [
              _c("div", { staticClass: "list" }, [
                _c("div", { staticClass: "text" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("連絡先")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.initData.receiver_email)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "list" }, [
                _c("div", { staticClass: "text" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("配送先")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.initData.receiver_name.replace(" ", "")) +
                        " 様"
                    ),
                    _c("br"),
                    _vm._v(
                      " 〒" +
                        _vm._s(_vm.initData.zip_code) +
                        " " +
                        _vm._s(_vm.initData.stats) +
                        _vm._s(_vm.initData.city) +
                        _vm._s(_vm.initData.address) +
                        _vm._s(
                          _vm.initData.detail_address
                            ? _vm.initData.detail_address
                            : ""
                        )
                    ),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.initData.receiver_phone) + " "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "list" }, [
                _vm._m(1),
                _c("div", { staticClass: "price" }, [
                  _vm._v(
                    "¥" + _vm._s(_vm.calcThousands(_vm.initData.freight_amount))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "list" }, [
                _c("div", { staticClass: "text" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("備考欄")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.initData.note)),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "list",
                  class: _vm.initData.pay_type === 2 ? "block" : "",
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "text" }, [
                      _c("div", { staticClass: "name" }, [_vm._v("支払方法")]),
                      _c("div", { staticClass: "value" }, [
                        _vm.initData.pay_type === 2
                          ? _c("span", [_vm._v("銀行振込・前払い")])
                          : _vm.initData.pay_type === 3
                          ? _c("span", [_vm._v("クレジットカード決済")])
                          : _vm.initData.pay_type === 4
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/pc/amazon.png"),
                              },
                            })
                          : _vm.initData.pay_type === 8
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/pc/paypay.png"),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "price" }, [
                      _vm._v(
                        " ¥" +
                          _vm._s(
                            _vm.calcThousands(Number(_vm.initData.pay_amount))
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _vm.initData.pay_type === 2
                    ? _c("div", { staticClass: "payment-desc" }, [
                        _vm._m(2),
                        _vm._m(3),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
            _c("div", { staticClass: "text1" }, [
              _vm._v("発送準備が完了した商品から順番に出荷となります。"),
            ]),
            _vm._m(4),
            _c("router-link", { staticClass: "button", attrs: { to: "/" } }, [
              _vm._v("トップページ"),
            ]),
          ],
          1
        ),
        _vm._m(5),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-content" }, [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step1_border.png") },
          }),
          _c("div", [_vm._v("カート")]),
        ]),
        _c("img", {
          staticClass: "step-line1",
          attrs: { src: require("@/assets/images/pc/step_line1_in.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step2.png") },
          }),
          _c("div", [_vm._v("配送")]),
        ]),
        _c("img", {
          staticClass: "step-line2",
          attrs: { src: require("@/assets/images/pc/step_line2_in.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step3_border.png") },
          }),
          _c("div", [_vm._v("支払い")]),
        ]),
        _c("img", {
          staticClass: "step-line1",
          attrs: { src: require("@/assets/images/pc/step_line1_in.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step4_in.png") },
          }),
          _c("div", [_vm._v("注文完了")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "name" }, [_vm._v("配送方法")]),
      _c("div", { staticClass: "value" }, [_vm._v("通常出荷")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" ご注文後7営業日以内お振込みくださいませ。"),
      _c("br"),
      _vm._v(" ご入金が確認でき次第商品手配とさせていただきます。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-content" }, [
      _c("table", { staticClass: "table" }, [
        _c("tr", [
          _c("td", [_vm._v("銀行名")]),
          _c("td", [_vm._v("三井住友銀行")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("支店名")]),
          _c("td", [_vm._v("船橋支店")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("口座番号")]),
          _c("td", [_vm._v("7154834（普）")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("口座名義")]),
          _c("td", [_vm._v("ハンコプレミアム（カ")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("振込み手数料")]),
          _c("td", [_vm._v("お客様ご負担")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("お支払期限")]),
          _c("td", [_vm._v("ご注文から7営業日まで")]),
        ]),
      ]),
      _c("table", { staticClass: "table" }, [
        _c("tr", [
          _c("td", [_vm._v("銀行名")]),
          _c("td", [_vm._v("ゆうちょ銀行")]),
        ]),
        _c("tr", [_c("td", [_vm._v("記号")]), _c("td", [_vm._v("10580")])]),
        _c("tr", [
          _c("td", [_vm._v("番号")]),
          _c("td", [_vm._v("7154834（普）")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("口座名義")]),
          _c("td", [_vm._v("ハンコプレミアム（カ")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("振込み手数料")]),
          _c("td", [_vm._v("お客様ご負担")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("お支払期限")]),
          _c("td", [_vm._v("ご注文から7営業日まで")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text2" }, [
      _vm._v(" オーダー家具は、お届けまでにお時間を頂戴します。"),
      _c("br"),
      _vm._v(
        " お届けの目安は商品ページに記載しておりますが、前後する場合がございますので、予めご確認お願い致します。 "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-content" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/pc/order_success.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }