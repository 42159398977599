<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>お気に入り商品</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/my_collection" />
      </div>
      <div class="right-content">
        <template v-if="initData.length > 0">
          <div class="title">
            <div>ご登録の新しい順に表示しています。</div>
            <div>{{ initData.length }} 商品</div>
          </div>
          <div class="product-content">
            <div v-for="(item,index) in initData" :key="item.id" class="list">
              <div class="flex">
                <router-link :to="`/product/${item.product_sn}`">
                  <img :src="item.image" :alt="item.image_alt">
                </router-link>
                <div class="desc">
                  <div class="name">{{ item.title }}</div>
                  <div class="text">{{ item.long_title }}</div>
                  <div class="price">¥{{ calcThousands(item.price) }}</div>
                </div>
              </div>
              <div class="delete" @click="handleCancel(item.product_sn,index)">
                <div>
                  <img src="@/assets/images/pc/grey_x.png" alt="">
                  <span>削除</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="collection-empty">
          <img src="@/assets/images/pc/my_collection_empty.png">
          <div class="text">お気に入り商品はありません</div>
          <router-link to="/">
            <button class="button">トップページに戻る</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMyCollectProduct, cancelCollectProduct } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
import SideBar from './component/sideBar'
export default {
  components: { SideBar },
  data() {
    return {
      initData: [],
      calcThousands
    }
  },
  computed: {

  },
  created() {
    this.getMyCollectProduct()
  },
  methods: {
    getMyCollectProduct() {
      getMyCollectProduct({ type: 'all' }).then(result => {
        this.initData = result
      })
    },
    handleCancel(id, index) {
      cancelCollectProduct(id).then(() => {
        this.initData.splice(index, 1)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        .title{
            display: flex;
            justify-content: space-between;
            padding-bottom: 8px;
            margin-bottom: 16px;
            font-size: 20px;
            border-bottom: 2px solid #333;
        }
        .product-content{
            .list{
                display: flex;
                justify-content: space-between;
                padding: 16px 40px 16px 16px;
                border: 1px solid #e8ebed;
                &:not(:last-child){
                    margin-bottom: 8px;
                }
                img{
                    width: 136px;
                    height: 136px;
                    object-fit: cover;
                }
                .flex{
                    display: flex;
                }
                .desc{
                    width: 514px;
                    margin-left: 12px;
                    .name{
                        margin-top: 12px;
                        font-weight: 700;
                    }
                    .text{
                        margin-top: 16px;
                        font-size: 14px;
                    }
                    .price{
                        margin-top: 20px;
                        font-weight: 700;
                    }
                }
                .delete{
                    display: flex;
                    align-items: center;
                    color: #999999;
                    div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 84px;
                        height: 32px;
                        border: 2px solid #dbdbdb;
                        box-sizing: border-box;
                        cursor: pointer;
                    }
                    img{
                        width: 12px;
                        height: 12px;
                        margin-right: 8px;
                    }
                }
            }
        }
         .collection-empty{
            margin-top: 72px;
            text-align: center;
            font-size: 16px;
            .text{
            margin-top: 12px;
            }
            .button{
            width: 204px;
            height: 48px;
            margin: 32px 0 auto;
            line-height: 48px;
            text-align: center;
            background-color: #333;
            color: #fff;
            cursor: pointer;
            }
        }
    }
}
</style>
