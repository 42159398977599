var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "bread-crumbs" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / "),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _vm._v(" / よくあるご質問 "),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
      _c("table", { staticClass: "table" }, [
        _c("tr", [
          _c("td", [_vm._v("お問い合わせ項目")]),
          _c("td", [
            _vm.ruleForm.type === 1
              ? _c("span", [_vm._v("商品について")])
              : _vm.ruleForm.type === 2
              ? _c("span", [_vm._v("納期について")])
              : _vm.ruleForm.type === 3
              ? _c("span", [_vm._v("不良交換について")])
              : _vm.ruleForm.type === 4
              ? _c("span", [_vm._v("サイトについて")])
              : _vm.ruleForm.type === 5
              ? _c("span", [_vm._v("その他")])
              : _vm._e(),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("お名前")]),
          _c("td", [_vm._v(_vm._s(_vm.ruleForm.username))]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("メールアドレス")]),
          _c("td", [_vm._v(_vm._s(_vm.ruleForm.email))]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("お問い合わせ内容")]),
          _c("td", [_vm._v(_vm._s(_vm.ruleForm.content))]),
        ]),
      ]),
      _c(
        "el-button",
        {
          staticClass: "submit-btn",
          attrs: { type: "primary", loading: _vm.btnLoading },
          on: {
            click: function ($event) {
              return _vm.onSubmit()
            },
          },
        },
        [_vm._v("送信する")]
      ),
      _c("router-link", { attrs: { to: "/guides/question" } }, [
        _c("div", { staticClass: "prev-btn" }, [_vm._v("修正する")]),
      ]),
      _c("van-overlay", { attrs: { show: _vm.dialogVisible } }, [
        _c(
          "div",
          {
            staticClass: "wrapper",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("img", {
                staticClass: "close-btn",
                attrs: { src: require("@/assets/images/sp/close_btn.png") },
                on: { click: _vm.handleClose },
              }),
              _c("div", { staticClass: "title" }, [
                _vm._v("お問い合わせ受付完了"),
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v("お問い合わせありがとうございます。"),
                _c("br"),
                _vm._v("順次ご対応いたします、しばらくお待ちください。"),
              ]),
              _c(
                "div",
                { staticClass: "close", on: { click: _vm.handleClose } },
                [_vm._v("閉じる")]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-content" }, [
      _c("div", { staticClass: "en" }, [_vm._v("Contact us by email")]),
      _c("div", { staticClass: "ja" }, [_vm._v("メールでお問い合わせ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-content" }, [
      _vm._v(" 下記フォームに必要事項をご記入の上、送信してください。"),
      _c("br"),
      _c("br"),
      _vm._v(
        " 24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " ※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " ※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }