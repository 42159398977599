<template>
  <div>
    <div v-show="swiperData.length>0" class="swiper home-page-swiper">
      <div class="swiper-wrapper">
        <div v-for="item in swiperData" :key="item.id" class="swiper-slide">
          <a v-if="item.href" :href="item.href">
            <img :src="item.image" :alt="item.alt">
          </a>
          <img v-else :src="item.image" :alt="item.alt">
        </div>
      </div>
      <div class="swiper-pagination" />
    </div>
    <div class="new-product-content">
      <router-link to="/new/product" class="item">
        <div class="en">NEW ITEM</div>
        <div class="ja">新商品一覧</div>
      </router-link>
      <router-link to="/search?input=3-5営業日出荷" class="item">
        <div class="en">SHORT PERIOD</div>
        <div class="ja">短納期商品一覧</div>
      </router-link>
    </div>
    <div class="category-content">
      <div class="view-title">CATEGORY SEARCH</div>
      <div class="item-content">
        <router-link to="/category/sofa" class="item">ソファ</router-link>
        <router-link to="/category/table" class="item">テーブル</router-link>
        <router-link to="/category/bed" class="item">ベッド</router-link>
        <router-link to="/category/chair" class="item">チェア</router-link>
        <router-link to="/category/rack" class="item">収納・本棚</router-link>
        <router-link to="/category/TV_board" class="item">TVボード</router-link>
        <template v-if="categoryShow">
          <router-link to="/category/interior" class="item">インテリア雑貨</router-link>
          <router-link to="/category/outdoor" class="item">アウトドア</router-link>
        </template>
      </div>
      <div v-if="!categoryShow" class="view-more" @click="categoryShow = true">view more</div>
    </div>
    <div class="custom" v-html="customData.sp_content" />
    <!-- <div class="our-service-content">
      <div class="view-title">OUR SERVICES</div>
      <div class="item-content">
        <div class="item">
          <img src="@/assets/images/sp/our_services_1.png" alt="組立設置サービス">
          <div class="text">組立設置サービス</div>
        </div>
        <div class="item">
          <img src="@/assets/images/sp/our_services_2.png" alt="送料無料">
          <div class="text">送料無料</div>
        </div>
        <div class="item">
          <img src="@/assets/images/sp/our_services_3.png" alt="安心の保証">
          <div class="text">安心の保証</div>
        </div>
        <div class="item">
          <img src="@/assets/images/sp/our_services_4.png" alt="AmazonPay対応">
          <div class="text">AmazonPay対応</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="ins-content">
      <div class="view-title">INSTAGRAM</div>
      <div id="c7c2cf80_1678778462" class="powr-instagram-feed" />
    </div> -->
    <div v-if="productData.length" class="product-content">
      <div class="view-title">PRODUCTS</div>
      <div class="waterfall-container">
        <div v-for="item in productData" :key="item.id" class="waterfall-item">
          <router-link :to="`/product/${item.product_sn}`">
            <img :src="item.image" :alt="item.image_alt">
            <div class="collect" @click="event=>handleCollection(event,item)">
              <img v-if="item.isCollect" src="@/assets/images/sp/home_collect_in.png">
              <img v-else src="@/assets/images/sp/home_collect.png">
            </div>
          </router-link>
        </div>
      </div>
      <div v-show="currentPage !== allPage" class="view-more" @click="handleMoreProduct">view more</div>
    </div>
    <div class="category-content">
      <div class="view-title">CATEGORY SEARCH</div>
      <div class="item-content">
        <router-link to="/category/sofa" class="item">ソファ</router-link>
        <router-link to="/category/table" class="item">テーブル</router-link>
        <router-link to="/category/bed" class="item">ベッド</router-link>
        <router-link to="/category/chair" class="item">チェア</router-link>
        <router-link to="/category/rack" class="item">収納・本棚</router-link>
        <router-link to="/category/TV_board" class="item">TVボード</router-link>
        <template v-if="categoryShow">
          <router-link to="/category/interior" class="item">インテリア雑貨</router-link>
          <router-link to="/category/outdoor" class="item">アウトドア</router-link>
        </template>
      </div>
      <div v-if="!categoryShow" class="view-more" @click="categoryShow = true">view more</div>
    </div>
    <div v-show="articleData.length>0">
      <div class="view-title">INFORMATION</div>
      <div class="article-box">
        <router-link v-for="item in articleData" :key="item.id" class="article-item" :to="`/article/${item.id}`">
          <div class="date">{{ item.created_at.substring(0,10) }}</div>
          <div class="title">{{ item.title }}</div>
        </router-link>
      </div>
      <router-link class="view-more" to="/article">view more</router-link>
    </div>
    <!-- <div class="view-title">ABOUT US</div>
    <div class="swiper commitment-swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img width="100%" src="@/assets/images/sp/commitment_1.png">
          <div class="title">無垢材使用<br>Solid wood</div>
          <div class="desc">当店の木製品に使用される木は、北アメリカとヨーロッパの広葉樹林で育つ優れた木であり、一本の原木から角材や板を直接必要な寸法に切り出したものを使用しています。</div>
        </div>
        <div class="swiper-slide">
          <img width="100%" src="@/assets/images/sp/commitment_2.png">
          <div class="title">RoHS指令対応塗料</div>
          <div class="desc">ウッドワックスオイル仕上げ。EU（欧州連合）RoHS指令に該当する有害物質(鉛、クロム、トルエン、キシレン)を含まず環境に配慮したアクリルラッカー塗料を使用しています。</div>
        </div>
        <div class="swiper-slide">
          <img width="100%" src="@/assets/images/sp/commitment_3.png">
          <div class="title">安全基準ワックスオイル塗装</div>
          <div class="desc">有機ホホバ油で作られ、植物油の自然な香りがあり、無毒で、環境保護は子供に影響を与えません。</div>
        </div>
      </div>
      <div class="swiper-pagination" />
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" />
    </div> -->
  </div>
</template>
<script>
import _ from 'lodash'
import { getSwipers, getCustomPage } from '@/api/config'
import { getArticles } from '@/api/article'
import { getHomeModelDetail } from '@/api/homeModel'
import { productCollect } from '@/api/product'
import { parameterCorrection } from '@/utils/validate'
export default {
  data() {
    return {
      swiperData: [],
      articleData: [],
      customData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        sp_content: null,
        sp_css_path: '',
        sp_js_path: ''
      },
      productData: [],
      productAllData: [],
      currentPage: 1,
      allPage: 0,
      categoryShow: false
    }
  },
  metaInfo() {
    return {
      title: this.customData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.customData.seo_keyword },
        { vmid: 'description', name: 'description', content: this.customData.seo_description }
      ],
      link: [
        { vmid: this.customData.sp_css_path, rel: 'stylesheet', href: this.customData.sp_css_path }
      ],
      script: [
        { vmid: this.customData.sp_js_path, async: 'async', src: this.customData.sp_js_path }
      ]
    }
  },
  mounted() {
    this.getSwipers()
    this.getArticles()
    this.getCustomPage()
    this.getHomeModelDetail()

    new window.Swiper('.commitment-swiper', {
      effect: 'fade',
      pagination: {
        type: 'progressbar',
        el: '.swiper-pagination'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  },
  methods: {
    // 轮播
    getSwipers() {
      const data = parameterCorrection({ type: 2 })
      getSwipers({ ...data, type: 'all' }).then(result => {
        this.swiperData = result
        this.$nextTick(() => {
          this.swiper()
        })
      })
    },
    getArticles() {
      const data = parameterCorrection({ article_category_id: 3 })
      getArticles(data).then(result => {
        this.articleData = result.data.slice(0, 3)
      })
    },
    // 获取自定义内容
    getCustomPage() {
      getCustomPage('home').then(result => {
        result.sp_css_path = result.sp_css_path || ''
        result.sp_js_path = result.sp_js_path || ''
        setTimeout(() => {
          this.customData = result
        }, 300)
      })
    },
    getHomeModelDetail() {
      getHomeModelDetail({ include: 'products', type: 'all' }).then(result => {
        const arr = result.data[0].products
        if (arr.length) {
          this.productAllData = _.chunk(arr, 12)
          this.allPage = this.productAllData.length
          this.productData = this.productAllData[0]
        }
      })
    },
    handleMoreProduct() {
      this.currentPage++
      this.productData = _.concat(this.productData, this.productAllData[this.currentPage - 1])
    },
    swiper() {
      new window.Swiper('.home-page-swiper', {
        autoplay: true,
        loop: true,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination'
        }
      })
    },
    handleCollection(event, item) {
      event.stopPropagation()
      event.preventDefault()
      productCollect(item.product_sn).then(result => {
        if (result.code === 2001) {
          item.isCollect = true
        } else {
          item.isCollect = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.home-page-swiper{
  padding-bottom: 0.5rem;
    img{
      width: 100%;
      height: 4.38rem;
      vertical-align: middle;
  }
  .swiper-pagination ::v-deep{
    bottom: 0.1rem;
    .swiper-pagination-bullet{
      width: 0.56rem;
      height: 0.08rem;
      border: 0.02rem solid #666666;
      background: transparent;
      margin: 0 0.06rem;
      border-radius: 0;
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #333333;
    }
  }
}
.new-product-content{
  display: flex;
  justify-content: space-between;
  margin: 1.2rem .24rem 0;
  .item{
    width: 3.44rem;
    padding: .14rem 0;
    border: .02rem solid #333333;
    text-align: center;
    box-sizing: border-box;
    .en{
      margin-bottom: .1rem;
      font-size: .32rem;
      font-weight: bold;
    }
  }
}
.view-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.9rem 0 0.28rem;
  // &::before,
  // &::after{
  //   content: '';
  //   width: 0.8rem;
  //   height: 0.02rem;
  //   background: #333333;
  //   margin: 0 0.2rem;
  // }
}
.view-more{
  display: block;
  width: 4.04rem;
  height: 0.76rem;
  text-align: center;
  line-height: 0.76rem;
  border: 0.02rem solid #333;
  margin: 0.4rem auto 0.9rem;
}
.article-box{
  margin: 0 0.24rem;
  .article-item{
    display: flex;
    align-items: center;
    padding: 0.2rem 0;
    border-bottom: 0.02rem solid #E8EBED;
    &:nth-child(1){
      border-top: 0.02rem solid #E8EBED;
    }
    .date{
      font-size: 0.24rem;
      color: #999999;
      white-space: nowrap;
      margin-right: 0.32rem;
    }
    .title{
      font-size: 0.28rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.our-service-content{
  .item-content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 .24rem;
    .item{
      width: 3.44rem;
      padding: .32rem 0;
      margin-bottom: .14rem;
      text-align: center;
      background-color: #f4f5fc;
    }
    img{
      width: 1.28rem;
      height: 1.04rem;
    }
    .text{
      margin-top: .2rem;
      font-weight: 700;
    }
  }
}
.product-content{
  .waterfall-container {
    margin: 0 .24rem;
    column-count: 2;
  }

  .waterfall-item {
    position: relative;
    width: 3.44rem;
    margin-bottom: .14rem;
    break-inside: avoid;
    img{
      width: 3.44rem;
      height: 3.44rem;
    }
    &:first-child{
      img{
        width: 3.44rem ;
        height: 4.58rem;
      }
    }
    .collect{
      img{
        position: absolute;
        right: .16rem;
        bottom: .16rem;
        width: .88rem;
        height: .88rem;
      }
    }
  }
}

.category-content{
  margin-bottom: 1.12rem;
  .item-content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 .24rem;
    .item{
      width: 3.44rem;
      height: 1.04rem;
      padding-left: .16rem;
      margin-bottom: .24rem;
      line-height: 1.04rem;
      border: .02rem solid #292a2e;
      box-sizing: border-box;
      text-align: center;
    }
  }
}
.commitment-swiper ::v-deep {
  margin: 0.28rem 0.24rem 1.1rem;
  padding-bottom: 1.12rem;
  .swiper-slide{
    background: #fff;
    img{
      height: 3.96rem;
    }
  }
  .title{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.02rem;
    height: 3.96rem;
    font-size: 0.36rem;
    font-weight: bold;
    color: #fff;
  }
  .desc{
    min-height: 1.68rem;
    line-height: 0.42rem;
    margin-top: 0.28rem;
  }
  .swiper-pagination{
    top: auto;
    bottom: 0.32rem;
    width: 4.98rem;
    height: 0.02rem;
    background: #E8EBED;
  }
  .swiper-pagination-progressbar-fill{
    background: #333333;
  }
  .swiper-button-prev{
    top: auto;
    left: auto;
    right: 0.92rem;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/left_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
  .swiper-button-next{
    top: auto;
    right: 0;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/right_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
}
</style>
