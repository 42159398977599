var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/pc/breadcrumb_arrow.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("注文履歴")]),
      ],
      1
    ),
    _c("div", { staticClass: "personal-content" }, [
      _c(
        "div",
        { staticClass: "left-content" },
        [_c("SideBar", { attrs: { path: "/personal/order" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "right-content" },
        [
          _vm.initData.length > 0
            ? [
                _c("div", { staticClass: "title" }, [
                  _vm._v("ご注文の新しい順に表示しています。"),
                ]),
                _c(
                  "div",
                  { staticClass: "order-content" },
                  _vm._l(_vm.initData, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "head" }, [
                        _c("div", [
                          _c("span", [
                            _vm._v("ご注文番号：" + _vm._s(item.order_sn)),
                          ]),
                          _c("span", { staticClass: "time" }, [
                            _vm._v(_vm._s(item.created_at)),
                          ]),
                        ]),
                        _c("div", { staticClass: "status" }, [
                          _vm._v(
                            _vm._s(item.store_status && item.store_status.name)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "product-detail" }, [
                        _c(
                          "div",
                          _vm._l(item.order_products, function (item2) {
                            return _c(
                              "div",
                              { key: item2.id, staticClass: "flex" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/product/" + item2.product_sn,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: item2.product_img },
                                    }),
                                  ]
                                ),
                                _c("div", { staticClass: "desc" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item2.product_title)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "sku-content" },
                                    _vm._l(
                                      item2.product_sku_display,
                                      function (skuItem, index2) {
                                        return _c(
                                          "div",
                                          { key: index2, staticClass: "list" },
                                          [_vm._v(_vm._s(skuItem))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "norm-content" },
                                    _vm._l(
                                      item2.product_spu_display,
                                      function (normItem, index3) {
                                        return _c(
                                          "div",
                                          { key: index3, staticClass: "list" },
                                          [_vm._v(_vm._s(normItem))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _c("div", { staticClass: "count" }, [
                                  _vm._v(
                                    "数量：" + _vm._s(item2.product_quantity)
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "action-content" }, [
                          _c(
                            "div",
                            { staticClass: "action" },
                            [
                              item.is_comment === 0 && item.status_to_user === 3
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "button dark",
                                      attrs: {
                                        to:
                                          "/personal/order/add_comments/" +
                                          item.id,
                                      },
                                    },
                                    [_vm._v("レビュー")]
                                  )
                                : _vm._e(),
                              _c(
                                "router-link",
                                {
                                  staticClass: "button detail",
                                  attrs: {
                                    to: "/personal/order/detail/" + item.id,
                                  },
                                },
                                [_vm._v("詳細を確認する")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "order-detail" }, [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              "商品数量 " +
                                _vm._s(item.total_quantity) +
                                " 件、合计金额"
                            ),
                          ]),
                          _c("span", { staticClass: "price" }, [
                            _vm._v(
                              "¥" + _vm._s(_vm.calcThousands(item.pay_amount))
                            ),
                          ]),
                        ]),
                        _vm._m(0, true),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            : _c(
                "div",
                { staticClass: "order-empty" },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/my_order_empty.png"),
                    },
                  }),
                  _c("div", { staticClass: "text" }, [
                    _vm._v("ご購入の商品はまた登録されていません。"),
                  ]),
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("button", { staticClass: "button" }, [
                      _vm._v("トップページに戻る"),
                    ]),
                  ]),
                ],
                1
              ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "share" }, [
      _c("span", [_vm._v("SHARE ON SNS :")]),
      _c("a", { attrs: { href: "" } }, [
        _c("div", { staticClass: "facebook" }),
      ]),
      _c("a", { attrs: { href: "" } }, [_c("div", { staticClass: "twitter" })]),
      _c("a", { attrs: { href: "" } }, [_c("div", { staticClass: "ins" })]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }