<template>
  <div class="search">
    <div class="search-title">検索</div>
    <div class="retrieval">
      <el-input v-model="input" clearable placeholder="ここに検索キーワードを入力してください…" />
      <div class="retrieval-submit" @click="parameterInfo.page = 1,productList()">検索</div>
    </div>
    <div v-if="keywordsData.length>0" class="retrieval-record">
      <div
        v-for="item in keywordsData"
        :key="item.id"
        @click="input = item.name,parameterInfo.page = 1,productList()"
      >{{ item.name }}</div>
    </div>
    <div v-show="show === 3" class="search-empty">
      <img src="@/assets/images/pc/search_empty.png">
      <div>“ {{ input }} ” に該当する製品はございません。</div>
    </div>
    <div v-show="show !== 2">
      <div class="item-title">
        <div class="item-left">
          <div class="arrow">›</div>
          <div>
            <div class="item-bold">ITEM</div>
            <div>カテゴリー</div>
          </div>
        </div>
        <div class="item-right">
          <router-link v-for="item in categoryData" :key="item.id" :to="`/category/${item.url}`">{{ item.name }}</router-link>
        </div>
      </div>
      <div class="popularity">
        <div class="popularity-title">
          <div class="arrow">›</div>
          <div>
            <div class="item-bold">RECOMMENDED ITEM</div>
            <div>人気商品</div>
          </div>
        </div>
        <div class="swiper popularity-swiper">
          <div class="swiper-wrapper">
            <router-link v-for="item in initPopularityData" :key="item.id" :to="'/product/'+ item.product_sn" class="swiper-slide">
              <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
              <div class="discount">
                <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="value">{{ item.promotion[0].discount }}%OFF</div>
              </div>
              <div class="label" @mouseleave="item.colorTagIndex = ''">
                <div
                  v-for="(it,index) in item.color_tags"
                  :key="index"
                  :style="{background:it.color_value}"
                  :class="{active:item.colorTagIndex === index}"
                  @mouseenter="item.colorTagIndex = index"
                >
                  <span />
                </div>
              </div>
              <div class="title">
                <span v-if="item.is_hot" class="sale">SALE</span>
                <span v-if="item.is_new" class="new">NEW</span>
                <span>{{ item.title }}</span>
              </div>
              <div class="desc">{{ item.long_title }}</div>
              <div class="price">
                <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
                  <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
                  <span class="original">¥{{ calcThousands(item.price) }}</span>
                </template>
                <template v-else>
                  ¥{{ calcThousands(item.price) }}
                </template>
              </div>
            </router-link>
          </div>
          <div class="swiper-pagination" />
          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
      </div>
      <div class="popularity">
        <div class="popularity-title">
          <div class="arrow">›</div>
          <div>
            <div class="item-bold">GUIDE</div>
            <div>よくあるご質問</div>
          </div>
        </div>
        <common />
      </div>
    </div>
    <div v-show="show === 2" class="goods-box">
      <div class="screen">
        <span>01～ 20 件目を表示しています。（全{{ total }}件）</span>
        <el-select v-model="parameterInfo.sort" :popper-append-to-body="false" @change="parameterInfo.page = 1,productList()">
          <el-option label="表示順" value="sort" />
          <el-option label="人気順" value="is_hot" />
          <el-option label="新着順" value="-created_at" />
          <el-option label="価格安い順" value="price" />
          <el-option label="価格高い順" value="-price" />
        </el-select>
      </div>
      <div class="goods-list">
        <router-link v-for="item in initData" :key="item.id" :to="'/product/'+ item.product_sn" class="goods-item">
          <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
          <div class="discount">
            <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="value">{{ item.promotion[0].discount }}%OFF</div>
          </div>
          <div class="label" @mouseleave="item.colorTagIndex = ''">
            <div
              v-for="(it,index) in item.color_tags"
              :key="index"
              :style="{background:it.color_value}"
              :class="{active:item.colorTagIndex === index}"
              @mouseenter="item.colorTagIndex = index"
            >
              <span />
            </div>
          </div>
          <div class="title">
            <span v-if="item.is_hot" class="sale">SALE</span>
            <span v-if="item.is_new" class="new">NEW</span>
            <span>{{ item.title }}</span>
          </div>
          <div class="desc">{{ item.long_title }}</div>
          <div class="price">
            <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
              <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
              <span class="original">¥{{ calcThousands(item.price) }}</span>
            </template>
            <template v-else>
              ¥{{ calcThousands(item.price) }}
            </template>
          </div>
        </router-link>
        <div class="goods-item-seat" />
      </div>
    </div>
    <pagination-pc
      :hidden="total <= parameterInfo.limit"
      :total="total"
      :page.sync="parameterInfo.page"
      :limit.sync="parameterInfo.limit"
      :link="$route.fullPath"
      @pagination="productList"
    />
  </div>
</template>
<script>
import common from '@/views/pc/question/common'
import { keywords } from '@/api/config'
import { productList } from '@/api/product'
import { getCategoryList } from '@/api/category'
import { calcThousands, parameterCorrection } from '@/utils/validate'
import PaginationPc from '@/components/PaginationPc'
export default {
  components: { common, PaginationPc },
  data() {
    return {
      show: 1,
      input: this.$route.query.input || '',
      keywordsData: [],
      initData: [],
      initPopularityData: [],
      categoryData: [],
      total: 0,
      parameterInfo: {
        page: parseInt(this.$route.query.page) || 1,
        limit: 30,
        sort: 'sort',
        include: 'tag,colorTags,promotion'
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.input = this.$route.query.input || ''
        this.parameterInfo.page = this.$route.query.page || 1
        this.parameterInfo.sort = this.$route.query.sort || ''
        this.productList()
      }
    }
  },
  created() {
    this.keywords()
    this.productList()
    this.productPopularityList()
    this.getCategoryList()
  },
  methods: {
    calcThousands,
    // 轮播
    swiper() {
      new window.Swiper('.popularity-swiper', {
        slidesPerView: 'auto',
        spaceBetween: 20,
        pagination: {
          type: 'progressbar',
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    },
    // 商品列表
    productList() {
      this.$router.push({ query: { input: this.input }})
      let data = parameterCorrection({ search: this.input, is_on_sale: true })
      data = { ...data, ...this.parameterInfo }
      productList(data).then((result) => {
        result.data.forEach(item => {
          item.colorTagIndex = ''
        })
        if (result.total < 1) {
          this.show = 3
        } else {
          this.show = 2
        }
        this.initData = result.data
        this.total = result.total
        this.initData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
      })
    },
    getCategoryList() {
      getCategoryList({ type: 'all', 'filter[is_recommend]]': true, sort: 'sort' }).then(result => {
        this.categoryData = result.data
      })
    },
    productPopularityList() {
      const data = parameterCorrection({ is_on_sale: true, is_hot: true })
      productList({ ...data, type: 'all', ...{ include: 'promotion' }}).then((result) => {
        result.forEach(item => {
          item.colorTagIndex = ''
        })
        this.initPopularityData = result
        this.initPopularityData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
        this.$nextTick(() => {
          this.swiper()
        })
      })
    },
    // 关键词
    keywords() {
      const data = parameterCorrection({ type: 1 })
      keywords({ ...data, type: 'all' }).then(result => {
        this.keywordsData = result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.search{
  font-size: 16px;
  color: #333;
}
.search-title{
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin: 38px 0 0;
}
.retrieval ::v-deep{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  height: 60px;
  padding: 0 8px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 6px 20px 0px rgba(30,89,116,0.08);
  margin: 32px auto 0;
  .el-input{
    width: 670px;
  }
  input{
    border: none;
  }
}
.retrieval-submit{
  width: 96px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  background: #333;
  border-radius: 4px;
  cursor: pointer;
}
.retrieval-record{
  display: flex;
  flex-wrap: wrap;
  width: 800px;
  margin: 32px auto 0;
  div{
    height: 36px;
    line-height: 38px;
    padding: 0 12px;
    border: 1px solid #707070;
    margin: 0 12px 12px 0;
    cursor: pointer;
  }
}
.search-empty{
  text-align: center;
  margin: 50px 0 80px;
  img{
    margin-bottom: 7px;
  }
}
.item-title{
  display: flex;
  width: 1440px;
  font-size: 14px;
  padding: 0 0 80px;
  margin: 80px auto;
  border-bottom: 2px solid #333;
  .item-left{
    display: flex;
  }
  .arrow{
    font-size: 40px;
    line-height: 32px;
    margin-right: 12px;
  }
  .item-bold{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .item-right{
    display: flex;
    flex-wrap: wrap;
    width: 648px;
    margin-left: 390px;
    // div{
    //   margin-right: 100px;
    // }
    a{
      display: block;
      width: 224px;
      margin-bottom: 24px;
      &:nth-child(2n-1){
        margin-right: 100px;
      }
    }
  }
}
.popularity{
  width: 1440px;
  margin: 40px auto;
  .popularity-title{
    display: flex;
    font-size: 14px;
  }
  .arrow{
    font-size: 40px;
    line-height: 32px;
    margin-right: 12px;
  }
  .item-bold{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .popularity-swiper ::v-deep{
    width: 1440px;
    padding: 0 0 80px;
    margin: 32px auto 80px;
    .swiper-slide{
      width: 345px;
    }
    img{
      width: 100%;
      height: 345px;
    }
    .title{
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 12px 0 0 0;
    }
    .sale{
      font-weight: bold;
      color: #FECB35;
      margin-right: 8px;
    }
    .new{
      font-weight: bold;
      color: #F90412;
      margin-right: 8px;
    }
     .discount{
      width: 72px;
      height: 20px;
      margin-top: 8px;
      line-height: 20px;
      text-align: center;
      .value{
        color: #ffffff;
        background-color: #c40a0a;
      }
    }
    .label{
      margin-top: 8px;
      span{
        background: transparent;
      }
      div{
        display: inline-block;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 4px;
      }
      .active{
        border: 1px solid #333;
        span{
          display: block;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          box-sizing: border-box;
          border: 2px solid #fff;
        }
      }
    }
    .desc{
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2;
      margin: 16px 0 0 0;
    }
     .price{
      margin-top: 20px;
      .now{
        font-size: 16px;
        font-weight: bold;
        color: #c40a0a;
      }
      .original{
        margin-left: 8px;
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;
      }
    }
    .swiper-pagination{
      top: auto;
      bottom: 23px;
      width: 1288px;
      background: #E8EBED;
    }
    .swiper-pagination-progressbar-fill{
      background: #333;
    }
    .swiper-button-prev{
      top: auto;
      left: auto;
      right: 72px;
      bottom: 0;
      margin: 0;
    }
    .swiper-button-prev{
      top: auto;
      left: auto;
      right: 72px;
      bottom: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #E8EBED;
      background: url('~@/assets/images/pc/arrow-left.png') center center;
      margin: 0;
      &::after{
        content: '';
      }
    }
    .swiper-button-next{
      top: auto;
      right: 0;
      bottom: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #E8EBED;
      background: url('~@/assets/images/pc/arrow-right.png') center center;
      margin: 0;
      &::after{
        content: '';
      }
    }
    .swiper-pagination-lock{
      display: block;
    }
    .swiper-button-lock{
      display: flex;
    }
  }
}
.goods-box{
  width: 1440px;
  margin: 100px auto 80px;
  .screen ::v-deep{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 20px;
    padding-bottom: 8px;
    border-bottom: 2px solid #333;
    .el-input{
      width: 138px;
      height: 48px;
    }
    input{
      height: 100%;
      font-size: 16px;
    }
    .el-select-dropdown{
      margin: 0;
    }
    .el-select-dropdown__item{
      font-size: 16px;
    }
    .popper__arrow{
      display: none;
    }
  }
  .goods-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .goods-item-seat{
      width: 464px;
    }
  }
  .goods-item{
    width: 402px;
    margin: 32px 0 0 0;
    img{
      width: 100%;
      height: 402px;
    }
    .title{
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 12px 0 0 0;
    }
    .sale{
      font-weight: bold;
      color: #FECB35;
      margin-right: 8px;
    }
    .new{
      font-weight: bold;
      color: #F90412;
      margin-right: 8px;
    }
     .discount{
      width: 72px;
      height: 20px;
      margin-top: 8px;
      line-height: 20px;
      text-align: center;
      .value{
        color: #ffffff;
        background-color: #c40a0a;
      }
    }
    .label{
      margin-top: 8px;
      span{
        background: transparent;
      }
      div{
        display: inline-block;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 4px;
      }
      .active{
        border: 1px solid #333;
        span{
          display: block;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          box-sizing: border-box;
          border: 2px solid #fff;
        }
      }
    }
    .desc{
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2;
      margin: 16px 0 0 0;
    }
     .price{
      margin-top: 20px;
      .now{
        font-size: 16px;
        font-weight: bold;
        color: #c40a0a;
      }
      .original{
        margin-left: 8px;
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;
      }
    }
  }
}

</style>
