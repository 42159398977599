var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticClass: "custom",
        domProps: { innerHTML: _vm._s(_vm.customData.pc_content) },
      }),
      _c(
        "div",
        { staticClass: "goods-list" },
        [
          _vm._l(_vm.initData, function (item) {
            return _c(
              "router-link",
              {
                key: item.id,
                staticClass: "goods-item",
                attrs: { to: "/product/" + item.product_sn },
              },
              [
                _c("img", {
                  attrs: {
                    src:
                      item.colorTagIndex === ""
                        ? item.image
                        : item.color_tags[item.colorTagIndex].pivot.image,
                  },
                }),
                _c("div", { staticClass: "discount" }, [
                  item.promotion.length > 0 && item.promotion[0].is_open
                    ? _c("div", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.promotion[0].discount) + "%OFF"),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "label",
                    on: {
                      mouseleave: function ($event) {
                        item.colorTagIndex = ""
                      },
                    },
                  },
                  _vm._l(item.color_tags, function (it, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: { active: item.colorTagIndex === index },
                        style: { background: it.color_value },
                        on: {
                          mouseenter: function ($event) {
                            item.colorTagIndex = index
                          },
                        },
                      },
                      [_c("span")]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "title" }, [
                  item.is_hot
                    ? _c("span", { staticClass: "sale" }, [_vm._v("SALE")])
                    : _vm._e(),
                  item.is_new
                    ? _c("span", { staticClass: "new" }, [_vm._v("NEW")])
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(item.title))]),
                ]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(_vm._s(item.long_title)),
                ]),
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    item.promotion.length > 0 && item.promotion[0].is_open
                      ? [
                          _c("span", { staticClass: "now" }, [
                            _vm._v(
                              "¥" +
                                _vm._s(_vm.calcThousands(item.discount_price))
                            ),
                          ]),
                          _c("span", { staticClass: "original" }, [
                            _vm._v("¥" + _vm._s(_vm.calcThousands(item.price))),
                          ]),
                        ]
                      : [
                          _vm._v(
                            " ¥" + _vm._s(_vm.calcThousands(item.price)) + " "
                          ),
                        ],
                  ],
                  2
                ),
              ]
            )
          }),
          _c("div", { staticClass: "goods-item-seat" }),
        ],
        2
      ),
      _c("pagination-pc", {
        attrs: {
          hidden: _vm.total <= _vm.parameterInfo.limit,
          total: _vm.total,
          page: _vm.parameterInfo.page,
          limit: _vm.parameterInfo.limit,
          link: "/new/product",
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.parameterInfo, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.parameterInfo, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("popularity"),
      _c("special"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }