<template>
  <div v-show="confirmVisible" class="overlay confirm-overlay">
    <div class="main">
      <div class="modal-content" :class="{success:confirmVisibleSuccess}">
        <template v-if="!confirmVisibleSuccess">
          <div class="title">メールで入荷通知を受取る</div>
          <div class="text">お知らせを受けるメールアドレスをご入力ください。</div>
          <input v-model="email" type="text" placeholder="E-mail:">
          <button class="confirm-btn" @click="emailSubmit">Eメール登録</button>
        </template>
        <template v-else>
          <div class="desc">入荷お知らせを<br>受付致しました</div>
          <button class="confirm-btn" @click="close">閉じる</button>
        </template>
        <img src="@/assets/images/pc/x.png" class="close-btn" @click="close">
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { emailSubscriptions } from '@/api/config'
export default {
  props: {
    confirmVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: '',
      confirmVisibleSuccess: false
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      if (this.token) {
        const userInfo = await this.$store.dispatch('user/getUserDetail')
        this.email = userInfo.email
      }
    },
    // 邮件订阅
    emailSubmit() {
      emailSubscriptions({
        email: this.email,
        type: 2,
        product_id: this.product_id,
        sku_id: this.sku_id
      }).then(() => {
        this.email = ''
        this.confirmVisibleSuccess = true
      })
    },
    close() {
      this.$emit('update:confirmVisible', false)
      this.confirmVisibleSuccess = false
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm-overlay{
  .modal-content{
    width: 400px;
    height: 330px;
    padding: 0 40px;
    .title{
      margin-top: 56px;
      font-size: 28px;
      font-weight: 700;
    }
    .text{
      margin-top: 38px;
    }
    input{
      width: 100%;
      height: 48px;
      border: 1px solid #e8ebed;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0 12px;
      margin: 8px 0 0;
    }
    .confirm-btn{
      width: 100%;
      height: 48px;
      margin: 32px 0 auto;
      line-height: 48px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      background-color: #333;
      color: #fff;
    }
    .desc{
      font-weight: bold;
      text-align: center;
      margin: 56px 0 0;
    }
  }
  .success{
    height: 232px;
  }
}
</style>
