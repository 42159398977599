<template>
  <div class="payment-header">
    <h1>家具通販・おしゃれなインテリア専門店（ウッドライフ）</h1>
    <router-link to="/">
      <img src="@/assets/images/pc/logo.png" alt="" class="logo">
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'PaymentHeader',
  data() {
    return {

    }
  }
}

</script>
<style lang="scss" scoped>
.payment-header{
    padding: 22px 0;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(23,42,103,0.11);
    backdrop-filter: blur(20px);
    h1{
        font-size: 16px;
        font-weight: 400;
    }
    .logo{
        width: 374px;
        height: 50px;
        margin-top: 6px;
    }
}
</style>
