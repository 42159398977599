var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tips" },
      [
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
        _vm._v(" / ニュース"),
      ],
      1
    ),
    _c("div", { staticClass: "article-box" }, [
      _c("div", { staticClass: "article-detail" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.initData.title)),
        ]),
        _c("div", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm.initData.created_at.substring(0, 10))),
        ]),
        _c("div", {
          staticClass: "custom",
          domProps: { innerHTML: _vm._s(_vm.initData.mobile_desc) },
        }),
      ]),
      _vm.initData.products.length > 0
        ? _c("div", [
            _c("div", { staticClass: "view-title" }, [_vm._v("関連商品")]),
            _c("div", { staticClass: "swiper relation-swiper" }, [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.initData.products, function (item, index) {
                  return _c(
                    "router-link",
                    {
                      key: index,
                      staticClass: "swiper-slide",
                      attrs: { to: "/product/" + item.product_sn },
                    },
                    [
                      _c("img", { attrs: { width: "100%", src: item.image } }),
                      _c("div", { staticClass: "product-desc" }, [
                        _c("div", { staticClass: "product-name" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("div", { staticClass: "price" }, [
                          _vm._v("￥" + _vm._s(_vm.calcThousands(item.price))),
                          _c("span", [_vm._v("(税込)")]),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
              _c("div", { staticClass: "swiper-pagination" }),
              _c("div", { staticClass: "swiper-button-prev" }),
              _c("div", { staticClass: "swiper-button-next" }),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }