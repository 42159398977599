<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <router-link to="/question">お問い合わせ</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <span>内容ご確認</span>
    </div>
    <div class="question-from">
      <div class="ttl">
        <span>Contact us by email</span>
        <span class="num">02</span>
      </div>
      <div class="dc">お問い合わせ内容ご確認</div>
      <div class="tips">下記フォームに必要事項をご記入の上、送信してください。<br>24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。</div>
      <div class="tips">※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。<br>※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。</div>
      <div class="question-table">
        <div class="title">
          <div class="ask">内容ご確認</div>
          <div class="desc">Contents confirmation</div>
        </div>
        <div class="question-table-item">
          <div class="question-table-left">
            <div>
              <div class="desc">お問い合わせ項目</div>
              <div class="ask">
                <span v-if="ruleForm.type === 1">商品について</span>
                <span v-else-if="ruleForm.type === 2">納期について</span>
                <span v-else-if="ruleForm.type === 3">不良交換について</span>
                <span v-else-if="ruleForm.type ===4">サイトについて</span>
                <span v-else-if="ruleForm.type === 5">その他</span>
              </div>
            </div>
            <div>
              <div class="desc">お名前</div>
              <div class="ask">{{ ruleForm.username }}</div>
            </div>
            <div>
              <div class="desc">メールアドレス</div>
              <div class="ask">{{ ruleForm.email }}</div>
            </div>
          </div>
          <div>
            <div class="desc">お問い合わせ内容</div>
            <div class="ask">{{ ruleForm.content }}</div>
          </div>
        </div>
      </div>
      <div class="button">
        <el-button class="return" type="primary" @click="$router.go(-1)">
          <img src="@/assets/images/pc/leftArrow.png">
          <span>前に戻る</span>
        </el-button>
        <el-button :loading="btnLoading" class="deliver" type="primary" @click="onSubmit">
          <span>送信する</span>
          <img src="@/assets/images/pc/rightArrow.png">
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { questions } from '@/api/config'
export default {
  data() {
    return {
      btnLoading: false,
      ruleForm: {
        type: 1,
        username: '',
        email: '',
        content: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'question'
    ])
  },
  created() {
    this.ruleForm = { ...this.ruleForm, ...this.question }
  },
  methods: {
    // 提交
    onSubmit() {
      this.btnLoading = true
      questions(this.ruleForm).then(() => {
        this.btnLoading = false
        this.$router.push({ path: '/question/success' })
        this.$store.dispatch('config/setQuestion', {})
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  line-height: 17px;
  margin: 28px auto 0;
}
.question-from{
  width: 1440px;
  color: #333;
  font-size: 16px;
  margin: 80px auto;
}
.ttl{
  position: relative;
  font-size: 32px;
  font-weight: bold;
  margin-left: 16px;
  .num{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 200px;
    line-height: 1;
    background-clip:text;
    color: transparent;
    background-image:-webkit-linear-gradient(90deg,#FFFFFF,#F4F5FC);
    color:transparent;
    z-index: -1;
  }
}
.dc{
  width: 340px;
  font-size: 14px;
  padding: 22px 0 8px 16px;
  background: #F4F5FC;
  margin: -20px 0 28px;
}
.tips{
  margin-bottom: 20px;
}
.question-table{
  display: flex;
  width: 1440px;
  border: 2px solid #333;
  margin-top: 56px;
  .title{
    flex-shrink: 0;
    width: 310px;
    min-height: 252px;
    box-sizing: border-box;
    padding: 16px 0 0 56px;
    border-right: 1px solid #333;
    margin: 24px 0;
    .ask{
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    .desc{
      font-size: 12px;
    }
  }
  .question-table-item{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 56px;
    margin: 24px 0;
    .question-table-left{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;
      width: 450px;
    }
    .ask{
      font-size: 20px;
      font-weight: bold;
    }
    .desc{
      color: #666;
      margin-bottom: 12px;
    }
  }
}
.button{
  display: flex;
  justify-content: center;
  margin: 32px auto;
}
.return{
  display: block;
  width: 204px;
  height: 48px;
  text-align: center;
  background: #fff;
  color: #333;
  border: 1px solid #333;
  margin: 0 16px;
  cursor: pointer;
  img{
    margin-right: 4px;
  }
}
.deliver{
  display: block;
  width: 204px;
  height: 48px;
  color: #fff;
  text-align: center;
  background: #333;
  border: none;
  margin: 0 16px;
  cursor: pointer;
  img{
    margin-left: 4px;
  }
}
</style>
