var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "bread-crumbs" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / "),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _vm._v(" / ご注文について "),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
      _c("router-link", { attrs: { to: "/guides/question" } }, [
        _c("div", { staticClass: "button" }, [_vm._v("他のご質問はこちら")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-content" }, [
      _vm._v(" ご注文について "),
      _c("img", {
        attrs: { src: require("@/assets/images/sp/guides_wood.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lists-content" }, [
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("01 ご注文の流れについて"),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " (1) 商品ページで、必須項目をご選択すると自動的にサイズに応じた金額が表示されます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " (2) 希望の商品を選んで、「カートに入れる」ボタンより商品をショッピングカートに入れてください。 "
          ),
        ]),
        _c("div", { staticClass: "red-box" }, [
          _vm._v(
            " 購入手続きは会員登録が必要です。会員ではない方は「新規ユーザー登録」からご登録ください。 "
          ),
        ]),
        _c("br"),
        _c("div", { staticClass: "desc" }, [
          _vm._v(" (3) 会員ログインしてご購入手続きへお進みください。"),
          _c("br"),
          _c("br"),
          _vm._v(
            " (4) 配送方法とお届け先情報をご入力、ご選択くださ い。全ての項目のご入力が完了しましたら、「支払方法に進む」ボタンをクリックしてください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " (5) 希望の支払方法を選びください。必須項目をご入力が完了しましたら、「ご注文確定する」ボタンをクリックください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " (6) 「ご注文を確定前のお願い」をよくご理解の上、同意する/同意しないをご選択ください。 ※【同意しない】をご選択した場合、商品購入はできませんので、ご了承ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " (7)注文が正しく完了すると、画面にご注文番号が表示されます。ご入力したメールアドレスに「ご注文内容確認メール」が自動配信されます。 "
          ),
        ]),
        _c("div", { staticClass: "red-box" }, [
          _vm._v("弊社からのメールが届かない場合は早めにお問い合せください。"),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("02 お取引の流れについて"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/sp/guides_order_img.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("03 ご注文後のキャンセル・内容変更について"),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 当店の無垢材家具は、お客様からご注文を頂いてから製作する受注生産の為、商品ご注文後の返品・返金・キャンセルは原則としてお受けしておりません。 "
          ),
        ]),
        _c("div", { staticClass: "red-box" }, [
          _vm._v(" ご注文後のキャンセル・内容変更（追加・変更）はできません。"),
          _c("br"),
          _vm._v("ご了承の上、ご購入をお願いいたします。 "),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("04 ご注文後に「ご注文内容確認メール」が届かない場合"),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " お客様ご登録のアドレスの不備・受信拒否設定・自動振分設定などが原因で、自動送信メールが届いていない場合がございます。"
          ),
          _c("br"),
          _vm._v(
            "上記のご確認及びご入力いただいたメールアドレスが正しいかのご確認をお願い致します。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " またご利用のメールサービスによっては、弊社からのメール（@woodlife.jp）が、迷惑メールへ分類されている可能性や受信拒否設定がされている可能性があります。"
          ),
          _c("br"),
          _vm._v(
            "メールが届かない場合は、迷惑メールフォルダの確認およびドメイン設定をご確認ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " Amazon Payをご利用いただいたお客様はAmazon様にご登録いただいているメールアドレスにメールが送信されていますので、ご確認お願いいたします。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("05 商品の在庫について")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 当サイトに掲載している大型家具商品は、複数店舗で同時に販売しております。その為、同一商品へご注文が集中した場合など、他店舗にて完売してしまい欠品してしまう場合がございます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 在庫管理は、できる限りリアルタイムな更新を心がけておりますが、万一欠品の際はご了承下さいませ。メーカー取り寄せになる商品につきましては、在庫の有無が判明するまでに数日かかる場合がございます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 欠品の場合は、その旨をメールにてご連絡させていただき、勝手ながらキャンセルさせていただきます。誠に恐れ入りますが、あらかじめご了承のほどお願い申し上げます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ※お支払いが完了した後においても、商品を受注数分ご用意することが出来ず、ご注文をキャンセルさせて頂く場合がございます。その場合は、規約に従い返金いたします。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 欠品の商品ページに表示される「メールで入荷通知を受け取る」につきまして、登録をされた方に対して入荷をお約束するものではありません。入荷すること無く、お知らせメールが送信されない場合がありますのでご承知ください。 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }