var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v("家具通販専門店TOP"),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/breadcrumb_arrow.png"),
              alt: "",
            },
          }),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/breadcrumb_arrow.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("配送・送料について")]),
        ],
        1
      ),
      _c("div", { staticClass: "list-content" }, [
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "title" }, [_vm._v("01 配送について")]),
          _c("div", { staticClass: "bg-text" }, [
            _vm._v(
              "配送は玄関先までとなります。開梱や組立、梱包材の回収はございません。"
            ),
          ]),
          _c(
            "div",
            { staticClass: "text-content" },
            [
              _vm._v(
                " 配送会社（ヤマト運輸・佐川急便・メーカーから直送）にて発送しております。配送会社ご指定ありの場合は差額分お客様ご負担となります。"
              ),
              _c("br"),
              _c("span", { staticClass: "red" }, [
                _vm._v(
                  "配送希望日にお応えできない場合、搬入出来なかった場合も、ご注文キャンセル・返品などはお受けできませんので、予めご了承くださいませ。"
                ),
              ]),
              _c("br"),
              _vm._v(
                " ※＜開梱設置＞無料・有料ご選択された場合、開梱・組み立て・梱包材の引き上げまで行います。"
              ),
              _c("br"),
              _c("router-link", { attrs: { to: "/guides/install" } }, [
                _vm._v(
                  "＞＞搬入・組立てについてこちらのページにてご確認ください。"
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "red-bg" }, [
            _vm._v(
              "北海道・沖縄県・その他の離島につきましてはご利用・お届けができないことを予めご了承ください。"
            ),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/pc/delivery.png") },
          }),
          _vm._m(0),
        ]),
        _vm._m(1),
        _vm._m(2),
      ]),
      _c("router-link", { attrs: { to: "/guides/question" } }, [
        _c("div", { staticClass: "button" }, [
          _c("span", [_vm._v("他のご質問はこちら")]),
          _c("img", {
            attrs: { src: require("@/assets/images/pc/button_arrow.png") },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/guides_about_arrow.png") },
      }),
      _c("span", [_vm._v("About delivery")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "title" }, [_vm._v("02 配送日時について")]),
      _c("div", { staticClass: "text-content" }, [
        _vm._v(
          " 大型家具商品(取り寄せ)のお届けまでは時間がかかるため、ご注文の際に配送希望日時の指定が不可となっております。"
        ),
        _c("br"),
        _vm._v(
          " 通常在庫品は、ご入金確認後から1週間程度で順次発送をさせていただきます。"
        ),
        _c("br"),
        _vm._v(
          " 取り寄せ品は各商品ページでご確認ください。最大約60日前後発送いたします。"
        ),
        _c("br"),
        _vm._v(
          " 発送準備が完了した商品から「ご注文商品出荷のお知らせ」メールにてご連絡し、順番に出荷となります。"
        ),
        _c("br"),
        _vm._v(
          " 工場生産状況や港の混雑に伴いにより、お届けが遅れる場合がございますので、予めご了承くださいませ。"
        ),
        _c("br"),
        _vm._v(" ★★複数商品をご注文の場合のお届け★★"),
        _c("br"),
        _vm._v(
          " すべての商品のお届けができる最短のお日にちにて、まとめて発送致します。"
        ),
        _c("br"),
        _vm._v(
          " ただし、欠品等で納期が大幅に遅れてしまう場合は、複数に分けてのお届けとなる場合がございます。"
        ),
        _c("br"),
        _vm._v(
          " その際は、メール、またはお電話にて手配方法についてご相談させていただきます。 "
        ),
      ]),
      _c("div", { staticClass: "about" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/pc/guides_about_arrow.png") },
        }),
        _c("span", [_vm._v("About delivery date and time")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "title" }, [_vm._v("03 送料について")]),
      _c("div", { staticClass: "text-content" }, [
        _vm._v("送料は、1回のご注文につき3,000円（税込）となります。"),
      ]),
      _c("p", { staticClass: "p1 red" }, [
        _vm._v(
          "※北海道・沖縄・鹿児島・離島へのお届けは、別途追加送料が必要です。"
        ),
      ]),
      _c("table", [
        _c("tr", [
          _c("td", [_vm._v("合計が54,000円(税込)未満")]),
          _c("td", [_vm._v("合計が54,000円(税込)以上")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("送料3,000円")]),
          _c("td", [_vm._v("送料無料")]),
        ]),
      ]),
      _c("p", { staticClass: "p2" }, [
        _vm._v(" 対象外※配送業者による組立作業などの付帯料金・手数料"),
        _c("br"),
        _vm._v(
          " お客様都合による受取辞退、長期不在、再発送をされますと送料は別途かかります。（再送・転送料金込み） "
        ),
      ]),
      _c("div", { staticClass: "about" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/pc/guides_about_arrow.png") },
        }),
        _c("span", [_vm._v("About shipping cost")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }