var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "bread-crumbs" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / "),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _vm._v(" / お支払方法 "),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
      _c("router-link", { attrs: { to: "/guides/question" } }, [
        _c("div", { staticClass: "button" }, [_vm._v("他のご質問はこちら")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-content" }, [
      _vm._v(" お支払方法 "),
      _c("img", {
        attrs: { src: require("@/assets/images/sp/guides_payment.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lists-content" }, [
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("01 クレジット決済")]),
        _c("div", { staticClass: "text" }, [
          _vm._v("ご利用可能なクレジットカード"),
        ]),
        _c("img", {
          staticClass: "payment-img",
          attrs: { src: require("@/assets/images/sp/payment_img.png") },
        }),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " ※ 海外発行のクレジットカードはご利用いただけませんので、あらかじめご了承ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " また、決済処理はご注文確定の際におこなっております。引き落とし名義は「はんこプレミアム」と記載されます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 引き落とし日時につきましては、ご利用のクレジットカードの締め日や契約内容により異なりますので、ご利用のクレジットカード会社に直接お問合せをお願いいたします。 "
          ),
        ]),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("Credit payment")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("02 銀行振込について")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 銀行振込は前払いですので、お支払いを確認させていただいてからの生産及び出荷手配となります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("お振込は下記の口座にお願いいたします。 "),
        ]),
        _c("table", { staticClass: "table" }, [
          _c("tr", [
            _c("td", [_vm._v("三井住友銀行")]),
            _c("td", [_vm._v("ゆうちょ銀行")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("船橋支店 普通 7154834")]),
            _c("td", [_vm._v("記号10580 番号37025671")]),
          ]),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _vm._v("口座名義"),
              _c("span", { staticStyle: { "margin-left": ".4rem" } }, [
                _vm._v("ハンコプレミアム( カ"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " ※振込み手数料はお客様のご負担となりますのでご了承ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "※ご注文後の7日以内にお振込み下さい。当店よりお振込用紙は送付いたしておりません。 "
          ),
        ]),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("Bank transfer")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v("03")]),
          _c("img", {
            attrs: { src: require("@/assets/images/sp/guides_amazon.png") },
          }),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " Amazonに登録されている住所・クレジットカード情報を使用してお支払いができるサービスです。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "会員登録をせずにご購入の際はログイン画面よりAmazon Payを選択の上、決済手続きにお進みください "
          ),
        ]),
        _c(
          "div",
          { staticClass: "desc", staticStyle: { "margin-top": ".68rem" } },
          [
            _vm._v(" 【注意事項】"),
            _c("br"),
            _vm._v(
              " ※会員サービス（ポイント・クーポン等）をご利用いただくには、woodlife.jp会員情報でのログインが必要です。"
            ),
            _c("br"),
            _vm._v(
              " ※Amazonアカウントでログインいただいた場合、Amazon Pay以外のお支払い方法は選択できません。"
            ),
            _c("br"),
            _vm._v(" ※Amazonポイントはつきません。"),
            _c("br"),
            _vm._v(
              " ※Amazonアカウントでご注文後は配送先・お支払い方法の変更はできません。 "
            ),
          ]
        ),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("Amazonpay")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("04 PayPay")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " PayPayは事前にチャージした電子マネー(PayPay残高)を使ってお支払いができるサービスです。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("詳しいお支払手順はこちらからご確認ください。 "),
        ]),
        _c(
          "div",
          { staticClass: "desc", staticStyle: { "margin-top": ".68rem" } },
          [
            _vm._v(" 【注意事項】"),
            _c("br"),
            _vm._v(
              " ネットサービスの支払いで使用できるのはPayPay残高のみとなります。"
            ),
            _c("br"),
            _vm._v(
              " ※残高による一括払いのみとなり残高不足分の場合はご利用いただけません。"
            ),
            _c("br"),
            _vm._v(" ※PayPayから他のお支払い方法への変更はできません。"),
            _c("br"),
            _vm._v(
              " ※注文後、お客様ご自身でのキャンセルはできません。当店までお問い合わせください。"
            ),
            _c("br"),
            _vm._v(
              " また、購入商品の一部キャンセルは承ることはできませんので、予めご了承ください。 "
            ),
          ]
        ),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("Paypay")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("05 楽天ペイ")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " いつもの楽天IDとパスワードを使ってスムーズなお支払いが可能です。"
          ),
          _c("br"),
          _vm._v(
            " 楽天ポイントが貯まる・使える！「簡単」「あんしん」「お得」な楽天ペイをご利用ください。"
          ),
          _c("br"),
          _vm._v(" 楽天ペイの詳細については "),
          _c(
            "a",
            {
              attrs: {
                href: "https://checkout.rakuten.co.jp/",
                target: "_blank",
              },
            },
            [_vm._v("こちら")]
          ),
          _vm._v(" よりお願いします。 "),
        ]),
        _c(
          "div",
          { staticClass: "desc", staticStyle: { "margin-top": ".68rem" } },
          [
            _vm._v(" 【注意事項】"),
            _c("br"),
            _vm._v(" ※ご利用には楽天IDが必要です。"),
            _c("br"),
            _vm._v(
              " ※ご利用の際、楽天ペイのお支払い画面に移動してご購入手続きを行ってください。"
            ),
            _c("br"),
            _vm._v(" ※楽天ポイントのご利用履歴は「"),
            _c(
              "a",
              {
                attrs: {
                  href: "https://my.checkout.rakuten.co.jp/mych/",
                  target: "_blank",
                },
              },
              [_vm._v("楽天ペイ利用履歴")]
            ),
            _vm._v(
              "」にてご確認ください。当サイトのマイページには表示されません。"
            ),
            _c("br"),
            _vm._v(
              " ※クレジットカードの有効期限により、お支払い方法を変更していただく場合があります。あらかじめご了承ください。"
            ),
            _c("br"),
            _vm._v(
              " ※楽天ポイントが貯まるのは楽天カード・楽天ポイント・楽天キャッシュでのお支払いに限ります。"
            ),
            _c("br"),
            _vm._v(
              " ※取り寄せ品・入荷予定商品につきましては、お支払方法としてご利用いただけません。予めご了承をお願いいたします。 "
            ),
          ]
        ),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("楽天ペイ")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }