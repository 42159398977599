var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "guides-content" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/pc/breadcrumb_arrow.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("ご利用ガイド")]),
      ],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "category-content" },
      [
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/order" } },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/pc/guides_order.png") },
            }),
            _c("span", [_vm._v("ご注文について")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/payment" } },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/pc/guides_payment.png") },
            }),
            _c("span", [_vm._v("お支払い方法について")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/delivery" } },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/pc/guides_delivery.png") },
            }),
            _c("span", [_vm._v("配送·送料について")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/install" } },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/pc/guides_install.png") },
            }),
            _c("span", [_vm._v("搬入・組立て・設置")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/return" } },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/pc/guides_return.png") },
            }),
            _c("span", [_vm._v("返品·交換について")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/question" } },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/pc/guides_question.png") },
            }),
            _c("span", [_vm._v("よくあるご質問")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/afterService" } },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/pc/guides_after_service.png"),
              },
            }),
            _c("span", [_vm._v("アフターサービスについて")]),
          ]
        ),
        _c(
          "router-link",
          { staticClass: "item", attrs: { to: "/guides/about" } },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/pc/guides_about.png") },
            }),
            _c("span", [_vm._v("WOODLIFEについて")]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "question-content" },
      [
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "ご質問に該当がなかった場合は、下記の窓口へお問い合わせください。"
          ),
        ]),
        _c("router-link", { attrs: { to: "/question" } }, [
          _c("div", { staticClass: "button" }, [
            _vm._v(" お問い合わせフォーム"),
            _c("img", {
              attrs: { src: require("@/assets/images/pc/button_arrow.png") },
            }),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "name" }, [_vm._v("SHOPPING GUIDE")]),
      _c("div", { staticClass: "text" }, [_vm._v("ご利用ガイド")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }