var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icons-container" }, [
    _c(
      "div",
      { staticClass: "icons-wrapper" },
      _vm._l(_vm.iconsMap, function (item) {
        return _c(
          "div",
          {
            key: item,
            on: {
              click: function ($event) {
                _vm.handleClipboard(_vm.generateIconCode(item), $event)
              },
            },
          },
          [
            _c("el-tooltip", { attrs: { placement: "top" } }, [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(" " + _vm._s(_vm.generateIconCode(item)) + " "),
              ]),
              _c(
                "div",
                { staticClass: "icon-item" },
                [
                  _c("svg-icon", {
                    attrs: { "icon-class": item, "class-name": "disabled" },
                  }),
                  _c("span", [_vm._v(_vm._s(item))]),
                ],
                1
              ),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }