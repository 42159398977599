var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-content" },
    [
      _c("PaymentHeader"),
      _vm._m(0),
      _c("div", { staticClass: "shop-content" }, [
        _c(
          "div",
          { staticClass: "left-content" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-position": "top",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { prop: "address.receiver_email", label: "" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "連絡先" },
                      model: {
                        value: _vm.ruleForm.address.receiver_email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm.address,
                            "receiver_email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "ruleForm.address.receiver_email",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "delivery-content" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("配送方法")]),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: _vm.delivery,
                              callback: function ($$v) {
                                _vm.delivery = $$v
                              },
                              expression: "delivery",
                            },
                          },
                          [_c("span", [_vm._v("通常出荷")])]
                        ),
                        _c("div", { staticClass: "price" }, [
                          _vm._v(
                            "¥" +
                              _vm._s(
                                _vm.calcThousands(
                                  _vm.priceParams.freight_amount
                                )
                              )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "detail" }, [
                      _vm._v(
                        " 配送は玄関先までとなります。開梱や組立、梱包材の回収はございません。"
                      ),
                      _c("br"),
                      _vm._v(
                        " 発送準備が完了した商品から順番に出荷となります。"
                      ),
                      _c("br"),
                      _vm._v(
                        " オーダー家具は、お届けまでにお時間を頂戴します。出荷目安は各商品ページにご確認ください。 "
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "address-content" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("お届け先の住所"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "inline-item" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "address.first_name", label: "姓" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "姓" },
                              model: {
                                value: _vm.ruleForm.address.first_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.address,
                                    "first_name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.address.first_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "address.last_name", label: "名" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "名" },
                              model: {
                                value: _vm.ruleForm.address.last_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.address,
                                    "last_name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.address.last_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "inline-item" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "address.phone", label: "電話番号" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "11",
                                placeholder: "電話番号",
                              },
                              model: {
                                value: _vm.ruleForm.address.phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.address,
                                    "phone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.address.phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "address.zip_code",
                              label: "郵便番号",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "7",
                                placeholder: "郵便番号",
                              },
                              on: { change: _vm.changeZipCode },
                              model: {
                                value: _vm.ruleForm.address.zip_code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.address,
                                    "zip_code",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.address.zip_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "inline-item" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "address.stats", label: "都道府県" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "都道府県" },
                                model: {
                                  value: _vm.ruleForm.address.stats,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm.address, "stats", $$v)
                                  },
                                  expression: "ruleForm.address.stats",
                                },
                              },
                              _vm._l(_vm.areaData, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.name },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "address.city", label: "市区町村" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "市区町村" },
                              model: {
                                value: _vm.ruleForm.address.city,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.address,
                                    "city",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.address.city",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { prop: "address.address", label: "番地住所" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "番地住所" },
                          model: {
                            value: _vm.ruleForm.address.address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.address,
                                "address",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.address.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          prop: "address.detail_address",
                          label: "建物名・部屋番号（任意）",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "建物名・部屋番号（任意）" },
                          model: {
                            value: _vm.ruleForm.address.detail_address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.address,
                                "detail_address",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.address.detail_address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.token
                      ? _c(
                          "el-form-item",
                          { staticStyle: { width: "100%" } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.checked,
                                  callback: function ($$v) {
                                    _vm.checked = $$v
                                  },
                                  expression: "checked",
                                },
                              },
                              [_vm._v("よく使う住所として登録")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "note-content" },
                  [
                    _c("div", { staticClass: "title" }, [_vm._v("備考欄")]),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        placeholder: "備考欄（任意）",
                      },
                      model: {
                        value: _vm.ruleForm.note,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "note", $$v)
                        },
                        expression: "ruleForm.note",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "right-content" }, [
          _c(
            "div",
            { staticClass: "sticky" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v("買い物カート内の商品"),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("div", { staticClass: "item font16" }, [
                  _c("div", [_vm._v("送料")]),
                  _c("div", [
                    _vm._v(
                      "￥" +
                        _vm._s(
                          _vm.calcThousands(_vm.priceParams.freight_amount)
                        )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", [_vm._v("合計")]),
                  _c("div", { staticClass: "price" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(_vm.calcThousands(_vm.priceParams.pay_amount))
                    ),
                  ]),
                ]),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "next-btn",
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.toPayment },
                },
                [_vm._v("支払い方法に進む")]
              ),
              _c("router-link", { attrs: { to: "/cart" } }, [
                _c("div", { staticClass: "light-btn" }, [
                  _vm._v("カートに戻る"),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-content" }, [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step1_border.png") },
          }),
          _c("div", [_vm._v("カート")]),
        ]),
        _c("img", {
          staticClass: "step-line1",
          attrs: { src: require("@/assets/images/pc/step_line1_in.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step2_in.png") },
          }),
          _c("div", [_vm._v("配送")]),
        ]),
        _c("img", {
          staticClass: "step-line2",
          attrs: { src: require("@/assets/images/pc/step_line2.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step3.png") },
          }),
          _c("div", [_vm._v("支払い")]),
        ]),
        _c("img", {
          staticClass: "step-line1",
          attrs: { src: require("@/assets/images/pc/step_line1.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step4.png") },
          }),
          _c("div", [_vm._v("注文完了")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }