<template>
  <div class="register-content">
    <div class="breadcrumb">
      <div class="breadcrumb">
        <router-link to="/">家具通販専門店TOP</router-link>
        <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
        <span>新規ユーザー登録完了</span>
      </div>
    </div>
    <div class="content">
      <div class="title">認証メールを送信いたしました。</div>
      <div class="username">{{ firstName }} {{ lastName }} 様</div>
      <div class="text">
        会員登録ありがとうございます。<br>
        それではショッピングをお楽しみください。<br><br>
        「 www.woodlife.com 」に認証メールを送信いたしました。<br>
        メールに記載されたURLをクリックして、会員登録を完了させてくださいませ。<br><br>
        ※認証用URLの有効期限は12時間です。<br><br>
        メールアドレスが受け取り可能なものにもかかわらずご確認メールが届かない場合、<br>
        お問い合わせ窓口までご連絡くださいませ。
      </div>
      <router-link to="/">
        <div class="button">トップページ</div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      firstName: this.$route.query.first_name,
      lastName: this.$route.query.last_name
    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.register-content{
    width: 1440px;
    margin: 0 auto 80px;
    .breadcrumb{
        margin-top: 28px;
    }
    .content{
        padding-left: 460px;
        margin-top: 54px;
        .title{
            font-size: 28px;
            font-weight: 700;
            color: #4bc4b0;
        }
        .username{
            margin-top: 56px;
            font-size: 20px;
            font-weight: 700;
        }
        .text{
            margin-top: 24px;
            font-size: 16px;
        }
        .button{
            width: 204px;
            height: 48px;
            margin-top: 32px;
            line-height: 48px;
            text-align: center;
            background-color: #333;
            color: #fff;
            font-size: 16px;
        }
    }
}
</style>
