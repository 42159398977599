<template>
  <div class="order-content">
    <router-link to="/">
      <img src="@/assets/images/sp/logo_sp.png" class="logo">
    </router-link>
    <img src="@/assets/images/sp/step3.png" class="step">
    <div class="margin-box">
      <div class="text-content">
        <div class="title">ご注文ありがとうございます！</div>
        <div class="order-sn">
          ご注文番号：<span>{{ initData.order_sn }}<img src="@/assets/images/sp/order_sn_bg.png" alt=""></span>
        </div>
        <div class="text">
          発送準備が完了しましたら順番に出荷となります。<br>オーダー家具は、お届けまでにお時間を頂戴します。<br>商品の到着までしばらくお待ちください。
        </div>
      </div>
      <table class="table">
        <tr>
          <td>連絡先</td>
          <td>{{ initData.receiver_email }}</td>
        </tr>
        <tr>
          <td>配送先</td>
          <td>
            <p>{{ initData.receiver_name }} 様</p>
            <p>〒{{ initData.zip_code }} {{ initData.stats }}{{ initData.city }}{{ initData.address }}</p>
            <p>{{ initData.detail_address ? initData.detail_address : '' }}</p>
            <p>{{ initData.receiver_phone }}</p>
          </td>
        </tr>
        <tr>
          <td>配送方法</td>
          <td>
            <div class="flex">
              <div class="name">通常出荷</div>
              <div class="value">¥{{ calcThousands(initData.freight_amount) }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td>備考欄</td>
          <td>{{ initData.note }}</td>
        </tr>
        <tr>
          <td>支払方法</td>
          <td>
            <div class="flex">
              <div class="name">{{ initData.pay_type === 2 ? '銀行振込・前払い' : initData.pay_type === 3 ? 'クレジットカード決済' : initData.pay_type === 4 ? 'amazon pay': initData.pay_type === 8 ? 'paypay': '' }}</div>
              <div class="value"> ¥{{ calcThousands(Number(initData.pay_amount)) }}</div>
            </div>
          </td>
        </tr>
      </table>
      <div v-if="initData.pay_type === 2" class="table-content">
        <div class="text">
          ご注文後7営業日以内お振込みくださいませ。<br>ご入金が確認でき次第商品手配とさせていただきます。
        </div>
        <table class="table">
          <tr>
            <td>銀行名</td>
            <td>三井住友銀行</td>
          </tr>
          <tr>
            <td>支店名</td>
            <td>船橋支店</td>
          </tr>
          <tr>
            <td>口座番号</td>
            <td>7154834（普）</td>
          </tr>
          <tr>
            <td>口座名義</td>
            <td>ハンコプレミアム（カ</td>
          </tr>
          <tr>
            <td>振込み手数料</td>
            <td>お客様ご負担</td>
          </tr>
          <tr>
            <td>お支払期限</td>
            <td>ご注文から7営業日まで</td>
          </tr>
        </table>
        <table class="table">
          <tr>
            <td>銀行名</td>
            <td>ゆうちょ銀行</td>
          </tr>
          <tr>
            <td>記号</td>
            <td>10580</td>
          </tr>
          <tr>
            <td>番号</td>
            <td>7154834（普）</td>
          </tr>
          <tr>
            <td>口座名義</td>
            <td>ハンコプレミアム（カ</td>
          </tr>
          <tr>
            <td>振込み手数料</td>
            <td>お客様ご負担</td>
          </tr>
          <tr>
            <td>お支払期限</td>
            <td>ご注文から7営業日まで</td>
          </tr>
        </table>
        <div class="text2">
          &lt;他行から振り込みの場合&gt;<br>
          【店 名】058 ゼロゴハチ<br>
          【口座番号】3702567<br>
          ********************************************
        </div>
      </div>
      <router-link to="/">
        <div class="button">ホームページに戻る</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getOrderDetail, getOrderSnDetail } from '@/api/order'
import { calcThousands } from '@/utils/validate'
import { getMaf } from '@/utils/auth'
import { getQueryString } from '@/utils/validate'
export default {
  components: { },
  data() {
    return {
      initData: {
        pay_amount: 0,
        freight_amount: 0,
        receiver_name: ''
      },
      orderSn: getQueryString('order_sn'),
      calcThousands
    }
  },
  created() {
    if (this.orderSn) {
      this.getOrderSnDetail()
    } else {
      this.getOrderDetail()
    }
    this.$store.dispatch('user/getCartNumber')
  },
  methods: {
    getOrderDetail() {
      getOrderDetail().then(result => {
        this.initData = result
        const body = document.body || document.getElementsByTagName('body')[0]
        const script = document.createElement('script')
        const script2 = document.createElement('script') // もしもアフィリエイト
        const script3 = document.createElement('script') // yahoo
        const noscript = document.createElement('noscript')
        const script4 = document.createElement('script') // ga4
        const script5 = document.createElement('script') // BannerBridge
        const script6 = document.createElement('script') // yahoo
        const maf = getMaf() || ''
        script2.src = `https://r.moshimo.com/af/r/result?p_id=4540&pc_id=11849&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}`
        noscript.innerHTML = `<img src="https://r.moshimo.com/af/r/result?p_id=1578&pc_id=2875&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}" width="1" height="1" alt="">`
        let arr = []
        let ga4Arr = []
        script2.setAttribute('id', 'msmaf')
        this.initData.order_products.forEach(item => {
          arr.push({
            'code': item.product_sn,
            'price': Number(item.product_price),
            'quantity': item.product_quantity
          })
          ga4Arr.push({
            item_id: `${item.product_sn}`,
            item_name: `${item.product_title}`,
            price: Number(item.product_sku_price),
            quantity: item.product_quantity
          })
        })
        arr = (JSON.stringify(arr)).replace('}]', '},]')
        ga4Arr = (JSON.stringify(ga4Arr))
        let value = `
          a8sales({
          "pid": "s00000013030006",
          "order_number": "${this.initData.order_sn}",
          "currency": "JPY",
          "items":${arr},
          "total_price": ${this.initData.pay_amount}
          });
        `
        const payAmount = (Number(this.initData.total_amount) / 1.1).toFixed()
        value += `
        (function(){var aid="${this.initData.order_sn}",buy="${payAmount}";var pid="13671",mode="dck";var s=document.cookie.split(";");var l=pid.split("_");var t=0,p,c;for(var i=0;i<s.length;i++){var x=s[i].split("=",2);var k=x[0].trim();var v=x[1].trim().split("T",2);for(var j=0;j<l.length;j++){if(k=="NQINPX_"+l[j]&&v[0]&&+v[1]>t){p=l[j];c=v[0];t=+v[1];}}}var u=t?"https://advack.net/m2/img.php?pcode="+p+"&cid="+encodeURIComponent(c):"https://advack.net/m/img.php?pcode="+pid;var g=document.createElement("img");g.width=1;g.height=1;g.src=u+"&md="+mode+"&aid="+encodeURIComponent(aid)+"&buy="+encodeURIComponent(buy);document.body.appendChild(g);})();
        `
        script.innerHTML = value
        script3.innerHTML = `
        ytag({
        "type": "yss_conversion",
        "config": {
          "yahoo_conversion_id": "1001262176",
          "yahoo_conversion_label": "dx5GCJPB4N0DEIjJvfYo",
          "yahoo_conversion_value": "${this.initData.pay_amount}"
        }
        });
        `
        script3.async = 'async'
        script4.innerHTML = `
         dataLayer.push({ ecommerce:null });
                dataLayer.push({
                    event: 'purchase',
                    ecommerce: {
                      transaction_id: "${this.initData.order_sn}",
                      value: ${this.initData.pay_amount},
                      currency: "JPY",
                      items: ${ga4Arr}
                    }
                });
        `
        script5.src = `https://track.bannerbridge.net/sale_js.php?proID=19443&trans_amount=${this.initData.pay_amount}&orderid=${this.initData.order_sn}`
        script6.innerHTML = `
        ytag({
          "type":"yjad_conversion",
          "config":{
            "yahoo_ydn_conv_io": "JBw00kzVYy7Yg99wJVv7NQ..",
            "yahoo_ydn_conv_label": "CEAKP2CFIRI351LJ8T1065092",
            "yahoo_ydn_conv_transaction_id": "${this.initData.order_sn}",
            "yahoo_ydn_conv_value": "${this.initData.pay_amount}",
          }
        });
        `
        script6.async = 'async'
        body.appendChild(script)
        body.appendChild(script2)
        body.appendChild(noscript)
        body.appendChild(script3)
        body.appendChild(script4)
        body.appendChild(script5)
        body.appendChild(script6)
        const codeJs = document.querySelector('#codejs')
        codeJs.innerHTML += `fbq('track', 'Purchase', {value: ${this.initData.pay_amount}, currency: 'JPY'});`
      })
    },
    getOrderSnDetail() {
      getOrderSnDetail(this.orderSn, { include: 'orderProducts' }).then(result => {
        this.initData = result
        const body = document.body || document.getElementsByTagName('body')[0]
        const script = document.createElement('script')
        const script2 = document.createElement('script') // もしもアフィリエイト
        const script3 = document.createElement('script') // yahoo
        const noscript = document.createElement('noscript')
        const script4 = document.createElement('script') // ga4
        const script5 = document.createElement('script') // BannerBridge
        const script6 = document.createElement('script') // yahoo
        const maf = getMaf() || ''
        script2.src = `https://r.moshimo.com/af/r/result?p_id=4540&pc_id=11849&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}`
        noscript.innerHTML = `<img src="https://r.moshimo.com/af/r/result?p_id=1578&pc_id=2875&m_v=${this.initData.order_sn}&p=${this.initData.pay_amount}&rd_code= ${maf}" width="1" height="1" alt="">`
        let arr = []
        let ga4Arr = []
        script2.setAttribute('id', 'msmaf')
        this.initData.order_products.forEach(item => {
          arr.push({
            'code': item.product_sn,
            'price': Number(item.product_price),
            'quantity': item.product_quantity
          })
          ga4Arr.push({
            item_id: `${item.product_sn}`,
            item_name: `${item.product_title}`,
            price: Number(item.product_sku_price),
            quantity: item.product_quantity
          })
        })
        arr = (JSON.stringify(arr)).replace('}]', '},]')
        ga4Arr = (JSON.stringify(ga4Arr))
        let value = `
          a8sales({
          "pid": "s00000013030006",
          "order_number": "${this.initData.order_sn}",
          "currency": "JPY",
          "items":${arr},
          "total_price": ${this.initData.pay_amount}
          });
        `
        const payAmount = (Number(this.initData.total_amount) / 1.1).toFixed()
        value += `
        (function(){var aid="${this.initData.order_sn}",buy="${payAmount}";var pid="13671",mode="dck";var s=document.cookie.split(";");var l=pid.split("_");var t=0,p,c;for(var i=0;i<s.length;i++){var x=s[i].split("=",2);var k=x[0].trim();var v=x[1].trim().split("T",2);for(var j=0;j<l.length;j++){if(k=="NQINPX_"+l[j]&&v[0]&&+v[1]>t){p=l[j];c=v[0];t=+v[1];}}}var u=t?"https://advack.net/m2/img.php?pcode="+p+"&cid="+encodeURIComponent(c):"https://advack.net/m/img.php?pcode="+pid;var g=document.createElement("img");g.width=1;g.height=1;g.src=u+"&md="+mode+"&aid="+encodeURIComponent(aid)+"&buy="+encodeURIComponent(buy);document.body.appendChild(g);})();
        `
        script.innerHTML = value
        script3.innerHTML = `
        ytag({
        "type": "yss_conversion",
        "config": {
          "yahoo_conversion_id": "1001262176",
          "yahoo_conversion_label": "dx5GCJPB4N0DEIjJvfYo",
          "yahoo_conversion_value": "${this.initData.pay_amount}"
        }
        });
        `
        script3.async = 'async'
        script4.innerHTML = `
         dataLayer.push({ ecommerce:null });
                dataLayer.push({
                    event: 'purchase',
                    ecommerce: {
                      transaction_id: "${this.initData.order_sn}",
                      value: ${this.initData.pay_amount},
                      currency: "JPY",
                      items: ${ga4Arr}
                    }
                });
        `
        script5.src = `https://track.bannerbridge.net/sale_js.php?proID=19443&trans_amount=${this.initData.pay_amount}&orderid=${this.initData.order_sn}`
        script6.innerHTML = `
        ytag({
          "type":"yjad_conversion",
          "config":{
            "yahoo_ydn_conv_io": "JBw00kzVYy7Yg99wJVv7NQ..",
            "yahoo_ydn_conv_label": "CEAKP2CFIRI351LJ8T1065092",
            "yahoo_ydn_conv_transaction_id": "${this.initData.order_sn}",
            "yahoo_ydn_conv_value": "${this.initData.pay_amount}",
          }
        });
        `
        script6.async = 'async'
        body.appendChild(script)
        body.appendChild(script2)
        body.appendChild(noscript)
        body.appendChild(script3)
        body.appendChild(script4)
        body.appendChild(script5)
        body.appendChild(script6)
        const codeJs = document.querySelector('#codejs')
        codeJs.innerHTML += `fbq('track', 'Purchase', {value: ${this.initData.pay_amount}, currency: 'JPY'});`
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.order-content{
    .logo{
        display: block;
        width: 3.74rem;
        margin: .34rem auto;
    }
     .flex{
        display: flex;
        justify-content: space-between;
    }
    .margin-box{
        margin: 0 .24rem;
    }
    .text-content{
        margin-top: .56rem;
        text-align: center;
        .title{
            font-size: .46rem;
            font-weight: 700;
            color: #4BC4B0;
        }
         .order-sn{
            margin-top: .24rem;
            font-size: .32rem;
            span{
                position: relative;
                display: inline-block;
                font-weight: 700;
            }
            img{
                width: 2.64rem;
                height: .12rem;
                position: absolute;
                left: -.06rem;
                bottom: -.12rem;
            }
      }
      .text{
                margin-top: .54rem;
                line-height: .48rem;
            }
    }
    .table{
        width: 100%;
        margin-top: .64rem;
        td{
            padding: .24rem;
            border: .02rem solid #333333;
            &:first-of-type{
                width: 2.2rem;
            }
        }
        .value{
            color: #c40a0a;
        }
    }
    .table-content{
        padding: .36rem .24rem;
        background: #f4f5fc;
        .text1{
            line-height: .42rem;
        }
        .text2{
            margin-top: .3rem;
            line-height: .42rem;
        }
        .table{
            &:first-of-type{
                margin-top: .3rem;
            }
            &:last-of-type{
                margin-top: .24rem;
            }
        }
    }
    .button{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
