<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/guides/category">ご利用ガイド</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>搬入・設置について</span>
    </div>
    <div class="list-content">
      <div class="list">
        <div class="title">01 搬入について</div>
        <div class="text-content">
          <div class="red">
            配送は玄関先までとなります。設置・組立はお客様作業にてお願いしております。<br><br>
            ※エレベーターの無い場合、階段上げ手数料が発生いたします。
          </div>
          <br>
          玄関以外への移動が必要な場合、お客様ご自身にてご対応のほどよろしくお願いいたします。<br><br>
          お客様組み立て商品になりますが、組立設置サービスを付けられる商品がございます。詳細は各商品ページをご参照ください。<br><br>
          組み立て商品は、配送後に長く放置をしておきますと、部材が変形する可能性がございます（不具合ではございません）。<br><br>
          なるべくお早めに組み立てをいただけますようお願いいたします。<br><br><br>
          ご注文の前には、商品梱包が玄関・階段の幅・踊り場・部屋の間口等を通るかどうかを予めご確認くださいますようお願いたします。 <br> <br>
          設置・組立てご希望の場合でも、家具の大きさ等により、搬入できない場合がございますので、搬入経路のご確認をお願い致します。 <br> <br>
          <div class="red underline">※搬入経路が狭かったため、搬入出来なかった場合も、ご注文キャンセル・返品などはお受けできませんので、予めご了承くださいませ。</div>
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>About returns</span>
        </div>
      </div>
      <div class="list">
        <div class="title">02 経路確認</div>
        <div class="num-bg"><span>1</span>商品のサイズ</div>
        <div class="img-content">
          <div>
            梱包時の寸法または製品の横幅（W）、高さ（H）、奥行（D）をご確認ください。 <br><br>
            傾けたりすることができる製品かも合わせて確認してください。 <br><br>
            搬入可否については天地左右＋10㎝以上の余裕を持って判断することをおすすめします。
          </div>
          <img src="@/assets/images/pc/install_1.png">
        </div>
        <div class="num-bg"><span>2</span>エレベーターのサイズ</div>
        <div class="img-content">
          <div>
            エレベーターがある場合は十分にサイズをご確認ください。<br><br>
            エレベーター入口と内部の有効寸法<br><br>
            傾けることができる商品の場合、横向きに搬入してエレベーター内で縦置きにすることも可能です。<br>
            エレベーターから降ろすときに切り返しが発生する場合は後述している「4.通路/廊下/扉」の項目と併せて確<br>
            認ください。<br>
            ※幅は0.8M以下，高さは2.1M以下は大形家具が搬入困難です。
          </div>
          <img src="@/assets/images/pc/install_2.png">
        </div>
        <div class="num-bg"><span>3</span>階段</div>
        <div class="img-content">
          <div>
            天井の高さが足りず、搬入ができないことがあります。特に踊り場では商品を立てられる高<br>
            さが必要です。螺旋や直角に曲がる階段では特にご注意ください。 <br><br>
            1.階段の寸法と手すりや照明などの障害物がないか。<br><br>
            2.切り返しのある階段の場合、踊り場の寸法が重要になります。 <br><br>
            ※螺旋階段や踊り場で家具を立ち上げて折り返す場合がありますので、踊り場の天井の高さ<br>
            は梱包サイズの1番長い部分より必要です。 <br><br>
            ※階段幅は1M以下は大形家具が搬入困難です。
          </div>
          <img src="@/assets/images/pc/install_3.png">
        </div>
        <div class="num-bg"><span>4</span>通路/廊下/扉</div>
        <div class="img-content">
          <div>
            通路や廊下の有効幅が特に重要な箇所になります。 <br>
            コーナー(角)がある場合はその対角幅もポイントの一つです。 <br>
            コーナーを曲がるには商品を斜めに切り返さなければなりません。  <br>
            またコーナーの切り返しが厳しい場所でも十分な天井高があれば搬入できる場合があります。  <br>
            扉が90°以上開くか、また開けたとき取手から壁までの距離が十分あるかが重要です。 <br>
            通路は通っても扉の造りによっては搬入できない場合がございます。 <br>
            ※玄関ドア幅は0.8M以下、通路幅は1M以下は大形家具が搬入困難です。
          </div>
          <img src="@/assets/images/pc/install_4.png">
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>About returns</span>
        </div>
      </div>
      <div class="list">
        <div class="title">03 組立・設置について</div>
        <div class="text-content">
          原則は玄関先でのお渡しになりますが、すぐにお使いたいお客様に1点からお申し込みできるサービスです。（他商品との混載輸送になります。）<br><br>
          ご自宅まで2名スタッフで伺い、テーブル・ベッド・ソファなど一部の家具は有料にて開梱・設置・廃材（梱包材）回収まで行います。<br><br>
          対象の商品は、「組立品」アイコンがついている商品となります。<br><br>
          ご希望の方は、ご注文の際に各商品ページにてオプション項目の「組立を依頼する」にご選択ください、商品をカートへ入れてご注文お手続きをお願いいたします。<br>
          場合によってご注文後の組立追加はお受けできないので、あらかじめご了承ください。<br><br>
          お住まい、お届けの地域によっては、荷受けができない場合もございます。<br>
          沖縄県や、離島へのお荷物の配送はできませんのでご了承ください。お申し込みから配送までにかかる日数は、お申し込みの際にご確認いただけます。<br><br><br>
          【組立サービスの料金】 ➡️ 1点 10,000円（税込）<br><br><br>
          ⚠️【注意点】<br><br>
          ※商品1点あたりの組立料金です。<br><br>
          ※商品によって組立にかかる時間によって料金が変わる場合、改めてご連絡させて頂きます。<br><br>
          ※配送業者はヤマト運輸となります。他の配送業者はお選びいただけません。
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>About exchange</span>
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">
        <span>他のご質問はこちら</span>
        <img src="@/assets/images/pc/button_arrow.png">
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.guides-content{
    width: 1440px;
    margin: 0 auto 80px;
    font-size: 16px;
    .breadcrumb{
        margin-top: 28px;
    }
    .list-content{
        .list{
            padding: 38px 32px 0;
            margin-top: 45px;
            border-top: 1px solid #e8ebed;
            &:last-child{
                padding-bottom: 45px;
                border-bottom: 1px solid #e8ebed;
            }
            .title{
                font-size: 20px;
                font-weight: 700;
            }
            .text-content{
                margin-top: 24px;
                line-height: 24px;
            }
            .img-content{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                line-height: 24px;
                padding: 0 32px;
                margin-top: 19px;
            }
            .num-bg{
              display: flex;
              align-items: center;
              height: 48px;
              font-weight: bold;
              padding: 0 32px;
              background: #F4F5FC;
              margin: 28px 0 24px;
              span{
                display: inline-block;
                width: 15px;
                text-align: center;
                line-height: 0.4;
                padding-left: 1px;
                border-bottom: 4px solid #F8DABF;
                margin: 4px 12px 0 0;
              }
            }
            .red{
                color: #c40a0a;
            }
            .underline{
                text-decoration: underline;
            }
            .about{
                margin-top: 28px;
                font-size: 12px;
                color: #999;
                span{
                    margin-left: 8px;
                }
            }
        }
    }
    .button{
      width: 248px;
      height: 48px;
      margin: 52px auto 0;
      line-height: 48px;
      text-align: center;
      background-color: #333;
      color: #fff;
      cursor: pointer;
      img{
          margin-left: 4px;
      }
    }
}
</style>
