<template>
  <div class="password-content">
    <img src="@/assets/images/sp/reset_pwd_success_icon.png" class="icon">
    <div class="title">パスワードを再設定しました</div>
    <router-link to="/login">
      <div class="button">ログイン</div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.password-content{
    margin-top: 1.9rem;
    text-align: center;
    flex: 1;
    .icon{
        width: 1.05rem;
    }
    .title{
        margin-top: .2rem;
        font-size: .46rem;
        font-weight: 700;
        color: #4bc4b0;
    }
    .button{
      width: 4.08rem;
      height: .8rem;
      margin: .72rem auto 0;
      line-height: .8rem;
      text-align: center;
      color: #ffffff;
      background: #333333;
    }
}
</style>
