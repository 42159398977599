import request from '@/utils/request'
/**
 * 文章api
 */

// url 获取文章分类
export function articleCategories(params) {
  return request({
    url: '/articleCategories',
    method: 'get',
    params
  })
}
// 获取文章列表
export function getArticles(params) {
  return request({
    url: '/articles',
    method: 'get',
    params
  })
}
// 获取文章详情
export function getArticlesDetail(id, params) {
  return request({
    url: '/articles/' + id,
    method: 'get',
    params
  })
}
