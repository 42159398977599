<template>
  <div>
    <div class="tips"><router-link to="/">家具TOP</router-link> / ニュース</div>
    <div class="article-box">
      <div class="article-detail">
        <div class="title">{{ initData.title }}</div>
        <div class="date">{{ initData.created_at.substring(0,10) }}</div>
        <div class="custom" v-html="initData.mobile_desc" />
      </div>
      <div v-if="initData.products.length > 0">
        <div class="view-title">関連商品</div>
        <div class="swiper relation-swiper">
          <div class="swiper-wrapper">
            <router-link v-for="(item,index) in initData.products" :key="index" :to="'/product/'+ item.product_sn" class="swiper-slide">
              <img width="100%" :src="item.image">
              <div class="product-desc">
                <div class="product-name">{{ item.title }}</div>
                <div class="price">￥{{ calcThousands(item.price) }}<span>(税込)</span></div>
              </div>
            </router-link>
          </div>
          <div class="swiper-pagination" />
          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getArticlesDetail } from '@/api/article'
import { calcThousands } from '@/utils/validate'
export default {
  data() {
    return {
      show: false,
      initData: {
        created_at: '',
        products: [],
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        mobile_desc: null,
        mobile_desc_css_path: '',
        mobile_desc_js_path: ''
      }
    }
  },
  metaInfo() {
    return {
      title: this.initData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.initData.seo_keywords },
        { vmid: 'description', name: 'description', content: this.initData.seo_description }
      ],
      link: [
        { vmid: this.initData.mobile_desc_css_path, rel: 'stylesheet', href: this.initData.mobile_desc_css_path }
      ],
      script: [
        { vmid: this.initData.mobile_desc_js_path, async: 'async', src: this.initData.mobile_desc_js_path }
      ]
    }
  },
  computed: {
    id: function() { return this.$route.params.id }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.fetchData()
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    calcThousands,
    fetchData() {
      getArticlesDetail(this.id, { include: 'products' }).then(result => {
        result.mobile_desc_css_path = result.mobile_desc_css_path || ''
        result.mobile_desc_js_path = result.mobile_desc_js_path || ''
        this.initData = result
        this.$nextTick(() => {
          new window.Swiper('.relation-swiper', {
            slidesPerView: 'auto',
            pagination: {
              type: 'progressbar',
              el: '.swiper-pagination'
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tips{
  text-align: center;
  margin: 0.16rem 0 0.16rem;
}
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.article-box{
  margin: 0 0.24rem 0.9rem;
  .title{
    font-size: 0.36rem;
    font-weight: bold;
    margin-top: 0.36rem;
  }
  .date{
    font-size: 0.24rem;
    color: #999;
    margin: 0.24rem 0 0.4rem;
  }
}
.view-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.9rem 0 0.28rem;
  &::before,
  &::after{
    content: '';
    width: 0.8rem;
    height: 0.02rem;
    background: #333333;
    margin: 0 0.4rem;
  }
}
.relation-swiper ::v-deep {
  margin: 0.28rem 0;
  padding-bottom: 1.12rem;
  .swiper-slide{
    width: 3.44rem;
    margin-right: 0.14rem;
  }
  & .swiper-slide:nth-last-child(1){
    margin-right: 0;
  }
  .product-name{
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-top: 0.16rem;
  }
  .price{
    font-weight: bold;
  }
  .swiper-pagination{
    top: auto;
    bottom: 0.32rem;
    width: 4.98rem;
    height: 0.02rem;
    background: #E8EBED;
  }
  .swiper-pagination-progressbar-fill{
    background: #333333;
  }
  .swiper-button-prev{
    top: auto;
    left: auto;
    right: 0.92rem;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/left_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
  .swiper-button-next{
    top: auto;
    right: 0;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/right_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
}
.custom{
 :target {
    scroll-margin-top: 1.4rem;
}
 p{
      line-height: 1.6;
      margin: .4rem 0;
  }
  .share-content{
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  a{
    width: 48%;
    height: .8rem;
    line-height: .8rem;
    border-radius: .06rem;
    color: #ffffff;
    text-align: center;
  }
  .facebook{
    background: #3b5998;
    box-shadow: 0 .04rem #2d4373;
  }
  .line{
    background: #00c300;
    box-shadow: 0 .04rem #009d00;
  }
}
.top-img{
  margin-top: .4rem;
}
  .title-content{
    padding: .2rem;
    margin: 0 auto;
    background: #f9f9f9 none repeat scroll 0 0;
    border: .02rem solid #aaa;
    .top-title{
      margin: .32rem auto;
      font-size: .28rem;
      text-align: center;
      font-weight: 700;
    }
    .list-content{
      color: #2581c4;
      li{
        margin: .12rem 0;
        font-size: .26rem;
      }
      .little{
        margin-left: .1rem;
      }
      a{
        display: block;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .detail-content{
    h2{
      display: block;
      font-size: .32rem;
      margin: .6rem 0 .5rem 0;
      padding: .32rem .2rem .32rem .4rem;
      color: #383939;
      background-color: #f8f8f8;
      border-bottom: .06rem solid #0063dc;
    }
    h3{
      margin: .8rem 0 .4rem;
      font-size: .3rem;
      border-bottom: .04rem solid #0063dc;
      padding-bottom: .26rem;
    }
  }
  .swiper-list-content{
    .list-content{
      margin-top: .6rem;
    }
    .product-title{
      margin-bottom: .2rem;
      font-size: .32rem;
      font-weight: 700;
    }
    .swiper-button-prev{
      background: url('https://www.woodlife.jp/storage/custom/articles/ceramic/swiper_prev.png') no-repeat center center / 100%;
      left: 0;
      &::after{
        content: '';
      }
    }
    .swiper-button-next{
      background: url('https://www.woodlife.jp/storage/custom/articles/ceramic/swiper_next.png') no-repeat center center / 100%;
      right: 0;
      &::after{
        content: '';
      }
    }
    .swiper-pagination-bullet-active{
      background: #333333;
    }
    .product-detail{
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: .3rem;
      border: .02rem solid #cccccc;
      font-size: .24rem;
      img{
        width: 1.8rem;
        height: 1.8rem;
      }
      .content{
        width: 4.16rem;
        .desc{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            margin-top: .08rem;
            color: #666666;
          }
          .price{
            margin-top: .2rem;
            font-size: .4rem;
            font-weight: 700;
          }
          .detail-btn{
            display: block;
            margin-top: .2rem;
            text-decoration: underline;
            color: #666666;
          }
          .tag{
            position: absolute;
            right: 16px;
            top: 58px;
            padding: 4px 8px;
            background-color: #333333;
            color: #ffffff;
          }
      }
    }
  }
}
</style>
