<template>
  <div>
    <div class="tips"><router-link to="/">家具TOP</router-link> / お問い合わせ</div>
    <div class="question-from">
      <div class="question-table">
        <div class="question-table-item">
          <div class="desc">お問い合わせ項目</div>
          <div class="ask">
            <span v-if="ruleForm.type === 1">商品について</span>
            <span v-else-if="ruleForm.type === 2">納期について</span>
            <span v-else-if="ruleForm.type === 3">不良交換について</span>
            <span v-else-if="ruleForm.type ===4">サイトについて</span>
            <span v-else-if="ruleForm.type === 5">その他</span>
          </div>
        </div>
        <div class="question-table-item">
          <div class="desc">お名前</div>
          <div class="ask">{{ ruleForm.username }}</div>
        </div>
        <div class="question-table-item">
          <div class="desc">メールアドレス</div>
          <div class="ask">{{ ruleForm.email }}</div>
        </div>
        <div class="question-table-item">
          <div class="desc">お問い合わせ内容</div>
          <div class="ask">{{ ruleForm.content }}</div>
        </div>
      </div>
      <div class="button">
        <el-button :loading="btnLoading" class="deliver" type="primary" @click="onSubmit">送信する</el-button>
        <el-button class="return" type="primary" @click="$router.go(-1)">修正する</el-button>
      </div>
    </div>
    <van-overlay :show="confirmVisible">
      <div class="wrapper" @click.stop>
        <div class="modal-content">
          <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="handleClose">
          <div class="title">お問い合わせ受付完了</div>
          <div class="text">お問い合わせありがとうございます。<br>順次ご対応いたします、しばらくお待ちください。</div>
          <div class="close" @click="handleClose">閉じる</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { questions } from '@/api/config'
export default {
  data() {
    return {
      btnLoading: false,
      confirmVisible: false,
      ruleForm: {
        type: 1,
        username: '',
        email: '',
        content: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'question'
    ])
  },
  created() {
    this.ruleForm = { ...this.ruleForm, ...this.question }
  },
  methods: {
    // 提交
    onSubmit() {
      this.btnLoading = true
      questions(this.ruleForm).then(() => {
        this.btnLoading = false
        this.confirmVisible = true
        this.$store.dispatch('config/setQuestion', {})
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handleClose() {
      this.confirmVisible = false
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.tips{
  text-align: center;
  margin: 0.16rem 0 0.16rem;
}
.question-from{
  margin: 0.36rem 0.24rem;
}
.question-table{
  border: 0.02rem solid #333;
  border-bottom: 0;
  .question-table-item{
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 0.02rem solid #333;
    .desc{
      display: flex;
      align-items: center;
      width: 3rem;
      min-height: 0.88rem;
      padding: 0.24rem;
      box-sizing: border-box;
      border-right: 0.02rem solid #333;
    }
    .ask{
      width: calc(100% - 3rem);
      padding: 0.24rem;
      box-sizing: border-box;
    }
  }
}
.return{
  display: block;
  width: 100%;
  height: 0.88rem;
  color: #333;
  text-align: center;
  background: #fff;
  border: 0.02rem solid #333;
  margin: 0.16rem auto 1.1rem;
}
.deliver{
  display: block;
  width: 100%;
  height: 1.08rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
  background: #333;
  border: none;
  margin: 0.8rem auto 0.16rem;
}
.modal-content{
      .title{
        font-size: .36rem;
        font-weight: 700;
        color: #4bc4b0;
      }
      .text{
        margin-top: .4rem;
        line-height: .42rem;
      }
      .close{
        width: 100%;
        height: .88rem;
        margin-top: .5rem;
        line-height: .88rem;
        background: #333333;
        color: #ffffff;
      }
    }
</style>
