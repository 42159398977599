<template>
  <div class="company-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / 企業情報
    </div>
    <div class="lists-content">
      <div class="list">
        <div class="name">社名</div>
        <div class="value">
          はんこプレミアム株式会社<br>(Hanko Premium Co.,Ltd.)
        </div>
      </div>
      <div class="list">
        <div class="name">設立</div>
        <div class="value">2011年9月</div>
      </div>
      <div class="list">
        <div class="name">住所</div>
        <div class="value">
          〒274-0065<br>千葉県船橋市高根台7丁目26-12
        </div>
      </div>
      <div class="list">
        <div class="name">電話番号</div>
        <div class="value">047-489-5595<br>(営業電話などはご遠慮ください)</div>
      </div>
      <div class="list">
        <div class="name">電話受付時間</div>
        <div class="value">平日 9:30〜18:30</div>
      </div>
      <div class="list">
        <div class="name">お問い合わせ</div>
        <div class="value">
          <router-link to="/question">お問い合わせはこちら ＞＞</router-link>
        </div>
      </div>
      <div class="list">
        <div class="name">公式通販サイト</div>
        <div class="value">
          <router-link to="/">https://www.woodlife.jp/</router-link>
        </div>
      </div>
      <div class="list">
        <div class="name">古物営業法の<br>規定に基づく表記</div>
        <div class="value">千葉県公安委員会<br>第441360001654号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.company-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .lists-content{
        margin-top: .34rem;
        .list{
            display: flex;
            padding: .28rem 0;
            line-height: .42rem;
            border-bottom: .02rem dashed #e8ebed;
            &:first-child{
                border-top: .02rem dashed #e8ebed;
            }
            .name{
                display: flex;
                align-items: center;
                width: 2.24rem;
                font-weight: 700;
            }
            .value{
                margin-left: .44rem;
                a{
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
