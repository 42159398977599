var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "special-title" }, [_vm._v("人気特集")]),
      _c("div", { staticClass: "special-box" }, [
        _c("div", { staticClass: "special-item" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/pc/swiper-img.png") },
          }),
          _c("div", { staticClass: "desc" }, [
            _vm._v("北欧風 テレビ台・ローボード特集"),
          ]),
        ]),
        _c("div", { staticClass: "special-item" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/pc/swiper-img.png") },
          }),
          _c("div", { staticClass: "desc" }, [
            _vm._v("お部屋の模様替えをして"),
            _c("br"),
            _vm._v("新年を新しい気持ちで迎えよう！家具特集"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }