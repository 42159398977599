var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/pc/breadcrumb_arrow.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("お気に入り商品")]),
      ],
      1
    ),
    _c("div", { staticClass: "personal-content" }, [
      _c(
        "div",
        { staticClass: "left-content" },
        [_c("SideBar", { attrs: { path: "/personal/my_collection" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "right-content" },
        [
          _vm.initData.length > 0
            ? [
                _c("div", { staticClass: "title" }, [
                  _c("div", [_vm._v("ご登録の新しい順に表示しています。")]),
                  _c("div", [_vm._v(_vm._s(_vm.initData.length) + " 商品")]),
                ]),
                _c(
                  "div",
                  { staticClass: "product-content" },
                  _vm._l(_vm.initData, function (item, index) {
                    return _c("div", { key: item.id, staticClass: "list" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/product/" + item.product_sn } },
                            [
                              _c("img", {
                                attrs: { src: item.image, alt: item.image_alt },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "desc" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.long_title)),
                            ]),
                            _c("div", { staticClass: "price" }, [
                              _vm._v(
                                "¥" + _vm._s(_vm.calcThousands(item.price))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "delete",
                          on: {
                            click: function ($event) {
                              return _vm.handleCancel(item.product_sn, index)
                            },
                          },
                        },
                        [_vm._m(0, true)]
                      ),
                    ])
                  }),
                  0
                ),
              ]
            : _c(
                "div",
                { staticClass: "collection-empty" },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/my_collection_empty.png"),
                    },
                  }),
                  _c("div", { staticClass: "text" }, [
                    _vm._v("お気に入り商品はありません"),
                  ]),
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("button", { staticClass: "button" }, [
                      _vm._v("トップページに戻る"),
                    ]),
                  ]),
                ],
                1
              ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/grey_x.png"), alt: "" },
      }),
      _c("span", [_vm._v("削除")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }