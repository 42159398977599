<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <span>お問い合わせ</span>
    </div>
    <div class="question-title">お問い合わせ前に、よくあるご質問に該当がないかご確認ください。</div>
    <common />
    <question-from />
  </div>
</template>
<script>
import common from '@/views/pc/question/common'
import QuestionFrom from '@/views/pc/question/from'
export default {
  components: { common, QuestionFrom }
}
</script>
<style lang="scss" scoped>
.question-title{
  height: 140px;
  line-height: 140px;
  font-size: 28px;
  color: #333;
  text-align: center;
  font-weight: bold;
  background: url('~@/assets/images/pc/question_bg.png') center center / auto 100%;
  margin-top: -26px;
}
.breadcrumb{
  width: 1440px;
  line-height: 17px;
  margin: 28px auto 0;
}
</style>
