var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tips" },
      [
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
        _vm._v(" / ニュース"),
      ],
      1
    ),
    _c("div", { staticClass: "article-title" }, [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.commentScroll("info")
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/article_arrow_black.png"),
            },
          }),
          _c("span", [_vm._v("INFORMATION")]),
        ]
      ),
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.commentScroll("new")
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/article_arrow_black.png"),
            },
          }),
          _c("span", [_vm._v("NEWS")]),
        ]
      ),
    ]),
    _c("div", { staticClass: "view-title" }, [
      _c("div", [_vm._v("INFORMATION")]),
      _c("div", { ref: "info", staticClass: "seat" }),
    ]),
    _c(
      "div",
      { staticClass: "info-box" },
      _vm._l(_vm.infoData, function (item) {
        return _c(
          "router-link",
          {
            key: item.id,
            staticClass: "info-item",
            attrs: { to: "/article/" + item.id },
          },
          [
            _c("div", { staticClass: "date" }, [
              _vm._v(_vm._s(item.created_at.substring(0, 10))),
            ]),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
          ]
        )
      }),
      1
    ),
    _c("div", { staticClass: "view-title" }, [
      _c("div", [_vm._v("NEWS")]),
      _c("div", { ref: "new", staticClass: "seat" }),
    ]),
    _c(
      "div",
      { staticClass: "article-box" },
      _vm._l(_vm.newData, function (item) {
        return _c(
          "router-link",
          {
            key: item.id,
            staticClass: "article-item",
            attrs: { to: "/article/" + item.id },
          },
          [
            _c("img", { attrs: { width: "100%", src: item.image } }),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
            _c("div", { staticClass: "desc" }, [
              _vm._v(_vm._s(item.description)),
            ]),
            _c("div", { staticClass: "date" }, [
              _vm._v(_vm._s(item.created_at.substring(0, 10))),
            ]),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }