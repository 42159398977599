<template>
  <div>
    <div class="question-from">
      <div class="ttl">
        <span>Contact us by email</span>
        <span class="num">01</span>
      </div>
      <div class="dc">メールでお問い合わせ</div>
      <div class="tips">下記フォームに必要事項をご記入の上、送信してください。<br>24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。</div>
      <div class="tips">※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。<br>※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
        <div class="item-left">
          <el-form-item prop="type" label="お問い合わせ項目">
            <el-select v-model="ruleForm.type" placeholder="（項目を選んでください）" clearable>
              <el-option label="商品について" :value="1" />
              <el-option label="納期について" :value="2" />
              <el-option label="不良交換について" :value="3" />
              <el-option label="サイトについて" :value="4" />
              <el-option label="その他" :value="5" />
            </el-select>
          </el-form-item>
          <el-form-item prop="username" label="お名前">
            <el-input v-model="ruleForm.username" placeholder="お名前" clearable />
          </el-form-item>
          <el-form-item prop="email" label="メールアドレス">
            <el-input v-model="ruleForm.email" placeholder="メールアドレス" clearable />
          </el-form-item>
        </div>
        <div class="item-right">
          <el-form-item prop="content" label="お問い合わせ内容">
            <el-input v-model="ruleForm.content" type="textarea" placeholder="お問い合わせ内容" />
          </el-form-item>
        </div>
      </el-form>
      <el-button class="deliver" type="primary" @click="onSubmit">
        <span>送信</span>
        <img src="@/assets/images/pc/rightArrow.png">
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      ruleForm: {
        type: 1,
        username: '',
        email: '',
        content: ''
      },
      rules: {
        type: [{ required: true }],
        username: [{ required: true, message: 'お名前をご入力ください', trigger: 'blur' }],
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスをご入力ください', trigger: ['blur'] }
        ],
        content: [{ required: true, message: 'お問い合わせ内容をご入力ください', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters([
      'question'
    ])
  },
  created() {
    this.ruleForm = { ...this.ruleForm, ...this.question }
  },
  methods: {
    // 提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('config/setQuestion', this.ruleForm)
          this.$router.push({ path: '/question/confirm' })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.question-from{
  width: 1440px;
  color: #333;
  font-size: 16px;
  margin: 32px auto 80px;
}
.ttl{
  position: relative;
  font-size: 32px;
  font-weight: bold;
  margin-left: 16px;
  .num{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 200px;
    line-height: 1;
    background-clip:text;
    color: transparent;
    background-image:-webkit-linear-gradient(90deg,#FFFFFF,#F4F5FC);
    color:transparent;
    z-index: -1;
  }
}
.dc{
  width: 340px;
  font-size: 14px;
  padding: 22px 0 8px 16px;
  background: #F4F5FC;
  margin: -20px 0 28px;
}
.tips{
  margin-bottom: 20px;
}
::v-deep {
  .el-form{
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
  }
  .item-left{
    width: 688px;
  }
  .el-select{
    width: 100%;
  }
  .el-form-item__label{
    line-height: 1;
    padding-bottom: 8px;
  }
  .item-right{
    width: 688px;
  }
  .el-textarea{
    height: 240px;
  }
  .el-textarea__inner{
    height: 100%;
    resize: none;
  }
}
.deliver{
  display: block;
  width: 204px;
  height: 48px;
  color: #fff;
  text-align: center;
  background: #333;
  border: none;
  margin: 32px auto;
  cursor: pointer;
  img{
    margin-left: 4px;
  }
}
</style>
