var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v("家具通販専門店TOP"),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
          }),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _c("img", {
            attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
          }),
          _c("span", [_vm._v("よくあるご質問")]),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "common-question-list" },
        [
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.handleScroll("scrollTitle1")
                },
              },
            },
            [_vm._m(1), _vm._m(2), _vm._m(3)]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/guides/payment" } },
            [
              _c("div", { staticClass: "title" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/common_question_9.png"),
                  },
                }),
                _c("span", [_vm._v("お支払いについて")]),
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("div", [_vm._v("代引きを使えますか？")]),
                _c("div", [_vm._v("支払い方法を教えてください。")]),
              ]),
              _c("div", { staticClass: "tips" }, [
                _c("span", [_vm._v("全てを見る")]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/rightArrowAsh.png"),
                  },
                }),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/guides/delivery" } },
            [
              _c("div", { staticClass: "title" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/common_question_3.png"),
                  },
                }),
                _c("span", [_vm._v("配送·送料について")]),
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("div", [_vm._v("送料を教えてください。")]),
                _c("div", [_vm._v("沖縄県（離島）までの運賃はいくらですか？")]),
              ]),
              _c("div", { staticClass: "tips" }, [
                _c("span", [_vm._v("全てを見る")]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/rightArrowAsh.png"),
                  },
                }),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/guides/install" } },
            [
              _c("div", { staticClass: "title" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/common_question_8.png"),
                  },
                }),
                _c("span", [_vm._v("搬入・組立について")]),
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("div", [_vm._v("部屋まで搬入できますか？")]),
                _c("div", [_vm._v("組立をしてくれますか？")]),
              ]),
              _c("div", { staticClass: "tips" }, [
                _c("span", [_vm._v("全てを見る")]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/rightArrowAsh.png"),
                  },
                }),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/guides/return" } },
            [
              _c("div", { staticClass: "title" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/common_question_7.png"),
                  },
                }),
                _c("span", [_vm._v("返品について")]),
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("div", [_vm._v("イメージと違いますが、返品したい。")]),
                _c("div", [_vm._v("クーリングオフで返品したい")]),
              ]),
              _c("div", { staticClass: "tips" }, [
                _c("span", [_vm._v("全てを見る")]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/pc/rightArrowAsh.png"),
                  },
                }),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.handleScroll("scrollTitle2")
                },
              },
            },
            [_vm._m(4), _vm._m(5), _vm._m(6)]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.handleScroll("scrollTitle3")
                },
              },
            },
            [_vm._m(7), _vm._m(8), _vm._m(9)]
          ),
          _c("div", { staticClass: "item-seat" }),
        ],
        1
      ),
      _c("div", { ref: "scrollTitle1", staticClass: "question-title" }, [
        _vm._v("ご注文について"),
      ]),
      _c(
        "div",
        { staticClass: "question-box" },
        [
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(1) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(1)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("注文完了メールが届きません。")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(1),
                    expression: "show.includes(1)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " ご注文受付後、自動的に注文受付メールをお送りしておりますが、登録メールアドレスに誤りがある、または ドメイン指定受信や迷惑メールフィルタ機能の設定などにより、メールをお届けできない場合がございます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " また、お客様のメール受信ボックスがいっぱいの為、メールが受信されないケースが最近増えております。ご確認下さいませ。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ご購入手続き完了時に14ケタの注文番号が発行されている場合は、ご注文が完了しています。"
                    ),
                    _c("br"),
                    _vm._v(" 重複注文など不安に思われた場合は、"),
                    _c("br"),
                    _vm._v(
                      " お問合せフォームまたはカスタマーセンターまでお問い合わせください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 【カスタマーセンター】047-489-5595（受付時間：10：00～18：00 土・日・祝を除く） "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(2) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(2)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("注文したらどのぐらいものは届くですか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(2),
                    expression: "show.includes(2)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 納期は商品により異なりますので、詳しくは各商品ページをご覧ください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ご入金を確認後のオーダーとなりますことご了承下さい。"
                    ),
                    _c("br"),
                    _vm._v(
                      " また、予期せぬ出来事により、さらに長くお待ちいただくこともあります。"
                    ),
                    _c("br"),
                    _vm._v(
                      " オーダー家具は受注から30-60営業日後の配送となります。"
                    ),
                    _c("br"),
                    _vm._v(
                      " その他商品は受注から8-10営業日後の配送となります。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ※年末年始・夏季休暇・ゴールデンウィークなどの特別な時期を除く。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ※大型商品の場合はチャーター便となるため、通常よりお時間を頂戴しております。 お客様ご注文時のご希望納期を運送会社に伝えたのち、運送会社からの納期調整のご連絡によって最終納期の確定となります。ご注文時点での納期確約ではないので予めご了承ください。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(3) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(3)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("電話注文はできますか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(3),
                    expression: "show.includes(3)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " お電話でのご注文は受け付けておりません。オンラインショップ・メール・FAXでのみ承っております。ご注文前のご相談は下記の窓口で承っております。お気軽にご連絡ください。"
                    ),
                    _c("br"),
                    _vm._v(" 平日：カスタマーサポート窓口："),
                    _c("br"),
                    _vm._v(
                      " 月曜 ~ 金曜日 ／ 時間：10:00 ～18:00 となっております。※土日・祝日以外の営業日においては、通常24時間以内に返信させていただきます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ※お問い合わせ内容、混雑等により、回答に日数を要する場合がございます。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(4) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(4)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("注文後、オーダー変更は可能ですか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(4),
                    expression: "show.includes(4)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(" （ご注文内容の変更）"),
                    _c("br"),
                    _vm._v(
                      " 送り先の変更など：出荷手続き前であれば対応させていただきます。"
                    ),
                    _c("br"),
                    _vm._v(" 【お問合せ先電話番号】047-489-5595"),
                    _c("br"),
                    _vm._v(" （キャンセル）"),
                    _c("br"),
                    _vm._v(
                      " 基本的にキャンセルはお受けいたしかねますことをご理解くださいませ。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ただし、ご注文が確定していない場合や、オーダーに入っていない場合はご相談くださいませ。"
                    ),
                    _c("br"),
                    _vm._v(
                      " １件ご注文当たり数万円のキャンセル手数料を別途でご請求させていただきますので、ご了承くださいますようお願い申し上げます。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(5) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(5)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("高さや幅などのサイズ変更できますか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(5),
                    expression: "show.includes(5)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 基本的に特注は行っておりません。規定されたサイズのみで販売しております。"
                    ),
                    _c("br"),
                    _vm._v(
                      " オーダー可能な商品も取り扱いますが、料金と納期は各商品ページでご確認ください。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { ref: "scrollTitle2", staticClass: "question-title" }, [
        _vm._v("商品について"),
      ]),
      _c(
        "div",
        { staticClass: "question-box" },
        [
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(6) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(6)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("商品の保証期間はどれぐらいですか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(6),
                    expression: "show.includes(6)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 全品三ヶ月保証となります。お品は到着より三ヶ月以内、通常通りご利用頂いで発生した"
                    ),
                    _c("br"),
                    _vm._v(
                      " 不具合に関してましては、無償にて修理もしくは交換させていだきます。お手数でござい"
                    ),
                    _c("br"),
                    _vm._v(
                      " ますが、メールにて破損状況の分かるお写真(写メール)と共にご連絡くださいませ。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 3ヶ月以上お使い頂いて発生した不具合については、修理可能でございましたら、有料にて"
                    ),
                    _c("br"),
                    _vm._v(
                      " 修理（都度お見積となります）を承りますので、ご相談くださいませ。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(7) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(7)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [
                _vm._v(
                  "カタログが欲しいのですが、送ってもらうことはできますか？"
                ),
              ]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(7),
                    expression: "show.includes(7)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 当店ではWEBページのみとなり、カタログをご用意しておりません。各商品ページにて詳細などをご確認くださいませ。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "question-title" }, [
        _vm._v("配送・送料について"),
      ]),
      _c(
        "div",
        { staticClass: "question-box" },
        [
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(8) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(8)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("配送前に電話してもらえますか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(8),
                    expression: "show.includes(8)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " お客様の代わりに運送業者にお願い致しますが、必ずのお約束はできませんので、予めご了承ください。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(9) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(9)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [
                _vm._v("商品の到着日や受け取り時間を指定できますか？"),
              ]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(9),
                    expression: "show.includes(9)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " ご注文確定前：ご注文者情報入力ページにて「ご希望お届け予定日」を入力することが可能です。または「備考欄」にその旨入力願います。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ご注文確定後：既に出荷手続きを完了している場合がございます。まずはお問合せフォーム、もしくはカスタマーセンターまでご連絡願います。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 当店出荷後：「出荷完了のお知らせメール」に記載されております、お荷物お問合せ番号に基づき運輸会社に直接ご連絡をお願い申し上げます。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(10) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(10)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [
                _vm._v("不要になった家具を引き取って欲しいのですか。"),
              ]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(10),
                    expression: "show.includes(10)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 恐れ入りますが、現在家具の引き取りサービスは行っておりません。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(11) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(11)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("組み立てはいくらにしますか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(11),
                    expression: "show.includes(11)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 商品により異なりますので、詳しくは各商品ページをご覧ください。"
                    ),
                    _c("br"),
                    _vm._v(" 【組立サービスの料金】 ➡️ 1点 10,000円（税込）"),
                    _c("br"),
                    _vm._v(
                      " ※大型家具の場合、お客様にお手伝いをお願いする場合がございます。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(12) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(12)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("送料を教えてください。")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(12),
                    expression: "show.includes(12)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 当店では全国一律運賃（離島除く）を採用しておりますので、各商品詳細ページ及び運賃表のページにてご確認いただけます。 "
                    ),
                    _c("table", { staticClass: "table" }, [
                      _c("tr", [
                        _c("td", [_vm._v("合計が54,000円(税込)未満")]),
                        _c("td", [_vm._v("合計が54,000円(税込)以上")]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("送料3,000円")]),
                        _c("td", [_vm._v("送料無料")]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(13) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(13)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("沖縄県（離島）までの運賃はいくらですか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(13),
                    expression: "show.includes(13)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 北海道・沖縄・離島へのお届けは、別途追加送料が必要です。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "question-title" }, [
        _vm._v("返品・交換について"),
      ]),
      _c(
        "div",
        { staticClass: "question-box" },
        [
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(14) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(14)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("イメージと違いますが、返品できますか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(14),
                    expression: "show.includes(14)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " ご注文後のご返品、キャンセルについては、商品の破損、不良以外ではご容赦いただいております。"
                    ),
                    _c("br"),
                    _vm._v(
                      " モニター上では色目を完全に再現することができません。特に天然素材は色調や模様にバラツキがあります。"
                    ),
                    _c("br"),
                    _vm._v(
                      " イメージ違い、カラー違い、木目の好み、サイズが部屋に合わない、ニオイ、肌触り等の理由でご返品は、対応しかねますのでご了承下さい。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(15) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(15)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("配送が遅かったのですが、返品できますか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(15),
                    expression: "show.includes(15)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 基本的に各商品ページ通りで順次出荷しておりますが、早く納品したい方は必ずご注文前にご連絡ください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ご注文時連絡なければ、サイトに案内した納期を了承済みことと認識しておりますので、その後のキャンセル返品はご対応できません。"
                    ),
                    _c("br"),
                    _vm._v(" その点をご理解の上ご利用ください。 "),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(16) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(16)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [
                _vm._v("商品が破損していた場合、対応して頂けますか？"),
              ]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(16),
                    expression: "show.includes(16)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " お届けの際には必ず検品してください。商品に不良のある場合は、配達員に報告の上、商品到着後3日以内に当社までご連絡ください。代替品をお送りする等、適切な対処を致します。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ※返品・交換の際には商品状態の確認の為、デジカメや携帯で撮影頂いた画像をご提示頂く必要がございます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 返品・交換の際は、組み上がり状態（商品完成状態）では運送業者が回収できませんので、お手数ですが、分解して再梱包していただく必要がございます。予めご了承下さい。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （ダンボール・箱等の梱包材は商品状態を確認するまで、破棄しないようにお願い致します。） "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(17) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(17)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("クーリングオフで返品したい。")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(17),
                    expression: "show.includes(17)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " インターネットでの売買契約は、通商産業省令が定める「訪問販売に関する法律」の適用範囲内に有り「通信販売」に該当します。従いまして、「通信販売法」により、特定商取引法で規定されているクーリングオフは適用されません。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { ref: "scrollTitle3", staticClass: "question-title" }, [
        _vm._v("マイページについて"),
      ]),
      _c(
        "div",
        { staticClass: "question-box" },
        [
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(18) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(18)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("領収書はどこから発行できますか？")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(18),
                    expression: "show.includes(18)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(" 領収書はすべてネットからの発行になっております。"),
                    _c("br"),
                    _vm._v(
                      " 「出荷完了のお知らせ」メールに領収書発行できるURLが記載されておりますので、"
                    ),
                    _c("br"),
                    _vm._v(
                      " そちらにアクセスしていただき、ダウンロード・印刷していただきますようお願い致します。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(19) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(19)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("ログインできません。")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(19),
                    expression: "show.includes(19)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " 半角に切り替えていただき、再度お試し下さい。また、登録時にメールアドレスが間違っているケースがございますので、その際は「お問い合わせ」よりお問い合わせください。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "question-q",
              class: { show: _vm.show.includes(20) },
              on: {
                click: function ($event) {
                  return _vm.changeShow(20)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/pc/question_q.png") },
              }),
              _c("span", [_vm._v("パスワードを忘れた。")]),
            ]
          ),
          _c("el-collapse-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show.includes(20),
                    expression: "show.includes(20)",
                  },
                ],
              },
              [
                _c("div", { staticClass: "question-a" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/question_a.png"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      " マイページへのログイン画面の「パスワードを忘れた方はこちら」をクリックしてご確認ください。メールアドレスをご入力いただきました後、当店よりパスワードの再登録用URLをメールでご案内いたしますので、そちらで再登録の手続きををお願いいたします。 "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "question-desc" }, [
        _vm._v("解決されない場合は、こちらよりお問い合わせください。"),
      ]),
      _c("question-from"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "guides-title" }, [
      _c("div", { staticClass: "name" }, [_vm._v("guide")]),
      _c("div", { staticClass: "text" }, [_vm._v("よくあるご質問")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/common_question_1.png") },
      }),
      _c("span", [_vm._v("ご注文について")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("div", [_vm._v("注文完了メールが届きません。")]),
      _c("div", [_vm._v("注文したらどのぐらいものは届くですか？")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _c("span", [_vm._v("全てを見る")]),
      _c("img", {
        attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/common_question_2.png") },
      }),
      _c("span", [_vm._v("商品について")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("div", [_vm._v("商品の保証期間はどれぐらいですか？")]),
      _c("div", [
        _vm._v("カタログが欲しいのですが、送ってもらうことはできますか？"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _c("span", [_vm._v("全てを見る")]),
      _c("img", {
        attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/common_question_5.png") },
      }),
      _c("span", [_vm._v("マイページについて")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("div", [_vm._v("自分でキャンセルできますか？")]),
      _c("div", [_vm._v("領収書はどこから発行できますか？")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _c("span", [_vm._v("全てを見る")]),
      _c("img", {
        attrs: { src: require("@/assets/images/pc/rightArrowAsh.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }