import request from '@/utils/request'
// 获取商品列表
export function getCartList(params) {
  return request({
    url: '/carts',
    method: 'get',
    params
  })
}
// 删除购物车商品
export function deleteCartProduct(data) {
  return request({
    url: '/carts',
    method: 'delete',
    data
  })
}

// 更新购物车数量
export function updateCart(data) {
  return request({
    url: '/carts/update/batch',
    method: 'patch',
    data
  })
}

// 进入下单环节
export function toOrder(data) {
  return request({
    url: '/buildSession',
    method: 'post',
    data
  })
}

// 获取购物车数量
export function getCartNumber() {
  return request({
    url: '/carts/number',
    method: 'get'
  })
}
