<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>注文履歴</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/order" />
      </div>
      <div class="right-content">
        <template v-if="initData.length > 0">
          <div class="title">ご注文の新しい順に表示しています。</div>
          <div class="order-content">
            <div v-for="(item,index) in initData" :key="index" class="item">
              <div class="head">
                <div>
                  <span>ご注文番号：{{ item.order_sn }}</span>
                  <span class="time">{{ item.created_at }}</span>
                </div>
                <div class="status">{{ item.store_status && item.store_status.name }}</div>
              </div>
              <div class="product-detail">
                <div>
                  <div v-for="item2 in item.order_products" :key="item2.id" class="flex">
                    <router-link :to="`/product/${item2.product_sn}`">
                      <img :src="item2.product_img">
                    </router-link>
                    <div class="desc">
                      <div class="name">{{ item2.product_title }}</div>
                      <div class="sku-content">
                        <div v-for="(skuItem,index2) in item2.product_sku_display" :key="index2" class="list">{{ skuItem }}</div>
                      </div>
                      <div class="norm-content">
                        <div v-for="(normItem,index3) in item2.product_spu_display" :key="index3" class="list">{{ normItem }}</div>
                      </div>
                    </div>
                    <div class="count">数量：{{ item2.product_quantity }}</div>
                  </div>
                </div>
                <div class="action-content">
                  <div class="action">
                    <router-link v-if="item.is_comment === 0 && item.status_to_user === 3" :to="`/personal/order/add_comments/${item.id}`" class="button dark">レビュー</router-link>
                    <!-- <div class="button dark">もう一度購入する</div> -->
                    <router-link :to="`/personal/order/detail/${item.id}`" class="button detail">詳細を確認する</router-link>
                  </div>
                </div>
              </div>
              <div class="order-detail">
                <div>
                  <span>商品数量 {{ item.total_quantity }} 件、合计金额</span><span class="price">¥{{ calcThousands(item.pay_amount) }}</span>
                </div>
                <div class="share">
                  <span>SHARE ON SNS :</span>
                  <a href="">
                    <div class="facebook" />
                  </a>
                  <a href="">
                    <div class="twitter" />
                  </a>
                  <a href="">
                    <div class="ins" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="order-empty">
          <img src="@/assets/images/pc/my_order_empty.png">
          <div class="text">ご購入の商品はまた登録されていません。</div>
          <router-link to="/">
            <button class="button">トップページに戻る</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMyOrderList } from '@/api/personal'
import SideBar from './component/sideBar'
import { calcThousands } from '@/utils/validate'
export default {
  components: { SideBar },
  data() {
    return {
      initData: [],
      calcThousands
    }
  },
  computed: {

  },
  created() {
    this.getMyOrderList()
  },
  methods: {
    getMyOrderList() {
      getMyOrderList({ type: 'all', include: 'orderProducts,storeStatus' }).then(result => {
        this.initData = result
        this.initData.forEach(item => {
          let num = 0
          item.order_products.forEach(item2 => {
            num += item2.product_quantity
            this.$set(item, 'total_quantity', num)
          })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        .title{
            padding-bottom: 8px;
            margin-bottom: 16px;
            font-size: 20px;
            border-bottom: 2px solid #333;
        }
        .order-content{
            .item{
                border: 1px solid #e8ebed;
                font-size: 16px;
                &:not(:first-child){
                  margin-top: 14px;
                }
                .head{
                    display: flex;
                    justify-content: space-between;
                    height: 40px;
                    padding: 0 24px;
                    line-height: 40px;
                    background-color: #f4f5fc;
                    .time{
                        margin-left: 16px;
                        font-size: 12px;
                        color: #999999;
                    }
                    .status{
                      font-weight: 700;
                    }
                }
                .product-detail{
                    display: flex;
                    justify-content: space-between;
                    width: 1018px;
                    margin: 0 auto;
                    .flex{
                        display: flex;
                        width: 795px;
                        padding: 8px 0;
                        &:not(:last-child){
                            border-bottom: 1px solid #e8ebed;
                        }
                        img{
                            width: 256px;
                            height: 256px;
                            object-fit: cover;
                        }
                        .desc{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 264px;
                            margin-left: 24px;
                            .name{
                                font-weight: 700;
                            }
                            .sku-content{
                                margin-top: 16px;
                                font-size: 14px;
                                line-height: 24px;
                            }
                            .norm-content{
                              font-size: 14px;
                              line-height: 24px;
                            }
                        }
                        .count{
                          display: flex;
                          align-items: center;
                          margin-left: 96px;
                          font-size: 14px;
                        }
                    }
                    .action-content{
                        padding: 40px 0;
                        box-sizing: border-box;
                    }
                    .action{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;
                        padding-left: 40px;
                        border-left: 1px solid #e8ebed;
                        .button{
                            width: 142px;
                            height: 48px;
                            line-height: 48px;
                            text-align: center;
                            font-size: 14px;
                            border: 1px solid #333;
                            box-sizing: border-box;
                            cursor: pointer;
                            &:not(:first-child){
                              margin-top: 16px;
                            }
                        }
                        .dark{
                            background-color: #333;
                            color: #fff;
                        }
                    }
                }
                .order-detail{
                    display: flex;
                    justify-content: space-between;
                    width: 1018px;
                    height: 40px;
                    padding: 28px 0;
                    margin: 0 auto;
                    line-height: 40px;
                    border-top: 1px solid #e8ebed;
                    .price{
                        margin-left: 4px;
                        color: #C40A0A;
                        font-weight: 700;
                    }
                    .share{
                        display: flex;
                        div{
                            width: 40px;
                            height: 40px;
                            margin-left: 12px;
                        }
                        .facebook{
                            background: url('../../../assets/images/pc/facebook.png');
                            &:hover{
                                background: url('../../../assets/images/pc/facebook_current.png')
                            }
                        }
                        .twitter{
                            background: url('../../../assets/images/pc/twitter.png');
                            &:hover{
                                background: url('../../../assets/images/pc/twitter_current.png')
                            }
                        }
                        .ins{
                            background: url('../../../assets/images/pc/ins.png');
                            &:hover{
                                background: url('../../../assets/images/pc/ins_current.png')
                            }
                        }
                    }
                }
            }
        }
         .order-empty{
            margin-top: 72px;
            text-align: center;
            font-size: 16px;
            .text{
            margin-top: 12px;
            }
            .button{
            width: 204px;
            height: 48px;
            margin: 32px 0 auto;
            line-height: 48px;
            text-align: center;
            background-color: #333;
            color: #fff;
            cursor: pointer;
            }
        }
    }
}
</style>
