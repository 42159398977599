<template>
  <div class="register-content">
    <div class="title-content">
      <div class="en">JOIN TO WOODLIFE</div>
      <div class="ja">WOODLIFE会員に登録する</div>
    </div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
      <div class="inline-item">
        <el-form-item prop="first_name" label="">
          <el-input v-model.trim="ruleForm.first_name" placeholder="姓※" />
        </el-form-item>
        <el-form-item prop="last_name" label="">
          <el-input v-model.trim="ruleForm.last_name" placeholder="名※" />
        </el-form-item>
      </div>
      <el-form-item prop="email" label="">
        <el-input v-model.trim="ruleForm.email" placeholder="メールアドレス（会員ID）※" />
      </el-form-item>
      <el-form-item v-show="ruleForm.email" prop="" label="">
        <div class="name">メールアドレス確認</div>
        <div class="value">{{ ruleForm.email }}</div>
        <div class="name">※メールアドレスにお間違いがないか、今一度ご確認ください。</div>
      </el-form-item>
      <el-form-item prop="password" label="">
        <el-input v-model.trim="ruleForm.password" type="password" placeholder="パスワード※" />
      </el-form-item>
      <el-form-item prop="password_confirmation" label="">
        <el-input v-model.trim="ruleForm.password_confirmation" type="password" placeholder="パスワード（確認用）※" />
      </el-form-item>
      <el-form-item prop="" label="" style="margin-bottom: 0">
        <!-- <div class="button" @click="handleRegister">会員登録する</div> -->
        <el-button class="button" :loading="btnLoading" @click="handleRegister">会員登録する</el-button>
        <div class="text">
          すでにアカウントがあって、<router-link to="/login">ログイン</router-link>します
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { register } from '@/api/user'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        first_name: { required: true, message: '姓をご入力ください', trigger: ['blur'] },
        last_name: { required: true, message: '名をご入力ください', trigger: ['blur'] },
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ],
        password: { required: true, message: 'パスワードごをご入力ください', trigger: ['blur'] },
        password_confirmation: { required: true, message: 'パスワードを再度ご入力ください', trigger: ['blur'] }
      },
      btnLoading: false
    }
  },
  methods: {
    handleRegister() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.btnLoading = true
          register(this.ruleForm).then(() => {
            this.btnLoading = false
            this.$router.push({ path: '/register/auth', query: { first_name: this.ruleForm.first_name, last_name: this.ruleForm.last_name }})
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.register-content{
    padding: 0 .24rem;
    margin-top: .42rem;
    flex: 1;
    .title-content{
        color: #292A2E;
        text-align: center;
        .en{
            font-size: .36rem;
            font-weight: 700;
        }
        .ja{
            margin-top: .06rem;
            font-size: .24rem;
        }
    }
    .el-form{
        margin-top: .36rem;
    }
    .el-form-item{
      margin-bottom: .4rem;
    }
    .inline-item{
        display: flex;
        justify-content: space-between;
        .el-input{
            width: 3.42rem;
        }
    }
    .name{
      color: #999999;
      font-size: .28rem;
      line-height: .54rem;
    }
    .value{
      font-size: .6rem;
      font-weight: bold;
    }
    .button{
      display: block;
      width: 100%;
      height: .88rem;
      margin-top: .24rem;
      color: #ffffff;
      background: #333333;
      border: 0;
    }
    .text{
        margin-top: .16rem;
        text-align: center;
        color: #999999;
        text-decoration: underline;
        a{
            color: #333333;
            font-weight: 700;
        }
    }
}
</style>
