<template>
  <div id="order-content" class="order-content">
    <PaymentHeader />
    <div class="step-content">
      <div class="main">
        <div class="item">
          <img src="@/assets/images/pc/step1_border.png" class="icon">
          <div>カート</div>
        </div>
        <img src="@/assets/images/pc/step_line1_in.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step2_border.png" class="icon">
          <div>配送</div>
        </div>
        <img src="@/assets/images/pc/step_line2_in.png" class="step-line2">
        <div class="item">
          <img src="@/assets/images/pc/step3_in.png" class="icon">
          <div>支払い</div>
        </div>
        <img src="@/assets/images/pc/step_line1.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step4.png" class="icon">
          <div>注文完了</div>
        </div>
      </div>
    </div>
    <div class="shop-content">
      <div class="left-content">
        <div class="message-content">
          <div class="list">
            <div class="text">
              <div class="name">連絡先</div>
              <div class="value">{{ initData.receiver_email }}</div>
            </div>
            <div class="change-btn">
              <router-link to="/order/address">変更</router-link>
            </div>
          </div>
          <div class="list">
            <div class="text">
              <div class="name">配送先</div>
              <div class="value">
                {{ initData.first_name+initData.last_name }} 様<br>
                〒{{ initData.zip_code }} {{ initData.stats }}{{ initData.city }}{{ initData.address }}{{ initData.detail_address ? initData.detail_address : '' }}<br>
                {{ initData.phone }}
              </div>
            </div>
            <div class="change-btn">
              <router-link to="/order/address">変更</router-link>
            </div>
          </div>
          <div class="list">
            <div class="text">
              <div class="name">配送方法</div>
              <div class="value">通常出荷</div>
            </div>
            <div class="price">¥{{ calcThousands(priceParams.freight_amount || 0) }}</div>
          </div>
          <div class="list">
            <div class="text">
              <div class="name">備考欄</div>
              <div class="value">{{ initData.note }}</div>
            </div>
            <div class="change-btn">
              <router-link to="/order/address">変更</router-link>
            </div>
          </div>
        </div>
        <div v-if="token" class="discount-content">
          <div class="title">ご利用可能なクーポン</div>
          <div class="radio-content">
            <div class="item">
              <el-radio v-model="useCoupon" :label="false" class="radio" @change="handleCoupon">
                <span>クーポンを利用しない</span>
              </el-radio>
            </div>
            <div class="item">
              <el-radio v-model="useCoupon" :label="true" class="radio" @change="handleCoupon">
                <span>クーポンを選択する</span>
              </el-radio>
              <div v-show="useCoupon" class="discount-detail">
                <div class="input-content">
                  <span>クーポンを登録する</span>
                  <el-input v-model.trim="code" placeholder="クーポンコード" />
                  <el-button type="primary" :loading="codeLoading" @click="handleGetCoupon">登録する</el-button>
                </div>
                <div class="coupon-content">
                  <el-radio-group v-model="couponValue" @change="getPriceShow">
                    <div v-for="(item,index) in couponData" :key="index" class="list">
                      <el-radio :label="item.id">
                        <div>{{ item.coupon.name }}</div>
                        <div class="time">【有効期限】{{ item.coupon.end_time }}</div>
                      </el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="token" class="discount-content">
          <div class="title">ご利用可能なポイント</div>
          <div class="radio-content">
            <div class="item">
              <el-radio v-model="usePoint" :label="0" class="radio" @change="handleNoPoint">
                <span>ポイントを利用しない</span>
              </el-radio>
            </div>
            <div class="item">
              <el-radio v-model="usePoint" :label="1" class="radio" @change="getPriceShow">
                <span>利用可能なポイントをすべて利用する：<b>{{ totalPoint }}</b>（￥{{ calcThousands(totalPointFee) }}相当）</span>
              </el-radio>
            </div>
            <div class="item">
              <el-radio v-model="usePoint" :label="2" class="radio" @change="getPriceShow">
                <span>一部のポイントを利用する</span>
                <el-input v-model.number="pointValue" placeholder="ポイントを" @focus="handleFocus" @input="changePoint" />
              </el-radio>
            </div>
          </div>
        </div>
        <div class="payment-content">
          <div class="title">お支払い方法</div>
          <div class="desc">入力された情報はすべて暗号化され、安全に転送されます。</div>
          <div class="radio-content">
            <div class="item">
              <el-radio v-model="ruleForm.pay_type" :label="3">
                <span>クレジットカード決済</span>
              </el-radio>
              <div v-show="ruleForm.pay_type === 3" class="payment-desc">
                <el-form ref="blackcatForm" :model="blackcatForm" :rules="rules">
                  <el-form-item prop="credit_number" label="">
                    <el-input v-model="blackcatForm.credit_number" placeholder="カード番号" />
                  </el-form-item>
                  <el-form-item prop="credit_username" label="">
                    <el-input v-model.trim="blackcatForm.credit_username" placeholder="カード名義人" />
                  </el-form-item>
                  <div class="flex">
                    <el-form-item prop="date" label="">
                      <el-input v-model="blackcatForm.date" placeholder="MM/YY" />
                    </el-form-item>
                    <el-form-item prop="credit_security_code" label="">
                      <el-input v-model="blackcatForm.credit_security_code" placeholder="セキュリティコード" />
                    </el-form-item>
                  </div>
                  <el-form-item prop="credit_count" label="">
                    <el-select v-model="blackcatForm.credit_count" placeholder="お支払い回数">
                      <el-option v-for="item in payCount" :key="item" :label="item" :value="item" />
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="item">
              <el-radio v-model="ruleForm.pay_type" :label="9">
                <img src="@/assets/images/pc/rakuten.png" alt="" class="rakuten-icon">
                <span class="pay-name">楽天ペイ</span>
              </el-radio>
              <div v-show="ruleForm.pay_type === 9" class="payment-desc">
                <div class="rakuten-content">
                  <img src="@/assets/images/pc/rakuten_pay_icon.png" alt="">
                  <div class="text">
                    いつもの楽天会員IDとパスワードを使ってスムーズなお支払いが可能です。<br>
                    楽天ポイントが貯まる・使える！「簡単」「あんしん」「お得」な楽天ペイをご利用ください。<br>
                    ※楽天ポイントが貯まるのは楽天カード・楽天ポイント・楽天キャッシュでのお支払いに限ります。
                  </div>
                </div>
              </div>
            </div>
            <div class="item amazon">
              <el-radio v-model="ruleForm.pay_type" :label="4">
                <img src="@/assets/images/pc/amazon.png" alt="">
              </el-radio>
              <div v-show="ruleForm.pay_type === 4" class="payment-desc">
                <div v-if="amazonToken" style="display: flex;margin: 0 auto;margin-bottom: 40px">
                  <!-- {显示亚马逊地址簿-----开始 -->
                  <div id="addressBookWidgetDiv" style="height: 400px;width: 400px;margin-right: 20px" />
                  <!-- --显示亚马逊地址簿-----结束-- -->
                  <!-- 显示亚马逊支付方式-----开始 -->
                  <div id="walletWidgetDiv" style="width: 500px;height: 400px;" />
                  <!-- 显示亚马逊支付方式-----结束 -->
                </div>
                <div class="text">
                  Amazonに登録された配送先情報やクレジットカード情報を利用してお買い物できます。<br>
                  右のボタンをクリックして、Amazonのユーザー名とパスワードでログインする<br>
                  必要がございます。<br>
                  登録ボダンが表示されない場合ば、ページを再読み込みしてください。<br>
                  Amazon Pay（アマゾンペイ）の閲覧環境は、以下のシステムとブラウザにて正しく動作<br>
                  するよう設計されております。<br><br>
                  [PC]<br>
                  Microsoft Windows 7以降<br>
                  Mac OS X v.10.11以降<br>
                  [スマートフォン／タブレット]<br>
                  Android 7.0以降<br>
                  Apple iOS 11.2以降<br>
                  推奨ブラウザ<br>
                  [PC]<br>
                  Firefox バージョン 70.0以降 (Windowsのみ)<br>
                  ※その他のブラウザは、はんこプレミアムのオンラインストアの閲覧環境に基づきます。
                  <!-- 亚马逊按钮 -->
                  <div id="AmazonPayButton" />
                </div>
              </div>
            </div>
            <div class="item">
              <el-radio v-model="ruleForm.pay_type" :label="8">
                <img src="@/assets/images/pc/paypay.png" alt="">
              </el-radio>
              <div v-show="ruleForm.pay_type === 8" class="payment-desc">
                <el-form ref="paypayForm" :model="paypayForm" :rules="rules">
                  <el-form-item label="" prop="buyer_name_kanji">
                    <el-input v-model.trim="paypayForm.buyer_name_kanji" placeholder="お名前を" />
                  </el-form-item>
                  <el-form-item label="" prop="buyer_tel">
                    <el-input v-model.trim="paypayForm.buyer_tel" maxlength="11" placeholder="電話番号" />
                  </el-form-item>
                  <el-form-item label="" prop="buyer_email">
                    <el-input v-model.trim="paypayForm.buyer_email" placeholder="メールアドレス" />
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div v-if="payBank" class="item">
              <el-radio v-model="ruleForm.pay_type" :label="2">
                <span>銀行振込・前払い</span>
              </el-radio>
              <div v-show="ruleForm.pay_type === 2" class="payment-desc">
                <div class="text">
                  ご注文後7営業日以内お振込みくださいませ。<br>
                  ご入金が確認でき次第商品手配とさせていただきます。
                </div>
                <div class="table-content">
                  <table class="table">
                    <tr>
                      <td>銀行名</td>
                      <td>三井住友銀行</td>
                    </tr>
                    <tr>
                      <td>支店名</td>
                      <td>船橋支店</td>
                    </tr>
                    <tr>
                      <td>口座番号</td>
                      <td>7154834（普）</td>
                    </tr>
                    <tr>
                      <td>口座名義</td>
                      <td>ハンコプレミアム（カ</td>
                    </tr>
                    <tr>
                      <td>振込み手数料</td>
                      <td>お客様ご負担</td>
                    </tr>
                    <tr>
                      <td>お支払期限</td>
                      <td>ご注文から7営業日まで</td>
                    </tr>
                  </table>
                  <table class="table">
                    <tr>
                      <td>銀行名</td>
                      <td>ゆうちょ銀行</td>
                    </tr>
                    <tr>
                      <td>記号</td>
                      <td>10580</td>
                    </tr>
                    <tr>
                      <td>番号</td>
                      <td>7154834（普）</td>
                    </tr>
                    <tr>
                      <td>口座名義</td>
                      <td>ハンコプレミアム（カ</td>
                    </tr>
                    <tr>
                      <td>振込み手数料</td>
                      <td>お客様ご負担</td>
                    </tr>
                    <tr>
                      <td>お支払期限</td>
                      <td>ご注文から7営業日まで</td>
                    </tr>
                  </table>
                </div>
                <div class="text">
                  &lt;他行から振り込みの場合&gt;<br>
                  【店 名】058 ゼロゴハチ<br>
                  【口座番号】3702567<br>
                  ********************************************
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="sticky">
          <div class="title">買い物カート内の商品</div>
          <div class="detail">
            <div class="item font16">
              <div>送料</div>
              <div>+￥{{ calcThousands(priceParams.freight_amount || 0) }}</div>
            </div>
            <div class="item font16">
              <div class="name">クーポン</div>
              <div class="value">-￥{{ calcThousands(priceParams.use_point_fee) }}</div>
            </div>
            <div class="item font16">
              <div class="name">利用ポイント</div>
              <div class="value">-￥{{ calcThousands(priceParams.coupon_amount || 0) }}</div>
            </div>
            <div class="item">
              <div>合計</div>
              <div class="price">￥{{ calcThousands(priceParams.pay_amount) }}</div>
            </div>
          </div>
          <div class="next-btn" @click="handleConfirmShow">ご注文を確定する</div>
          <router-link to="/order/address">
            <div class="light-btn">配送に戻る</div>
          </router-link>
        </div>
      </div>
    </div>
    <div v-show="confirmVisible" class="overlay">
      <div class="main">
        <div class="modal-content">
          <div class="title">ご注文確定前のお願い</div>
          <div class="text">
            ▼搬入経路は問題ございませんか？▼<br>※搬入か可能か事前にご確認をお願い致します。
          </div>
          <div class="text">
            ご注文確定後の変更・キャンセルはできませんので、<br>よくご確認の上ご注文をお願いいたします。
          </div>
          <div class="text">
            同意する(Yes)をクリックすることで<br>
            <router-link to="/privacy">プライバシーポリシー</router-link>に同意したことになります。
          </div>
          <div class="button-content">
            <el-button type="primary button" :loading="btnLoading" @click="handleConfirmPay">同意する（Yes）</el-button>
            <button class="button cancel-btn" @click="confirmVisible = false">同意しない（NO）</button>
          </div>
          <img src="@/assets/images/pc/x.png" class="close-btn" @click="confirmVisible = false">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getShoppingDetail, getPriceShow, orderPayment, sendPaypay, sendRakutenPay, useDiscount } from '@/api/order'
import { getMyPoint } from '@/api/personal'
import { getOrderCouponList, getCodeCoupon } from '@/api/coupon'
import PaymentHeader from '@/components/PaymentHeader'
import { calcThousands } from '@/utils/validate'
export default {
  components: { PaymentHeader },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('電話番号をご入力ください'))
      } else {
        if (isNaN(value)) {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else if (value.length < 9 || value.length > 12 || value.substr(0, 1) !== '0') {
          callback(new Error('電話番号は半角数字10〜11桁で入力してください(始めの文字は0である必要があります)'))
        } else {
          callback()
        }
      }
    }
    return {
      btnLoading: false,
      codeLoading: false,
      confirmVisible: false,
      payBank: true,
      initData: {},
      priceParams: {
        freight_amount: 0,
        pay_amount: 0,
        receiver_name: ''
      },
      ruleForm: {
        pay_type: ''
      },
      pointValue: 0,
      couponValue: '',
      discountForm: {
        coupon_for_user_id: '',
        use_point: 0
      },
      totalPoint: 0, // 我的积分
      totalPointFee: 0, // 积分最高可抵用金额
      useCoupon: false,
      usePoint: 0, // 下单选择积分状态
      amazonToken: this.$route.query && this.$route.query.access_token,
      blackcatForm: {
        credit_count: '',
        credit_username: '',
        credit_security_code: ''
      },
      paypayForm: {
        buyer_name_kanji: '',
        buyer_tel: '',
        buyer_email: ''
      },
      couponData: [],
      payCount: [1, 2, 3, 4, 5, 6],
      code: '',
      rules: {
        credit_number: { required: true, message: 'カード番号をご入力ください', trigger: ['blur'] },
        credit_username: { required: true, message: 'カード名義人をご入力ください', trigger: ['blur'] },
        date: { required: true, message: 'MM/YYをご入力ください', trigger: ['blur'] },
        credit_security_code: { required: true, message: 'セキュリティコードをご入力ください', trigger: ['blur'] },
        credit_count: { required: true, message: 'お支払い回数をご選択ください', trigger: ['change'] },
        buyer_name_kanji: { required: true, message: 'お名前をご入力ください', trigger: ['blur'] },
        buyer_tel: { required: true, validator: checkPhone, trigger: ['blur'] },
        buyer_email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ]
      },
      calcThousands
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  created() {
    this.getShoppingDetail()
    this.getPriceShow()
    if (this.token) {
      this.getOrderCouponList()
      this.getMyPoint()
    }
  },
  mounted() {
    this.$nextTick(_ => {
      const head = document.head || document.getElementsByTagName('head')[0]
      const blackcatScript = document.createElement('script')
      const amazonScript = document.createElement('script')
      blackcatScript.src = process.env.VUE_APP_BLACKCAT
      amazonScript.src = process.env.VUE_APP_AMAZON
      head.appendChild(blackcatScript)
      head.appendChild(amazonScript)
    })
    window.onAmazonLoginReady = () => {
      /* eslint-disable */
      amazon.Login.setClientId(process.env.VUE_APP_AMAZON_CLIENT_ID)
      /* eslint-enable */
    }
    window.onAmazonPaymentsReady = () => {
      this.showButton()
    }
    if (this.amazonToken) {
      this.ruleForm.pay_type = 4
      this.ruleForm.amazon_access_token = this.amazonToken
      window.onAmazonPaymentsReady = () => {
      /* eslint-disable */
        new OffAmazonPayments.Widgets.AddressBook({
          /* eslint-enable */
          sellerId: process.env.VUE_APP_AMAZON_MERCHANT_ID,
          onOrderReferenceCreate: (orderReference) => {
            this.ruleForm.reference_id = orderReference.getAmazonOrderReferenceId()
          },
          onAddressSelect: (orderReference) => {
            this.showWallet()
          },
          design: {
            designMode: 'responsive'
          },
          onReady: (orderReference) => {},
          onError: () => {

          }
        }).bind('addressBookWidgetDiv')
      }
    }
  },
  methods: {
    showWallet() {
      /* eslint-disable */
      new OffAmazonPayments.Widgets.Wallet({
        /* eslint-enable */
        sellerId: process.env.VUE_APP_AMAZON_MERCHANT_ID,
        onPaymentSelect: (orderReference) => {},
        design: {
          designMode: 'responsive'
        },

        onError: () => {

        }
      }).bind('walletWidgetDiv')
    },
    getShoppingDetail() {
      getShoppingDetail().then(result => {
        this.initData = result
      })
    },
    getPriceShow() {
      if (this.usePoint === 1) {
        this.discountForm.use_point = this.totalPoint
        getPriceShow(this.discountForm).then(result => {
          this.priceParams = result
        })
      } else if (this.usePoint === 2) {
        this.discountForm.use_point = this.pointValue
      }
      if (this.useCoupon) {
        this.discountForm.coupon_for_user_id = this.couponValue
      }
      getPriceShow(this.discountForm).then(result => {
        this.priceParams = result
      })
    },
    getMyPoint() {
      getMyPoint().then(result => {
        this.totalPoint = result.totalPoint
        this.totalPointFee = result.totalPointFee
      })
    },
    getOrderCouponList() {
      getOrderCouponList().then(result => {
        this.couponData = result.data
      })
    },
    handleNoPoint() {
      this.discountForm.use_point = 0
      this.getPriceShow()
    },
    handleCoupon(value) {
      if (value) {
        this.discountForm.coupon_for_user_id = this.couponValue
      } else {
        this.discountForm.coupon_for_user_id = ''
      }
      this.getPriceShow()
    },
    handleGetCoupon() {
      if (this.code) {
        this.codeLoading = true
        getCodeCoupon({ code: this.code, is_order: true }).then(result => {
          this.codeLoading = false
          this.$toast('クーポンをGETしました！')
          if (result.is_useful) {
            this.discountForm.coupon_for_user_id = result.id
            this.couponValue = result.id
            this.getOrderCouponList()
            this.getPriceShow()
          }
        }).catch(() => {
          this.codeLoading = false
        })
      } else {
        this.$toast('クーポンコードを入力ください')
      }
    },
    handleFocus() {
      this.usePoint = 2
    },
    changePoint() {
      if (this.pointValue > this.totalPoint) {
        this.pointValue = this.totalPoint
      }
      if (this.pointValue === '' || this.pointValue < 0 || isNaN(this.pointValue)) {
        this.pointValue = 0
      }
      this.discountForm.use_point = this.pointValue
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.getPriceShow()
      }, 500)
    },
    handleConfirmShow() {
      if (this.useCoupon && !this.discountForm.coupon_for_user_id) {
        this.$toast('クーポンを選択ください')
        return false
      }
      if (this.ruleForm.pay_type === '') {
        this.$toast('お支払い方法')
      } else if (this.ruleForm.pay_type === 3) {
        this.$refs.blackcatForm.validate((valid) => {
          if (valid) {
            this.confirmVisible = true
          }
        })
      } else if (this.ruleForm.pay_type === 8) {
        this.$refs.paypayForm.validate((valid) => {
          if (valid) {
            this.confirmVisible = true
          }
        })
      } else {
        this.confirmVisible = true
      }
    },
    showButton() {
      /* eslint-disable */
      var authRequest
      OffAmazonPayments.Button('AmazonPayButton', process.env.VUE_APP_AMAZON_MERCHANT_ID, {
        type: 'PwA',
        color: 'Dark gray',
        size: 'medium',
        authorization() {
          const loginOptions = {
            scope: 'profile profile:user_id postal_code payments:widget payments:shipping_address',
            popup: true
          }
          authRequest = amazon.Login.authorize(loginOptions, window.location.href)
        },
        onError: () =>{
          // 登录失败的处理
          // console.log(error)
        }
      })
          /* eslint-enable */
    },
    callbackSuccess(result) { // コールバック関数（「正常」の場合）
      this.ruleForm.credit_token = result.token
      this.ruleForm.credit_count = this.blackcatForm.credit_count
      this.orderPayment()
    },
    callbackFailure(result) { // コールバック関数（「異常」の場合）
      this.btnLoading = false
      // エラー情報を取得
      var errorInfo = result.errorInfo
      // エラーの数だけ処理を繰り返す
      for (var i = 0; i < errorInfo.length; i++) {
        // メッセージを alert で出力
        this.$toast(errorInfo[i].errorCode + ' : ' + errorInfo[i].errorMsg)
      }
    },
    orderPayment() {
      const data = { ...this.ruleForm }
      if (this.priceParams.pay_amount === 0) {
        data.pay_type = 99
      }
      orderPayment(data).then(result => {
        this.btnLoading = false
        this.confirmVisible = false
        if (this.ruleForm.pay_type === 3) {
          const body = document.body || document.getElementsByTagName('body')[0]
          const div = document.createElement('div')
          div.innerHTML = result.html
          body.appendChild(div)
          document.forms['submitForm'].submit()
        } else {
          this.$router.push('/order/complete')
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handlePaypay() {
      sendPaypay(this.paypayForm).then(result => {
        this.btnLoading = false
        this.confirmVisible = false
        const body = document.body || document.getElementsByTagName('body')[0]
        const div = document.createElement('div')
        div.innerHTML = result
        body.appendChild(div)
        document.forms['paypaySettleForm'].submit()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handleRakutenPay() {
      sendRakutenPay().then(result => {
        this.btnLoading = false
        const chargeId = result.charge_id
        if (chargeId) {
          this.confirmVisible = false
          window.location.href = `${process.env.VUE_APP_RAKUTEN_PAY}?charge_id=${chargeId}`
        } else {
          this.$toast('支払いに失敗しました。後ほど、もう一度お試しください。')
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handleConfirmPay() {
      this.btnLoading = true
      useDiscount(this.discountForm).then(() => {
        switch (this.ruleForm.pay_type) {
          case 2: // 银行
            this.orderPayment()
            break
          case 3: // 黑猫
            var createTokenInfo = {
              traderCode: process.env.VUE_APP_BLACKCAT_CREDIT_CODE,
              authDiv: '3',
              optServDiv: '00',
              memberId: '',
              authKey: '',
              checkSum: process.env.VUE_APP_BLACKCAT_CHECK_SUM,
              cardNo: this.blackcatForm.credit_number,
              cardOwner: this.blackcatForm.credit_username,
              cardExp: this.blackcatForm.date.replace('/', ''),
              securityCode: this.blackcatForm.credit_security_code
            }
            // ｗｅｂコレクトが提供する JavaScript 関数を実行し、トークンを発行する。
            /* eslint-disable */
            WebcollectTokenLib.createToken(createTokenInfo, this.callbackSuccess, this.callbackFailure)
            /* eslint-enable */
            break
          case 4: // amazon
            this.orderPayment()
            break
          case 8: // paypay
            this.handlePaypay()
            break
          case 9: // rakuten pay
            this.handleRakutenPay()
            break
        }
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.order-content{
  ::v-deep .el-radio__label{
    font-weight: 700;
  }
  .el-select{
      width: 460px;
  }
  ::v-deep .el-radio__inner{
      width: 18px;
      height: 18px;
  }
  .shop-content{
    display: flex;
    justify-content: space-between;
    width: 1440px;
    margin: 88px auto;
    .left-content{
      width: 952px;
      .message-content{
          font-size: 16px;
          .list{
              display: flex;
              justify-content: space-between;
              padding: 24px;
              border: 1px solid #e8ebed;
              border-radius: 4px;
              &:not(:last-child){
                  margin-bottom: 8px;
              }
              .text{
                  display: flex;
                  .name{
                      display: flex;
                      align-items: center;
                      width: 144px;
                  }
                  .value{
                      width: 700px;
                      line-height: 24px;
                      font-weight: 700;
                  }
              }
              .change-btn{
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  color: #666666;
              }
              .price{
                  display: flex;
                  align-items: center;
                  color: #c40a0a;
              }
          }
      }
      .discount-content{
        margin-top: 40px;
        .title{
            font-size: 20px;
        }
        .radio-content{
              margin-top: 12px;
              border: 1px solid #e8ebed;
              border-radius: 4px;
              .el-radio-group{
                width: 100%;
              }
              .item{
                  &:not(:last-child){
                      border-bottom: 1px solid #e8ebed;
                  }
                  b{
                    font-size: 24px;
                  }
                  .radio{
                      width: 100%;
                      padding: 24px;
                      box-sizing: border-box;
                  }
              }
          }
          .discount-detail{
            .input-content{
              padding: 12px 24px 12px 60px;
              background-color: #f4f5fc;
              span{
                font-size: 16px;
                font-weight: 700;
              }
            }
            .coupon-content{
              padding: 24px 24px;
              .list{
                &:not(:first-child){
                  margin-top: 8px;
                }
                .time{
                  margin-top: 8px;
                  font-size: 12px;
                  font-weight: normal;
                  color: #999FA5;
                }
              }
              .el-radio{
                display: flex;
                padding: 16px 24px;
                background-color: #f4f5fc;
              }
              .is-checked{
                border: 1px solid #333333;
              }
              ::v-deep .el-radio__input{
                display: flex;
                align-items: center;
              }
            }
          }
          .el-input{
            width: 260px;
            margin-left: 12px;
          }
          .el-button{
            height: 46px;
            margin-left: 16px;
          }
      }
      .payment-content{
          margin-top: 40px;
          .title{
              font-size: 20px;
          }
          .desc{
              margin-top: 12px;
              font-size: 16px;
          }
          .radio-content{
              margin-top: 12px;
              border: 1px solid #e8ebed;
              border-radius: 4px;
              .item{
                  &:not(:last-child){
                      border-bottom: 1px solid #e8ebed;
                  }
                  .rakuten-icon{
                    height: 34px;
                    margin-right: 10px;
                    vertical-align: middle;
                  }
                  .pay-name{
                    vertical-align: middle;
                  }
                  .payment-desc{
                      padding: 38px 24px 1px;
                      font-size: 16px;
                      background: #f4f5fc;
                      .text{
                        position: relative;
                        line-height: 24px;
                        padding-bottom: 24px;
                        #AmazonPayButton{
                          position: absolute;
                          top: 26px;
                          right: 0;
                        }
                      }
                      .el-input,.el-select{
                          width: 400px;
                      }
                      .flex{
                          display: flex;
                          justify-content: space-between;
                          width: 400px;
                          .el-input{
                              width: 194px;
                          }
                      }
                  }
                  .rakuten-content{
                    display: flex;
                    img{
                      width: 256px;
                      height: 86px;
                    }
                    .text{
                      width: 600px;
                      margin-left: 40px;
                    }
                  }
                  .table-content{
                      display: flex;
                      justify-content: space-between;
                      width: 794px;
                      margin-bottom: 24px;
                  }
                  .table{
                        width: 385px;
                        text-align: center;
                        td{
                        height: 48px;
                        border: 1px solid #333333;
                            &:first-of-type {
                                width: 144px;
                            }
                        }
                    }
                    .el-radio{
                        width: 100%;
                        padding: 24px;
                        box-sizing: border-box;
                    }
                    .amazon .el-radio{
                      padding: 16px 24px;
                    }
              }
          }
      }
    }
    .right-content{
      width: 416px;
      .sticky{
          position: sticky;
          top: 0;
          padding: 32px 24px;
          background-color: #f4f5fc;
          box-sizing: border-box;
      }
      .title{
        font-size: 20px;
        font-weight: 700;
      }
      .detail{
        padding: 24px 0;
        margin-top: 16px;
        margin-bottom: 44px;
        border-top: 1px solid #333333;
        border-bottom: 1px solid #333333;
        .item{
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          &:not(:last-child){
            margin-bottom: 24px;
          }
          .price{
            font-weight: 700;
          }
        }
      }
      .font16{
        font-size: 16px !important;
      }
      .light-btn{
        width: 368px;
        height: 60px;
        margin-bottom: 16px;
        line-height: 60px;
        border: 1px solid #333333;
        font-size: 20px;
        text-align: center;
        background-color: #fff;
        color: #333333;
        box-sizing: border-box;
        cursor: pointer;
      }
      .next-btn{
        width: 368px;
        height: 60px;
        margin-bottom: 16px;
        line-height: 60px;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        background-color: #333333;
        cursor: pointer;
      }
    }
  }
  .modal-content{
      width: 452px;
      padding-bottom: 56px;
      text-align: center;
      .title{
          margin-top: 56px;
          font-size: 28px;
          font-weight: 700;
          color: #c40a0a;
      }
      .text{
          margin-top: 24px;
          font-size: 16px;
          line-height: 28px;
          a{
            color: #055FE6;
            font-weight: bold;
            text-decoration: underline;
          }
      }
      .button-content{
          display: flex;
          justify-content: space-between;
          width: 304px;
          margin: 52px auto 0;
      }
      .button{
          width: 142px;
          height: 48px;
          text-align: center;
          border: 1px solid #333333;
          font-size: 14px;
          cursor: pointer;
      }
      .cancel-btn{
          background-color: #fff;
          color: #333;
          &:hover{
            opacity: .7;
          }
      }
  }
}
</style>
