<template>
  <div class="login-content">
    <div class="title">会員登録がお済みの方</div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
      <el-form-item prop="email" label="">
        <el-input v-model.trim="ruleForm.email" placeholder="メールアドレス（会員ID）※" />
      </el-form-item>
      <el-form-item prop="password" label="">
        <el-input :key="passwordType" ref="password" v-model.trim="ruleForm.password" :type="passwordType" placeholder="パスワード※" @keyup.enter.native="handleLogin" />
        <span class="show-pwd" @click="showPwd">
          <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>
      <el-form-item prop="" label="">
        <el-button type="primary" class="button" :loading="btnLoading" @click="handleLogin">
          ログインする
        </el-button>
        <!-- <div class="button" @click="handleLogin">
          ログインする
        </div> -->
        <div class="forget-password">
          <router-link to="/password">
            パスワードをお忘れの場合
          </router-link>
        </div>
      </el-form-item>
    </el-form>
    <div class="cut">
      <span class="line" />
      <span class="text">または</span>
      <span class="line" />
    </div>
    <router-link to="/register">
      <div class="register-button">
        新規会員登録する
      </div>
    </router-link>
    <div v-if="cartIds" class="from-cart-content">
      <div class="s-title">まだ会員登録されていない方</div>
      <div class="s-text">会員登録をせずに購入手続きをされたいかたは、下記よりお進みください。<br>ポイントの獲得や会員限定クーポンなどの適用対象外となりますのでご了承ください。</div>
      <el-button type="primary to-order" :loading="orderLoading" @click="handleOrder">会員登録せずに購入する ➜</el-button>
    </div>
  </div>
</template>
<script>
import { toOrder } from '@/api/cart'
export default {
  data() {
    return {
      passwordType: 'password',
      btnLoading: false,
      orderLoading: false,
      cartIds: this.$route.query && this.$route.query.cart_ids,
      ruleForm: {},
      rules: {
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ],
        password: { required: true, message: 'パスワードをご入力ください', trigger: ['blur'] }
      }
    }
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.btnLoading = true
          this.$store.dispatch('user/login', this.ruleForm).then(() => {
            this.btnLoading = false
            this.$router.push({ path: '/personal/mine' })
            this.$store.dispatch('user/getCartNumber')
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    handleOrder() {
      this.orderLoading = true
      toOrder({ cart_list_ids: this.cartIds.split(',') }).then(() => {
        this.orderLoading = false
        this.$router.push('/order/address')
      }).catch(() => {
        this.orderLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login-content{
    padding: 0 .24rem;
    margin-top: .56rem;
    flex: 1;
    font-size: .28rem;
    .title{
        font-size: .36rem;
        font-weight: 700;
    }
    .el-form{
        margin-top: .4rem;
    }
    .el-form-item{
        margin-bottom: .4rem;
    }
    .show-pwd{
        position: absolute;
        right: .2rem;
        top: 0;
        font-size: .48rem;
        user-select: none;
    }
    .button{
      width: 100%;
      margin-top: .24rem;
    }
    .forget-password{
      text-align: right;
      color: #999999;
      text-decoration: underline;
    }
    .cut{
      margin-top: .8rem;
      .text{
        margin: 0 .24rem;
        font-size: .24rem;
        color: #b2b2b2;
      }
      .line{
        display: inline-block;
        width: 2.9rem;
        border-top: .02rem solid #e8ebed;
        vertical-align: middle;
      }
    }
    .register-button{
      width: 100%;
      height: .88rem;
      margin-top: .48rem;
      line-height: .88rem;
      text-align: center;
      border: .02rem solid #333333;
      box-sizing: border-box;
    }
    .from-cart-content{
      padding: .24rem .24rem .48rem;
      margin-top: .48rem;
      background-color: rgba(242,242,242);
      .s-title{
        font-size: .36rem;
        font-weight: 700;
        text-align: center;
      }
      .s-text{
        margin-top: .24rem;
        line-height: .36rem;
      }
      .to-order{
        width: 100%;
        margin-top: .24rem;
      }
    }
}
</style>
