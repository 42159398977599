<template>
  <div class="password-content">
    <div class="title">パスワードをお忘れの方</div>
    <div class="text">パスワード再設定用URLをお送りいたします。<br>ご登録のメールアドレスをご入力ください。</div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
      <el-form-item prop="email" label="">
        <el-input v-model.trim="ruleForm.email" placeholder="メールアドレス（会員ID）※" />
      </el-form-item>
      <el-form-item prop="" label="">
        <div class="button" @click="handleRegister">
          <span>送信する</span>
          <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { sendForgetMail } from '@/api/user'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ]
      }
    }
  },
  methods: {
    handleRegister() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          sendForgetMail(this.ruleForm).then(() => {
            this.$router.push({ path: '/password/auth', query: { email: this.ruleForm.email }})
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.password-content{
    margin-top: .56rem;
    padding: 0 .24rem;
    flex: 1;
    .title{
        font-size: .36rem;
        font-weight: 700;
    }
    .text{
        margin-top: .08rem;
        line-height: .42rem;
    }
    .el-form{
        margin-top: .36rem;
    }
    .button{
      width: 100%;
      height: .88rem;
      margin-top: .24rem;
      line-height: .88rem;
      text-align: center;
      color: #ffffff;
      background: #333333;
    }
}
</style>
