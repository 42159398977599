<template>
  <div>
    <div v-html="initData.sp_content" />
    <div v-if="initData.products.length > 0" class="product-content">
      <div class="view-title">関連商品</div>
      <div class="swiper relation-swiper">
        <div class="swiper-wrapper">
          <router-link v-for="(item,index) in initData.products" :key="index" :to="'/product/'+ item.product_sn" class="swiper-slide">
            <img width="100%" :src="item.image">
            <div class="product-desc">
              <div class="product-name">{{ item.title }}</div>
              <div class="price">￥{{ calcThousands(item.price) }}<span>(税込)</span></div>
            </div>
          </router-link>
        </div>
        <div class="swiper-pagination" />
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
      </div>
    </div>
    <div class="link-box">
      <router-link to="/new/product">
        <div>NEW ITEM</div>
        新商品一覧
      </router-link>
      <router-link to="">
        <div>SHORT PERIOD</div>
        短納期商品一覧
      </router-link>
    </div>
    <form action="javascript:return false" @submit="search">
      <input ref="search" v-model="input" class="search" type="search" placeholder="キーワードで検索">
    </form>
  </div>
</template>
<script>
import { getCustomActivityDetail } from '@/api/activity'
import { calcThousands } from '@/utils/validate'
export default {
  data() {
    return {
      url: this.$route.params.url,
      initData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        sp_content: null,
        sp_css_path: '',
        sp_js_path: '',
        products: []
      },
      input: '',
      calcThousands
    }
  },
  metaInfo() {
    return {
      title: this.initData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.initData.seo_keyword },
        { vmid: 'description', name: 'description', content: this.initData.seo_description }
      ],
      link: [
        { vmid: this.initData.sp_css_path, rel: 'stylesheet', href: this.initData.sp_css_path }
      ],
      script: [
        { vmid: this.initData.sp_js_path, defer: 'defer', src: this.initData.sp_js_path }
      ]
    }
  },
  mounted() {
    this.getCustomActivityDetail()
  },
  methods: {
    getCustomActivityDetail() {
      getCustomActivityDetail(this.url, { include: 'products.colorTags' }).then(result => {
        this.initData = result
        this.$nextTick(() => {
          this.swiper()
        })
      })
    },
    swiper() {
      new window.Swiper('.relation-swiper', {
        slidesPerView: 'auto',
        pagination: {
          type: 'progressbar',
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    },
    search(e) {
      this.$router.push({ path: '/search', query: { input: this.input }})
      this.$refs.search.blur()
      e.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.view-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.9rem 0 0.28rem;
  &::before,
  &::after{
    content: '';
    width: 0.8rem;
    height: 0.02rem;
    background: #333333;
    margin: 0 0.4rem;
  }
}
.relation-swiper ::v-deep {
  margin: 0.28rem 0.24rem;
  padding-bottom: 1.12rem;
  .swiper-slide{
    width: 3.44rem;
    margin-right: 0.14rem;
  }
  & .swiper-slide:nth-last-child(1){
    margin-right: 0;
  }
  .product-name{
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-top: 0.16rem;
    min-height: .84rem;
    line-height: .42rem;
  }
  .price{
    margin-top: .24rem;
    font-weight: bold;
  }
  .swiper-pagination{
    top: auto;
    bottom: 0.32rem;
    width: 4.98rem;
    height: 0.02rem;
    background: #E8EBED;
  }
  .swiper-pagination-progressbar-fill{
    background: #333333;
  }
  .swiper-button-prev{
    top: auto;
    left: auto;
    right: 0.92rem;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/left_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
  .swiper-button-next{
    top: auto;
    right: 0;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/right_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
}
.link-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.12rem 0.24rem 0;
  a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3.44rem;
    height: 1.2rem;
    box-sizing: border-box;
    border: 0.02rem solid #333;
  }
  div{
    font-size: 0.32rem;
    font-weight: bold;
    margin-bottom: 0.06rem;
  }
}
.search{
    width: 7.02rem;
    height: 0.88rem;
    padding: 0 0 0 0.6rem;
    border: 0.02rem solid #E8EBED;
    box-sizing: border-box;
    background: url('~@/assets/images/sp/shape.png') 0.2rem center / 0.28rem 0.28rem no-repeat;
    margin: 0.24rem 0.24rem 1.72rem;
}
.search::-webkit-input-placeholder{
  color: #999FA5
}

</style>
