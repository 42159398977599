<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>ご利用ガイド</span>
    </div>
    <div class="title">
      <div class="name">SHOPPING GUIDE</div>
      <div class="text">ご利用ガイド</div>
    </div>
    <div class="category-content">
      <router-link to="/guides/order" class="item">
        <img src="@/assets/images/pc/guides_order.png">
        <span>ご注文について</span>
      </router-link>
      <router-link to="/guides/payment" class="item">
        <img src="@/assets/images/pc/guides_payment.png">
        <span>お支払い方法について</span>
      </router-link>
      <router-link to="/guides/delivery" class="item">
        <img src="@/assets/images/pc/guides_delivery.png">
        <span>配送·送料について</span>
      </router-link>
      <router-link to="/guides/install" class="item">
        <img src="@/assets/images/pc/guides_install.png">
        <span>搬入・組立て・設置</span>
      </router-link>
      <router-link to="/guides/return" class="item">
        <img src="@/assets/images/pc/guides_return.png">
        <span>返品·交換について</span>
      </router-link>
      <router-link to="/guides/question" class="item">
        <img src="@/assets/images/pc/guides_question.png">
        <span>よくあるご質問</span>
      </router-link>
      <router-link to="/guides/afterService" class="item">
        <img src="@/assets/images/pc/guides_after_service.png">
        <span>アフターサービスについて</span>
      </router-link>
      <router-link to="/guides/about" class="item">
        <img src="@/assets/images/pc/guides_about.png">
        <span>WOODLIFEについて</span>
      </router-link>
    </div>
    <div class="question-content">
      <div class="text">ご質問に該当がなかった場合は、下記の窓口へお問い合わせください。</div>
      <router-link to="/question">
        <div class="button">
          お問い合わせフォーム<img src="@/assets/images/pc/button_arrow.png">
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.guides-content{
    width: 1440px;
    margin: 0 auto 80px;
    .breadcrumb{
        margin-top: 28px;
    }
    .title{
        position: relative;
        width: 308px;
        height: 44px;
        margin-top: 60px;
        background-color: #f4f5fc;
        .name{
            position: absolute;
            top: -18px;
            left: 14px;
            font-size: 30px;
            font-weight: 700;
        }
        .text{
            position: absolute;
            bottom: 8px;
            left: 16px;
            font-size: 14px;
        }
    }
    .category-content{
        overflow: hidden;
        margin: 32px auto 0;
        .item{
            float: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 268px;
            height: 230px;
            border: 2px solid #333;
            box-sizing: border-box;
            font-size: 20px;
            margin-bottom: 24px;
            &:not(:nth-child(5n)){
                margin-right: 24px;
            }
            img{
                margin-bottom: 16px;
            }
        }
    }
    .question-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1440px;
        height: 220px;
        background: url('../../../assets/images/pc/guides_bg.png');
        .text{
            font-size: 28px;
            font-weight: 700;
        }
        .button{
            width: 248px;
            height: 48px;
            margin-top: 32px;
            line-height: 48px;
            text-align: center;
            background-color: #333;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            img{
                width: 32px;
                height: 9px;
                margin-left: 4px;
            }
        }
    }
}
</style>
