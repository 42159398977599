<template>
  <div class="personal-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/personal/mine">マイページ</router-link> / <router-link to="/personal/order">購入履歴</router-link> / 商品レビューを書く
    </div>
    <div class="title">商品レビューを書く</div>
    <div class="product-lists">
      <div v-for="item in initData.order_products" :key="item.id" class="list">
        <div class="flex">
          <router-link :to="`/product/${item.product_sn}`">
            <img :src="item.product_img">
          </router-link>
          <div class="desc">
            <div class="name">{{ item.product_title }}</div>
            <div class="sku-content">
              <div v-for="(skuItem,index2) in item.product_sku_display" :key="index2" class="item">{{ skuItem }}</div>
            </div>
            <div class="norm-content">
              <div v-for="(normItem,index3) in item.product_spu_display" :key="index3" class="item">{{ normItem }}</div>
            </div>
          </div>
        </div>
        <div class="rate-content">
          <div class="box">
            <div class="name">商品評価</div>
            <van-rate v-model="item.stars" color="#fccf52" void-icon="star" void-color="#e3e4eb" :readonly="isPreview" />
            <span class="value">{{ item.stars }}</span>
          </div>
          <div v-show="!isPreview" :class="item.fileList.length > 0 ? 'image-content' : 'empty-image'">
            <van-uploader
              v-model="item.fileList"
              multiple
              :after-read="afterRead"
              :max-count="4"
              :before-delete="beforeDelete"
              :max-size="1024 * 1024"
              @oversize="onOversize"
            >
              <div class="upload-btn">
                <div class="notice">
                  <img src="@/assets/images/sp/upload_icon.png" alt="">
                  <p>写真を追加する </p>
                  <p class="limit">({{ item.fileList.length }}/4)</p>
                </div>
              </div>
            </van-uploader>
          </div>
          <div v-show="isPreview" class="preview-image" :class="item.imageList.length === 1 ? 'one': item.imageList.length === 2 ? 'two' : item.imageList.length === 3 ? 'three' : item.imageList.length === 4 ? 'four': ''">
            <img v-for="(item2,key) in item.imageList" :key="key" :src="item2.url">
          </div>
          <el-input v-show="!isPreview" v-model="item.content" type="textarea" maxlength="500" placeholder="商品のレビューを書く（最大500文字）" />
          <div v-show="isPreview" class="comments-value">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div v-show="!isPreview">
      <div class="button" @click="handlePreview">レビューを投稿する</div>
    </div>
    <div v-show="isPreview" class="button-content">
      <el-button type="primary" class="submit-button" :loading="btnLoading" @click="onSubmit">投稿する</el-button>
      <div class="cancel-button" @click="isPreview = false">修正する</div>
    </div>
    <van-overlay :show="dialogVisible">
      <div class="wrapper" @click.stop>
        <div class="modal-content">
          <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="handleClose">
          <div class="g-title">レビュー投稿完了</div>
          <div class="text">レビューのご投稿ありがとうございます。</div>
          <div class="close" @click="handleClose">閉じる</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import _ from 'lodash'
import { getMyOrderDetail, addComments } from '@/api/personal'
import { uploadImage } from '@/api/upload'
import { calcThousands } from '@/utils/validate'
import { scrollTo } from '@/utils/scrollTo'
export default {
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      index: 0,
      dialogVisible: false,
      btnLoading: false,
      initData: {},
      isPreview: false,
      calcThousands
    }
  },
  computed: {
  },
  created() {
    this.getMyOrderDetail()
  },
  methods: {
    getMyOrderDetail() {
      getMyOrderDetail(this.id, { include: 'orderProducts' }).then(result => {
        this.initData = result
        this.initData.order_products.forEach(item => {
          this.$set(item, 'stars', 5)
          this.$set(item, 'fileList', [])
          this.$set(item, 'imageList', [])
        })
      })
    },
    afterRead(content) {
      if (_.isArray(content)) { // 同时传多个
        content.forEach(item => {
          this.upload(item)
        })
      } else {
        this.upload(content)
      }
    },
    upload(content) {
      content.index = this.index
      this.index++
      const formData = new FormData()
      formData.append('image', content.file)
      formData.append('type', 'comment')
      uploadImage(formData).then(result => {
        this.initData.order_products.forEach(item => {
          item.fileList.forEach(item2 => {
            if (item2.index === content.index) {
              item.imageList.push({
                url: result.path_url,
                index: content.index
              })
            }
          })
        })
        const vanWrapper = document.getElementsByClassName('van-uploader__wrapper')[0]
        vanWrapper.scrollTo(vanWrapper.scrollWidth, 0)
      }).catch(() => {
        this.initData.order_products.forEach(item => {
          item.fileList.forEach(item2 => {
            if (item2.index === content.index) {
              item.fileList = [...item.imageList]
            }
          })
        })
      })
    },
    beforeDelete(content, detail) {
      this.initData.order_products.forEach(item => {
        item.fileList.forEach(item2 => {
          if (item2.index === content.index) {
            item.imageList.splice(detail.index, 1)
          }
        })
      })
      return true
    },
    onOversize() {
      this.$toast('ファイルサイズは1mbまで')
    },
    onSubmit() {
      const data = {
        order_id: this.id,
        comments: []
      }
      this.initData.order_products.forEach(item => {
        data.comments.push({
          product_id: item.product_id,
          product_attribute: item.product_sku_display.toString(),
          product_name: item.product_title,
          product_spu_display: item.product_spu_display.join(';'),
          stars: item.stars,
          images: item.imageList,
          content: item.content
        })
      })
      data.comments.forEach(item => {
        const arr = []
        item.images.forEach(item2 => {
          arr.push(item2.url)
        })
        item.images = [...arr]
      })
      this.btnLoading = true
      addComments(data).then(() => {
        this.btnLoading = false
        this.dialogVisible = true
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$router.push('/personal/order')
    },
    handlePreview() {
      this.isPreview = true
      scrollTo(0, 0)
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content {
    flex: 1;

    .bread-crumbs {
        padding: 0 .24rem;
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .title{
      padding-bottom: .24rem;
      margin: .56rem .24rem 0;
      font-size: .36rem;
      font-weight: 700;
      border-bottom: .02rem solid #333333;
    }
    .product-lists{
      .list{
        padding: 0 .24rem;
        margin-top: .4rem;
        &:not(:last-child){
          padding-bottom: .4rem;
          border-bottom: .16rem solid #f4f5fc;
        }
        .flex{
          display: flex;
          justify-content: space-between;
        }
        img{
          width: 2rem;
          height: 2rem;
          object-fit: cover;
        }
        .desc{
          width: 4.78rem;
          .name{
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:1;
            overflow:hidden;
            margin-bottom: .24rem;
            font-weight: 700;
          }
          .sku-content,.norm-content{
            font-size: .24rem;
            color: #999999;
            line-height: .36rem;
          }
        }
        .rate-content{
          margin-top: .48rem;
          .box{
            display: flex;
            line-height: .48rem;
          }
          .empty-image{
            margin-top: .32rem;
            .upload-btn{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 2.24rem;
              background: #f4f5fc;
              border: .02rem dashed #333333;
              text-align: center;
              font-size: .24rem;
              img{
                width: .56rem;
                height: .56rem;
              }
            }
            .van-uploader{
              width: 100%;
            }
            ::v-deep .van-uploader__input-wrapper{
              width: 100%;
            }
          }
          .image-content{
            margin-top: .4rem;
            ::v-deep .van-uploader{
              display: block;
              .van-uploader__wrapper{
                flex-wrap: nowrap;
                overflow-x: scroll;
              }
              .van-uploader__preview{
                margin: 0;
                .van-uploader__preview-image{
                  width: 2.16rem;
                  height: 2.16rem;
                  margin: .12rem .14rem 0 0;
                }
              }
              .van-uploader__preview-delete{
                width: .4rem;
                height: .4rem;
                border-radius: 50%;
                right: .06rem;
              }
              .van-uploader__preview-delete-icon{
                top: 0.1rem;
                right: 0.07rem;
                font-size: .24rem;
                font-weight: 700;
                transform: scale(1);
              }
            }
            .upload-btn{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2.24rem;
              height: 2.24rem;
              background: #f4f5fc;
              border: .02rem dashed #333333;
              font-size: .24rem;
              text-align: center;
              img{
                width: .56rem;
                height: .56rem;
              }
            }
          }
          .preview-image{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: .1rem;
            img{
              margin-top: .14rem;
            }
          }
          .one{
            img{
              width: 4.32rem;
              height: 4.32rem;
            }
          }
          .two,.four{
            img{
              width: 3.44rem;
              height: 3.44rem;
            }
          }
          .three{
            img{
              width: 2.24rem;
              height: 2.24rem;
            }
          }
          .name{
            font-size: .32rem;
            margin-right: .24rem;
          }
          .value{
            margin-left: .24rem;
            font-size: .32rem;
            color: #fccf52;
            font-weight: 700;
          }
           ::v-deep .van-rate__icon{
            font-size: .48rem;
          }
           ::v-deep .el-textarea__inner{
                width: 100%;
                height: 2.4rem;
                margin-top: .24rem;
            }
            .comments-value{
              margin-top: .24rem;
              font-weight: 700;
            }
        }
      }
    }
    .button{
      height: 1.08rem;
      margin: .8rem .24rem 0;
      line-height: 1.08rem;
      text-align: center;
      background: #333333;
      color: #ffffff;
      font-weight: 700;
    }
    .button-content{
      padding: 0 .24rem;
      margin-top: .8rem;
      .submit-button{
        width: 100%;
        height: 1.08rem;
        font-weight: 700;
      }
      .cancel-button{
        height: .88rem;
        margin-top: .16rem;
        line-height: .88rem;
        text-align: center;
        border: .02rem solid #333333;
      }
    }
    .modal-content{
      .g-title{
        font-size: .36rem;
        font-weight: 700;
        color: #4bc4b0;
      }
      .text{
        margin-top: .34rem;
      }
      .close{
        width: 100%;
        height: .88rem;
        margin-top: .5rem;
        line-height: .88rem;
        background: #333333;
        color: #ffffff;
      }
    }
}
</style>
