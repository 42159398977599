var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.confirmVisible,
          expression: "confirmVisible",
        },
      ],
      staticClass: "overlay confirm-overlay",
    },
    [
      _c("div", { staticClass: "main" }, [
        _c(
          "div",
          { staticClass: "modal-content" },
          [
            !_vm.confirmVisibleSuccess
              ? [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("メールで入荷通知を受取る"),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v("お知らせを受けるメールアドレスをご入力ください。"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    attrs: { type: "text", placeholder: "E-mail:" },
                    domProps: { value: _vm.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "confirm-btn",
                      on: { click: _vm.emailSubmit },
                    },
                    [_vm._v("Eメール登録")]
                  ),
                ]
              : [
                  _vm._m(0),
                  _c(
                    "button",
                    { staticClass: "confirm-btn", on: { click: _vm.close } },
                    [_vm._v("閉じる")]
                  ),
                ],
            _c("img", {
              staticClass: "close-btn",
              attrs: { src: require("@/assets/images/pc/x.png") },
              on: { click: _vm.close },
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _vm._v("入荷お知らせを"),
      _c("br"),
      _vm._v("受付致しました"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }