// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/sp/question_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tips[data-v-af7d719e] {\n  text-align: center;\n  margin: 0.16rem 0 0.16rem;\n}\n.question-title[data-v-af7d719e] {\n  height: 2.74rem;\n  font-size: 0.32rem;\n  padding: 0.4rem 0.24rem 0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center/100% 100%;\n  margin: 0.36rem 0 0.56rem;\n}\n.question-title a[data-v-af7d719e] {\n  display: block;\n  height: 0.8rem;\n  line-height: 0.8rem;\n  font-size: 0.28rem;\n  text-align: center;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  border: 0.02rem solid #333;\n  background: #fff;\n  margin-top: 0.35rem;\n}", ""]);
// Exports
module.exports = exports;
