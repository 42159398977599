<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / よくあるご質問
    </div>
    <div class="title-content">
      <div class="en">Contact us by email</div>
      <div class="ja">メールでお問い合わせ</div>
    </div>
    <div class="text-content">
      下記フォームに必要事項をご記入の上、送信してください。<br><br>
      24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。<br><br>
      ※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。<br><br>
      ※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。
    </div>
    <table class="table">
      <tr>
        <td>お問い合わせ項目</td>
        <td>
          <span v-if="ruleForm.type === 1">商品について</span>
          <span v-else-if="ruleForm.type === 2">納期について</span>
          <span v-else-if="ruleForm.type === 3">不良交換について</span>
          <span v-else-if="ruleForm.type ===4">サイトについて</span>
          <span v-else-if="ruleForm.type === 5">その他</span>
        </td>
      </tr>
      <tr>
        <td>お名前</td>
        <td>{{ ruleForm.username }}</td>
      </tr>
      <tr>
        <td>メールアドレス</td>
        <td>{{ ruleForm.email }}</td>
      </tr>
      <tr>
        <td>お問い合わせ内容</td>
        <td>{{ ruleForm.content }}</td>
      </tr>
    </table>
    <el-button type="primary" class="submit-btn" :loading="btnLoading" @click="onSubmit()">送信する</el-button>
    <router-link to="/guides/question">
      <div class="prev-btn">修正する</div>
    </router-link>
    <van-overlay :show="dialogVisible">
      <div class="wrapper" @click.stop>
        <div class="modal-content">
          <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="handleClose">
          <div class="title">お問い合わせ受付完了</div>
          <div class="text">お問い合わせありがとうございます。<br>順次ご対応いたします、しばらくお待ちください。</div>
          <div class="close" @click="handleClose">閉じる</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { questions } from '@/api/config'
export default {
  components: {},
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      ruleForm: {
        type: 1,
        username: '',
        email: '',
        content: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'question'
    ])
  },
  created() {
    this.ruleForm = { ...this.question }
  },
  methods: {
    onSubmit() {
      this.btnLoading = true
      questions(this.ruleForm).then(() => {
        this.btnLoading = false
        this.dialogVisible = true
        this.$store.dispatch('config/setQuestion', {})
      }).catch(() => {
        this.btnLoading = false
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$router.push('/')
    }
  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .title-content{
        position: relative;
        height: .68rem;
        margin-top: .56rem;
        background-color: #f4f5fc;
        .en{
            position: absolute;
            left: .24rem;
            top: -.2rem;
            font-size: .36rem;
            font-weight: 700;
        }
        .ja{
            position: absolute;
            left: .24rem;
            bottom: .1rem;
            font-size: .24rem;
        }
    }
    .text-content{
        margin-top: .44rem;
        line-height: .42rem;
    }
    .table{
        width: 100%;
        margin-top: .4rem;
        td{
            padding: .24rem;
            border: .02rem solid #333333;
            &:first-child{
                width: 2.72rem;
            }
        }
    }
    .submit-btn{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        font-weight: 700;
    }
    .prev-btn{
        width: 100%;
        height: .88rem;
        margin-top: .16rem;
        line-height: .88rem;
        text-align: center;
        border: .02rem solid #333333;
        box-sizing: border-box;
    }
     .modal-content{
      .title{
        font-size: .36rem;
        font-weight: 700;
        color: #4bc4b0;
      }
      .text{
        margin-top: .4rem;
        line-height: .42rem;
      }
      .close{
        width: 100%;
        height: .88rem;
        margin-top: .5rem;
        line-height: .88rem;
        background: #333333;
        color: #ffffff;
      }
    }
}
</style>
