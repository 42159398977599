<template>
  <div class="login-content">
    <div class="bg">
      <div class="breadcrumb">
        <router-link to="/">家具通販専門店TOP</router-link>
        <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
        <span>ログイン</span>
      </div>
    </div>
    <div class="right-form">
      <div class="title">会員のお客様</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item prop="email" label="">
          <el-input v-model.trim="ruleForm.email" placeholder="メールアドレス（会員ID）※" />
        </el-form-item>
        <el-form-item prop="password" label="">
          <el-input v-model.trim="ruleForm.password" type="password" placeholder="パスワード※" @keyup.enter.native="handleLogin" />
        </el-form-item>
        <el-form-item prop="" label="">
          <div class="forget-password">
            <router-link to="/password">
              パスワードをお忘れの場合
            </router-link>
          </div>
          <div class="button" @click="handleLogin">
            <span>ログインする</span>
            <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
          </div>
        </el-form-item>
      </el-form>
      <div class="cut">
        <span class="line" />
        <span class="text">または</span>
        <span class="line" />
      </div>
      <router-link to="/register">
        <div class="register-button">
          新規ユーザー登録する
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ],
        password: { required: true, message: 'パスワードをご入力ください', trigger: ['blur'] }
      }
    }
  },
  methods: {
    handleLogin() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$store.dispatch('user/login', this.ruleForm).then(() => {
            this.$router.push({ path: '/personal/mine' })
            this.$store.dispatch('user/getCartNumber')
          }).catch(() => {})
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login-content{
    display: flex;
    .bg{
        width: 1076px;
        height: 760px;
        background: url('../../../assets/images/pc/login_bg.png') 100%;
        .breadcrumb{
            margin-top: 28px;
            margin-left: 240px;
        }
    }
    .right-form{
        margin-left: 18px;
        .title{
          margin-top: 80px;
          font-size: 28px;
          font-weight: 700;
        }
        .el-form{
          margin-top: 38px;
        }
        .el-input{
          width: 400px;
        }
        .forget-password{
            height: 34px;
            font-size: 14px;
            color: #999999;
            text-decoration: underline;
            text-align: right;
        }
        .button{
          display: flex;
          justify-content: space-between;
          width: 400px;
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          background: #333;
          color: #fff;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
          img{
            width: 32px;
            height: 9px;
          }
          .arrow{
            display: flex;
            align-items: center;
          }
        }
        .cut{
          margin-top: 60px;
          color: #999999;
            .text{
              margin: 0 4px;
            }
            .line{
              display: inline-block;
              width: 175px;
              border-top: 1px solid #e8ebed;
              vertical-align: middle;
            }
        }
        .register-button{
          width: 400px;
          height: 60px;
          margin-top: 34px;
          line-height: 60px;
          text-align: center;
          border: 1px solid #333;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
        }
    }
}
</style>
