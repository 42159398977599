var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "swiper home-page-swiper" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.swiperData, function (item, index) {
          return _c("div", { key: index, staticClass: "swiper-slide" }, [
            item.href
              ? _c("a", { attrs: { href: item.href } }, [
                  _c("img", { attrs: { src: item.image, alt: item.alt } }),
                ])
              : _c("img", { attrs: { src: item.image, alt: item.alt } }),
          ])
        }),
        0
      ),
    ]),
    _c("div", {
      staticClass: "custom",
      domProps: { innerHTML: _vm._s(_vm.customData.pc_content) },
    }),
    _c("div", { staticClass: "article-box" }, [
      _c(
        "div",
        { staticClass: "article-item" },
        [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("span", [_vm._v("NEWS")]),
              _c("router-link", { attrs: { to: "/article" } }, [
                _vm._v("view more"),
              ]),
            ],
            1
          ),
          _vm._l(_vm.newsArticleData, function (item) {
            return _c(
              "router-link",
              {
                key: item.id,
                staticClass: "article-item-box",
                attrs: { to: "/article/" + item.id },
              },
              [
                _c("img", { attrs: { src: item.image } }),
                _c("div", { staticClass: "article-desc" }, [
                  _c("div", [_vm._v(_vm._s(item.title))]),
                  _c("div", [_vm._v(_vm._s(item.description))]),
                  _c("div", [_vm._v(_vm._s(item.created_at.substring(0, 10)))]),
                ]),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "article-item" },
        [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("span", [_vm._v("INFORMATION")]),
              _c("router-link", { attrs: { to: "/article" } }, [
                _vm._v("view more"),
              ]),
            ],
            1
          ),
          _vm._l(_vm.informationArticleData, function (item) {
            return _c(
              "router-link",
              {
                key: item.id,
                staticClass: "article-item-box",
                attrs: { to: "/article/" + item.id },
              },
              [
                _c("img", { attrs: { src: item.image } }),
                _c("div", { staticClass: "article-desc" }, [
                  _c("div", [_vm._v(_vm._s(item.title))]),
                  _c("div", [_vm._v(_vm._s(item.description))]),
                  _c("div", [_vm._v(_vm._s(item.created_at.substring(0, 10)))]),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]),
    _vm._m(0),
    _vm._m(1),
    _vm.productData.length
      ? _c("div", { staticClass: "product-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("PRODUCTS")]),
          _c("div", { staticClass: "desc" }, [_vm._v("商品情報")]),
          _c(
            "div",
            { staticClass: "waterfall-container" },
            _vm._l(_vm.productData, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "waterfall-item" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/product/" + item.product_sn } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image",
                          class: { active: item.active },
                        },
                        [
                          _c("img", {
                            attrs: { src: item.image, alt: item.image_alt },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "collect",
                              on: {
                                click: function (event) {
                                  return _vm.handleCollection(event, item)
                                },
                              },
                            },
                            [
                              item.isCollect
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/sp/home_collect_in.png"),
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/sp/home_collect.png"),
                                    },
                                  }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPage !== _vm.allPage,
                  expression: "currentPage !== allPage",
                },
              ],
              staticClass: "view-more",
              on: { click: _vm.handleMoreProduct },
            },
            [_vm._v("他の商品を表示する")]
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "category-search-content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("CATEGORY SEARCH")]),
      _c("div", { staticClass: "desc" }, [_vm._v("カテゴリー検索")]),
      _c(
        "div",
        { staticClass: "item-content" },
        [
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/sofa" } },
            [_vm._v("ソファ")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/table" } },
            [_vm._v("テーブル")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/bed" } },
            [_vm._v("ベッド")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/chair" } },
            [_vm._v("チェア")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/rack" } },
            [_vm._v("収納・本棚")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/TV_board" } },
            [_vm._v("TVボード")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/interior" } },
            [_vm._v("インテリア雑貨")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/category/outdoor" } },
            [_vm._v("アウトドア")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "our-service-content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("OUR SERVICES")]),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "item" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/our_services_1.png"),
              alt: "組立設置サービス",
            },
          }),
          _c("div", { staticClass: "name" }, [_vm._v("組立設置サービス")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v("配送員が開梱・組立・設置・梱包資材の回収までを行います。"),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/our_services_2.png"),
              alt: "送料無料",
            },
          }),
          _c("div", { staticClass: "name" }, [_vm._v("送料無料")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "北海道 · 沖縄県 · 離島、山間部の配送は別途お見積もりとなります。"
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/our_services_3.png"),
              alt: "安心の保証",
            },
          }),
          _c("div", { staticClass: "name" }, [_vm._v("安心の保証")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "商品のお届け日から１年間を保証期間として、無償修理致します。"
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/our_services_4.png"),
              alt: "AmazonPay対応",
            },
          }),
          _c("div", { staticClass: "name" }, [_vm._v("AmazonPay対応")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "Amazonアカウント情報を使って簡単にログインし、安心、安全に商品を購入することが可能です。"
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ins-content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("INSTAGRAM")]),
      _c("div", { staticClass: "desc" }, [_vm._v("@woodlife.jp")]),
      _c("div", {
        staticClass: "powr-instagram-feed",
        attrs: { id: "c7c2cf80_1678778462" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }