<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/personal/order">注文履歴</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>注文詳細</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/order" />
      </div>
      <div class="right-content">
        <div class="order-content">
          <div class="head">
            <div>
              <span>ご注文番号：{{ initData.order_sn }}</span>
              <span class="time">{{ initData.created_at }}</span>
            </div>
            <div class="status">{{ initData.store_status && initData.store_status.name }}</div>
          </div>
          <div class="product-content">
            <div>
              <div v-for="item in initData.order_products" :key="item.id" class="list">
                <div class="product">
                  <router-link :to="`/product/${item.product_sn}`">
                    <img :src="item.product_img">
                  </router-link>
                  <div class="desc">
                    <div class="name">{{ item.product_title }}</div>
                    <div class="sku-content">
                      <div v-for="(skuItem,index2) in item.product_sku_display" :key="index2" class="item">{{ skuItem }}</div>
                    </div>
                    <div class="norm-content">
                      <div v-for="(normItem,index3) in item.product_spu_display" :key="index3" class="item">{{ normItem }}</div>
                    </div>
                  </div>
                </div>
                <div class="price-content">
                  <div class="price">¥{{ calcThousands(item.pay_price) }}円</div>
                  <div class="num">個数：{{ item.product_quantity }} 件</div>
                </div>
              </div>
            </div>
            <div class="total-content">
              <div>
                <div class="name">送料</div>
                <div class="value">+¥{{ calcThousands(initData.freight_amount) }}</div>
              </div>
              <div>
                <div class="name">クーポン</div>
                <div class="value">-¥{{ calcThousands(initData.use_point_fee) }}</div>
              </div>
              <div>
                <div class="name">利用ポイント</div>
                <div class="value">-¥{{ calcThousands(initData.coupon_amount) }}</div>
              </div>
              <div>
                <div class="name">合计金额</div>
                <div class="value price">¥{{ calcThousands(initData.pay_amount) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="address-content">
          <div class="item">
            <div class="name">連絡先</div>
            <div class="value">{{ initData.receiver_email }}</div>
          </div>
          <div class="item">
            <div class="name">配送先</div>
            <div class="value">
              {{ initData.receiver_name }} 様<br>
              〒{{ initData.zip_code }} {{ initData.stats }}{{ initData.city }}{{ initData.address }}{{ initData.detail_address ? initData.detail_address : '' }}<br>
              {{ initData.receiver_phone }}
            </div>
          </div>
          <div class="item">
            <div class="name">配送方法</div>
            <div class="value">通常出荷</div>
          </div>
          <div class="item">
            <div class="name">備考欄</div>
            <div class="value">{{ initData.note }}</div>
          </div>
          <div class="item between">
            <div class="name">投稿したレビュー</div>
            <router-link v-if="initData.is_comment === 1" :to="`/personal/order/my_comments/${id}`">レビューを見る</router-link>
          </div>
        </div>
        <div class="email-content">
          <div class="title">
            <div>
              <span class="ja">メール配信履歴一覧</span><span class="en">（Mail delivery list）</span>
            </div>
            <div class="text">1ヶ月以内に送信されたメールを表示しています。</div>
          </div>
          <div class="list-content">
            <div v-for="item in initData.order_mail_record" :key="item.id" class="list" @click="item.showEmailContent = !item.showEmailContent">
              <div class="mail-subject">
                <div>
                  <img src="@/assets/images/pc/email_icon.png">
                  <span>{{ item.mail_subject }}</span>
                </div>
                <div class="time">{{ item.created_at }}</div>
              </div>
              <div v-show="item.showEmailContent" class="content" v-html="item.mail_content" />
            </div>
          </div>
        </div>
        <div class="action-content">
          <!-- <div class="button dark">もう一度購入する</div> -->
          <router-link to="/question">
            <div class="button">お問い合わせ</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMyOrderDetail } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
import SideBar from './component/sideBar'
export default {
  components: { SideBar },
  data() {
    return {
      id: this.$route.params.id,
      initData: {
        freight_amount: 0,
        pay_amount: 0,
        use_point_fee: 0,
        coupon_amount: 0
      },
      calcThousands
    }
  },
  computed: {

  },
  created() {
    this.getMyOrderDetail()
  },
  methods: {
    getMyOrderDetail() {
      getMyOrderDetail(this.id, { include: 'orderProducts,storeStatus,orderMailRecord' }).then(result => {
        this.initData = result
        this.initData.order_mail_record.forEach(item => {
          this.$set(item, 'showEmailContent', false)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        .order-content{
            border: 1px solid #e8ebed;
            font-size: 16px;
                .head{
                    display: flex;
                    justify-content: space-between;
                    height: 40px;
                    padding: 0 24px;
                    line-height: 40px;
                    background-color: #f4f5fc;
                    .time{
                        margin-left: 16px;
                        font-size: 12px;
                        color: #999999;
                    }
                    .status{
                        font-weight: 700;
                    }
                }
                .product-content{
                    padding: 0 40px;
                    .list{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 8px;
                        &:not(:last-child){
                            padding-bottom: 8px;
                            border-bottom: 1px solid #e8ebed;
                        }
                        .product{
                            display: flex;
                            .desc{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                width: 460px;
                                margin-left: 24px;
                                 .name{
                                    font-weight: 700;
                                }
                                .sku-content{
                                    margin-top: 16px;
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                                .norm-content{
                                  font-size: 14px;
                                  line-height: 24px;
                                }
                            }
                        }
                        img{
                            width: 256px;
                            height: 256px;
                            object-fit: cover;
                        }
                        .price-content{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding-right: 120px;
                            .price{
                                color: #C40A0A;
                            }
                            .num{
                                margin-top: 2px;
                                color: #999999;
                            }
                        }
                    }
                    .total-content{
                       padding: 10px 24px;
                       margin-top: 8px;
                       margin-bottom: 24px;
                       text-align: right;
                       background-color: #f4f5fc;
                       line-height: 30px;
                       .name,.value{
                           display: inline-block;
                       }
                       .value{
                           width: 170px;
                       }
                       .price{
                           font-weight: 700;
                           color: #C40A0A;
                       }
                    }
                }
        }
        .address-content{
            padding: 0 40px;
            margin-top: 8px;
            border: 1px solid #e8ebed;
            font-size: 16px;
            .item{
                display: flex;
                padding: 24px 0;
                &:not(:last-child){
                    border-bottom: 1px solid #e8ebed;
                }
                .name{
                    display: flex;
                    align-items: center;
                    width: 168px;
                }
                .value{
                    font-weight: 700;
                    line-height: 24px;
                }
            }
            .between{
                justify-content: space-between;
                a{
                    color: #666;
                    text-decoration: underline;
                }
            }
        }
        .email-content{
          padding: 24px 40px;
          margin-top: 8px;
          border: 1px solid #e8ebed;
          .title{
            display: flex;
            justify-content: space-between;
            margin-bottom: 28px;
            .ja{
              font-size: 20px;
              font-weight: 700;
            }
            .en{
              font-size: 14px;
            }
            .text{
              font-size: 14px;
              color: #999999;
            }
          }
          .list-content{
            .list{
              padding: 12px 16px;
              background: #f4f5fc;
              border-radius: 4px;
              cursor: pointer;
              &:not(:last-child){
                margin-bottom: 8px;
              }
              .mail-subject{
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                img{
                  margin-right: 6px;
                  vertical-align: middle;
                }
                .time{
                  display: flex;
                  align-items: center;
                  font-size: 12px;
                  color: #999999;
                }
              }
              .content{
                padding: 24px 40px;
                margin-top: 12px;
                background-color: #ffffff;
              }
            }
          }
        }
        .action-content{
            display: flex;
            justify-content: space-between;
            // width: 304px;
            width: 142px;
            margin: 52px auto 0;
            font-size: 14px;
            .button{
                width: 142px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                box-sizing: border-box;
                border: 1px solid #333;
                cursor: pointer;
            }
            .dark{
                margin-right: 20px;
                background-color: #333;
                color: #f4f5fc;
            }
        }
    }
}
</style>
