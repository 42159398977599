<template>
  <div>
    <div class="popularity-title">おすすめの商品</div>
    <div class="swiper popularity-swiper">
      <div class="swiper-wrapper">
        <router-link v-for="item in initData" :key="item.id" :to="'/product/'+ item.product_sn" class="swiper-slide">
          <img :src="item.image" :alt="item.image_alt">
          <div class="discount">
            <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="value">{{ item.promotion[0].discount }}%OFF</div>
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.long_title }}</div>
          <div class="price">
            <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
              <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
              <span class="original">¥{{ calcThousands(item.price) }}</span>
            </template>
            <template v-else>
              ¥{{ calcThousands(item.price) }}
            </template>
          </div>
        </router-link>
      </div>
      <div class="swiper-pagination" />
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" />
    </div>
  </div>
</template>
<script>
import { productList } from '@/api/product'
import { calcThousands, parameterCorrection } from '@/utils/validate'
export default {
  data() {
    return {
      initData: []
    }
  },
  mounted() {
    this.productList()
  },
  methods: {
    calcThousands,
    // 轮播
    swiper() {
      new window.Swiper('.popularity-swiper', {
        slidesPerView: 'auto',
        spaceBetween: 20,
        pagination: {
          type: 'progressbar',
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    },
    productList() {
      const data = parameterCorrection({ is_on_sale: true, is_recommend: true })
      productList({ ...data, type: 'all', ...{ include: 'promotion' }}).then((result) => {
        this.initData = result
        this.initData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
        this.$nextTick(() => {
          this.swiper()
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.popularity-title{
  width: 1440px;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 2px solid #333;
  margin: 80px auto 0;
}
.popularity-swiper ::v-deep{
  width: 1440px;
  padding: 0 0 80px;
  margin: 32px auto 80px;
  .swiper-slide{
    width: 345px;
  }
  img{
    width: 100%;
    height: 345px;
  }
  .discount{
      width: 72px;
      height: 20px;
      margin-top: 8px;
      line-height: 20px;
      text-align: center;
      .value{
        color: #ffffff;
        background-color: #c40a0a;
      }
    }
  .title{
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 8px 0 0;
  }
  .desc{
    display: -webkit-box;
    font-size: 12px;
    color: #999;
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    margin: 12px 0 0 0;
  }
  .price{
      margin-top: 8px;
      .now{
        font-size: 16px;
        font-weight: bold;
        color: #c40a0a;
      }
      .original{
        margin-left: 8px;
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;
      }
    }
  .swiper-pagination{
    top: auto;
    bottom: 23px;
    width: 1288px;
    background: #E8EBED;
  }
  .swiper-pagination-progressbar-fill{
    background: #333;
  }
  .swiper-button-prev{
    top: auto;
    left: auto;
    right: 72px;
    bottom: 0;
    margin: 0;
  }
  .swiper-button-prev{
    top: auto;
    left: auto;
    right: 72px;
    bottom: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #E8EBED;
    background: url('~@/assets/images/pc/arrow-left.png') center center;
    margin: 0;
    &::after{
      content: '';
    }
  }
  .swiper-button-next{
    top: auto;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #E8EBED;
    background: url('~@/assets/images/pc/arrow-right.png') center center;
    margin: 0;
    &::after{
      content: '';
    }
  }
  .swiper-pagination-lock{
    display: block;
  }
  .swiper-button-lock{
    display: flex;
  }
}
</style>
