<template>
  <div>
    <div class="tips"><router-link to="/">家具TOP</router-link> / お問い合わせ</div>
    <div class="question-title">
      <div>お問い合わせ前に、よくあるご質問に該当がないかご確認ください。</div>
      <router-link to="/guides/question">よくあるご質問を確認する >></router-link>
    </div>
    <question-from />
  </div>
</template>
<script>
import questionFrom from '@/views/sp/question/from'
export default {
  components: { questionFrom }
}
</script>
<style lang="scss" scoped>
.tips{
  text-align: center;
  margin: 0.16rem 0 0.16rem;
}
.question-title{
  height: 2.74rem;
  font-size: 0.32rem;
  padding: 0.4rem 0.24rem 0;
  box-sizing: border-box;
  background: url('~@/assets/images/sp/question_bg.png') center center / 100% 100%;
  margin: 0.36rem 0 0.56rem;
  a{
    display: block;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.28rem;
    text-align: center;
    box-sizing: border-box;
    border: 0.02rem solid #333;
    background: #fff;
    margin-top: 0.35rem;
  }
}
</style>
