var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "headerContent", staticClass: "header-content" }, [
    _vm.config.h1
      ? _c("h1", { staticClass: "notice" }, [_vm._v(_vm._s(_vm.config.h1))])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.fixed,
            expression: "!fixed",
          },
        ],
        ref: "header",
        staticClass: "nav-box",
      },
      [
        _c("div", { staticClass: "nav-fixed" }, [
          _c("div", { staticClass: "nav" }, [
            _c("div", [
              _vm._v("家具通販・おしゃれなインテリア専門店（ウッドライフ）"),
            ]),
            _c(
              "div",
              { staticClass: "nav-img" },
              [
                _c("div", { staticClass: "input-content" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchValue,
                        expression: "searchValue",
                      },
                    ],
                    attrs: { type: "text", placeholder: "キーワードで検索" },
                    domProps: { value: _vm.searchValue },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSearch($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchValue = $event.target.value
                      },
                    },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pc/search_icon.png"),
                    },
                  }),
                ]),
                _c(
                  "router-link",
                  { attrs: { to: "/cart" } },
                  [
                    _c(
                      "el-badge",
                      {
                        attrs: {
                          value: _vm.cartNumber,
                          hidden: !_vm.cartNumber > 0,
                          max: 99,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/pc/cart.png"),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  { attrs: { to: "/personal/my_collection" } },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/images/pc/heart.png") },
                    }),
                  ]
                ),
                _c("router-link", { attrs: { to: "/personal/mine" } }, [
                  _c("img", {
                    attrs: { src: require("@/assets/images/pc/user.png") },
                  }),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/pc/logo.png") },
                }),
              ]),
              _c(
                "div",
                { staticClass: "category-nav" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "category-show",
                      on: {
                        mouseenter: function ($event) {
                          _vm.show = true
                        },
                        mouseleave: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("商品カテゴリ")]),
                      _c("img", {
                        staticClass: "category-arrow",
                        class: { active: _vm.show },
                        attrs: {
                          src: require("@/assets/images/pc/category_arrow.png"),
                        },
                      }),
                    ]
                  ),
                  _c("router-link", { attrs: { to: "/new/product" } }, [
                    _c("span", [_vm._v("新着商品")]),
                  ]),
                  _c("router-link", { attrs: { to: "/guides/category" } }, [
                    _c("span", [_vm._v("ご利用ガイド")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              staticClass: "category-reveal",
              on: {
                mouseenter: function ($event) {
                  _vm.show = true
                },
                mouseleave: function ($event) {
                  _vm.show = false
                },
              },
            },
            [
              _c("div", {
                staticClass: "category-mask",
                on: {
                  mouseenter: function ($event) {
                    _vm.show = false
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "category-box" },
                [
                  _vm._l(_vm.categoryData, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "category-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "parent",
                            attrs: { to: "/category/" + item.url },
                          },
                          [
                            _c("img", { attrs: { src: item.image } }),
                            _c("div", [
                              _c("div", { staticClass: "english" }, [
                                _vm._v(_vm._s(item.en_name)),
                              ]),
                              _c("div", { staticClass: "japanese" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "subset" },
                          _vm._l(item.children, function (it) {
                            return _c(
                              "router-link",
                              {
                                key: it.id,
                                attrs: { to: "/category/" + it.url },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/pc/header_arrow.png"),
                                    alt: "",
                                  },
                                }),
                                _c("span", [_vm._v(_vm._s(it.name))]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c("div", { staticClass: "category-item hidden" }),
                  _c("div", { staticClass: "category-item hidden" }),
                  _c("div", { staticClass: "category-item hidden" }),
                ],
                2
              ),
            ]
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.fixed,
            expression: "fixed",
          },
        ],
        staticClass: "simple-header",
      },
      [
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            { staticClass: "left-content" },
            [
              _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/pc/logo.png") },
                }),
              ]),
              _c(
                "div",
                { staticClass: "category-nav" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "category-show",
                      on: {
                        mouseenter: function ($event) {
                          _vm.show = true
                        },
                        mouseleave: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("商品カテゴリ")]),
                      _c("img", {
                        staticClass: "category-arrow",
                        class: { active: _vm.show },
                        attrs: {
                          src: require("@/assets/images/pc/category_arrow.png"),
                        },
                      }),
                    ]
                  ),
                  _c("router-link", { attrs: { to: "/new/product" } }, [
                    _c("span", [_vm._v("新着商品")]),
                  ]),
                  _c("router-link", { attrs: { to: "/guides/category" } }, [
                    _c("span", [_vm._v("ご利用ガイド")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "nav-img" },
            [
              _c("div", { staticClass: "input-content" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchValue,
                      expression: "searchValue",
                    },
                  ],
                  attrs: { type: "text", placeholder: "キーワードで検索" },
                  domProps: { value: _vm.searchValue },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleSearch($event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchValue = $event.target.value
                    },
                  },
                }),
                _c("img", {
                  attrs: { src: require("@/assets/images/pc/search_icon.png") },
                }),
              ]),
              _c(
                "router-link",
                { attrs: { to: "/cart" } },
                [
                  _c(
                    "el-badge",
                    {
                      attrs: {
                        value: _vm.cartNumber,
                        hidden: !_vm.cartNumber > 0,
                        max: 99,
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/images/pc/cart.png") },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c("router-link", { attrs: { to: "/personal/my_collection" } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/pc/heart.png") },
                }),
              ]),
              _c("router-link", { attrs: { to: "/personal/mine" } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/pc/user.png") },
                }),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "category-reveal",
            on: {
              mouseenter: function ($event) {
                _vm.show = true
              },
              mouseleave: function ($event) {
                _vm.show = false
              },
            },
          },
          [
            _c("div", {
              staticClass: "category-mask",
              on: {
                mouseenter: function ($event) {
                  _vm.show = false
                },
              },
            }),
            _c(
              "div",
              { staticClass: "category-box" },
              [
                _vm._l(_vm.categoryData, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "category-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "parent",
                          attrs: { to: "/category/" + item.url },
                        },
                        [
                          _c("img", { attrs: { src: item.image } }),
                          _c("div", [
                            _c("div", { staticClass: "english" }, [
                              _vm._v(_vm._s(item.en_name)),
                            ]),
                            _c("div", { staticClass: "japanese" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "subset" },
                        _vm._l(item.children, function (it) {
                          return _c(
                            "router-link",
                            {
                              key: it.id,
                              attrs: { to: "/category/" + it.url },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/pc/header_arrow.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(it.name))]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c("div", { staticClass: "category-item hidden" }),
                _c("div", { staticClass: "category-item hidden" }),
                _c("div", { staticClass: "category-item hidden" }),
              ],
              2
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }