var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
        }),
        _c("span", [_vm._v("ニュース")]),
      ],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "article-box" },
      [
        _c("common"),
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "article-list" },
              [
                _vm._l(_vm.initData, function (item) {
                  return _c(
                    "router-link",
                    {
                      key: item.id,
                      staticClass: "article-item",
                      attrs: { to: "/article/" + item.id },
                    },
                    [
                      _c("img", { attrs: { src: item.image } }),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(_vm._s(item.description)),
                      ]),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(_vm._s(item.created_at.substring(0, 10))),
                      ]),
                    ]
                  )
                }),
                _c("div", { staticClass: "article-item-seat" }),
              ],
              2
            ),
            _c("pagination-pc", {
              attrs: {
                hidden: _vm.total <= _vm.parameterInfo.limit,
                total: _vm.total,
                "auto-scroll": "",
                page: _vm.parameterInfo.page,
                limit: _vm.parameterInfo.limit,
                link: _vm.$route.fullPath,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.parameterInfo, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.parameterInfo, "limit", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article-title" }, [
      _c("div", [_vm._v("NEW")]),
      _c("div", [_vm._v("ニュース")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }