var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
        }),
        _c("router-link", { attrs: { to: "/article" } }, [_vm._v("ニュース")]),
        _c("img", {
          attrs: { src: require("@/assets/images/pc/breadcrumb_arrow.png") },
        }),
        _c("span", [_vm._v(_vm._s(_vm.initData.title))]),
      ],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "article-box" },
      [
        _c("common"),
        _c("div", { staticClass: "article-detail" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.initData.title)),
          ]),
          _c("div", { staticClass: "date" }, [
            _vm._v(_vm._s(_vm.initData.created_at.substring(0, 10))),
          ]),
          _c("div", {
            staticClass: "custom",
            domProps: { innerHTML: _vm._s(_vm.initData.pc_desc) },
          }),
          _c("div", { staticClass: "operation" }, [
            _c(
              "div",
              {
                staticClass: "return",
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/images/pc/leftArrow.png") },
                }),
                _c("span", [_vm._v("戻る")]),
              ]
            ),
            _c(
              "div",
              { staticClass: "share" },
              [
                _c("span", [_vm._v("SHARE ON SNS :")]),
                _c(
                  "router-link",
                  {
                    attrs: { to: "" },
                    nativeOn: {
                      mouseenter: function ($event) {
                        _vm.show = 1
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 1,
                          expression: "show !== 1",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/facebook.png"),
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 1,
                          expression: "show === 1",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/facebook_current.png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "" },
                    nativeOn: {
                      mouseenter: function ($event) {
                        _vm.show = 2
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 2,
                          expression: "show !== 2",
                        },
                      ],
                      attrs: { src: require("@/assets/images/pc/twitter.png") },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 2,
                          expression: "show === 2",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/twitter_current.png"),
                      },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "" },
                    nativeOn: {
                      mouseenter: function ($event) {
                        _vm.show = 3
                      },
                      mouseleave: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show !== 3,
                          expression: "show !== 3",
                        },
                      ],
                      attrs: { src: require("@/assets/images/pc/ins.png") },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show === 3,
                          expression: "show === 3",
                        },
                      ],
                      attrs: {
                        src: require("@/assets/images/pc/ins_current.png"),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm.initData.products.length > 0
            ? _c("div", { staticClass: "ttl" }, [_vm._v("関連商品")])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "goods" },
            _vm._l(_vm.initData.products, function (item) {
              return _c(
                "router-link",
                {
                  key: item.id,
                  staticClass: "goods-item",
                  attrs: { to: "/product/" + item.product_sn },
                },
                [
                  _c("img", {
                    attrs: { src: item.image, alt: item.image_alt },
                  }),
                  _c("div", { staticClass: "goods-title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("div", { staticClass: "desc" }, [
                    _vm._v(_vm._s(item.long_title)),
                  ]),
                  _c("div", { staticClass: "price" }, [
                    _vm._v("¥" + _vm._s(_vm.calcThousands(item.price))),
                  ]),
                ]
              )
            }),
            1
          ),
          _vm.initData.link_article.length > 0
            ? _c("div", { staticClass: "ttl" }, [_vm._v("関連記事")])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "article" },
            _vm._l(_vm.initData.link_article, function (item) {
              return _c(
                "router-link",
                {
                  key: item.id,
                  staticClass: "article-item",
                  attrs: { to: "/article/" + item.id },
                },
                [
                  _c("img", { attrs: { src: item.image } }),
                  _c("div", { staticClass: "article-item-box" }, [
                    _c("div", { staticClass: "article-box-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "article-box-desc" }, [
                      _vm._v(_vm._s(item.description)),
                    ]),
                    _c("div", { staticClass: "article-box-date" }, [
                      _vm._v(_vm._s(item.created_at.substring(0, 10))),
                    ]),
                  ]),
                ]
              )
            }),
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article-title" }, [
      _c("div", [_vm._v("NEW")]),
      _c("div", [_vm._v("ニュース")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }