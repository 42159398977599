<template>
  <div class="personal-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/personal/mine">マイページ</router-link> / お気に入り商品
    </div>
    <template v-if="initData.length > 0">
      <div class="title">お気に入り商品</div>
      <div class="product-content">
        <div v-for="item in initData" :key="item.id" class="list">
          <router-link :to="`/product/${item.product_sn}`">
            <img :src="item.image" :alt="item.image_alt">
          </router-link>
          <div class="desc">
            <div class="name">{{ item.title }}</div>
            <div class="text">{{ item.long_title }}</div>
            <div class="price">¥{{ calcThousands(item.price) }}円</div>
          </div>
          <div class="collection" @click="handleCollection(item)">
            <img v-if="item.is_collection" src="@/assets/images/sp/collect_in.png">
            <img v-else src="@/assets/images/sp/collect.png">
          </div>
        </div>
      </div>
    </template>
    <div v-else class="empty-collection">
      <img src="@/assets/images/sp/empty_collection.png">
      <div class="text">お気に入り商品はありません</div>
      <router-link to="/">
        <div class="button">トップページに戻る</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getMyCollectProduct } from '@/api/personal'
import { productCollect } from '@/api/product'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      initData: [],
      calcThousands
    }
  },
  computed: {
  },
  created() {
    this.getMyCollectProduct()
  },

  methods: {
    getMyCollectProduct() {
      getMyCollectProduct({ type: 'all' }).then(result => {
        this.initData = result
        this.initData.forEach(item => {
          this.$set(item, 'is_collection', true)
        })
      })
    },
    handleCollection(item) {
      productCollect(item.product_sn).then(result => {
        if (result.code === 2001) {
          item.is_collection = true
        } else {
          item.is_collection = false
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content{
  padding: 0 .24rem;
  flex: 1;
  .bread-crumbs{
      margin-top: .16rem;
      text-align: center;
      font-size: .24rem;
  }
  .title{
      margin-top: .56rem;
      padding-bottom: .24rem;
      border-bottom: .02rem solid #333333;
      font-size: .36rem;
      font-weight: 700;
  }
  .product-content{
      .list{
          position: relative;
          display: flex;
          justify-content: space-between;
          padding-bottom: .5rem;
          margin-top: .4rem;
          border-bottom: .02rem solid #e8ebed;
          img{
              width: 2rem;
              height: 2rem;
              object-fit: cover;
          }
          .desc{
              width: 4.78rem;
              .name{
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
                overflow:hidden;
                font-weight: 700;
                line-height: .42rem;
              }
              .text{
                  margin-top: .16rem;
                  font-size: .24rem;
                  line-height: .36rem;
              }
              .price{
                  margin-top: .16rem;
              }
          }
          .collection{
              position: absolute;
              right: 0;
              bottom: .4rem;
              img{
                  width: .48rem;
                  height: .48rem;
              }
          }
      }
  }
  .empty-collection{
      margin-top: 1.46rem;
      text-align: center;
      img{
          width: 1.32rem;
          height: 1.32rem;
      }
      .text{
          margin-top: .32rem;
      }
      .button{
          width: 4.08rem;
          height: .8rem;
          margin: .8rem auto 0;
          line-height: .8rem;
          text-align: center;
          color: #ffffff;
          background-color: #333333;
      }
  }
}
</style>
