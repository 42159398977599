<template>
  <div class="personal-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/personal/mine">マイページ</router-link> / 購入履歴
    </div>
    <template v-if="initData.length > 0">
      <div class="title">購入履歴</div>
      <div class="order-content">
        <div v-for="(item,index) in initData" :key="index" class="content" :class="{'detail-show':item.detail_show}">
          <div class="head" @click="item.detail_show = !item.detail_show">
            <div>
              <span>ご注文番号：{{ item.order_sn }}</span>
              <span class="time">{{ (item.created_at).substring(0,item.created_at.length-3) }}</span>
            </div>
            <img src="@/assets/images/sp/right_arrow1.png" class="arrow">
          </div>
          <div class="detail-content">
            <div class="box">
              <span class="payment">
                お支払方法：{{ item.pay_type === 2 ? '銀行振込・前払い' : item.pay_type === 3 ? 'クレジットカード決済' : item.pay_type === 4 ? 'amazon pay': '' }}
              </span>
              <span class="status" :class="item.status_to_user === 1 ? 'blue' : item.status_to_user === 2 ? 'green' : item.status_to_user === 4 ? 'gray' : ''">
                {{ item.store_status && item.store_status.name }}
              </span>
            </div>
            <div class="product-lists">
              <div v-for="item2 in item.order_products" :key="item2.id" class="item">
                <router-link :to="`/product/${item2.product_sn}`">
                  <img :src="item2.product_img">
                </router-link>
                <div class="desc">
                  <div class="name">{{ item2.product_title }}</div>
                  <div class="sku-content">
                    <div v-for="(skuItem,index2) in item2.product_sku_display" :key="index2" class="list">{{ skuItem }}</div>
                  </div>
                  <div class="norm-content">
                    <div v-for="(normItem,index3) in item2.product_spu_display" :key="index3" class="list">{{ normItem }}</div>
                  </div>
                  <div class="price">
                    <span>¥{{ calcThousands(item2.pay_price) }}円</span>
                    <span class="num">×{{ item2.product_quantity }}</span>
                  </div>
                </div>
              </div>
              <div class="action-content">
                <router-link :to="`/personal/order/detail/${item.id}`" class="button detail">詳細を確認する</router-link>
                <router-link v-if="item.is_comment === 0 && item.status_to_user === 3" :to="`/personal/order/add_comments/${item.id}`" class="button dark">レビュー</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="order-empty">
      <img src="@/assets/images/sp/order_empty.png">
      <div class="text">ご購入の商品はまた登録されていません。</div>
      <router-link to="/">
        <div class="button">トップページに戻る</div>
      </router-link>
    </div>
  </div>
</template>

<script>

import { getMyOrderList } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      initData: [],
      calcThousands
    }
  },
  computed: {
  },
  created() {
    this.getMyOrderList()
  },
  methods: {
    getMyOrderList() {
      getMyOrderList({ type: 'all', include: 'orderProducts,storeStatus' }).then(result => {
        this.initData = result
        this.initData.forEach((item, index) => {
          let num = 0
          item.order_products.forEach(item2 => {
            num += item2.product_quantity
            this.$set(item, 'total_quantity', num)
          })
          this.$set(item, 'detail_show', index === 0)
        })
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content{
  padding: 0 .24rem;
  flex: 1;
  .bread-crumbs{
      margin-top: .16rem;
      text-align: center;
      font-size: .24rem;
  }
  .title{
      padding-bottom: .24rem;
      margin-top: .56rem;
      font-size: .36rem;
      font-weight: 700;
      border-bottom: .02rem solid #333333;
  }
  .order-content{
      .content{
          margin-top: .42rem;
          border: .02rem solid #e8ebed;
          .head{
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: .84rem;
              padding: 0 .24rem;
              line-height: .84rem;
              background: #f4f5fc;
              font-size: .24rem;
              .time{
                  margin-left: .32rem;
                  color: #999999;
              }
              .arrow{
                  width: .24rem;
                  height: .24rem;
              }
          }
          .detail-content{
              display: none;
              .box{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: .24rem;
                  border-bottom: .02rem solid #e8ebed;
                  .status{
                      font-weight: 700;
                  }
                  .blue{
                      color: #57a3e6;
                  }
                  .green{
                      color: #4bc4b0;
                  }
                  .gray{
                      color: #999999;
                  }
              }
              .product-lists{
                  padding: 0 .24rem;
                  .item{
                      display: flex;
                      justify-content: space-between;
                      padding: .32rem 0;
                      border-bottom: .02rem solid #e8ebed;
                      img{
                          width: 2rem;
                          height: 2rem;
                          object-fit: cover;
                      }
                      .desc{
                          width: 4.3rem;
                          .name{
                            display:-webkit-box;
                            -webkit-box-orient:vertical;
                            -webkit-line-clamp:2;
                            overflow:hidden;
                            margin-bottom: .24rem;
                            font-weight: 700;
                            line-height: .42rem;
                        }
                         .sku-content,.norm-content{
                            line-height: .36rem;
                            word-break: break-all;
                            font-size: .24rem;
                        }
                        .price{
                            margin-top: .24rem;
                            color: #c40a0a;
                        }
                        .num{
                            margin-left: .06rem;
                            color: #999999;
                        }
                      }
                  }
                  .action-content{
                          display: flex;
                          justify-content: flex-end;
                          margin: .24rem 0;
                          .button{
                            height: .62rem;
                            padding: 0 .16rem;
                            margin-left: .08rem;
                            line-height: .62rem;
                          }
                          .detail{
                              color: #999999;
                              border: .02rem solid #999999;
                          }
                          .dark{
                              color: #ffffff;
                              background: #333333;
                              border: .02rem solid #333333;
                          }

                      }
              }
          }
      }
        .detail-show{
            .arrow{
                transform: rotate(90deg);
            }
            .detail-content{
                display: block;
            }
        }
  }
  .order-empty{
      margin-top: 1.46rem;
      text-align: center;
      img{
          width: 1.32rem;
          height: 1.32rem;
      }
      .text{
          margin-top: .32rem;
      }
      .button{
          width: 4.08rem;
          height: .8rem;
          margin: .8rem auto;
          line-height: .8rem;
          text-align: center;
          background: #333333;
          color: #ffffff;
      }
  }
}
</style>
