var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guides-content" },
    [
      _c(
        "div",
        { staticClass: "bread-crumbs" },
        [
          _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
          _vm._v(" / "),
          _c("router-link", { attrs: { to: "/guides/category" } }, [
            _vm._v("ご利用ガイド"),
          ]),
          _vm._v(" / 搬入・設置について "),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
      _c("router-link", { attrs: { to: "/guides/question" } }, [
        _c("div", { staticClass: "button" }, [_vm._v("他のご質問はこちら")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-content" }, [
      _vm._v(" 搬入・設置について "),
      _c("img", {
        attrs: { src: require("@/assets/images/sp/guides_install.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lists-content" }, [
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("01 搬入について")]),
        _c("div", { staticClass: "desc red" }, [
          _vm._v(
            " 配送は玄関先までとなります。設置・組立はお客様作業にてお願いしております。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ※エレベーターの無い場合、階段上げ手数料が発生いたします。 "),
        ]),
        _c("br"),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 玄関以外への移動が必要な場合、お客様ご自身にてご対応のほどよろしくお願いいたします。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " お客様組み立て商品になりますが、組立設置サービスを付けられる商品がございます。詳細は各商品ページをご参照ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 組み立て商品は、配送後に長く放置をしておきますと、部材が変形する可能性がございます（不具合ではございません）。なるべくお早めに組み立てをいただけますようお願いいたします。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ご注文の前には、商品梱包が玄関・階段の幅・踊り場・部屋の間口等を通るかどうかを予めご確認くださいますようお願いたします。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 設置・組立てご希望の場合でも、家具の大きさ等により、搬入できない場合がございますので、搬入経路のご確認をお願い致します。 "
          ),
        ]),
        _c("br"),
        _c("div", { staticClass: "desc red" }, [
          _vm._v(
            " ※搬入経路が狭かったため、搬入出来なかった場合も、ご注文キャンセル・返品などはお受けできませんので、予めご了承くださいませ。 "
          ),
        ]),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("About carry-in")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("02 経路確認")]),
        _c("div", { staticClass: "bg-title" }, [_vm._v("1. 商品のサイズ")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 梱包時の寸法または製品の横幅（W）、高さ（H）、奥行（D）をご確認ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" 傾けたりすることができる製品かも合わせて確認してください。"),
          _c("br"),
          _c("br"),
          _vm._v(
            " 搬入可否については天地左右＋10㎝以上の余裕を持って判断することをおすすめします。 "
          ),
        ]),
        _c("img", {
          staticClass: "img",
          attrs: { src: require("@/assets/images/sp/guides_install_img1.png") },
        }),
        _c("div", { staticClass: "bg-title" }, [
          _vm._v("2. エレベーターのサイズ"),
        ]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(" エレベーターがある場合は十分にサイズをご確認ください。"),
          _c("br"),
          _vm._v(
            " エレベーター入口と内部の有効寸法 傾けることができる商品の場合、横向きに搬入してエレベーター内で縦置きにすることも可能です。"
          ),
          _c("br"),
          _vm._v(
            " エレベーターから降ろすときに切り返しが発生する場合は後述している「4.通路/廊下/扉」の項目と併せて確認ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ※幅は0.8M以下，高さは2.1M以下は大形家具が搬入困難です。 "),
        ]),
        _c("img", {
          staticClass: "img",
          attrs: { src: require("@/assets/images/sp/guides_install_img2.png") },
        }),
        _c("div", { staticClass: "bg-title" }, [_vm._v("3. 階段")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 天井の高さが足りず、搬入ができないことがあります。特に踊り場では商品を立てられる高さが必要です。螺旋や直角に曲がる階段では特にご注意ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" 1.階段の寸法と手すりや照明などの障害物がないか。"),
          _c("br"),
          _c("br"),
          _vm._v(" 2.切り返しのある階段の場合、踊り場の寸法が重要になります。"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ※螺旋階段や踊り場で家具を立ち上げて折り返す場合がありますので、踊り場の天井の高さは梱包サイズの1番長い部分より必要です。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" ※階段幅は1M以下は大形家具が搬入困難です。 "),
        ]),
        _c("img", {
          staticClass: "img",
          staticStyle: { "margin-top": ".24rem" },
          attrs: { src: require("@/assets/images/sp/guides_install_img3.png") },
        }),
        _c("div", { staticClass: "bg-title" }, [_vm._v("4. 通路/廊下/扉")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(" 通路や廊下の有効幅が特に重要な箇所になります。"),
          _c("br"),
          _c("br"),
          _vm._v(
            " コーナー(角)がある場合はその対角幅もポイントの一つです。コーナーを曲がるには商品を斜めに切り返さなければなりません。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " またコーナーの切り返しが厳しい場所でも十分な天井高があれば搬入できる場合があります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 扉が90°以上開くか、また開けたとき取手から壁までの距離が十分あるかが重要です。通路は通っても扉の造りによっては搬入できない場合がございます。※玄関ドア幅は0.8M以下、通路幅は1M以下は大形家具が搬入困難です。 "
          ),
        ]),
        _c("img", {
          staticStyle: { width: "100%", "margin-top": ".24rem" },
          attrs: { src: require("@/assets/images/sp/guides_install_img4.png") },
        }),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("Route confirmation")]),
        ]),
      ]),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "title" }, [_vm._v("03 組立・設置について")]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            " 原則は玄関先でのお渡しになりますが、すぐにお使いたいお客様に1点からお申し込みできるサービスです。"
          ),
          _c("br"),
          _vm._v(" （他商品との混載輸送になります。）"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ご自宅まで2名スタッフで伺い、テーブル・ベッド・ソファなど一部の家具は有料にて開梱・設置・廃材（梱包材）回収まで行います。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 対象の商品は、「組立品」アイコンがついている商品となります。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ご希望の方は、ご注文の際に各商品ページにてオプション項目の「組立を依頼する」にご選択ください、商品をカートへ入れてご注文お手続きをお願いいたします。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 場合によってご注文後の組立追加はお受けできないので、あらかじめご了承ください。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " お住まい、お届けの地域によっては、荷受けができない場合もございます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " 沖縄県や、離島へのお荷物の配送はできませんのでご了承ください。お申し込みから配送までにかかる日数は、お申し込みの際にご確認いただけます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(" 【組立サービスの料金】 ➡️ "),
          _c("span", { staticStyle: { "margin-left": ".1rem" } }, [
            _vm._v("1点"),
          ]),
          _vm._v(" "),
          _c("span", { staticStyle: { "margin-left": ".1rem" } }, [
            _vm._v("10,000円（税込）"),
          ]),
        ]),
        _c("div", { staticClass: "warn-content" }, [
          _c("p", { staticClass: "bold" }, [
            _c("span", { staticClass: "yellow" }, [_vm._v("⚠️")]),
            _vm._v("【注意点】"),
          ]),
          _c("br"),
          _vm._v(" ※商品1点あたりの組立料金です。"),
          _c("br"),
          _c("br"),
          _vm._v(
            " ※商品によって組立にかかる時間によって料金が変わる場合、改めてご連絡させて頂きます。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            " ※配送業者はヤマト運輸となります。他の配送業者はお選びいただけません。 "
          ),
        ]),
        _c("div", { staticClass: "s-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sp/guides_about_arrow.png"),
            },
          }),
          _c("span", [_vm._v("About installation")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }