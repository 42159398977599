<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / 搬入・設置について
    </div>
    <div class="title-content">
      搬入・設置について
      <img src="@/assets/images/sp/guides_install.png">
    </div>
    <div class="lists-content">
      <div class="list">
        <div class="title">01 搬入について</div>
        <div class="desc red">
          配送は玄関先までとなります。設置・組立はお客様作業にてお願いしております。<br><br>
          ※エレベーターの無い場合、階段上げ手数料が発生いたします。
        </div>
        <br>
        <div class="desc">
          玄関以外への移動が必要な場合、お客様ご自身にてご対応のほどよろしくお願いいたします。<br><br>
          お客様組み立て商品になりますが、組立設置サービスを付けられる商品がございます。詳細は各商品ページをご参照ください。<br><br>
          組み立て商品は、配送後に長く放置をしておきますと、部材が変形する可能性がございます（不具合ではございません）。なるべくお早めに組み立てをいただけますようお願いいたします。<br><br>
          ご注文の前には、商品梱包が玄関・階段の幅・踊り場・部屋の間口等を通るかどうかを予めご確認くださいますようお願いたします。<br><br>
          設置・組立てご希望の場合でも、家具の大きさ等により、搬入できない場合がございますので、搬入経路のご確認をお願い致します。
        </div>
        <br>
        <div class="desc red">
          ※搬入経路が狭かったため、搬入出来なかった場合も、ご注文キャンセル・返品などはお受けできませんので、予めご了承くださいませ。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About carry-in</span>
        </div>
      </div>
      <div class="list">
        <div class="title">02 経路確認</div>
        <div class="bg-title">1. 商品のサイズ</div>
        <div class="desc">
          梱包時の寸法または製品の横幅（W）、高さ（H）、奥行（D）をご確認ください。<br><br>
          傾けたりすることができる製品かも合わせて確認してください。<br><br>
          搬入可否については天地左右＋10㎝以上の余裕を持って判断することをおすすめします。
        </div>
        <img src="@/assets/images/sp/guides_install_img1.png" class="img">
        <div class="bg-title">2. エレベーターのサイズ</div>
        <div class="desc">
          エレベーターがある場合は十分にサイズをご確認ください。<br>
          エレベーター入口と内部の有効寸法 傾けることができる商品の場合、横向きに搬入してエレベーター内で縦置きにすることも可能です。<br>
          エレベーターから降ろすときに切り返しが発生する場合は後述している「4.通路/廊下/扉」の項目と併せて確認ください。<br><br>
          ※幅は0.8M以下，高さは2.1M以下は大形家具が搬入困難です。
        </div>
        <img src="@/assets/images/sp/guides_install_img2.png" class="img">
        <div class="bg-title">3. 階段</div>
        <div class="desc">
          天井の高さが足りず、搬入ができないことがあります。特に踊り場では商品を立てられる高さが必要です。螺旋や直角に曲がる階段では特にご注意ください。<br><br>
          1.階段の寸法と手すりや照明などの障害物がないか。<br><br>
          2.切り返しのある階段の場合、踊り場の寸法が重要になります。<br><br>
          ※螺旋階段や踊り場で家具を立ち上げて折り返す場合がありますので、踊り場の天井の高さは梱包サイズの1番長い部分より必要です。<br><br>
          ※階段幅は1M以下は大形家具が搬入困難です。
        </div>
        <img src="@/assets/images/sp/guides_install_img3.png" class="img" style="margin-top: .24rem;">
        <div class="bg-title">4. 通路/廊下/扉</div>
        <div class="desc">
          通路や廊下の有効幅が特に重要な箇所になります。<br><br>
          コーナー(角)がある場合はその対角幅もポイントの一つです。コーナーを曲がるには商品を斜めに切り返さなければなりません。<br><br>
          またコーナーの切り返しが厳しい場所でも十分な天井高があれば搬入できる場合があります。<br><br>
          扉が90°以上開くか、また開けたとき取手から壁までの距離が十分あるかが重要です。通路は通っても扉の造りによっては搬入できない場合がございます。※玄関ドア幅は0.8M以下、通路幅は1M以下は大形家具が搬入困難です。
        </div>
        <img src="@/assets/images/sp/guides_install_img4.png" style="width:100%;margin-top: .24rem;">
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>Route confirmation</span>
        </div>
      </div>

      <div class="list">
        <div class="title">03 組立・設置について</div>
        <div class="desc">
          原則は玄関先でのお渡しになりますが、すぐにお使いたいお客様に1点からお申し込みできるサービスです。<br>
          （他商品との混載輸送になります。）<br><br>
          ご自宅まで2名スタッフで伺い、テーブル・ベッド・ソファなど一部の家具は有料にて開梱・設置・廃材（梱包材）回収まで行います。<br><br>
          対象の商品は、「組立品」アイコンがついている商品となります。<br><br>
          ご希望の方は、ご注文の際に各商品ページにてオプション項目の「組立を依頼する」にご選択ください、商品をカートへ入れてご注文お手続きをお願いいたします。<br><br>
          場合によってご注文後の組立追加はお受けできないので、あらかじめご了承ください。<br><br>
          お住まい、お届けの地域によっては、荷受けができない場合もございます。<br><br>
          沖縄県や、離島へのお荷物の配送はできませんのでご了承ください。お申し込みから配送までにかかる日数は、お申し込みの際にご確認いただけます。<br><br>
          【組立サービスの料金】 ➡️ <span style="margin-left:.1rem;">1点</span> <span style="margin-left:.1rem;">10,000円（税込）</span>
        </div>
        <div class="warn-content">
          <p class="bold"><span class="yellow">⚠️</span>【注意点】</p><br>
          ※商品1点あたりの組立料金です。<br><br>
          ※商品によって組立にかかる時間によって料金が変わる場合、改めてご連絡させて頂きます。<br><br>
          ※配送業者はヤマト運輸となります。他の配送業者はお選びいただけません。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About installation</span>
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">他のご質問はこちら</div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .title-content{
        position: relative;
        width: 100%;
        height: .88rem;
        padding: 0 .24rem;
        margin-top: .36rem;
        line-height: .88rem;
        font-size: .32rem;
        font-weight: 700;
        background: linear-gradient(90deg,#f4f5fc 1%, #ffffff 100%);
        box-sizing: border-box;
        img{
            position: absolute;
            right: 0;
            top: -.3rem;
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    .red{
        color: #c40a0a;
    }
    .lists-content{
        padding: 0 .24rem;
        margin-top: .56rem;
        .list{
            padding-bottom: .4rem;
            border-bottom: .02rem solid #e8ebed;
            &:not(:last-child){
                margin-bottom: .4rem;
            }
            .title{
                margin-bottom: .32rem;
                font-size: .32rem;
                font-weight: 700;
                img{
                    width: 2.52rem;
                    height: .64rem;
                    margin-left: .2rem;
                    vertical-align: middle;
                }
            }
            .bg-title{
                width: 100%;
                padding: .28rem .24rem;
                margin: .4rem 0 .24rem;
                background: #f4f5fc;
                box-sizing: border-box;
                font-weight: 500;
            }
            .img{
                width: 100%;
                margin-top: .24rem;
            }
            .desc{
                line-height: .42rem;
            }
            .warn-content{
                margin-top: .32rem;
                line-height: .42rem;
                .bold{
                    font-weight: 700;
                }
                .yellow{
                    color: #ffcd00;
                }
            }
            .s-title{
                margin-top: .34rem;
                text-align: right;
                font-size: .24rem;
                color: #999999;
                img{
                    width: .16rem;
                    height: .2rem;
                    vertical-align: middle;
                }
                span{
                    margin-left: .08rem;
                    vertical-align: middle;
                }
            }
        }
    }
    .button{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
