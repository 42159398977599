<template>
  <div class="password-content">
    <div class="bg">
      <div class="breadcrumb">
        <router-link to="/">家具通販専門店TOP</router-link>
        <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
        <span>パスワードをお忘れの方</span>
      </div>
    </div>
    <div class="right-form">
      <div class="title">パスワードをお忘れの方</div>
      <div class="text">パスワード再設定用URLをお送りいたします。<br>ご登録のメールアドレスをご入力ください。</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item prop="email" label="">
          <el-input v-model.trim="ruleForm.email" placeholder="メールアドレス（会員ID）※" />
        </el-form-item>
        <el-form-item prop="" label="">
          <div class="button" @click="handleRegister">
            <span>送信する</span>
            <div class="arrow"><img src="@/assets/images/pc/button_arrow.png" alt=""></div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { sendForgetMail } from '@/api/user'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスを入力してください', trigger: ['blur'] }
        ]
      }
    }
  },
  methods: {
    handleRegister() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          sendForgetMail(this.ruleForm).then(() => {
            this.$router.push({ path: '/password/auth', query: { email: this.ruleForm.email }})
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.password-content{
    display: flex;
    .bg{
        width: 1076px;
        height: 760px;
        background: url('../../../assets/images/pc/login_bg.png') 100%;
        .breadcrumb{
            margin-top: 28px;
            margin-left: 240px;
        }
    }
    .right-form{
        margin-left: 18px;
        .title{
          margin-top: 80px;
          font-size: 28px;
          font-weight: 700;
        }
        .text{
            margin-top: 64px;
            font-size: 16px;
            line-height: 24px;
        }
        .el-form{
          margin-top: 16px;
        }
        .el-input{
          width: 400px;
        }
        .button{
          display: flex;
          justify-content: space-between;
          width: 400px;
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          background: #333;
          color: #fff;
          font-size: 20px;
          box-sizing: border-box;
          cursor: pointer;
          img{
            width: 32px;
            height: 9px;
          }
          .arrow{
            display: flex;
            align-items: center;
          }
        }
    }
}
</style>
