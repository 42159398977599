var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-content" }, [
    _vm._m(0),
    _c("div", { staticClass: "nav-content" }, [
      _c(
        "div",
        { staticClass: "item current" },
        [
          _c("router-link", { attrs: { to: "/company" } }, [
            _vm._v("企業情報"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("router-link", { attrs: { to: "/tokutei" } }, [
            _vm._v("特定商取引法に基づく表示"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("router-link", { attrs: { to: "/privacy" } }, [
            _vm._v("プライバシーポリシー"),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "table" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/pc/table_right_top.png") },
      }),
      _c("div", { staticClass: "list-content" }, [
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _vm._m(5),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "name" }, [_vm._v("お問い合わせ")]),
          _c(
            "div",
            { staticClass: "value" },
            [
              _c("router-link", { attrs: { to: "/question" } }, [
                _vm._v("お問い合わせはこちら ＞＞"),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "name" }, [_vm._v("公式通販サイト")]),
          _c(
            "div",
            { staticClass: "value" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _vm._v("https://www.woodlife.jp/"),
              ]),
            ],
            1
          ),
        ]),
        _vm._m(6),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg" }, [
      _c("div", { staticClass: "title" }, [_vm._v("WOODLIFEについて")]),
      _c("div", { staticClass: "text" }, [
        _vm._v("家具通販・おしゃれなインテリア専門店（ウッドライフ）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("社名")]),
      _c("div", { staticClass: "value" }, [
        _vm._v("はんこプレミアム株式会社 （Hanko Premium Co.,Ltd.）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("設立")]),
      _c("div", { staticClass: "value" }, [_vm._v("2011年9月")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("住所")]),
      _c("div", { staticClass: "value" }, [
        _vm._v(" 〒274-0065"),
        _c("br"),
        _vm._v("千葉県船橋市高根台7丁目26-12 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("電話番号")]),
      _c("div", { staticClass: "value" }, [
        _vm._v("047-489-5595 (営業電話などはご遠慮ください。)"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [_vm._v("電話受付時間")]),
      _c("div", { staticClass: "value" }, [_vm._v("平日 9:30〜18:30")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "name" }, [
        _vm._v("古物営業法の"),
        _c("br"),
        _vm._v("規定に基づく表記"),
      ]),
      _c("div", { staticClass: "value" }, [
        _vm._v("千葉県公安委員会"),
        _c("br"),
        _vm._v("第441360001654号"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }