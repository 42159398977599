import router from '.'
import store from '../store'
const interceptList = ['/login', '/register'] // 路由拦截名单
router.beforeEach(async(to, from, next) => {
  if (store.getters.token && interceptList.indexOf(to.path) !== -1) {
    next('/')
  } else if (!store.getters.token && to.path.match(/personal/)) { // 个人中心 登录拦截
    next('/login')
  } else {
    next()
  }
})
