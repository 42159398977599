import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=25d5faee&scoped=true"
import script from "./confirm.vue?vue&type=script&lang=js"
export * from "./confirm.vue?vue&type=script&lang=js"
import style0 from "./confirm.vue?vue&type=style&index=0&id=25d5faee&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d5faee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/test.woodlife.jp/releases/54/vue-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25d5faee')) {
      api.createRecord('25d5faee', component.options)
    } else {
      api.reload('25d5faee', component.options)
    }
    module.hot.accept("./confirm.vue?vue&type=template&id=25d5faee&scoped=true", function () {
      api.rerender('25d5faee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pc/question/confirm.vue"
export default component.exports