var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v("家具通販専門店TOP"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/pc/breadcrumb_arrow.png"),
            alt: "",
          },
        }),
        _c("router-link", { attrs: { to: "/personal/order" } }, [
          _vm._v("注文履歴"),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/images/pc/breadcrumb_arrow.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("注文詳細")]),
      ],
      1
    ),
    _c("div", { staticClass: "personal-content" }, [
      _c(
        "div",
        { staticClass: "left-content" },
        [_c("SideBar", { attrs: { path: "/personal/order" } })],
        1
      ),
      _c("div", { staticClass: "right-content" }, [
        _c("div", { staticClass: "order-content" }, [
          _c("div", { staticClass: "head" }, [
            _c("div", [
              _c("span", [
                _vm._v("ご注文番号：" + _vm._s(_vm.initData.order_sn)),
              ]),
              _c("span", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.initData.created_at)),
              ]),
            ]),
            _c("div", { staticClass: "status" }, [
              _vm._v(
                _vm._s(
                  _vm.initData.store_status && _vm.initData.store_status.name
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "product-content" }, [
            _c(
              "div",
              _vm._l(_vm.initData.order_products, function (item) {
                return _c("div", { key: item.id, staticClass: "list" }, [
                  _c(
                    "div",
                    { staticClass: "product" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/product/" + item.product_sn } },
                        [_c("img", { attrs: { src: item.product_img } })]
                      ),
                      _c("div", { staticClass: "desc" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.product_title)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "sku-content" },
                          _vm._l(
                            item.product_sku_display,
                            function (skuItem, index2) {
                              return _c(
                                "div",
                                { key: index2, staticClass: "item" },
                                [_vm._v(_vm._s(skuItem))]
                              )
                            }
                          ),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "norm-content" },
                          _vm._l(
                            item.product_spu_display,
                            function (normItem, index3) {
                              return _c(
                                "div",
                                { key: index3, staticClass: "item" },
                                [_vm._v(_vm._s(normItem))]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "price-content" }, [
                    _c("div", { staticClass: "price" }, [
                      _vm._v(
                        "¥" + _vm._s(_vm.calcThousands(item.pay_price)) + "円"
                      ),
                    ]),
                    _c("div", { staticClass: "num" }, [
                      _vm._v("個数：" + _vm._s(item.product_quantity) + " 件"),
                    ]),
                  ]),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "total-content" }, [
              _c("div", [
                _c("div", { staticClass: "name" }, [_vm._v("送料")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "+¥" +
                      _vm._s(_vm.calcThousands(_vm.initData.freight_amount))
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "name" }, [_vm._v("クーポン")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "-¥" + _vm._s(_vm.calcThousands(_vm.initData.use_point_fee))
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "name" }, [_vm._v("利用ポイント")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "-¥" + _vm._s(_vm.calcThousands(_vm.initData.coupon_amount))
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "name" }, [_vm._v("合计金额")]),
                _c("div", { staticClass: "value price" }, [
                  _vm._v(
                    "¥" + _vm._s(_vm.calcThousands(_vm.initData.pay_amount))
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "address-content" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("連絡先")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.initData.receiver_email)),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("配送先")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.initData.receiver_name) + " 様"),
              _c("br"),
              _vm._v(
                " 〒" +
                  _vm._s(_vm.initData.zip_code) +
                  " " +
                  _vm._s(_vm.initData.stats) +
                  _vm._s(_vm.initData.city) +
                  _vm._s(_vm.initData.address) +
                  _vm._s(
                    _vm.initData.detail_address
                      ? _vm.initData.detail_address
                      : ""
                  )
              ),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.initData.receiver_phone) + " "),
            ]),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("備考欄")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.initData.note)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "item between" },
            [
              _c("div", { staticClass: "name" }, [_vm._v("投稿したレビュー")]),
              _vm.initData.is_comment === 1
                ? _c(
                    "router-link",
                    { attrs: { to: "/personal/order/my_comments/" + _vm.id } },
                    [_vm._v("レビューを見る")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "email-content" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "list-content" },
            _vm._l(_vm.initData.order_mail_record, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "list",
                  on: {
                    click: function ($event) {
                      item.showEmailContent = !item.showEmailContent
                    },
                  },
                },
                [
                  _c("div", { staticClass: "mail-subject" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/pc/email_icon.png"),
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(item.mail_subject))]),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.created_at)),
                    ]),
                  ]),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.showEmailContent,
                        expression: "item.showEmailContent",
                      },
                    ],
                    staticClass: "content",
                    domProps: { innerHTML: _vm._s(item.mail_content) },
                  }),
                ]
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "action-content" },
          [
            _c("router-link", { attrs: { to: "/question" } }, [
              _c("div", { staticClass: "button" }, [_vm._v("お問い合わせ")]),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "name" }, [_vm._v("配送方法")]),
      _c("div", { staticClass: "value" }, [_vm._v("通常出荷")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", [
        _c("span", { staticClass: "ja" }, [_vm._v("メール配信履歴一覧")]),
        _c("span", { staticClass: "en" }, [_vm._v("（Mail delivery list）")]),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("1ヶ月以内に送信されたメールを表示しています。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }