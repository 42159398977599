var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("認証メールを送信いたしました。"),
        ]),
        _c("div", { staticClass: "username" }, [
          _vm._v(_vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName) + " 様"),
        ]),
        _vm._m(0),
        _c("router-link", { attrs: { to: "/" } }, [
          _c("div", { staticClass: "button" }, [_vm._v("トップページ")]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" 会員登録ありがとうございます。"),
      _c("br"),
      _vm._v(" それではショッピングをお楽しみください。"),
      _c("br"),
      _c("br"),
      _vm._v(" 「 www.woodlife.com 」に認証メールを送信いたしました。"),
      _c("br"),
      _vm._v(
        " メールに記載されたURLをクリックして、会員登録を完了させてくださいませ。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(" ※認証用URLの有効期限は12時間です。"),
      _c("br"),
      _c("br"),
      _vm._v(
        " メールアドレスが受け取り可能なものにもかかわらずご確認メールが届かない場合、お問い合わせ窓口までご連絡くださいませ。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }