import request from '@/utils/request'
/**
 * 店铺配置api
 */

// 获取配置
export function getBasicConfig(params) {
  return request({
    url: '/shopConfig',
    method: 'get',
    params
  })
}
// 邮件订阅
export function emailSubscriptions(data) {
  return request({
    url: '/emailSubscriptions',
    method: 'post',
    data
  })
}
// 轮播
export function getSwipers(params) {
  return request({
    url: '/swipers',
    method: 'get',
    params
  })
}
// 问合
export function questions(data) {
  return request({
    url: '/questions',
    method: 'post',
    data
  })
}
// 关键词
export function keywords(params) {
  return request({
    url: '/keywords',
    method: 'get',
    params
  })
}
// 自定义内容
export function getCustomPage(url) {
  return request({
    url: '/customPages/' + url,
    method: 'get'
  })
}
