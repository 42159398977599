var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initData.length
    ? _c("div", { staticClass: "record-content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("閲覧履歴")]),
        _c("div", { staticClass: "swiper popularity-swiper" }, [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.initData, function (item) {
              return _c(
                "router-link",
                {
                  key: item.id,
                  staticClass: "swiper-slide",
                  attrs: { to: "/product/" + item.product_sn },
                },
                [
                  _c("img", {
                    attrs: { src: item.image, alt: item.image_alt },
                  }),
                  _c("div", { staticClass: "product-title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("div", { staticClass: "product-price" }, [
                    _vm._v("¥" + _vm._s(_vm.calcThousands(item.price)) + "円"),
                  ]),
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "swiper-pagination" }),
          _c("div", { staticClass: "swiper-button-prev" }),
          _c("div", { staticClass: "swiper-button-next" }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }