var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "password-content" }, [
    _c("div", { staticClass: "breadcrumb" }, [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v("家具通販専門店TOP"),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/pc/breadcrumb_arrow.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("パスワードをお忘れの方")]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("パスワードをお忘れの方")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(" パスワードを再設定しました "),
        ]),
        _c("router-link", { attrs: { to: "/" } }, [
          _c("div", { staticClass: "button" }, [_vm._v("トップページ")]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }