<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/personal/order">注文履歴</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link :to="`/personal/order/detail/${id}`">注文詳細</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>投稿したレビュー</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/order" />
      </div>
      <div class="right-content">
        <div class="product-content">
          <div v-for="item in initData" :key="item.id" class="item">
            <div class="product">
              <div class="img">
                <router-link :to="`/product/${item.product.product_sn}`">
                  <img :src="item.product.image" :alt="item.product.image_alt">
                </router-link>
              </div>
              <div class="desc">
                <div class="name">{{ item.product_name }}</div>
                <div class="sku">{{ item.product_attribute.join(';') }} {{ item.product_spu_display.join(';') }}</div>
                <div class="star">
                  <van-rate v-model="item.stars" :size="28" color="#fccf52" void-icon="star" void-color="#e3e4eb" readonly />
                  <span class="value">{{ item.stars }}</span>
                </div>
              </div>
            </div>
            <div class="detail">
              <p>{{ item.content }}</p>
              <div
                v-if=" item.images.length > 0"
                class="image-content"
              >
                <div
                  v-for="(value,key) in item.images"
                  :key="key"
                  class="list"
                  :class="{'current': imageIndex === key && currentIndex === index}"
                  @click="handleImagePreview(value,index,key)"
                >
                  <img :src="value">
                </div>
              </div>
              <div v-show="currentIndex === index" class="preview-image">
                <img :src="previewImageUrl" :class="imageIndex === 0 ? 'one': imageIndex === 1 ? 'two' : imageIndex === 2 ? 'three' : imageIndex === 3 ? 'four': ''">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMyOrderComments } from '@/api/personal'
import SideBar from './component/sideBar'
export default {
  components: { SideBar },
  data() {
    return {
      id: this.$route.params.id,
      initData: {},
      currentIndex: '',
      previewImageUrl: '',
      imageIndex: ''
    }
  },
  computed: {

  },
  created() {
    this.getMyOrderComments()
  },
  methods: {
    getMyOrderComments() {
      getMyOrderComments({ 'filter[order_id]': this.id, type: 'all', include: 'product' }).then(result => {
        this.initData = result
      })
    },
    handleImagePreview(url, index, key) {
      if (this.imageIndex === key) {
        this.currentIndex = ''
        this.imageIndex = ''
      } else {
        this.previewImageUrl = url
        this.currentIndex = index
        this.imageIndex = key
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    font-size: 16px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        .product-content{
            .item{
                padding: 24px 40px;
                border: 1px solid #e8ebed;
                &:not(:last-child){
                    margin-bottom: 22px;
                }
                .product{
                    display: flex;
                    .img{
                        width: 104px;
                        height: 104px;
                        box-sizing: border-box;
                        border: 1px solid #e8ebed;
                        img{
                          width: 102px;
                          height: 102px;
                          object-fit: cover;
                        }
                    }
                    .desc{
                        margin-left: 16px;
                        .name{
                            margin-top: 4px;
                            font-weight: 700;
                        }
                        .sku{
                            margin-top: 12px;
                            font-size: 14px;
                        }
                        .star{
                            margin-top: 16px;
                            .value{
                                margin-left: 8px;
                                font-size: 20px;
                                font-weight: 700;
                                color: #FCCF52;
                            }
                        }
                    }
                }
                .detail{
                    padding: 16px;
                    margin-top: 16px;
                    background-color: #f4f5fc;
                    border-radius: 4px;
                    line-height: 24px;
                    .image-content{
                      margin-top: 16px;
                      display: flex;
                      .list{
                        margin-right: 8px;
                        width: 160px;
                        height: 160px;
                        img{
                          width: 100%;
                          height: 100%;
                          cursor: zoom-in;
                        }
                      }
                      .current{
                        position: relative;
                        box-sizing: border-box;
                        border: 2px solid #333333;
                        &::after{
                          content: '';
                          position: absolute;
                          bottom: -6px;
                          left: 50%;
                          width: 0;
                          height: 0;
                          transform: translate(-50%, 0);
                          border-left: 4px solid transparent;
                          border-right: 4px solid transparent;
                          border-top: 4px solid #333333;
                        }
                        img{
                          cursor: zoom-out;
                        }
                      }
                    }
                    .preview-image{
                      margin-top: 4px;
                      img{
                        width: 312px;
                        height: 312px;
                      }
                      .two{
                        margin-left: 100px;
                      }
                      .three{
                        margin-left: 262px;
                      }
                      .four{
                        margin-left: 430px;
                      }
                    }
                }
            }
        }
        .button{
            width: 204px;
            height: 48px;
            margin: 52px auto 0;
            line-height: 48px;
            text-align: center;
            background-color: #333;
            color: #fff;
            cursor: pointer;
        }
    }
}
</style>
