<template>
  <div>
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>お気に入り商品</span>
    </div>
    <div class="personal-content">
      <div class="left-content">
        <SideBar path="/personal/coupon" />
      </div>
      <div class="right-content">
        <div class="title">
          <div>ご利用できるクーポン</div>
          <div>{{ canUseData.length }} 枚</div>
        </div>
        <div v-if="canUseData.length > 0" class="can-use-content">
          <div v-for="(item,index) in canUseData" :key="index" class="item">
            <div class="detail">
              <div class="image">
                <img :src="item.coupon.pc_image">
              </div>
              <div class="desc">
                <div>
                  <div class="name">{{ item.coupon.name }}</div>
                  <div class="limit">{{ item.coupon.remark }}</div>
                  <div class="time"><span>期間中</span>～{{ item.end_time.replace(/-/g, "/") }}</div>
                  <div class="day">有効期間終了まで、あと {{ item.rest_day }}日！</div>
                </div>
              </div>
            </div>
            <div class="button-content">
              <a :href="item.coupon.link ? `${item.coupon.link}` : '/'">
                <el-button type="primary">クーポンを使う</el-button>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="empty-content">
          <img src="@/assets/images/pc/my_coupon_empty.png">
          <div class="text">お気に入り商品はありません</div>
          <router-link to="/">
            <button class="button">トップページに戻る</button>
          </router-link>
        </div>
        <div v-if="usedData.length > 0" class="over-content">
          <div class="head">
            <div>使用不可のクーポン</div>
            <a class="show-detail" @click="handleUsedCopuponShow">詳細を見る</a>
          </div>
          <div v-show="usedCouponShow" class="item-content">
            <div v-for="(item,index) in usedData" :key="index" class="item">
              <div class="detail">
                <div class="image">
                  <img :src="item.coupon.pc_image">
                </div>
                <div class="desc">
                  <div>
                    <div class="name">{{ item.coupon.name }}</div>
                    <div class="limit">{{ item.coupon.remark }}</div>
                    <div class="time"><span>期間中</span>～{{ item.end_time.replace(/-/g, "/") }}</div>
                  </div>
                </div>
              </div>
              <img v-if="item.is_used" src="@/assets/images/pc/coupon_used.png" class="icon">
              <img v-else src="@/assets/images/pc/coupon_over.png" class="icon">
            </div>
          </div>
        </div>
        <div class="get-coupon-content">
          <div class="title">クーポンコード登録</div>
          <div class="text">お手持ちの「クーポンコード」を入力してください </div>
          <div class="input-content">
            <el-input v-model.trim="couponCode" placeholder="クーポンコード" />
            <el-button type="primary" :loading="btnLoading" @click="handleGetCoupon">登録する</el-button>
          </div>
        </div>
        <Record />
      </div>
    </div>
  </div>
</template>
<script>
import { getMyCouponList, getCodeCoupon } from '@/api/coupon'
import { calcThousands } from '@/utils/validate'
import SideBar from './component/sideBar'
import Record from './component/Record'
export default {
  components: { SideBar, Record },
  data() {
    return {
      btnLoading: false,
      usedCouponShow: true,
      canUseData: [],
      usedData: [],
      couponCode: '',
      calcThousands
    }
  },
  computed: {

  },
  created() {
    this.getCanUseCouponList()
    this.getUsedCouponList()
  },
  methods: {
    getCanUseCouponList() {
      getMyCouponList({ 'filter[is_valid]': true, include: 'coupon' }).then(result => {
        this.canUseData = result.data
        this.canUseData.forEach(item => {
          this.$set(item, 'rest_day', this.calculateDaysUntilDate(item.end_time))
        })
      })
    },
    getUsedCouponList() {
      getMyCouponList({ 'filter[is_valid]': false, include: 'coupon' }).then(result => {
        this.usedData = result.data
      })
    },
    handleUsedCopuponShow() {
      this.usedCouponShow = !this.usedCouponShow
    },
    handleGetCoupon() {
      if (this.couponCode) {
        this.btnLoading = true
        getCodeCoupon({ code: this.couponCode }).then(() => {
          this.btnLoading = false
          this.$toast('クーポンをGETしました！')
          this.getCanUseCouponList()
          this.getUsedCouponList()
        }).catch(() => {
          this.btnLoading = false
        })
      } else {
        this.$toast('クーポンコードを入力ください')
      }
    },
    calculateDaysUntilDate(targetDateStr) {
      const today = new Date()
      const targetDate = new Date(targetDateStr)
      const timeDiff = targetDate - today
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + 1
      return daysDiff
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb{
  width: 1440px;
  margin: 28px auto 0;
}
.personal-content{
    overflow: hidden;
    width: 1440px;
    margin: 42px auto 80px;
    .left-content{
        float:left;
    }
    .right-content{
        float: right;
        width: 1096px;
        .title{
            display: flex;
            justify-content: space-between;
            padding-bottom: 8px;
            margin-bottom: 16px;
            font-size: 20px;
            border-bottom: 2px solid #333;
        }
        .can-use-content{
          .item{
            display: flex;
            justify-content: space-between;
            padding: 16px 32px 16px 16px;
            border: 1px solid #e8ebed;
            &:not(:first-child){
              margin-top: 8px;
            }
            .detail{
              display: flex;
            }
            .image{
              display: flex;
              align-items: center;
            }
            img{
              width: 240px;
              height: 134px;
              margin-right: 16px
            }
            .desc{
              display: flex;
              align-items: center;
              width: 530px;
            }
            .name{
              font-weight: 700;
              font-size: 18px;
            }
            .limit{
              margin-top: 20px;
            }
            .time{
              margin-top: 6px;
              span{
                color: #C40A0A;
              }
            }
            .day{
              margin-top: 6px;
            }
          }
          .button-content{
            display: flex;
            align-items: center;
          }
          .el-button{
            height: 48px;
          }
        }
        .empty-content{
            margin-top: 72px;
            margin-bottom: 80px;
            text-align: center;
            font-size: 16px;
            .text{
            margin-top: 12px;
            }
            .button{
            width: 204px;
            height: 48px;
            margin: 32px 0 auto;
            line-height: 48px;
            text-align: center;
            background-color: #333;
            color: #fff;
            cursor: pointer;
            }
        }
        .over-content{
          margin-top: 40px;
          border: 1px solid #e8ebed;
          .head{
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 64px;
            padding: 0 40px 0 16px;
            line-height: 64px;
            background: #f7f7f7;
            box-sizing: border-box;
          }
          .item-content{
            .item{
            display: flex;
            justify-content: space-between;
            padding: 16px 8px 16px 16px;
            &:not(:last-child){
              border-bottom: 1px solid #e8ebed;
            }
            .detail{
              display: flex;
              opacity: .3;
            }
            .image{
              display: flex;
              align-items: center;
              img{
                width: 240px;
                height: 134px;
                margin-right: 16px
              }
            }
            .desc{
              display: flex;
              align-items: center;
              width: 530px;
            }
            .name{
              font-weight: 700;
              font-size: 18px;
            }
            .limit{
              margin-top: 20px;
            }
            .time{
              margin-top: 6px;
              span{
                color: #C40A0A;
              }
            }
            .day{
              margin-top: 6px;
            }
            .icon{
              width: 88px;
              height: 88px;
              margin-top: -14px;
            }
          }
          }
          .show-detail{
            text-decoration: underline;
            color: #666666;
          }
        }
        .get-coupon-content{
          margin-top: 40px;
          .input-content{
            margin-top: 8px;
          }
          .el-input{
            width: 260px;
          }
          .el-button{
            height: 48px;
            margin-left: 16px;
          }
        }
    }
}
</style>
