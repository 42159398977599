<template>
  <div>
    <div class="tips">
      <router-link to="/">家具TOP</router-link>
      <span> / </span>
      <span v-for="(item,index) in parentsData" :key="index">
        <router-link v-if="index!==parentsData.length-1" :to="item.url">
          <span class="crumb-a">{{ item.name }}</span>
        </router-link>
        <span v-if="index!==parentsData.length-1"> / </span>
        <span v-if="index===parentsData.length-1" class="crumb-span">{{ item.name }}</span>
      </span>
    </div>
    <div class="custom" v-html="categoryData.mobile_desc" />
    <div class="view-title">ITEMS</div>
    <div class="view-desc">{{ (parameterInfo.page - 1) * parameterInfo.limit }} ～ {{ parameterInfo.page * parameterInfo.limit }} 件目を表示しています。(全{{ total }}件)</div>
    <div class="screen">
      <div class="screen-box" :class="{show : show}" @click.stop="show = !show,showTags = false">
        <img v-if="show" src="@/assets/images/sp/screen_show.png">
        <img v-else src="@/assets/images/sp/screen.png">
        <span>並べ替え</span>
        <transition name="el-fade-in-linear">
          <div v-show="show" class="screen-item">
            <div :class="{current:parameterInfo.sort === 'is_hot'}" @click="parameterInfo.sort = 'is_hot',parameterInfo.page = 1,productList()">人気順</div>
            <div :class="{current:parameterInfo.sort === '-created_at'}" @click="parameterInfo.sort = '-created_at',parameterInfo.page = 1,productList()">新着順</div>
            <div :class="{current:parameterInfo.sort === 'price'}" @click="parameterInfo.sort = 'price',parameterInfo.page = 1,productList()">価格安い順</div>
            <div :class="{current:parameterInfo.sort === '-price'}" @click="parameterInfo.sort = '-price',parameterInfo.page = 1,productList()">価格高い順</div>
          </div>
        </transition>
      </div>
      <div class="tags" :class="{show : showTags}">
        <div class="tags-title" @click.stop="showTags = !showTags,show = false">
          <img v-if="showTags" src="@/assets/images/sp/tags_show.png">
          <img v-else src="@/assets/images/sp/tags.png">
          <span>絞り込み</span>
        </div>
        <transition name="el-fade-in-linear">
          <div v-show="showTags" class="tags-box">
            <div class="tags-box-content">
              <div v-for="it in tagsData" :key="it.id" class="tags-category">
                <template v-if="it.id !== 'カラー'">
                  <div class="tags-category-name" @click.stop="showTagsDataClick(it.id)">
                    <div>{{ it.name }}</div>
                    <img v-if="showTagsData.includes(it.id)" src="@/assets/images/sp/article_hide_black.png">
                    <img v-else src="@/assets/images/sp/article_open_black.png">
                  </div>
                  <el-collapse-transition>
                    <div v-show="showTagsData.includes(it.id)">
                      <div
                        v-for="item in it.tags"
                        :key="item.id"
                        class="tags-item"
                        :class="{active:searchData.tag_ids === item.id}"
                        @click.stop="changeTags(item.id)"
                      >
                        <div class="tags-item-name">{{ item.name }}</div>
                        <img v-show="searchData.tag_ids === item.id" src="@/assets/images/sp/tags_choice.png">
                      </div>
                    </div>
                  </el-collapse-transition>
                </template>
                <template v-else>
                  <div class="tags-category-name" @click.stop="showTagsColor = !showTagsColor">
                    <div>カラー</div>
                    <img v-if="showTagsColor" src="@/assets/images/sp/article_hide_black.png">
                    <img v-else src="@/assets/images/sp/article_open_black.png">
                  </div>
                  <el-collapse-transition>
                    <div v-show="showTagsColor">
                      <div
                        v-for="item in it.tags"
                        :key="item.id"
                        class="tags-item"
                        :class="{active:searchData.color_tag_ids === item.id}"
                        @click.stop="changeColorTags(item.id)"
                      >
                        <div class="color-box">
                          <div class="color" :style="{background:item.color_value}" />
                          <div class="tags-item-name">{{ item.name }}</div>
                        </div>
                        <img v-show="searchData.color_tag_ids === item.id" src="@/assets/images/sp/tags_choice.png">
                      </div>
                    </div>
                  </el-collapse-transition>
                </template>
              </div>
            </div>
            <div class="tags-butt">
              <div class="cancel" @click="searchData.tag_ids = '',searchData.color_tag_ids = '',productList()">リセット</div>
              <div class="confirm" @click="parameterInfo.page = 1,productList()">決定</div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div v-if="initData.length > 0">
      <div class="goods-list">
        <div v-for="item in initData" :key="item.id" class="goods-item">
          <router-link :to="'/product/'+ item.product_sn">
            <div class="img">
              <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
              <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="discount">{{ item.promotion[0].discount }}%OFF</div>
              <div v-if="!item.is_on_sale" class="no-inventory">在庫なし</div>
            </div>
          </router-link>
          <div class="label">
            <div
              v-for="(it,index) in item.color_tags"
              :key="index"
              :style="{background:it.color_value}"
              :class="{active:item.colorTagIndex === index}"
              @click="item.colorTagIndex === index ? item.colorTagIndex = '' : item.colorTagIndex = index"
            >
              <span />
            </div>
          </div>
          <div class="title-box">
            <!-- <div class="price">
              <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
                <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
                <span class="original">¥{{ calcThousands(item.price) }}</span>
              </template>
              <template v-else>
                ¥{{ calcThousands(item.price) }}
              </template>
            </div> -->
            <div class="price">
              <div class="now">
                <span>¥{{ item.discount_price ? calcThousands(item.discount_price) : calcThousands(item.price) }}</span>
                <span v-if="item.is_hot" class="sale">SALE</span>
                <span v-if="item.is_new" class="new">NEW</span>
              </div>
              <div v-show="item.discount_price" class="original">¥{{ calcThousands(item.price) }}</div>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <pagination-sp
        :hidden="total <= parameterInfo.limit"
        :total="total"
        :page.sync="parameterInfo.page"
        :limit.sync="parameterInfo.limit"
        :link="$route.fullPath"
        @pagination="productList"
      />
    </div>
    <div class="tips tips2"><router-link to="/">家具TOP</router-link> / {{ categoryData.name }}</div>
    <div class="link-box">
      <router-link to="/new/product">
        <div>NEW ITEM</div>
        新商品一覧
      </router-link>
      <router-link to="">
        <div>SHORT PERIOD</div>
        短納期商品一覧
      </router-link>
    </div>
    <form action="javascript:return false" @submit="search">
      <input ref="search" v-model="input" class="search" type="search" placeholder="キーワードで検索">
    </form>
  </div>
</template>
<script>
import _ from 'lodash'
import { productList } from '@/api/product'
import { getCategoryDetail, getTagCategories } from '@/api/category'
import { getColorTagList } from '@/api/tag'
import { calcThousands, parameterCorrection } from '@/utils/validate'
import PaginationSp from '@/components/PaginationSp'
export default {
  components: { PaginationSp },
  data() {
    return {
      input: '',
      show: false,
      showTags: false,
      showTagsColor: false,
      showTagsData: [],
      categoryData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        mobile_desc: null,
        mobile_desc_css_path: '',
        mobile_desc_js_path: ''
      },
      initData: [],
      parentsData: [],
      tag: [],
      colorTag: [],
      tagsData: [],
      total: 0,
      parameterInfo: {
        page: parseInt(this.$route.query.page) || 1,
        limit: 20,
        sort: 'sort',
        include: 'tag,colorTags,promotion'
      },
      searchData: {
        color_tag_ids: '',
        tag_ids: ''
      }
    }
  },
  metaInfo() {
    return {
      title: this.categoryData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.categoryData.seo_keywords },
        { vmid: 'description', name: 'description', content: this.categoryData.seo_description }
      ],
      link: [
        { vmid: this.categoryData.mobile_desc_css_path, rel: 'stylesheet', href: this.categoryData.mobile_desc_css_path }
      ],
      script: [
        { vmid: this.categoryData.mobile_desc_js_path, async: 'async', src: this.categoryData.mobile_desc_js_path }
      ]
    }
  },
  computed: {
    url: function() {
      return this.$route.params.url
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.showTagsColor = false
        this.showTagsData = []
        this.searchData.color_tag_ids = ''
        this.searchData.tag_ids = ''
        this.parameterInfo.page = this.$route.query.page || 1
        this.parameterInfo.sort = this.$route.query.sort || ''
        this.fetchData().then(() => {
          this.productList()
          this.getColorTagList()
        })
      }
    }
  },
  created() {
    this.fetchData().then(() => {
      this.productList()
      this.getColorTagList()
    })
    window.addEventListener('click', this.handleClick)
  },
  destroyed() {
    window.removeEventListener('click', this.handleClick)
  },
  // beforeRouteLeave(to, from, next) {
  //   let position = 0
  //   if (to.name === 'product') {
  //     position = window.scrollY
  //   }
  //   this.$store.dispatch('user/updatePosition', position)
  //   next()
  // },
  methods: {
    calcThousands,
    handleClick() {
      this.show = false
      this.showTags = false
    },
    fetchData() {
      return getCategoryDetail(this.url).then(result => {
        result.mobile_desc_css_path = result.mobile_desc_css_path || ''
        result.mobile_desc_js_path = result.mobile_desc_js_path || ''
        this.categoryData = result
        this.parentsData = []
        if (this.categoryData.parents && this.categoryData.parents.length > 0) {
          this.categoryData.parents.forEach(item => {
            this.parentsData.push({ name: item.name, url: '/category/' + item.url })
          })
        }
        this.parentsData.push({ name: this.categoryData.name, url: '' })
        // const position = this.$store.state.user.position
        // setTimeout(() => {
        //   window.scroll(0, position)
        // }, 100)
      })
    },
    productList() {
      let data = parameterCorrection({ ...this.searchData, category_with_children: this.categoryData.id })
      data = { ...data, ...this.parameterInfo }
      productList(data).then((result) => {
        result.data.forEach(item => {
          item.colorTagIndex = ''
        })
        this.initData = result.data
        this.total = result.total
        this.initData.forEach(item => {
          if (item.promotion.length > 0 && item.promotion[0].is_open) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
      })
    },
    getColorTagList() {
      getColorTagList(this.categoryData.id).then(result => {
        this.colorTag = result
        getTagCategories(this.categoryData.id, { type: 'all', include: 'toShowTags' }).then(result => {
          let tagCategoriesOrders = []
          this.categoryData.tag_categories.forEach(item => {
            result.forEach(item2 => {
              if (item.id === 'カラー') {
                tagCategoriesOrders.push('カラー')
              }
              if (item2.id === item.id) {
                tagCategoriesOrders.push(item2.id)
              }
            })
          })
          tagCategoriesOrders = _.uniq(tagCategoriesOrders)
          const index = tagCategoriesOrders.findIndex((item) => item === 'カラー')
          const arr = result
          const colorData = {
            id: 'カラー',
            name: 'カラー',
            tags: this.colorTag
          }
          arr.splice(index, 0, colorData)
          this.tagsData = arr
        })
      })
    },
    changeColorTags(id) {
      if (this.searchData.color_tag_ids === id) {
        this.searchData.color_tag_ids = ''
      } else {
        this.searchData.color_tag_ids = id
      }
      this.searchData.tag_ids = ''
    },
    showTagsDataClick(id) {
      if (this.showTagsData.includes(id)) {
        this.showTagsData.splice(this.showTagsData.indexOf(id), 1)
      } else {
        this.showTagsData.push(id)
      }
    },
    changeTags(id) {
      if (this.searchData.tag_ids === id) {
        this.searchData.tag_ids = ''
      } else {
        this.searchData.tag_ids = id
      }
      this.searchData.color_tag_ids = ''
    },
    search(e) {
      this.$router.push({ path: '/search', query: { input: this.input }})
      this.$refs.search.blur()
      e.preventDefault()
    }
  }
}
</script>
<style lang="scss" scoped>
.tips{
  text-align: center;
  margin: .24rem .24rem .16rem;
}
.tips2{
  margin-top: 1.1rem;
}
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.view-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.9rem 0 0.28rem;
  &::before,
  &::after{
    content: '';
    width: 0.8rem;
    height: 0.02rem;
    background: #333333;
    margin: 0 0.4rem;
  }
}
.view-desc{
  text-align: center;
  margin-bottom: 0.32rem;
}
.screen{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.24rem;
  padding: 0 0 0.16rem;
  border-bottom: 0.02rem solid #333;
  margin: 0 0.24rem 0.24rem;
  .screen-box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.44rem;
    height: 0.8rem;
    font-size: 0.28rem;
    background: #F4F5FC;
    img{
      width: 0.32rem;
    }
  }
  .screen-item{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    line-height: 0.88rem;
    color: #333;
    box-sizing: border-box;
    border: 0.02rem solid #333;
    background: #fff;
    z-index: 10;
    div{
      padding: 0 0.22rem;
    }
    .current{
      background: #F4F5FC;
    }
  }
  .tags{
    position: relative;
    width: 3.44rem;
    height: 0.8rem;
    font-size: 0.28rem;
    background: #F4F5FC;
    .tags-title{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    img{
      width: 0.32rem;
    }
  }
  .screen-box.show,.tags.show{
    color: #fff;
    background: #333;
  }
  .tags-box{
    position: absolute;
    right: 0;
    top: 100%;
    width: 7.02rem;
    color: #333;
    box-sizing: border-box;
    border: 0.02rem solid #333;
    background: #fff;
    z-index: 10;
    .tags-box-content{
      max-height: 50vh;
      overflow: auto;
    }
    .tags-category{
      .tags-category-name{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 0.88rem;
        padding: 0 0.24rem 0 0.22rem;
        img{
          width: 0.24rem;
        }
        div{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 0.16rem;
        }
      }
      .tags-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 0.86rem;
        padding: 0 0.24rem 0 0.54rem;
        border-bottom: 0.02rem solid #F4F5FC;
        &:nth-child(1){
          border-top: 0.02rem solid #F4F5FC;
        }
        &.active{
          background: #F4F5FC;
        }
        .color-box{
          display: flex;
          align-items: center;
          width: calc(100% - 0.48rem);
        }
        .color{
          flex-shrink: 0;
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          margin-right: 0.16rem;
        }
        .tags-item-name{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 0.16rem;
        }
        img{
          width: 0.32rem;
        }
      }
    }
    .tags-butt{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.24rem 0;
      border-top: 0.02rem solid #333;
      div{
        height: 0.8rem;
        text-align: center;
        line-height: 0.8rem;
        box-sizing: border-box;
      }
      .cancel{
        width: 2.34rem;
        border: 0.02rem solid #333;
      }
      .confirm{
        width: 4.2rem;
        color: #fff;
        background: #333;
      }
    }
  }
}
.goods-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0.24rem;
    .goods-item{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 3.44rem;
      margin: 0 0 0.44rem;
      .img{
        position: relative;
        img{
          flex-shrink: 0;
          width: 100%;
          height: 3.44rem;
          vertical-align: middle;
        }
        .discount{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1.1rem;
          height: .36rem;
          line-height: .36rem;
          text-align: center;
          font-size: .24rem;
          background-color: #c40a0a;
          color: #ffffff;
        }
        .no-inventory{
          position: absolute;
          right: .08rem;
          top: .08rem;
          padding: .06rem;
          line-height: .3rem;
          text-align: center;
          background-color: #999fa5;
          color: #ffffff;
          font-size: .24rem;
        }
      }
      .label{
        height: 100%;
        span{
          background: transparent;
        }
        div{
          display: inline-block;
          width: 0.48rem;
          height: 0.48rem;
          vertical-align: middle;
          box-sizing: border-box;
          border-radius: 50%;
          margin: 0.16rem 0.1rem 0 0;
          border: .02rem solid #e8ebed;
        }
        .active{
          border: 0.02rem solid #333;
          span{
            display: block;
            width: 0.44rem;
            height: 0.44rem;
            border-radius: 50%;
            box-sizing: border-box;
            border: 0.04rem solid #fff;
          }
        }
      }
      .title-box{
        flex-shrink: 0;
      }
      .title{
        display: -webkit-box;
        line-height: .32rem;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        margin: .24rem 0 0 0;
        font-size: .24rem;
      }
      .price{
        margin: 0.16rem 0 0 0;
        .now{
          font-size: .28rem;
          font-weight: bold;
          .sale{
            margin-left: .2rem;
            color: #FECB35;
          }
          .new{
            margin-left: .12rem;
            color: #F90412;
          }
        }
        .original{
          margin-top: .08rem;
          font-size: .24rem;
          color: #999999;
          text-decoration: line-through;
        }
      }
    }
}
.link-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.32rem 0.24rem 0.24rem;
  a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3.44rem;
    height: 1.2rem;
    box-sizing: border-box;
    border: 0.02rem solid #333;
  }
  div{
    font-size: 0.32rem;
    font-weight: bold;
    margin-bottom: 0.06rem;
  }
}
.search{
    width: 7.02rem;
    height: 0.88rem;
    padding: 0 0 0 0.6rem;
    border: 0.02rem solid #E8EBED;
    box-sizing: border-box;
    background: url('~@/assets/images/sp/shape.png') 0.2rem center / 0.28rem 0.28rem no-repeat;
    margin: 0.14rem 0.24rem 1.6rem;
}
.search::-webkit-input-placeholder{
  color: #999FA5
}
</style>
