<template>
  <div v-show="confirmVisible" class="overlay confirm-overlay">
    <div class="main">
      <div class="modal-content">
        <template v-if="!confirmVisibleSuccess">
          <div class="title">メールで入荷通知を受取る</div>
          <div class="text">お知らせを受けるメールアドレスをご入力ください。</div>
          <input v-model="email" type="text" placeholder="E-mail:">
          <button class="confirm-btn" @click="emailSubmit">Eメール登録</button>
        </template>
        <template v-else>
          <div class="desc">入荷お知らせを<br>受付致しました</div>
          <button class="confirm-btn" @click="close">閉じる</button>
        </template>
        <img src="@/assets/images/pc/x.png" class="close-btn" @click="close">
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { emailSubscriptions } from '@/api/config'
export default {
  props: {
    confirmVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: '',
      confirmVisibleSuccess: false
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      if (this.token) {
        const userInfo = await this.$store.dispatch('user/getUserDetail')
        this.email = userInfo.email
      }
    },
    // 邮件订阅
    emailSubmit() {
      emailSubscriptions({
        email: this.email,
        type: 2,
        product_id: this.product_id,
        sku_id: this.sku_id
      }).then(() => {
        this.email = ''
        this.confirmVisibleSuccess = true
      })
    },
    close() {
      this.$emit('update:confirmVisible', false)
      this.confirmVisibleSuccess = false
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm-overlay{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 1000;
  .main{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 6.54rem;
    height: min-content;
    background: #fff;
    margin: auto;
  }
  .modal-content{
    padding: 0.8rem 0.4rem;
    .close-btn{
      position: absolute;
      top: 0.24rem;
      right: 0.24rem;
      width: 0.24rem;
    }
    .title{
      font-size: 0.36rem;
      font-weight: bold;
    }
    .text{
      margin-top: 0.48rem;
    }
    input{
      width: 100%;
      height: 0.88rem;
      border: 0.02rem solid #e8ebed;
      box-sizing: border-box;
      padding: 0 0.2rem;
      margin: 0.24rem 0 0;
    }
    .confirm-btn{
      width: 100%;
      height: 0.88rem;
      margin: 0.56rem 0 auto;
      line-height: 0.88rem;
      text-align: center;
      color: #fff;
      background-color: #333;
    }
    .desc{
      font-size: 0.36rem;
      line-height: 0.54rem;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
