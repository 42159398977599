<template>
  <div>
    <div class="question-from">
      <div class="ttl">Contact us by email</div>
      <div class="dc">メールでお問い合わせ</div>
      <div class="tips">
        下記フォームに必要事項をご記入の上、送信してください。<br><br>
        24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。<br><br>
        ※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。<br><br>
        ※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。
      </div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
        <el-form-item prop="type" label="お問い合わせ項目">
          <el-select v-model="ruleForm.type" placeholder="（項目を選んでください）" clearable>
            <el-option label="商品について" :value="1" />
            <el-option label="納期について" :value="2" />
            <el-option label="不良交換について" :value="3" />
            <el-option label="サイトについて" :value="4" />
            <el-option label="その他" :value="5" />
          </el-select>
        </el-form-item>
        <el-form-item prop="username" label="お名前">
          <el-input v-model="ruleForm.username" placeholder="お名前" clearable />
        </el-form-item>
        <el-form-item prop="email" label="メールアドレス">
          <el-input v-model="ruleForm.email" placeholder="メールアドレス" clearable />
        </el-form-item>
        <el-form-item prop="content" label="お問い合わせ内容">
          <el-input v-model="ruleForm.content" type="textarea" placeholder="お問い合わせ内容" />
        </el-form-item>
      </el-form>
      <el-button class="deliver" type="primary" @click="onSubmit">送信</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      ruleForm: {
        type: 1,
        username: '',
        email: '',
        content: ''
      },
      rules: {
        type: [{ required: true }],
        username: [{ required: true, message: 'お名前をご入力ください', trigger: 'blur' }],
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスをご入力ください', trigger: ['blur'] }
        ],
        content: [{ required: true, message: 'お問い合わせ内容をご入力ください', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters([
      'question'
    ])
  },
  created() {
    this.ruleForm = { ...this.ruleForm, ...this.question }
  },
  methods: {
    // 提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('config/setQuestion', this.ruleForm)
          this.$router.push({ path: '/question/confirm' })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.question-from{
  margin: 0.36rem 0.24rem 1rem;
}
.ttl{
  font-size: 0.36rem;
  font-weight: bold;
  padding: 0 0.24rem;
}
.dc{
  height: 0.68rem;
  font-size: 14px;
  padding: 0.28rem 0.24rem 0;
  box-sizing: border-box;
  background: #F4F5FC;
  margin: -0.2rem 0 0.44rem;
}
.tips{
  margin-bottom: 0.32rem;
}
::v-deep {
  .el-form{
    margin-top: 0.36rem;
  }
  .el-select{
    width: 100%;
  }
  .el-textarea{
    height: 2.4rem;
  }
  .el-textarea__inner{
    height: 100%;
    resize: none;
  }
}
.deliver{
  display: block;
  width: 100%;
  height: 1.08rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
  background: #333;
  border: none;
  margin: 0.8rem auto 1.1rem;
}
</style>
