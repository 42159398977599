<template>
  <div class="register-content">
    <div class="content">
      <div class="title">認証メールを送信いたしました。</div>
      <div class="username">{{ firstName }} {{ lastName }} 様</div>
      <div class="text">
        会員登録ありがとうございます。<br>
        それではショッピングをお楽しみください。<br><br>
        「 www.woodlife.com 」に認証メールを送信いたしました。<br>
        メールに記載されたURLをクリックして、会員登録を完了させてくださいませ。<br><br>
        ※認証用URLの有効期限は12時間です。<br><br>
        メールアドレスが受け取り可能なものにもかかわらずご確認メールが届かない場合、お問い合わせ窓口までご連絡くださいませ。
      </div>
      <router-link to="/">
        <div class="button">トップページ</div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      firstName: this.$route.query.first_name,
      lastName: this.$route.query.last_name
    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.register-content{
    padding: 0 .24rem;
    margin-top: .42rem;
    flex: 1;
    .title{
        font-size: .46rem;
        font-weight: 700;
        color: #4bc4b0;
    }
    .username{
        margin-top: .46rem;
        font-size: .36rem;
        font-weight: 700;
    }
    .text{
        margin-top: .3rem;
        line-height: .42rem;
    }
    .button{
        width: 4.08rem;
        height: .8rem;
        margin: .4rem auto 0;
        line-height: .8rem;
        text-align: center;
        color: #ffffff;
        background: #333333;
    }
}
</style>
