<template>
  <div class="personal-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/personal/mine">マイページ</router-link> / <router-link to="/personal/order">購入履歴</router-link> / 注文詳細
    </div>
    <div class="detail-content">
      <div class="head">
        <div class="box">
          <div>ご注文番号：{{ initData.order_sn }}</div>
          <div class="time">{{ initData.created_at }}</div>
        </div>
        <div class="status" :class="initData.status_to_user === 1 ? 'blue' : initData.status_to_user === 2 ? 'green' : initData.status_to_user === 4 ? 'gray' : ''">
          {{ initData.store_status && initData.store_status.name }}
        </div>
      </div>
      <div class="product-lists">
        <div v-for="item in initData.order_products" :key="item.id" class="list">
          <router-link :to="`/product/${item.product_sn}`">
            <img :src="item.product_img">
          </router-link>
          <div class="desc">
            <div class="name">{{ item.product_title }}</div>
            <div class="sku-content">
              <div v-for="(skuItem,index2) in item.product_sku_display" :key="index2" class="item">{{ skuItem }}</div>
            </div>
            <div class="norm-content">
              <div v-for="(normItem,index3) in item.product_spu_display" :key="index3" class="item">{{ normItem }}</div>
            </div>
            <div class="price">
              <span>¥{{ calcThousands(item.pay_price) }}円</span>
              <span class="num">×{{ item.product_quantity }}</span>
            </div>
          </div>
        </div>
        <div class="price-detail">
          <div class="item">
            <div class="name">送料</div>
            <div class="value">+¥{{ calcThousands(initData.freight_amount) }}</div>
          </div>
          <div class="item">
            <div class="name">クーポン</div>
            <div class="value">-¥{{ calcThousands(initData.use_point_fee) }}</div>
          </div>
          <div class="item">
            <div class="name">利用ポイント</div>
            <div class="value">-¥{{ calcThousands(initData.coupon_amount) }}</div>
          </div>
          <div class="item">
            <div class="name">合计金额</div>
            <div class="value price">¥{{ calcThousands(initData.pay_amount) }}</div>
          </div>
        </div>
      </div>
    </div>
    <table class="address-content">
      <tr>
        <td>配送先</td>
        <td>
          <p>{{ initData.receiver_name }} 様</p>
          <p>〒{{ initData.zip_code }} {{ initData.stats }}{{ initData.city }}{{ initData.address }}</p>
          <p>{{ initData.detail_address ? initData.detail_address : '' }}</p>
          <p>{{ initData.receiver_phone }}</p>
        </td>
      </tr>
      <tr>
        <td>支払方法</td>
        <td>{{ initData.pay_type === 2 ? '銀行振込・前払い' : initData.pay_type === 3 ? 'クレジットカード決済' : initData.pay_type === 4 ? 'amazon pay': '' }}</td>
      </tr>
    </table>
    <div v-if="initData.is_comment" class="comments-content">
      <div class="head">レビュー</div>
      <div class="product-lists">
        <div v-for="item in commentsData" :key="item.id" class="list">
          <div class="flex">
            <router-link :to="`/product/${item.product.product_sn}`">
              <img :src="item.product.image" :alt="item.product.image_alt">
            </router-link>
            <div class="desc">
              <div class="name">{{ item.product_name }}</div>
              <div class="sku-content">
                <div v-for="(skuItem,index2) in item.product_attribute" :key="index2" class="item">{{ skuItem }}</div>
              </div>
              <div class="norm-content">
                <div v-for="(normItem,index3) in item.product_spu_display" :key="index3" class="item">{{ normItem }}</div>
              </div>
            </div>
          </div>
          <div class="rate-content">
            <div>
              <van-rate v-model="item.stars" color="#fccf52" void-icon="star" void-color="#e3e4eb" readonly />
              <span class="value">{{ item.stars }}</span>
            </div>
            <div class="content">{{ item.content }}</div>
            <div
              v-if="item.images.length > 0"
              class="image-content"
            >
              <div class="box" :class="item.images.length === 1 ? 'one': item.images.length === 2 ? 'two' : item.images.length === 3 ? 'three' : item.images.length === 4 ? 'four': ''">
                <img
                  v-for="(value,key) in item.images"
                  :key="key"
                  :src="value"
                  @click="handleImagePreview(item.images,key)"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link to="/personal/order">
      <div class="button">閉じる</div>
    </router-link>
  </div>
</template>

<script>
import { getMyOrderDetail, getMyOrderComments } from '@/api/personal'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      initData: {
        freight_amount: 0,
        pay_amount: 0,
        use_point_fee: 0,
        coupon_amount: 0
      },
      commentsData: [],
      calcThousands
    }
  },
  computed: {
  },
  created() {
    this.getMyOrderDetail().then(() => {
      if (this.initData.is_comment === 1) {
        this.getMyOrderComments()
      }
    })
  },
  methods: {
    getMyOrderDetail() {
      return getMyOrderDetail(this.id, { include: 'orderProducts,storeStatus' }).then(result => {
        this.initData = result
      })
    },
    getMyOrderComments() {
      getMyOrderComments({ 'filter[order_id]': this.id, type: 'all', include: 'product' }).then(result => {
        this.commentsData = result
      })
    },
    handleImagePreview(arr, index) {
      this.$ImagePreview({
        images: arr,
        startPosition: index
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.personal-content {
    padding: 0 .24rem;
    flex: 1;

    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }

    .detail-content {
        margin-top: .48rem;
        border: .02rem solid #e8ebed;

        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 1.2rem;
            padding: 0 .22rem;
            background: #f4f5fc;

            .box {
                font-size: .24rem;

                .time {
                    margin-top: .16rem;
                    color: #999999;
                }
            }

            .status {
                font-weight: 700;
            }

            .blue {
                color: #57a3e6;
            }

            .green {
                color: #4bc4b0;
            }

            .gray {
                color: #999999;
            }
        }

        .product-lists {
            padding: 0 .24rem;

            .list {
                display: flex;
                justify-content: space-between;
                padding: .32rem 0;
                border-bottom: .02rem solid #e8ebed;

                img {
                    width: 2rem;
                    height: 2rem;
                    object-fit: cover;
                }

                .desc {
                    width: 4.3rem;

                    .name {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        margin-bottom: .24rem;
                        font-weight: 700;
                        line-height: .42rem;
                    }

                    .sku-content,
                    .norm-content {
                        line-height: .36rem;
                        word-break: break-all;
                        font-size: .24rem;
                    }

                    .price {
                        margin-top: .24rem;
                        color: #c40a0a;
                    }

                    .num {
                        margin-left: .06rem;
                        color: #999999;
                    }
                }
            }

            .price-detail {
                margin-top: .32rem;

                .item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: .32rem;

                    &:last-child {
                        font-weight: 700;

                        .value {
                            color: #c40a0a;
                        }
                    }
                }
            }
        }
    }

    .address-content {
        width: 100%;
        margin-top: .4rem;

        tr {
            td {
                padding: .24rem;
                ;
                border: .02rem solid #e8ebed;

                &:first-child {
                    width: 2.2rem;
                }
            }
        }
    }

    .comments-content {
        margin-top: .4rem;
        border: .02rem solid #e8ebed;

        .head {
            padding: 0 .24rem;
            height: .64rem;
            line-height: .64rem;
            background: #f4f5fc;
        }

        .product-lists {
            padding: 0 .24rem;

            .list {
                margin-top: .32rem;
                padding-bottom: .32rem;

                &:not(:last-child) {
                    border-bottom: .02rem solid #e8ebed;
                }

                .flex {
                    display: flex;
                    justify-content: space-between;
                }

                img {
                    width: 2rem;
                    height: 2rem;
                    object-fit: cover;
                }

                .desc {
                    width: 4.3rem;

                    .name {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        margin-bottom: .24rem;
                        font-weight: 700;
                    }

                    .sku-content,
                    .norm-content {
                        line-height: .36rem;
                        font-size: .24rem;
                        color: #999999;
                    }
                }

                .rate-content {
                    margin-top: .16rem;

                    ::v-deep .van-rate__icon {
                        font-size: .28rem;
                    }

                    .value {
                        margin-left: .08rem;
                        color: #fccf52;
                        font-weight: 700;
                    }
                    .image-content{
                      margin-top: .1rem;
                      .box{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        img{
                          margin-top: .14rem;
                        }
                      }
                      .one{
                        img{
                          width: 4.28rem;
                          height: 4.28rem;
                        }
                      }
                      .two,.four{
                        img{
                          width: 3.2rem;
                          height: 3.2rem;
                        }
                      }
                      .three{
                        img{
                          width: 2.08rem;
                          height: 2.08rem;
                        }
                      }
                    }

                    .content {
                        margin-top: .16rem;
                        line-height: .42rem;
                    }
                }
            }
        }
    }

    .button {
        width: 4.08rem;
        height: .8rem;
        margin: .8rem auto 0;
        line-height: .8rem;
        text-align: center;
        border: .02rem solid #333333;
    }
}
</style>
