<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / よくあるご質問
    </div>
    <div class="title-content">
      よくあるご質問
      <img src="@/assets/images/sp/guides_question.png">
    </div>
    <div class="question-content">
      <div class="question-title">ご注文について</div>
      <div class="question-box">
        <div class="question-q" :class="{show:show.includes(1)}" @click="changeShow(1)">
          <img src="@/assets/images/pc/question_q.png">
          <span>注文完了メールが届きません。</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(1)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                ご注文受付後、自動的に注文受付メールをお送りしておりますが、登録メールアドレスに誤りがある、または ドメイン指定受信や迷惑メールフィルタ機能の設定などにより、メールをお届けできない場合がございます。<br>
                また、お客様のメール受信ボックスがいっぱいの為、メールが受信されないケースが最近増えております。ご確認下さいませ。<br>
                ご購入手続き完了時に14ケタの注文番号が発行されている場合は、ご注文が完了しています。<br>
                重複注文など不安に思われた場合は、<br>
                お問合せフォームまたはカスタマーセンターまでお問い合わせください。<br>
                【カスタマーセンター】047-489-5595（受付時間：10：00～18：00 土・日・祝を除く）

              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(2)}" @click="changeShow(2)">
          <img src="@/assets/images/pc/question_q.png">
          <span>注文したらどのぐらいものは届くですか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(2)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                納期は商品により異なりますので、詳しくは各商品ページをご覧ください。<br>
                ご入金を確認後のオーダーとなりますことご了承下さい。<br>
                また、予期せぬ出来事により、さらに長くお待ちいただくこともあります。<br>
                オーダー家具は受注から30-60営業日後の配送となります。<br>
                その他商品は受注から8-10営業日後の配送となります。<br>
                ※年末年始・夏季休暇・ゴールデンウィークなどの特別な時期を除く。<br>
                ※大型商品の場合はチャーター便となるため、通常よりお時間を頂戴しております。 お客様ご注文時のご希望納期を運送会社に伝えたのち、運送会社からの納期調整のご連絡によって最終納期の確定となります。ご注文時点での納期確約ではないので予めご了承ください。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(3)}" @click="changeShow(3)">
          <img src="@/assets/images/pc/question_q.png">
          <span>電話注文はできますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(3)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                お電話でのご注文は受け付けておりません。オンラインショップ・メール・FAXでのみ承っております。ご注文前のご相談は下記の窓口で承っております。お気軽にご連絡ください。<br>
                平日：カスタマーサポート窓口：<br>
                月曜 ~ 金曜日 ／ 時間：10:00 ～18:00 となっております。※土日・祝日以外の営業日においては、通常24時間以内に返信させていただきます。<br>
                ※お問い合わせ内容、混雑等により、回答に日数を要する場合がございます。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(4)}" @click="changeShow(4)">
          <img src="@/assets/images/pc/question_q.png">
          <span>注文後、オーダー変更は可能ですか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(4)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                （ご注文内容の変更）<br>
                送り先の変更など：出荷手続き前であれば対応させていただきます。<br>
                【お問合せ先電話番号】047-489-5595<br>
                （キャンセル）<br>
                基本的にキャンセルはお受けいたしかねますことをご理解くださいませ。<br>
                ただし、ご注文が確定していない場合や、オーダーに入っていない場合はご相談くださいませ。<br>
                １件ご注文当たり数万円のキャンセル手数料を別途でご請求させていただきますので、ご了承くださいますようお願い申し上げます。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(5)}" @click="changeShow(5)">
          <img src="@/assets/images/pc/question_q.png">
          <span>高さや幅などのサイズ変更できますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(5)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                基本的に特注は行っておりません。規定されたサイズのみで販売しております。<br>
                オーダー可能な商品も取り扱いますが、料金と納期は各商品ページでご確認ください。
              </span>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="question-title">商品について</div>
      <div class="question-box">
        <div class="question-q" :class="{show:show.includes(6)}" @click="changeShow(6)">
          <img src="@/assets/images/pc/question_q.png">
          <span>商品の保証期間はどれぐらいですか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(6)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                全品三ヶ月保証となります。お品は到着より三ヶ月以内、通常通りご利用頂いで発生した<br>
                不具合に関してましては、無償にて修理もしくは交換させていだきます。お手数でござい<br>
                ますが、メールにて破損状況の分かるお写真(写メール)と共にご連絡くださいませ。<br>
                3ヶ月以上お使い頂いて発生した不具合については、修理可能でございましたら、有料にて<br>
                修理（都度お見積となります）を承りますので、ご相談くださいませ。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(7)}" @click="changeShow(7)">
          <img src="@/assets/images/pc/question_q.png">
          <span>カタログが欲しいのですが、送ってもらうことはできますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(7)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                当店ではWEBページのみとなり、カタログをご用意しておりません。各商品ページにて詳細などをご確認くださいませ。
              </span>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="question-title">配送・送料について</div>
      <div class="question-box">
        <div class="question-q" :class="{show:show.includes(8)}" @click="changeShow(8)">
          <img src="@/assets/images/pc/question_q.png">
          <span>配送前に電話してもらえますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(8)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                お客様の代わりに運送業者にお願い致しますが、必ずのお約束はできませんので、予めご了承ください。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(9)}" @click="changeShow(9)">
          <img src="@/assets/images/pc/question_q.png">
          <span>商品の到着日や受け取り時間を指定できますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(9)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                ご注文確定前：ご注文者情報入力ページにて「ご希望お届け予定日」を入力することが可能です。または「備考欄」にその旨入力願います。<br>
                ご注文確定後：既に出荷手続きを完了している場合がございます。まずはお問合せフォーム、もしくはカスタマーセンターまでご連絡願います。<br>
                当店出荷後：「出荷完了のお知らせメール」に記載されております、お荷物お問合せ番号に基づき運輸会社に直接ご連絡をお願い申し上げます。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(10)}" @click="changeShow(10)">
          <img src="@/assets/images/pc/question_q.png">
          <span>不要になった家具を引き取って欲しいのですか。</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(10)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                恐れ入りますが、現在家具の引き取りサービスは行っておりません。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(11)}" @click="changeShow(11)">
          <img src="@/assets/images/pc/question_q.png">
          <span>組み立てはいくらにしますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(11)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                商品により異なりますので、詳しくは各商品ページをご覧ください。<br>
                【組立サービスの料金】 ➡️ 1点 10,000円（税込）<br>
                ※大型家具の場合、お客様にお手伝いをお願いする場合がございます。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(12)}" @click="changeShow(12)">
          <img src="@/assets/images/pc/question_q.png">
          <span>送料を教えてください。</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(12)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                当店では全国一律運賃（離島除く）を採用しておりますので、各商品詳細ページ及び運賃表のページにてご確認いただけます。
                <table class="table">
                  <tr>
                    <td>合計が54,000円(税込)未満</td>
                    <td>合計が54,000円(税込)以上</td>
                  </tr>
                  <tr>
                    <td>送料3,000円</td>
                    <td>送料無料</td>
                  </tr>
                </table>
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(13)}" @click="changeShow(13)">
          <img src="@/assets/images/pc/question_q.png">
          <span>沖縄県（離島）までの運賃はいくらですか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(13)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                北海道・沖縄・離島へのお届けは、別途追加送料が必要です。
              </span>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="question-title">返品・交換について</div>
      <div class="question-box">
        <div class="question-q" :class="{show:show.includes(14)}" @click="changeShow(14)">
          <img src="@/assets/images/pc/question_q.png">
          <span>イメージと違いますが、返品できますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(14)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                ご注文後のご返品、キャンセルについては、商品の破損、不良以外ではご容赦いただいております。<br>
                モニター上では色目を完全に再現することができません。特に天然素材は色調や模様にバラツキがあります。<br>
                イメージ違い、カラー違い、木目の好み、サイズが部屋に合わない、ニオイ、肌触り等の理由でご返品は、対応しかねますのでご了承下さい。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(15)}" @click="changeShow(15)">
          <img src="@/assets/images/pc/question_q.png">
          <span>配送が遅かったのですが、返品できますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(15)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                基本的に各商品ページ通りで順次出荷しておりますが、早く納品したい方は必ずご注文前にご連絡ください。<br>
                ご注文時連絡なければ、サイトに案内した納期を了承済みことと認識しておりますので、その後のキャンセル返品はご対応できません。<br>
                その点をご理解の上ご利用ください。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(16)}" @click="changeShow(16)">
          <img src="@/assets/images/pc/question_q.png">
          <span>商品が破損していた場合、対応して頂けますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(16)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                お届けの際には必ず検品してください。商品に不良のある場合は、配達員に報告の上、商品到着後3日以内に当社までご連絡ください。代替品をお送りする等、適切な対処を致します。<br>
                ※返品・交換の際には商品状態の確認の為、デジカメや携帯で撮影頂いた画像をご提示頂く必要がございます。<br>
                返品・交換の際は、組み上がり状態（商品完成状態）では運送業者が回収できませんので、お手数ですが、分解して再梱包していただく必要がございます。予めご了承下さい。<br>
                （ダンボール・箱等の梱包材は商品状態を確認するまで、破棄しないようにお願い致します。）
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(17)}" @click="changeShow(17)">
          <img src="@/assets/images/pc/question_q.png">
          <span>クーリングオフで返品したい。</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(17)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                インターネットでの売買契約は、通商産業省令が定める「訪問販売に関する法律」の適用範囲内に有り「通信販売」に該当します。従いまして、「通信販売法」により、特定商取引法で規定されているクーリングオフは適用されません。
              </span>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="question-title">マイページについて</div>
      <div class="question-box">
        <div class="question-q" :class="{show:show.includes(18)}" @click="changeShow(18)">
          <img src="@/assets/images/pc/question_q.png">
          <span>領収書はどこから発行できますか？</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(18)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                領収書はすべてネットからの発行になっております。<br>
                「出荷完了のお知らせ」メールに領収書発行できるURLが記載されておりますので、<br>
                そちらにアクセスしていただき、ダウンロード・印刷していただきますようお願い致します。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(19)}" @click="changeShow(19)">
          <img src="@/assets/images/pc/question_q.png">
          <span>ログインできません。</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(19)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                半角に切り替えていただき、再度お試し下さい。また、登録時にメールアドレスが間違っているケースがございますので、その際は「お問い合わせ」よりお問い合わせください。
              </span>
            </div>
          </div>
        </el-collapse-transition>
        <div class="question-q" :class="{show:show.includes(20)}" @click="changeShow(20)">
          <img src="@/assets/images/pc/question_q.png">
          <span>パスワードを忘れた。</span>
        </div>
        <el-collapse-transition>
          <div v-show="show.includes(20)">
            <div class="question-a">
              <img src="@/assets/images/pc/question_a.png">
              <span>
                マイページへのログイン画面の「パスワードを忘れた方はこちら」をクリックしてご確認ください。メールアドレスをご入力いただきました後、当店よりパスワードの再登録用URLをメールでご案内いたしますので、そちらで再登録の手続きををお願いいたします。
              </span>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <div class="bg-content">
      解決されない場合は、こちらよりお問い合わせください。
    </div>
    <div class="bg-title">
      <div class="en">Contact us by email</div>
      <div class="ja">メールでお問い合わせ</div>
    </div>
    <div class="desc">
      下記フォームに必要事項をご記入の上、送信してください。<br><br>
      24時間以内自動送信メールが届かない場合、アドレスの入力間違い、迷惑メール設定の影響が考えられますので、メール環境をご確認の上再度送信してくださいませ。<br><br>
      ※営業関係のお問い合わせはお断りしておりますので、改めてご注意ください。<br><br>
      ※誠に勝手ながら、平日10：00～18：00までの間とさせていただいております。土・日・祝日、休日の受付は、翌営業日にご対応いたします。
    </div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="top">
      <el-form-item prop="type" label="お問い合わせ項目">
        <el-select v-model="ruleForm.type" placeholder="（項目を選んでください）">
          <el-option label="商品について" :value="1" />
          <el-option label="納期について" :value="2" />
          <el-option label="不良交換について" :value="3" />
          <el-option label="サイトについて" :value="4" />
          <el-option label="その他" :value="5" />
        </el-select>
      </el-form-item>
      <el-form-item prop="username" label="お名前">
        <el-input v-model="ruleForm.username" placeholder="お名前" />
      </el-form-item>
      <el-form-item prop="email" label="メールアドレス">
        <el-input v-model="ruleForm.email" placeholder="メールアドレス" />
      </el-form-item>
      <el-form-item prop="content" label="お問い合わせ内容">
        <el-input v-model="ruleForm.content" type="textarea" placeholder="お問い合わせ内容" />
      </el-form-item>
    </el-form>
    <div class="button" @click="onSubmit">送信</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      show: [],
      ruleForm: {
        type: 1,
        username: '',
        email: '',
        content: ''
      },
      rules: {
        type: [{ required: true }],
        username: [{ required: true, message: 'お名前をご入力ください', trigger: 'blur' }],
        email: [
          { required: true, message: 'メールアドレスをご入力ください', trigger: ['blur'] },
          { type: 'email', message: '有効なメールアドレスをご入力ください', trigger: ['blur'] }
        ],
        content: [{ required: true, message: 'お問い合わせ内容をご入力ください', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters([
      'question'
    ])
  },
  created() {
    this.ruleForm = { ...this.ruleForm, ...this.question }
  },
  methods: {
    changeShow(id) {
      if (this.show.includes(id)) {
        this.show.splice(this.show.indexOf(id), 1)
      } else {
        this.show.push(id)
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('config/setQuestion', this.ruleForm)
          this.$router.push({ path: '/guides/question_confirm' })
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;

    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }

    .title-content {
        position: relative;
        width: 100%;
        height: .88rem;
        padding: 0 .24rem;
        margin-top: .36rem;
        margin-bottom: .64rem;
        line-height: .88rem;
        font-size: .32rem;
        font-weight: 700;
        background: linear-gradient(90deg, #f4f5fc 1%, #ffffff 100%);
        box-sizing: border-box;

        img {
            position: absolute;
            right: 0;
            top: -.3rem;
            width: 1.3rem;
            height: 1.3rem;
        }
    }

    .question-content {
        .question-title {
            font-size: .32rem;
            font-weight: 700;
        }

        .question-box {
            border: .02rem solid #e8ebed;
            border-top: 0;
            margin: .24rem 0 .64rem;
        }

        .question-q {
            display: flex;
            align-items: center;
            font-weight: bold;
            padding: .28rem .24rem;
            box-sizing: border-box;
            border-top: .02rem solid #e8ebed;
            background: url('~@/assets/images/sp/question_show.png') right .24rem center / .24rem .24rem no-repeat;
            box-sizing: border-box;

            img {
                width: .4rem;
                height: .4rem;
                margin-right: .16rem;
            }

            span {
                width: 5.34rem;
            }

            &.show {
                background: url('~@/assets/images/sp/question_hide.png') right .24rem center / .24rem .24rem no-repeat;
            }
        }

        .question-a {
            display: flex;
            padding: .3rem .24rem;
            background: #F4F5FC;

            img {
                width: .4rem;
                height: .4rem;
                margin-right: .16rem;
            }

            span {
                line-height: .42rem;
            }
            .table{
                margin-top: .2rem;
                text-align: center;
                td{
                  padding: .1rem;
                  border: .02rem solid #333333;
                }
            }
        }
    }

    .bg-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 1.6rem;
        padding: 0 .24rem;
        background: url('~@/assets/images/sp/guides_question_bg.png') no-repeat center / 100%;
        line-height: .48rem;
        font-size: .32rem;
        box-sizing: border-box;
    }

    .bg-title {
        position: relative;
        height: .68rem;
        margin-top: .74rem;
        background-color: #f4f5fc;

        .en {
            position: absolute;
            left: .24rem;
            top: -.2rem;
            font-size: .36rem;
            font-weight: 700;
        }

        .ja {
            position: absolute;
            left: .24rem;
            bottom: .1rem;
            font-size: .24rem;
        }
    }

    .desc {
        margin-top: .44rem;
        line-height: .42rem;
    }

    .el-form {
        margin-top: .32rem;

        .el-select {
            width: 100%;
        }

        .el-form-item {
            margin-bottom: .32rem;
        }

        ::v-deep .el-textarea__inner {
            height: 2.4rem;
        }
    }

    .button {
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
