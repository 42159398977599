<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <router-link to="/guides/category">ご利用ガイド</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png">
      <span>よくあるご質問</span>
    </div>
    <div class="guides-title">
      <div class="name">guide</div>
      <div class="text">よくあるご質問</div>
    </div>
    <div class="common-question-list">
      <div class="item" @click="handleScroll('scrollTitle1')">
        <div class="title">
          <img src="@/assets/images/pc/common_question_1.png">
          <span>ご注文について</span>
        </div>
        <div class="desc">
          <div>注文完了メールが届きません。</div>
          <div>注文したらどのぐらいものは届くですか？</div>
        </div>
        <div class="tips">
          <span>全てを見る</span>
          <img src="@/assets/images/pc/rightArrowAsh.png">
        </div>
      </div>
      <router-link to="/guides/payment" class="item">
        <div class="title">
          <img src="@/assets/images/pc/common_question_9.png">
          <span>お支払いについて</span>
        </div>
        <div class="desc">
          <div>代引きを使えますか？</div>
          <div>支払い方法を教えてください。</div>
        </div>
        <div class="tips">
          <span>全てを見る</span>
          <img src="@/assets/images/pc/rightArrowAsh.png">
        </div>
      </router-link>
      <router-link to="/guides/delivery" class="item">
        <div class="title">
          <img src="@/assets/images/pc/common_question_3.png">
          <span>配送·送料について</span>
        </div>
        <div class="desc">
          <div>送料を教えてください。</div>
          <div>沖縄県（離島）までの運賃はいくらですか？</div>
        </div>
        <div class="tips">
          <span>全てを見る</span>
          <img src="@/assets/images/pc/rightArrowAsh.png">
        </div>
      </router-link>
      <router-link to="/guides/install" class="item">
        <div class="title">
          <img src="@/assets/images/pc/common_question_8.png">
          <span>搬入・組立について</span>
        </div>
        <div class="desc">
          <div>部屋まで搬入できますか？</div>
          <div>組立をしてくれますか？</div>
        </div>
        <div class="tips">
          <span>全てを見る</span>
          <img src="@/assets/images/pc/rightArrowAsh.png">
        </div>
      </router-link>
      <router-link to="/guides/return" class="item">
        <div class="title">
          <img src="@/assets/images/pc/common_question_7.png">
          <span>返品について</span>
        </div>
        <div class="desc">
          <div>イメージと違いますが、返品したい。</div>
          <div>クーリングオフで返品したい</div>
        </div>
        <div class="tips">
          <span>全てを見る</span>
          <img src="@/assets/images/pc/rightArrowAsh.png">
        </div>
      </router-link>
      <div class="item" @click="handleScroll('scrollTitle2')">
        <div class="title">
          <img src="@/assets/images/pc/common_question_2.png">
          <span>商品について</span>
        </div>
        <div class="desc">
          <div>商品の保証期間はどれぐらいですか？</div>
          <div>カタログが欲しいのですが、送ってもらうことはできますか？</div>
        </div>
        <div class="tips">
          <span>全てを見る</span>
          <img src="@/assets/images/pc/rightArrowAsh.png">
        </div>
      </div>
      <div class="item" @click="handleScroll('scrollTitle3')">
        <div class="title">
          <img src="@/assets/images/pc/common_question_5.png">
          <span>マイページについて</span>
        </div>
        <div class="desc">
          <div>自分でキャンセルできますか？</div>
          <div>領収書はどこから発行できますか？</div>
        </div>
        <div class="tips">
          <span>全てを見る</span>
          <img src="@/assets/images/pc/rightArrowAsh.png">
        </div>
      </div>
      <div class="item-seat" />
    </div>
    <div ref="scrollTitle1" class="question-title">ご注文について</div>
    <div class="question-box">
      <div class="question-q" :class="{show:show.includes(1)}" @click="changeShow(1)">
        <img src="@/assets/images/pc/question_q.png">
        <span>注文完了メールが届きません。</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(1)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              ご注文受付後、自動的に注文受付メールをお送りしておりますが、登録メールアドレスに誤りがある、または ドメイン指定受信や迷惑メールフィルタ機能の設定などにより、メールをお届けできない場合がございます。<br>
              また、お客様のメール受信ボックスがいっぱいの為、メールが受信されないケースが最近増えております。ご確認下さいませ。<br>
              ご購入手続き完了時に14ケタの注文番号が発行されている場合は、ご注文が完了しています。<br>
              重複注文など不安に思われた場合は、<br>
              お問合せフォームまたはカスタマーセンターまでお問い合わせください。<br>
              【カスタマーセンター】047-489-5595（受付時間：10：00～18：00 土・日・祝を除く）
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(2)}" @click="changeShow(2)">
        <img src="@/assets/images/pc/question_q.png">
        <span>注文したらどのぐらいものは届くですか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(2)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              納期は商品により異なりますので、詳しくは各商品ページをご覧ください。<br>
              ご入金を確認後のオーダーとなりますことご了承下さい。<br>
              また、予期せぬ出来事により、さらに長くお待ちいただくこともあります。<br>
              オーダー家具は受注から30-60営業日後の配送となります。<br>
              その他商品は受注から8-10営業日後の配送となります。<br>
              ※年末年始・夏季休暇・ゴールデンウィークなどの特別な時期を除く。<br>
              ※大型商品の場合はチャーター便となるため、通常よりお時間を頂戴しております。 お客様ご注文時のご希望納期を運送会社に伝えたのち、運送会社からの納期調整のご連絡によって最終納期の確定となります。ご注文時点での納期確約ではないので予めご了承ください。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(3)}" @click="changeShow(3)">
        <img src="@/assets/images/pc/question_q.png">
        <span>電話注文はできますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(3)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              お電話でのご注文は受け付けておりません。オンラインショップ・メール・FAXでのみ承っております。ご注文前のご相談は下記の窓口で承っております。お気軽にご連絡ください。<br>
              平日：カスタマーサポート窓口：<br>
              月曜 ~ 金曜日 ／ 時間：10:00 ～18:00 となっております。※土日・祝日以外の営業日においては、通常24時間以内に返信させていただきます。<br>
              ※お問い合わせ内容、混雑等により、回答に日数を要する場合がございます。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(4)}" @click="changeShow(4)">
        <img src="@/assets/images/pc/question_q.png">
        <span>注文後、オーダー変更は可能ですか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(4)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              （ご注文内容の変更）<br>
              送り先の変更など：出荷手続き前であれば対応させていただきます。<br>
              【お問合せ先電話番号】047-489-5595<br>
              （キャンセル）<br>
              基本的にキャンセルはお受けいたしかねますことをご理解くださいませ。<br>
              ただし、ご注文が確定していない場合や、オーダーに入っていない場合はご相談くださいませ。<br>
              １件ご注文当たり数万円のキャンセル手数料を別途でご請求させていただきますので、ご了承くださいますようお願い申し上げます。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(5)}" @click="changeShow(5)">
        <img src="@/assets/images/pc/question_q.png">
        <span>高さや幅などのサイズ変更できますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(5)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              基本的に特注は行っておりません。規定されたサイズのみで販売しております。<br>
              オーダー可能な商品も取り扱いますが、料金と納期は各商品ページでご確認ください。
            </span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div ref="scrollTitle2" class="question-title">商品について</div>
    <div class="question-box">
      <div class="question-q" :class="{show:show.includes(6)}" @click="changeShow(6)">
        <img src="@/assets/images/pc/question_q.png">
        <span>商品の保証期間はどれぐらいですか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(6)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              全品三ヶ月保証となります。お品は到着より三ヶ月以内、通常通りご利用頂いで発生した<br>
              不具合に関してましては、無償にて修理もしくは交換させていだきます。お手数でござい<br>
              ますが、メールにて破損状況の分かるお写真(写メール)と共にご連絡くださいませ。<br>
              3ヶ月以上お使い頂いて発生した不具合については、修理可能でございましたら、有料にて<br>
              修理（都度お見積となります）を承りますので、ご相談くださいませ。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(7)}" @click="changeShow(7)">
        <img src="@/assets/images/pc/question_q.png">
        <span>カタログが欲しいのですが、送ってもらうことはできますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(7)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              当店ではWEBページのみとなり、カタログをご用意しておりません。各商品ページにて詳細などをご確認くださいませ。
            </span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div class="question-title">配送・送料について</div>
    <div class="question-box">
      <div class="question-q" :class="{show:show.includes(8)}" @click="changeShow(8)">
        <img src="@/assets/images/pc/question_q.png">
        <span>配送前に電話してもらえますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(8)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              お客様の代わりに運送業者にお願い致しますが、必ずのお約束はできませんので、予めご了承ください。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(9)}" @click="changeShow(9)">
        <img src="@/assets/images/pc/question_q.png">
        <span>商品の到着日や受け取り時間を指定できますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(9)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              ご注文確定前：ご注文者情報入力ページにて「ご希望お届け予定日」を入力することが可能です。または「備考欄」にその旨入力願います。<br>
              ご注文確定後：既に出荷手続きを完了している場合がございます。まずはお問合せフォーム、もしくはカスタマーセンターまでご連絡願います。<br>
              当店出荷後：「出荷完了のお知らせメール」に記載されております、お荷物お問合せ番号に基づき運輸会社に直接ご連絡をお願い申し上げます。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(10)}" @click="changeShow(10)">
        <img src="@/assets/images/pc/question_q.png">
        <span>不要になった家具を引き取って欲しいのですか。</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(10)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              恐れ入りますが、現在家具の引き取りサービスは行っておりません。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(11)}" @click="changeShow(11)">
        <img src="@/assets/images/pc/question_q.png">
        <span>組み立てはいくらにしますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(11)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              商品により異なりますので、詳しくは各商品ページをご覧ください。<br>
              【組立サービスの料金】 ➡️ 1点 10,000円（税込）<br>
              ※大型家具の場合、お客様にお手伝いをお願いする場合がございます。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(12)}" @click="changeShow(12)">
        <img src="@/assets/images/pc/question_q.png">
        <span>送料を教えてください。</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(12)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              当店では全国一律運賃（離島除く）を採用しておりますので、各商品詳細ページ及び運賃表のページにてご確認いただけます。
              <table class="table">
                <tr>
                  <td>合計が54,000円(税込)未満</td>
                  <td>合計が54,000円(税込)以上</td>
                </tr>
                <tr>
                  <td>送料3,000円</td>
                  <td>送料無料</td>
                </tr>
              </table>
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(13)}" @click="changeShow(13)">
        <img src="@/assets/images/pc/question_q.png">
        <span>沖縄県（離島）までの運賃はいくらですか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(13)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              北海道・沖縄・離島へのお届けは、別途追加送料が必要です。
            </span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div class="question-title">返品・交換について</div>
    <div class="question-box">
      <div class="question-q" :class="{show:show.includes(14)}" @click="changeShow(14)">
        <img src="@/assets/images/pc/question_q.png">
        <span>イメージと違いますが、返品できますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(14)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              ご注文後のご返品、キャンセルについては、商品の破損、不良以外ではご容赦いただいております。<br>
              モニター上では色目を完全に再現することができません。特に天然素材は色調や模様にバラツキがあります。<br>
              イメージ違い、カラー違い、木目の好み、サイズが部屋に合わない、ニオイ、肌触り等の理由でご返品は、対応しかねますのでご了承下さい。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(15)}" @click="changeShow(15)">
        <img src="@/assets/images/pc/question_q.png">
        <span>配送が遅かったのですが、返品できますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(15)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              基本的に各商品ページ通りで順次出荷しておりますが、早く納品したい方は必ずご注文前にご連絡ください。<br>
              ご注文時連絡なければ、サイトに案内した納期を了承済みことと認識しておりますので、その後のキャンセル返品はご対応できません。<br>
              その点をご理解の上ご利用ください。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(16)}" @click="changeShow(16)">
        <img src="@/assets/images/pc/question_q.png">
        <span>商品が破損していた場合、対応して頂けますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(16)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              お届けの際には必ず検品してください。商品に不良のある場合は、配達員に報告の上、商品到着後3日以内に当社までご連絡ください。代替品をお送りする等、適切な対処を致します。<br>
              ※返品・交換の際には商品状態の確認の為、デジカメや携帯で撮影頂いた画像をご提示頂く必要がございます。<br>
              返品・交換の際は、組み上がり状態（商品完成状態）では運送業者が回収できませんので、お手数ですが、分解して再梱包していただく必要がございます。予めご了承下さい。<br>
              （ダンボール・箱等の梱包材は商品状態を確認するまで、破棄しないようにお願い致します。）
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(17)}" @click="changeShow(17)">
        <img src="@/assets/images/pc/question_q.png">
        <span>クーリングオフで返品したい。</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(17)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              インターネットでの売買契約は、通商産業省令が定める「訪問販売に関する法律」の適用範囲内に有り「通信販売」に該当します。従いまして、「通信販売法」により、特定商取引法で規定されているクーリングオフは適用されません。
            </span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div ref="scrollTitle3" class="question-title">マイページについて</div>
    <div class="question-box">
      <div class="question-q" :class="{show:show.includes(18)}" @click="changeShow(18)">
        <img src="@/assets/images/pc/question_q.png">
        <span>領収書はどこから発行できますか？</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(18)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              領収書はすべてネットからの発行になっております。<br>
              「出荷完了のお知らせ」メールに領収書発行できるURLが記載されておりますので、<br>
              そちらにアクセスしていただき、ダウンロード・印刷していただきますようお願い致します。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(19)}" @click="changeShow(19)">
        <img src="@/assets/images/pc/question_q.png">
        <span>ログインできません。</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(19)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              半角に切り替えていただき、再度お試し下さい。また、登録時にメールアドレスが間違っているケースがございますので、その際は「お問い合わせ」よりお問い合わせください。
            </span>
          </div>
        </div>
      </el-collapse-transition>
      <div class="question-q" :class="{show:show.includes(20)}" @click="changeShow(20)">
        <img src="@/assets/images/pc/question_q.png">
        <span>パスワードを忘れた。</span>
      </div>
      <el-collapse-transition>
        <div v-show="show.includes(20)">
          <div class="question-a">
            <img src="@/assets/images/pc/question_a.png">
            <span>
              マイページへのログイン画面の「パスワードを忘れた方はこちら」をクリックしてご確認ください。メールアドレスをご入力いただきました後、当店よりパスワードの再登録用URLをメールでご案内いたしますので、そちらで再登録の手続きををお願いいたします。
            </span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div class="question-desc">解決されない場合は、こちらよりお問い合わせください。</div>
    <question-from />
  </div>
</template>
<script>
import QuestionFrom from '@/views/pc/question/from'
import { scrollTo } from '@/utils/scrollTo'
import { getQueryString } from '@/utils/validate'
export default {
  components: { QuestionFrom },
  data() {
    return {
      show: []
    }
  },
  created() {
    if (getQueryString('from')) {
      this.$nextTick(() => {
        this.handleScroll('scrollTitle1')
      })
    }
  },
  methods: {
    changeShow(id) {
      if (this.show.includes(id)) {
        this.show.splice(this.show.indexOf(id), 1)
      } else {
        this.show.push(id)
      }
    },
    handleScroll(value) {
      scrollTo(this.$refs[value].offsetTop - 140, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
.guides-content{
  width: 1440px;
  font-size: 16px;
  color: #333;
  margin: 0 auto 80px;
  .breadcrumb{
      margin-top: 28px;
  }
  .guides-title{
    position: relative;
    width: 130px;
    height: 44px;
    margin-top: 60px;
    background-color: #f4f5fc;
    .name{
      position: absolute;
      top: -18px;
      left: 14px;
      font-size: 30px;
      font-weight: 700;
    }
    .text{
      position: absolute;
      bottom: 8px;
      left: 16px;
      font-size: 14px;
    }
  }
  .question-title{
    font-size: 24px;
    font-weight: bold;
  }
  .question-box{
    border: 1px solid #E8EBED;
    border-top: 0;
    margin: 16px 0 40px;
  }
  .question-q{
    display: flex;
    align-items: center;
    height: 56px;
    font-weight: bold;
    padding: 0 24px;
    box-sizing: border-box;
    border-top: 1px solid #E8EBED;
    background: url('~@/assets/images/pc/question_show.png') calc(100% - 36px) center no-repeat;
    cursor: pointer;
    img{
      margin-right: 18px;
    }
    &.show{
      background: url('~@/assets/images/pc/question_hide.png') calc(100% - 36px) center no-repeat;
    }
  }
  .question-a{
    display: flex;
    padding: 14px 24px;
    background: #F4F5FC;
    img{
      width: 26px;
      height: 26px;
      margin-right: 18px;
    }
    span{
      line-height: 26px;
    }
     .table{
          margin-top: 20px;
          text-align: center;
          td{
            padding: 10px;
            border: 1px solid #333333;
          }
      }
  }
  .question-desc{
    line-height: 64px;
    font-weight: bold;
    padding: 0 24px;
    background: url('~@/assets/images/pc/question_desc_bg.png') center center / 100% 100%;
    margin: 80px 0 32px;
  }
}
.common-question-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1440px;
  font-size: 16px;
  color: #333;
  margin: 8px auto 80px;
  .item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 464px;
    height: 220px;
    box-sizing: border-box;
    padding: 16px 24px 24px;
    border: 2px solid #333333;
    margin: 24px 0 0;
    cursor: pointer;
  }
  .item-seat{
    width: 464px;
  }
  .title{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 12px;
    border-bottom: 2px solid #333333;
    img{
      margin-right: 8px;
    }
  }
  .desc{
    height: 100%;
    padding-left: 44px;
    div{
      margin-top: 16px;
    }
  }
  .tips{
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-end;
    color: #999999;
    font-size: 14px;
    img{
      margin-left: 5px;
    }
  }
}
</style>
