var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-content", attrs: { id: "order-content" } },
    [
      _c("PaymentHeader"),
      _vm._m(0),
      _c("div", { staticClass: "shop-content" }, [
        _c("div", { staticClass: "left-content" }, [
          _c("div", { staticClass: "message-content" }, [
            _c("div", { staticClass: "list" }, [
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "name" }, [_vm._v("連絡先")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.initData.receiver_email)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "change-btn" },
                [
                  _c("router-link", { attrs: { to: "/order/address" } }, [
                    _vm._v("変更"),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "list" }, [
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "name" }, [_vm._v("配送先")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.initData.first_name + _vm.initData.last_name) +
                      " 様"
                  ),
                  _c("br"),
                  _vm._v(
                    " 〒" +
                      _vm._s(_vm.initData.zip_code) +
                      " " +
                      _vm._s(_vm.initData.stats) +
                      _vm._s(_vm.initData.city) +
                      _vm._s(_vm.initData.address) +
                      _vm._s(
                        _vm.initData.detail_address
                          ? _vm.initData.detail_address
                          : ""
                      )
                  ),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.initData.phone) + " "),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "change-btn" },
                [
                  _c("router-link", { attrs: { to: "/order/address" } }, [
                    _vm._v("変更"),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "list" }, [
              _vm._m(1),
              _c("div", { staticClass: "price" }, [
                _vm._v(
                  "¥" +
                    _vm._s(
                      _vm.calcThousands(_vm.priceParams.freight_amount || 0)
                    )
                ),
              ]),
            ]),
            _c("div", { staticClass: "list" }, [
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "name" }, [_vm._v("備考欄")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.initData.note)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "change-btn" },
                [
                  _c("router-link", { attrs: { to: "/order/address" } }, [
                    _vm._v("変更"),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _vm.token
            ? _c("div", { staticClass: "discount-content" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("ご利用可能なクーポン"),
                ]),
                _c("div", { staticClass: "radio-content" }, [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "radio",
                          attrs: { label: false },
                          on: { change: _vm.handleCoupon },
                          model: {
                            value: _vm.useCoupon,
                            callback: function ($$v) {
                              _vm.useCoupon = $$v
                            },
                            expression: "useCoupon",
                          },
                        },
                        [_c("span", [_vm._v("クーポンを利用しない")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "radio",
                          attrs: { label: true },
                          on: { change: _vm.handleCoupon },
                          model: {
                            value: _vm.useCoupon,
                            callback: function ($$v) {
                              _vm.useCoupon = $$v
                            },
                            expression: "useCoupon",
                          },
                        },
                        [_c("span", [_vm._v("クーポンを選択する")])]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.useCoupon,
                              expression: "useCoupon",
                            },
                          ],
                          staticClass: "discount-detail",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "input-content" },
                            [
                              _c("span", [_vm._v("クーポンを登録する")]),
                              _c("el-input", {
                                attrs: { placeholder: "クーポンコード" },
                                model: {
                                  value: _vm.code,
                                  callback: function ($$v) {
                                    _vm.code =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "code",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.codeLoading,
                                  },
                                  on: { click: _vm.handleGetCoupon },
                                },
                                [_vm._v("登録する")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "coupon-content" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.getPriceShow },
                                  model: {
                                    value: _vm.couponValue,
                                    callback: function ($$v) {
                                      _vm.couponValue = $$v
                                    },
                                    expression: "couponValue",
                                  },
                                },
                                _vm._l(_vm.couponData, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "list" },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: item.id } },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(item.coupon.name)),
                                          ]),
                                          _c("div", { staticClass: "time" }, [
                                            _vm._v(
                                              "【有効期限】" +
                                                _vm._s(item.coupon.end_time)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.token
            ? _c("div", { staticClass: "discount-content" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("ご利用可能なポイント"),
                ]),
                _c("div", { staticClass: "radio-content" }, [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "radio",
                          attrs: { label: 0 },
                          on: { change: _vm.handleNoPoint },
                          model: {
                            value: _vm.usePoint,
                            callback: function ($$v) {
                              _vm.usePoint = $$v
                            },
                            expression: "usePoint",
                          },
                        },
                        [_c("span", [_vm._v("ポイントを利用しない")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "radio",
                          attrs: { label: 1 },
                          on: { change: _vm.getPriceShow },
                          model: {
                            value: _vm.usePoint,
                            callback: function ($$v) {
                              _vm.usePoint = $$v
                            },
                            expression: "usePoint",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v("利用可能なポイントをすべて利用する："),
                            _c("b", [_vm._v(_vm._s(_vm.totalPoint))]),
                            _vm._v(
                              "（￥" +
                                _vm._s(_vm.calcThousands(_vm.totalPointFee)) +
                                "相当）"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "radio",
                          attrs: { label: 2 },
                          on: { change: _vm.getPriceShow },
                          model: {
                            value: _vm.usePoint,
                            callback: function ($$v) {
                              _vm.usePoint = $$v
                            },
                            expression: "usePoint",
                          },
                        },
                        [
                          _c("span", [_vm._v("一部のポイントを利用する")]),
                          _c("el-input", {
                            attrs: { placeholder: "ポイントを" },
                            on: {
                              focus: _vm.handleFocus,
                              input: _vm.changePoint,
                            },
                            model: {
                              value: _vm.pointValue,
                              callback: function ($$v) {
                                _vm.pointValue = _vm._n($$v)
                              },
                              expression: "pointValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "payment-content" }, [
            _c("div", { staticClass: "title" }, [_vm._v("お支払い方法")]),
            _c("div", { staticClass: "desc" }, [
              _vm._v("入力された情報はすべて暗号化され、安全に転送されます。"),
            ]),
            _c("div", { staticClass: "radio-content" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 3 },
                      model: {
                        value: _vm.ruleForm.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pay_type", $$v)
                        },
                        expression: "ruleForm.pay_type",
                      },
                    },
                    [_c("span", [_vm._v("クレジットカード決済")])]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ruleForm.pay_type === 3,
                          expression: "ruleForm.pay_type === 3",
                        },
                      ],
                      staticClass: "payment-desc",
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "blackcatForm",
                          attrs: { model: _vm.blackcatForm, rules: _vm.rules },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "credit_email", label: "" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "メールアドレス" },
                                model: {
                                  value: _vm.blackcatForm.credit_email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.blackcatForm,
                                      "credit_email",
                                      $$v
                                    )
                                  },
                                  expression: "blackcatForm.credit_email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "credit_number", label: "" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "カード番号" },
                                model: {
                                  value: _vm.blackcatForm.credit_number,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.blackcatForm,
                                      "credit_number",
                                      $$v
                                    )
                                  },
                                  expression: "blackcatForm.credit_number",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "credit_username", label: "" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "カード名義人" },
                                model: {
                                  value: _vm.blackcatForm.credit_username,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.blackcatForm,
                                      "credit_username",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "blackcatForm.credit_username",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "date", label: "" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "MM/YY" },
                                    model: {
                                      value: _vm.blackcatForm.date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.blackcatForm, "date", $$v)
                                      },
                                      expression: "blackcatForm.date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "credit_security_code",
                                    label: "",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "セキュリティコード",
                                    },
                                    model: {
                                      value:
                                        _vm.blackcatForm.credit_security_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.blackcatForm,
                                          "credit_security_code",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "blackcatForm.credit_security_code",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "credit_count", label: "" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "お支払い回数" },
                                  model: {
                                    value: _vm.blackcatForm.credit_count,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.blackcatForm,
                                        "credit_count",
                                        $$v
                                      )
                                    },
                                    expression: "blackcatForm.credit_count",
                                  },
                                },
                                _vm._l(_vm.payCount, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item, value: item },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 9 },
                      model: {
                        value: _vm.ruleForm.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pay_type", $$v)
                        },
                        expression: "ruleForm.pay_type",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "rakuten-icon",
                        attrs: {
                          src: require("@/assets/images/pc/rakuten.png"),
                          alt: "",
                        },
                      }),
                      _c("span", { staticClass: "pay-name" }, [
                        _vm._v("楽天ペイ"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ruleForm.pay_type === 9,
                          expression: "ruleForm.pay_type === 9",
                        },
                      ],
                      staticClass: "payment-desc",
                    },
                    [_vm._m(2)]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item amazon" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 4 },
                      model: {
                        value: _vm.ruleForm.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pay_type", $$v)
                        },
                        expression: "ruleForm.pay_type",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/pc/amazon.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ruleForm.pay_type === 4,
                          expression: "ruleForm.pay_type === 4",
                        },
                      ],
                      staticClass: "payment-desc",
                    },
                    [
                      _vm.amazonToken
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                margin: "0 auto",
                                "margin-bottom": "40px",
                              },
                            },
                            [
                              _c("div", {
                                staticStyle: {
                                  height: "400px",
                                  width: "400px",
                                  "margin-right": "20px",
                                },
                                attrs: { id: "addressBookWidgetDiv" },
                              }),
                              _c("div", {
                                staticStyle: {
                                  width: "500px",
                                  height: "400px",
                                },
                                attrs: { id: "walletWidgetDiv" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._m(3),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 8 },
                      model: {
                        value: _vm.ruleForm.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "pay_type", $$v)
                        },
                        expression: "ruleForm.pay_type",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/pc/paypay.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ruleForm.pay_type === 8,
                          expression: "ruleForm.pay_type === 8",
                        },
                      ],
                      staticClass: "payment-desc",
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "paypayForm",
                          attrs: { model: _vm.paypayForm, rules: _vm.rules },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "buyer_name_kanji" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "お名前を" },
                                model: {
                                  value: _vm.paypayForm.buyer_name_kanji,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paypayForm,
                                      "buyer_name_kanji",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "paypayForm.buyer_name_kanji",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "buyer_tel" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "電話番号",
                                },
                                model: {
                                  value: _vm.paypayForm.buyer_tel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paypayForm,
                                      "buyer_tel",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "paypayForm.buyer_tel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "buyer_email" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "メールアドレス" },
                                model: {
                                  value: _vm.paypayForm.buyer_email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paypayForm,
                                      "buyer_email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "paypayForm.buyer_email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.payBank
                ? _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.ruleForm.pay_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "pay_type", $$v)
                            },
                            expression: "ruleForm.pay_type",
                          },
                        },
                        [_c("span", [_vm._v("銀行振込・前払い")])]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleForm.pay_type === 2,
                              expression: "ruleForm.pay_type === 2",
                            },
                          ],
                          staticClass: "payment-desc",
                        },
                        [_vm._m(4), _vm._m(5), _vm._m(6)]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "right-content" }, [
          _c(
            "div",
            { staticClass: "sticky" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v("買い物カート内の商品"),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("div", { staticClass: "item font16" }, [
                  _c("div", [_vm._v("送料")]),
                  _c("div", [
                    _vm._v(
                      "+￥" +
                        _vm._s(
                          _vm.calcThousands(_vm.priceParams.freight_amount || 0)
                        )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item font16" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("クーポン")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "-￥" +
                        _vm._s(_vm.calcThousands(_vm.priceParams.use_point_fee))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item font16" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("利用ポイント")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      "-￥" +
                        _vm._s(
                          _vm.calcThousands(_vm.priceParams.coupon_amount || 0)
                        )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", [_vm._v("合計")]),
                  _c("div", { staticClass: "price" }, [
                    _vm._v(
                      "￥" +
                        _vm._s(_vm.calcThousands(_vm.priceParams.pay_amount))
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "next-btn",
                  on: { click: _vm.handleConfirmShow },
                },
                [_vm._v("ご注文を確定する")]
              ),
              _c("router-link", { attrs: { to: "/order/address" } }, [
                _c("div", { staticClass: "light-btn" }, [_vm._v("配送に戻る")]),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.confirmVisible,
              expression: "confirmVisible",
            },
          ],
          staticClass: "overlay",
        },
        [
          _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("ご注文確定前のお願い"),
              ]),
              _vm._m(7),
              _vm._m(8),
              _c(
                "div",
                { staticClass: "text" },
                [
                  _vm._v(" 同意する(Yes)をクリックすることで"),
                  _c("br"),
                  _c("router-link", { attrs: { to: "/privacy" } }, [
                    _vm._v("プライバシーポリシー"),
                  ]),
                  _vm._v("に同意したことになります。 "),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "button-content" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary button",
                        loading: _vm.btnLoading,
                      },
                      on: { click: _vm.handleConfirmPay },
                    },
                    [_vm._v("同意する（Yes）")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button cancel-btn",
                      on: {
                        click: function ($event) {
                          _vm.confirmVisible = false
                        },
                      },
                    },
                    [_vm._v("同意しない（NO）")]
                  ),
                ],
                1
              ),
              _c("img", {
                staticClass: "close-btn",
                attrs: { src: require("@/assets/images/pc/x.png") },
                on: {
                  click: function ($event) {
                    _vm.confirmVisible = false
                  },
                },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-content" }, [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step1_border.png") },
          }),
          _c("div", [_vm._v("カート")]),
        ]),
        _c("img", {
          staticClass: "step-line1",
          attrs: { src: require("@/assets/images/pc/step_line1_in.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step2_border.png") },
          }),
          _c("div", [_vm._v("配送")]),
        ]),
        _c("img", {
          staticClass: "step-line2",
          attrs: { src: require("@/assets/images/pc/step_line2_in.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step3_in.png") },
          }),
          _c("div", [_vm._v("支払い")]),
        ]),
        _c("img", {
          staticClass: "step-line1",
          attrs: { src: require("@/assets/images/pc/step_line1.png") },
        }),
        _c("div", { staticClass: "item" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/pc/step4.png") },
          }),
          _c("div", [_vm._v("注文完了")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "name" }, [_vm._v("配送方法")]),
      _c("div", { staticClass: "value" }, [_vm._v("通常出荷")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rakuten-content" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/pc/rakuten_pay_icon.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          " いつもの楽天会員IDとパスワードを使ってスムーズなお支払いが可能です。"
        ),
        _c("br"),
        _vm._v(
          " 楽天ポイントが貯まる・使える！「簡単」「あんしん」「お得」な楽天ペイをご利用ください。"
        ),
        _c("br"),
        _vm._v(
          " ※楽天ポイントが貯まるのは楽天カード・楽天ポイント・楽天キャッシュでのお支払いに限ります。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(
        " Amazonに登録された配送先情報やクレジットカード情報を利用してお買い物できます。"
      ),
      _c("br"),
      _vm._v(
        " 右のボタンをクリックして、Amazonのユーザー名とパスワードでログインする"
      ),
      _c("br"),
      _vm._v(" 必要がございます。"),
      _c("br"),
      _vm._v(
        " 登録ボダンが表示されない場合ば、ページを再読み込みしてください。"
      ),
      _c("br"),
      _vm._v(
        " Amazon Pay（アマゾンペイ）の閲覧環境は、以下のシステムとブラウザにて正しく動作"
      ),
      _c("br"),
      _vm._v(" するよう設計されております。"),
      _c("br"),
      _c("br"),
      _vm._v(" [PC]"),
      _c("br"),
      _vm._v(" Microsoft Windows 7以降"),
      _c("br"),
      _vm._v(" Mac OS X v.10.11以降"),
      _c("br"),
      _vm._v(" [スマートフォン／タブレット]"),
      _c("br"),
      _vm._v(" Android 7.0以降"),
      _c("br"),
      _vm._v(" Apple iOS 11.2以降"),
      _c("br"),
      _vm._v(" 推奨ブラウザ"),
      _c("br"),
      _vm._v(" [PC]"),
      _c("br"),
      _vm._v(" Firefox バージョン 70.0以降 (Windowsのみ)"),
      _c("br"),
      _vm._v(
        " ※その他のブラウザは、はんこプレミアムのオンラインストアの閲覧環境に基づきます。 "
      ),
      _c("div", { attrs: { id: "AmazonPayButton" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" ご注文後7営業日以内お振込みくださいませ。"),
      _c("br"),
      _vm._v(" ご入金が確認でき次第商品手配とさせていただきます。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-content" }, [
      _c("table", { staticClass: "table" }, [
        _c("tr", [
          _c("td", [_vm._v("銀行名")]),
          _c("td", [_vm._v("三井住友銀行")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("支店名")]),
          _c("td", [_vm._v("船橋支店")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("口座番号")]),
          _c("td", [_vm._v("7154834（普）")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("口座名義")]),
          _c("td", [_vm._v("ハンコプレミアム（カ")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("振込み手数料")]),
          _c("td", [_vm._v("お客様ご負担")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("お支払期限")]),
          _c("td", [_vm._v("ご注文から7営業日まで")]),
        ]),
      ]),
      _c("table", { staticClass: "table" }, [
        _c("tr", [
          _c("td", [_vm._v("銀行名")]),
          _c("td", [_vm._v("ゆうちょ銀行")]),
        ]),
        _c("tr", [_c("td", [_vm._v("記号")]), _c("td", [_vm._v("10580")])]),
        _c("tr", [
          _c("td", [_vm._v("番号")]),
          _c("td", [_vm._v("7154834（普）")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("口座名義")]),
          _c("td", [_vm._v("ハンコプレミアム（カ")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("振込み手数料")]),
          _c("td", [_vm._v("お客様ご負担")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("お支払期限")]),
          _c("td", [_vm._v("ご注文から7営業日まで")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" <他行から振り込みの場合>"),
      _c("br"),
      _vm._v(" 【店 名】058 ゼロゴハチ"),
      _c("br"),
      _vm._v(" 【口座番号】3702567"),
      _c("br"),
      _vm._v(" ******************************************** "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" ▼搬入経路は問題ございませんか？▼"),
      _c("br"),
      _vm._v("※搬入か可能か事前にご確認をお願い致します。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _vm._v(" ご注文確定後の変更・キャンセルはできませんので、"),
      _c("br"),
      _vm._v("よくご確認の上ご注文をお願いいたします。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }