<template>
  <div>
    <div class="tips">
      <router-link to="/">家具TOP</router-link>
      <span> / </span>
      <span v-for="(item,index) in parentsData" :key="index">
        <router-link v-if="index!==parentsData.length-1" :to="item.url">
          <span class="crumb-a">{{ item.name }}</span>
        </router-link>
        <span v-if="index!==parentsData.length-1"> / </span>
        <span v-if="index===parentsData.length-1" class="crumb-span">{{ item.name }}</span>
      </span>
    </div>
    <div ref="productSwiper" class="swiper product-swiper">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in productData.galleries" :key="index" class="swiper-slide">
          <img width="100%" :src="item">
        </div>
      </div>
    </div>
    <div v-show="floatBtnShow" class="suspension-button-content">
      <div class="item" :class="{active: current === 1}" @click="handleScroll('productDesc',1)">製品詳細</div>
      <div v-if="producCommentsData.length" class="item" :class="{active: current === 2}" @click="handleScroll('comment',2)">レビュー</div>
      <div v-if="productData.product.length" class="item" :class="{active: current === 3}" @click="handleScroll('relation',3)">関連商品</div>
    </div>
    <div class="swiper product-swiper-thumbs">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in productData.galleries" :key="index" class="swiper-slide">
          <img :src="item">
        </div>
      </div>
    </div>
    <div class="product-introduce">
      <div class="price-content">
        <div class="now">
          <div class="price">￥<span class="value">{{ productData.promotion.length > 0 && productData.promotion[0].is_open ? calcThousands(productData.discount_price) : calcThousands(productData.price) }}</span>(税込)</div>
          <div v-if="productData.promotion.length > 0 && productData.promotion[0].is_open" class="off">{{ productData.promotion[0].discount }}%OFF</div>
        </div>
        <div v-if="productData.promotion.length > 0 && productData.promotion[0].is_open" class="original">
          ￥{{ calcThousands(productData.price) }}円
        </div>
      </div>
      <div class="label">
        <div v-for="(item,index) in productData.tag" :key="index">{{ item.name }}</div>
      </div>
      <div class="sn">商品コード：{{ productData.product_sn }}</div>
      <div class="title">{{ productData.title }}</div>
      <div v-if="producCommentsData.length" class="rate" @click="commentScroll()">
        <span>{{ productData.average_star || 5 }}</span>
        <el-rate :value="productData.average_star || 5" allow-half disabled />
      </div>
    </div>
    <!-- <div class="product-attribute">
        <div v-for="(item,index) in productData.attribute" :key="index" class="attribute-box">
          <div class="attribute-title">{{ item.name }}<span>必須</span></div>
          <div class="attribute-item">
            <div
              v-for="(it,ind) in item.attribute_value"
              :key="ind"
              class="attribute-item-box"
              :class="{active:it.id === skuChecked[index].id}"
              :title="it.value"
              @click="changeSku(index,it)"
            >
              <div v-if="it.icon" class="img-box">
                <img :src="it.icon" :alt="it.value">
              </div>
              <div v-else class="text-box">{{ it.value }}</div>
            </div>
          </div>
        </div>
        <div v-for="(item,index) in productData.norm" :key="'n' + index" class="attribute-box">
          <div class="attribute-title">{{ item.name }}<span v-if="item.is_required">必須</span></div>
          <div class="attribute-item">
            <div
              v-for="(it,ind) in item.norm_values"
              :key="ind"
              class="attribute-item-box"
              :class="{active:norm_values.includes(it.id)}"
              :title="it.name"
              @click="changeNorm(item,it)"
            >
              <div v-if="it.image" class="img-box">
                <img :src="it.image" :alt="it.name">
              </div>
              <div v-else class="text-box">{{ it.name + '＋￥' + calcThousands(it.price) + '円' }}</div>
            </div>
          </div>
        </div>
      </div> -->
    <div class="checked-attribute-content">
      <div @click="attributeShow = true">
        <div class="name">
          <span>選択された仕様</span>
          <img src="@/assets/images/sp/right_arrow1.png">
        </div>
        <div class="checked-value">{{ checkedAttribute }}</div>
      </div>
      <div class="image-content">
        <div v-for="(item,index) in productData.attribute" :key="index" class="item">
          <div v-for="(value, index2) in item.attribute_value" :key="index2" class="value" :class="{active:value.id === skuChecked[index].id}" @click="changeSku(index,value)">
            <div v-if="value.icon" class="img-box">
              <img :src="value.icon" :alt="value.value">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desc-content">
      <div class="text1">
        <span>受注品 / 納期60日後</span>
        <span>在庫品 / 納期10日後</span>
      </div>
      <div class="text2">購入金額 {{ calcThousands(config.free_shipping_amount) }}円以上 送料無料</div>
      <div class="text3">※北海道・沖縄・鹿児島県・離島へのお届けは、別途追加送料が必要です。</div>
    </div>
    <div class="product-desc">
      <div class="product-desc-title" @click="descShow = !descShow">
        <span ref="productDesc">製品詳細</span>
        <img v-if="descShow" src="@/assets/images/sp/article_hide.png">
        <img v-else src="@/assets/images/sp/article_open.png">
      </div>
      <el-collapse-transition>
        <div v-show="descShow">
          <div class="product-desc-content" v-html="productData.mobile_desc" />
        </div>
      </el-collapse-transition>
    </div>
    <div v-show="producCommentsData.length" ref="comment" class="product-desc product-comment">
      <div class="product-anchor" />
      <div class="product-desc-title" @click="commentShow = !commentShow">
        <span>レビュー（{{ productData.average_star }}）</span>
        <img v-if="commentShow" src="@/assets/images/sp/article_hide.png">
        <img v-else src="@/assets/images/sp/article_open.png">
      </div>
      <el-collapse-transition>
        <div v-show="commentShow" class="evaluate-box">
          <template v-for="(item,index) in producCommentsData">
            <div v-if="index < 3" :key="index" class="evaluate-item">
              <div class="evaluate-box">
                <div class="name">{{ item.user_stats }}user</div>
                <div class="stars">{{ item.stars }}</div>
              </div>
              <div class="evaluate-box">
                <div class="date">{{ item.created_at }}</div>
                <el-rate :value="item.stars" allow-half disabled />
              </div>
              <div
                v-if="item.images.length > 0"
                class="image-content"
              >
                <div class="box" :class="item.images.length === 1 ? 'one': item.images.length === 2 ? 'two' : item.images.length === 3 ? 'three' : item.images.length === 4 ? 'four': ''">
                  <img
                    v-for="(value,key) in item.images"
                    :key="key"
                    :src="value"
                    @click="handleImagePreview(item.images,key)"
                  >
                </div>
              </div>
              <div class="desc">{{ item.content }}</div>
            </div>
          </template>
          <el-collapse-transition>
            <div v-show="commentTotalShow">
              <template v-for="(item,index) in producCommentsData">
                <div v-if="index > 2" :key="index" class="evaluate-item">
                  <div class="evaluate-box">
                    <div class="name">{{ item.user_stats }}user</div>
                    <div class="stars">{{ item.stars }}</div>
                  </div>
                  <div class="evaluate-box">
                    <div class="date">{{ item.created_at }}</div>
                    <el-rate :value="item.stars" allow-half disabled />
                  </div>
                  <div
                    v-if="item.images.length > 0"
                    class="image-content"
                  >
                    <div class="box" :class="item.images.length === 1 ? 'one': item.images.length === 2 ? 'two' : item.images.length === 3 ? 'three' : item.images.length === 4 ? 'four': ''">
                      <img
                        v-for="(value,key) in item.images"
                        :key="key"
                        :src="value"
                        @click="handleImagePreview(item.images,key)"
                      >
                    </div>
                  </div>
                  <div class="desc">{{ item.content }}</div>
                </div>
              </template>
            </div>
          </el-collapse-transition>
          <div v-show="producCommentsData.length > 3 " class="view-more" @click="commentTotalShow = !commentTotalShow">{{ commentTotalShow ? '閉じる' : 'view more' }}</div>
        </div>
      </el-collapse-transition>
    </div>
    <div class="label-keywords-content">
      <router-link v-for="(item,index) in productData.keywordTag" :key="index" :to="`/search?input=${item.name}&page=1`" class="item"># {{ item.name }}</router-link>
    </div>
    <div v-show="productData.product.length" ref="relation">
      <div class="view-title">関連商品</div>
      <div class="swiper relation-swiper">
        <div class="swiper-wrapper">
          <router-link v-for="(item,index) in productData.product" :key="index" :to="'/product/'+ item.product_sn" class="swiper-slide">
            <img width="100%" :src="item.image">
            <div class="product-desc">
              <div class="product-name">{{ item.title }}</div>
              <div class="price">￥{{ calcThousands(item.price) }}<span>(税込)</span></div>
            </div>
          </router-link>
        </div>
        <div class="swiper-pagination" />
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
      </div>
    </div>
    <div class="link-box">
      <router-link to="/new/product">
        <div>NEW ITEM</div>
        新商品一覧
      </router-link>
      <router-link to="">
        <div>SHORT PERIOD</div>
        短納期商品一覧
      </router-link>
    </div>
    <form action="javascript:return false" @submit="search">
      <input ref="search" v-model="input" class="search" type="search" placeholder="キーワードで検索">
    </form>
    <mail :confirm-visible.sync="confirmVisible" />
    <div class="product-cart">
      <div class="collection" @click="collect()">
        <img v-if="productData.is_collected" src="@/assets/images/sp/collection_active.png">
        <img v-else src="@/assets/images/sp/collection.png">
        <div>{{ productData.collects_count + productData.collect_count }}</div>
      </div>
      <div v-if="productData.is_on_sale && sku_id" class="add-cart" @click="attributeShow = true">
        <img src="@/assets/images/sp/add-cart.png">
        <span>カートに入れる</span>
      </div>
      <div v-else class="add-cart" @click="confirmVisible = true">メールで入荷通知を受取る</div>
    </div>
    <van-popup v-model="attributeShow" position="bottom" class="fixed-attribute-content">
      <div class="top-content">
        <img :src="imageUrl" :alt="productData.image_alt" class="image">
        <div class="detail">
          <div class="price">￥<span class="value">{{ productData.promotion.length > 0 && productData.promotion[0].is_open ? calcThousands(productData.discount_price) : calcThousands(productData.price) }}</span>(税込)</div>
          <div class="original">
            <span v-if="productData.promotion.length > 0 && productData.promotion[0].is_open">
              ￥{{ calcThousands(productData.price) }}円
            </span>
          </div>
          <div class="checked-attribute">{{ checkedAttribute }}</div>
        </div>
        <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="attributeShow = false">
      </div>
      <div class="mid-content">
        <div class="product-attribute">
          <div v-for="(item,index) in productData.attribute" :key="index" class="attribute-box">
            <div class="attribute-title">{{ item.name }}<span>必須</span></div>
            <div class="attribute-item">
              <div
                v-for="(it,ind) in item.attribute_value"
                :key="ind"
                class="attribute-item-box"
                :class="{active:it.id === skuChecked[index].id}"
                :title="it.value"
                @click="changeSku(index,it)"
              >
                <div v-if="it.icon" class="img-box">
                  <img :src="it.icon" :alt="it.value">
                </div>
                <div v-else class="text-box">{{ it.value }}</div>
              </div>
            </div>
          </div>
          <div v-for="(item,index) in productData.norm" :key="'n' + index" class="attribute-box">
            <div class="attribute-title">{{ item.name }}<span v-if="item.is_required">必須</span></div>
            <div class="attribute-item">
              <div
                v-for="(it,ind) in item.norm_values"
                :key="ind"
                class="attribute-item-box"
                :class="{active:norm_values.includes(it.id)}"
                :title="it.name"
                @click="changeNorm(item,it)"
              >
                <div v-if="it.image" class="img-box">
                  <img :src="it.image" :alt="it.name">
                </div>
                <div v-else class="text-box">{{ it.name + '＋￥' + calcThousands(it.price) + '円' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-button type="primary" class="bottom-content" :loading="btnLoading" @click="toCart">
        <img src="@/assets/images/sp/add-cart.png">
        <span>カートに入れる￥{{ productData.promotion.length > 0 && productData.promotion[0].is_open ? calcThousands(productData.discount_price) : calcThousands(productData.price) }}(税込)</span>
      </el-button>
      <!-- <div class="bottom-content" @click="toCart">
      </div> -->
    </van-popup>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { scrollTo } from '@/utils/scrollTo'
import { productDetail, producComments, addCart, productCollect, getProductCrumb } from '@/api/product'
import mail from '@/views/sp/layout/mail'
import { calcThousands, parameterCorrection } from '@/utils/validate'
export default {
  components: { mail },
  data() {
    return {
      btnLoading: false,
      floatBtnShow: false,
      descShow: false,
      commentShow: false,
      commentTotalShow: false,
      attributeShow: false,
      product_id: null,
      confirmVisible: false,
      title: null,
      keywords: null,
      description: null,
      input: '',
      current: '',
      imageUrl: '',
      productData: {
        product: [],
        price: 0,
        collects_count: 0,
        collect_count: 0,
        is_collected: false,
        promotion: []
      },
      imageData: [],
      parentsData: [],
      producCommentsData: [],
      sku_id: '',
      sku_code: '',
      skuChecked: [],
      norm_values: [],
      attribute_value_ids: [],
      checkedAttribute: ''
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.keywords },
        { vmid: 'description', name: 'description', content: this.description }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'config'
    ]),
    productSn: function() {
      return this.$route.params.productSn
    }
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.sku_id = ''
        this.sku_code = ''
        this.skuChecked = []
        this.norm_values = []
        this.attribute_value_ids = []
        this.productDetail().then(() => {
          this.getProductCrumb()
        })
      }
    }
  },
  mounted() {
    this.productDetail().then(() => {
      this.getProductCrumb()
    })
    window.addEventListener('scroll', this.windowScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.windowScroll)
  },
  methods: {
    calcThousands,
    handleScroll(value, index) {
      let top = this.$refs[value].getBoundingClientRect().top + document.documentElement.scrollTop
      if (value === 'productDesc') {
        top = top - 80
      } else if (value === 'comment') {
        top = top - 50
      }
      scrollTo(top, 800)
      // this.current = index
    },
    search(e) {
      this.$router.push({ path: '/search', query: { input: this.input }})
      this.$refs.search.blur()
      e.preventDefault()
    },
    // 轮播
    swiper() {
      new window.Swiper('.product-swiper', {
        loop: true,
        thumbs: {
          swiper: {
            el: '.product-swiper-thumbs',
            slidesPerView: 'auto'
          }
        }
      })
      new window.Swiper('.relation-swiper', {
        slidesPerView: 'auto',
        pagination: {
          type: 'progressbar',
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    },
    // 获取商品详情
    productDetail() {
      return productDetail(this.productSn, { include: 'tag,sku,attribute,attribute.attributeValue,norm,norm.normValues,product,promotion' }).then((result) => {
        this.product_id = result.id
        this.title = result.seo_title
        this.keywords = result.seo_keywords
        this.description = result.seo_description
        result.tag = result.tag.filter(item => {
          return item.is_detail
        })
        result.attribute.forEach(item => {
          this.skuChecked.push(item.attribute_value.length > 0 ? item.attribute_value[0] : '')
        })
        result.norm.forEach(item => {
          if (item.is_required && item.norm_values.length > 0) {
            this.norm_values.push(item.norm_values[0].id)
          }
        })
        this.productData = result
        const keywordArr = []
        this.productData.tag.forEach(item => {
          if (item.is_keyword) {
            keywordArr.push(item)
          }
        })
        this.$set(this.productData, 'keywordTag', keywordArr)
        this.imageUrl = result.image
        const arr = []
        this.productData.attribute.forEach(item => {
          item.attribute_value.forEach(item2 => {
            if (item2.icon) {
              arr.push(item)
            }
          })
        })
        this.imageData = []
        const uniqueAttributeNames = []

        arr.forEach(attribute => {
          if (!uniqueAttributeNames.includes(attribute.id)) {
            this.imageData.push(attribute)
            uniqueAttributeNames.push(attribute.id)
          }
        })
        this.totalPrice()
        this.producComments()
        this.$nextTick(() => {
          this.swiper()
        })
      })
    },
    getProductCrumb() {
      getProductCrumb(this.productSn).then(result => {
        this.parentsData = []
        result.data.forEach(item => {
          this.parentsData.push({ name: item.name, url: '/category/' + item.url })
        })
        this.parentsData.push({ name: this.productData.title, url: '' })
      })
    },
    // 获取商品评论
    producComments() {
      const data = parameterCorrection({ is_show: true })
      producComments(this.product_id, { type: 'all', ...data }).then((result) => {
        this.producCommentsData = result.data
        this.producCommentsData.forEach(item => {
          item.images = item.images === null ? [] : item.images
        })
        this.total = result.meta.total
      })
    },
    handleImagePreview(arr, index) {
      this.$ImagePreview({
        images: arr,
        startPosition: index
      })
    },
    windowScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const fixedBtnScrollTop = document.getElementsByClassName('product-swiper-thumbs')[0].offsetTop
      if (scrollTop > fixedBtnScrollTop && !this.floatBtnShow) {
        this.floatBtnShow = true
      }
      if (scrollTop < fixedBtnScrollTop && this.floatBtnShow) {
        this.floatBtnShow = false
      }

      // 滑动选中状态
      const productDescTop = this.$refs.productDesc.offsetTop - 120
      const commentTop = this.$refs.comment.offsetTop - 60
      const relationTop = this.$refs.relation.offsetTop - 120
      if (commentTop > 0) {
        if (scrollTop >= productDescTop && scrollTop < commentTop) {
          this.current = 1
        } else if (scrollTop >= commentTop && scrollTop < relationTop) {
          this.current = 2
        } else if (scrollTop >= relationTop) {
          this.current = 3
        } else {
          this.current = ''
        }
      } else {
        if (scrollTop >= productDescTop && scrollTop < relationTop) {
          this.current = 1
        } else if (scrollTop >= relationTop) {
          this.current = 3
        } else {
          this.current = ''
        }
      }
    },
    // 改变 SKU
    changeSku(index, val) {
      this.skuChecked.splice(index, 1, val)
      this.totalPrice()
      this.imageUrl = val.image
    },
    // 改变 服务
    changeNorm(norm, val) {
      if (norm.is_required && norm.choose_type === 1) { // 必选单选
        norm.norm_values.forEach(item => {
          if (this.norm_values.indexOf(item.id) !== -1) this.norm_values.splice(this.norm_values.indexOf(item.id), 1)
        })
        this.norm_values.push(val.id)
      } else if (norm.is_required && norm.choose_type === 2) { // 必选多选
        if (this.norm_values.indexOf(val.id) !== -1) {
          const arr = norm.norm_values.map(item => this.norm_values.indexOf(item.id) !== -1)
          if (arr.length > 1) { this.norm_values.splice(this.norm_values.indexOf(val.id), 1) }
        } else {
          this.norm_values.push(val.id)
        }
      } else if (!norm.is_required && norm.choose_type === 1) { // 非必选单选
        if (this.norm_values.indexOf(val.id) !== -1) {
          this.norm_values.splice(this.norm_values.indexOf(val.id), 1)
        } else {
          norm.norm_values.forEach(item => {
            if (this.norm_values.indexOf(item.id) !== -1) this.norm_values.splice(this.norm_values.indexOf(item.id), 1)
          })
          this.norm_values.push(val.id)
        }
      } else if (!norm.is_required && norm.choose_type === 2) { // 非必选多选
        if (this.norm_values.indexOf(val.id) !== -1) {
          this.norm_values.splice(this.norm_values.indexOf(val.id), 1)
        } else {
          this.norm_values.push(val.id)
        }
      }
      this.totalPrice()
    },
    // 计算价格
    totalPrice() {
      let price = 0
      let discountPrice = 0
      const value = this.skuChecked.map(item => item.value).join(',')
      this.checkedAttribute = this.skuChecked.map(item => item.value).join('/')
      this.productData.sku.forEach(item => {
        if (value === item.sku_value) {
          this.sku_code = item.sku_code
          this.sku_id = ''
          this.attribute_value_ids = ''
          if (item.stock > 0) {
            this.sku_id = item.id
            this.attribute_value_ids = this.skuChecked.map(item => item.id)
          }
          price += parseInt(item.price)
          discountPrice += parseInt(item.price)
        }
      })
      if (this.productData.promotion.length > 0) {
        discountPrice = Math.ceil(price * (100 - this.productData.promotion[0].discount) / 100)
        if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
          discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
        }
      }
      this.productData.norm.forEach(norm => {
        norm.norm_values.forEach(value => {
          if (this.norm_values.indexOf(value.id) !== -1) {
            this.checkedAttribute += `/${value.name}＋￥${value.price}円`
            price += parseInt(value.price)
            discountPrice += parseInt(value.price)
          }
        })
      })
      this.productData.price = price
      this.$set(this.productData, 'discount_price', discountPrice)
    },
    // 商品收藏
    collect() {
      productCollect(this.productSn).then(() => {
        this.productData.is_collected = !this.productData.is_collected
        productDetail(this.productSn).then((result) => {
          this.productData.collects_count = result.collects_count
        })
      })
    },
    // 加入购物车
    toCart() {
      const data = {
        sku_code: this.sku_code,
        number: 1,
        norm_values: this.norm_values,
        attribute_value_ids: this.attribute_value_ids
      }
      this.btnLoading = true
      addCart(data).then(() => {
        this.attributeShow = false
        this.btnLoading = false
        this.$Toast('カートに入りました。')
        this.$store.dispatch('user/getCartNumber')
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tips{
  text-align: center;
  margin: 0.16rem 0.24rem 0.16rem;
}
.product-swiper{
  img{
    width: 100%;
    height: 7.5rem;
    vertical-align: middle;
  }
}
.product-swiper-thumbs{
  padding: 0.24rem 0.24rem 0.18rem;
  .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.52rem;
    height: 1.52rem;
    box-sizing: border-box;
    margin-left: 0.1rem;
    &:nth-child(1){
      margin-left: 0;
    }
    img{
      width: 1.52rem;
      height: 1.52rem;
      vertical-align: middle;
    }
  }
  .swiper-slide-thumb-active{
    border: 0.02rem solid #333;
    img{
      width: 1.36rem;
      height: 1.36rem;
    }
  }
}
.suspension-button-content{
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 1.2rem;
  padding: 0 1.13rem;
  line-height: 1.2rem;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 101;
  border-bottom: .02rem solid #f4f5fc;
  .item{
    min-width: 1.12rem;
    text-align: center;
    color: #717171;
  }
  .active{
    color: #333333;
    font-weight: 700;
    border-bottom: .04rem solid #333333;
  }
}
.product-introduce{
  padding: 0 0.24rem .32rem;
  border-bottom: 0.16rem solid #F4F5FC;
  .label{
    display: flex;
    flex-wrap: wrap;
    margin: .24rem 0 0.16rem;
    div{
      height: 0.36rem;
      color: #888991;
      line-height: 0.36rem;
      padding: 0 0.12rem;
      margin: 0 0.08rem 0.08rem 0;
      background: #ECEDF5;
    }
  }

  .price-content{
    margin-top: .2rem;
    .now{
      .price{
        display: inline-block;
        font-weight: 700;
        color: #C40A0C;
        .value{
          font-size: .56rem;
        }
      }
      .off{
        display: inline-block;
        margin-left: .16rem;
        padding: .04rem .08rem;
        line-height: .28rem;
        background-color: #c40a0a;
        color: #ffffff;
      }
    }
    .original{
      margin-top: .08rem;
      color: #c9c9c9;
      text-decoration: line-through;
    }
  }
  .sn{
    font-size: 0.24rem;
    margin: 0.16rem 0 0;
  }
  .title{
    font-size: 0.4rem;
    font-weight: bold;
    margin: 0.12rem 0 0;
  }
  .rate ::v-deep {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #FCCF52;
    margin-top: 0.32rem;
    .el-rate{
      height: auto;
      margin-left: 0.16rem;
    }
    .el-rate__icon{
      font-size: 0.32rem;
      margin-right: 0.04rem;
    }
  }
}
.product-attribute{
  margin: 0 0.24rem;
  .attribute-box{
    padding-bottom: 0.32rem;
    border-bottom: 0.02rem solid #E8EBED;
    margin: 0.2rem 0;
  }
  .attribute-title{
    span{
      color: #C40A0A;
      font-size: 0.24rem;
      margin-left: 0.12rem;
    }
  }
  .attribute-item{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .text-box{
    padding: 0.24rem;
    box-sizing: border-box;
    border: 0.02rem solid #E8EBED;
    margin: 0.24rem 0.24rem 0 0;
  }
  .img-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.88rem;
    height: 0.88rem;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 0.24rem 0.24rem 0 0;
    img{
      width: 0.88rem;
      height: 0.88rem;
      border-radius: 50%;
      vertical-align: middle;
    }
  }
  .active{
    .text-box{
      border: 0.02rem solid #333;
    }
    .img-box{
      border: 0.02rem solid #333;
      img{
        width: 0.7rem;
        height: 0.7rem;
      }
    }
  }
}
.checked-attribute-content{
  padding: .28rem .24rem .34rem;
  border-bottom: .16rem solid #f4f5fc;
  box-sizing: border-box;
  font-size: .28rem;
  .name{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    img{
      width: .24rem;
      height: .24rem;
    }
  }
  .checked-value{
    margin-top: .24rem;
  }
  .image-content{
    .item{
      display: flex;
      .img-box{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.72rem;
        height: 0.72rem;
        margin: 0.24rem 0.24rem 0 0;
        border-radius: 50%;
        box-sizing: border-box;
        img{
          width: 0.72rem;
          height: 0.72rem;
          border-radius: 50%;
          vertical-align: middle;
        }
      }
      .active{
        .img-box{
          border: 0.02rem solid #333;
          img{
            width: 0.54rem;
            height: 0.54rem;
          }
        }
      }
    }
  }
}
.desc-content{
  margin-top: .2rem;
  margin-bottom: .56rem;
  padding: 0 .24rem .2rem;
  border-bottom: .16rem solid #f4f5fc;
  font-size: .28rem;
  .text1{
    padding-bottom: .28rem;
    border-bottom: .02rem solid #e8ebed;
    span:first-child{
      margin-right: .4rem;
    }
  }
  .text2{
    margin-top: .2rem;
  }
  .text3{
    margin-top: .1rem;
    font-size: .24rem;
  }
}
.product-comment{
  position: relative;
}

.label-keywords-content{
  display: flex;
  flex-wrap: wrap;
  padding: .24rem .24rem 0;
  margin: .32rem .24rem 0;
  background-color: #f4f5fc;
  .item{
    padding: .12rem .32rem;
    margin-right: .24rem;
    margin-bottom: .24rem;
    border: .02rem solid #999fa5;
    border-radius: .34rem;
  }

}
.product-anchor{
  position: absolute;
  top: -1.2rem;
}
.product-desc ::v-deep{
  margin: 0.08rem 0.24rem;
  img{
    max-width: 100%;
  }
  .product-desc-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.98rem;
    color: #fff;
    padding: 0 0.24rem;
    background: #292E32;
    img{
      width: 0.24rem;
    }
  }
  .product-desc-content{
    padding: 0.48rem 0;
    box-sizing: border-box;
  }
  .evaluate-box {
    .evaluate-item{
      padding-bottom: 0.28rem;
      border-bottom: 0.02rem solid #E8EBED;
      margin: 0.22rem 0 0;
    }
    .evaluate-box{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .name{
      font-size: 0.32rem;
    }
    .image-content{
      margin-top: .04rem;
      .box{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        img{
          margin-top: .14rem;
        }
      }
      .one{
        img{
          width: 4.32rem;
          height: 4.32rem;
        }
      }
      .two,.four{
        img{
          width: 3.44rem;
          height: 3.44rem;
        }
      }
      .three{
        img{
          width: 2.24rem;
          height: 2.24rem;
        }
      }
    }
    .stars{
      color: #FCCF52;
      font-size: 0.32rem;
      font-weight: bold;
    }
    .date{
      color: #999999;
      font-size: 0.24rem;
    }
    .el-rate__icon{
      font-size: 0.32rem;
      margin: 0 0 0 6px;
    }
    .desc{
      text-align: justify;
      line-height: 0.48rem;
      margin-top: 0.18rem;
    }
    .view-more{
      width: 4.04rem;
      height: 0.76rem;
      text-align: center;
      line-height: 0.76rem;
      border: 0.02rem solid #333333;
      margin: 0.4rem auto;
    }
  }
}
.view-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.9rem 0 0.28rem;
  &::before,
  &::after{
    content: '';
    width: 0.8rem;
    height: 0.02rem;
    background: #333333;
    margin: 0 0.4rem;
  }
}
.relation-swiper ::v-deep {
  margin: 0.28rem 0.24rem;
  padding-bottom: 1.12rem;
  .swiper-slide{
    width: 3.44rem;
    margin-right: 0.14rem;
  }
  & .swiper-slide:nth-last-child(1){
    margin-right: 0;
  }
  .product-name{
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-top: 0.16rem;
  }
  .price{
    font-weight: bold;
  }
  .swiper-pagination{
    top: auto;
    bottom: 0.32rem;
    width: 4.98rem;
    height: 0.02rem;
    background: #E8EBED;
  }
  .swiper-pagination-progressbar-fill{
    background: #333333;
  }
  .swiper-button-prev{
    top: auto;
    left: auto;
    right: 0.92rem;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/left_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
  .swiper-button-next{
    top: auto;
    right: 0;
    bottom: 0;
    width: 0.72rem;
    height: 0.72rem;
    border-radius: 50%;
    background: url('~@/assets/images/sp/right_arrow.png') 0 0 / 100% 100%;
    margin-top: 0;
    outline: none;
    &::after{
      content:''
    }
  }
}
.link-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.12rem 0.24rem 0.24rem;
  a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3.44rem;
    height: 1.2rem;
    box-sizing: border-box;
    border: 0.02rem solid #333;
  }
  div{
    font-size: 0.32rem;
    font-weight: bold;
    margin-bottom: 0.06rem;
  }
}
.search{
    width: 7.02rem;
    height: 0.88rem;
    padding: 0 0 0 0.6rem;
    border: 0.02rem solid #E8EBED;
    box-sizing: border-box;
    background: url('~@/assets/images/sp/shape.png') 0.2rem center / 0.28rem 0.28rem no-repeat;
    margin: 0.14rem 0.24rem 1.6rem;
}
.search::-webkit-input-placeholder{
  color: #999FA5
}
.product-cart{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1rem;
  padding-left: .24rem;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 20;
  box-sizing: border-box;
  .collection{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 0.9rem;
    height: 0.9rem;
    line-height: 1;
    font-size: 0.24rem;
    box-sizing: border-box;
    border: 0.02rem solid #333;
    img{
      width: 0.44rem;
      margin-bottom: 0.06rem;
      vertical-align: middle;
    }
  }
  .add-cart{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.2rem;
    height: 100%;
    color: #fff;
    font-weight: bold;
    background: #333;
    img{
      width: 0.32rem;
      vertical-align: middle;
      margin: 0 0.12rem 0 0;
    }
  }
}

.fixed-attribute-content{
  .top-content{
    position: relative;
    display: flex;
    height: 2.2rem;
    padding: .24rem;
    box-sizing: border-box;
    border-bottom: .16rem solid #f4f5fc;
    .image{
      width: 1.6rem;
      height: 1.6rem;
      margin-right: .24rem;
    }
    .detail{
      width: 4.8rem;
    }
    .price{
      color: #C40A0C;
      font-size: .28rem;
      font-weight: 700;
      .value{
        font-size: .44rem;
      }
    }
    .original{
      height: .32rem;
      margin-top: .06rem;
      color: #666666;
      text-decoration: line-through;
    }
    .close-btn{
      position: absolute;
      right: .24rem;
      top: .24rem;
      width: .24rem;
      height: .24rem;
    }
    .checked-attribute{
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2;
      margin-top: .14rem;
      color: #999FA5;
    }
  }
  .mid-content{
    overflow-y: scroll;
    height: 7.5rem;
    .product-attribute{
  margin: 0 0.24rem;
  .attribute-box{
    padding-bottom: 0.32rem;
    border-bottom: 0.02rem solid #E8EBED;
    margin: 0.2rem 0;
  }
  .attribute-title{
    span{
      color: #C40A0A;
      font-size: 0.24rem;
      margin-left: 0.12rem;
    }
  }
  .attribute-item{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .text-box{
    padding: 0.24rem;
    box-sizing: border-box;
    border: 0.02rem solid #E8EBED;
    margin: 0.24rem 0.24rem 0 0;
  }
  .img-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.88rem;
    height: 0.88rem;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 0.24rem 0.24rem 0 0;
    img{
      width: 0.88rem;
      height: 0.88rem;
      border-radius: 50%;
      vertical-align: middle;
    }
  }
  .active{
    .text-box{
      border: 0.02rem solid #333;
    }
    .img-box{
      border: 0.02rem solid #333;
      img{
        width: 0.7rem;
        height: 0.7rem;
      }
    }
  }
}
  }
  .bottom-content{
    width: 100%;
    height: 1rem;
    // line-height: 1rem;
    background-color: #333333;
    color: #ffffff;
    text-align: center;
    font-size: .28rem;
    font-weight: 700;
    img{
      width: .32rem;
      height: .32rem;
      margin-right: .12rem;
    }
  }
}
</style>
