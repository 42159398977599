<template>
  <div class="sidebar">
    <div class="username">{{ username }}</div>
    <div class="list-content">
      <router-link
        v-for="(item,index) in sidebarOptions"
        :key="index"
        :to="item.path"
      >
        <div class="list">
          <div :class="{'current':path === item.path}">
            <img :src="item.img">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </router-link>
      <div class="list" @click="handleLogout">
        <div>
          <img src="@/assets/images/pc/personal_logout.png">
          <span>ログアウト</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: { },
  props: {
    path: { // 权限类型
      type: String,
      default: '/personal/mine',
      required: true
    }
  },
  data() {
    return {
      sidebarOptions: [
        {
          path: '/personal/mine',
          name: 'マイページ',
          img: require('@/assets/images/pc/personal_mine.png')
        },
        {
          path: '/personal/address',
          name: 'アカウント情報・変更',
          img: require('@/assets/images/pc/personal_address.png')
        },
        {
          path: '/personal/order',
          name: '注文履歴',
          img: require('@/assets/images/pc/personal_order.png')
        },
        {
          path: '/personal/my_collection',
          name: 'お気に入り商品',
          img: require('@/assets/images/pc/personal_collection.png')
        },
        {
          path: '/personal/coupon',
          name: 'myクーポン',
          img: require('@/assets/images/pc/personal_coupon.png')
        },
        {
          path: '/personal/point',
          name: 'myポイント',
          img: require('@/assets/images/pc/personal_point.png')
        }
      ],
      initData: {}
    }
  },
  computed: {
    ...mapGetters([
      'username'
    ])
  },
  created() {
    if (!this.username) {
      this.$store.dispatch('user/getUserDetail')
    }
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$store.dispatch('user/getCartNumber')
        this.$router.push('/')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar{
    width: 280px;
    // padding-top: 32px;
    border: 1px solid #e8ebed;
    box-sizing: border-box;
    font-size: 16px;
    .username{
        // margin-top: 32px;
        width: 100%;
        height: 91px;
        line-height: 91px;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        background: url(~@/assets/images/pc/personal_side_bg.png);
    }
    .list-content{
        .list{
            padding: 8px 0;
            border-top: 1px solid #e8ebed;
            cursor: pointer;
            div{
                height: 40px;
                line-height: 40px;
                img{
                    margin-left: 16px;
                }
                span{
                    margin-left: 8px;
                }
            }
            div.current{
                border-left: 4px solid #333333;
                img{
                    margin-left: 24px;
                }
            }
            img,span{
                vertical-align: middle;
            }
        }
    }
}
</style>
