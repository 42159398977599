const state = {
  config: {},
  question: {}
}

const mutations = {
  SET_CONFIG: (state, config) => {
    state.config = config
  },
  SET_QUESTION: (state, question) => {
    state.question = question
  }
}

const actions = {
  // 获取店铺配置
  getBasicConfig({ commit }, config) {
    commit('SET_CONFIG', config)
  },
  setQuestion({ commit }, question) {
    return new Promise(resolve => {
      commit('SET_QUESTION', question)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

