var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal-content" }, [
    _c(
      "div",
      { staticClass: "bread-crumbs" },
      [
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("家具TOP")]),
        _vm._v(" / "),
        _c("router-link", { attrs: { to: "/personal/mine" } }, [
          _vm._v("マイページ"),
        ]),
        _vm._v(" / myポイント "),
      ],
      1
    ),
    _c("div", { staticClass: "title" }, [_vm._v("myポイント")]),
    _c("div", { staticClass: "detail-content" }, [
      _c("div", { staticClass: "head" }, [
        _c("span", [_vm._v("ご利用可能ポイント：")]),
        _c("span", { staticClass: "value" }, [
          _vm._v(_vm._s(_vm.calcThousands(_vm.initData.totalPoint)) + " pt"),
        ]),
      ]),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.calcThousands(_vm.initData.normalPoint)) + " pt"),
          ]),
          _c("div", { staticClass: "text" }, [_vm._v("通常ポイント")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              " ■ 有効期限： " + _vm._s(_vm.initData.normalPointExpiredAt)
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " ■ 通常ポイントの有効期限は最後のご購入またはポイント獲得から1年間です。woodlife.jpでお買い物をするたびに最後のお買い物の1年後に延長されます。 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.calcThousands(_vm.initData.activityPoint))),
          ]),
          _c("div", { staticClass: "text" }, [_vm._v("期間限定ポイント")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              " ■ 通常ポイントのうち、特定の有効期限までに限って利用できるポイントです。"
            ),
            _c("br"),
            _vm._v(" ■ お買い物をすることで有効期限が延長されません。"),
            _c("br"),
            _vm._v(
              " ■ 通常のポイントと同様、" +
                _vm._s(_vm.initData.per) +
                "ポイント＝" +
                _vm._s(_vm.initData.deuction) +
                "円分として使えるポイントです。"
            ),
            _c("br"),
            _vm._v(" ■ 期間終了後、期間限定ポイントは無効となります。 "),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.calcThousands(_vm.willPoint))),
          ]),
          _c("div", { staticClass: "text" }, [_vm._v("獲得予定ポイント")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              " ■ 獲得予定ポイントはお支払いにはご利用いただけません。原則として、獲得予定ポイントは、商品の発送日を目安にご利用可能ポイントに変わります。 "
            ),
          ]),
        ]),
      ]),
    ]),
    _vm.pointRecordData.length
      ? _c("div", { staticClass: "record-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("ポイントご利用明細")]),
          _c(
            "div",
            { staticClass: "item-content" },
            _vm._l(_vm.pointRecordData, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  attrs: { to: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTo(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "name" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(item.name) + " " + _vm._s(item.order_sn || "")
                      ),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.created_at)),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      class: { red: !item.is_point_obtain },
                    },
                    [
                      _vm._v(
                        _vm._s(item.is_point_obtain ? "+" : "-") +
                          _vm._s(item.point)
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "arrow" }, [
                    item.order
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/sp/right_arrow1.png"),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }