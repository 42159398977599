<template>
  <div class="app-wrapper">
    <heads v-show="headerShow" />
    <router-view name="default" />
    <footers />
    <div class="side-content" :class="{'close':!sideShow}">
      <div class="content">
        <router-link class="side-item" to="/question">
          <img src="@/assets/images/pc/customer.png">
          <div>カスタマーサ<br>ポート</div>
        </router-link>
        <div class="side-item" @click="confirmVisible = true">
          <img src="@/assets/images/pc/mail.png">
          <div>ニュースレ<br>ター登録</div>
        </div>
        <transition name="el-fade-in">
          <div v-show="toTopShow" class="side-item" @click="toTop()">
            <img src="@/assets/images/pc/to_top.png">
          </div>
        </transition>
        <div class="btn" @click.prevent="sideShow = false" />
        <img v-show="!sideShow" src="@/assets/images/pc/side_open.png" class="open" @click="sideShow = true">
      </div>
    </div>
    <mail :confirm-visible.sync="confirmVisible" />
  </div>
</template>
<script>
import heads from './header'
import footers from './footer'
import mail from '@/views/pc/layout/mail'
import { scrollTo } from '@/utils/scrollTo'
export default {
  components: { heads, footers, mail },
  data() {
    return {
      confirmVisible: false,
      toTopShow: false,
      headerShow: true,
      footerShow: true,
      sideShow: true
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.headerShow = this.$route.meta.headerShow === undefined
        this.footerShow = this.$route.meta.footerShow === undefined
      },
      immediate: true
    }
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // 获取滚动时的高度
      const top = document.documentElement.scrollTop
      if (top > 300) {
        this.toTopShow = true
      } else {
        this.toTopShow = false
      }
    },
    // 回到顶部
    toTop() {
      scrollTo(0, 800)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-wrapper{
  min-width: 1440px;
  min-height: 100vh;
}
.side-content{
  position: fixed;
  right: 0;
  top: 50%;
  width: 100px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 0 12px;
  box-sizing: border-box;
  background: #000;
  z-index: 10;
  transition: .1s;
  &:hover{
    .btn{
      display: block !important;;
    }
  }
  .content{
    position: relative;
    .btn{
      display: none;
      position: absolute;
      width: 24px;
      height: 24px;
      left: -24px;
      top: 14px;
      background: url('~@/assets/images/pc/side_arrow.png');
      cursor: pointer;
      &:hover{
        background: url('~@/assets/images/pc/side_arrow_hover.png');
      }
    }
    .open{
      position: absolute;
      width: 24px;
      height: 24px;
      left: -48px;
      top: 14px;
      cursor: pointer;
    }
  }
}
.close{
  right: -112px;
}
.side-item{
  display: block;
  padding: 16px 0 6px;
  border-top: 1px solid #fff;
  cursor: pointer;
  &:nth-child(1){
    border-top: 0;
  }
  img{
    margin-bottom: 6px;
  }
}
</style>
