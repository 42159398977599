var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-content" },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/sp/logo_sp.png") },
        }),
      ]),
      _c("img", {
        staticClass: "step",
        attrs: { src: require("@/assets/images/sp/step_pay_error.png") },
      }),
      _c(
        "div",
        { staticClass: "error-content" },
        [
          _vm._m(0),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "ご入力頂いたパスワードが間違っているか、クレジットカードに本人認証サービス（3Dセキュア）の設定をされていない可能性が考えられるため、ご利用のカード発行会社にご確認ください。"
            ),
          ]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "安全にご利用いただくため、本人認証サービスのパスワードが設定されたクレジットカードが必要です。"
            ),
          ]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "※3Dセキュア非対応のクレジットカードもございます。詳細はご利用のカード発行会社へお問い合わせください。"
            ),
          ]),
          _c("img", {
            staticClass: "error-img",
            attrs: { src: require("@/assets/images/sp/stripe_pay_error.png") },
          }),
          _c("router-link", { attrs: { to: "/cart" } }, [
            _c("div", { staticClass: "button" }, [_vm._v("もう一度やり直す")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "warn" }, [
      _vm._v(" クレジットカードの本人認証"),
      _c("br"),
      _vm._v(" （3Dセキュア）に失敗しました！"),
      _c("br"),
      _vm._v(" 一度購入画面に戻り、"),
      _c("br"),
      _vm._v(" 再度決済を実施してください。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }