<template>
  <div class="guides-content">
    <div class="breadcrumb">
      <router-link to="/">家具通販専門店TOP</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <router-link to="/guides/category">ご利用ガイド</router-link>
      <img src="@/assets/images/pc/breadcrumb_arrow.png" alt="">
      <span>配送・送料について</span>
    </div>
    <div class="list-content">
      <div class="list">
        <div class="title">01 配送について</div>
        <div class="bg-text">配送は玄関先までとなります。開梱や組立、梱包材の回収はございません。</div>
        <div class="text-content">
          配送会社（ヤマト運輸・佐川急便・メーカーから直送）にて発送しております。配送会社ご指定ありの場合は差額分お客様ご負担となります。<br>
          <span class="red">配送希望日にお応えできない場合、搬入出来なかった場合も、ご注文キャンセル・返品などはお受けできませんので、予めご了承くださいませ。</span><br>
          ※＜開梱設置＞無料・有料ご選択された場合、開梱・組み立て・梱包材の引き上げまで行います。<br>
          <router-link to="/guides/install">＞＞搬入・組立てについてこちらのページにてご確認ください。</router-link>
        </div>
        <div class="red-bg">北海道・沖縄県・その他の離島につきましてはご利用・お届けができないことを予めご了承ください。</div>
        <img src="@/assets/images/pc/delivery.png">
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>About delivery</span>
        </div>
      </div>
      <div class="list">
        <div class="title">02 配送日時について</div>
        <div class="text-content">
          大型家具商品(受注生産)のお届けまでは時間がかかるため、ご注文の際に配送希望日時の指定が不可となっております。<br>
          通常在庫品は、ご入金確認後から1週間程度で順次発送をさせていただきます。<br>
          受注生産品は各商品ページでご確認ください。最大約60日前後発送いたします。<br>
          発送準備が完了した商品から「ご注文商品出荷のお知らせ」メールにてご連絡し、順番に出荷となります。<br>
          工場生産状況や港の混雑に伴いにより、お届けが遅れる場合がございますので、予めご了承くださいませ。<br>
          ★★複数商品をご注文の場合のお届け★★<br>
          すべての商品のお届けができる最短のお日にちにて、まとめて発送致します。<br>
          ただし、欠品等で納期が大幅に遅れてしまう場合は、複数に分けてのお届けとなる場合がございます。<br>
          その際は、メール、またはお電話にて手配方法についてご相談させていただきます。
        </div>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>About delivery date and time</span>
        </div>
      </div>
      <div class="list">
        <div class="title">03 送料について</div>
        <div class="text-content">送料は、1回のご注文につき3,000円（税込）となります。</div>
        <p class="p1 red">※北海道・沖縄・鹿児島・離島へのお届けは、別途追加送料が必要です。</p>
        <table>
          <tr>
            <td>合計が54,000円(税込)未満</td>
            <td>合計が54,000円(税込)以上</td>
          </tr>
          <tr>
            <td>送料3,000円</td>
            <td>送料無料</td>
          </tr>
        </table>
        <p class="p2">
          対象外※配送業者による組立作業などの付帯料金・手数料<br>
          お客様都合による受取辞退、長期不在、再発送をされますと送料は別途かかります。（再送・転送料金込み）
        </p>
        <div class="about">
          <img src="@/assets/images/pc/guides_about_arrow.png">
          <span>About shipping cost</span>
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">
        <span>他のご質問はこちら</span>
        <img src="@/assets/images/pc/button_arrow.png">
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.guides-content{
    width: 1440px;
    margin: 0 auto 80px;
    font-size: 16px;
    .breadcrumb{
        margin-top: 28px;
    }
    .red{
      color: #c40a0a;
      font-weight: bold;
    }
    a{
      text-decoration: underline;
    }
    .list-content{
        .list{
            padding: 38px 32px 0;
            margin-top: 45px;
            border-top: 1px solid #e8ebed;
            &:last-child{
                padding-bottom: 45px;
                border-bottom: 1px solid #e8ebed;
            }
            .title{
                font-size: 20px;
                font-weight: 700;
            }
            .bg-text{
                height: 48px;
                padding-left: 24px;
                margin-top: 24px;
                line-height: 48px;
                font-weight: 700;
                background-color: #f4f5fc;
            }
            .text-content{
                margin-top: 24px;
                line-height: 32px;
            }
            .red-bg{
                width: 784px;
                height: 48px;
                margin: 20px 0;
                line-height: 48px;
                font-weight: 700;
                color: #c40a0a;
                background: #fcf0f0;
                text-align: center;
            }
            .about{
                margin-top: 28px;
                font-size: 12px;
                color: #999;
                span{
                    margin-left: 8px;
                }
            }
            .p1{
                margin-top: 16px;
                line-height: 24px;
            }
            table{
                width: 482px;
                margin: 20px 0;
                td{
                    width: 240px;
                    height: 48px;
                    border: 1px solid #333333;
                    text-align: center;
                }
            }
            .p2{
                line-height: 32px;
            }
        }
    }
    .button{
      width: 248px;
      height: 48px;
      margin: 52px auto 0;
      line-height: 48px;
      text-align: center;
      background-color: #333;
      color: #fff;
      cursor: pointer;
      img{
          margin-left: 4px;
      }
    }
}
</style>
