<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / ご注文について
    </div>
    <div class="title-content">
      ご注文について
      <img src="@/assets/images/sp/guides_wood.png">
    </div>
    <div class="lists-content">
      <div class="list">
        <div class="title">01 ご注文の流れについて</div>
        <div class="desc">
          (1) 商品ページで、必須項目をご選択すると自動的にサイズに応じた金額が表示されます。<br><br>
          (2) 希望の商品を選んで、「カートに入れる」ボタンより商品をショッピングカートに入れてください。
        </div>
        <div class="red-box">
          購入手続きは会員登録が必要です。会員ではない方は「新規ユーザー登録」からご登録ください。
        </div>
        <br>
        <div class="desc">
          (3) 会員ログインしてご購入手続きへお進みください。<br><br>
          (4) 配送方法とお届け先情報をご入力、ご選択くださ い。全ての項目のご入力が完了しましたら、「支払方法に進む」ボタンをクリックしてください。<br><br>
          (5) 希望の支払方法を選びください。必須項目をご入力が完了しましたら、「ご注文確定する」ボタンをクリックください。<br><br>
          (6) 「ご注文を確定前のお願い」をよくご理解の上、同意する/同意しないをご選択ください。 ※【同意しない】をご選択した場合、商品購入はできませんので、ご了承ください。<br><br>
          (7)注文が正しく完了すると、画面にご注文番号が表示されます。ご入力したメールアドレスに「ご注文内容確認メール」が自動配信されます。
        </div>
        <div class="red-box">弊社からのメールが届かない場合は早めにお問い合せください。</div>
      </div>
      <div class="list">
        <div class="title">02 お取引の流れについて</div>
        <img src="@/assets/images/sp/guides_order_img.png" alt="">
      </div>
      <div class="list">
        <div class="title">03 ご注文後のキャンセル・内容変更について</div>
        <div class="desc">
          当店の無垢材家具は、お客様からご注文を頂いてから製作する取り寄せの為、商品ご注文後の返品・返金・キャンセルは原則としてお受けしておりません。
        </div>
        <div class="red-box">
          ご注文後のキャンセル・内容変更（追加・変更）はできません。<br>ご了承の上、ご購入をお願いいたします。
        </div>
      </div>
      <div class="list">
        <div class="title">04 ご注文後に「ご注文内容確認メール」が届かない場合</div>
        <div class="desc">
          お客様ご登録のアドレスの不備・受信拒否設定・自動振分設定などが原因で、自動送信メールが届いていない場合がございます。<br>上記のご確認及びご入力いただいたメールアドレスが正しいかのご確認をお願い致します。<br><br>
          またご利用のメールサービスによっては、弊社からのメール（@woodlife.jp）が、迷惑メールへ分類されている可能性や受信拒否設定がされている可能性があります。<br>メールが届かない場合は、迷惑メールフォルダの確認およびドメイン設定をご確認ください。<br><br>
          Amazon Payをご利用いただいたお客様はAmazon様にご登録いただいているメールアドレスにメールが送信されていますので、ご確認お願いいたします。
        </div>
      </div>
      <div class="list">
        <div class="title">05 商品の在庫について</div>
        <div class="desc">
          当サイトに掲載している大型家具商品は、複数店舗で同時に販売しております。その為、同一商品へご注文が集中した場合など、他店舗にて完売してしまい欠品してしまう場合がございます。<br><br>
          在庫管理は、できる限りリアルタイムな更新を心がけておりますが、万一欠品の際はご了承下さいませ。メーカー取り寄せになる商品につきましては、在庫の有無が判明するまでに数日かかる場合がございます。<br><br>
          欠品の場合は、その旨をメールにてご連絡させていただき、勝手ながらキャンセルさせていただきます。誠に恐れ入りますが、あらかじめご了承のほどお願い申し上げます。<br><br>
          ※お支払いが完了した後においても、商品を受注数分ご用意することが出来ず、ご注文をキャンセルさせて頂く場合がございます。その場合は、規約に従い返金いたします。<br><br>
          欠品の商品ページに表示される「メールで入荷通知を受け取る」につきまして、登録をされた方に対して入荷をお約束するものではありません。入荷すること無く、お知らせメールが送信されない場合がありますのでご承知ください。
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">他のご質問はこちら</div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .title-content{
        position: relative;
        width: 100%;
        height: .88rem;
        padding: 0 .24rem;
        margin-top: .36rem;
        line-height: .88rem;
        font-size: .32rem;
        font-weight: 700;
        background: linear-gradient(90deg,#f4f5fc 1%, #ffffff 100%);
        box-sizing: border-box;
        img{
            position: absolute;
            right: 0;
            top: -.3rem;
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    .lists-content{
        margin-top: .56rem;
        .list{
            padding-bottom: .4rem;
            border-bottom: .02rem solid #e8ebed;
            &:not(:last-child){
                margin-bottom: .4rem;
            }
            .title{
                margin-bottom: .32rem;
                font-size: .32rem;
                font-weight: 700;
                span{
                  padding-left: .6rem;
                }
            }
            .red-box{
                width: 100%;
                padding: .28rem .24rem;
                margin-top: .32rem;
                background: #fcf0f0;
                color: #c40a0c;
                box-sizing: border-box;
                font-weight: 500;
                line-height: .4rem;
            }
            .desc{
                line-height: .42rem;
            }
        }
    }
    .button{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
