import { render, staticRenderFns } from "./delivery.vue?vue&type=template&id=737e4d8a&scoped=true"
import script from "./delivery.vue?vue&type=script&lang=js"
export * from "./delivery.vue?vue&type=script&lang=js"
import style0 from "./delivery.vue?vue&type=style&index=0&id=737e4d8a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "737e4d8a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/test.woodlife.jp/releases/56/vue-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('737e4d8a')) {
      api.createRecord('737e4d8a', component.options)
    } else {
      api.reload('737e4d8a', component.options)
    }
    module.hot.accept("./delivery.vue?vue&type=template&id=737e4d8a&scoped=true", function () {
      api.rerender('737e4d8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pc/guides/delivery.vue"
export default component.exports