<template>
  <div ref="headerContent" class="header-content">
    <h1 v-if="config.h1" class="notice">{{ config.h1 }}</h1>
    <div v-show="!fixed" ref="header" class="nav-box">
      <div class="nav-fixed">
        <div class="nav">
          <div>家具通販・おしゃれなインテリア専門店（ウッドライフ）</div>
          <div class="nav-img">
            <div class="input-content">
              <input v-model="searchValue" type="text" placeholder="キーワードで検索" @keyup.enter="handleSearch">
              <img src="@/assets/images/pc/search_icon.png">
            </div>
            <router-link to="/cart">
              <el-badge :value="cartNumber" :hidden="!cartNumber>0" :max="99">
                <img src="@/assets/images/pc/cart.png">
              </el-badge>
            </router-link>
            <router-link to="/personal/my_collection">
              <img src="@/assets/images/pc/heart.png">
            </router-link>
            <router-link to="/personal/mine">
              <img src="@/assets/images/pc/user.png">
            </router-link>
          </div>
        </div>
        <div class="logo">
          <router-link to="/">
            <img src="@/assets/images/pc/logo.png">
          </router-link>
          <div class="category-nav">
            <span class="category-show" @mouseenter="show = true" @mouseleave="show = false">
              <span>商品カテゴリ</span>
              <img class="category-arrow" :class="{active:show}" src="@/assets/images/pc/category_arrow.png">
            </span>
            <router-link to="/new/product"><span>新着商品</span></router-link>
            <router-link to="/guides/category"><span>ご利用ガイド</span></router-link>
          </div>
        </div>
        <div v-show="show" class="category-reveal" @mouseenter="show = true" @mouseleave="show = false">
          <div class="category-mask" @mouseenter="show = false" />
          <div class="category-box">
            <div v-for="item in categoryData" :key="item.id" class="category-item">
              <router-link :to="'/category/'+ item.url" class="parent">
                <img :src="item.image">
                <div>
                  <div class="english">{{ item.en_name }}</div>
                  <div class="japanese">{{ item.name }}</div>
                </div>
              </router-link>
              <div class="subset">
                <router-link v-for="it in item.children" :key="it.id" :to="'/category/'+ it.url">
                  <img src="@/assets/images/pc/header_arrow.png" alt="">
                  <span>{{ it.name }}</span>
                </router-link>
              </div>
            </div>
            <div class="category-item hidden" />
            <div class="category-item hidden" />
            <div class="category-item hidden" />
          </div>
        </div>
      </div>
    </div>
    <div v-show="fixed" class="simple-header">
      <div class="main">
        <div class="left-content">
          <router-link to="/" class="logo">
            <img src="@/assets/images/pc/logo.png">
          </router-link>
          <div class="category-nav">
            <span class="category-show" @mouseenter="show = true" @mouseleave="show = false">
              <span>商品カテゴリ</span>
              <img class="category-arrow" :class="{active:show}" src="@/assets/images/pc/category_arrow.png">
            </span>
            <router-link to="/new/product"><span>新着商品</span></router-link>
            <router-link to="/guides/category"><span>ご利用ガイド</span></router-link>
          </div>
        </div>
        <div class="nav-img">
          <div class="input-content">
            <input v-model="searchValue" type="text" placeholder="キーワードで検索" @keyup.enter="handleSearch">
            <img src="@/assets/images/pc/search_icon.png">
          </div>
          <router-link to="/cart">
            <el-badge :value="cartNumber" :hidden="!cartNumber>0" :max="99">
              <img src="@/assets/images/pc/cart.png">
            </el-badge>
          </router-link>
          <router-link to="/personal/my_collection">
            <img src="@/assets/images/pc/heart.png">
          </router-link>
          <router-link to="/personal/mine">
            <img src="@/assets/images/pc/user.png">
          </router-link>
        </div>
      </div>
      <div v-show="show" class="category-reveal" @mouseenter="show = true" @mouseleave="show = false">
        <div class="category-mask" @mouseenter="show = false" />
        <div class="category-box">
          <div v-for="item in categoryData" :key="item.id" class="category-item">
            <router-link :to="'/category/'+ item.url" class="parent">
              <img :src="item.image">
              <div>
                <div class="english">{{ item.en_name }}</div>
                <div class="japanese">{{ item.name }}</div>
              </div>
            </router-link>
            <div class="subset">
              <router-link v-for="it in item.children" :key="it.id" :to="'/category/'+ it.url">
                <img src="@/assets/images/pc/header_arrow.png" alt="">
                <span>{{ it.name }}</span>
              </router-link>
            </div>
          </div>
          <div class="category-item hidden" />
          <div class="category-item hidden" />
          <div class="category-item hidden" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getCategoryTree } from '@/api/category'
export default {
  data() {
    return {
      fixed: false,
      show: false,
      searchValue: '',
      categoryData: []
    }
  },
  computed: {
    ...mapGetters([
      'config',
      'cartNumber'
    ])
  },
  watch: {
    $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.show = false
      }
    },
    'config.h1': function() {
      this.$nextTick(() => {
        this.handleScroll()
      })
    }
  },
  created() {
    this.$store.dispatch('user/getCartNumber')
    getCategoryTree({ platform: 1 }).then(result => {
      this.categoryData = result
    })
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // 获取滚动时的高度
      const height = this.$refs.headerContent.offsetHeight
      const scrollTop = window.scrollY
      if (scrollTop >= height) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
    handleSearch() {
      this.$router.push({ path: '/search', query: { input: this.searchValue, page: 1 }})
    }
  }
}
</script>
<style lang="scss" scoped>
.notice{
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: lighter;
  padding: 10px;
  background: #292E32;
}
.nav-box{
  height: 128px;
  color: #333;
  font-size: 16px;
}
.nav-fixed{
  position: relative;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(23,42,103,0.11);
  z-index: 100;
  .nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1440px;
    height: 56px;
    background: #fff;
    margin: 0 auto;
  }
  .nav-img{
    display: flex;
    align-items: center;
    justify-content: center;
    .input-content{
      position: relative;
      input{
        width: 230px;
        height: 40px;
        padding-left: 38px;
        background: #f4f5fc;
        border-radius: 4px;
        border: 0;
        box-sizing: border-box;
      }
      img{
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }
    a{
      margin: 0 0 0 24px;
    }
  }
  .logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1440px;
    height: 88px;
    box-sizing: border-box;
    border-top: 1px solid #E8EBED;
    margin: 0 auto;
    div a{
      height: 100%;
      margin: 0 0 0 64px;
    }
    .category-nav{
      display: flex;
      align-items: center;
    }
    .category-show{
      display: flex;
      align-items: center;
      height: 94px;
      cursor: pointer;
    }
    .category-arrow{
      margin: 0 0 2px 4px;
    }
    .active{
      transform: rotate(180deg);
    }
  }
  .category-reveal{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #f2f2f2;
    padding: 48px 0 0;
    .category-mask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba($color: #000000, $alpha: 0.4);
      z-index: -1;
    }
    .category-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1440px;
      margin: 0 auto;
    }
    .category-item{
      width: 346px;
      // margin-right: 20px;
      margin-bottom: 44px;
    }
    .hidden{
      visibility: hidden;
      margin-bottom: 0!important;
    }
    .category-item:nth-child(3n){
      margin-right: 0;
    }
    .parent{
      display: flex;
      align-items: center;
      height: 112px;
      color: #403D3A;
      background: #ffffff;
      img{
        flex-shrink: 0;
        width: 110px;
        height: 80px;
        margin-left: 56px;
        margin-right: 32px;
        mix-blend-mode: multiply;
      }
      .english{
        width: 190px;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
      }
      .japanese{
        width: 190px;
        margin-top: 6px;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #666666;
      }
    }
    .subset{
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      a{
        width: 160px;
        line-height: 30px;
        color: #333333;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:not(:nth-child(2n)){
          margin-right: 24px;
        }
        &:hover{
          color: #999999;
        }
        img{
          width: 8px;
          height: 12px;
          margin-right: 6px;
        }
      }
    }
  }
}
.simple-header{
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  top: 0;
  box-shadow: 0px 0px 12px 0px rgba(23,42,103,0.11);
  .main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1440px;
    height: 56px;
    margin: 0 auto;
  }
  .left-content{
    display: flex;
    height: 100%;
    .logo{
      display: flex;
      align-items: center;
    }
    .category-nav{
      display: flex;
      align-items: center;
      margin-left: 152px;
      a{
        margin-left: 64px;
      }
    }
    .category-show{
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }
    .category-arrow{
      margin: 0 0 2px 4px;
    }
    .active{
      transform: rotate(180deg);
    }
  }
  .nav-img{
    display: flex;
    align-items: center;
    justify-content: center;
    .input-content{
      position: relative;
      input{
        width: 230px;
        height: 40px;
        padding-left: 38px;
        background: #f4f5fc;
        border-radius: 4px;
        border: 0;
        box-sizing: border-box;
      }
      img{
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }
    a{
      margin: 0 0 0 24px;
    }
  }
  .category-reveal{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #f2f2f2;
    padding: 48px 0 0;
    .category-mask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba($color: #000000, $alpha: 0.4);
      z-index: -1;
    }
    .category-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1440px;
      margin: 0 auto;
    }
    .category-item{
      width: 346px;
      // margin-right: 20px;
      margin-bottom: 44px;
    }
    .hidden{
      visibility: hidden;
      margin-bottom: 0!important;
    }
    .category-item:nth-child(3n){
      margin-right: 0;
    }
    .parent{
      display: flex;
      align-items: center;
      height: 112px;
      color: #403D3A;
      background: #ffffff;
      img{
        flex-shrink: 0;
        width: 110px;
        height: 80px;
        margin-left: 56px;
        margin-right: 32px;
        mix-blend-mode: multiply;
      }
      .english{
        width: 190px;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
      }
      .japanese{
        width: 190px;
        margin-top: 6px;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #666666;
      }
    }
    .subset{
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      a{
        width: 160px;
        line-height: 30px;
        color: #333333;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:not(:nth-child(2n)){
          margin-right: 24px;
        }
        &:hover{
          color: #999999;
        }
        img{
          width: 8px;
          height: 12px;
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
