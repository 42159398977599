<template>
  <div class="order-content">
    <PaymentHeader />
    <div class="step-content">
      <div class="main">
        <div class="item">
          <img src="@/assets/images/pc/step1_border.png" class="icon">
          <div>カート</div>
        </div>
        <img src="@/assets/images/pc/step_line1_in.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step2.png" class="icon">
          <div>配送</div>
        </div>
        <img src="@/assets/images/pc/step_line2_in.png" class="step-line2">
        <div class="item">
          <img src="@/assets/images/pc/step3_border.png" class="icon">
          <div>支払い</div>
        </div>
        <img src="@/assets/images/pc/step_line1_in.png" class="step-line1">
        <div class="item">
          <img src="@/assets/images/pc/step_pay_error.png" class="icon">
          <div>エラー</div>
        </div>
      </div>
    </div>
    <div class="error-content">
      <div class="left-content">
        <div class="warn">クレジットカードの本人認証（3Dセキュア）に失敗しました！<br>一度購入画面に戻り、再度決済を実施してください。</div>
        <div class="desc">ご入力頂いたパスワードが間違っているか、クレジットカードに本人認証サービス（3Dセキュア）の設定をされていない可能性が考えられるため、ご利用のカード発行会社にご確認ください。</div>
        <div class="desc">安全にご利用いただくため、本人認証サービスのパスワードが設定されたクレジットカードが必要です。</div>
        <div class="desc">※3Dセキュア非対応のクレジットカードもございます。<br>詳細はご利用のカード発行会社へお問い合わせください。</div>
        <router-link to="/cart" class="button">もう一度やり直す</router-link>
      </div>
      <img src="@/assets/images/pc/stripe_pay_error.png">
    </div>
  </div>
</template>
<script>
import PaymentHeader from '@/components/PaymentHeader'
export default {
  components: { PaymentHeader },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.order-content{
  .error-content{
    display: flex;
    justify-content: space-between;
    width: 1440px;
    margin: 80px auto;
    .left-content{
        width: 812px;
        .warn{
            margin-bottom: 20px;
            line-height: 42px;
            font-size: 28px;
            font-weight: 700;
            color: #C40A0A;
        }
        .desc{
            margin-top: 12px;
            line-height: 24px;
            font-size: 16px;
        }
        .button{
            display: block;
            margin: 32px auto 0;
            width: 204px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background-color: #333333;
            color: #ffffff;
        }
    }
  }
}
</style>
