<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / お支払方法
    </div>
    <div class="title-content">
      お支払方法
      <img src="@/assets/images/sp/guides_payment.png">
    </div>
    <div class="lists-content">
      <div class="list">
        <div class="title">01 クレジット決済</div>
        <div class="text">ご利用可能なクレジットカード</div>
        <img src="@/assets/images/sp/payment_img.png" class="payment-img">
        <div class="desc">
          ※ 海外発行のクレジットカードはご利用いただけませんので、あらかじめご了承ください。<br><br>
          また、決済処理はご注文確定の際におこなっております。引き落とし名義は「はんこプレミアム」と記載されます。<br><br>
          引き落とし日時につきましては、ご利用のクレジットカードの締め日や契約内容により異なりますので、ご利用のクレジットカード会社に直接お問合せをお願いいたします。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>Credit payment</span>
        </div>
      </div>
      <div class="list">
        <div class="title">02 銀行振込について</div>
        <div class="desc">
          銀行振込は前払いですので、お支払いを確認させていただいてからの生産及び出荷手配となります。<br><br>お振込は下記の口座にお願いいたします。
        </div>
        <table class="table">
          <tr>
            <td>三井住友銀行</td>
            <td>ゆうちょ銀行</td>
          </tr>
          <tr>
            <td>船橋支店 普通 7154834</td>
            <td>記号10580 番号37025671</td>
          </tr>
          <tr>
            <td colspan="2">口座名義<span style="margin-left: .4rem">ハンコプレミアム( カ</span></td>
          </tr>
        </table>
        <div class="desc">
          ※振込み手数料はお客様のご負担となりますのでご了承ください。<br><br>※ご注文後の7日以内にお振込み下さい。当店よりお振込用紙は送付いたしておりません。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>Bank transfer</span>
        </div>
      </div>
      <div class="list">
        <div class="title">
          <span>03</span>
          <img src="@/assets/images/sp/guides_amazon.png">
        </div>
        <div class="desc">
          Amazonに登録されている住所・クレジットカード情報を使用してお支払いができるサービスです。<br><br>会員登録をせずにご購入の際はログイン画面よりAmazon Payを選択の上、決済手続きにお進みください
        </div>
        <div class="desc" style="margin-top: .68rem">
          【注意事項】<br>
          ※会員サービス（ポイント・クーポン等）をご利用いただくには、woodlife.jp会員情報でのログインが必要です。<br>
          ※Amazonアカウントでログインいただいた場合、Amazon Pay以外のお支払い方法は選択できません。<br>
          ※Amazonポイントはつきません。<br>
          ※Amazonアカウントでご注文後は配送先・お支払い方法の変更はできません。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>Amazonpay</span>
        </div>
      </div>
      <div class="list">
        <div class="title">04 PayPay</div>
        <div class="desc">
          PayPayは事前にチャージした電子マネー(PayPay残高)を使ってお支払いができるサービスです。<br><br>詳しいお支払手順はこちらからご確認ください。
        </div>
        <div class="desc" style="margin-top: .68rem">
          【注意事項】<br>
          ネットサービスの支払いで使用できるのはPayPay残高のみとなります。<br>
          ※残高による一括払いのみとなり残高不足分の場合はご利用いただけません。<br>
          ※PayPayから他のお支払い方法への変更はできません。<br>
          ※注文後、お客様ご自身でのキャンセルはできません。当店までお問い合わせください。<br>
          また、購入商品の一部キャンセルは承ることはできませんので、予めご了承ください。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>Paypay</span>
        </div>
      </div>
      <div class="list">
        <div class="title">05 楽天ペイ</div>
        <div class="desc">
          いつもの楽天IDとパスワードを使ってスムーズなお支払いが可能です。<br>
          楽天ポイントが貯まる・使える！「簡単」「あんしん」「お得」な楽天ペイをご利用ください。<br>
          楽天ペイの詳細については <a href="https://checkout.rakuten.co.jp/" target="_blank">こちら</a> よりお願いします。
        </div>
        <div class="desc" style="margin-top: .68rem">
          【注意事項】<br>
          ※ご利用には楽天IDが必要です。<br>
          ※ご利用の際、楽天ペイのお支払い画面に移動してご購入手続きを行ってください。<br>
          ※楽天ポイントのご利用履歴は「<a href="https://my.checkout.rakuten.co.jp/mych/" target="_blank">楽天ペイ利用履歴</a>」にてご確認ください。当サイトのマイページには表示されません。<br>
          ※クレジットカードの有効期限により、お支払い方法を変更していただく場合があります。あらかじめご了承ください。<br>
          ※楽天ポイントが貯まるのは楽天カード・楽天ポイント・楽天キャッシュでのお支払いに限ります。<br>
          ※受注生産品・入荷予定商品につきましては、お支払方法としてご利用いただけません。予めご了承をお願いいたします。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>楽天ペイ</span>
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">他のご質問はこちら</div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .title-content{
        position: relative;
        width: 100%;
        height: .88rem;
        padding: 0 .24rem;
        margin-top: .36rem;
        line-height: .88rem;
        font-size: .32rem;
        font-weight: 700;
        background: linear-gradient(90deg,#f4f5fc 1%, #ffffff 100%);
        box-sizing: border-box;
        img{
            position: absolute;
            right: 0;
            top: -.3rem;
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    .lists-content{
        padding: 0 .24rem;
        margin-top: .56rem;
        .list{
            padding-bottom: .4rem;
            border-bottom: .02rem solid #e8ebed;
            &:not(:last-child){
                margin-bottom: .4rem;
            }
            .title{
                margin-bottom: .32rem;
                font-size: .32rem;
                font-weight: 700;
                img{
                    width: 2.52rem;
                    height: .64rem;
                    margin-left: .2rem;
                    vertical-align: middle;
                }
            }
            .payment-img{
                width: 100%;
                margin: .32rem 0 .24rem;
            }
            .desc{
                line-height: .42rem;
                a{
                  color: blue;
                }
            }
            .s-title{
                margin-top: .34rem;
                text-align: right;
                font-size: .24rem;
                color: #999999;
                img{
                    width: .16rem;
                    height: .2rem;
                    vertical-align: middle;
                }
                span{
                    margin-left: .08rem;
                    vertical-align: middle;
                }
            }
            .table{
                width: 100%;
                margin: .24rem 0;
                font-size: .24rem;
                td{
                    padding: .3rem 0;
                    text-align: center;
                    border: .02rem solid #333333;
                }
            }
        }
    }
    .button{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
