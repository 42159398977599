<template>
  <div class="guides-content">
    <div class="bread-crumbs">
      <router-link to="/">家具TOP</router-link> / <router-link to="/guides/category">ご利用ガイド</router-link> / 返品·交換について
    </div>
    <div class="title-content">
      返品·交換について
      <img src="@/assets/images/sp/guides_return.png">
    </div>
    <div class="lists-content">
      <div class="list">
        <div class="title">01 返品ついて</div>
        <div class="red-box">
          お客様ご都合による返品・交換は基本的にお受けすることができません。
        </div>
        <div class="desc">
          返品・交換は商品到着日より7日以内に弊社へご連絡ください。期限を過ぎた場合、返品・交換のご要望はお受けできません。<br><br>
          お客様の都合（イメージ違い、サイズ違い、商品の搬入が出来ない等）による返品はお受け出来ませんので予めご了承下さい。<br><br>
          ご注文の際は、商品について十分にご確認の上、お申し込み頂けます様お願い致します。<br><br>
          モニター上では色目を完全に再現することができません。特に天然素材は色調や模様にバラツキがあります。<br><br>
          ご連絡がいただけない場合は、ご返品をお受けいたしません。<br><br>
          イメージ違い、カラー違い、木目の好み、サイズが部屋に合わない、ニオイ、肌触り等の理由でご返品は、対応いたしませんので予めご了承下さいませ。<br><br><br>
          <b>下記は商品の不良や掲載情報の不備には該当しません。</b><br><br>
          <ul>
            <li>商品のお色に関してはブラウザの閲覧環境によって若干異なって見える場合がございます。</li><br>
            <li>表記サイズ・重量の計測方法や計測位置によって多少の誤差がございます。<br>※ 表記寸法において±3cm 程の誤差が生じる場合でも良品とさせて頂いております。</li><br>
            <li>天然木を材料（突板を含む）として使用した商品の木目、節、木肌の色味、色合い、商品ページと多少の色味の違いが発生する場合がございます。<br>その場合は商品不良に該当しません。</li><br>
            <li>商品の取り扱いには細心の注意を払っておりますが、組立て・梱包時や、運送時に不可抗力で小傷（擦り傷）などが付く場合がございます。<br>その場合は不良扱いの範疇にはなりませんのであらかじめご了承くださいますようお願い致します。</li><br>
            <li>組立てが必要な商品の場合、商品を組立てた後に傷や汚れに関してご連絡を頂きましてもご対応致しかねます。ご注意ください。</li><br>
            <img src="@/assets/images/sp/wood.png"><br><br>
            上記以外の交換・返品をご希望の方は、事前に必ずメールまたはお電話で当店までご連絡ください。<br><br>
            その時お客様ご自身で対応箇所をデジカメ・スマートフォンで撮影して頂き、メールに画像添付して送信してください。<br><br>
            お手続き・ご連絡がないまま当店センターに直接ご返品頂いても、ご返金できない場合がございますので、予めご了承くださいませ。
          </ul>
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About returns</span>
        </div>
      </div>
      <div class="list">
        <div class="title">02 返金について</div>
        <div class="desc">
          <b>【クレジットカード決済】</b><br><br>
          カード会社を通じて返金処理を行います。<br><br>
          クレジットカード会社の締日のタイミングにより、同月内で相殺される場合や、一度請求が発生し、その後マイナス請求の形でご返金となる場合がございますので、ご了承下さい。<br>
          実際にクレジットカード会社から返金される時期や、クレジットカードご利用明細書に記載された金額についてご不明な点がある場合は、ご利用のクレジットカード会社に直接お問い合わせください。<br><br>
          <b>【銀行振込のご利用】</b><br><br>
          銀行振り込みをご利用された方につきましてはご返金先口座情報をメールでお知らせ頂きますようお願い申し上げます。一週間程度でご指定の口座に送金致します。<br><br>
          <b>【キャッシュレス決済】</b><br><br>
          クレジットカードと同じように、決済の事業者に対して返金処理を行います。原則として現金での返金処理は行いません。<br><br>
          どの方法で支払ったかによって、お客様への返金処理は多少異なります。<br>
          チャージした残高から支払った場合、残高として返金が行われ、貯まったポイントで支払った場合は、ポイントで変換されます。<br>
          また、コード決済アプリに登録したクレジットカードから支払われる場合もあり、このケースではクレジットカードの請求額から相殺されます。<br>
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About refund</span>
        </div>
      </div>
      <div class="list">
        <div class="title">03 送料について</div>
        <div class="desc">
          商品不良の場合を除き、商品を交換することはできません。<br><br>
          別のカラー・サイズへの交換をご希望の場合は、お届けした商品を返品の上、改めてご注文ください。<br><br>
          商品不良で交換をご希望の場合は、オンラインショップ カスタマーサービスへお手続き前にご連絡ください。<br><br>
          在庫がある場合は同一商品と交換させていただきます。在庫がない場合は、ご返金させていただきます。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About exchange</span>
        </div>
      </div>
      <div class="list">
        <div class="title">04 クーリングオフについて</div>
        <div class="desc">
          当店サービスはクーリングオフの適用対象外です。<br><br>
          当店の運営している通信販売事業は、クーリングオフの対象にはなりません。<br><br>
          これは、勧誘や押し売り等、半強制的または圧力的なセールスを行っておらず、受動的なサービスであることに起因します。<br><br>
          お客様の意思でご注文いただくサービスのため、クーリングオフは適用外となります。<br><br>
          誠に恐れ入りますが、ご注文の際には充分にご検討いただきますよう宜しくお願いいたします。<br><br>
          ※特定商取引に関する法律 第十一条より
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About cooling off</span>
        </div>
      </div>
      <div class="list">
        <div class="title">05 長期不在・受取拒否</div>
        <div class="desc">
          商品出荷後、理由なく長期不在・受取拒否などにより商品が返却された場合、往復の送料と手数料をご請求させて頂きます。<br><br>
          長期不在により商品が返却され、その後もご連絡を頂けない場合、キャンセル料として商品代金の50％～80％をご請求致します。<br><br>
          また、返却後に弊社から再度商品を発送することはできませんので、改めてご注文頂けますようお願い申し上げます。<br><br>
          場合によって、利用者からの注文を承諾せず、又は売買契約を解除することができるものとします。
        </div>
        <div class="s-title">
          <img src="@/assets/images/sp/guides_about_arrow.png">
          <span>About absence・rejection</span>
        </div>
      </div>
    </div>
    <router-link to="/guides/question">
      <div class="button">他のご質問はこちら</div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
  },
  created() {

  },
  methods: {

  }
}

</script>
<style lang="scss" scoped>
.guides-content {
    flex: 1;
    padding: 0 .24rem;
    margin-bottom: 1.12rem;
    .bread-crumbs {
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .title-content{
        position: relative;
        width: 100%;
        height: .88rem;
        padding: 0 .24rem;
        margin-top: .36rem;
        line-height: .88rem;
        font-size: .32rem;
        font-weight: 700;
        background: linear-gradient(90deg,#f4f5fc 1%, #ffffff 100%);
        box-sizing: border-box;
        img{
            position: absolute;
            right: 0;
            top: -.3rem;
            width: 1.3rem;
            height: 1.3rem;
        }
    }
    .lists-content{
        margin-top: .56rem;
        .list{
            padding-bottom: .4rem;
            border-bottom: .02rem solid #e8ebed;
            &:not(:last-child){
                margin-bottom: .4rem;
            }
            .title{
                margin-bottom: .32rem;
                font-size: .32rem;
                font-weight: 700;
                img{
                    width: 2.52rem;
                    height: .64rem;
                    margin-left: .2rem;
                    vertical-align: middle;
                }
            }
            .red-box{
                width: 100%;
                padding: .28rem .24rem;
                margin-top: .4rem;
                margin-bottom: .24rem;
                background: #fcf0f0;
                color: #c40a0c;
                box-sizing: border-box;
                font-weight: 500;
                line-height: .4rem;
                font-size: .32rem;
            }
            .desc{
                line-height: .42rem;
            }
            .s-title{
                margin-top: .34rem;
                text-align: right;
                font-size: .24rem;
                color: #999999;
                img{
                    width: .16rem;
                    height: .2rem;
                    vertical-align: middle;
                }
                span{
                    margin-left: .08rem;
                    vertical-align: middle;
                }
            }
            ul{
              li{
                position: relative;
                padding-left: .4rem;
                &::before{
                  content: "✖️";
                  position: absolute;
                  left: 0;
                  top: 0;
                }
              }
            }
        }
    }
    .button{
        width: 100%;
        height: 1.08rem;
        margin-top: .8rem;
        line-height: 1.08rem;
        text-align: center;
        background: #333333;
        color: #ffffff;
        font-weight: 700;
    }
}
</style>
