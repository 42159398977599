var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.initData.sp_content) } }),
    _vm.initData.products.length > 0
      ? _c("div", { staticClass: "product-content" }, [
          _c("div", { staticClass: "view-title" }, [_vm._v("関連商品")]),
          _c("div", { staticClass: "swiper relation-swiper" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.initData.products, function (item, index) {
                return _c(
                  "router-link",
                  {
                    key: index,
                    staticClass: "swiper-slide",
                    attrs: { to: "/product/" + item.product_sn },
                  },
                  [
                    _c("img", { attrs: { width: "100%", src: item.image } }),
                    _c("div", { staticClass: "product-desc" }, [
                      _c("div", { staticClass: "product-name" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _vm._v("￥" + _vm._s(_vm.calcThousands(item.price))),
                        _c("span", [_vm._v("(税込)")]),
                      ]),
                    ]),
                  ]
                )
              }),
              1
            ),
            _c("div", { staticClass: "swiper-pagination" }),
            _c("div", { staticClass: "swiper-button-prev" }),
            _c("div", { staticClass: "swiper-button-next" }),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "link-box" },
      [
        _c("router-link", { attrs: { to: "/new/product" } }, [
          _c("div", [_vm._v("NEW ITEM")]),
          _vm._v(" 新商品一覧 "),
        ]),
        _c("router-link", { attrs: { to: "" } }, [
          _c("div", [_vm._v("SHORT PERIOD")]),
          _vm._v(" 短納期商品一覧 "),
        ]),
      ],
      1
    ),
    _c(
      "form",
      {
        attrs: { action: "javascript:return false" },
        on: { submit: _vm.search },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          ref: "search",
          staticClass: "search",
          attrs: { type: "search", placeholder: "キーワードで検索" },
          domProps: { value: _vm.input },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.input = $event.target.value
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }