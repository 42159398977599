<template>
  <div>
    <div class="tips"><router-link to="/">家具TOP</router-link> / 新着商品</div>
    <div class="custom" v-html="customData.sp_content" />
    <div v-if="initData.length > 0">
      <div class="view-title">ITEMS</div>
      <div class="view-desc">{{ (parameterInfo.page - 1) * parameterInfo.limit }} ～ {{ parameterInfo.page * parameterInfo.limit }} 件目を表示しています。(全{{ total }}件)</div>
      <div class="goods-list">
        <div v-for="item in initData" :key="item.id" class="goods-item">
          <router-link :to="'/product/'+ item.product_sn">
            <div class="img">
              <img :src="item.colorTagIndex===''?item.image:item.color_tags[item.colorTagIndex].pivot.image">
              <div v-if="item.promotion.length > 0 && item.promotion[0].is_open" class="discount">{{ item.promotion[0].discount }}%OFF</div>
            </div>
          </router-link>
          <div class="label">
            <div
              v-for="(it,index) in item.color_tags"
              :key="index"
              :style="{background:it.color_value}"
              :class="{active:item.colorTagIndex === index}"
              @click="item.colorTagIndex === index ? item.colorTagIndex = '' : item.colorTagIndex = index"
            >
              <span />
            </div>
          </div>
          <div class="title-box">
            <div class="title">{{ item.title }}</div>
            <div class="price">
              <template v-if="item.promotion.length > 0 && item.promotion[0].is_open">
                <span class="now">¥{{ calcThousands(item.discount_price) }}</span>
                <span class="original">¥{{ calcThousands(item.price) }}</span>
              </template>
              <template v-else>
                ¥{{ calcThousands(item.price) }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <pagination-sp
        :hidden="total <= parameterInfo.limit"
        :total="total"
        :page.sync="parameterInfo.page"
        :limit.sync="parameterInfo.limit"
        :link="$route.fullPath"
        @pagination="fetchData"
      />
    </div>
  </div>
</template>
<script>
import { productList } from '@/api/product'
import PaginationSp from '@/components/PaginationSp'
import { calcThousands, parameterCorrection } from '@/utils/validate'
import { getCustomPage } from '@/api/config'
export default {
  components: { PaginationSp },
  data() {
    return {
      initData: [],
      customData: {
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        sp_content: null,
        sp_css_path: '',
        sp_js_path: ''
      },
      total: 0,
      parameterInfo: {
        page: parseInt(this.$route.query.page) || 1,
        limit: 20
      }
    }
  },
  metaInfo() {
    return {
      title: this.customData.seo_title,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.customData.seo_keywords },
        { vmid: 'description', name: 'description', content: this.customData.seo_description }
      ],
      link: [
        { vmid: this.customData.sp_css_path, rel: 'stylesheet', href: this.customData.sp_css_path }
      ],
      script: [
        { vmid: this.customData.sp_js_path, async: 'async', src: this.customData.sp_js_path }
      ]
    }
  },
  created() {
    this.fetchData()
    this.getCustomPage()
  },
  methods: {
    calcThousands,
    fetchData() {
      let data = parameterCorrection({ is_new: true, is_on_sale: true })
      data = { ...data, ...this.parameterInfo, include: 'tag,colorTags,promotion' }
      productList(data).then(result => {
        result.data.forEach(item => {
          item.colorTagIndex = ''
        })
        this.initData = result.data
        this.total = result.total
        this.initData.forEach(item => {
          if (item.promotion.length > 0) {
            let discountPrice = Math.ceil(item.price * (100 - item.promotion[0].discount) / 100)
            if ((discountPrice % 100000 % 10000 % 1000 % 100 % 10) !== 0) {
              discountPrice = discountPrice + 10 - (discountPrice % 100000 % 10000 % 1000 % 100 % 10)
            }
            this.$set(item, 'discount_price', discountPrice)
          }
        })
      })
    },
    // 获取自定义内容
    getCustomPage() {
      getCustomPage('new_product').then(result => {
        result.sp_css_path = result.sp_css_path || ''
        result.sp_js_path = result.sp_js_path || ''
        this.customData = result
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tips{
  text-align: center;
  margin: 0.16rem 0 0.16rem;
}
.custom ::v-deep{
  img{
    max-width: 100%;
  }
}
.view-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.48rem;
  font-weight: bold;
  margin: 0.9rem 0 0.28rem;
  &::before,
  &::after{
    content: '';
    width: 0.8rem;
    height: 0.02rem;
    background: #333333;
    margin: 0 0.4rem;
  }
}
.view-desc{
  text-align: center;
  margin-bottom: 0.32rem;
}
.goods-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0.24rem;
    .goods-item{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 3.44rem;
      margin: 0 0 0.44rem;
      .img{
        position: relative;
        img{
          flex-shrink: 0;
          width: 100%;
          height: 3.44rem;
          vertical-align: middle;
        }
        .discount{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1.1rem;
          height: .36rem;
          line-height: .36rem;
          text-align: center;
          font-size: .24rem;
          background-color: #c40a0a;
          color: #ffffff;
        }
      }
      .label{
        height: 100%;
        span{
          background: transparent;
        }
        div{
          display: inline-block;
          width: 0.48rem;
          height: 0.48rem;
          vertical-align: middle;
          box-sizing: border-box;
          border-radius: 50%;
          margin: 0.16rem 0.1rem 0 0;
        }
        .active{
          border: 0.02rem solid #333;
          span{
            display: block;
            width: 0.44rem;
            height: 0.44rem;
            border-radius: 50%;
            box-sizing: border-box;
            border: 0.04rem solid #fff;
          }
        }
      }
      .title-box{
        flex-shrink: 0;
      }
      .title{
        display: -webkit-box;
        height: .68rem;
        line-height: .32rem;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        margin: .24rem 0 0 0;
        font-size: .24rem;
      }
      .price{
        margin: 0.16rem 0 0 0;
        .now{
          font-size: .28rem;
          color: #c40a0c;
          font-weight: bold;
        }
        .original{
          margin-left: .12rem;
          font-size: .24rem;
          color: #999999;
          text-decoration: line-through;
        }
      }
    }
}
</style>
