<template>
  <div class="footer">
    <div class="article-list">
      <router-link to="/new/product">
        <span>新着商品 ／ 特集</span>
        <img src="@/assets/images/sp/article_arrow.png">
      </router-link>
      <router-link to="/article">
        <span>NEWS ／ お知らせ</span>
        <img src="@/assets/images/sp/article_arrow.png">
      </router-link>
      <div class="guide-box" @click="guide = !guide">
        <span>ご利用ガイド</span>
        <img v-if="guide" src="@/assets/images/sp/article_hide.png">
        <img v-else src="@/assets/images/sp/article_open.png">
      </div>
      <el-collapse-transition>
        <div v-show="guide" class="guide">
          <router-link to="/guides/category">
            <span>ご利用ガイド</span>
            <img src="@/assets/images/sp/article_arrow.png">
          </router-link>
          <router-link to="/question">
            <span>よくあるご質問</span>
            <img src="@/assets/images/sp/article_arrow.png">
          </router-link>
          <router-link to="/guides/delivery">
            <span>配送・送料</span>
            <img src="@/assets/images/sp/article_arrow.png">
          </router-link>
          <router-link to="/company">
            <span>企業情報</span>
            <img src="@/assets/images/sp/article_arrow.png">
          </router-link>
        </div>
      </el-collapse-transition>
      <router-link to="/tokutei">
        <span>特定商取引法に基づく表示</span>
        <img src="@/assets/images/sp/article_arrow.png">
      </router-link>
      <router-link to="/privacy">
        <span>プライバシーポリシー</span>
        <img src="@/assets/images/sp/article_arrow.png">
      </router-link>
    </div>
    <div class="question">
      <router-link to="/question">お問い合わせ</router-link>
      <div>平時 9:30~18:30 (土・日を除く)</div>
    </div>
    <div class="share">
      <a href="https://www.facebook.com/woodlife.jp"><img src="@/assets/images/sp/footerShare_1.png"></a>
      <router-link to=""><img src="@/assets/images/sp/footerShare_2.png"></router-link>
      <a href="https://www.instagram.com/woodlife.jp"><img src="@/assets/images/sp/footerShare_3.png"></a>
      <router-link to=""><img src="@/assets/images/sp/footerShare_4.png"></router-link>
      <router-link to=""><img src="@/assets/images/sp/footerShare_5.png"></router-link>
    </div>
    <router-link class="logo" to="/">
      <img src="@/assets/images/sp/logo_footer.png">
    </router-link>
    <div class="footer-box">© HANKO PREMIUM CO.,LTD. ALL RIGHTS RESERVED.</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      guide: false,
      paymentFooter: false,
      year: new Date().getFullYear()
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.paymentFooter = this.$route.meta.paymentFooter
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.footer{
  position: sticky;
  top: 100%;
  color: #fff;
  font-size: 0.28rem;
  background: #292E32;
}
.article-list{
  .guide-box,a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 0.96rem;
    padding: 0 .24rem;
    box-sizing: border-box;
    border-bottom: 0.02rem solid #E8EBED;
  }
  img{
    width: 0.24rem;
    height: 0.24rem;
  }
  .guide{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #3A4145;
    a{
      width: 50%;
    }
    a:nth-child(2n){
      border-left: 0.02rem solid #E8EBED;
    }
  }
}
.question{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1.12rem 0 0.8rem;
  a{
    width: 4.04rem;
    height: 0.76rem;
    line-height: 0.76rem;
    border: 0.02rem solid #fff;
    margin-bottom: 0.1rem;
  }
}
.share{
  text-align: center;
  img {
    width: 1rem;
    height: 1rem;
    margin: 0 0.12rem;
  }
}
.logo{
  display: block;
  text-align: center;
  margin: 0.32rem 0 0.68rem;
  img {
    width: 4rem;
    height: .52rem;
  }
}
.footer-box{
  font-size: 0.24rem;
  text-align: center;
  line-height: 0.56rem;
  border-top: 0.02rem solid rgba($color: #fff, $alpha: 0.6);
}
</style>
