<template>
  <div class="cart-content">
    <template v-if="initData.length > 0">
      <div class="bread-crumbs"><router-link to="/">家具TOP</router-link> / 買い物カート</div>
      <div class="cart-title">
        <div class="name">受注生産品 / 納期2ヶ月程度～</div>
        <div class="cart"><span class="num">{{ allNumber }}</span> 商品</div>
      </div>
      <div class="list-content">
        <div
          v-for="(item, key) in initData"
          :key="item.id"
          class="item"
          :class="{
            'no-sale': !item.product.is_on_sale || (item.product_sku_stock && item.product_sku_stock.stock === 0 || item.product_sku_stock === null),
            'no-sku-stock': !item.product_sku_stock
          }"
        >
          <div class="flex">
            <router-link :to="`/product/${item.product.product_sn}`">
              <div class="img">
                <img :src="item.product.image" :alt="item.product.image_alt">
                <div v-if="!item.product.is_on_sale" class="toast">販売終了</div>
                <div v-else-if="item.product_sku_stock && item.product_sku_stock.stock === 0 || item.product_sku_stock === null" class="toast">品切れ中</div>
              </div>
            </router-link>
            <div class="detail">
              <div class="name">{{ item.product.title }}</div>
              <div class="sku-content">
                <div v-for="(skuItem,index2) in item.sku_value" :key="index2" class="list">{{ skuItem }}</div>
              </div>
              <div class="norm-content">
                <div v-for="(normItem,index3) in item.norm_values" :key="index3" class="list">{{ normItem.norm.name }}：{{ normItem.name }}</div>
              </div>
              <div class="price">¥{{ calcThousands(item.price) }}円</div>
            </div>
          </div>
          <div class="action-content">
            <div class="delete" @click="handleDeleteShow(item.id, key)">削除</div>
            <div class="box">
              <span v-show="(item.product.is_on_sale && item.product_sku_stock && item.product_sku_stock.stock !== 0 && item.number > item.product_sku_stock.stock) || item.error" class="error-text">在庫不足しています</span>
              <div class="reduce" :class="{'disabled':item.number === 1}" @click="handleReduce(item)">—</div>
              <div class="number">{{ item.number }}</div>
              <div class="add" @click="handleAdd(item)">+</div>
            </div>
          </div>
        </div>
      </div>
      <div class="total">
        <span class="name">合計金額</span>
        <span class="value">￥{{ calcThousands(totalPrice) }}</span>
      </div>
      <div class="button-content">
        <el-button type="primary" class="next-btn" :loading="btnLoading" @click="handleNext()">ご購入手続きへ</el-button>
        <router-link to="/">
          <div class="home-btn">お買い物を続ける</div>
        </router-link>
      </div>
      <div class="text-content">
        <div class="text1">購入金額 {{ calcThousands(freeShippingAmount) }}円以上 送料無料</div>
        <div class="text2">通常送料は1回のご注文につき{{ calcThousands(shippingAmount) }}円です。<br>※北海道・沖縄・離島はお届けができません。</div>
      </div>
      <van-overlay :show="deleteVisible">
        <div class="wrapper" @click.stop>
          <div class="modal-content">
            <img src="@/assets/images/sp/close_btn.png" class="close-btn" @click="deleteVisible = false">
            <div class="title">商品の削除？</div>
            <div class="text">この商品を削除しますか？</div>
            <el-button type="primary" class="confirm" :loading="confirmLoading" @click="handleConfirmDelete(index)">はい(Yes)</el-button>
            <div class="cancel" @click="deleteVisible = false">しない（NO）</div>
          </div>
        </div>
      </van-overlay>
    </template>
    <div v-else class="empty-cart">
      <img src="@/assets/images/sp/cart_empty.png">
      <div class="text">
        現在、買い物かごには商品が入っておりません。<br>お買い物を続けるには下の 「お買い物を続ける」 をクリックしてください。
      </div>
      <router-link to="/">
        <div class="button">お買い物を続ける</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBasicConfig } from '@/api/config'
import { getCartList, deleteCartProduct, updateCart, toOrder } from '@/api/cart'
import { calcThousands } from '@/utils/validate'
export default {
  components: {},
  data() {
    return {
      initData: [],
      btnLoading: false,
      confirmLoading: false,
      deleteVisible: false,
      freeShippingAmount: 0,
      shippingAmount: 0,
      calcThousands
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ]),
    allNumber() { // 购物车总数
      let number = 0
      this.initData.forEach(item => {
        number += item.number
      })
      return number
    },
    totalPrice() { // 购物车总价
      let price = 0
      this.initData.forEach(item => {
        price += item.price * item.number
      })
      return price
    }
  },
  created() {
    this.fetchData().then(() => {
      this.getBasicConfig()
    })
  },
  methods: {
    fetchData() {
      return getCartList().then(result => {
        this.initData = result
      })
    },
    getBasicConfig() {
      getBasicConfig().then(result => {
        this.freeShippingAmount = result.free_shipping_amount
        this.shippingAmount = result.shipping_amount
      })
    },
    handleAdd(item) {
      if (item.number < 99) {
        item.number++
        if (item.number <= item.product_sku_stock.stock) {
          this.updateCart()
        }
      }
    },
    handleReduce(item) {
      if (item.number !== 1) {
        item.number--
        this.updateCart()
      }
      if (item.number <= item.product_sku_stock.stock) {
        item.error = false
      }
    },
    updateCart() {
      const data = {
        carts: []
      }
      this.initData.forEach(item => {
        data.carts.push({
          id: item.id,
          number: item.number
        })
      })
      updateCart(data).then(result => {
        const data = result
        if (data.length === 0) {
          this.fetchData()
        } else {
          this.initData.forEach(item => {
            data.forEach(item2 => {
              if (item.id === item2) {
                this.$set(item, 'error', true)
              }
            })
          })
        }
        this.$store.dispatch('user/getCartNumber')
      }).catch(() => {})
    },
    handleDeleteShow(cartId, index) { // 显示删除确认弹窗
      this.deleteVisible = true
      this.index = index
      this.cartId = cartId
    },
    handleConfirmDelete() { // 确认删除
      this.confirmLoading = true
      deleteCartProduct({ cart_ids: [this.cartId] }).then(() => {
        this.confirmLoading = false
        this.fetchData()
        this.$store.dispatch('user/getCartNumber')
        this.deleteVisible = false
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    handleNext() {
      const data = {
        cart_list_ids: []
      }
      let flag = true
      this.initData.forEach(item => {
        if (!item.product.is_on_sale) {
          flag = false
          this.$toast('カートに廃盤商品が残っています。その商品を削除してください。')
        } else if (item.product_sku_stock && item.number > item.product_sku_stock.stock) {
          flag = false
        } else {
          data.cart_list_ids.push(item.id)
        }
      })
      if (flag) {
        if (this.token) {
          this.btnLoading = true
          toOrder(data).then(result => {
            const data = result
            if (data.length === 0) {
              this.btnLoading = false
              this.$router.push('/order/address')
            } else {
              this.btnLoading = false
              this.initData.forEach(item => {
                data.forEach(item2 => {
                  if (item.id === item2) {
                    this.$set(item, 'error', true)
                  }
                })
              })
            }
          }).catch(() => {
            this.btnLoading = false
          })
        } else {
          this.$router.push(`/login?cart_ids=${data.cart_list_ids}`)
        }
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.cart-content{
    flex: 1;
    .bread-crumbs{
        margin-top: .16rem;
        text-align: center;
        font-size: .24rem;
    }
    .cart-title{
      display: flex;
      justify-content: space-between;
      padding: 0 .24rem;
      margin-top: .56rem;
      padding-bottom: .28rem;
      border-bottom: .02rem solid #333333;
      .num{
        font-weight: 700;
      }
    }
    .list-content{
      .item{
        padding: 0 .24rem;
        margin-top: .4rem;
        border-bottom: .16rem solid #f4f5fc;
        .flex{
          display: flex;
          justify-content: space-between;
        }
        .img{
          position: relative;
          width: 2rem;
          height: 2rem;
        }
        .toast{
          position: absolute;
          left: 50%;
          bottom: -.5rem;
          transform: translate(-50%, 0);
          width: 1.4rem;
          height: .38rem;
          line-height: .38rem;
          text-align: center;
          background: #333333;
          border-radius: .2rem;
          font-size: .24rem;
          color: #ffffff;
        }
        .detail{
          width: 4.78rem;
          .name{
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
            overflow:hidden;
            margin-bottom: .24rem;
            line-height: .42rem;
            font-weight: 700;
          }
          .sku-content,.norm-content{
            line-height: .36rem;
            font-size: .24rem;
          }
          .price{
            margin-top: .24rem;
            color: #c40a0a;
          }
        }
        .action-content{
          display: flex;
          justify-content: space-between;
          padding: .24rem 0;
          margin-top: .3rem;
          border-top: .02rem dashed #e8ebed;
          .delete{
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #999999;
          }
          .box{
            display: flex;
            .error-text{
              display: flex;
              align-items: center;
              margin-right: .14rem;
              font-size: .24rem;
              color: #c40a0a;
            }
            div{
              display: flex;
              align-items: center;
              justify-content: center;
              width: .56rem;
              height: .56rem;
            }
            .number{
              color: #666666;
            }
            .reduce{
                font-size: .28rem;
                font-weight: 700;
                background: #f4f5fc;
            }
            .disabled{
              color: #cccccc;
            }
            .add{
              background: #f4f5fc;
              font-size: .46rem;
            }
          }
        }
      }
      .no-sale{
        color: #999999;
        .add,.reduce{
          color: #cccccc;
        }
      }
    }
    .total{
      display: flex;
      justify-content: space-between;
      padding: 0 .24rem;
      margin-top: .32rem;
      font-weight: 700;
      .name{
        display: flex;
        align-items: center;
      }
      .value{
        font-size: .48rem;
      }
    }
    .button-content{
      padding: 0 .24rem;
    }
    .next-btn{
      width: 100%;
      height: 1.08rem;
      margin-top: .24rem;
      background: #333333;
      color: #ffffff;
      font-weight: 700;
    }
    .home-btn{
      width: 100%;
      height: .88rem;
      margin-top: .16rem;
      line-height: .88rem;
      text-align: center;
      border: .02rem solid #333333;
      box-sizing: border-box;
    }
    .text-content{
      padding: .2rem .24rem;
      margin: .24rem .24rem 0;
      background: #fffceb;
      .text1{
        font-weight: 500;
      }
      .text2{
        font-size: .24rem;
        line-height: .44rem;
      }
    }
}

.empty-cart{
    padding: 0 .24rem;
    margin-top: 1.02rem;
    text-align: center;
    img{
      width: 1.32rem;
    }
    .text{
      margin-top: .32rem;
      line-height: .42rem;
    }
    .button{
      width: 4.08rem;
      height: .8rem;
      margin: .8rem auto 0;
      line-height: .8rem;
      text-align: center;
      background: #333333;
      color: #ffffff;
    }
}
.van-overlay{
  .modal-content{
    .title{
      font-size: .36rem;
      font-weight: 700;
    }
    .text{
      margin-top: .34rem;
    }
    .confirm{
      width: 100%;
      height: .88rem;
      margin-top: .5rem;
      background: #333333;
      color: #ffffff;
    }
    .cancel{
      width: 100%;
      height: .88rem;
      margin-top: .16rem;
      line-height: .88rem;
      border: .02rem solid #333333;
      box-sizing: border-box;
    }
  }
}
</style>
